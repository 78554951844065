import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { calculateEdgeSlope, getTwoPoints3DLength } from "../../helpers/Helpers";
import {
  IAddProduct,
  IGeometryData,
  IGutteringData,
  IInvestment,
  IMetalSheet,
  IResultEdgeRequest,
  IResultItemResponse,
  IResultPlaneRequest,
  IResultPointRequest,
  IResultRoofRequest,
  IRoofAccessory,
} from "../../models/Models";
import client from "../../api/ApiClient";
import { addProductInReducer } from "../general/productsSlice";
import { setInvestmentIdGeneral } from "../general/generalSlice";
import { addInvestmentInReducer } from "../general/investmentsSlice";
import { getStateToSave } from "../reduxHelper";
import { endLoading, startLoading } from "../loading/loadingStateSlice";
import { getDataForPrincing } from "../../helpers/DomaFunctions";
import { getPrincingResult } from "../../api/ApiContractors";
import store, { AppThunk } from "../store";
import { v4 as uuidv4 } from "uuid";
import { calculateRoofMetal, ICalcRoofMetalRequestModel } from "../../api/ApiRoof";
import { addSavedAdditionalProductReducer } from "./savedAdditionalProducts";

export interface IDiscountGroupCalc {
  code: string;
  discount: number;
  discountType: "%" | "price"; // Typ rabatu procentowy czy kwotowy
  discountPart: "nett" | "gross"; // Czy rabat na kwotę netto czy na brutto
}

export interface ICalcResult {
  isCalculated: boolean; // Czy jest policzone
  shouldRecalculate: boolean; // Czy powinno się ponownie obliczyć (gdy coś zostanie zmienione)
  data: IResultItemResponse[] | null; // Odpowiedź z serwera API a dokładnie to lista produktów

  vat: 8 | 23; // Jaki vat na produkty
  offerDiscount: number; //  Rabat na całą ofertę (% or price)
  offerDiscountType: "%" | "price"; // Typ rabatu procentowy czy kwotowy
  offerDiscountPart: "nett" | "gross"; // Czy rabat na kwotę netto czy na brutto

  discountGroups: IDiscountGroupCalc[]; // Grupy rabatowe
}

const initialState: ICalcResult = {
  isCalculated: false,
  shouldRecalculate: false,
  data: null,
  vat: 23,
  offerDiscount: 0,
  offerDiscountType: "%",
  offerDiscountPart: "nett",
  discountGroups: [],
};

// Kontener na dane materiałowe potrzebne do oblczeń
export const calcResultSlice = createSlice({
  name: "calcResult",
  initialState,
  reducers: {
    invalidateCalculation: (state) => {
      return { ...state, isCalculated: false };
    },
    updateStatusCalcResult: (state, action: PayloadAction<ICalcResult>) => {
      const calcResult = action.payload;
      return { ...calcResult, data: state.data };
    },
    addCalcResultItem: (state, action: PayloadAction<IResultItemResponse>) => {
      const item = action.payload;
      if (state.data) {
        const data = [...state.data, item];
        return { ...state, data: data };
      }
    },
    addOrUpdateDiscountGroupCalc: (state, action: PayloadAction<IDiscountGroupCalc>) => {
      const discountGroup = action.payload;

      // Sprawdzamy czy już taki rabat nie istnieje
      const discountGroupExist = state.discountGroups.find((o) => o.code === discountGroup.code);

      // Jeśli nie istnieje to dodajemy
      if (!discountGroupExist) {
        const discountGroups = [...state.discountGroups, discountGroup];
        return { ...state, discountGroups: discountGroups };
      } else {
        // Istniej to aktualizujemy
        const discountGroups = [...state.discountGroups].map((o) => {
          if (o.code === discountGroup.code) {
            return discountGroup;
          }
          return o;
        });
        return { ...state, discountGroups: discountGroups };
      }
    },
    addOrUpdateDiscountGroupsCalc: (state, action: PayloadAction<IDiscountGroupCalc[]>) => {
      const discountGroups = action.payload;

      if (state !== null) {
        if (state.discountGroups !== null) {
          for (const discountGroup of discountGroups) {
            const discountGroupExist = state.discountGroups.find((o) => o.code === discountGroup.code);

            if (!discountGroupExist) {
              state.discountGroups.push(discountGroup);
            } else {
              state.discountGroups = state.discountGroups.map((o) => {
                if (o.code === discountGroup.code) {
                  return discountGroup;
                }
                return o;
              });
            }
          }
        }
      }
    },
    deleteDiscountGroupCalc: (state, action: PayloadAction<IDiscountGroupCalc>) => {
      const discountGroup = action.payload;

      // Sprawdzamy czy już taki rabat nie istnieje
      const discountGroupExist = state.discountGroups.find((o) => o.code === discountGroup.code);

      // Jeśli istnieje to usuwamy
      if (discountGroupExist) {
        const discountGroups = [...state.discountGroups].filter((o) => o.code !== discountGroup.code);
        return { ...state, discountGroups: discountGroups };
      }
    },
    deleteCalcResultItem: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      if (state.data) {
        const data = [...state.data].filter((o) => o.id !== id);
        return { ...state, data: data };
      }
    },
    deleteLaboursCalcResultItem: (state) => {
      if (state.data) {
        const data = [...state.data].filter((o) => o.categoryCode !== "labour");
        return { ...state, data: data };
      }
    },
    updateActiveCalcResultItem: (state, action: PayloadAction<{ id: string; value: boolean }>) => {
      const id = action.payload.id;
      const value = action.payload.value;

      const index = state.data?.findIndex((o) => o.id === id);

      if (state) {
        if (state.data) {
          state.data[index as number].active = value;
        }
      }
    },
    // Aktualziacja amount dla produktu
    updateAmountCalcResultItem: (state, action: PayloadAction<{ id: string; amount: number }>) => {
      const id = action.payload.id;
      const amount = action.payload.amount;

      const index = state.data?.findIndex((o) => o.id === id);

      if (state) {
        if (state.data) {
          state.data[index as number].amount = amount;
        }
      }
    },
    setCalcResultItems: (state, action: PayloadAction<IResultItemResponse[]>) => {
      const data = action.payload;
      return { ...state, data: data };
    },
    updateAdditionalDiscountResultItem: (
      state,
      action: PayloadAction<{
        offerDiscount: number;
        offerDiscountType: "%" | "price";
        offerDiscountPart: "nett" | "gross";
      }>
    ) => {
      return {
        ...state,
        offerDiscount: action.payload.offerDiscount,
        offerDiscountType: action.payload.offerDiscountType,
        offerDiscountPart: action.payload.offerDiscountPart,
      };
    },
    updateVATResultItem: (state, action: PayloadAction<number>) => {
      const vat = action.payload;
      return { ...state, vat: vat as 8 | 23 };
    },
    updateInfoResultItem: (state, action: PayloadAction<{ discount: number; vat: number }>) => {
      return { ...state, offerDiscount: action.payload.discount, vat: action.payload.vat as 8 | 23 };
    },
    resetOfferDiscountResultItem: (state) => {
      return { ...state, offerDiscount: 0 };
    },
    updateCalcResultItem: (state, action: PayloadAction<IResultItemResponse>) => {
      const calcResult = action.payload;

      const index = state.data?.findIndex((o) => o.id === calcResult.id);

      if (state) {
        if (state.data) {
          state.data[index as number] = calcResult;
        }
      }
    },
    updateGroupDiscountsCalcResultsItem: (state, action: PayloadAction<IResultItemResponse[]>) => {
      const calcResults: IResultItemResponse[] = action.payload;

      if (state !== null) {
        if (state.data !== null) {
          const resultItems = [...state.data];

          for (const item of calcResults) {
            const index = state.data?.findIndex((o) => o.id === item.id);

            if (index !== undefined && index !== null) {
              resultItems[index] = item;
            }
          }

          return { ...state, data: resultItems };
        }
      }
    },
    clearCalcResult: (state) => {
      return initialState;
    },
    setSavedRoofCalcResults: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  invalidateCalculation,
  updateStatusCalcResult,
  addCalcResultItem,
  deleteCalcResultItem,
  setCalcResultItems,
  updateInfoResultItem,
  updateVATResultItem,
  updateAmountCalcResultItem,
  updateActiveCalcResultItem,
  updateAdditionalDiscountResultItem,
  deleteLaboursCalcResultItem,
  resetOfferDiscountResultItem,
  updateCalcResultItem,
  clearCalcResult,
  setSavedRoofCalcResults,
  addOrUpdateDiscountGroupCalc,
  addOrUpdateDiscountGroupsCalc,
  deleteDiscountGroupCalc,
  updateGroupDiscountsCalcResultsItem,
} = calcResultSlice.actions;
export default calcResultSlice.reducer;

// Główna funkcja obliczająca ofertę
export const calculateOffer =
  (firstCalc: boolean): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    // Ustalić jaki typ kalulator czy z obrysu jest narysowane

    const state = getState();

    const rsoPlanes = state.rsoPlanes;
    if (rsoPlanes.length > 0) {
      // Jeśli z obrysu połaci
      dispatch(calucateOfferRoofOutline(firstCalc));
    } else {
      // Jeśli z kalulatora to obliczamy z kalkulatora
      dispatch(calculateOfferCalculator(firstCalc));
    }
  };

export interface IRSOPoint3D {
  x: number;
  y: number;
  z: number;
}

// Funkcja obliczająca dach z obrysu
export const calucateOfferRoofOutline =
  (firstCalc: boolean): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    // console.log("START OUTLINE OFFER");
    dispatch(startLoading());

    // Potrzebne dane
    const state = getState();

    const scale = state.drawingScale;

    const rsoPlanes = state.rsoPlanes;
    const rsoPoints = state.rsoPoints;
    const rsoEdges = state.rsoEdges;

    const calcMaterialsData = state.calcMaterialsData;

    const calcResult = state.calcResult;
    const windows = state.windows;
    const additionalElements = state.additionalElements;
    const productCategories = state.productCategories;
    const productColors = state.productColors;
    const gutteringElements = state.gutteringElements;
    const roofAccessories = state.roofAccessories;
    const windowHoles = state.windowHoles;

    const geometryDataState = state.geometryData;

    const currencyValue = state.currencyValue;

    const additionalProducts = state.additionalProducts;

    // Ustalenie połaci
    // TODO
    const planes: IResultPlaneRequest[] = [];
    for (const plane of rsoPlanes) {
      const area = plane.area / scale ** 2;

      let rPlane: IResultPlaneRequest = {
        id: plane.id,
        slope: plane.angle,
        area: area, // Tu dodac funkcję liczącą pole powierzchni
      };
      planes.push(rPlane);
    }

    // Ustalenie krawędzi
    const edges: IResultEdgeRequest[] = [];
    for (const edge of rsoEdges) {
      const s = rsoPoints.find((x) => x.id === edge.startPointId);
      const e = rsoPoints.find((x) => x.id === edge.endPointId);

      const sn = { x: s?.x as number, y: s?.y as number, z: s?.z as number };
      const en = { x: e?.x as number, y: e?.y as number, z: e?.z as number };

      let rEdge: IResultEdgeRequest = {
        id: edge.id,
        type: edge.type as number,
        slope: 45,
        length: getTwoPoints3DLength(sn, en, scale),
        startPointId: edge.startPointId,
        endPointId: edge.endPointId,
      };
      edges.push(rEdge);
    }

    // Dane orynnowania
    const gutteringData: IGutteringData = {
      eaveOverhang: calcMaterialsData.eaveOverhang,
      wallHeight: calcMaterialsData.wallHeight,
      drainPipeCount: gutteringElements.gutterDrainPipes.length,
      internalCornerCount: gutteringElements.gutterInternalCorners.length,
      outerCornerCount: gutteringElements.gutterExternalCorners.length,
      leftCapCount: gutteringElements.gutterEndLefts.length,
      rightCapCount: gutteringElements.gutterEndRights.length,
    };

    const appState = getStateToSave(getState()); // stan reduxa

    let roofingType = 0; // Dla dachówki
    // Dachówka czy blacha
    // Jełsi systemType > 0 to jets blacha na dachu
    if (calcMaterialsData.systemType > 0) {
      roofingType = 1; // 0 dachówka, 1 - blacha
    }

    let metalSheets: IMetalSheet[] = [];

    if (roofingType === 1) {
      metalSheets = state.metalSheets.map((sheet) => ({
        ...sheet,
        width: sheet.width / scale,
        height: sheet.height / scale,
      }));
    }

    // console.log("META SHEETS", metalSheets);

    const geometryData: IGeometryData = {
      startHorizontalRidgeCount: geometryDataState?.startHorizontalRidgeCount,
      endHorizontalRidgeCount: geometryDataState?.endHorizontalRidgeCount,
      endAngledRidgeCount: geometryDataState?.endAngledRidgeCount,
      endRidgeThreeCount: geometryDataState?.endRidgeThreeCount,
      initialRidgeThreeCount: geometryDataState?.initialRidgeThreeCount,
      ridgeFourCount: geometryDataState?.ridgeFourCount,
      startAngledRidgeCount: geometryDataState?.startAngledRidgeCount,
    };

    const roofAccessoriesData: IRoofAccessory = {
      roofStepCount: roofAccessories.roofStep,
      chimneySweepBench200Count: roofAccessories.chimneySweepBench200,
      chimneySweepBench300Count: roofAccessories.chimneySweepBench300,
      chimneySweepBench40Count: roofAccessories.chimneySweepBench40,
      chimneySweepBench80Count: roofAccessories.chimneySweepBench80,
      snowFence200Count: roofAccessories.snowFence200,
      snowFence300Count: roofAccessories.snowFence300,
      roofVent100Count: roofAccessories.roofVent100,
      roofVent125Count: roofAccessories.roofVent125,
      roofVent150Count: roofAccessories.roofVent150,
    };

    const data: IResultRoofRequest = {
      isNewInvestment: firstCalc,
      data: JSON.stringify(appState), // Tu zapisać cały sta REDUX
      isCalculatedAutomatically: false,
      roofingType: roofingType,
      roofSystemId: calcMaterialsData.roofSystemId as string,
      gutterSystemId: calcMaterialsData.gutterSystemId as string,
      preCoveringSystemId: calcMaterialsData.preCoveringSystemId as string,
      fasteningSystemId: calcMaterialsData.fasteningSystemId as string,
      geometryData: geometryData,
      roofAccessoriesData: roofAccessoriesData,
      windowHoles: windowHoles,
      gutteringData: gutteringData,
      metalSheets: metalSheets,
      planes: planes,
      edges: edges,
      points: rsoPoints,
      eurValue: currencyValue.tempEur || 1,
      additionalProducts: additionalProducts || [],
    };

    // TODO

    client
      .post("/Results/calculateMaterial", data)
      .then((response) => {
        if (response.status == 200) {
          const investment: IInvestment = response.data.investment;
          const resultItems: IResultItemResponse[] = response.data.resultItems;

          console.log("ZAPYTANIE O OFERTĘ ODPWOIEDŹ Z API", response.data);

          const result: IResultItemResponse[] = [];

          if (resultItems) {
            for (const item of resultItems) {
              const product = getState().products?.find((x) => x.id === item.productId);
              const company = getState().companies?.find((x) => x.id === product?.companyId);
              const color = getState().productColors?.find((x) => x.id === product?.productColorId);
              const category = getState().productCategories?.find((x) => x.id === product?.productCategoryId);

              // const netValue = Number((item.amount * item.price).toFixed(2));
              // const grossValue = Number((netValue * (1 + DEFAULT_VAT / 100)).toFixed(2));

              const name = item.productName
                ? item.productName
                : `${product?.name} ${company?.name} (${color?.name})`;

              if (!product && !company && !color && !category) {
                const obj: IResultItemResponse = {
                  id: item.id,
                  active: true,
                  productId: item.productId,
                  categoryCode: item.productCategoryCode || "others",
                  name: name,
                  amount: item.amount,
                  unit: item.salesFormName || "Sztuka",
                  price: item.price,
                  salesFormCurrency: item.salesFormCurrency || "PLN",
                  salesFormName: item.salesFormName || "Sztuka",
                  discount: 0,
                  discountPart: "nett",
                  discountType: "%",
                };
                result.push(obj);
              } else {
                const obj: IResultItemResponse = {
                  id: item.id,
                  active: true,
                  productId: item.productId,
                  categoryCode: category?.code as string,
                  name: name,
                  amount: item.amount,
                  unit: item.salesFormName || "Sztuka",
                  price: item.price,
                  salesFormCurrency: item.salesFormCurrency || "PLN",
                  salesFormName: item.salesFormName || "Sztuka",
                  discount: 0,
                  discountPart: "nett",
                  discountType: "%",
                };
                result.push(obj);
              }
            }
          }

          // Zapisane dodatkowe produkty
          const savedAdditionalProducts = state.savedAdditionalProducts;
          // console.log("SAVED ADDITIONAL PRODUCTS", savedAdditionalProducts);
          if (savedAdditionalProducts && savedAdditionalProducts.length > 0) {
            for (const item of savedAdditionalProducts) {
              result.push(item as IResultItemResponse);
            }
          }

          // // Dodanie okien do listy produktów
          // for (const w of windows) {
          //   result.push(w);
          // }

          // TOD przenieść też do API
          // Dodanie ellementów dodatkowych do listy produktów
          for (const ae of additionalElements) {
            result.push(ae);
          }

          if (firstCalc) {
            dispatch(addInvestmentInReducer(investment)); // Dodanie inwestycji na listę
            dispatch(setInvestmentIdGeneral(investment.id)); // Zapisanie aktywnego id dl ainwestycji do bierzących zapisów raporów
          }

          // Wyłączam reset globalnego rabatu po przeliczeniu
          // dispatch(resetOfferDiscountResultItem());

          // Wczytanie zapisanych rabatów grupowych
          const groupDiscounts = state.groupDiscounts;

          const discountGroups: IDiscountGroupCalc[] = [
            {
              code: "basic-products",
              discount: groupDiscounts.basicProductsDiscountValue,
              discountType: groupDiscounts.basicProductsDiscountType,
              discountPart: groupDiscounts.basicProductsDiscountPart,
            },
            {
              code: "roofing-accessories",
              discount: groupDiscounts.roofingAccessoriesDiscountValue,
              discountType: groupDiscounts.roofingAccessoriesDiscountType,
              discountPart: groupDiscounts.roofingAccessoriesDiscountPart,
            },
            {
              code: "preliminary-covering",
              discount: groupDiscounts.preliminaryCoveringDiscountValue,
              discountType: groupDiscounts.preliminaryCoveringDiscountType,
              discountPart: groupDiscounts.preliminaryCoveringDiscountPart,
            },
            {
              code: "wood",
              discount: groupDiscounts.woodDiscountValue,
              discountType: groupDiscounts.woodDiscountType,
              discountPart: groupDiscounts.woodDiscountPart,
            },
            {
              code: "roof-walkways-and-snow-guards",
              discount: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountValue,
              discountType: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountType,
              discountPart: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountPart,
            },
            {
              code: "guttering",
              discount: groupDiscounts.gutteringDiscountValue,
              discountType: groupDiscounts.gutteringDiscountType,
              discountPart: groupDiscounts.gutteringDiscountPart,
            },
            {
              code: "fasteners",
              discount: groupDiscounts.fastenersDiscountValue,
              discountType: groupDiscounts.fastenersDiscountType,
              discountPart: groupDiscounts.fastenersDiscountPart,
            },
            {
              code: "windows",
              discount: groupDiscounts.windowsDiscountValue,
              discountType: groupDiscounts.windowsDiscountType,
              discountPart: groupDiscounts.windowsDiscountPart,
            },
            {
              code: "thermal-insulation",
              discount: groupDiscounts.thermalInsulationDiscountValue,
              discountType: groupDiscounts.thermalInsulationDiscountType,
              discountPart: groupDiscounts.thermalInsulationDiscountPart,
            },
            {
              code: "lightning-protection-systems",
              discount: groupDiscounts.lightningProtectionSystemsDiscountValue,
              discountType: groupDiscounts.lightningProtectionSystemsDiscountType,
              discountPart: groupDiscounts.lightningProtectionSystemsDiscountPart,
            },
            {
              code: "supplementary-products",
              discount: groupDiscounts.supplementaryProductsDiscountValue,
              discountType: groupDiscounts.supplementaryProductsDiscountType,
              discountPart: groupDiscounts.supplementaryProductsDiscountPart,
            },
            {
              code: "others",
              discount: groupDiscounts.othersDiscountValue,
              discountType: groupDiscounts.othersDiscountType,
              discountPart: groupDiscounts.othersDiscountPart,
            },
            {
              code: "labour",
              discount: groupDiscounts.labourDiscountValue,
              discountType: groupDiscounts.labourDiscountType,
              discountPart: groupDiscounts.labourDiscountPart,
            },
          ];

          const discountCategories = [
            {
              codes: [
                "basic-products-sheet-metal-standing-seam",
                "basic-products-cut-sheet-metal",
                "basic-products-tiles-ceramic",
                "basic-products-sheet-metal-trapezoidal",
                "basic-products-sheet-metal",
                "basic-products-tiles-concrete",
              ],
              discount: groupDiscounts.basicProductsDiscountValue,
              discountType: groupDiscounts.basicProductsDiscountType,
              discountPart: groupDiscounts.basicProductsDiscountPart,
            },
            {
              codes: ["roofing-accessories"],
              discount: groupDiscounts.roofingAccessoriesDiscountValue,
              discountType: groupDiscounts.roofingAccessoriesDiscountType,
              discountPart: groupDiscounts.roofingAccessoriesDiscountPart,
            },
            {
              codes: ["preliminary-covering"],
              discount: groupDiscounts.preliminaryCoveringDiscountValue,
              discountType: groupDiscounts.preliminaryCoveringDiscountType,
              discountPart: groupDiscounts.preliminaryCoveringDiscountPart,
            },
            {
              codes: ["wood"],
              discount: groupDiscounts.woodDiscountValue,
              discountType: groupDiscounts.woodDiscountType,
              discountPart: groupDiscounts.woodDiscountPart,
            },
            {
              codes: ["roof-walkways-and-snow-guards"],
              discount: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountValue,
              discountType: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountType,
              discountPart: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountPart,
            },
            {
              codes: ["guttering"],
              discount: groupDiscounts.gutteringDiscountValue,
              discountType: groupDiscounts.gutteringDiscountType,
              discountPart: groupDiscounts.gutteringDiscountPart,
            },
            {
              codes: ["fasteners"],
              discount: groupDiscounts.fastenersDiscountValue,
              discountType: groupDiscounts.fastenersDiscountType,
              discountPart: groupDiscounts.fastenersDiscountPart,
            },
            {
              codes: ["windows", "window-collars-and-accessories"],
              discount: groupDiscounts.windowsDiscountValue,
              discountType: groupDiscounts.windowsDiscountType,
              discountPart: groupDiscounts.windowsDiscountPart,
            },
            {
              codes: ["thermal-insulation"],
              discount: groupDiscounts.thermalInsulationDiscountValue,
              discountType: groupDiscounts.thermalInsulationDiscountType,
              discountPart: groupDiscounts.thermalInsulationDiscountPart,
            },
            {
              codes: ["lightning-protection-systems"],
              discount: groupDiscounts.lightningProtectionSystemsDiscountValue,
              discountType: groupDiscounts.lightningProtectionSystemsDiscountType,
              discountPart: groupDiscounts.lightningProtectionSystemsDiscountPart,
            },
            {
              codes: ["supplementary-products"],
              discount: groupDiscounts.supplementaryProductsDiscountValue,
              discountType: groupDiscounts.supplementaryProductsDiscountType,
              discountPart: groupDiscounts.supplementaryProductsDiscountPart,
            },
            {
              codes: ["others"],
              discount: groupDiscounts.othersDiscountValue,
              discountType: groupDiscounts.othersDiscountType,
              discountPart: groupDiscounts.othersDiscountPart,
            },
            {
              codes: ["labour"],
              discount: groupDiscounts.labourDiscountValue,
              discountType: groupDiscounts.labourDiscountType,
              discountPart: groupDiscounts.labourDiscountPart,
            },
          ];

          // Załadowanie zapisanych rabatów grupowych
          dispatch(addOrUpdateDiscountGroupsCalc(discountGroups));

          // Pętla po produktach w result i jeśli są rabaty na produkt w grupie to przypisanie
          for (let i = 0; i < result.length; i++) {
            const resultItem = result[i];

            for (let j = 0; j < discountCategories.length - 1; j++) {
              const resultCategory = resultItem.categoryCode;

              if (discountCategories[j].codes.includes(resultCategory)) {
                // Tworzenie nowego obiektu z aktualizowanymi właściwościami
                result[i] = {
                  ...resultItem,
                  discount: discountCategories[j].discount,
                  discountType: discountCategories[j].discountType,
                  discountPart: discountCategories[j].discountPart,
                };
                break; // Zakładamy, że jedno dopasowanie wystarczy
              }
            }
          }

          dispatch(setCalcResultItems(result));
          dispatch(updateStatusCalcResult({ ...calcResult, isCalculated: true }));

          // Obliczanie wyceny ***********************************************************

          const currentState = getState();

          const model = getDataForPrincing(calcMaterialsData.contractorId, currentState);
          // console.log("DATA FOR PRINCING", model);

          if (model.princingSystemId) {
            dispatch(
              getPrincingResult(model, (data: any) => {
                for (const element of data.elements) {
                  let model: IAddProduct = {
                    name: element.name,
                    ean: "",
                    active: true,
                    coating: "",
                    effectiveWidth: 0,
                    lowerZipperLength: 0,
                    upperZipperLength: 0,
                    material: "",
                    maxLength: 0,
                    minLength: 0,
                    moduleCount: 0,
                    moduleLength: 0,
                    producerId: "",
                    shelfLife: "",
                    warranty: "",
                    storageTemperature: 0,
                    companyId: undefined,
                    externalId: "",
                    productCategoryId: productCategories.find((x) => x.code === "labour")?.id as string,
                    productColorId: productColors.find((x) => x.name === "Brak")?.id as string,
                    productDiscount: 0,
                    stockQuantity: 0,
                    width: 0,
                    length: 0,
                    weight: 0,
                    capacity: 0,
                    diameter: 0,
                    height: 0,
                    productModelId: "",
                    salesForms: [],
                    use: 0,
                    calculationUnit: "szt.",
                  };

                  // WAŻNE ->
                  // Tu trzeba dodac listę produktów
                  // Czy mamy funkcj, która doda kilka produktów na raz? w API
                  // TEraz dodajemy wszystko w pętli
                  dispatch(addProductToOrder(model, 1));
                }
              })
            );
          }

          // END Obliczanie wyceny ***********************************************************

          dispatch(endLoading());
        }
      })
      .catch((error) => {
        dispatch(endLoading());
      });
  };

// Funckja obliczająca dach z kalkulatora
export const calculateOfferCalculator =
  (firstCalc: boolean): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    dispatch(startLoading());

    const state = getState();

    const calcMaterialsData = state.calcMaterialsData;
    const scale = state.drawingScale;
    const roofPoints = state.roofPoints;
    const roofEdges = state.roofEdges;
    const roofPlanes = state.roofPlanes;
    const calcResult = state.calcResult;
    // const windows = state.windows;
    const windowHoles = state.windowHoles;
    const additionalElements = state.additionalElements;
    const productCategories = state.productCategories;
    const productColors = state.productColors;
    const priceUnits = state.priceUnits;
    const gutteringElements = state.gutteringElements;
    const roofAccessories = state.roofAccessories;
    const currencyValue = state.currencyValue;
    const additionalProducts = state.additionalProducts;

    const points: IResultPointRequest[] = [];
    for (const p of roofPoints) {
      let rPoint: IResultPointRequest = {
        id: p.id,
        calcId: p.calcId,
        x: p.x,
        y: p.y,
        z: p.z,
      };
      points.push(rPoint);
    }

    const planes: IResultPlaneRequest[] = [];
    for (const plane of roofPlanes) {
      let rPlane: IResultPlaneRequest = {
        id: plane.id,
        slope: plane.angle,
        area: plane.area / scale ** 2,
      };
      planes.push(rPlane);
    }

    // Przygotowanie Planes do obliczeń
    const edges: IResultEdgeRequest[] = [];
    for (const edge of roofEdges) {
      const s = roofPoints.find((x) => x.id === edge.startPointId);
      const e = roofPoints.find((x) => x.id === edge.endPointId);

      const sn = { x: s?.x as number, y: s?.y as number, z: s?.z as number };
      const en = { x: e?.x as number, y: e?.y as number, z: e?.z as number };

      let rEdge: IResultEdgeRequest = {
        id: edge.id,
        type: edge.type as number,
        slope: calculateEdgeSlope(sn, en),
        length: getTwoPoints3DLength(sn, en, scale),
        startPointId: edge.startPointId,
        endPointId: edge.endPointId,
      };
      edges.push(rEdge);
    }

    // Dane orynnowania idą z listy elementow dodanych dla auto
    const gutteringData: IGutteringData = {
      eaveOverhang: calcMaterialsData.eaveOverhang,
      wallHeight: calcMaterialsData.wallHeight,
      drainPipeCount: gutteringElements.gutterDrainPipes.length,
      internalCornerCount: gutteringElements.gutterInternalCorners.length,
      outerCornerCount: gutteringElements.gutterExternalCorners.length,
      leftCapCount: gutteringElements.gutterEndLefts.length,
      rightCapCount: gutteringElements.gutterEndRights.length,
    };

    const appState = getStateToSave(getState()); // stan reduxa

    let roofingType = 0; // Dla dachówki
    // Dachówka czy blacha
    // Jełsi systemType > 0 to jets blacha na dachu
    if (calcMaterialsData.systemType > 0) {
      roofingType = 1; // 0 dachówka, 1 - blacha
    }

    let metalSheets: IMetalSheet[] = [];

    if (roofingType === 1) {
      metalSheets = state.metalSheets.map((sheet) => ({
        ...sheet,
        width: sheet.width / scale,
        height: sheet.height / scale,
      }));
    }

    const geometryData: IGeometryData = {
      startHorizontalRidgeCount: 0,
      endHorizontalRidgeCount: 0,
      endAngledRidgeCount: 0,
      endRidgeThreeCount: 0,
      initialRidgeThreeCount: 0,
      ridgeFourCount: 0,
      startAngledRidgeCount: 0,
    };

    const roofAccessoriesData: IRoofAccessory = {
      roofStepCount: roofAccessories.roofStep,
      chimneySweepBench200Count: roofAccessories.chimneySweepBench200,
      chimneySweepBench300Count: roofAccessories.chimneySweepBench300,
      chimneySweepBench40Count: roofAccessories.chimneySweepBench40,
      chimneySweepBench80Count: roofAccessories.chimneySweepBench80,
      snowFence200Count: roofAccessories.snowFence200,
      snowFence300Count: roofAccessories.snowFence300,
      roofVent100Count: roofAccessories.roofVent100,
      roofVent125Count: roofAccessories.roofVent125,
      roofVent150Count: roofAccessories.roofVent150,
    };

    const data: IResultRoofRequest = {
      isNewInvestment: firstCalc,
      data: JSON.stringify(appState), // Tu zapisać cały sta REDUX
      isCalculatedAutomatically: true,
      roofingType: roofingType,
      roofSystemId: calcMaterialsData.roofSystemId as string,
      gutterSystemId: calcMaterialsData.gutterSystemId as string,
      preCoveringSystemId: calcMaterialsData.preCoveringSystemId as string,
      fasteningSystemId: calcMaterialsData.fasteningSystemId as string,
      geometryData: geometryData,
      roofAccessoriesData: roofAccessoriesData,
      windowHoles: windowHoles,
      gutteringData: gutteringData,
      metalSheets: metalSheets,
      planes: planes,
      edges: edges,
      points: points,
      eurValue: currencyValue.tempEur || 1,
      additionalProducts: additionalProducts || [],
    };

    console.log("ZAPYTANIE O OFERTĘ MODEL", data);

    client
      .post("/Results/calculateMaterial", data)
      .then((response) => {
        if (response.status == 200) {
          const investment: IInvestment = response.data.investment;
          const resultItems: IResultItemResponse[] = response.data.resultItems;

          console.log("ZAPYTANIE O OFERTĘ ODPWOIEDŹ Z API", response.data);

          const result: IResultItemResponse[] = [];

          if (resultItems) {
            for (const item of resultItems) {
              const product = getState().products?.find((x) => x.id === item.productId);
              const company = getState().companies?.find((x) => x.id === product?.companyId);
              const color = getState().productColors?.find((x) => x.id === product?.productColorId);
              const category = getState().productCategories?.find((x) => x.id === product?.productCategoryId);

              // const netValue = Number((item.amount * item.price).toFixed(2));
              // const grossValue = Number((netValue * (1 + DEFAULT_VAT / 100)).toFixed(2));

              const name = item.productName
                ? item.productName
                : `${product?.name} ${company?.name} (${color?.name})`;

              if (!product && !company && !color && !category) {
                const obj: IResultItemResponse = {
                  id: item.id,
                  active: true,
                  productId: item.productId,
                  categoryCode: item.productCategoryCode || "others",
                  name: name,
                  amount: item.amount,
                  unit: item.unit || "Sztuka",
                  price: item.price,
                  salesFormCurrency: item.salesFormCurrency || "PLN",
                  salesFormName: item.salesFormName || "Sztuka",
                  discount: 0,
                  discountPart: "nett",
                  discountType: "%",
                };
                result.push(obj);
              } else {
                const obj: IResultItemResponse = {
                  id: item.id,
                  active: true,
                  productId: item.productId,
                  categoryCode: category?.code as string,
                  name: name,
                  amount: item.amount,
                  unit: item.salesFormName || "Sztuka",
                  price: item.price,
                  salesFormCurrency: item.salesFormCurrency || "PLN",
                  salesFormName: item.salesFormName || "Sztuka",
                  discount: 0,
                  discountPart: "nett",
                  discountType: "%",
                };
                result.push(obj);
              }
            }
          }

          // Zapisane dodatkowe produkty
          const savedAdditionalProducts = state.savedAdditionalProducts;
          // console.log("SAVED ADDITIONAL PRODUCTS", savedAdditionalProducts);
          if (savedAdditionalProducts && savedAdditionalProducts.length > 0) {
            for (const item of savedAdditionalProducts) {
              result.push(item as IResultItemResponse);
            }
          }

          // const savedAdditionalProducts = state.savedAdditionalProducts;
          // console.log("SAVED ADDITIONAL PRODUCTS", savedAdditionalProducts);
          // if (savedAdditionalProducts && savedAdditionalProducts.length > 0) {
          //   for (const item of savedAdditionalProducts) {
          //     console.log("SAVED ITEM", item as IResultItemResponse);
          //     const obj = item as IResultItemResponse;
          //     result.push(obj);
          //   }
          // }

          // TODO przenieśc do API
          // Dodanie ellementów dodatkowych do listy produktów
          for (const ae of additionalElements) {
            result.push(ae);
          }

          if (firstCalc) {
            dispatch(addInvestmentInReducer(investment)); // Dodanie inwestycji na listę
            dispatch(setInvestmentIdGeneral(investment.id)); // Zapisanie aktywnego id dla inwestycji do bierzących zapisów raporów
          }

          // Wyłączam czyszczenie globalnego rabatu
          // dispatch(resetOfferDiscountResultItem());

          // Wczytanie zapisanych rabatów grupowych
          const groupDiscounts = state.groupDiscounts;

          const discountGroups: IDiscountGroupCalc[] = [
            {
              code: "basic-products",
              discount: groupDiscounts.basicProductsDiscountValue,
              discountType: groupDiscounts.basicProductsDiscountType,
              discountPart: groupDiscounts.basicProductsDiscountPart,
            },
            {
              code: "roofing-accessories",
              discount: groupDiscounts.roofingAccessoriesDiscountValue,
              discountType: groupDiscounts.roofingAccessoriesDiscountType,
              discountPart: groupDiscounts.roofingAccessoriesDiscountPart,
            },
            {
              code: "preliminary-covering",
              discount: groupDiscounts.preliminaryCoveringDiscountValue,
              discountType: groupDiscounts.preliminaryCoveringDiscountType,
              discountPart: groupDiscounts.preliminaryCoveringDiscountPart,
            },
            {
              code: "wood",
              discount: groupDiscounts.woodDiscountValue,
              discountType: groupDiscounts.woodDiscountType,
              discountPart: groupDiscounts.woodDiscountPart,
            },
            {
              code: "roof-walkways-and-snow-guards",
              discount: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountValue,
              discountType: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountType,
              discountPart: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountPart,
            },
            {
              code: "guttering",
              discount: groupDiscounts.gutteringDiscountValue,
              discountType: groupDiscounts.gutteringDiscountType,
              discountPart: groupDiscounts.gutteringDiscountPart,
            },
            {
              code: "fasteners",
              discount: groupDiscounts.fastenersDiscountValue,
              discountType: groupDiscounts.fastenersDiscountType,
              discountPart: groupDiscounts.fastenersDiscountPart,
            },
            {
              code: "windows",
              discount: groupDiscounts.windowsDiscountValue,
              discountType: groupDiscounts.windowsDiscountType,
              discountPart: groupDiscounts.windowsDiscountPart,
            },
            {
              code: "thermal-insulation",
              discount: groupDiscounts.thermalInsulationDiscountValue,
              discountType: groupDiscounts.thermalInsulationDiscountType,
              discountPart: groupDiscounts.thermalInsulationDiscountPart,
            },
            {
              code: "lightning-protection-systems",
              discount: groupDiscounts.lightningProtectionSystemsDiscountValue,
              discountType: groupDiscounts.lightningProtectionSystemsDiscountType,
              discountPart: groupDiscounts.lightningProtectionSystemsDiscountPart,
            },
            {
              code: "supplementary-products",
              discount: groupDiscounts.supplementaryProductsDiscountValue,
              discountType: groupDiscounts.supplementaryProductsDiscountType,
              discountPart: groupDiscounts.supplementaryProductsDiscountPart,
            },
            {
              code: "others",
              discount: groupDiscounts.othersDiscountValue,
              discountType: groupDiscounts.othersDiscountType,
              discountPart: groupDiscounts.othersDiscountPart,
            },
            {
              code: "labour",
              discount: groupDiscounts.labourDiscountValue,
              discountType: groupDiscounts.labourDiscountType,
              discountPart: groupDiscounts.labourDiscountPart,
            },
          ];

          const discountCategories = [
            {
              codes: [
                "basic-products-sheet-metal-standing-seam",
                "basic-products-cut-sheet-metal",
                "basic-products-tiles-ceramic",
                "basic-products-sheet-metal-trapezoidal",
                "basic-products-sheet-metal",
                "basic-products-tiles-concrete",
              ],
              discount: groupDiscounts.basicProductsDiscountValue,
              discountType: groupDiscounts.basicProductsDiscountType,
              discountPart: groupDiscounts.basicProductsDiscountPart,
            },
            {
              codes: ["roofing-accessories"],
              discount: groupDiscounts.roofingAccessoriesDiscountValue,
              discountType: groupDiscounts.roofingAccessoriesDiscountType,
              discountPart: groupDiscounts.roofingAccessoriesDiscountPart,
            },
            {
              codes: ["preliminary-covering"],
              discount: groupDiscounts.preliminaryCoveringDiscountValue,
              discountType: groupDiscounts.preliminaryCoveringDiscountType,
              discountPart: groupDiscounts.preliminaryCoveringDiscountPart,
            },
            {
              codes: ["wood"],
              discount: groupDiscounts.woodDiscountValue,
              discountType: groupDiscounts.woodDiscountType,
              discountPart: groupDiscounts.woodDiscountPart,
            },
            {
              codes: ["roof-walkways-and-snow-guards"],
              discount: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountValue,
              discountType: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountType,
              discountPart: groupDiscounts.roofWalkwaysAndSnowGuardsDiscountPart,
            },
            {
              codes: ["guttering"],
              discount: groupDiscounts.gutteringDiscountValue,
              discountType: groupDiscounts.gutteringDiscountType,
              discountPart: groupDiscounts.gutteringDiscountPart,
            },
            {
              codes: ["fasteners"],
              discount: groupDiscounts.fastenersDiscountValue,
              discountType: groupDiscounts.fastenersDiscountType,
              discountPart: groupDiscounts.fastenersDiscountPart,
            },
            {
              codes: ["windows", "window-collars-and-accessories"],
              discount: groupDiscounts.windowsDiscountValue,
              discountType: groupDiscounts.windowsDiscountType,
              discountPart: groupDiscounts.windowsDiscountPart,
            },
            {
              codes: ["thermal-insulation"],
              discount: groupDiscounts.thermalInsulationDiscountValue,
              discountType: groupDiscounts.thermalInsulationDiscountType,
              discountPart: groupDiscounts.thermalInsulationDiscountPart,
            },
            {
              codes: ["lightning-protection-systems"],
              discount: groupDiscounts.lightningProtectionSystemsDiscountValue,
              discountType: groupDiscounts.lightningProtectionSystemsDiscountType,
              discountPart: groupDiscounts.lightningProtectionSystemsDiscountPart,
            },
            {
              codes: ["supplementary-products"],
              discount: groupDiscounts.supplementaryProductsDiscountValue,
              discountType: groupDiscounts.supplementaryProductsDiscountType,
              discountPart: groupDiscounts.supplementaryProductsDiscountPart,
            },
            {
              codes: ["others"],
              discount: groupDiscounts.othersDiscountValue,
              discountType: groupDiscounts.othersDiscountType,
              discountPart: groupDiscounts.othersDiscountPart,
            },
            {
              codes: ["labour"],
              discount: groupDiscounts.labourDiscountValue,
              discountType: groupDiscounts.labourDiscountType,
              discountPart: groupDiscounts.labourDiscountPart,
            },
          ];

          // Załadowanie zapisanych rabatów grupowych
          dispatch(addOrUpdateDiscountGroupsCalc(discountGroups));

          // Pętla po produktach w result i jeśli są rabaty na produkt w grupie to przypisanie
          for (let i = 0; i < result.length; i++) {
            const resultItem = result[i];

            for (let j = 0; j < discountCategories.length - 1; j++) {
              const resultCategory = resultItem.categoryCode;

              if (discountCategories[j].codes.includes(resultCategory)) {
                // Tworzenie nowego obiektu z aktualizowanymi właściwościami
                result[i] = {
                  ...resultItem,
                  discount: discountCategories[j].discount,
                  discountType: discountCategories[j].discountType,
                  discountPart: discountCategories[j].discountPart,
                };
                break; // Zakładamy, że jedno dopasowanie wystarczy
              }
            }
          }

          // Sprawdzenie czy rabat na ofertę działa po przeładowaniu !!!!!!!!!!!!!!!!!!!!!!!!!!!

          // Załadowanie elementów oferty
          dispatch(setCalcResultItems(result));
          dispatch(updateStatusCalcResult({ ...calcResult, isCalculated: true }));

          // Obliczanie wyceny ***********************************************************
          const currentState = getState();

          const model = getDataForPrincing(calcMaterialsData.contractorId, currentState);
          // console.log("DATA FOR PRINCING", model);

          if (model.princingSystemId) {
            dispatch(
              getPrincingResult(model, (data: any) => {
                for (const element of data.elements) {
                  let model: IAddProduct = {
                    name: element.name,
                    ean: "",
                    active: true,
                    coating: "",
                    effectiveWidth: 0,
                    lowerZipperLength: 0,
                    upperZipperLength: 0,
                    material: "",
                    maxLength: 0,
                    minLength: 0,
                    moduleCount: 0,
                    moduleLength: 0,
                    producerId: "",
                    shelfLife: "",
                    warranty: "",
                    storageTemperature: 0,
                    companyId: undefined,
                    externalId: "",
                    productCategoryId: productCategories.find((x) => x.code === "labour")?.id as string,
                    productColorId: productColors.find((x) => x.name === "Brak")?.id as string,
                    productDiscount: 0,
                    stockQuantity: 0,
                    width: 0,
                    length: 0,
                    weight: 0,
                    capacity: 0,
                    diameter: 0,
                    height: 0,
                    productModelId: "",
                    salesForms: [],
                    use: 0,
                    calculationUnit: "szt.",
                  };

                  // WAŻNE ->
                  // Tu trzeba dodac listę produktów
                  // Czy mamy funkcj, która doda kilka produktów na raz? w API
                  // TEraz dodajemy wszystko w pętli
                  dispatch(addProductToOrder(model, 1));
                }
              })
            );
          }

          // END Obliczanie wyceny ***********************************************************

          dispatch(endLoading());
        }
      })
      .catch((error) => {
        console.error("Error calculating offer", error);
        dispatch(endLoading());
      });
  };

// *********************************************************
// API
// *********************************************************

// Add product to order
export const addProductToOrder =
  (data: IAddProduct, amount: number, asAdditionalProduct: boolean = false): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    // console.log("ADD PRODUCT TO ORDER", data);

    client
      .post("/Products", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addProductInReducer(response.data));
          // dispatch(addStatusApi({ key: "addProduct", type: "success", message: "" }));

          const product = getState().products.find((x) => x.id === response.data.id);
          const category = getState().productCategories.find((x) => x.id === product?.productCategoryId);

          let price = 0;

          if (product && product.salesForms && product.salesForms.length > 0) {
            price = product?.salesForms[0].price as number;
          }

          const model: IResultItemResponse = {
            id: uuidv4(),
            active: response.data.active as boolean,
            productId: response.data.id,
            categoryCode: category?.code as string,
            name: response.data.name,
            amount: amount,
            unit: "Sztuka",
            price: price,
            salesFormCurrency: "PLN",
            salesFormName: "Sztuka",
            discount: 0,
            discountType: "%",
            discountPart: "nett",
          };

          dispatch(addCalcResultItem(model));

          // Gdy jako dodatkowy produkt to zapisujemy go do listy zapisanych dodatkowych produktów - aby pojawił sie przy kolejnym przeliczeniu - głównie dla produktow na zamowienie
          if (asAdditionalProduct) {
            dispatch(addSavedAdditionalProductReducer(model));
          }
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
        // console.error(error);
      });
  };
