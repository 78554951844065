import React from "react";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import styled from "styled-components";
import CompanyPage from "../modules/admin/CompanyPage";
import UsersPage from "../modules/admin/UsersPage";
import HomePage from "../modules/home/HomePage";
import MaterialsPage from "../modules/materials/MaterialsPage";
import ProfilePage from "../modules/pages/profilePage/ProfilePage";
import GutterSystemsPage from "../modules/roofs/gutterSystem/GutterSystemsPage";
import RoofsPage from "../modules/roofs/RoofsPage";
import RoofSystemsPage from "../modules/roofs/roofSystems/RoofSystemsPage";
import ReportsPage from "../modules/pages/reportsPage/ReportsPage";
import CompaniesPage from "../modules/companies/CompaniesPage";
import ProductCategoryPage from "../modules/materials/ProductCategoryPage";
import AddProduct from "../modules/materials/AddProduct";
import EditProduct from "../modules/materials/EditProduct";
import AddTileSystemPage from "../modules/roofs/roofSystems/AddTileSystemPage";
import AddSteelSystemPage from "../modules/roofs/roofSystems/AddSteelSystemPage";
import EditTileSystemPage from "../modules/roofs/roofSystems/EditTileSystemPage";
import EditSteelSystemPage from "../modules/roofs/roofSystems/EditSteelSystemPage";
import AddGutterSystemPage from "../modules/roofs/gutterSystem/AddGutterSystemPage";
import EditGutterSystemPage from "../modules/roofs/gutterSystem/EditGutterSystemPage";
import ImportMaterialsPage from "../modules/pages/importMaterialsPage/ImportMaterialsPage";
import ContractorDataPage from "../modules/contractors/components/ContractorDataPage";
import ConfigurationContractorPriceList from "../modules/contractors/components/ConfigurationContractorPriceList";
import ContractorDataTransfer from "../modules/contractors/components/ContractorDataTransfer";
import EditContractorPrincingSystemPage from "../modules/contractors/EditContractorPrincingSystemPage";
import AddContractorPrincingSystemPage from "../modules/contractors/AddContractorPrincingSystemPage";
import ConfigurationContractor from "../modules/contractors/components/ConfigurationContractor";
import ContractorsPage from "../modules/contractors/components/ContractorsPage";
import ImportProductsFromExcel from "../modules/pages/importMaterialsPage/ImportProductsFromExcel";
import Header from "./Header";
import { useAppSelector } from "../redux/hooks";
import PreCoveringSystemsPage from "../modules/roofs/preCoveringSystems/PreCoveringSystemsPage";
import AddPreCoveringSystemPage from "../modules/roofs/preCoveringSystems/AddPreCoveringSystemPage";
import EditPreCoveringSystemPage from "../modules/roofs/preCoveringSystems/EditPreCoveringSystemPage";
import FasteningSystemsPage from "../modules/roofs/fasteningSystems/FasteningSystemsPage";
import AddFasteningSystemPage from "../modules/roofs/fasteningSystems/AddFasteningSystemPage";
import EditFasteningSystemPage from "../modules/roofs/fasteningSystems/EditFasteningSystemPage";
import WindowIntegrationPage from "../modules/roofs/windowIntegration/WindowIntegrationPage";
import ProductsGroupsPage from "../modules/roofs/productsGroups/ProductsGroupsPage";
import AddProductsGroupsPage from "../modules/roofs/productsGroups/AddProductsGroupsPage";
import EditProductsGroupsPage from "../modules/roofs/productsGroups/EditProductsGroupsPage";
import TechnicalEquipmentsGroupsPage from "../modules/roofs/technicalEquipmentsGroups/TechnicalEquipmentsGroupsPage";
import AddTechnicalEquipmentsGroupsPage from "../modules/roofs/technicalEquipmentsGroups/AddTechnicalEquipmentsGroupsPage";
import EditTechnicalEquipmentsGroupsPage from "../modules/roofs/technicalEquipmentsGroups/EditTechnicalEquipmentsGroupsPage";
import ImportUpdateProductsFromExcel from "../modules/pages/importMaterialsPage/ImportUpdateProductsFromExcel";

const getErrorComponent = () => {
  return <div>Wystąpił błąd lub strona nie istnieje</div>;
};

export default function Content({ isAdmin, isModerator }) {
  return (
    <Container>
      <Header isAdmin={isAdmin} isModerator={isModerator} />

      <Wrap>
        <Routes>
          <Route path="/" element={<HomePage />} exact />
          <Route path="/dachy" element={<RoofsPage />} exact />
          <Route path="/materialy" element={<MaterialsPage />} exact />
          <Route
            path="/materialy/:productCategoryCode"
            element={<ProductCategoryPage errorElement={getErrorComponent()} />}
          />
          <Route
            path="/materialy/:productCategoryCode/dodaj"
            element={<AddProduct />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/materialy/:productId/edytuj"
            element={<EditProduct />}
            errorElement={getErrorComponent()}
          />

          <Route path="/systemy-dachowe" element={<RoofSystemsPage />} exact />
          <Route
            path="/systemy-dachowe/dachowka/dodaj"
            element={<AddTileSystemPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/systemy-dachowe/dachowka/:tileSystemId/edytuj"
            element={<EditTileSystemPage />}
            errorElement={getErrorComponent()}
          />

          <Route
            path="/systemy-dachowe/blachodachowka/dodaj"
            element={<AddSteelSystemPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/systemy-dachowe/blachodachowka/:steelSystemId/edytuj"
            element={<EditSteelSystemPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/systemy-rynnowe" element={<GutterSystemsPage />} exact />
          <Route
            path="/systemy-rynnowe/dodaj"
            element={<AddGutterSystemPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/systemy-rynnowe/:gutterSystemId/edytuj"
            element={<EditGutterSystemPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/systemy-krycia-wstepnego" element={<PreCoveringSystemsPage />} exact />
          <Route
            path="/systemy-krycia-wstepnego/dodaj"
            element={<AddPreCoveringSystemPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/systemy-krycia-wstepnego/:preCoveringSystemId/edytuj"
            element={<EditPreCoveringSystemPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/systemy-zamocowan" element={<FasteningSystemsPage />} exact />
          <Route
            path="/systemy-zamocowan/dodaj"
            element={<AddFasteningSystemPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/systemy-zamocowan/:fasteningSystemId/edytuj"
            element={<EditFasteningSystemPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/grupy-produktowe" element={<ProductsGroupsPage />} exact />
          <Route
            path="/grupy-produktowe/dodaj"
            element={<AddProductsGroupsPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/grupy-produktowe/:groupId/edytuj"
            element={<EditProductsGroupsPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/grupy-wyposazenie-techniczne" element={<TechnicalEquipmentsGroupsPage />} exact />
          <Route
            path="/grupy-wyposazenie-techniczne/dodaj"
            element={<AddTechnicalEquipmentsGroupsPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/grupy-wyposazenie-techniczne/:groupId/edytuj"
            element={<EditTechnicalEquipmentsGroupsPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/integracja-okien" element={<WindowIntegrationPage />} exact />

          <Route path="/uzytkownicy" element={<UsersPage />} exact />
          <Route path="/firma" element={<CompanyPage />} exact />
          <Route path="/profil" element={<ProfilePage />} exact />
          <Route path="/oferty" element={<ReportsPage />} exact />
          <Route path="/producenci" element={<CompaniesPage />} exact />

          <Route path="/import-materialow" element={<ImportMaterialsPage />} exact />

          <Route path="/import-materialow/z-pliku" element={<ImportProductsFromExcel />} exact />
          <Route path="/import-aktualizacja-produktow/z-pliku" element={<ImportUpdateProductsFromExcel />} exact />

          <Route path="/wycena-robocizny" element={<RoofsPage calcLabour={true} />} exact />
          <Route path="/dane-wykonawcy" element={<ContractorDataPage />} exact />

          <Route path="/konfiguracja-cennika-wykonawcy" element={<ConfigurationContractorPriceList />} exact />

          <Route
            path="/konfiguracja-cennika-wykonawcy/dodaj"
            element={<AddContractorPrincingSystemPage />}
            errorElement={getErrorComponent()}
          />
          <Route
            path="/konfiguracja-cennika-wykonawcy/:systemId/edytuj"
            element={<EditContractorPrincingSystemPage />}
            errorElement={getErrorComponent()}
          />

          <Route path="/transfer-danych-wykonawcy" element={<ContractorDataTransfer />} exact />

          <Route path="/konfiguracja-wykonawcy" element={<ConfigurationContractor />} exact />

          <Route path="/wykonawcy" element={<ContractorsPage />} exact />
        </Routes>
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  width: calc(100% - 50px);
  min-height: calc(100vh - 51px);
  border-top: 1px solid #e0e0e0;

  margin-left: 0px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Wrap = styled.div`
  margin: 16px;
`;
