import { Col, Row } from "antd";
import React, { FC, useState } from "react";
import styled from "styled-components";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { IProductToUpdatePrice } from "./ImportUpdateProductsFromExcel";

type Props = {
  productToUpdate: IProductToUpdatePrice;
};

const UpdateProductListPosition: FC<Props> = ({ productToUpdate }) => {
  return (
    <Container key={productToUpdate.productId}>
      <Col span={6} style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
        {productToUpdate.productName}
      </Col>
      {productToUpdate.salesForms.map((salesForm, i) => (
        <Col span={6} key={i}>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              {salesForm.salesFormName}
            </Col>
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              <del>{salesForm.oldPrice}</del>
            </Col>
            <Col span={6} style={{ display: "flex", alignItems: "center" }}>
              <DomaInputNumber value={salesForm.newPrice} disabled />
            </Col>
          </Row>
        </Col>
      ))}
    </Container>
  );
};
export default UpdateProductListPosition;

const Container = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
