import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Group, Line, Text } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import { PRIMARY_COLOR, TEXT_COLOR } from "../../../../../theme";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import { getDistTwoPoints, middlePoint } from "../../../../../helpers/Helpers";
import { useFirstRender } from "../../../../../hooks/useFirstRender";
import { IPoint, IRoofPoint } from "../../../../../models/Models";
import { IOutlineEdge, IOutlinePoint, updateOutlineEdge } from "../../../../../redux/drawing/outlineSlice";
import {
  addSelectedEdgeToDrawRoofProcess,
  deleteEdgeFromSelectedToDrawRoofProcess,
  IRoofProcessFunctionsStatus,
} from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { IRoofProcessStatus } from "../../../../../redux/drawing/roofProcessStatusSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { saveDrawing } from "../../../../../redux/roofs/roofDrawingStoreSlice";
import store from "../../../../../redux/store";

type Props = {
  edge: IOutlineEdge;
  roofProcessStatus: IRoofProcessStatus;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  scaleZoom: number;
  scale: number;
  setShouldRecalculate: any;
  isEaveVector: boolean;
};

const OutlineEdge: FC<Props> = ({
  edge,
  roofProcessStatus,
  roofProcessFunctionsStatus,
  scaleZoom,
  scale,
  setShouldRecalculate,
  isEaveVector,
}) => {
  const dispatch = useAppDispatch();

  const firstRender = useFirstRender();

  const roofContourNumber = useAppSelector((state) => state.roofContourNumber);

  const outline = useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber.index));
  const outlinePoints =
    useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber.index))?.points || [];

  const [flatPoints, setFlatPoints] = useState<number[]>([]);
  const [hover, setHover] = useState<boolean>(false);

  const [slope, setSlope] = useState<number>(0);
  const [initialSlope, setInitialSlope] = useState<number>(0);

  const [eaveVector, setEaveVector] = useState<{ start: IOutlinePoint; end: IOutlinePoint }>();

  const inFunctionSelectManyEdges = roofProcessFunctionsStatus.inSelectManyEdges;

  useEffect(() => {
    if (outlinePoints && edge && edge.points.length >= 2) {
      const ps: IOutlinePoint[] = [];

      for (const p of edge.points) {
        ps.push(outlinePoints.find((o) => o.id === p) as IOutlinePoint);
      }

      const flattedPoints = ps.map((p) => [p.x, p.y]).flat();

      setFlatPoints(flattedPoints);

      const start = outlinePoints.find((o) => o.id === edge.points[0]);
      const end = outlinePoints.find((o) => o.id === edge.points[1]);

      setEaveVector({ start: start as IOutlinePoint, end: end as IOutlinePoint });
    }
  }, [outlinePoints, edge]);

  useEffect(() => {
    const slope = outline?.edges.find((o) => o.id === edge.id)?.slope as number;
    setSlope(slope);

    // Tylko wtedy gdy nie jest ścianą szcytową
    if (slope != 90 && slope != 200) {
      setInitialSlope(slope);
    }

    if (!firstRender) {
      setShouldRecalculate(true);
    }
  }, [outline]);

  const onClickEdge = () => {
    if (inFunctionSelectManyEdges) {
      if (roofProcessFunctionsStatus.selectedEdges.includes(edge.id)) {
        dispatch(deleteEdgeFromSelectedToDrawRoofProcess(edge.id));
      } else {
        dispatch(addSelectedEdgeToDrawRoofProcess(edge.id));
      }
      dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
    }
  };

  const setPointer = (e: any) => {
    e.target.getStage().container().style.cursor = "pointer";
  };

  const deletepointer = (e: any) => {
    e.target.getStage().container().style.cursor = "default";
  };

  const isSelectedEdge = () => {
    return roofProcessFunctionsStatus.selectedEdges.includes(edge.id);
  };

  const getPointById = (id: string) => {
    if (outlinePoints) {
      return outlinePoints.find((o) => o.id === id);
    }
  };

  const getCenterPoint = () => {
    const start = getPointById(edge?.points[0]);
    const end = getPointById(edge?.points[edge?.points.length - 1]);

    return middlePoint(start as IOutlinePoint, end as IOutlinePoint);
  };

  return (
    <Group>
      <Line
        points={flatPoints}
        closed={false}
        stroke={hover || isSelectedEdge() ? "#4300ebcc" : "#ff0000cc"}
        strokeWidth={isSelectedEdge() ? 7 : 4}
        onMouseEnter={(e) => {
          setPointer(e);
          setHover(true);
        }}
        onMouseLeave={(e) => {
          deletepointer(e);
          setHover(false);
        }}
        onClick={() => {
          onClickEdge();
        }}
      />

      {/* Okienka do zmiany kąta oraz wymiar */}
      {roofProcessFunctionsStatus.inDrawOutline === false && (
        <Group x={getCenterPoint().x} y={getCenterPoint().y} scale={{ x: 1 / scaleZoom, y: 1 / scaleZoom }}>
          {edge.slope != 90 && edge.slope != 200 ? (
            <Html>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background: "#f3f3f3",
                  padding: "4px",
                  borderRadius: "4px",
                  gap: "4px",
                }}
              >
                <Button
                  style={{ width: "100%", color: TEXT_COLOR }}
                  size="small"
                  type={
                    (roofProcessStatus.typeSlope === "degrees" && edge.slope == 90) ||
                    (roofProcessStatus.typeSlope !== "degrees" && edge.slope == 200)
                      ? "primary"
                      : "default"
                  }
                  onClick={() => {
                    if (
                      (roofProcessStatus.typeSlope === "degrees" && edge.slope == 90) ||
                      (roofProcessStatus.typeSlope !== "degrees" && edge.slope == 200)
                    ) {
                      dispatch(
                        updateOutlineEdge({
                          edge: { ...edge, slope: roofProcessStatus.typeSlope === "degrees" ? initialSlope : 100 },
                          roofNumber: roofContourNumber.index,
                        })
                      );
                    } else {
                      dispatch(
                        updateOutlineEdge({
                          edge: { ...edge, slope: roofProcessStatus.typeSlope === "degrees" ? 90 : 200 },
                          roofNumber: roofContourNumber.index,
                        })
                      );
                    }
                  }}
                >
                  Usuń połać
                </Button>
                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <div>{roofProcessStatus.typeSlope === "degrees" ? "α [°] =" : "α [%] ="}</div>
                  <CustomDomaInputNumber
                    value={slope}
                    onChange={(v) => {
                      setSlope(v as number);
                    }}
                    style={{
                      width: "100px",
                    }}
                    onStep={(e) => {
                      dispatch(
                        updateOutlineEdge({
                          edge: { ...edge, slope: e.valueOf() as number },
                          roofNumber: roofContourNumber.index,
                        })
                      );
                    }}
                    addonAfter={
                      <AddonAfter
                        onClick={() => {
                          dispatch(
                            updateOutlineEdge({
                              edge: { ...edge, slope: slope },
                              roofNumber: roofContourNumber.index,
                            })
                          );
                        }}
                        style={{ color: TEXT_COLOR }}
                      >
                        OK
                      </AddonAfter>
                    }
                  />
                </div>
              </div>
            </Html>
          ) : (
            <Html>
              <Button
                style={{ width: "100%" }}
                // size="small"
                onClick={() => {
                  dispatch(
                    updateOutlineEdge({
                      edge: { ...edge, slope: roofProcessStatus.typeSlope === "degrees" ? initialSlope : 100 },
                      roofNumber: roofContourNumber.index,
                    })
                  );
                }}
              >
                Dodaj połać
              </Button>
            </Html>
          )}
        </Group>
      )}

      {/* {getEaveComponent()} */}
    </Group>
  );
};
export default OutlineEdge;

const CustomDomaInputNumber = styled(DomaInputNumber)`
  .ant-input-number-group-addon {
    background-color: ${PRIMARY_COLOR}; // tutaj można ustawić inny kolor
  }
`;

const AddonAfter = styled.div`
  cursor: pointer;
`;
