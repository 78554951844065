import Konva from "konva";
import React, { FC, useEffect, useRef, useState } from "react";
import { Group } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSO, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { IBasicPoint } from "../../../../../../../models/Models";
import {
  getChimneySweepBenchIcon,
  getRoofStepIcon,
  getRoofVentIcon,
  getSnowFenceIcon,
} from "../../../../geometryRoofAccessories/RoofAccessoryElement";
import { saveDrawing } from "../../../../../../../redux/roofs/roofDrawingStoreSlice";
import {
  addChimneySweepBench200,
  addChimneySweepBench300,
  addChimneySweepBench40,
  addChimneySweepBench80,
  addRoofStep,
  addRoofVent100,
  addRoofVent125,
  addRoofVent150,
  addSnowFence200,
  addSnowFence300,
} from "../../../../../../../redux/roofs/roofAccessoriesSlice";
import store from "../../../../../../../redux/store";
import { invalidateCalculation } from "../../../../../../../redux/calculationProcess/calcResultSlice";

interface FunctionAddRoofAccessoryHandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  typeElementState:
    | null
    | "roofStep"
    | "chimneySweepBench40"
    | "chimneySweepBench80"
    | "chimneySweepBench200"
    | "chimneySweepBench300"
    | "snowFence200"
    | "snowFence300"
    | "roofVent100"
    | "roofVent125"
    | "roofVent150";
  eventClickStage: any;
  scale: number;
  zoomScale: number;
  closeFunction: any;
  activeRoofPlane?: IRSO | null;
}

// Funckja do dodawania otwworu okiennego
const FunctionAddRoofAccessoryHandler: FC<FunctionAddRoofAccessoryHandlerProps> = ({
  stageRef,
  point0Ref,
  typeElementState,
  activeRoofPlane,
  scale,
  zoomScale,
}) => {
  const dispatch = useAppDispatch();

  const rsoPlanes = useAppSelector((state) => state.rsoPlanes);

  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  const typeElementRef = useRef(typeElementState);
  const activeRoofPlaneRef = useRef(activeRoofPlane);

  const [accessoryPoint, setAccessoryPoint] = useState<IBasicPoint | null>(null);

  useEffect(() => {
    activeRoofPlaneRef.current = activeRoofPlane;
  }, [activeRoofPlane]);

  useEffect(() => {
    typeElementRef.current = typeElementState;
  }, [typeElementState]);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
  }, [mpLocal]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        e.evt.preventDefault();
        const mousePosition = point0Ref?.current?.getRelativePointerPosition() || { x: 0, y: 0 };

        const angle = getRotationAngle();

        // Wstawienie elementu orynnowania w wybranym miejscu czyli dodanie gdzieś do listy w reduxie która póxniej jest wyświetlana
        if (typeElementRef.current === "roofStep") {
          dispatch(addRoofStep({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: angle }));
        } else if (typeElementRef.current === "chimneySweepBench40") {
          dispatch(
            addChimneySweepBench40({
              id: uuidv4(),
              x: mousePosition.x,
              y: mousePosition.y,
              angle: angle,
            })
          );
        } else if (typeElementRef.current === "chimneySweepBench80") {
          dispatch(
            addChimneySweepBench80({
              id: uuidv4(),
              x: mousePosition.x,
              y: mousePosition.y,
              angle: angle,
            })
          );
        } else if (typeElementRef.current === "chimneySweepBench200") {
          dispatch(
            addChimneySweepBench200({
              id: uuidv4(),
              x: mousePosition.x,
              y: mousePosition.y,
              angle: angle,
            })
          );
        } else if (typeElementRef.current === "chimneySweepBench300") {
          dispatch(
            addChimneySweepBench300({
              id: uuidv4(),
              x: mousePosition.x,
              y: mousePosition.y,
              angle: angle,
            })
          );
        } else if (typeElementRef.current === "snowFence200") {
          dispatch(addSnowFence200({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: angle }));
        } else if (typeElementRef.current === "snowFence300") {
          dispatch(addSnowFence300({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: angle }));
        } else if (typeElementRef.current === "roofVent100") {
          dispatch(addRoofVent100({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: angle }));
        } else if (typeElementRef.current === "roofVent125") {
          dispatch(addRoofVent125({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: angle }));
        } else if (typeElementRef.current === "roofVent150") {
          dispatch(addRoofVent150({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: angle }));
        }

        dispatch(invalidateCalculation());

        dispatch(saveDrawing(store.getState()));
      } else if (e.evt.button === 2) {
        // console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const mousePointPosition = point0Ref?.current?.getRelativePointerPosition();

      if (e.target.hasName("rsoPlane")) {
        const planeId = e.target.id();

        if (planeId) {
          const selectedPlane = rsoPlanes.find((plane) => plane.id === planeId);
          if (selectedPlane) {
            let p = mousePointPosition as IBasicPoint;

            setAccessoryPoint(p);
          }
        }
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  const calculateAngle = (vector: { x: number; y: number }) => {
    const angleInRadians = Math.atan2(vector.y, vector.x); // Poprawiona kolejność argumentów
    const angleInDegrees = angleInRadians * (180 / Math.PI); // Konwersja z radianów na stopnie
    return angleInDegrees + 90;
  };

  const getRotationAngle = () => {
    if (activeRoofPlaneRef.current) {
      const angle = calculateAngle(activeRoofPlaneRef.current.declineVectoor);
      return angle;
    }
    return 0;
  };

  return (
    <Group x={accessoryPoint ? accessoryPoint?.x : 0} y={accessoryPoint ? accessoryPoint?.y : 0}>
      {typeElementRef.current === "roofStep" && getRoofStepIcon(scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "chimneySweepBench40" &&
        getChimneySweepBenchIcon(0.4, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "chimneySweepBench80" &&
        getChimneySweepBenchIcon(0.8, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "chimneySweepBench200" &&
        getChimneySweepBenchIcon(2, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "chimneySweepBench300" &&
        getChimneySweepBenchIcon(3, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "snowFence200" && getSnowFenceIcon(2, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "snowFence300" && getSnowFenceIcon(3, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "roofVent100" && getRoofVentIcon(0.1, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "roofVent125" && getRoofVentIcon(0.125, scale, zoomScale, getRotationAngle())}
      {typeElementRef.current === "roofVent150" && getRoofVentIcon(0.15, scale, zoomScale, getRotationAngle())}
    </Group>
  );
};

export default FunctionAddRoofAccessoryHandler;
