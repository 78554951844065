import { Alert, Button, Card, Col, Divider, Form, Input, message, Modal, Radio, Row, Select, Switch } from "antd";
import useForm from "antd/es/form/hooks/useForm";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DomaInputNumber from "../../../../helpers/DomaInputNumber";
import { createFilterOption } from "../../../../helpers/Helpers";
import {
  IAddTileSystem,
  IProduct,
  IProductCategory,
  IRootState,
  ISelectOption,
  ISheetMetalProcess,
  ITileSystem,
  ITileSystemAddition,
} from "../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteStatusApi } from "../../../../redux/other/statusApiSlice";
import { addTileSystem, updateTileSystem } from "../../../../redux/roofSystems/tileSystemsSlice";
import { v4 as uuidv4 } from "uuid";
import { EDGES_RELATIONS, TILE_SYSTEM_RELATIONS } from "../../RoofConstants";
import ExtraProductPosition from "./ExtraProductPosition";
import SheetMetalProcessPosition from "./SheetMetalProcessPosition";
import SystemImageComponent from "./SystemImageComponent";
import SystemConfigurationDetailsSection from "../../components/SystemConfigurationDetailsSection";
import { IProductsGroup } from "../../../../redux/productsGroups/productsGroupsSlice";
import { ITechnicalEquipmentGroup } from "../../../../redux/technicalEquipmentsGroups/technicalEquipmentsGroupsSlice";
import { PRIMARY_COLOR } from "../../../../theme";

type Props = {
  system: ITileSystem | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const TileSystemDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);
  const statusApi = useAppSelector((state: IRootState) => state.statusApi.find((x) => x.key === "addTileSystem"));

  const [tileSystemAdditions, setTileSystemAdditions] = useState<ITileSystemAddition[]>([]); // Pozostale  produkty systemu
  const [sheetMetalProcesses, setSheetMetalProcesses] = useState<ISheetMetalProcess[]>([]); // Obróbki blacharskie

  const [imageFileName, setImageFileName] = useState<string>("");

  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems);

  const productsGroupsState = useAppSelector((state: IRootState) => state.productsGroups);
  const technicalEquipmentsGroupsState = useAppSelector((state: IRootState) => state.technicalEquipmentsGroups);

  const [productsSelectForTiles, setProductsSelectForTiles] = useState<ISelectOption[]>([]);
  const [productsSelectForSheetMetal, setProductsSelectForSheetMetal] = useState<ISelectOption[]>([]);
  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);
  const [productsSelectForAccessories, setProductsSelectForAccessories] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForTiles.length === 0) {
        setProductsSelectForTiles(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-tiles-concrete",
            "basic-products-tiles-ceramic",
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
            "roofing-accessories",
          ])
        );
      }

      if (productsSelectForSheetMetal.length === 0) {
        setProductsSelectForSheetMetal(
          getProductsByCategoryForSelect(products, productCategories, ["supplementary-products"])
        );
      }

      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-tiles-concrete",
            "basic-products-tiles-ceramic",
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
            "wood",
            "supplementary-products",
            "lightning-protection-systems",
            "thermal-insulation",
            "roofing-accessories",
            "fasteners",
            "preliminary-covering",
          ])
        );
      }

      if (productsSelectForAccessories.length === 0) {
        setProductsSelectForAccessories(
          getProductsByCategoryForSelect(products, productCategories, [
            "roofing-accessories",
            "supplementary-products",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addTileSystem"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addTileSystem"));
    }
  }, [statusApi]);

  // Jesli coś zmieni się w systemach dachowych to ustawiamy ponownie dodatki i procesy
  useEffect(() => {
    if (tileSystems) {
      const s = tileSystems.find((x) => x.id === system?.id);
      if (s) {
        setTileSystemAdditions(s.tileSystemAdditions);
        setSheetMetalProcesses(s.sheetMetalProcesses);
      }
    }
  }, [tileSystems]);

  useEffect(() => {
    if (system && system.tileSystemAdditions && system.tileSystemAdditions.length > 0) {
      setTileSystemAdditions(system.tileSystemAdditions);
    }

    if (system && system.sheetMetalProcesses && system.sheetMetalProcesses.length > 0) {
      setSheetMetalProcesses(system.sheetMetalProcesses);
    }

    if (system?.imageUrl) {
      setImageFileName(system.imageUrl);
    }
  }, [system]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getProductsByCategoryForSelect = useCallback(
    (products: IProduct[], categories: IProductCategory[], categoryCodes: string[]) => {
      const result: { value: any; label: string }[] = [
        {
          value: null,
          label: "Brak",
        },
      ];
      for (const p of products) {
        const category = categories.find((x) => x.id === p.productCategoryId);
        if (categoryCodes.includes(category?.code as string)) {
          const company = companies?.find((x) => x.id === p.companyId);
          const color = productColors?.find((x) => x.id === p.productColorId);
          const model = productModels?.find((x) => x.id === p.productModelId);
          let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

          let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
          if (p.coating.length > 0) {
            productName += `, ${p.coating}`;
          }
          productName += ")";

          result.push({
            value: p.id,
            label: `${productName}`,
          });
        }
      }
      return result;
    },
    [tileSystemAdditions, sheetMetalProcesses]
  );

  const getInitialValues = () => {
    return isNew()
      ? {
          name: "",
          description: "",
          imageUrl: "",
          typeTileSystem: 1,
          methodOfLaying: 1,
          basicTileUse: 0,
          basicTileRelation: "area",
          basicTileCorrection: 0,
          tileClipsUse: 0,
          tileClipsRelation: "area",
          tileClipsCorrection: 0,
          leftTileEntireUse: 0,
          leftTileEntireRelation: "edge-gable-left",
          leftTileEntireCorrection: 0,
          leftTileHalfUse: 0,
          leftTileHalfRelation: "edge-gable-left",
          leftTileHalfCorrection: 0,
          rightTileEntireUse: 0,
          rightTileEntireRelation: "edge-gable-right",
          rightTileEntireCorrection: 0,
          rightTileHalfUse: 0,
          rightTileHalfRelation: "edge-gable-right",
          rightTileHalfCorrection: 0,
          basicRidgeUse: 0,
          basicRidgeRelation: "edge-ridge-all",
          basicRidgeCorrection: 0,
          startHorizontalRidgeUse: 1,
          startHorizontalRidgeRelation: "none",
          startHorizontalRidgeCorrection: 0,
          startHorizontalRidgeCountdown: false,
          endHorizontalRidgeUse: 1,
          endHorizontalRidgeRelation: "none",
          endHorizontalRidgeCorrection: 0,
          endHorizontalRidgeCountdown: false,
          startAngledRidgeUse: 1,
          startAngledRidgeRelation: "none",
          startAngledRidgeCorrection: 0,
          startAngledRidgeCountdown: false,
          endAngledRidgeUse: 1,
          endAngledRidgeRelation: "none",
          endAngledRidgeCorrection: 0,
          endAngledRidgeCountdown: false,
          initialRidgeThreeUse: 1,
          initialRidgeThreeRelation: "none",
          initialRidgeThreeCorrection: 0,
          endRidgeThreeUse: 1,
          endRidgeThreeRelation: "none",
          endRidgeThreeCorrection: 0,
          ridgeFourUse: 1,
          ridgeFourRelation: "none",
          ridgeFourCorrection: 0,
          // roofStepCount: 1,
          // chimneySweepBench40Count: 2,
          // chimneySweepBench80Count: 2,
          // chimneySweepBench200Count: 3,
          // chimneySweepBench300Count: 4,
          // snowFence200Count: 3,
          // snowFence300Count: 4,
          productsGroups: [],
        }
      : {
          // Usupełnić wszystkie pola
          name: system?.name,
          description: system?.description,
          imageUrl: system?.imageUrl,
          typeTileSystem: system?.typeTileSystem,
          methodOfLaying: system?.methodOfLaying,
          basicTileProductId: system?.basicTileProductId,
          basicTileUse: system?.basicTileUse,
          basicTileRelation: system?.basicTileRelation,
          basicTileCorrection: system?.basicTileCorrection,
          tileClipsProductId: system?.tileClipsProductId,
          tileClipsUse: system?.tileClipsUse,
          tileClipsRelation: system?.tileClipsRelation,
          tileClipsCorrection: system?.tileClipsCorrection,
          leftTileEntireProductId: system?.leftTileEntireProductId,
          leftTileEntireUse: system?.leftTileEntireUse,
          leftTileEntireRelation: system?.leftTileEntireRelation,
          leftTileEntireCorrection: system?.leftTileEntireCorrection,
          leftTileHalfProductId: system?.leftTileHalfProductId,
          leftTileHalfUse: system?.leftTileHalfUse,
          leftTileHalfRelation: system?.leftTileHalfRelation,
          leftTileHalfCorrection: system?.leftTileHalfCorrection,
          rightTileEntireProductId: system?.rightTileEntireProductId,
          rightTileEntireUse: system?.rightTileEntireUse,
          rightTileEntireRelation: system?.rightTileEntireRelation,
          rightTileEntireCorrection: system?.rightTileEntireCorrection,
          rightTileHalfProductId: system?.rightTileHalfProductId,
          rightTileHalfUse: system?.rightTileHalfUse,
          rightTileHalfRelation: system?.rightTileHalfRelation,
          rightTileHalfCorrection: system?.rightTileHalfCorrection,
          basicRidgeProductId: system?.basicRidgeProductId,
          basicRidgeUse: system?.basicRidgeUse,
          basicRidgeRelation: system?.basicRidgeRelation,
          basicRidgeCorrection: system?.basicRidgeCorrection,
          startHorizontalRidgeProductId: system?.startHorizontalRidgeProductId,
          startHorizontalRidgeUse: system?.startHorizontalRidgeUse,
          startHorizontalRidgeRelation: system?.startHorizontalRidgeRelation,
          startHorizontalRidgeCorrection: system?.startHorizontalRidgeCorrection,
          startHorizontalRidgeCountdown: system?.startHorizontalRidgeCountdown,
          endHorizontalRidgeProductId: system?.endHorizontalRidgeProductId,
          endHorizontalRidgeUse: system?.endHorizontalRidgeUse,
          endHorizontalRidgeRelation: system?.endHorizontalRidgeRelation,
          endHorizontalRidgeCorrection: system?.endHorizontalRidgeCorrection,
          endHorizontalRidgeCountdown: system?.endHorizontalRidgeCountdown,
          startAngledRidgeProductId: system?.startAngledRidgeProductId,
          startAngledRidgeUse: system?.startAngledRidgeUse,
          startAngledRidgeRelation: system?.startAngledRidgeRelation,
          startAngledRidgeCorrection: system?.startAngledRidgeCorrection,
          startAngledRidgeCountdown: system?.startAngledRidgeCountdown,
          endAngledRidgeProductId: system?.endAngledRidgeProductId,
          endAngledRidgeUse: system?.endAngledRidgeUse,
          endAngledRidgeRelation: system?.endAngledRidgeRelation,
          endAngledRidgeCorrection: system?.endAngledRidgeCorrection,
          endAngledRidgeCountdown: system?.endAngledRidgeCountdown,
          initialRidgeThreeProductId: system?.initialRidgeThreeProductId,
          initialRidgeThreeUse: system?.initialRidgeThreeUse,
          initialRidgeThreeRelation: system?.initialRidgeThreeRelation,
          initialRidgeThreeCorrection: system?.initialRidgeThreeCorrection,
          endRidgeThreeProductId: system?.endRidgeThreeProductId,
          endRidgeThreeUse: system?.endRidgeThreeUse,
          endRidgeThreeRelation: system?.endRidgeThreeRelation,
          endRidgeThreeCorrection: system?.endRidgeThreeCorrection,
          ridgeFourProductId: system?.ridgeFourProductId,
          ridgeFourUse: system?.ridgeFourUse,
          ridgeFourRelation: system?.ridgeFourRelation,
          ridgeFourCorrection: system?.ridgeFourCorrection,
          // roofSupportProductId: system?.roofSupportProductId,
          // roofSupportSnowFenceProductId: system?.roofSupportSnowFenceProductId,
          // chimneyBenchGripProductId: system?.chimneyBenchGripProductId,
          // roofStepProductId: system?.roofStepProductId,
          // roofStepCount: system?.roofStepCount,
          // chimneySweepBench40ProductId: system?.chimneySweepBench40ProductId,
          // chimneySweepBench40Count: system?.chimneySweepBench40Count,
          // chimneySweepBench80ProductId: system?.chimneySweepBench80ProductId,
          // chimneySweepBench80Count: system?.chimneySweepBench80Count,
          // chimneySweepBench200ProductId: system?.chimneySweepBench200ProductId,
          // chimneySweepBench200Count: system?.chimneySweepBench200Count,
          // chimneySweepBench300ProductId: system?.chimneySweepBench300ProductId,
          // chimneySweepBench300Count: system?.chimneySweepBench300Count,
          // snowFence200ProductId: system?.snowFence200ProductId,
          // snowFence200Count: system?.snowFence200Count,
          // snowFence300ProductId: system?.snowFence300ProductId,
          // snowFence300Count: system?.snowFence300Count,

          roofVent100ProductId: system?.roofVent100ProductId,
          roofVent100Extra1ProductId: system?.roofVent100Extra1ProductId,
          roofVent100Extra2ProductId: system?.roofVent100Extra2ProductId,
          roofVent125ProductId: system?.roofVent125ProductId,
          roofVent125Extra1ProductId: system?.roofVent125Extra1ProductId,
          roofVent125Extra2ProductId: system?.roofVent125Extra2ProductId,
          roofVent150ProductId: system?.roofVent150ProductId,
          roofVent150Extra1ProductId: system?.roofVent150Extra1ProductId,
          roofVent150Extra2ProductId: system?.roofVent150Extra2ProductId,

          tileSystemAdditions: tileSystemAdditions,
          sheetMetalProcesses: sheetMetalProcesses,

          productsGroups: system?.productsGroups,
          technicalEquipmentsGroupId: system?.technicalEquipmentsGroupId,

          companyId: system?.companyId,
          modelId: system?.productModelId,
          colorId: system?.productColorId,
        };
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "System został utworzony!",
    });
  };

  const onFinish = (values: any, newVersion: boolean) => {
    if (isNew() || newVersion) {
      let s: IAddTileSystem = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        typeTileSystem: values.typeTileSystem,
        methodOfLaying: values.methodOfLaying,
        basicTileProductId: values?.basicTileProductId,
        basicTileUse: values?.basicTileUse,
        basicTileRelation: values?.basicTileRelation,
        basicTileCorrection: values?.basicTileCorrection,
        tileClipsProductId: values?.tileClipsProductId,
        tileClipsUse: values?.tileClipsUse,
        tileClipsRelation: values?.tileClipsRelation,
        tileClipsCorrection: values?.tileClipsCorrection,
        leftTileEntireProductId: values?.leftTileEntireProductId,
        leftTileEntireUse: values?.leftTileEntireUse,
        leftTileEntireRelation: values?.leftTileEntireRelation,
        leftTileEntireCorrection: values?.leftTileEntireCorrection,
        leftTileHalfProductId: values?.leftTileHalfProductId,
        leftTileHalfUse: values?.leftTileHalfUse,
        leftTileHalfRelation: values?.leftTileHalfRelation,
        leftTileHalfCorrection: values?.leftTileHalfCorrection,
        rightTileEntireProductId: values?.rightTileEntireProductId,
        rightTileEntireUse: values?.rightTileEntireUse,
        rightTileEntireRelation: values?.rightTileEntireRelation,
        rightTileEntireCorrection: values?.rightTileEntireCorrection,
        rightTileHalfProductId: values?.rightTileHalfProductId,
        rightTileHalfUse: values?.rightTileHalfUse,
        rightTileHalfRelation: values?.rightTileHalfRelation,
        rightTileHalfCorrection: values?.rightTileHalfCorrection,
        basicRidgeProductId: values?.basicRidgeProductId,
        basicRidgeUse: values?.basicRidgeUse,
        basicRidgeRelation: values?.basicRidgeRelation,
        basicRidgeCorrection: values?.basicRidgeCorrection,
        startHorizontalRidgeProductId: values?.startHorizontalRidgeProductId,
        startHorizontalRidgeUse: values?.startHorizontalRidgeUse,
        startHorizontalRidgeRelation: values?.startHorizontalRidgeRelation,
        startHorizontalRidgeCorrection: values?.startHorizontalRidgeCorrection,
        startHorizontalRidgeCountdown: values?.startHorizontalRidgeCountdown,
        endHorizontalRidgeProductId: values?.endHorizontalRidgeProductId,
        endHorizontalRidgeUse: values?.endHorizontalRidgeUse,
        endHorizontalRidgeRelation: values?.endHorizontalRidgeRelation,
        endHorizontalRidgeCorrection: values?.endHorizontalRidgeCorrection,
        endHorizontalRidgeCountdown: values?.endHorizontalRidgeCountdown,
        startAngledRidgeProductId: values?.startAngledRidgeProductId,
        startAngledRidgeUse: values?.startAngledRidgeUse,
        startAngledRidgeRelation: values?.startAngledRidgeRelation,
        startAngledRidgeCorrection: values?.startAngledRidgeCorrection,
        startAngledRidgeCountdown: values?.startAngledRidgeCountdown,
        endAngledRidgeProductId: values?.endAngledRidgeProductId,
        endAngledRidgeUse: values?.endAngledRidgeUse,
        endAngledRidgeRelation: values?.endAngledRidgeRelation,
        endAngledRidgeCorrection: values?.endAngledRidgeCorrection,
        endAngledRidgeCountdown: values?.endAngledRidgeCountdown,
        initialRidgeThreeProductId: values?.initialRidgeThreeProductId,
        initialRidgeThreeUse: values?.initialRidgeThreeUse,
        initialRidgeThreeRelation: values?.initialRidgeThreeRelation,
        initialRidgeThreeCorrection: values?.initialRidgeThreeCorrection,
        endRidgeThreeProductId: values?.endRidgeThreeProductId,
        endRidgeThreeUse: values?.endRidgeThreeUse,
        endRidgeThreeRelation: values?.endRidgeThreeRelation,
        endRidgeThreeCorrection: values?.endRidgeThreeCorrection,
        ridgeFourProductId: values?.ridgeFourProductId,
        ridgeFourUse: values?.ridgeFourUse,
        ridgeFourRelation: values?.ridgeFourRelation,
        ridgeFourCorrection: values?.ridgeFourCorrection,
        // roofSupportProductId: values?.roofSupportProductId,
        // roofSupportSnowFenceProductId: values?.roofSupportSnowFenceProductId,
        // chimneyBenchGripProductId: values?.chimneyBenchGripProductId,
        // roofStepProductId: values?.roofStepProductId,
        // roofStepCount: values?.roofStepCount,
        // chimneySweepBench40ProductId: values?.chimneySweepBench40ProductId,
        // chimneySweepBench40Count: values?.chimneySweepBench40Count,
        // chimneySweepBench80ProductId: values?.chimneySweepBench80ProductId,
        // chimneySweepBench80Count: values?.chimneySweepBench80Count,
        // chimneySweepBench200ProductId: values?.chimneySweepBench200ProductId,
        // chimneySweepBench200Count: values?.chimneySweepBench200Count,
        // chimneySweepBench300ProductId: values?.chimneySweepBench300ProductId,
        // chimneySweepBench300Count: values?.chimneySweepBench300Count,
        // snowFence200ProductId: values?.snowFence200ProductId,
        // snowFence200Count: values?.snowFence200Count,
        // snowFence300ProductId: values?.snowFence300ProductId,
        // snowFence300Count: values?.snowFence300Count,
        roofVent100ProductId: values?.roofVent100ProductId,
        roofVent100Extra1ProductId: values?.roofVent100Extra1ProductId,
        roofVent100Extra2ProductId: values?.roofVent100Extra2ProductId,
        roofVent125ProductId: values?.roofVent125ProductId,
        roofVent125Extra1ProductId: values?.roofVent125Extra1ProductId,
        roofVent125Extra2ProductId: values?.roofVent125Extra2ProductId,
        roofVent150ProductId: values?.roofVent150ProductId,
        roofVent150Extra1ProductId: values?.roofVent150Extra1ProductId,
        roofVent150Extra2ProductId: values?.roofVent150Extra2ProductId,
        tileSystemAdditions: tileSystemAdditions,
        sheetMetalProcesses: sheetMetalProcesses,
        productsGroups: values?.productsGroups,
        technicalEquipmentsGroupId: values?.technicalEquipmentsGroupId,
      };

      // Sprawdzamy czy każdy w tileSystemAdditions ma productId. jesli nie to usuń
      const tileSystemAdditionsFiltered = [...tileSystemAdditions].filter((x) => x.productId !== "");
      s.tileSystemAdditions = tileSystemAdditionsFiltered;

      const sheetMetalProcessesFiltered = [...sheetMetalProcesses].filter((x) => x.productId !== "");
      s.sheetMetalProcesses = sheetMetalProcessesFiltered;

      dispatch(addTileSystem(s, successCallback));
    } else {
      let s: ITileSystem = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        typeTileSystem: values.typeTileSystem,
        methodOfLaying: values.methodOfLaying,
        basicTileProductId: values?.basicTileProductId,
        basicTileUse: values?.basicTileUse,
        basicTileRelation: values?.basicTileRelation,
        basicTileCorrection: values?.basicTileCorrection,
        tileClipsProductId: values?.tileClipsProductId,
        tileClipsUse: values?.tileClipsUse,
        tileClipsRelation: values?.tileClipsRelation,
        tileClipsCorrection: values?.tileClipsCorrection,
        leftTileEntireProductId: values?.leftTileEntireProductId,
        leftTileEntireUse: values?.leftTileEntireUse,
        leftTileEntireRelation: values?.leftTileEntireRelation,
        leftTileEntireCorrection: values?.leftTileEntireCorrection,
        leftTileHalfProductId: values?.leftTileHalfProductId,
        leftTileHalfUse: values?.leftTileHalfUse,
        leftTileHalfRelation: values?.leftTileHalfRelation,
        leftTileHalfCorrection: values?.leftTileHalfCorrection,
        rightTileEntireProductId: values?.rightTileEntireProductId,
        rightTileEntireUse: values?.rightTileEntireUse,
        rightTileEntireRelation: values?.rightTileEntireRelation,
        rightTileEntireCorrection: values?.rightTileEntireCorrection,
        rightTileHalfProductId: values?.rightTileHalfProductId,
        rightTileHalfUse: values?.rightTileHalfUse,
        rightTileHalfRelation: values?.rightTileHalfRelation,
        rightTileHalfCorrection: values?.rightTileHalfCorrection,
        basicRidgeProductId: values?.basicRidgeProductId,
        basicRidgeUse: values?.basicRidgeUse,
        basicRidgeRelation: values?.basicRidgeRelation,
        basicRidgeCorrection: values?.basicRidgeCorrection,
        startHorizontalRidgeProductId: values?.startHorizontalRidgeProductId,
        startHorizontalRidgeUse: values?.startHorizontalRidgeUse,
        startHorizontalRidgeRelation: values?.startHorizontalRidgeRelation,
        startHorizontalRidgeCorrection: values?.startHorizontalRidgeCorrection,
        startHorizontalRidgeCountdown: values?.startHorizontalRidgeCountdown,
        endHorizontalRidgeProductId: values?.endHorizontalRidgeProductId,
        endHorizontalRidgeUse: values?.endHorizontalRidgeUse,
        endHorizontalRidgeRelation: values?.endHorizontalRidgeRelation,
        endHorizontalRidgeCorrection: values?.endHorizontalRidgeCorrection,
        endHorizontalRidgeCountdown: values?.endHorizontalRidgeCountdown,
        startAngledRidgeProductId: values?.startAngledRidgeProductId,
        startAngledRidgeUse: values?.startAngledRidgeUse,
        startAngledRidgeRelation: values?.startAngledRidgeRelation,
        startAngledRidgeCorrection: values?.startAngledRidgeCorrection,
        startAngledRidgeCountdown: values?.startAngledRidgeCountdown,
        endAngledRidgeProductId: values?.endAngledRidgeProductId,
        endAngledRidgeUse: values?.endAngledRidgeUse,
        endAngledRidgeRelation: values?.endAngledRidgeRelation,
        endAngledRidgeCorrection: values?.endAngledRidgeCorrection,
        endAngledRidgeCountdown: values?.endAngledRidgeCountdown,
        initialRidgeThreeProductId: values?.initialRidgeThreeProductId,
        initialRidgeThreeUse: values?.initialRidgeThreeUse,
        initialRidgeThreeRelation: values?.initialRidgeThreeRelation,
        initialRidgeThreeCorrection: values?.initialRidgeThreeCorrection,
        endRidgeThreeProductId: values?.endRidgeThreeProductId,
        endRidgeThreeUse: values?.endRidgeThreeUse,
        endRidgeThreeRelation: values?.endRidgeThreeRelation,
        endRidgeThreeCorrection: values?.endRidgeThreeCorrection,
        ridgeFourProductId: values?.ridgeFourProductId,
        ridgeFourUse: values?.ridgeFourUse,
        ridgeFourRelation: values?.ridgeFourRelation,
        ridgeFourCorrection: values?.ridgeFourCorrection,
        // roofSupportProductId: values?.roofSupportProductId,
        // roofSupportSnowFenceProductId: values?.roofSupportSnowFenceProductId,
        // chimneyBenchGripProductId: values?.chimneyBenchGripProductId,
        // roofStepProductId: values?.roofStepProductId,
        // roofStepCount: values?.roofStepCount,
        // chimneySweepBench40ProductId: values?.chimneySweepBench40ProductId,
        // chimneySweepBench40Count: values?.chimneySweepBench40Count,
        // chimneySweepBench80ProductId: values?.chimneySweepBench80ProductId,
        // chimneySweepBench80Count: values?.chimneySweepBench80Count,
        // chimneySweepBench200ProductId: values?.chimneySweepBench200ProductId,
        // chimneySweepBench200Count: values?.chimneySweepBench200Count,
        // chimneySweepBench300ProductId: values?.chimneySweepBench300ProductId,
        // chimneySweepBench300Count: values?.chimneySweepBench300Count,
        // snowFence200ProductId: values?.snowFence200ProductId,
        // snowFence200Count: values?.snowFence200Count,
        // snowFence300ProductId: values?.snowFence300ProductId,
        // snowFence300Count: values?.snowFence300Count,
        roofVent100ProductId: values?.roofVent100ProductId,
        roofVent100Extra1ProductId: values?.roofVent100Extra1ProductId,
        roofVent100Extra2ProductId: values?.roofVent100Extra2ProductId,
        roofVent125ProductId: values?.roofVent125ProductId,
        roofVent125Extra1ProductId: values?.roofVent125Extra1ProductId,
        roofVent125Extra2ProductId: values?.roofVent125Extra2ProductId,
        roofVent150ProductId: values?.roofVent150ProductId,
        roofVent150Extra1ProductId: values?.roofVent150Extra1ProductId,
        roofVent150Extra2ProductId: values?.roofVent150Extra2ProductId,
        tileSystemAdditions: tileSystemAdditions,
        sheetMetalProcesses: sheetMetalProcesses,
        productsGroups: values?.productsGroups,
        technicalEquipmentsGroupId: values?.technicalEquipmentsGroupId,
      };

      // Sprawdzamy czy każdy w tileSystemAdditions ma productId. jesli nie to usuń
      const tileSystemAdditionsFiltered = [...tileSystemAdditions].filter((x) => x.productId !== "");
      s.tileSystemAdditions = tileSystemAdditionsFiltered;

      const sheetMetalProcessesFiltered = [...sheetMetalProcesses].filter((x) => x.productId !== "");
      s.sheetMetalProcesses = sheetMetalProcessesFiltered;

      dispatch(updateTileSystem(s));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getProductsGroupsForSelect = (productsGroups: IProductsGroup[]) => {
    const result: { value: any; label: string }[] = [];

    for (const p of productsGroups) {
      result.push({
        value: p.id,
        label: p.name,
      });
    }
    return result;
  };

  const getTechnicalEquipmentsGroupsForSelect = (productsGroups: ITechnicalEquipmentGroup[]) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];

    for (const p of productsGroups) {
      result.push({
        value: p.id,
        label: p.name,
      });
    }
    return result;
  };

  interface MainProductPositionProps {
    productLabel: string;
    productName: string;
    useName: string;
    useDisabled: boolean;
    relationName: string;
    relationDisabled: boolean;
    correctionName: string;
    correctionDisabled: boolean;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
    selectedCategories: string[];
    readyOptions?: any[];
  }

  const MainProductPosition: React.FC<MainProductPositionProps> = ({
    productLabel,
    productName,
    useName,
    useDisabled,
    relationName,
    relationDisabled,
    correctionName,
    correctionDisabled,
    products,
    productCategories,
    relations,
    selectedCategories,
    readyOptions,
  }) => {
    const onProductChange = (productId: any) => {
      const selectedProduct = products.find((p) => p.id === productId);
      if (selectedProduct) {
        form.setFieldsValue({ [useName]: selectedProduct.use });
      }
    };

    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={onProductChange}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Użycie" name={useName} labelCol={{ span: 24 }}>
            <DomaInputNumber disabled={useDisabled} min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zależność" name={relationName} labelCol={{ span: 24 }}>
            <Select
              disabled={relationDisabled}
              placeholder="Wybierz zależność!"
              options={relations.sort((a, b) => a.index - b.index)}
              dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
              filterOption={createFilterOption()}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Korekta w [%]" name={correctionName} labelCol={{ span: 24 }}>
            <DomaInputNumber
              disabled={correctionDisabled}
              allowNegative
              min={-100}
              max={200}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  interface SecondProductPositionProps {
    productLabel: string;
    productName: string;
    useName: string;
    useDisabled: boolean;
    countdownName: string;
    relationName: string;
    relationDisabled: boolean;
    correctionName: string;
    correctionDisabled: boolean;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
    selectedCategories: string[];
    readyOptions?: any[];
  }

  const SecondProductPosition: React.FC<SecondProductPositionProps> = ({
    productLabel,
    productName,
    useName,
    useDisabled,
    countdownName,
    relationName,
    relationDisabled,
    correctionName,
    correctionDisabled,
    products,
    productCategories,
    relations,
    selectedCategories,
    readyOptions,
  }) => {
    const onProductChange = (productId: any) => {
      const selectedProduct = products.find((p) => p.id === productId);
      if (selectedProduct) {
        form.setFieldsValue({ [useName]: selectedProduct.use });
      }
    };

    return (
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={onProductChange}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Odlicz gąsior" name={countdownName} valuePropName="checked" labelCol={{ span: 24 }}>
            <Switch
              checkedChildren="TAK"
              unCheckedChildren="NIE"
              style={{ width: "100%", height: "100%", padding: "0px", margin: "0px" }}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Użycie" name={useName} labelCol={{ span: 24 }}>
            <DomaInputNumber disabled={useDisabled} min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zależność" name={relationName} labelCol={{ span: 24 }}>
            <Select
              disabled={relationDisabled}
              placeholder="Wybierz zależność!"
              options={relations.sort((a, b) => a.index - b.index)}
              dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
              filterOption={createFilterOption()}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Korekta w [%]" name={correctionName} labelCol={{ span: 24 }}>
            <DomaInputNumber
              disabled={correctionDisabled}
              allowNegative
              min={-100}
              max={200}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  interface AccessoriesProductPositionProps {
    productLabel: string;
    productName: string;
    supportsCountName: string;
    supportsCountLabel: string;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    selectedCategories: string[];
    supportsDisabled: boolean;
    readyOptions?: any[];
  }

  const AccessoriesProductPosition: React.FC<AccessoriesProductPositionProps> = ({
    productLabel,
    productName,
    supportsCountName,
    supportsCountLabel,
    products,
    productCategories,
    selectedCategories,
    supportsDisabled,
    readyOptions,
  }) => {
    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          {!supportsDisabled && (
            <Form.Item label={supportsCountLabel} name={supportsCountName} labelCol={{ span: 24 }}>
              <DomaInputNumber allowNegative min={-100} max={200} style={{ width: "100%" }} />
            </Form.Item>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <Container>
      {contextHolder}
      <Form
        id="tileSystemDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Section title={"Ogólne dane systemu"}>
          <Form.Item
            label="Nazwa systemu"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę systemu!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis systemu" name="description" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>

          {/* <Form.Item label="Link do zdjęcia obrazującego system" name="imageUrl" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item> */}

          <SystemImageComponent
            systemId={system?.id || ""}
            systemName="tile"
            imageFileName={imageFileName}
            setImageFileName={(name) => {
              setImageFileName(name);
            }}
          />

          <Form.Item label="Typ pokrycia" name="typeTileSystem" labelCol={{ span: 24 }}>
            <StyledRadioGroup>
              <Radio.Button value={1}>Dachówka ceramiczna</Radio.Button>
              <Radio.Button value={2}>Dachówka cementowa</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item label="Sposób układania" name="methodOfLaying" labelCol={{ span: 24 }}>
            <StyledRadioGroup>
              <Radio.Button value={1}>Standardowy</Radio.Button>
              <Radio.Button value={2}>Przesunięty</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Alert
            showIcon
            message="Przesunięty sposób układania dachówki powoduje dobieranie dodatkowych dachówek krawędziowych połówkowych"
          />
        </Section>

        {/* Sekcja do konfiguracji producenta, modelu oraz koloru */}
        <SystemConfigurationDetailsSection form={form} />

        <Section
          title={"Podstawowe produkty systemu (np. dachówka, gąsiory, trójniki)"}
          style={{ marginTop: "16px" }}
        >
          {/* Dachówka podstawowa */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Dachówka podstawowa"
            productName="basicTileProductId"
            useName="basicTileUse"
            relationName="basicTileRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="basicTileCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Spinka do dachówki */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "supplementary-products",
              "roofing-accessories",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Spinka do dachówki"
            productName="tileClipsProductId"
            useName="tileClipsUse"
            relationName="tileClipsRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="tileClipsCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Dachówka krawędziowa lewa cała */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Dachówka krawędziowa lewa cała"
            productName="leftTileEntireProductId"
            useName="leftTileEntireUse"
            relationName="leftTileEntireRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="leftTileEntireCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Dachówka krawędziowa lewa połówkowa */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Dachówka krawędziowa lewa połówkowa (tylko dla systemu przesuniętego)"
            productName="leftTileHalfProductId"
            useName="leftTileHalfUse"
            relationName="leftTileHalfRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="leftTileHalfCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Dachówka krawędziowa prawa cała */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Dachówka krawędziowa prawa cała"
            productName="rightTileEntireProductId"
            useName="rightTileEntireUse"
            relationName="rightTileEntireRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="rightTileEntireCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Dachówka krawędziowa prawa połówkowa */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Dachówka krawędziowa prawa połówkowa (tylko dla systemu przesuniętego)"
            productName="rightTileHalfProductId"
            useName="rightTileHalfUse"
            relationName="rightTileHalfRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="rightTileHalfCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Gąsior podstawowy */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Gąsior podstawowy"
            productName="basicRidgeProductId"
            useName="basicRidgeUse"
            relationName="basicRidgeRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="basicRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Początek kalenicy poziomej (gąsior lub zaślepka) */}
          {/* <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Początek kalenicy poziomej (gąsior lub zaślepka)"
            productName="startHorizontalRidgeProductId"
            useName="startHorizontalRidgeUse"
            relationName="startHorizontalRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="startHorizontalRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          /> */}

          {/* Początek kalenicy poziomej (gąsior lub zaślepka) */}
          <SecondProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Początek kalenicy poziomej (gąsior lub zaślepka)"
            productName="startHorizontalRidgeProductId"
            useName="startHorizontalRidgeUse"
            relationName="startHorizontalRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="startHorizontalRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
            countdownName="startHorizontalRidgeCountdown"
          />

          {/* Początek kalenicy skośnej - grzebietu (gąsior lub zaślepka) */}
          {/* <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Początek kalenicy skośnej - grzebietu (gąsior lub zaślepka)"
            productName="startAngledRidgeProductId"
            useName="startAngledRidgeUse"
            relationName="startAngledRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="startAngledRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          /> */}

          {/* Początek kalenicy skośnej - grzebietu (gąsior lub zaślepka) */}
          <SecondProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Początek kalenicy skośnej - grzebietu (gąsior lub zaślepka)"
            productName="startAngledRidgeProductId"
            useName="startAngledRidgeUse"
            relationName="startAngledRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="startAngledRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
            countdownName="startAngledRidgeCountdown"
          />

          {/* Koniec kalenicy poziomej (gąsior lub zaślepka) */}
          {/* <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Koniec kalenicy poziomej (gąsior lub zaślepka)"
            productName="endHorizontalRidgeProductId"
            useName="endHorizontalRidgeUse"
            relationName="endHorizontalRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="endHorizontalRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          /> */}

          {/* Koniec kalenicy poziomej (gąsior lub zaślepka) */}
          <SecondProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Koniec kalenicy poziomej (gąsior lub zaślepka)"
            productName="endHorizontalRidgeProductId"
            useName="endHorizontalRidgeUse"
            relationName="endHorizontalRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="endHorizontalRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
            countdownName="endHorizontalRidgeCountdown"
          />

          {/* Koniec kalenicy skośnej - grzebietu (gąsior lub zaślepka) */}
          {/* <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Koniec kalenicy skośnej - grzebietu (gąsior lub zaślepka)"
            productName="endAngledRidgeProductId"
            useName="endAngledRidgeUse"
            relationName="endAngledRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="endAngledRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          /> */}

          {/* Koniec kalenicy skośnej - grzebietu (gąsior lub zaślepka) */}
          <SecondProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Koniec kalenicy skośnej - grzebietu (gąsior lub zaślepka)"
            productName="endAngledRidgeProductId"
            useName="endAngledRidgeUse"
            relationName="endAngledRidgeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="endAngledRidgeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
            countdownName="endAngledRidgeCountdown"
          />

          {/* Trójnik na początku kalenicy */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Trójnik na początku kalenicy"
            productName="initialRidgeThreeProductId"
            useName="initialRidgeThreeUse"
            relationName="initialRidgeThreeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="initialRidgeThreeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Trójnik na końcu kalenicy */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Trójnik na końcu kalenicy"
            productName="endRidgeThreeProductId"
            useName="endRidgeThreeUse"
            relationName="endRidgeThreeRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="endRidgeThreeCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Czwórnik gąsiora */}
          <MainProductPosition
            selectedCategories={[
              "basic-products-tiles-concrete",
              "basic-products-tiles-ceramic",
              "basic-products-sheet-metal",
              "basic-products-cut-sheet-metal",
              "basic-products-sheet-metal-trapezoidal",
              "basic-products-sheet-metal-standing-seam",
            ]}
            readyOptions={productsSelectForTiles}
            products={products}
            productCategories={productCategories}
            productLabel="Czwórnik gąsiora"
            productName="ridgeFourProductId"
            useName="ridgeFourUse"
            relationName="ridgeFourRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="ridgeFourCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />
        </Section>

        <Section
          title={"Pozostałe produkty systemu (uzależnione od typu krawędzi, powierzchni dachu, itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newTileSystemAddition: ITileSystemAddition = {
                  id: uuidv4(),
                  productId: "",
                  use: 0,
                  relation: "none",
                  correction: 0,
                  tileSystemId: system?.id as string,
                };
                setTileSystemAdditions([newTileSystemAddition, ...tileSystemAdditions]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {tileSystemAdditions.map((addition, i) => {
            return (
              <ExtraProductPosition
                key={`${addition.id}_${i}`}
                addition={addition}
                selectedCategories={[
                  "basic-products-tiles-concrete",
                  "basic-products-tiles-ceramic",
                  "basic-products-sheet-metal",
                  "basic-products-cut-sheet-metal",
                  "basic-products-sheet-metal-trapezoidal",
                  "basic-products-sheet-metal-standing-seam",
                  "wood",
                  "supplementary-products",
                  "lightning-protection-systems",
                  "thermal-insulation",
                  "roofing-accessories",
                  "fasteners",
                  "preliminary-covering",
                ]}
                readyOptions={productsSelectForAdditions}
                products={products}
                productCategories={productCategories}
                useDisabled={false}
                relationDisabled={false}
                correctionDisabled={false}
                relations={[...TILE_SYSTEM_RELATIONS]}
                updateAddition={(addition) => {
                  const newAdditions = [...tileSystemAdditions];
                  const index = newAdditions.findIndex((x) => x.id === addition.id);

                  if (index >= 0) {
                    newAdditions[index] = addition;
                    setTileSystemAdditions(newAdditions);
                  }
                }}
                deleteAddition={(addition) => {
                  const newAdditions = [...tileSystemAdditions].filter((x) => x.id !== addition.id);
                  setTileSystemAdditions(newAdditions);
                }}
              />
            );
          })}
        </Section>

        <Section
          title={"Obróbki blacharskie (krawędzi koszowej, pasa nadrynnowego, komina itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newSheetMetalProcesses: ISheetMetalProcess = {
                  id: uuidv4(),
                  productId: "",
                  sheetWidth: 0,
                  sheetLength: 0,
                  stripWidth: 0,
                  overlap: 0,
                  relation: "none",
                  correction: 0,
                  tileSystemId: system?.id as string,
                };

                setSheetMetalProcesses([newSheetMetalProcesses, ...sheetMetalProcesses]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {sheetMetalProcesses.map((process) => {
            return (
              <SheetMetalProcessPosition
                key={process.id}
                sheetMetalProcess={process}
                selectedCategories={["supplementary-products"]}
                readyOptions={productsSelectForSheetMetal}
                products={products}
                productCategories={productCategories}
                relations={[...EDGES_RELATIONS]}
                updateSheetMetalProcess={(process) => {
                  const newSheetMetalProcesses = [...sheetMetalProcesses];
                  const index = newSheetMetalProcesses.findIndex((x) => x.id === process.id);

                  if (index >= 0) {
                    newSheetMetalProcesses[index] = process;
                    setSheetMetalProcesses(newSheetMetalProcesses);
                  }
                }}
                deleteSheetMetalProcess={(process) => {
                  const newSheetMetalProcesses = [...sheetMetalProcesses].filter((x) => x.id !== process.id);
                  setSheetMetalProcesses(newSheetMetalProcesses);
                }}
              />
            );
          })}
        </Section>

        <Section title={"Grupy dodatkowych produktów"} style={{ marginTop: "16px" }}>
          <Form.Item label={"Nazwy grup produktowych"} name="productsGroups" labelCol={{ span: 24 }}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Wybierz grupy produktowe"
              options={getProductsGroupsForSelect(productsGroupsState)}
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Form.Item>
        </Section>

        <Section
          title={"Akcesoria dachowe, komunikacja oraz zabezpieczenia przeciwśniegowe"}
          style={{ marginTop: "16px" }}
        >
          <Form.Item
            label={"Grupa wyposażenia technicznego"}
            name="technicalEquipmentsGroupId"
            labelCol={{ span: 24 }}
          >
            <Select
              allowClear
              placeholder="Wybierz grupę wyposażenia technicznego"
              options={getTechnicalEquipmentsGroupsForSelect(technicalEquipmentsGroupsState)}
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Form.Item>

          <Divider />

          {/* Kominek wentylacyjny 100*/}
          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 100 - produkt podstawowy"
            productName="roofVent100ProductId"
            supportsCountName="roofVent100Count"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 100 - produkt dodatkowy"
            productName="roofVent100Extra1ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 100 - produkt dodatkowy"
            productName="roofVent100Extra2ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <div style={{ marginTop: "32px" }}></div>

          {/* Kominek wentylacyjny 125*/}
          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 125 - produkt podstawowy"
            productName="roofVent125ProductId"
            supportsCountName="roofVent125Count"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 125 - produkt dodatkowy"
            productName="roofVent125Extra1ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 125 - produkt dodatkowy"
            productName="roofVent125Extra2ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <div style={{ marginTop: "32px" }}></div>

          {/* Kominek wentylacyjny 150*/}
          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 150 - produkt podstawowy"
            productName="roofVent150ProductId"
            supportsCountName="roofVent150Count"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 150 - produkt dodatkowy"
            productName="roofVent150Extra1ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 150 - produkt dodatkowy"
            productName="roofVent150Extra2ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />
        </Section>
      </Form>
    </Container>
  );
};

export default TileSystemDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
