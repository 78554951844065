import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISystemActivityType } from "../../models/Models";

const initialState: ISystemActivityType[] = [];

export const systemActivityTypesSlice = createSlice({
  name: "systemActivityTypes",
  initialState,
  reducers: {
    addSystemActivityTypesInReducer: (state, action: PayloadAction<Array<ISystemActivityType>>) => {
      let e = action.payload;
      return e;
    },
  },
});

export const { addSystemActivityTypesInReducer } = systemActivityTypesSlice.actions;
export default systemActivityTypesSlice.reducer;
