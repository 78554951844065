import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { IAddGutterSystem, IGutterSystem } from "../../models/Models";
import { addStatusApi } from "../other/statusApiSlice";
import { AppThunk } from "../store";

const initialState: IGutterSystem[] = [];

export const gutterSystemsSlice = createSlice({
  name: "gutterSystems",
  initialState,
  reducers: {
    addGutterSystemsInReducer: (state, action: PayloadAction<IGutterSystem[]>) => {
      let gutterSystems = action.payload;
      return gutterSystems;
    },
    addGutterSystemInReducer: (state, action: PayloadAction<IGutterSystem>) => {
      let gutterSystem = action.payload;
      return [...state, gutterSystem];
    },
    updateGutterSystemInReducer: (state, action: PayloadAction<IGutterSystem>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);
      state[index] = { ...state[index], ...update };
    },
    deleteGutterSystemInReducer: (state, action: PayloadAction<string>) => {
      const gutterSystemId = action.payload;
      return state.filter((o) => o.id !== gutterSystemId);
    },
  },
});

export const {
  addGutterSystemsInReducer,
  addGutterSystemInReducer,
  updateGutterSystemInReducer,
  deleteGutterSystemInReducer,
} = gutterSystemsSlice.actions;
export default gutterSystemsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add gutter system
export const addGutterSystem =
  (data: IAddGutterSystem, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    console.log(data);
    client
      .post("/GutterSystems", data)
      .then((response) => {
        if (response.status == 201) {
          console.log(response.data);
          dispatch(addGutterSystemInReducer(response.data));
          callback();
          // dispatch(addStatusApi({ key: "addGutterSystem", type: "success", message: "" }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addGutterSystem", type: "error", message: "Błąd dodania systemu!" }));
        // console.error(error);
      });
  };

// Update gutter system
export const updateGutterSystem =
  (data: IGutterSystem): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE GUTTER SYSTEM MODEL", data);
    client
      .put(`/GutterSystems`, data)
      .then((response) => {
        if (response.status == 200) {
          console.log(response.data);
          dispatch(updateGutterSystemInReducer(response.data));

          message.open({
            type: "success",
            content: "System został zaktualizowany!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete gutter system -> ONLY ADMIN
export const deleteGutterSystem =
  (data: IGutterSystem): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/GutterSystems/${data.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteGutterSystemInReducer(data.id));
          dispatch(addStatusApi({ key: "deleteGutterSystem", type: "success", message: "" }));

          message.open({
            type: "success",
            content: "System został usunięty!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
