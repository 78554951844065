import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const resetSlice = createSlice({
  name: "reset",
  initialState: {},
  reducers: {
    resetRedux: (state, action: PayloadAction<void>) => {
      // Ten reducer nie robi nic, ale zostanie użyty jako punkt wyzwalający resetowania stanu
    },
  },
});

export const { resetRedux } = resetSlice.actions;

export default resetSlice.reducer;
