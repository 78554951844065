import * as React from "react";
const DomaLogoComponentBW = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 1558 300"
    {...props}
  >
    <path
      d="M1912.11 773.384v-9.25a8.323 8.323 0 0 1 2.45-5.95 8.777 8.777 0 0 1 2.68-1.825 7.845 7.845 0 0 1 3.22-.675h17.75c1.47 0 2.87.383 4.2 1.15l.75.45v-10.35h3.4v34.95h-3.4v-1.6l-.75.45c-1.33.767-2.73 1.15-4.2 1.15h-17.75c-1.13 0-2.21-.225-3.22-.675a8.777 8.777 0 0 1-2.68-1.825 8.394 8.394 0 0 1-1.8-2.7 8.504 8.504 0 0 1-.65-3.3Zm3.45-9.25v9.25c0 .7.13 1.358.38 1.975s.6 1.15 1.05 1.6c.45.45.97.808 1.57 1.075.6.267 1.24.4 1.9.4h17.75c.7 0 1.35-.133 1.95-.4a5.21 5.21 0 0 0 1.58-1.075c.45-.45.8-.983 1.05-1.6.25-.617.37-1.275.37-1.975v-9.25c0-1.4-.47-2.583-1.42-3.55-.95-.967-2.13-1.45-3.53-1.45h-17.75c-.66 0-1.3.133-1.9.4-.6.267-1.12.625-1.57 1.075-.45.45-.8.975-1.05 1.575s-.38 1.25-.38 1.95ZM1963.76 755.684h17.75c1.17 0 2.26.225 3.28.675 1.01.45 1.9 1.058 2.65 1.825a8.992 8.992 0 0 1 1.8 2.675c.45 1.017.67 2.108.67 3.275v9.25c0 1.167-.22 2.267-.67 3.3a8.91 8.91 0 0 1-1.8 2.7 8.523 8.523 0 0 1-2.65 1.825c-1.02.45-2.11.675-3.28.675h-17.75c-1.13 0-2.21-.225-3.22-.675a8.777 8.777 0 0 1-2.68-1.825 8.559 8.559 0 0 1-1.8-2.7 8.504 8.504 0 0 1-.65-3.3v-9.25c0-1.167.22-2.258.65-3.275a8.63 8.63 0 0 1 1.8-2.675 8.777 8.777 0 0 1 2.68-1.825 7.845 7.845 0 0 1 3.22-.675Zm-4.9 8.45v9.25c0 .7.13 1.358.38 1.975s.6 1.15 1.05 1.6c.45.45.97.808 1.57 1.075.6.267 1.24.4 1.9.4h17.75c.7 0 1.35-.133 1.95-.4a5.21 5.21 0 0 0 1.58-1.075c.45-.45.8-.983 1.05-1.6.25-.617.37-1.275.37-1.975v-9.25c0-1.4-.47-2.583-1.42-3.55-.95-.967-2.13-1.45-3.53-1.45h-17.75c-.66 0-1.3.133-1.9.4-.6.267-1.12.625-1.57 1.075-.45.45-.8.975-1.05 1.575s-.38 1.25-.38 1.95Z"
      style={{
        fill: "#dedede",
        fillRule: "nonzero",
      }}
      transform="translate(-16411.22 -6410.74) scale(8.58278)"
    />
    <path
      d="M2033.91 755.684c1.17 0 2.25.225 3.25.675 1 .45 1.89 1.058 2.65 1.825a8.297 8.297 0 0 1 1.8 2.675c.44 1.017.65 2.108.65 3.275v17.75h-3.45v-17.75c0-.7-.12-1.35-.37-1.95-.25-.6-.6-1.125-1.05-1.575a5.21 5.21 0 0 0-1.58-1.075 4.61 4.61 0 0 0-1.9-.4h-11.6v22.75h-3.45v-22.75h-16.5v22.75h-3.45v-26.2h35Z"
      style={{
        fill: "#dedede",
        fillRule: "nonzero",
      }}
      transform="translate(-16412.52 -6410.78) scale(8.58278)"
    />
    <path
      d="m2076.46 746.934 19.2 34.95h-3.95l-17.4-31.65-17.35 31.65h-3.95l19.2-34.95h4.25Z"
      style={{
        fill: "#dedede",
        fillRule: "nonzero",
      }}
      transform="translate(-16428.82 -6410.77) scale(8.58278)"
    />
    <circle
      cx={1612.67}
      cy={948.65}
      r={21.24}
      style={{
        fill: "#dedede",
      }}
      transform="matrix(.94999 0 0 .94999 -157.27 -713.679)"
    />
  </svg>
);
export default DomaLogoComponentBW;
