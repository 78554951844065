import React, { useMemo } from "react";
import { Line } from "react-konva";
import { GRID_OFFSET } from "../modules/roofs/components/roofSteps/steps/GeometryStep";
import { IDomaGrid } from "../redux/general/domaGridSlice";

interface DomaGridProps {
  stageWidth: number;
  stageHeight: number;
  scale: number; // Skala rysunku
  zoomScale: number; // Skala zoomowania
  gridSettings: IDomaGrid;
}

const DomaGrid: React.FC<DomaGridProps> = ({ stageWidth, stageHeight, scale, zoomScale, gridSettings }) => {
  const gridLines = useMemo(() => {
    const lines = [];
    // const effectiveSpacing = gridSpacing * zoomScale;

    // scale odnsi się do 1m na rysunku wiec gdy size jest zmienione to trzeba pomnożyc wartości
    const effectiveSpacing = scale * gridSettings.gridSize;

    const gridOffset = GRID_OFFSET * 4;

    // Obliczenie wartości startowych Tu uwzglednia aby w punkcie 0,0  był naroznik siatki
    const startOffsetX = Math.floor(-gridOffset / effectiveSpacing) * effectiveSpacing;
    const startOffsetY = Math.floor(-gridOffset / effectiveSpacing) * effectiveSpacing;
    const endOffsetX = stageWidth + gridOffset;
    const endOffsetY = stageHeight + gridOffset;

    // Linie pionowe
    for (let i = startOffsetX; i <= endOffsetX; i += effectiveSpacing) {
      lines.push(
        <Line
          key={`v${i}`}
          points={[i, startOffsetY, i, endOffsetY]}
          stroke={gridSettings.gridColor}
          strokeWidth={1}
          listening={false}
        />
      );
    }

    // Linie poziome
    for (let j = startOffsetY; j <= endOffsetY; j += effectiveSpacing) {
      lines.push(
        <Line
          key={`h${j}`}
          points={[startOffsetX, j, endOffsetX, j]}
          stroke={gridSettings.gridColor}
          strokeWidth={1}
          listening={false}
        />
      );
    }

    return lines;
  }, [stageWidth, stageHeight, gridSettings, zoomScale, scale, GRID_OFFSET]);

  return <>{gridLines}</>;
};

export default DomaGrid;
