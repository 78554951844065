import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

export const sidebarStatusSlice = createSlice({
  name: "sidebarStatus",
  initialState,
  reducers: {
    changeSidebarStatus: (state) => {
      return !state;
    },
    resetSidebarStatus: (state) => {
      return initialState;
    },
  },
});

export const { changeSidebarStatus, resetSidebarStatus } = sidebarStatusSlice.actions;
export default sidebarStatusSlice.reducer;
