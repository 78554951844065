import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IProductColor } from "../../models/Models";
import { addStatusApi, deleteStatusApi } from "../other/statusApiSlice";
import { AppThunk } from "../store";

const initialState: Array<IProductColor> = [];

export const productColorsSlice = createSlice({
  name: "productColors",
  initialState,
  reducers: {
    addProductColorInReducer: (state, action: PayloadAction<IProductColor>) => {
      let productColor = action.payload;
      return [...state, productColor];
    },
    addProductColorsInReducer: (state, action: PayloadAction<Array<IProductColor>>) => {
      let productColors = action.payload;
      return productColors;
    },
  },
});

export const { addProductColorInReducer, addProductColorsInReducer } = productColorsSlice.actions;
export default productColorsSlice.reducer;

// API

// Add color
export const addColor =
  (data: IProductColor): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .put("/ProductColor", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addProductColorInReducer(response.data));
          dispatch(deleteStatusApi("productColor"));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        dispatch(addStatusApi({ key: "productColor", type: "error", message: "" }));
        // console.error(error);
      });
  };
