import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect } from "react";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { useFirstRender } from "../../../hooks/useFirstRender";
import { ISalesForm } from "../../../models/Models";

interface SalesFormPositionProps {
  salesForm?: ISalesForm;
  updateSalesForm: (salesForm: ISalesForm) => void;
  deleteSalesForm: (salesForm: ISalesForm) => void;
}

interface SalesFormState {
  isActive: boolean;
  name: string;
  amount: number;
  price: number;
  currency: "PLN" | "EUR";
}

const SalesFormPosition: React.FC<SalesFormPositionProps> = ({ salesForm, updateSalesForm, deleteSalesForm }) => {
  const firstRender = useFirstRender();

  const [salesFormState, setSalesFormState] = React.useState<SalesFormState>({
    isActive: false,
    name: "",
    amount: 0,
    price: 0,
    currency: "PLN",
  });

  // useEffect(() => {
  //   console.log("salesFormState", salesFormState);
  // });

  useEffect(() => {
    setSalesFormState({
      isActive: salesForm?.isActive !== undefined ? salesForm?.isActive : false,
      name: salesForm?.name || "",
      amount: salesForm?.amount || 0,
      price: salesForm?.price || 0,
      currency: salesForm?.currency || "PLN",
    });
  }, [salesForm]);

  useEffect(() => {
    if (
      salesForm &&
      !firstRender &&
      (salesFormState.name !== salesForm.name ||
        salesFormState.amount !== salesForm.amount ||
        salesFormState.price !== salesForm.price ||
        salesFormState.isActive !== salesForm.isActive ||
        salesFormState.currency !== salesForm.currency)
    ) {
      // console.log("updateSalesForm", salesFormState);
      updateSalesForm({
        ...salesForm,
        ...salesFormState,
      });
    }
  }, [salesFormState]);

  return (
    <Row
      gutter={16}
      // key={salesForm?.id}
      style={{
        opacity: salesFormState.isActive ? 1 : 0.5,
      }}
    >
      <Col span={12}>
        <Form.Item label="Nazwa formy" labelCol={{ span: 24 }}>
          <Input
            value={salesForm?.name}
            min={0}
            style={{ width: "100%" }}
            onChange={(e) => {
              setSalesFormState((prevState) => ({ ...prevState, name: e.target.value }));
            }}
            disabled={!salesFormState.isActive}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Ilość jednostek podstawowych" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={salesForm?.amount}
            min={0}
            style={{ width: "100%" }}
            onChange={(value) => {
              setSalesFormState((prevState) => ({ ...prevState, amount: value as number }));
            }}
            disabled={!salesFormState.isActive}
          />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="Cena za formę [zł]" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={salesForm?.price}
            allowNegative
            min={0}
            style={{ width: "100%" }}
            onChange={(value) => {
              setSalesFormState((prevState) => ({ ...prevState, price: value as number }));
            }}
            disabled={!salesFormState.isActive}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Waluta" labelCol={{ span: 24 }}>
          <Select
            value={salesForm?.currency}
            placeholder="Wybierz walutę"
            onChange={(value) => {
              setSalesFormState((prevState) => ({ ...prevState, currency: value as "PLN" | "EUR" }));
            }}
            options={[
              { value: "PLN", label: "PLN" },
              { value: "EUR", label: "EUR" },
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item
          label="Aktywne"
          labelCol={{ span: 24, style: { textAlign: "center" } }}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Checkbox
              checked={salesForm?.isActive}
              onChange={(e) => {
                setSalesFormState((prevState) => ({ ...prevState, isActive: e.target.checked }));
              }}
            />
          </div>
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              if (salesForm) {
                deleteSalesForm(salesForm);
              }
            }}
            disabled={!salesFormState.isActive}
          >
            <DeleteOutlined />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SalesFormPosition;
