import { Button, Card, Checkbox, Collapse, List, Modal, Radio, Select, Tooltip, Typography } from "antd";
import { is } from "immer/dist/internal";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  ITemplateImage,
  updateTemplatePositionImage,
  updateTemplateScaleImage,
  updateTemplateXYScaleImage,
  updateVisibleTemplateImage,
} from "../../../../../../redux/roofs/templateImageSlice";
import EditRoofSlopeOutlineEdgeModal from "./EditRoofSlopeOutlineEdgeModal";
import Function1Handler2 from "./Functions/Function1Handler2";
import Function2Handler from "./Functions/Function2Handler";
import Function3Handler, { calculatePerpendicularPoint } from "./Functions/Function3Handler";
import { IRSO, IRSOEdge, IRSOPoint, RSOPoint2D } from "./Models/RoofSlopeOutlineModels";
import RoofSlopeOutlineEdge from "./RoofSlopeOutlineEdge";
import RoofSlopeOutlineEditModal from "./RoofSlopeOutlineEditModal";
import RoofSlopeOutlineLinkedLine from "./RoofSlopeOutlineLinkedLine";
import RoofSlopeOutlinePosition from "./RoofSlopeOutlinePosition";
import RoofSlopeOutlinesTable from "./RoofSlopeOutlinesTable";
import RSOEdgesTable from "./RSOEdgesTable";
import { Stage, Layer, Circle, Transformer, Image, Group, Line } from "react-konva";
import { getGridPoints, getImageFromString64 } from "../../../../../../helpers/Helpers";
import DomaUnit from "../../others/DomaUnit";
import AddHoleForOutlineModal, { HoleType } from "./AddHoleForOutlineModal";
import FunctionHoleHandler from "./Functions/FunctionHoleHandler";
import RoofSlopeHole from "./RoofSlopeHole";
import {
  AlignCenterOutlined,
  BorderInnerOutlined,
  CheckOutlined,
  CloseOutlined,
  FullscreenExitOutlined,
  MenuUnfoldOutlined,
  RedoOutlined,
  TableOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { isPossibleRedo, isPossibleUndo } from "../../../../../../redux/roofs/roofDrawingStoreSlice";
import store from "../../../../../../redux/store";
import { redoDrawing, undoDrawing } from "../../../../../../redux/roofs/roofDrawingStoreSlice";
import { saveDrawing } from "../../../../../../redux/roofs/roofDrawingStoreSlice";
import { updatePointTracking } from "../../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import DomaGrid from "../../../../../../helpers/DomaGrid";
import { setDomaGridInReducer } from "../../../../../../redux/general/domaGridSlice";
import { getEdgeTypesSelect, GRID_OFFSET, TypeGutteringElement } from "../GeometryStep";
import { EdgeType } from "../../../../../../models/Enums";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { v4 as uuidv4 } from "uuid";
import { IBasicPoint } from "../../../../../../models/Models";
import { addWindowHole } from "../../../../../../redux/calculationProcess/windowHolesSlice";
import FunctionAddHoleHandler from "./Functions/FunctionAddHoleHandler";
import { TypeRoofAccessory } from "../../../../../../redux/roofs/roofAccessoriesSlice";
import PanelFlowGutters from "../Components/PanelFlowGutters";
import PanelFlowRoofAccessories from "../Components/PanelFlowRoofAccessories";
import PanelFlowGeometryData from "../Components/PanelFlowGeometryData";
import PanelFlowWindowHoles, { IWindowHole } from "../../others/PanelFlowWindowHoles";
import FunctionAddRoofAccessoryHandler from "./Functions/FunctionAddRoofAccessoryHandler";
import RoofAccessoryElement from "../../../geometryRoofAccessories/RoofAccessoryElement";
import { act } from "react-dom/test-utils";
import FunctionAddGutterElementHandler from "./Functions/FunctionAddGutterElementHandler";
import GutteringElement from "../../../geometryGuttering/GutteringElement";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import View3DModal from "../../others/View3DModal";
import { PRIMARY_COLOR } from "../../../../../../theme";

// CONSTANTS
const DIST = 5;
const SCALE_BY = 1.1;
const LEFT_MOUSE = 0;
const SCROLL_MOUSE = 1;
const RIGHT_MOUSE = 2;

Konva.dragButtons = [SCROLL_MOUSE]; // Określenie scroll przycisku myszki do funkcji draggable

enum Functions {
  Function1 = "draw-roof-slope-outline", // Rysuj obrys połaci
  Function2 = "Function2",
  Function3 = "Function3",
  Function4 = "Function4", // Przypisz wektor spadku
  FunctionHole = "add-hole", // Funkcja do rysowania otworów
  FunctionAddHole = "add-rectangle-hole", // Funkcja do dodawania otworów prostokątnych
  FunctionFastEdgesMode = "fast-edges-mode", // Funkcja jako tryb szybkiej zmiany typów krawędzi
  FunctionMoveVertical = "move-vertical", // Funkcja do przesuwania połaci w pionie (po osi z)
  FunctionAddRoofAccessory = "add-roof-accessory", // Funkcja do dodawania akcesoriów dachowych
  FunctionAddGutterElement = "add-gutter-element", // Funkcja do dodawania elementów orynnowania
}

type Props = {};

const RoofSlopeOutlineMain: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  // const scale = useAppSelector((state) => state.roofProcessStatus.unit); // TODO chyba to nie to
  const scale = useAppSelector((state) => state.drawingScale); // Wcześniej było 70 na sztywno

  const [distance, setDistance] = useState<number>(1);

  const [activeFunction, setActiveFunction] = useState<Functions | null>(null);

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);
  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage
  const [eventMoveStage, setEventMoveStage] = useState<KonvaEventObject<MouseEvent> | null>(null);
  const [eventClickStage, setEventClickStage] = useState<KonvaEventObject<MouseEvent> | null>(null);
  const point0Ref = useRef<Konva.Circle>(null); // Referencja do niewidocznego punktu

  const rsoPoints = useAppSelector((state) => state.rsoPoints) || [];
  const rsoEdges = useAppSelector((state) => state.rsoEdges) || [];
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes) || [];
  const rsoHoles = useAppSelector((state) => state.rsoHoles) || [];
  const rsoLinkedEdges = useAppSelector((state) => state.rsoLinkedEdges) || [];

  // Edycja Edge
  const [isEdgeModalVisible, setIsEdgeModalVisible] = useState(false); // Czy modal widoczny
  const [selectedEdge, setSelectedEdge] = useState<IRSOEdge | null>(null); // Dane wybranego Edge

  // Tryb szybkiej zmainy typow krawędzi
  const [selectedEdgeType, setSelectedEdgeType] = useState<EdgeType>(EdgeType.Eaves); // Wybrany typ krawędzi do klikania

  // Funkcja zwracająca listę wszystkich typów krawędzi
  const getAllEdgeTypes = () => {
    const types = getEdgeTypesSelect();
    return types;
  };

  // Edycja Outline
  const [isEditOutlineModalVisible, setIsEditOutlineModalVisible] = useState(false); // Czy modal widoczny
  const [selectedOutline, setSelectedOutline] = useState<IRSO | null>(null); // Dane wybranego Outline
  const [activeOutline, setActiveOutline] = useState<IRSO | null>(null); // Dane wybranego Outline - po anjechaniu myszką
  const [isDeclineVectorDrawing, setIsDeclineVectorDrawing] = useState(false); // Czy rysowany jest wektor spadku
  const [startPointDeclineVector, setStartPointDeclineVector] = useState<RSOPoint2D | null>(null); // Punkt początkowy wektora spadku
  const [endPointDeclineVector, setEndPointDeclineVector] = useState<RSOPoint2D | null>(null); // Punkt końcowy wektora spadku

  const [isEdgeForDeclineVectorSelecting, setIsEdgeForDeclineVectorSelecting] = useState(false); // Czy aktywny wybór krawędzi do obliczenia wektora spadku
  const [selectedEdgeForDeclineVector, setSelectedEdgeForDeclineVector] = useState<IRSOEdge | null>(null); // Wybrany Edge do przypisania wektora spadku

  // Podkład
  const templateImageStoreState = useAppSelector((state) => state.templateImage);
  const [templateImageStore, setTemplateImageStore] = useState<ITemplateImage>(templateImageStoreState);
  const shapeRef = useRef<Konva.Image>(null); // referencja do image
  const trRef = useRef<Konva.Transformer>(null); // referencja do transformer
  const [isSelectedTemplateImage, setIsSelectedTemplateImage] = useState(false); // Czy podkład jest kliknięty i aktywny do modyfikacji

  const [visibleHoleModal, setVisibleHoleModal] = useState<boolean>(false);
  const [selectedHoleType, setSelectedHoleType] = useState<HoleType>("shape");

  const [activeWindowHolePanel, setActiveWindowHolePanel] = useState<boolean>(false);
  const [readyForWindowPoint, setReadyForWindowPoint] = useState<boolean>(false);
  const [pointForWindow, setPointForWindow] = useState<IBasicPoint | null>(null);
  const [selectedWindowHole, setSelectedWindowHole] = useState<IWindowHole | null>(null);

  const [shouldCloseOutline, setShouldCloseOutline] = useState(false); // Triger do wywołania zakończenia rysowania obrysu połaci (zamknięcie)

  const [undoLastPoint, setUndoLastPoint] = useState(false); // Triger do cofnięcia ostatnio dodanego punktu

  const domaGrid = useAppSelector((state) => state.domaGrid);

  const [activeAddGutteringElement, setActiveAddGutteringElement] = useState<TypeGutteringElement>(null); // Czy aktywne dodawanie elementu orynnowania i jeśli tak to co dokładnie
  const [activeGuttering, setActiveGuttering] = useState<boolean>(false); // Czy aktywne system orynnowania
  const gutteringElements = useAppSelector((state) => state.gutteringElements); // odwołanie do redux po elementy orynnowania w geometrii

  const [activeAddRoofAccessory, setActiveAddRoofAccessory] = useState<TypeRoofAccessory>(null);
  const [activeRoofAccessories, setActiveRoofAccessories] = useState<boolean>(false);
  const roofAccessories = useAppSelector((state) => state.roofAccessories);

  const [activeGeometryData, setActiveGeometryData] = useState<boolean>(false);
  const geometryData = useAppSelector((state) => state.geometryData);

  // Widocznośc modalu z podglądem 3D
  const [view3DModal, setView3DModal] = useState(false);

  // Znajdź komponent który ma name "sidebar-doma" i pobierz jego szerokość
  const sidebarDomaWidth = document.querySelector("[name='sidebar-doma']")?.clientWidth;

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (templateImageStoreState.string64 && templateImageStoreState.x === 0 && templateImageStoreState.y === 0) {
      const img = new window.Image();
      img.src = templateImageStoreState.string64;

      img.onload = () => {
        const node = shapeRef.current;
        if (node) {
          node.x(0);
          node.y(0);

          const sW = stage.current?.width() as number;
          const sH = stage.current?.height() as number;

          const w = node.width();
          const h = node.height();

          // Okreslenie skali
          const rectRatio = sW / sH;
          const imageRatio = w / h;
          let ratio = rectRatio > imageRatio ? sH / h : sW / w;

          ratio = ratio / 1.1;

          // Przypisanie skali
          node.scale({ x: ratio, y: ratio });

          const newW = w * ratio;
          const newH = h * ratio;

          const x = sW / 2 - newW / 2;
          const y = sH / 2 - newH / 2;

          dispatch(updateTemplateXYScaleImage({ x: x, y: y, scaleX: ratio, scaleY: ratio }));

          node.x(x);
          node.y(y);
        }
      };
    }

    setTemplateImageStore(templateImageStoreState);
  }, [templateImageStoreState]);

  // Przypisnanie transformer po kliknięciu na zdjęcie
  useEffect(() => {
    if (isSelectedTemplateImage) {
      Konva.dragButtons = [LEFT_MOUSE];
      trRef?.current?.nodes([shapeRef?.current as Konva.Image]);
    } else {
      Konva.dragButtons = [SCROLL_MOUSE];
    }
  }, [isSelectedTemplateImage]);

  useEffect(() => {
    // Jeśli start oraz end sa wypełnione dla wektora spadku
    if (startPointDeclineVector && endPointDeclineVector) {
      // Kończymy funkcję rysowania wektora
      setIsDeclineVectorDrawing(false);

      // Otwoeramy modal
      setIsEditOutlineModalVisible(true);
    }

    // Jeśłi krawędz jest wybrana dla wektora spadku
  }, [startPointDeclineVector, endPointDeclineVector]);

  useEffect(() => {
    // Jeśli start oraz end sa wypełnione dla wektora spadku
    if (selectedEdgeForDeclineVector) {
      // Kończymy funkcję rysowania wektora
      setIsEdgeForDeclineVectorSelecting(false);

      // Punkty tylko dla zaznaczonej połaci czyli selcectedoutline
      const pointIds = selectedOutline?.pointIds || [];
      const planePoints: IRSOPoint[] = pointIds
        .map((id) => rsoPoints.find((p) => p.id === id))
        .filter((p): p is IRSOPoint => p != null) as IRSOPoint[];

      const centerPoint = findCentroid(planePoints);

      const secondPoint = calculatePerpendicularPoint(rsoPoints, selectedEdgeForDeclineVector, centerPoint);

      setStartPointDeclineVector(centerPoint);
      setEndPointDeclineVector(secondPoint);

      // Otwoeramy modal
      setIsEditOutlineModalVisible(true);
    }

    // Jeśłi krawędz jest wybrana dla wektora spadku
  }, [isEdgeForDeclineVectorSelecting, selectedEdgeForDeclineVector]);

  const onMouseDownStage = (e: KonvaEventObject<MouseEvent>) => {
    e.target.preventDefault();
    setEventClickStage(e);

    if (readyForWindowPoint) {
      const mp = store.getState().mousePosition;
      setPointForWindow(mp);
      setReadyForWindowPoint(false);
    }

    if (e.evt.button === 2) {
      // Wyłączenie dodawania elementów orynnowania po kliknieciu prawego przycisku myszki
      if (activeAddGutteringElement !== null) {
        setActiveAddGutteringElement(null);
      }

      if (activeAddRoofAccessory !== null) {
        setActiveAddRoofAccessory(null);
      }
    }
  };

  useEffect(() => {
    if (pointForWindow) {
      if (selectedWindowHole) {
        // Tu dodać otwór okienny do połaci

        dispatch(
          addWindowHole({
            width: selectedWindowHole.width / 100,
            height: selectedWindowHole.height / 100,
            index: 0, // Nie ma tu znaczenia bo w reduxie jest ustalane
            additionProducts: [],
            windowProducts: [],
          })
        );
      }

      dispatch(invalidateCalculation());

      // Czyszczenie
      setPointForWindow(null);
      setSelectedWindowHole(null);
    }
  }, [pointForWindow]);

  // Funkcja wwywoływana po wybraniu funkcji z menu lub kliknieciu na aktywną funckję
  const handleFunctionClick = (func: Functions) => {
    if (activeFunction === func) {
      setActiveFunction(null);
    } else {
      setActiveFunction(func);
    }
  };

  // Funkcja do opóźnionego ustawiania stanu
  const delaySetActiveFunction = (newFunction: Functions | null, delay: number) => {
    setTimeout(() => {
      setActiveFunction(newFunction);
    }, delay);
  };

  const handleClosePanelFlowRoofAccessories = useCallback(() => {
    setActiveRoofAccessories(false);
    setActiveAddRoofAccessory(null);
    delaySetActiveFunction(null, 100);
  }, [setActiveRoofAccessories, setActiveAddRoofAccessory]);

  const handleClosePanelFlowGutters = useCallback(() => {
    setActiveGuttering(false);
    setActiveAddGutteringElement(null);
    delaySetActiveFunction(null, 100);
  }, [setActiveGuttering, setActiveAddGutteringElement]);

  function zoomStage(event: any) {
    // event.evt.preventDefault();
    // event.stopPropagation();

    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScale = s.scaleX();
      const pointerX = s.getPointerPosition()?.x as number;
      const pointerY = s.getPointerPosition()?.y as number;
      const mousePointTo = {
        x: (pointerX - s.x()) / oldScale,
        y: (pointerY - s.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? Number((oldScale / SCALE_BY).toFixed(1)) : Number((oldScale * SCALE_BY).toFixed(1));
      s.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      s.position(newPos);
      s.batchDraw();

      setZoomScale(newScale);
    }
  }

  const openEdgeModal = (edge: IRSOEdge) => {
    setSelectedEdge(edge);
    setIsEdgeModalVisible(true);
  };

  const closeEdgeModal = () => {
    if (selectedEdge) {
      setSelectedEdge(null);
    }
    setIsEdgeModalVisible(false);
  };

  const openEditOutlineModal = (outline: IRSO) => {
    setSelectedOutline(outline);
    setIsEditOutlineModalVisible(true);
  };

  const closeEditOutlineModal = () => {
    if (selectedOutline) {
      setSelectedOutline(null);
    }

    setStartPointDeclineVector(null);
    setEndPointDeclineVector(null);
    setSelectedEdgeForDeclineVector(null);

    setIsEditOutlineModalVisible(false);
  };

  const activeUndo = () => {
    if (activeFunction === Functions.Function1 || activeFunction === Functions.FunctionHole) {
      return true;
    } else {
      return isPossibleUndo(store.getState());
    }
  };

  const activeRedo = () => {
    return isPossibleRedo(store.getState());
  };

  const isPlaneHasDeclineVector = (item: IRSO) => {
    return item.declineVectoor.x !== 0 || item.declineVectoor.y !== 0;
  };

  // Czy wszystkie połaci emają okreśłony wektor spadku
  const isAllPlanesHasDeclineVector = (rsoPlanes: IRSO[]) => {
    return rsoPlanes.every((p) => isPlaneHasDeclineVector(p));
  };

  // Czy wszystkie kraędzie mają określony typ
  const isAllEdgesHasType = (rsoEdges: IRSOEdge[]) => {
    return rsoEdges.every((e) => e.type !== 99);
  };

  interface IAddPoint {
    value: number;
    direction: "up" | "down" | "left" | "right";
  }

  const [newPointAdd, setNewPointAdd] = useState<IAddPoint | null>(null);

  const handleAddPointClick = (direction: "up" | "down" | "left" | "right") => {
    if (newPointAdd == null) {
      const newPointAdd: IAddPoint = {
        value: distance * scale,
        direction: direction,
      };
      setNewPointAdd(newPointAdd);
    }
  };

  // TODo zdefiniować interfejs dla punktów, które ida do kalkulatora
  const addNextPoint = (direction: string) => {
    if (direction === "left") {
      handleAddPointClick("left");
    } else if (direction === "right") {
      handleAddPointClick("right");
    } else if (direction === "up") {
      handleAddPointClick("up");
    } else if (direction === "down") {
      handleAddPointClick("down");
    }
  };

  function findCentroid(polygon: { x: number; y: number }[]) {
    let centroid = { x: 0, y: 0 };
    let signedArea = 0;
    let x0 = 0; // Współrzędne bieżącego wierzchołka
    let y0 = 0; // Współrzędne bieżącego wierzchołka
    let x1 = 0; // Współrzędne następnego wierzchołka
    let y1 = 0; // Współrzędne następnego wierzchołka
    let a = 0; // Częściowa obszar wielokąta

    // Dla wszystkich wierzchołków
    for (let i = 0; i < polygon.length; ++i) {
      x0 = polygon[i].x;
      y0 = polygon[i].y;
      x1 = polygon[(i + 1) % polygon.length].x;
      y1 = polygon[(i + 1) % polygon.length].y;
      a = x0 * y1 - x1 * y0;
      signedArea += a;
      centroid.x += (x0 + x1) * a;
      centroid.y += (y0 + y1) * a;
    }

    signedArea *= 0.5;
    centroid.x /= 6.0 * signedArea;
    centroid.y /= 6.0 * signedArea;

    return centroid;
  }

  const disabledMainFlowPlanesAndEdges = () => {
    if (activeGuttering || activeRoofAccessories || activeGeometryData || activeWindowHolePanel) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container
      bodyStyle={{
        padding: "0px",
        width: "100%",
      }}
    >
      <MenuBar>
        <MenuBarContent>
          <MenuButtonContainer>
            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeWindowHolePanel && (
                <Button
                  type={activeFunction === Functions.Function1 ? "primary" : "default"}
                  onClick={() => handleFunctionClick(Functions.Function1)}
                >
                  Dodaj połać
                </Button>
              )}

            {activeFunction === Functions.Function1 &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting && (
                <Button
                  type={activeFunction === Functions.Function1 ? "primary" : "default"}
                  onClick={() => setActiveFunction(null)}
                  danger
                >
                  Anuluj rysowanie
                </Button>
              )}
            {activeFunction === Functions.Function1 &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting && (
                <Button
                  type={activeFunction === Functions.Function1 ? "primary" : "default"}
                  onClick={() => setShouldCloseOutline(true)}
                >
                  Zamknij połać
                </Button>
              )}

            {/* {activeFunction === null && !isDeclineVectorDrawing && !isEdgeForDeclineVectorSelecting && (
              <Button
                disabled
                type={activeFunction === Functions.Function2 ? "primary" : "default"}
                onClick={() => handleFunctionClick(Functions.Function2)}
              >
                Powiąż krawędzie
              </Button>
            )}

            {activeFunction === Functions.Function2 && (
              <Button
                type={activeFunction === Functions.Function2 ? "primary" : "default"}
                onClick={() => handleFunctionClick(Functions.Function2)}
                danger
              >
                Anuluj powiązanie krawędzi
              </Button>
            )}

            {(isDeclineVectorDrawing || isEdgeForDeclineVectorSelecting) && (
              <Button
                type={"primary"}
                onClick={() => {
                  setIsDeclineVectorDrawing(false);
                  setIsEdgeForDeclineVectorSelecting(false);
                  setStartPointDeclineVector(null);
                  setEndPointDeclineVector(null);
                  setSelectedEdgeForDeclineVector(null);
                }}
                danger
              >
                Anuluj powiązanie krawędzi
              </Button>
            )} */}

            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeWindowHolePanel && (
                <Button
                  type={activeFunction === Functions.FunctionHole ? "primary" : "default"}
                  onClick={() => {
                    // setVisibleHoleModal(true);
                    handleFunctionClick(Functions.FunctionHole);
                  }}
                >
                  Pusty otwór
                </Button>
              )}

            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeWindowHolePanel && (
                <Button
                  type={activeFunction === Functions.FunctionHole ? "primary" : "default"}
                  onClick={() => {
                    setActiveWindowHolePanel(true);
                  }}
                >
                  Otwór okienny
                </Button>
              )}

            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeGuttering &&
              !activeRoofAccessories &&
              !activeWindowHolePanel && (
                <Button
                  type={activeFunction === Functions.FunctionFastEdgesMode ? "primary" : "default"}
                  onClick={() => handleFunctionClick(Functions.FunctionFastEdgesMode)}
                >
                  Krawędzie
                </Button>
              )}

            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeGuttering &&
              !activeRoofAccessories &&
              !activeGeometryData &&
              !activeWindowHolePanel && (
                <Button
                  onClick={() => {
                    handleFunctionClick(Functions.FunctionAddGutterElement);
                    setActiveGuttering(true);
                  }}
                >
                  Orynnowanie
                </Button>
              )}

            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeGuttering &&
              !activeRoofAccessories &&
              !activeGeometryData &&
              !activeWindowHolePanel && (
                <Button
                  onClick={() => {
                    handleFunctionClick(Functions.FunctionAddRoofAccessory);
                    setActiveRoofAccessories(true);
                  }}
                >
                  Akcesoria dachowe
                </Button>
              )}

            {activeFunction === null &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting &&
              !activeGuttering &&
              !activeRoofAccessories &&
              !activeGeometryData &&
              !activeWindowHolePanel && (
                <Button onClick={() => setActiveGeometryData(true)}>Punkty geometryczne</Button>
              )}

            {activeFunction === Functions.FunctionFastEdgesMode &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting && (
                <Button
                  type={activeFunction === Functions.FunctionFastEdgesMode ? "primary" : "default"}
                  onClick={() => handleFunctionClick(Functions.FunctionFastEdgesMode)}
                  danger
                >
                  Zakończ ustalanie typów krawędzi
                </Button>
              )}

            {/* {activeFunction === null && !isDeclineVectorDrawing && !isEdgeForDeclineVectorSelecting && (
              <Button
                disabled
                type={activeFunction === Functions.FunctionMoveVertical ? "primary" : "default"}
                onClick={() => handleFunctionClick(Functions.FunctionMoveVertical)}
              >
                Położenie w pionie
              </Button>
            )} */}

            {activeFunction === Functions.FunctionMoveVertical &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting && (
                <Button
                  type={activeFunction === Functions.FunctionMoveVertical ? "primary" : "default"}
                  onClick={() => handleFunctionClick(Functions.FunctionMoveVertical)}
                  danger
                >
                  Zakończ zmianę położenia w pionie
                </Button>
              )}

            {activeFunction === Functions.FunctionHole &&
              !isDeclineVectorDrawing &&
              !isEdgeForDeclineVectorSelecting && (
                <Button
                  type={activeFunction === Functions.FunctionHole ? "primary" : "default"}
                  onClick={() => {
                    setActiveFunction(null);
                  }}
                >
                  Przerwij rysowanie otworu
                </Button>
              )}

            {templateImageStore.string64 && !isDeclineVectorDrawing && !isEdgeForDeclineVectorSelecting && (
              <Button onClick={() => dispatch(updateVisibleTemplateImage(!templateImageStore.visible))}>
                {templateImageStore.visible ? "Ukryj podkład" : "Pokaż podkład"}
              </Button>
            )}

            {activeFunction === Functions.Function1 && (
              <>
                <div style={{ display: "flex", marginLeft: "64px", alignItems: "center" }}>
                  Długość:
                  <DomaInputNumber
                    value={distance}
                    onChange={(value) => setDistance(value as number)}
                    style={{ marginLeft: "8px", marginRight: "8px" }}
                  />
                  [m]
                </div>

                <div style={{ display: "flex", gap: "8px", marginLeft: "8px", alignItems: "center" }}>
                  <Tooltip
                    title={
                      <TooltipText>
                        Narysuj linię w <b>LEWĄ</b> stronę od ostatniego punktu
                      </TooltipText>
                    }
                    color={PRIMARY_COLOR}
                  >
                    <Button type="primary" onClick={() => addNextPoint("left")}>
                      ←
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={
                      <TooltipText>
                        Narysuj linię w <b>PRAWĄ</b> stronę od ostatniego punktu
                      </TooltipText>
                    }
                    color={PRIMARY_COLOR}
                  >
                    <Button type="primary" onClick={() => addNextPoint("right")}>
                      →
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={
                      <TooltipText>
                        Narysuj linię w <b>GÓRĘ</b> stronę od ostatniego punktu
                      </TooltipText>
                    }
                    color={PRIMARY_COLOR}
                  >
                    <Button type="primary" onClick={() => addNextPoint("up")}>
                      ↑
                    </Button>
                  </Tooltip>

                  <Tooltip
                    title={
                      <TooltipText>
                        Narysuj linię w <b>DÓŁ</b> stronę od ostatniego punktu
                      </TooltipText>
                    }
                    color={PRIMARY_COLOR}
                  >
                    <Button type="primary" onClick={() => addNextPoint("down")}>
                      ↓
                    </Button>
                  </Tooltip>
                </div>
              </>
            )}
          </MenuButtonContainer>

          {/* Right side */}
          <MenuButtonContainer>
            <Button
              onClick={() => {
                setView3DModal(true);
              }}
            >
              3D
            </Button>

            <Tooltip title={<TooltipText>Uwzględniaj punkty wspólne</TooltipText>} color={PRIMARY_COLOR}>
              <Button
                type={roofProcessFunctionsStatus.pointTracking ? "primary" : "default"}
                onClick={() => {
                  dispatch(updatePointTracking(!roofProcessFunctionsStatus.pointTracking));
                }}
              >
                <FullscreenExitOutlined />
              </Button>
            </Tooltip>

            {domaGrid.gridVisible && (
              <Tooltip title={<TooltipText>Śledzenie siatki</TooltipText>} color={PRIMARY_COLOR}>
                <Button
                  type={domaGrid.gridSnapping ? "primary" : "default"}
                  onClick={() =>
                    dispatch(setDomaGridInReducer({ ...domaGrid, gridSnapping: !domaGrid.gridSnapping }))
                  }
                >
                  <BorderInnerOutlined />
                </Button>
              </Tooltip>
            )}

            <Tooltip title={<TooltipText>Siatka</TooltipText>} color={PRIMARY_COLOR}>
              <Button
                type={domaGrid.gridVisible ? "primary" : "default"}
                onClick={() => dispatch(setDomaGridInReducer({ ...domaGrid, gridVisible: !domaGrid.gridVisible }))}
              >
                <TableOutlined />
              </Button>
            </Tooltip>

            <Button
              disabled={!activeUndo()}
              type="default"
              onClick={() => {
                // Jeśli w trakcie rysownaia połaci lub w trakcie rysowania otworu
                if (activeFunction === Functions.Function1 || activeFunction === Functions.FunctionHole) {
                  if (!undoLastPoint) {
                    setUndoLastPoint(true);
                  }
                } else {
                  // Jeśli w innych momentach (np. dodanie kolejnej połaci, zmiana typu krawędzi itp.)
                  dispatch(undoDrawing(store.getState()));
                }
              }}
            >
              <UndoOutlined />
            </Button>
            <Button
              disabled={!activeRedo()}
              onClick={() => {
                // To dotyczy tylko zmian większych z zapisem stanu - nie dotyczy rysowania połaci i otworów
                dispatch(redoDrawing(store.getState()));
              }}
            >
              <RedoOutlined />
            </Button>
          </MenuButtonContainer>
        </MenuBarContent>
      </MenuBar>

      {isEdgeModalVisible && selectedEdge && (
        <EditRoofSlopeOutlineEdgeModal rsoe={selectedEdge} closeEdgeModal={closeEdgeModal} />
      )}

      {isEditOutlineModalVisible && selectedOutline && (
        <RoofSlopeOutlineEditModal
          model={selectedOutline}
          activateDrawDeclineVector={() => {
            // Aktywacja funkcji dla rysowania wektora spadku
            // Ale najpierw upenic sie ze są czyste pola
            if (startPointDeclineVector) {
              setStartPointDeclineVector(null);
            }
            if (endPointDeclineVector) {
              setEndPointDeclineVector(null);
            }
            if (selectedEdgeForDeclineVector) {
              setSelectedEdgeForDeclineVector(null);
            }
            setSelectedEdgeForDeclineVector(null);
            setIsDeclineVectorDrawing(true);
            setIsEditOutlineModalVisible(false);
            setIsEdgeForDeclineVectorSelecting(false);
          }}
          activateGetEdgeDeclineVector={() => {
            // Aktywacja pobierania krawędzi okapu jako baza dla wektora spadku
            setIsEdgeForDeclineVectorSelecting(true);
            setIsEditOutlineModalVisible(false);
          }}
          startPointDeclineVector={startPointDeclineVector}
          endPointDeclineVector={endPointDeclineVector}
          selectedEdge={selectedEdgeForDeclineVector}
          closeModal={() => {
            closeEditOutlineModal();
          }}
          save={() => {
            setStartPointDeclineVector(null);
            setEndPointDeclineVector(null);
            setIsEditOutlineModalVisible(false);
            setIsEdgeForDeclineVectorSelecting(false);
            setSelectedEdgeForDeclineVector(null);

            dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
          }}
        />
      )}

      {view3DModal && (
        <View3DModal
          open={view3DModal}
          onOk={() => setView3DModal(false)}
          onCancel={() => setView3DModal(false)}
          roofPoints={[]}
          roofPlanes={[]}
          roofEdges={[]}
          rsoPoints={rsoPoints}
          rsoPlanes={rsoPlanes}
          rsoEdges={rsoEdges}
        />
      )}

      {/* {visibleHoleModal && (
        <AddHoleForOutlineModal
          onOk={(holeType: HoleType) => {
            setSelectedHoleType(holeType);
            handleFunctionClick(Functions.FunctionHole);
            setVisibleHoleModal(false);
          }}
          closeModal={() => setVisibleHoleModal(false)}
        />
      )} */}

      <StyledStageParent ref={stageParent}>
        {/* Panel do orynnowania */}
        {activeGuttering && (
          <PanelFlowGutters
            extended={false}
            close={handleClosePanelFlowGutters}
            activeAddGutteringElement={activeAddGutteringElement}
            setActiveAddGutteringElement={(value) => {
              setActiveAddGutteringElement(value);
            }}
          />
        )}

        {/* Panel do akcesorii dachowych */}
        {activeRoofAccessories && (
          <PanelFlowRoofAccessories
            extended={true}
            close={handleClosePanelFlowRoofAccessories}
            activeAddRoofAccessory={activeAddRoofAccessory}
            setActiveAddRoofAccessory={setActiveAddRoofAccessory}
          />
        )}

        {/* Panel do danych geometrycznych */}
        {activeGeometryData && (
          <PanelFlowGeometryData
            close={() => {
              setActiveGeometryData(false);
            }}
          />
        )}

        {activeWindowHolePanel && (
          <PanelFlowWindowHoles
            isAutomatic={false}
            close={() => {
              if (activeFunction !== null) setActiveFunction(null);
              if (selectedWindowHole) setSelectedWindowHole(null);
              if (readyForWindowPoint) setReadyForWindowPoint(false);

              setActiveWindowHolePanel(false);
            }}
            readyForWindowPoint={readyForWindowPoint}
            setReadyForWindowPoint={(v: boolean) => {
              setReadyForWindowPoint(v);
              handleFunctionClick(Functions.FunctionAddHole);
            }}
            setSelectedWindowHole={(v: IWindowHole) => setSelectedWindowHole(v)}
            onCancelClick={() => {
              if (activeFunction !== null) setActiveFunction(null);
              if (selectedWindowHole) setSelectedWindowHole(null);
              if (readyForWindowPoint) setReadyForWindowPoint(false);
            }}
          />
        )}

        <StyledStage
          ref={stage}
          width={dimStageParent.width}
          height={900}
          onMouseMove={(e: KonvaEventObject<MouseEvent>) => {}}
          onMouseDown={(e: KonvaEventObject<MouseEvent>) => {
            onMouseDownStage(e);
          }}
          onContextMenu={(e: KonvaEventObject<MouseEvent>) => {
            e.evt.preventDefault();
          }}
          onWheel={zoomStage}
          draggable
        >
          {templateImageStore?.visible && (
            <Layer>
              {templateImageStore.string64 && (
                <Image
                  ref={shapeRef}
                  image={getImageFromString64(templateImageStore?.string64)}
                  x={templateImageStore?.x}
                  y={templateImageStore?.y}
                  scaleX={templateImageStore?.scaleX}
                  scaleY={templateImageStore?.scaleY}
                  rotation={templateImageStore?.rotation}
                  // draggable={isSelectedTemplateImage ? true : false}
                  // onDragEnd={(e) => {
                  //   const position = { x: e.target.x(), y: e.target.y() };
                  //   dispatch(updateTemplatePositionImage(position));
                  // }}
                  // onTransformEnd={(e) => {
                  //   const node = shapeRef.current;
                  //   const scale = { x: node?.scaleX(), y: node?.scaleY(), rotation: node?.rotation() };
                  //   dispatch(updateTemplateScaleImage(scale));
                  // }}
                />
              )}

              {roofProcessFunctionsStatus.loadingDrawingScale && (
                <DomaUnit stageRef={stage} point0Ref={point0Ref} zoomScale={zoomScale} />
              )}
            </Layer>
          )}

          {domaGrid.gridVisible && (
            <Layer>
              <DomaGrid
                stageWidth={dimStageParent.width}
                stageHeight={dimStageParent.height}
                scale={scale}
                zoomScale={zoomScale}
                gridSettings={domaGrid}
              />
            </Layer>
          )}

          <Layer>
            <Group>
              {rsoPlanes.map((rso) => {
                if (rso.active) {
                  return (
                    <RoofSlopeOutlinePosition
                      key={rso.id}
                      rso={rso}
                      zoomScale={zoomScale}
                      setActiveOutline={(o) => {
                        setActiveOutline(o);
                      }}
                      openEditOutlineModal={(o) => {
                        if (activeFunction == null) {
                          openEditOutlineModal(o);
                        }
                      }}
                      activePlaneToGetDeclineVector={
                        (isDeclineVectorDrawing || isEdgeForDeclineVectorSelecting) &&
                        selectedOutline?.id === rso.id
                      } // Aktywna połaca do pobrania wektora spadku
                    />
                  );
                }
              })}

              {rsoHoles.map((rsoh) => {
                if (rsoh.active) {
                  return (
                    <RoofSlopeHole
                      key={rsoh.id}
                      rsoHole={rsoh}
                      rsoPoints={rsoPoints}
                      scale={scale}
                      fill="#e8e8e8"
                    />
                  );
                }
              })}

              {rsoEdges &&
                rsoEdges.map((e) => {
                  if (e.active) {
                    return (
                      <RoofSlopeOutlineEdge
                        key={e.id}
                        rsoe={e}
                        zoomScale={zoomScale}
                        selectedEdgeType={selectedEdgeType}
                        fastEdgeMode={activeFunction === Functions.FunctionFastEdgesMode}
                        openEdgeModal={(e) => {
                          if (activeFunction == null) {
                            openEdgeModal(e);
                          }
                        }}
                        isEdgeForDeclineVectorSelecting={isEdgeForDeclineVectorSelecting}
                        setSelectedEdgeForDeclineVector={(e: IRSOEdge | null) =>
                          setSelectedEdgeForDeclineVector(e)
                        }
                      />
                    );
                  }
                })}

              {rsoLinkedEdges &&
                rsoLinkedEdges.map((o) => {
                  return <RoofSlopeOutlineLinkedLine key={o.id} rsole={o} />;
                })}

              {/* Wyswietlanie elementów dodanych do rysunku */}
              {gutteringElements.gutterDrainPipes.map((o, i) => {
                return (
                  <GutteringElement key={i} typeElement="gutterDrainPipe" element={o} zoomScale={zoomScale} />
                );
              })}
              {gutteringElements.gutterInternalCorners.map((o, i) => {
                return (
                  <GutteringElement key={i} typeElement="gutterInternalCorner" element={o} zoomScale={zoomScale} />
                );
              })}
              {gutteringElements.gutterExternalCorners.map((o, i) => {
                return (
                  <GutteringElement key={i} typeElement="gutterExternalCorner" element={o} zoomScale={zoomScale} />
                );
              })}
              {gutteringElements.gutterEndLefts.map((o, i) => {
                return <GutteringElement key={i} typeElement="gutterEndLeft" element={o} zoomScale={zoomScale} />;
              })}
              {gutteringElements.gutterEndRights.map((o, i) => {
                return <GutteringElement key={i} typeElement="gutterEndRight" element={o} zoomScale={zoomScale} />;
              })}

              {/* Wyswietlanie akcesoriów dachowych dodanych do rysunku */}
              {roofAccessories.roofStepList.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofStep"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench40List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench40"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench80List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench80"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench200List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench200"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench300List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench300"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.snowFence200List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="snowFence200"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.snowFence300List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="snowFence300"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.roofVent100List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofVent100"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.roofVent125List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofVent125"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.roofVent150List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofVent150"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
            </Group>

            <Group>
              {activeFunction === Functions.Function1 && (
                // <Function1Handler stageRef={stage} point0Ref={point0Ref} close={() => setActiveFunction(null)} />
                <Function1Handler2
                  stageRef={stage}
                  point0Ref={point0Ref}
                  shouldCloseOutline={shouldCloseOutline}
                  undoLastPoint={undoLastPoint}
                  accetpUndo={() => setUndoLastPoint(false)}
                  close={() => {
                    delaySetActiveFunction(null, 300);
                    if (shouldCloseOutline) {
                      setShouldCloseOutline(false);
                    }
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                  gridPoints={getGridPoints(
                    dimStageParent.width,
                    dimStageParent.height,
                    scale,
                    domaGrid.gridSize,
                    GRID_OFFSET
                  )}
                  newPointAdd={newPointAdd}
                  setNewPointAdd={(e) => setNewPointAdd(e)}
                />
              )}

              {activeFunction === Functions.Function2 && (
                <Function2Handler
                  stageRef={stage}
                  point0Ref={point0Ref}
                  close={() => {
                    delaySetActiveFunction(null, 300);
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                />
              )}

              {isDeclineVectorDrawing && (
                <Function3Handler
                  stageRef={stage}
                  point0Ref={point0Ref}
                  startPointDeclineVector={startPointDeclineVector}
                  endPointDeclineVector={endPointDeclineVector}
                  setStartPointDeclineVector={setStartPointDeclineVector}
                  setEndPointDeclineVector={setEndPointDeclineVector}
                  activateEditOutlineModal={() => {
                    setIsEditOutlineModalVisible(true);
                  }}
                  close={() => {
                    delaySetActiveFunction(null, 300);
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                />
              )}

              {activeFunction === Functions.FunctionHole && (
                <FunctionHoleHandler
                  stageRef={stage}
                  point0Ref={point0Ref}
                  undoLastPoint={undoLastPoint}
                  accetpUndo={() => setUndoLastPoint(false)}
                  close={() => {
                    delaySetActiveFunction(null, 300);
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                />
              )}

              {activeFunction === Functions.FunctionAddHole && selectedWindowHole && (
                <FunctionAddHoleHandler
                  stageRef={stage}
                  point0Ref={point0Ref}
                  windowHole={selectedWindowHole}
                  scale={scale}
                  close={() => {
                    delaySetActiveFunction(null, 300);
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                />
              )}

              {activeFunction === Functions.FunctionAddRoofAccessory && (
                <FunctionAddRoofAccessoryHandler
                  stageRef={stage}
                  point0Ref={point0Ref}
                  typeElementState={activeAddRoofAccessory}
                  activeRoofPlane={activeOutline}
                  zoomScale={zoomScale}
                  closeFunction={() => {
                    delaySetActiveFunction(null, 300);
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                  scale={scale}
                  eventClickStage={(e: KonvaEventObject<MouseEvent>) => {
                    if (activeAddRoofAccessory !== null) {
                      setActiveAddRoofAccessory(null);
                    }
                  }}
                />
              )}

              {activeFunction === Functions.FunctionAddGutterElement && (
                <FunctionAddGutterElementHandler
                  stageRef={stage}
                  point0Ref={point0Ref}
                  typeElementState={activeAddGutteringElement}
                  activeRoofPlane={activeOutline}
                  zoomScale={zoomScale}
                  closeFunction={() => {
                    delaySetActiveFunction(null, 300);
                    dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                  }}
                  scale={scale}
                  eventClickStage={(e: KonvaEventObject<MouseEvent>) => {
                    if (activeAddRoofAccessory !== null) {
                      setActiveAddGutteringElement(null);
                    }
                  }}
                />
              )}
            </Group>

            {/* Niewidoczny punkt w pozycji 0,0 do skalowania */}
            <Circle ref={point0Ref} x={0} y={0} radius={0} fill="transparent" />
          </Layer>
        </StyledStage>

        <BottomPanel>
          {activeFunction === Functions.FunctionFastEdgesMode && (
            <RightPanel defaultActiveKey={["1"]}>
              <StyledCollapsePanel header="Wybierz typ krawędzi" key="1" showArrow={true}>
                {/* Miejsce na listę typów krawędzi */}

                <div style={{ marginTop: "8px" }}></div>

                {getAllEdgeTypes().map((e) => {
                  return (
                    <div style={{ paddingBottom: "8px", marginLeft: "8px" }}>
                      <Checkbox
                        checked={selectedEdgeType === e.value}
                        onChange={(o) => setSelectedEdgeType(e.value)}
                      >
                        {e.label}
                      </Checkbox>
                    </div>
                  );
                })}
              </StyledCollapsePanel>
            </RightPanel>
          )}

          {activeFunction !== Functions.FunctionFastEdgesMode && !disabledMainFlowPlanesAndEdges() && (
            <RightPanel defaultActiveKey={["1"]}>
              <StyledCollapsePanel
                header="Połacie dachu"
                key="1"
                showArrow={true}
                extra={
                  isAllPlanesHasDeclineVector(rsoPlanes) ? (
                    <CheckOutlined style={{ color: "#4bc227" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#ff0000" }} />
                  )
                }
              >
                <RoofSlopeOutlinesTable
                  openEditOutlineModal={(o) => {
                    if (activeFunction == null) {
                      openEditOutlineModal(o);
                    }
                  }}
                />
              </StyledCollapsePanel>
              <StyledCollapsePanel
                header="Krawędzie dachu"
                key="2"
                showArrow={true}
                extra={
                  isAllEdgesHasType(rsoEdges) ? (
                    <CheckOutlined style={{ color: "#4bc227" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#ff0000" }} />
                  )
                }
              >
                <RSOEdgesTable
                  openEditEdgeModal={(o) => {
                    if (activeFunction == null) {
                      openEdgeModal(o);
                    }
                  }}
                />
              </StyledCollapsePanel>
            </RightPanel>
          )}
        </BottomPanel>
      </StyledStageParent>
    </Container>
  );
};
export default RoofSlopeOutlineMain;

const Container = styled(Card)`
  width: 100%;
`;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 8px 8px 0 0;
  background: #bdbdbd;
`;

const MenuBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledStageParent = styled.div`
  width: 100%; // Zapewnia, że element będzie reagował na zmiany rozmiaru okna
  min-width: 0;
`;

const StyledStage = styled(Stage)`
  height: 900px;
  margin: 0;
  background: #e8e8e8;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
`;

const BottomPanel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RightPanel = styled(Collapse)`
  position: absolute;
  top: 0px;
  margin: 16px;
  margin-top: 66px;
  width: 300px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 999;
`;

const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 0px !important;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;

const TooltipText = styled.div`
  text-align: center;
`;
