import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import {
  IAddFasteningSystem,
  IAddPreCoveringSystem,
  IFasteningSystem,
  IPreCoveringSystem,
} from "../../models/Models";
import { addStatusApi } from "../other/statusApiSlice";
import { AppThunk } from "../store";

const initialState: IFasteningSystem[] = [];

export const fasteningSystemsSlice = createSlice({
  name: "fasteningSystems",
  initialState,
  reducers: {
    addFasteningSystemsInReducer: (state, action: PayloadAction<IFasteningSystem[]>) => {
      let systems = action.payload;
      return systems;
    },
    addFasteningSystemInReducer: (state, action: PayloadAction<IFasteningSystem>) => {
      let system = action.payload;
      return [...state, system];
    },
    updateFasteningSystemInReducer: (state, action: PayloadAction<IFasteningSystem>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);
      state[index] = { ...state[index], ...update };
    },
    deleteFasteningSystemInReducer: (state, action: PayloadAction<string>) => {
      const systemId = action.payload;
      return state.filter((o) => o.id !== systemId);
    },
  },
});

export const {
  addFasteningSystemsInReducer,
  addFasteningSystemInReducer,
  updateFasteningSystemInReducer,
  deleteFasteningSystemInReducer,
} = fasteningSystemsSlice.actions;
export default fasteningSystemsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add system
export const addFasteningSystem =
  (data: IAddFasteningSystem, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("ADD MODEL", data);
    client
      .put("/FasteningSystems", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addFasteningSystemInReducer(response.data));
          callback();
          // dispatch(addStatusApi({ key: "addSteelSystem", type: "success", message: "" }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addSteelSystem", type: "error", message: "Błąd dodania systemu!" }));
        // console.error(error);
      });
  };

// Update system
export const updateFasteningSystem =
  (data: IFasteningSystem): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE MODEL", data);
    client
      .post(`/FasteningSystems`, data)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATE MODEL RESPONSE", response.data);
          dispatch(updateFasteningSystemInReducer(response.data));

          message.open({
            type: "success",
            content: "System został zaktualizowany!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete system
export const deleteFasteningSystem =
  (data: IFasteningSystem): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/FasteningSystems/${data.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteFasteningSystemInReducer(data.id));
          dispatch(addStatusApi({ key: "deleteFasteningSystem", type: "success", message: "" }));

          message.open({
            type: "success",
            content: "System został usunięty!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
