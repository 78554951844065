import { Button, Modal, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { getEdgeNameByType } from "../../../../../../helpers/Helpers";
import { EdgeType } from "../../../../../../models/Enums";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { updateRSOEdge } from "../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { updateRSOLinkedEdge } from "../../../../../../redux/roofSlopeOutline/rsoLinkedEdgesSlice";
import { IRSOEdge } from "./Models/RoofSlopeOutlineModels";

type Props = {
  rsoe: IRSOEdge;
  closeEdgeModal: () => void;
};

const EditRoofSlopeOutlineEdgeModal: FC<Props> = ({ rsoe, closeEdgeModal }) => {
  const dispatch = useAppDispatch();

  const [selectedType, setSelectedType] = useState<number>(rsoe.type);

  const rsoLinkedEdges = useAppSelector((state) => state.rsoLinkedEdges) || [];

  useEffect(() => {
    // Sprawdzamy czy dana krawędz nie jest już powiązana z inną
    const linkedEdge = rsoLinkedEdges.find(
      (rsole) => rsole.firstEdgeId === rsoe.id || rsole.secondEdgeId === rsoe.id
    );

    // Jeśli tak to pobieramy z tad typ
    if (linkedEdge) {
      setSelectedType(linkedEdge.type);
    } else {
      setSelectedType(rsoe.type);
    }
  }, [rsoe]);

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="back" onClick={closeEdgeModal}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button type="primary" onClick={() => changeEdgeType()}>
        Zapisz zmiany
      </Button>
    );

    return buttons;
  };

  const getElementsForSelect = () => {
    const edgeTypesOptions = Object.values(EdgeType).filter((value) => typeof value === "number");

    const result = [];
    for (const value of edgeTypesOptions) {
      result.push({
        value: value,
        label: getEdgeNameByType(Number(value)),
      });
    }
    return result;
  };

  const changeEdgeType = () => {
    // Sprawdzamy czy dana krawędz nie jest już powiązana z inną
    const linkedEdge = rsoLinkedEdges.find(
      (rsole) => rsole.firstEdgeId === rsoe.id || rsole.secondEdgeId === rsoe.id
    );

    // Jeśłi tak to aktualizujemy typ w linkedEdges
    if (linkedEdge) {
      dispatch(updateRSOLinkedEdge({ ...linkedEdge, type: selectedType }));
    } else {
      // Jeśli nie to aktualizujemy typ w edges
      dispatch(updateRSOEdge({ ...rsoe, type: selectedType }));
    }

    dispatch(invalidateCalculation());

    closeEdgeModal();
  };

  return (
    <Modal
      title="Edycja krawędzi"
      open={true}
      bodyStyle={{
        paddingTop: "16px",
      }}
      centered
      onCancel={() => closeEdgeModal()}
      footer={getButtonsInFooter()}
    >
      <StyledSelect
        value={selectedType}
        options={getElementsForSelect()}
        onChange={(value: any) => {
          setSelectedType(value);
        }}
      />
    </Modal>
  );
};
export default EditRoofSlopeOutlineEdgeModal;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 20px;
`;
