import { createSlice, Dispatch, PayloadAction, Store } from "@reduxjs/toolkit";
import { IBasicPoint, ICalculationPlane, ICalculationPoint, IPoint } from "../../models/Models";
import { AppThunk, RootState } from "../store";
import { v4 as uuidv4 } from "uuid";
import { clearSelectedEdgeToDrawRoofProcess } from "./roofProcessFunctionsStatusSlice";

// Punkty na obwodzie
export interface IOutlinePoint {
  id: string; // id punktu
  numberId: number;
  x: number;
  y: number;
  z: number;
}

// Krawędzie na obwodzie (może być 1 linia lub więcej)
export interface IOutlineEdge {
  id: string;
  numberId: number;
  slope: number; // wartość nachylenia dachu
  slopeUnit: "degree" | "percentage"; // Typ jednostki nachylenia dachu
  points: string[]; // Lista id outlinePoints
  eaveVectorPoints: string[];
}

// interface IOutline {
//   points: IOutlinePoint[];
//   edges: IOutlineEdge[];
// }

// const initialState: IOutline = { points: [], edges: [] };

export interface IOutline {
  roofNumber: number;
  points: IOutlinePoint[];
  edges: IOutlineEdge[];
}

// Ograniczenie dachów, limit dachów do narysowania
// Tyle pozycji ile może być dachów narysowanych oddzielnych
// Było 5 dachów, jest 20 dachów
const initialState: IOutline[] = [
  { roofNumber: 1, points: [], edges: [] },
  { roofNumber: 2, points: [], edges: [] },
  { roofNumber: 3, points: [], edges: [] },
  { roofNumber: 4, points: [], edges: [] },
  { roofNumber: 5, points: [], edges: [] },
  { roofNumber: 6, points: [], edges: [] },
  { roofNumber: 7, points: [], edges: [] },
  { roofNumber: 8, points: [], edges: [] },
  { roofNumber: 9, points: [], edges: [] },
  { roofNumber: 10, points: [], edges: [] },
  { roofNumber: 11, points: [], edges: [] },
  { roofNumber: 12, points: [], edges: [] },
  { roofNumber: 13, points: [], edges: [] },
  { roofNumber: 14, points: [], edges: [] },
  { roofNumber: 15, points: [], edges: [] },
  { roofNumber: 16, points: [], edges: [] },
  { roofNumber: 17, points: [], edges: [] },
  { roofNumber: 18, points: [], edges: [] },
  { roofNumber: 19, points: [], edges: [] },
  { roofNumber: 20, points: [], edges: [] },
];

// Wszystkie dane związane z obrysem dachu (wszystko w trakcie rysowania)
export const outlineSlice = createSlice({
  name: "outline",
  initialState,
  reducers: {
    addOutlinePoint: (state, action: PayloadAction<{ point: ICalculationPoint; roofNumber: number }>) => {
      const p = action.payload.point;
      const roofNumber = action.payload.roofNumber;

      const stateIndex = state.findIndex((o) => o.roofNumber === roofNumber);

      if (stateIndex >= 0) {
        const lastnNumberId =
          state[stateIndex].points.length > 0
            ? state[stateIndex].points[state[stateIndex].points.length - 1].numberId
            : 0;

        const point: IOutlinePoint = { id: uuidv4(), numberId: lastnNumberId + 1, x: p.x, y: p.y, z: p.z };

        const points = [...state[stateIndex].points, point];

        state[stateIndex] = { ...state[stateIndex], points: [...points] };
      }
    },
    setOutlinePoints: (state, action: PayloadAction<{ points: IOutlinePoint[]; roofNumber: number }>) => {
      const data = action.payload;

      const stateIndex = state.findIndex((o) => o.roofNumber === data.roofNumber);

      state[stateIndex] = { ...state[stateIndex], points: [...data.points] };
    },
    setOutlineEdges: (state, action: PayloadAction<{ edges: IOutlineEdge[]; roofNumber: number }>) => {
      const data = action.payload;

      const stateIndex = state.findIndex((o) => o.roofNumber === data.roofNumber);

      state[stateIndex] = { ...state[stateIndex], edges: [...data.edges] };
    },
    updateOutlineEdge: (state, action: PayloadAction<{ edge: IOutlineEdge; roofNumber: number }>) => {
      const data = action.payload;

      const stateIndex = state.findIndex((o) => o.roofNumber === data.roofNumber);

      const index = state[stateIndex].edges.findIndex((o) => o.id === data.edge.id);

      if (index >= 0) {
        const edges = [...state[stateIndex].edges];
        edges[index] = data.edge;

        state[stateIndex] = { ...state[stateIndex], edges: [...edges] };
      }
    },
    clearOutline: (state) => {
      return initialState;
    },
    setSavedOutline: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addOutlinePoint,
  setOutlineEdges,
  setOutlinePoints,
  updateOutlineEdge,
  clearOutline,
  setSavedOutline,
} = outlineSlice.actions;
export default outlineSlice.reducer;

// Generowanie Edges dla punktów po zakoczeniu rysowania obrysu dachu
// Uruchamiane gdy zamykamy obrys dachu
export const generateEdgesForOutline = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  console.log("START GENERATE EDGES");
  const roofContourNumber = getState().roofContourNumber;
  // const points = getState().outline.points;
  const points = getState().outline.find((o) => o.roofNumber === roofContourNumber.index)?.points || [];
  const slope = getState().roofProcessStatus.slope;
  const slopeUnit = getState().roofProcessStatus.typeSlope === "degrees" ? "degree" : "percentage";

  const edges: IOutlineEdge[] = [];

  let i = 0;
  for (const p of points) {
    let z = i === points.length - 1 ? 0 : i + 1;
    edges.push({
      id: uuidv4(),
      numberId: i + 1,
      slope: slope,
      slopeUnit: slopeUnit,
      points: [points[i].id, points[z].id],
      eaveVectorPoints: [points[i].id, points[z].id],
    });
    i++;
  }

  dispatch(setOutlineEdges({ edges: edges, roofNumber: roofContourNumber.index }));
};

// Przegenerowanie edges po zaznaczeniu kilku krawędzi
export const regenerateEdgesForOutline =
  (vector?: number[]): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    // const points = getState().outline.points;
    const roofContourNumber = getState().roofContourNumber;
    const points = getState().outline.find((o) => o.roofNumber === roofContourNumber.index)?.points || [];
    const selectedEdges = getState().roofProcessFunctionsStatus.selectedEdges; // ids
    //   const slope = getState().drawRoofProcess.slope;
    //   const slopeUnit = getState().drawRoofProcess.slopeInAngle ? "degree" : "percentage";
    //   const edges: IOutlineEdge[] = [];

    // Istniejące edges
    const existEdges: IOutlineEdge[] =
      getState().outline.find((o) => o.roofNumber === roofContourNumber.index)?.edges || [];

    // Pobieramy wszystkie indeksy zaznaczonych edges
    const indexes: number[] = [];
    for (const selectedEdgeId of selectedEdges) {
      indexes.push(existEdges.findIndex((o) => o.id === selectedEdgeId));
    }

    // Wybieramy najmniejszy indeks
    const minIndex = Math.min(...indexes);

    // Ilośc zaznaczonych krawędzi
    const countSelectedEdges = selectedEdges.length;

    // Nowa lista edges
    const newEdges: IOutlineEdge[] = [];

    let i = 0;
    for (const existEdge of existEdges) {
      if (i < minIndex || i >= minIndex + countSelectedEdges) {
        newEdges.push({ ...existEdge });
      } else if (i === minIndex) {
        // Pełne obiekty Edges zaznaczonych krawędzi
        let fullSelectedEdges: IOutlineEdge[] = [];
        for (const id of selectedEdges) {
          const ee = existEdges.find((o) => o.id === id);
          fullSelectedEdges.push(ee as IOutlineEdge);
        }

        const selectedEdgeAsFirst = { ...fullSelectedEdges[0] }; // Wybrane jako pierwsze posłuży do wyboru vektora okapu

        // Sortowanie krawędzi aby była dobra kolejnosć
        fullSelectedEdges.sort((a, b) => a.numberId - b.numberId);

        // Pobieramy wszystkie punkty dla zaznaczonych krawędzi
        const newPoints: any[] = [];

        if (fullSelectedEdges && fullSelectedEdges.length > 0) {
          for (const e of fullSelectedEdges) {
            if (e?.points) {
              newPoints.push(e?.points[0]);
            }
          }
          const fullLength = fullSelectedEdges.length;
          const lastPoint = fullSelectedEdges[fullLength - 1].points[1];
          newPoints.push(lastPoint);
        }

        let vect = [selectedEdgeAsFirst.eaveVectorPoints[0], selectedEdgeAsFirst.eaveVectorPoints[1]];

        if (vector) {
          vect = [vector[0].toString(), vector[1].toString()];
        }

        newEdges.push({
          ...existEdge,
          points: newPoints,
          eaveVectorPoints: vect,
        });
      }

      i++;
    }

    dispatch(setOutlineEdges({ edges: newEdges, roofNumber: roofContourNumber.index }));
    dispatch(clearSelectedEdgeToDrawRoofProcess()); // czyści i kończy zaznaczanie
  };

// Funkcja pobierająca potrzebne punkty do obliczeń w kalkulatorze
export const getPointsFromOutline = (state: RootState) => {
  // Cała logika przygotowania punktów
  // const outline = state.outline;

  const roofContourNumber = state.roofContourNumber;
  const outline = state.outline.find((o) => o.roofNumber === roofContourNumber.index);

  const points: ICalculationPoint[] = [];

  if (outline) {
    for (const p of outline.points) {
      points.push({ x: p.x, y: p.y, z: p.z ? p.z : 0 });
    }

    return points;
  }
};

// Funkcja pobierająca potrzebne plane do obliczeń w kalkulatorze
export const getPlanesFromOutline = (state: RootState) => {
  // Cała logika przygotowania płaszczyzn
  const roofContourNumber = state.roofContourNumber;
  const outline = state.outline.find((o) => o.roofNumber === roofContourNumber.index);

  const planes: ICalculationPlane[] = [];

  if (outline) {
    for (const e of outline.edges) {
      const angle = e.slope; // TODO aby zamienić procenty na kąt
      planes.push({ index: roofContourNumber.index, edgeCount: 1, x: 0, y: 0, angle: angle, dH: 0 });
    }

    return planes;
  }
};
