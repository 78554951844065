import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TypeRoofAccessory =
  | null
  | "roofStep"
  | "chimneySweepBench40"
  | "chimneySweepBench80"
  | "chimneySweepBench200"
  | "chimneySweepBench300"
  | "snowFence200"
  | "snowFence300"
  | "roofVent100"
  | "roofVent125"
  | "roofVent150";

export interface IRoofAccessoryElement {
  id: string;
  x: number;
  y: number;
  angle: number;
}

export interface IRoofAccessoryElements {
  roofStep: number;
  chimneySweepBench40: number;
  chimneySweepBench80: number;
  chimneySweepBench200: number;
  chimneySweepBench300: number;
  snowFence200: number;
  snowFence300: number;
  roofVent100: number;
  roofVent125: number;
  roofVent150: number;

  roofStepList: IRoofAccessoryElement[];
  chimneySweepBench40List: IRoofAccessoryElement[];
  chimneySweepBench80List: IRoofAccessoryElement[];
  chimneySweepBench200List: IRoofAccessoryElement[];
  chimneySweepBench300List: IRoofAccessoryElement[];
  snowFence200List: IRoofAccessoryElement[];
  snowFence300List: IRoofAccessoryElement[];
  roofVent100List: IRoofAccessoryElement[];
  roofVent125List: IRoofAccessoryElement[];
  roofVent150List: IRoofAccessoryElement[];
}

const initialState: IRoofAccessoryElements = {
  roofStep: 0,
  chimneySweepBench40: 0,
  chimneySweepBench80: 0,
  chimneySweepBench200: 0,
  chimneySweepBench300: 0,
  snowFence200: 0,
  snowFence300: 0,
  roofVent100: 0,
  roofVent125: 0,
  roofVent150: 0,

  roofStepList: [],
  chimneySweepBench40List: [],
  chimneySweepBench80List: [],
  chimneySweepBench200List: [],
  chimneySweepBench300List: [],
  snowFence200List: [],
  snowFence300List: [],
  roofVent100List: [],
  roofVent125List: [],
  roofVent150List: [],
};

export const roofAccessoriesSlice = createSlice({
  name: "roofAccessories",
  initialState,
  reducers: {
    addRoofStep: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return { ...state, roofStep: state.roofStep + 1, roofStepList: [...state.roofStepList, e] };
    },
    addChimneySweepBench40: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        chimneySweepBench40: state.chimneySweepBench40 + 1,
        chimneySweepBench40List: [...state.chimneySweepBench40List, e],
      };
    },
    addChimneySweepBench80: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        chimneySweepBench80: state.chimneySweepBench80 + 1,
        chimneySweepBench80List: [...state.chimneySweepBench80List, e],
      };
    },
    addChimneySweepBench200: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        chimneySweepBench200: state.chimneySweepBench200 + 1,
        chimneySweepBench200List: [...state.chimneySweepBench200List, e],
      };
    },
    addChimneySweepBench300: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        chimneySweepBench300: state.chimneySweepBench300 + 1,
        chimneySweepBench300List: [...state.chimneySweepBench300List, e],
      };
    },
    addSnowFence200: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        snowFence200: state.snowFence200 + 1,
        snowFence200List: [...state.snowFence200List, e],
      };
    },
    addSnowFence300: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        snowFence300: state.snowFence300 + 1,
        snowFence300List: [...state.snowFence300List, e],
      };
    },
    addRoofVent100: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        roofVent100: state.roofVent100 + 1,
        roofVent100List: [...state.roofVent100List, e],
      };
    },
    addRoofVent125: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        roofVent125: state.roofVent125 + 1,
        roofVent125List: [...state.roofVent125List, e],
      };
    },
    addRoofVent150: (state, action: PayloadAction<IRoofAccessoryElement>) => {
      let e = action.payload;
      return {
        ...state,
        roofVent150: state.roofVent150 + 1,
        roofVent150List: [...state.roofVent150List, e],
      };
    },
    // Znajdź i zaktualizuj odpowiedni element jako tylko wartość
    updateRoofAccessoryValue: (
      state,
      action: PayloadAction<{ typeRoofAccessory: TypeRoofAccessory; countValue: number }>
    ) => {
      let e = action.payload;

      return {
        ...state,
        [e.typeRoofAccessory + ""]: e.countValue,
      };
    },
    setRoofAccessoriesElements: (state, action: PayloadAction<IRoofAccessoryElements>) => {
      let e = action.payload;
      return e;
    },
    deleteRoofAccessoriesElement: (state, action: PayloadAction<{ id: string; type: string }>) => {
      let element = action.payload;
      if (element.type === "roofStep") {
        return {
          ...state,
          roofStep: state.roofStep - 1,
          roofStepList: state.roofStepList.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "chimneySweepBench40") {
        return {
          ...state,
          chimneySweepBench40: state.chimneySweepBench40 - 1,
          chimneySweepBench40List: state.chimneySweepBench40List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "chimneySweepBench80") {
        return {
          ...state,
          chimneySweepBench80: state.chimneySweepBench80 - 1,
          chimneySweepBench80List: state.chimneySweepBench80List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "chimneySweepBench200") {
        return {
          ...state,
          chimneySweepBench200: state.chimneySweepBench200 - 1,
          chimneySweepBench200List: state.chimneySweepBench200List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "chimneySweepBench300") {
        return {
          ...state,
          chimneySweepBench300: state.chimneySweepBench300 - 1,
          chimneySweepBench300List: state.chimneySweepBench300List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "snowFence200") {
        return {
          ...state,
          snowFence200: state.snowFence200 - 1,
          snowFence200List: state.snowFence200List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "snowFence300") {
        return {
          ...state,
          snowFence300: state.snowFence300 - 1,
          snowFence300List: state.snowFence300List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "roofVent100") {
        return {
          ...state,
          roofVent100: state.roofVent100 - 1,
          roofVent100List: state.roofVent100List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "roofVent125") {
        return {
          ...state,
          roofVent125: state.roofVent125 - 1,
          roofVent125List: state.roofVent125List.filter((o) => o.id !== element.id),
        };
      } else if (element.type === "roofVent150") {
        return {
          ...state,
          roofVent150: state.roofVent150 - 1,
          roofVent150List: state.roofVent150List.filter((o) => o.id !== element.id),
        };
      }
    },
    clearRoofAccessoriesElements: (state) => {
      return initialState;
    },
    setSavedRoofAccessoriesElements: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  updateRoofAccessoryValue,
  addRoofStep,
  addChimneySweepBench40,
  addChimneySweepBench80,
  addChimneySweepBench200,
  addChimneySweepBench300,
  addSnowFence200,
  addSnowFence300,
  addRoofVent100,
  addRoofVent125,
  addRoofVent150,
  setRoofAccessoriesElements,
  clearRoofAccessoriesElements,
  setSavedRoofAccessoriesElements,
  deleteRoofAccessoriesElement,
} = roofAccessoriesSlice.actions;
export default roofAccessoriesSlice.reducer;
