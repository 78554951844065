import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, Checkbox, Form, Input, Modal, Select, Table, Tag } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addUser, createUser, deleteUser, removeUser, updateUser } from "../redux/general/usersSlice";
import { useForm } from "antd/es/form/Form";
import { ROLES } from "../constatnts/Roles";
import { useAppDispatch } from "../redux/hooks";

export const generatePassword = (length) => {
  const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%&";
  let password = "";

  // losujemy pojedyncze znaki z zbioru i dodajemy je do hasła
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }

  return password;
};

const getRoleName = (role) => {
  switch (role) {
    case "admin":
      return "Administrator";
    case "moderator":
      return "Moderator";
    case "seller":
      return "Sprzedawca";
    case "contractor":
      return "Wykonawca";
    default:
      return "Inne";
  }
};

const getRolesSelect = (roles) => {
  const result = [];
  for (const r of roles) {
    result.push({
      value: r,
      label: getRoleName(r),
    });
  }
  return result;
};

export default function UserModal({ user, isMyUser, addUserModalOpen, setAddUserModalOpen }) {
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const isNew = () => {
    return user ? false : true;
  };

  const isEdit = () => {
    return user ? true : false;
  };

  const handleCancel = () => {
    setAddUserModalOpen(false);
  };

  const onFinish = (values) => {
    if (isNew()) {
      // Add new user
      const newUser = {
        email: values.email,
        fullName: values.fullName,
        role: values.role,
        password: values.password,
        sendAccess: values.sendAccess,
      };
      dispatch(addUser(newUser));
    } else {
      // Update user
      const usertoUpdate = {
        id: user.id,
        active: values.active,
        role: values.role,
      };
      dispatch(updateUser(usertoUpdate));
    }

    setAddUserModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const deleteUserOnClick = () => {
    dispatch(deleteUser(user));
    setAddUserModalOpen(false);
  };

  const setGeneratedPassword = (password) => {
    form.setFieldsValue({ password: password });
  };

  const getButtonsInFooter = () => {
    const buttons = [];

    {
      isNew() &&
        buttons.push(
          <Button
            key="generatePassword"
            onClick={() => setGeneratedPassword(generatePassword(10))}
            disabled={isEdit() ? true : false}
          >
            Generuj hasło
          </Button>
        );
    }

    {
      isEdit() &&
        !isMyUser &&
        buttons.push(
          <Button type="primary" key="delete" danger onClick={deleteUserOnClick}>
            Usuń
          </Button>
        );
    }

    buttons.push(
      <Button key="back" onClick={handleCancel}>
        Anuluj
      </Button>
    );

    {
      isEdit() &&
        buttons.push(
          <Button form="addUserForm" key="submit" htmlType="submit" type="primary">
            Zaktualizuj
          </Button>
        );
    }

    {
      isNew() &&
        buttons.push(
          <Button form="addUserForm" key="submit" htmlType="submit" type="primary">
            Dodaj
          </Button>
        );
    }

    return buttons;
  };

  const getInitialValues = (user) => {
    if (user) {
      return {
        id: user.id,
        email: user.email,
        fullName: user.fullName,
        role: user.role,
        active: user.active,
        sendAccess: user.sendAccess,
      };
    } else {
      return {
        id: "",
        email: "",
        fullName: "",
        role: "seller",
        active: true,
        sendAccess: false,
      };
    }
  };

  return (
    <Modal
      centered
      width={"60%"}
      open={addUserModalOpen}
      title={isNew() ? "Dodaj nowego użytkownika" : "Edytuj użytkownika"}
      onCancel={handleCancel}
      footer={getButtonsInFooter(user ? false : true)}
    >
      <Form
        id="addUserForm"
        form={form}
        initialValues={getInitialValues(user)}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // layout="vertical"
      >
        <Form.Item
          label="Imię i nazwisko"
          name="fullName"
          rules={[{ required: true, message: "Podaj imię i nazwisko!" }]}
          labelCol={{ span: 24 }}
        >
          <Input disabled={isEdit() ? true : false} />
        </Form.Item>

        <Form.Item
          label="Login (email)"
          name="email"
          // rules={[
          //   {
          //     type: "email",
          //     message: "Podana treść nie jest typem email!",
          //   },
          //   {
          //     required: true,
          //     message: "Podaj email jako login!",
          //   },
          // ]}
          labelCol={{ span: 24 }}
        >
          <Input disabled={isEdit() ? true : false} />
        </Form.Item>

        <Form.Item
          label="Rola"
          name="role"
          rules={[{ required: true, message: "Podaj imię i nazwisko!" }]}
          labelCol={{ span: 24 }}
        >
          <Select options={getRolesSelect(ROLES)} />
        </Form.Item>

        <Form.Item label="Aktywne" name="active" valuePropName="checked">
          <Checkbox disabled={isNew() ? true : false} />
        </Form.Item>

        <Form.Item
          label="Hasło"
          name="password"
          rules={[{ required: isNew() ? true : false, message: "Wygeneruj hasło!" }]}
          labelCol={{ span: 24 }}
        >
          <Input disabled={isEdit() ? true : false} />
        </Form.Item>

        <Form.Item label="Wyślij email z dostępem do konta" name="sendAccess" valuePropName="checked">
          <Checkbox disabled={isEdit() ? true : false} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
