import React, { FC } from "react";
import { Group, Line, Text } from "react-konva";
import styled from "styled-components";
import { getDistTwoPoints, middlePoint } from "../../../../../helpers/Helpers";
import { IRoofEdge, IRoofPoint } from "../../../../../models/Models";

type Props = {
  roofEdge: IRoofEdge;
  roofPoints: IRoofPoint[];
  scale: number;
  zoomScale: number;
};

const DimensionEdgeLine: FC<Props> = ({ roofEdge, roofPoints, scale, zoomScale }) => {
  const getMiddlePoint = (p1: any, p2: any) => {
    const p1Full = roofPoints.find((x: any) => x.id === p1);
    const p2Full = roofPoints.find((x: any) => x.id === p2);

    if (!p1Full || !p2Full) {
      return { x: 0, y: 0 };
    }

    return middlePoint(p1Full, p2Full);
  };

  const centerPoint = getMiddlePoint(roofEdge.startPointId, roofEdge.endPointId);

  const getLengthForText = (p1: any, p2: any) => {
    const p1Full = roofPoints.find((x: any) => x.id === p1);
    const p2Full = roofPoints.find((x: any) => x.id === p2);

    if (!p1Full || !p2Full) {
      return 0;
    }

    let val = getDistTwoPoints(p1Full, p2Full);
    val = val / scale;

    return val.toFixed(2);
  };

  return (
    <Container>
      {/* <Line
        points={getPointsForLineComponent([roofEdge.startPointId, roofEdge.endPointId], roofPoints)}
        closed={false}
        stroke={"#9280b866"}
      /> */}

      {/* Wymiar */}
      <Text
        text={`${getLengthForText(roofEdge.startPointId, roofEdge.endPointId)} m`}
        x={centerPoint.x}
        y={centerPoint.y}
        fontSize={14 / zoomScale}
        fontStyle="600"
        fill="#5f5f5f"
      />
    </Container>
  );
};

export default DimensionEdgeLine;

const Container = styled(Group)``;
