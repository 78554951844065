import { Dispatch } from "redux";
import { IPrincingRequestModel, IUpdateUserSettings } from "../models/Models";
import { updateUserSettingsInReducer } from "../redux/general/profileSlice";
import { AppDispatch, AppThunk, RootState } from "../redux/store";
import client from "./ApiClient";

// Update steel system
export const updateUserSettings =
  (data: IUpdateUserSettings): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE MODEL", data);
    client
      .post(`/UserSettings`, data)
      .then((response) => {
        if (response.status == 200) {
          // Aktualizacja userSettings w Profile
          dispatch(updateUserSettingsInReducer(response.data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
