import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoofSlope } from "../../models/Models";

const initialState: IRoofSlope[] = [];

export const roofSlopesSlice = createSlice({
  name: "roofSlopes",
  initialState,
  reducers: {
    setRoofSlopes: (state, action: PayloadAction<IRoofSlope[]>) => {
      let data = action.payload;
      return data;
    },
    clearRoofSlopes: (state) => {
      return initialState;
    },
    setSavedRoofSlopes: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setRoofSlopes, clearRoofSlopes, setSavedRoofSlopes } = roofSlopesSlice.actions;
export default roofSlopesSlice.reducer;
