import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IWindowIntegration, IWindowIntegrationElement } from "../../models/Models";
import { v4 as uuidv4 } from "uuid";
import { AppDispatch } from "../store";

const initialState: IWindowIntegration = {
  id: uuidv4(),
  useDefaultWindows: false,
  useWindowIntegrations: false,
  windowIntegrationElements: [],
  defaultWindows: [],
};

export const windowIntegrationsSlice = createSlice({
  name: "windowIntegration",
  initialState,
  reducers: {
    setWindowIntegrationInReducer: (state, action: PayloadAction<IWindowIntegration>) => {
      return action.payload;
    },
    setWindowIntegrationElementssInReducer: (state, action: PayloadAction<IWindowIntegrationElement[]>) => {
      state.windowIntegrationElements = action.payload;
    },
    addWindowIntegrationElementInReducer: (state, action: PayloadAction<IWindowIntegrationElement>) => {
      state.windowIntegrationElements.push(action.payload);
    },
    updateWindowIntegrationElementInReducer: (state, action: PayloadAction<IWindowIntegrationElement>) => {
      const element = action.payload;
      const index = state.windowIntegrationElements.findIndex((o) => o.id === element.id);
      state.windowIntegrationElements[index] = element;
    },
    deleteWindowIntegrationElementInReducer: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.windowIntegrationElements = state.windowIntegrationElements.filter((o) => o.id !== id);
    },
    clearWindowIntegration: (state) => {
      return initialState;
    },
  },
});

export const {
  setWindowIntegrationInReducer,
  addWindowIntegrationElementInReducer,
  updateWindowIntegrationElementInReducer,
  deleteWindowIntegrationElementInReducer,
  setWindowIntegrationElementssInReducer,
  clearWindowIntegration,
} = windowIntegrationsSlice.actions;
export default windowIntegrationsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add or update window integration
export const updateWindowIntegration =
  (model: IWindowIntegration, callback: () => void) => async (dispatch: AppDispatch) => {
    try {
      console.log("Window Integration Model", model);
      const response = await client.post("/WindowIntegrations", model);
      if (response.status === 200) {
        dispatch(setWindowIntegrationInReducer(response.data));
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
