import { IPrincingRequestModel } from "../models/Models";
import { AppDispatch, RootState } from "../redux/store";
import client from "./ApiClient";

export const getProjectPrice = async (state: RootState) => {
  try {
    // Dynamicznie importuj moduł
    const module = await import("../modules/roofs/managers/DiscountManager");

    // Pobierz funkcję z zaimportowanego modułu
    const getNettValueSumWithAllDiscount = module.getNettValueSumWithAllDiscount;

    // Użyj zaimportowanej funkcji
    const price = getNettValueSumWithAllDiscount();

    return price;
  } catch (error) {
    console.error("Błąd podczas ładowania modułu:", error);
    throw error; // Rzuć błąd dalej, aby można było go obsłużyć wyżej w hierarchii
  }
};

export const getExtraElementsPrice = async (state: RootState) => {
  try {
    // Dynamicznie importuj moduł
    const module = await import("../modules/roofs/managers/DiscountManager");

    // Pobierz funkcję z zaimportowanego modułu
    const getNettValueWithPoductDiscount = module.getNettValueWithPoductDiscount;

    // Pobrac listęporduktów jako elementów dodatkowych
    const extraElements = state.additionalElements;

    let price = 0;

    for (const e of extraElements) {
      const p = getNettValueWithPoductDiscount(e.productId);
      price += p as number;
    }

    return price;
  } catch (error) {
    console.error("Błąd podczas ładowania modułu:", error);
    throw error; // Rzuć błąd dalej, aby można było go obsłużyć wyżej w hierarchii
  }
};

export const getPrincingResult =
  (model: IPrincingRequestModel, callback: (data: any) => void) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    // console.log("GET PRINCING");
    // console.log(model);

    // Dodanie ceny za projekt do wyliczenia prowizji
    model.princingDataModel.projectPrice = await getProjectPrice(getState());
    model.princingDataModel.additionalsPrice = await getExtraElementsPrice(getState());

    // TODO dodac funkcję która pobierze cenę za elementy dodatkowe
    try {
      const response = await client.post("/Contractor/getPrincing", model);
      if (response.status === 200) {
        // console.log("PRINCING REPSONSE", response.data);
        // dispatch(addPrincingSystemInReducer(response.data));
        callback(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
