import { message } from "antd";
import Konva from "konva";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Circle, Group, Line, Text } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSO, IRSOEdge, IRSOHole, IRSOPoint, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { EdgeType } from "../../../../../../../models/Enums";
import { addRSOEdges } from "../../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { addRSOPoints } from "../../../../../../../redux/roofSlopeOutline/rsoPointsSlice";
import { addRSOHole } from "../../../../../../../redux/roofSlopeOutline/rsoHolesSlice";
import { invalidateCalculation } from "../../../../../../../redux/calculationProcess/calcResultSlice";

interface FunctionHoleHandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  undoLastPoint: boolean; // Triger do cofnięcia ostatniego punktu
  accetpUndo: () => void; // Potwierdzenie cofnięcia ostatniego punktu
  close: () => void;
}

// Funckja do rysowania wektora spadku
const FunctionHoleHandler: FC<FunctionHoleHandlerProps> = ({
  stageRef,
  point0Ref,
  undoLastPoint,
  accetpUndo,
  close,
}) => {
  const dispatch = useAppDispatch();

  const rsoPoints = useAppSelector((state) => state.rsoPoints);
  const rsoEdges = useAppSelector((state) => state.rsoEdges);
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes);

  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  const [points, setPoints] = useState<IRSOPoint[]>([]); // Punkty do rysowania otworu
  const pointsRef = useRef(points);

  const [plane, setPlane] = useState<IRSO | undefined>(); // Połaca na której rysowany jest otwór
  const planeRef = useRef(plane);

  useEffect(() => {
    console.log("START FUNKCJI RYSUJĄCEJ OTWÓR");
  }, []);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
    pointsRef.current = points;
    planeRef.current = plane;
  }, [mpLocal, points, plane]);

  useEffect(() => {
    if (undoLastPoint) {
      if (pointsRef.current.length > 0) {
        const newPoints = [...pointsRef.current];
        newPoints.splice(newPoints.length - 1, 1);
        setPoints(newPoints);
      }
      accetpUndo();
    }
  }, [undoLastPoint]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        e.evt.preventDefault();

        const clickedPoint: IRSOPoint = {
          id: uuidv4(),
          calcId: 0,
          type: 1,
          status: 2,
          x: mpLocalRef.current.x,
          y: mpLocalRef.current.y,
          z: 0,
        };

        // Dodanie pierwszego punktu oraz określenie połaci
        if (pointsRef.current.length === 0) {
          // Wykryć w jakim plane zostało kliknięte i pobrac jego id
          if (e.target.hasName("rsoPlane")) {
            const planeId = e.target.id();
            // console.log("Kliknięto połać o ID: ", planeId);

            if (planeId) {
              const selectedPlane = rsoPlanes.find((plane) => plane.id === planeId);
              if (selectedPlane) {
                setPlane(selectedPlane);
                setPoints([clickedPoint]);
              }
            }
          }
        } else {
          // Jeśli klikniety punkt jest taki sam jak punkt pierwszy w points to koniec funkcji
          // Zakończenie działania funkcji
          const firstPoint = pointsRef.current[0];
          if (firstPoint.x === clickedPoint.x && firstPoint.y === clickedPoint.y) {
            // console.log("Kliknięto w pierwszy punkt co oznacza zakończenie funckji");

            // Aby działało poprawnie to minimum 3 punktyy muszą być na otwór
            if (pointsRef.current.length > 2) {
              // Zakończenie rysowania otworu
              finishHoleDrawing();
            } else {
              close();
              message.error("Otwór musi mieć co najmniej 3 punkty");
            }
          } else {
            setPoints([...pointsRef.current, clickedPoint]);
          }
        }
      } else if (e.evt.button === 2) {
        // console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();

        // Zakończenie rysowania otworu
        finishHoleDrawing();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      if (startPointPosition) {
        const scaledMp: RSOPoint2D = { x: startPointPosition.x, y: startPointPosition.y };
        setMpLocal(scaledMp);
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  const finishHoleDrawing = () => {
    if (!planeRef.current) return null;

    // Przygotować punkty tak jak w połaci tylko z innym statusem
    const points: IRSOPoint[] = [];

    // TODO - logika sprawdzania będzie miała sens gdy będzie sprawdzane i korzystane z istniejacych punktów przy otworach
    for (const p of pointsRef.current) {
      // Sprawdzamy czy punkt był pobrany (kopiowany) z istniejących punktów
      const isPointExist = rsoPoints.find((rp) => rp.id === p.id);

      // TODO sprawdzanie czy punkt istnieje po danych ale też po statusie

      if (!isPointExist) {
        // Jeśli punkt nie istnieje to trzeba go dodać do listy punktów
        const point: IRSOPoint = {
          id: uuidv4(),
          calcId: p.calcId, // TODO - Na razie wszędzie 0 -> może będzie potrzebne później iterowane
          type: 1, // 1 – punkt jest na dachu (połaci nachylonej),
          status: 2, // 1 – punkt leży wewnątrz dachu
          x: p.x,
          y: p.y,
          z: 0, // Na początek domyślnie 0
        };
        points.push(point);
      } else {
        // A jeśli istniał to tylko dodajemy
        points.push(p);
      }
    }

    // 2. Przygotować krawędzie o typie empty
    const edges: IRSOEdge[] = [];
    for (let i = 0; i < pointsRef.current.length; i++) {
      const startPoint = pointsRef.current[i];
      const endPoint = i === pointsRef.current.length - 1 ? pointsRef.current[0] : pointsRef.current[i + 1];

      const startPointId = points.find((p) => p.x === startPoint.x && p.y === startPoint.y)?.id;
      const endPointId = points.find((p) => p.x === endPoint.x && p.y === endPoint.y)?.id;

      if (startPointId && endPointId) {
        const existedEdge = rsoEdges.find(
          (e) =>
            (e.startPointId === startPointId && e.endPointId === endPointId) ||
            (e.startPointId === endPointId && e.endPointId === startPointId)
        );

        if (!existedEdge) {
          const edge: IRSOEdge = {
            id: uuidv4(),
            calcId: 0,
            // startPoint: startPoint, // nie jest potrzebne
            // endPoint: endPoint, // nie jest potrzebne
            startPoint: { x: 0, y: 0 },
            endPoint: { x: 0, y: 0 },
            type: EdgeType.Hole,
            status: 2, // 2 – zdefiniowano oba punkty
            startPointId: startPointId,
            endPointId: endPointId,
            startPointCalcId: 0,
            endPointCalcId: 0,
            active: true,
            hover: false,
          };

          edges.push(edge);
        } else {
          edges.push(existedEdge);
        }
      } else {
        console.log("Błąd w punktach");
        // Jeśłi błąd to może trzeba zmienić porównaie punktów z jakims przybliżeniem
      }
    }

    const hole: IRSOHole = {
      id: uuidv4(),
      calcId: 0,
      pointIds: points.map((p) => p.id),
      pointCalcIds: points.map((p) => p.calcId),
      rsoPlaneId: planeRef.current.id,
      type: 1, // Typ otworu 1 - otwór pod lukarna, 2 - otwór pod okno, 3 - otwór pod komin
      active: true,
      hover: false,
      area: 0,
      points: [],
      edges: [],
      edgeIds: edges.map((e) => e.id),
    };

    setPoints([]);
    // Dodanie wszystkich punktów, krawędzi i otwory do redux
    dispatch(addRSOPoints(points));
    dispatch(addRSOEdges(edges));

    // Dodac do rsoHoles
    dispatch(addRSOHole(hole));

    dispatch(invalidateCalculation());

    close();
  };

  return (
    <Group>
      <Line
        points={points.flatMap((p) => [p.x, p.y]).concat(mpLocal.x, mpLocal.y)}
        closed={true}
        stroke="black"
        fill="#d0fabd"
        strokeWidth={0.5}
      />
    </Group>
  );
};

export default FunctionHoleHandler;
