export const PRIMARY_COLOR = "#1AB7DD";
export const TEXT_COLOR = "#29333D";
export const DOMA_LOGO_COLOR_A = "#6D6D6D";

export const domaTheme = {
  token: {
    colorPrimary: PRIMARY_COLOR,
    colorText: TEXT_COLOR,
  },
};
