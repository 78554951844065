import { Button, Card, Form, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import Dragger from "antd/es/upload/Dragger";
import React, { FC, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { isNumberObject } from "util/types";
import DomaInputNumber from "../../../../helpers/DomaInputNumber";
import {
  finishNewRoofProcess,
  IRoofProcessFunctionsStatus,
  startDrawNewRoofOutline,
  startLoadingDrawingScale,
  updateRoofProcessWithTemplate,
} from "../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import {
  IRoofProcessStatus,
  selectRoofProcessStatusType,
  updateSlopeAndSlopeType,
} from "../../../../redux/drawing/roofProcessStatusSlice";
import { useAppDispatch } from "../../../../redux/hooks";
import { RoofProcessStep } from "../roofSteps/steps/RoofProcessManager";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

import * as pdfjsLib from "pdfjs-dist";
// @ts-ignore
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { InboxOutlined, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { addTemplateImage, ITemplateImage, uploadTemplateImage } from "../../../../redux/roofs/templateImageSlice";
import { PRIMARY_COLOR, TEXT_COLOR } from "../../../../theme";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ROTATE_STEP = 0.2; // Krok w stopniach do obrotu zdjęcia

export const convertPercentageToAngle = (percentage: number) => {
  // Przeliczenie procentów na stosunek
  const ratio = percentage / 100;

  // Obliczenie kąta w radianach za pomocą arcus tangens
  const radians = Math.atan(ratio);

  // Przeliczenie radianów na stopnie (180 stopni = π radianów)
  const degrees = radians * (180 / Math.PI);

  // Jeśłi więcej niż 200% to zwróć 90 stopni
  if (percentage >= 200) return 90;

  // Zwrócenie wyniku
  return degrees;
};

export const convertAngleToPercentage = (degrees: number) => {
  // Obliczenie stosunku jako tangens kąta podanego w stopniach
  const ratio = Math.tan(degrees * (Math.PI / 180));

  // Przeliczenie stosunku na procenty
  const percentage = ratio * 100;

  // Zwrócenie wyniku
  return percentage;
};

type Props = {
  roofProcessStatus: IRoofProcessStatus;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  currentStep: RoofProcessStep;
  onCancel: () => void;
};

const getTitleModal = (currentStep: RoofProcessStep) => {
  if (currentStep === "selectDrawType") {
    return "Wybierz sposób rysowania";
  } else if (currentStep === "selectTemplateOption") {
    return "Wybierz opcję";
  } else if (currentStep === "modifyTemplate") {
    return "Dostosuj podkład";
  } else if (currentStep === "getUnit") {
    return "Wprowadź jednostkę";
  } else if (currentStep === "inputSlope") {
    return "Wprowadź nachylenie dachu";
  } else if (currentStep === "drawRoof") {
    return "Rysuj dach";
  } else {
    return "Błąd";
  }
};

const RoofProcessMainModal: FC<Props> = ({
  roofProcessStatus,
  roofProcessFunctionsStatus,
  currentStep,
  onCancel,
}) => {
  const dispatch = useAppDispatch();

  const cropperRef = useRef<HTMLImageElement>(null);
  const [image, setImage] = useState<string>();

  const [modalWidth, setModalWidth] = useState<string>("900px"); // w % lub px

  const [slopeForm] = useForm();

  // Drag and drop modal
  const draggleRef = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onOk = () => {};

  const onCancelProcess = () => {
    onCancel();
  };

  const draggerProps = {
    accept: ".pdf, .png, .jpg",
    onChange(info: any) {
      setModalWidth("80%");

      const file = info.file;
      const reader = new FileReader();

      if (info.file.type === "application/pdf") {
        reader.onload = async (event) => {
          const pdfData = new Uint8Array(event.target?.result as ArrayBuffer);
          const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
          const page = await pdf.getPage(1);
          const scale = 2; // Zwiększ wartość skali, aby uzyskać lepszą jakość obrazu
          const viewport = page.getViewport({ scale: scale });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d") as CanvasRenderingContext2D;
          canvas.width = viewport.width;
          canvas.height = viewport.height;
          await page.render({ canvasContext: context, viewport }).promise;
          const imageDataUrl = canvas.toDataURL("image/webp", 1);

          setImage(imageDataUrl);
        };
        reader.readAsArrayBuffer(file.originFileObj);
      } else {
        // Optymalizacja dla obrazów innych niż PDF
        reader.onload = (e) => {
          const img = new Image();
          img.src = e?.target?.result as string;
          img.onload = () => {
            let scaleFactor = 1;
            // if (img.width > 1500) {
            //   scaleFactor = 1500 / img.width;
            // }

            // Ustawienie nowych wymiarów
            const scaledWidth = img.width * scaleFactor;
            const scaledHeight = img.height * scaleFactor;

            // Utworzenie elementu canvas do skalowania obrazu
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = scaledWidth;
            canvas.height = scaledHeight;

            // Skalowanie i rysowanie obrazu na canvasie
            ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Konwersja do formatu webp z określoną jakością
            const optimizedImageDataURL = canvas.toDataURL("image/webp", 1);

            // Ustawienie przetworzonego obrazu
            setImage(optimizedImageDataURL);
          };
        };
        reader.readAsDataURL(file.originFileObj);
      }
    },
  };

  const handleRotate = (angle: number) => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.rotate(angle);
  };

  const resetRotate = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.reset();
  };

  const getButtonsInFooter = (step: RoofProcessStep) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left side */}
        <div>
          {currentStep === "modifyTemplate" && image && (
            <>
              <Button key="resetRotate" onClick={() => resetRotate()}>
                Resetuj zmiany
              </Button>
              <Button key="rotateLeft" onClick={() => handleRotate(-ROTATE_STEP)}>
                <UndoOutlined />
              </Button>
              <Button key="rotateRight" onClick={() => handleRotate(ROTATE_STEP)}>
                <RedoOutlined />
              </Button>
              <Button key="rotate" onClick={() => handleRotate(90)}>
                90°
              </Button>
            </>
          )}
        </div>

        {/* Right side */}
        <div style={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}>
          {/* Ten przycisk jest zawsze niezależnie od sytuacji - jeśłi te przyciski są dodane */}
          {step === "inputSlope" && (
            <Button key="cancel" type="primary" danger onClick={() => onCancelProcess()}>
              Anuluj proces rysowania
            </Button>
          )}

          {step === "selectDrawType" && <>{/* BRAK PRZYCISKÓW */}</>}

          {step === "selectTemplateOption" && <>{/* BRAK PRZYCISKÓW */}</>}

          {step === "modifyTemplate" && image && (
            <Button
              key="modifyTemplate"
              type="primary"
              onClick={() => {
                const imageElement: any = cropperRef?.current;
                const cropper: any = imageElement?.cropper;
                const imageData = cropper.getCroppedCanvas().toDataURL();

                const obj: ITemplateImage = {
                  string64: imageData,
                  templateImageFileName: null,
                  visible: true,
                  x: 0,
                  y: 0,
                  rotation: 0,
                  scaleX: 1,
                  scaleY: 1,
                };
                dispatch(addTemplateImage(obj));
                dispatch(updateRoofProcessWithTemplate(true, true));
                dispatch(startLoadingDrawingScale());

                // Wywołanie dodania podkładu na serwer i aktualizacja templateImageFileName w reduxie
                dispatch(uploadTemplateImage(imageData));
              }}
            >
              Potwierdzam podkład
            </Button>
          )}

          {step === "modifyTemplate" && (
            <Button
              key="next"
              danger
              onClick={() => {
                dispatch(updateRoofProcessWithTemplate(false, false));
              }}
            >
              Pomiń dodanie podkładu
            </Button>
          )}

          {step === "inputSlope" && (
            <Button
              key="next"
              type="primary"
              onClick={() => {
                const { slope, typeSlope } = slopeForm.getFieldsValue();

                const slopeValue = typeof slope === "number" ? slope : 0;
                const type = typeSlope === "degrees" ? "degrees" : "percentages";

                dispatch(updateSlopeAndSlopeType(slopeValue, type));
                dispatch(finishNewRoofProcess());

                // Czy automatyczny czy ręczny
                if (roofProcessStatus.type === "outline") {
                  console.log("startDrawNewRoofOutline");
                  dispatch(startDrawNewRoofOutline()); // Od razu start funkcji dla automatycznego rysowania
                }
              }}
            >
              Potwierdzam nachylenie dla połaci
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        cursor: "move",
      }}
      onMouseEnter={() => {
        // if (disabled) {
        //   setDisabled(false);
        // }
      }}
      onMouseLeave={() => {
        // setDisabled(true);
      }}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      <StyledModal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            {getTitleModal(currentStep)}
          </div>
        }
        open={true}
        width={modalWidth}
        style={{ userSelect: "none", padding: "0", margin: "0" }}
        bodyStyle={{ width: "100%", padding: "0", margin: "0" }}
        centered
        onCancel={() => onCancelProcess()}
        footer={getButtonsInFooter(currentStep)}
        maskClosable={false}
        mask={currentStep === "inputSlope" ? false : true}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {currentStep === "selectDrawType" && (
          <Content>
            <div style={{ display: "flex", width: "100%", gap: "16px", padding: "16px" }}>
              <TypeCard
                hoverable
                onClick={() => {
                  dispatch(selectRoofProcessStatusType("general"));
                  dispatch(updateRoofProcessWithTemplate(true)); // Od razu jako z podkładem
                }}
              >
                OBRYS POŁACI
              </TypeCard>
              <TypeCard
                hoverable
                onClick={() => {
                  dispatch(selectRoofProcessStatusType("outline"));
                  dispatch(updateRoofProcessWithTemplate(true)); // Od razu jako z podkładem
                }}
              >
                AUTOMATYCZNY
              </TypeCard>
            </div>
          </Content>
        )}

        {/* {currentStep === "selectTemplateOption" && (
          <Content>
            <div style={{ display: "flex", width: "100%", gap: "16px", padding: "16px" }}>
              <TypeCard
                hoverable
                onClick={() => {
                  dispatch(updateRoofProcessWithTemplate(true));
                }}
              >
                MAM PODKŁAD RYSUNKOWY
              </TypeCard>
              <TypeCard
                hoverable
                onClick={() => {
                  dispatch(updateRoofProcessWithTemplate(false, false));
                }}
              >
                BEZ PODKŁADU
              </TypeCard>
            </div>
          </Content>
        )} */}

        {currentStep === "modifyTemplate" && (
          <Step2Content>
            {(!image || image.length === 0) && (
              <MyDragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Kliknij lub przeciągnij zdjęcie/pdf rysunku dachu do tego obszaru
                </p>
                <p className="ant-upload-hint">Dodaj podkład rysunkowy</p>
              </MyDragger>
            )}

            {image && (
              <Cropper
                src={image as string}
                viewMode={2}
                guides={true}
                rotatable={true}
                zoomable={true}
                ref={cropperRef}
                dragMode="move"
              />
            )}
          </Step2Content>
        )}

        {currentStep === "inputSlope" && (
          <Content>
            <Form form={slopeForm} initialValues={{ typeSlope: "degrees", slope: 40 }}>
              <Form.Item label="Typ wartości spadku:" labelCol={{ span: 24 }} name="typeSlope">
                <Select>
                  <Select.Option value="degrees">Spadek w stopniach [°]</Select.Option>
                  <Select.Option value="percentages">Spadek w procentach [%]</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Wartość spadku [°/%]:" labelCol={{ span: 24 }} name="slope">
                <DomaInputNumber />
              </Form.Item>
            </Form>
          </Content>
        )}
      </StyledModal>
    </div>
  );
};
export default RoofProcessMainModal;

const StyledModal = styled(Modal)`
  & .ant-modal {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  & .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  & .ant-modal-body {
    flex: 1;
    overflow-y: auto;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const TypeCard = styled(Card).attrs((props: { selected?: boolean }) => props)`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 200px;
  font-weight: bold;

  ${(props) =>
    props.selected
      ? css`
          background: ${PRIMARY_COLOR}
          color: #fdfdfd;
        `
      : css`
          background: #ffffff;
          color: ${TEXT_COLOR};
        `}
`;

const Step2Content = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  margin-top: 16px;
  flex-direction: column;
  justify-content: center;
`;

const MyDragger = styled(Dragger)`
  width: 100%;
`;
