import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Card, Checkbox, Col, Form, List, message, Row, Select, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  IDefaultWindow,
  IProduct,
  IProductCategory,
  IRootState,
  ISelectOption,
  IWindowIntegration,
  IWindowIntegrationElement,
} from "../../../models/Models";
import { v4 as uuidv4 } from "uuid";
import PageHeader from "../../../layout/components/PageHeader";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { createFilterOption, getRoofSystemsForSelect } from "../../../helpers/Helpers";
import { updateWindowIntegration } from "../../../redux/general/windowIntegrationsSlice";

const WindowIntegrationPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const windowIntegration = useAppSelector((state: IRootState) => state.windowIntegration);

  const [windowIntegrationElements, setWindowIntegrationElements] = useState<IWindowIntegrationElement[]>([]);
  const [defaultWindows, setDefaultWindows] = useState<IDefaultWindow[]>([]);
  const [useWindowIntegrations, setUseWindowIntegrations] = useState<boolean>(false);
  const [useDefaultWindows, setUseDefaultWindows] = useState<boolean>(false);

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const products = useAppSelector((state: IRootState) => state.products);

  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems);
  const steelSystems = useAppSelector((state: IRootState) => state.steelSystems);

  const [productsSelectForWindows, setProductsSelectForWindows] = useState<ISelectOption[]>([]);
  const [productsSelectForCollars, setProductsSelectForCollars] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForWindows.length === 0) {
        setProductsSelectForWindows(getProductsByCategoryForSelect(products, productCategories, ["windows"]));
      }

      if (productsSelectForCollars.length === 0) {
        setProductsSelectForCollars(
          getProductsByCategoryForSelect(products, productCategories, ["window-collars-and-accessories"])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (windowIntegration) {
      setUseWindowIntegrations(windowIntegration.useWindowIntegrations);
      setUseDefaultWindows(windowIntegration.useDefaultWindows);
    }

    if (windowIntegration && windowIntegration.windowIntegrationElements) {
      setWindowIntegrationElements(windowIntegration.windowIntegrationElements);
    }

    if (windowIntegration && windowIntegration.defaultWindows) {
      setDefaultWindows(windowIntegration.defaultWindows);
    }
  }, [windowIntegration]);

  const saveChanges = () => {
    const requestModel: IWindowIntegration = {
      id: windowIntegration.id,
      useDefaultWindows: useDefaultWindows,
      useWindowIntegrations: useWindowIntegrations,
      windowIntegrationElements: windowIntegrationElements,
      defaultWindows: defaultWindows,
    };

    dispatch(
      updateWindowIntegration(requestModel, () => {
        message.success(`Integracja okien dachowych została zaktualizowana!`);
      })
    );
  };

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  return (
    <Container>
      <PageHeader
        title={"Integracja okien dachowych"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="primary" onClick={() => saveChanges()}>
              Zapisz zmiany
            </Button>
          </div>
        }
      />

      <Section
        title={"Powiązanie okien dachowych z kołnierzami oraz systemami pokrycia"}
        // style={{ marginTop: "16px" }}
        extra={
          <Button
            type="primary"
            onClick={() => {
              const newElement: IWindowIntegrationElement = {
                id: uuidv4(),
                windowProductId: "",
                windowCollarProductId: "",
                roofSystemIds: [],
              };
              setWindowIntegrationElements([...windowIntegrationElements, newElement]);
            }}
          >
            Dodaj
          </Button>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={windowIntegrationElements}
          renderItem={(item: IWindowIntegrationElement, index) => (
            <List.Item key={index} style={{ padding: "16px 16px 0px 0px" }}>
              <List.Item.Meta
                description={
                  <Row gutter={16}>
                    <Col span={6}>
                      <Form.Item label="Okno" labelCol={{ span: 24 }}>
                        <Select
                          placeholder="Wybierz zależność!"
                          value={item.windowProductId}
                          onChange={(value) => {
                            const newElement = { ...item, windowProductId: value as string };
                            const newElements = [...windowIntegrationElements];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements[index] = newElement;
                              setWindowIntegrationElements(newElements);
                            }
                          }}
                          options={
                            productsSelectForWindows.length > 0
                              ? productsSelectForWindows
                              : getProductsByCategoryForSelect(products, productCategories, ["windows"])
                          }
                          filterOption={createFilterOption()}
                          showSearch
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Domyślny kołnierz" labelCol={{ span: 24 }}>
                        <Select
                          placeholder="Wybierz kołnierz!"
                          value={item.windowCollarProductId}
                          onChange={(value) => {
                            const newElement = { ...item, windowCollarProductId: value as string };
                            const newElements = [...windowIntegrationElements];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements[index] = newElement;
                              setWindowIntegrationElements(newElements);
                            }
                          }}
                          options={
                            productsSelectForCollars.length > 0
                              ? productsSelectForCollars
                              : getProductsByCategoryForSelect(products, productCategories, [
                                  "window-collars-and-accessories",
                                ])
                          }
                          filterOption={createFilterOption()}
                          showSearch
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item label="Systemy pokryć dachowych" labelCol={{ span: 24 }}>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Wybierz systemy!"
                          value={item.roofSystemIds}
                          onChange={(value) => {
                            const newElement = { ...item, roofSystemIds: value as string[] };
                            const newElements = [...windowIntegrationElements];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements[index] = newElement;
                              setWindowIntegrationElements(newElements);
                            }
                          }}
                          options={getRoofSystemsForSelect(tileSystems, steelSystems)}
                          filterOption={createFilterOption()}
                          showSearch
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Form.Item label="&nbsp;" labelCol={{ span: 24 }} style={{ textAlign: "center" }}>
                        <Button
                          danger
                          key="delete"
                          onClick={() => {
                            const newElements = [...windowIntegrationElements];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements.splice(index, 1);
                              setWindowIntegrationElements(newElements);
                            }
                          }}
                        >
                          Usuń
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </Section>
      <Section
        title={"Powiązanie okien dachowych z otworami"}
        style={{ marginTop: "16px" }}
        extra={
          <Button
            type="primary"
            onClick={() => {
              const newElement: IDefaultWindow = {
                id: uuidv4(),
                windowProductId: "",
                width: 0,
                height: 0,
              };
              setDefaultWindows([...defaultWindows, newElement]);
            }}
          >
            Dodaj
          </Button>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={defaultWindows}
          renderItem={(item: IDefaultWindow, index) => (
            <List.Item key={index} style={{ padding: "16px 16px 0px 0px" }}>
              <List.Item.Meta
                description={
                  <Row gutter={16}>
                    <Col span={3}>
                      <Form.Item label="Szerokość otworu [cm]" labelCol={{ span: 24 }}>
                        <DomaInputNumber
                          value={item.width}
                          onChange={(value) => {
                            const newElement = { ...item, width: value as number };
                            const newElements = [...defaultWindows];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements[index] = newElement;
                              setDefaultWindows(newElements);
                            }
                          }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item label="Wysokość otworu [cm]" labelCol={{ span: 24 }}>
                        <DomaInputNumber
                          value={item.height}
                          onChange={(value) => {
                            const newElement = { ...item, height: value as number };
                            const newElements = [...defaultWindows];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements[index] = newElement;
                              setDefaultWindows(newElements);
                            }
                          }}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={17}>
                      <Form.Item label="Domyślne okno" labelCol={{ span: 24 }}>
                        <Select
                          placeholder="Wybierz zależność!"
                          value={item.windowProductId}
                          onChange={(value) => {
                            const newElement = { ...item, windowProductId: value as string };
                            const newElements = [...defaultWindows];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements[index] = newElement;
                              setDefaultWindows(newElements);
                            }
                          }}
                          options={
                            productsSelectForWindows.length > 0
                              ? productsSelectForWindows
                              : getProductsByCategoryForSelect(products, productCategories, ["windows"])
                          }
                          filterOption={createFilterOption()}
                          showSearch
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <Form.Item label="&nbsp;" labelCol={{ span: 24 }} style={{ textAlign: "center" }}>
                        <Button
                          danger
                          key="delete"
                          onClick={() => {
                            const newElements = [...defaultWindows];
                            const index = newElements.findIndex((o) => o.id === item.id);
                            if (index !== -1) {
                              newElements.splice(index, 1);
                              setDefaultWindows(newElements);
                            }
                          }}
                        >
                          Usuń
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                }
              />
            </List.Item>
          )}
        />
      </Section>
    </Container>
  );
};

export default WindowIntegrationPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled(Card)``;
