import { Button } from "antd";
import Konva from "konva";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { Html } from "react-konva-utils";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import { getTwoPoints3DLength } from "../../../../../helpers/Helpers";
import { IBasicPoint } from "../../../../../models/Models";
import { finishLoadingDrawingScale } from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { updateUnit } from "../../../../../redux/drawing/roofProcessStatusSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateDrawingScale } from "../../../../../redux/roofs/drawingScaleSlice";

// CONSTANTS
const DIST = 2;
const LEN = 70;

export const generateTicks = (start: any, end: any, tickSpacing: any, zoom: any) => {
  tickSpacing = tickSpacing / zoom;

  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const length = Math.sqrt(dx * dx + dy * dy);
  const ticks = [];

  const normalX = -dy / length;
  const normalY = dx / length;
  const halfLineWidth = 9 / zoom;

  for (let i = 2; i < length; i += tickSpacing) {
    const x = start.x + (dx * i) / length;
    const y = start.y + (dy * i) / length;
    const tickStartX = x + normalX * halfLineWidth;
    const tickStartY = y + normalY * halfLineWidth;
    const tickEndX = x - normalX * halfLineWidth;
    const tickEndY = y - normalY * halfLineWidth;
    const tick = (
      <Line
        key={i}
        points={[tickStartX, tickStartY, tickEndX, tickEndY]}
        closed={false}
        stroke="#454545"
        strokeWidth={1 / zoom}
      />
    );
    ticks.push(tick);
  }
  return ticks;
};

type Props = {
  zoomScale: number;
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
};

const DomaUnit: FC<Props> = ({ zoomScale, stageRef, point0Ref }) => {
  const dispatch = useAppDispatch();

  const [mpLocal, setMpLocal] = React.useState<IBasicPoint>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  const [shouldFinish, setShouldFinish] = useState<boolean>(false);

  const [points, setPoints] = useState<{ start: IBasicPoint; end: IBasicPoint }>({
    start: { x: 500, y: 300 },
    end: { x: 700, y: 300 },
  });
  const pointsRef = useRef(points);

  // 0 - nic
  // 1 - start
  // 2 - end
  const [activePoint, setActivePoint] = useState<number>(0);

  const [unitValue, setUnitValue] = useState<number>(1);

  const [saved, setSaved] = useState<{ start: IBasicPoint; end: IBasicPoint; startMove: IBasicPoint }>(); // zapisane punkty start, stop oraz położenie myszki podczas kliknięcia do przesunięcia
  const [inMoving, setInMoving] = useState<boolean>(false);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
  }, [mpLocal]);

  useEffect(() => {
    pointsRef.current = points;
  }, [points]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        // console.log("Kliknięto lewym przyciskiem myszy");
      } else if (e.evt.button === 2) {
        // console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();
        rotateLine();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      if (startPointPosition) {
        const scaledMp: IBasicPoint = { x: startPointPosition.x, y: startPointPosition.y };
        setMpLocal(scaledMp);
      }
    };

    const handleMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        setActivePoint(0);
        setInMoving(false);
        deletepointer(e.evt);
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);
    stage.on("mouseup", handleMouseUp);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
      stage.off("mouseup", handleMouseUp);
    };
  }, [stageRef]);

  // useEffect(() => {
  //   function handleMouseUp(event: any) {
  //     if (event.button === 0) {
  //       setActivePoint(0);
  //       setInMoving(false);
  //       deletepointer(event.evt);
  //     }
  //   }
  //   window.addEventListener("mouseup", handleMouseUp);

  //   function handleMouseRightDown(event: any) {
  //     event.preventDefault();
  //     rotateLine();
  //   }
  //   window.addEventListener("contextmenu", handleMouseRightDown);

  //   return () => {
  //     window.removeEventListener("mouseup", handleMouseUp);
  //     window.removeEventListener("contextmenu", handleMouseRightDown);
  //   };
  // }, [points]);

  useEffect(() => {
    if (shouldFinish === true) {
      const length = getTwoPoints3DLength(
        { x: pointsRef.current.start.x, y: pointsRef.current.start.y, z: 0 },
        { x: pointsRef.current.end.x, y: pointsRef.current.end.y, z: 0 },
        1
      );

      const unitPer1 = length / unitValue;

      // Jednostka musi byc poza procesem bo potrzebna nawet po zakonczeniu rysowania
      dispatch(updateDrawingScale(unitPer1));
      dispatch(updateUnit(unitPer1));

      dispatch(finishLoadingDrawingScale());
    }
  }, [shouldFinish]);

  useEffect(() => {
    const mp = mpLocal;

    if (activePoint === 1) {
      let point = mp;

      // Sprawdzenie czy poziomo i pionowo dla punktu start
      if (Math.abs(mp.x - pointsRef.current.end.x) <= DIST) {
        point = { x: pointsRef.current.end.x, y: mp.y };
      }
      if (Math.abs(mp.y - pointsRef.current.end.y) <= DIST) {
        point = { x: mp.x, y: pointsRef.current.end.y };
      }
      setPoints({ ...pointsRef.current, start: { x: point.x, y: point.y } });
    } else if (activePoint === 2) {
      let point = mp;

      // Sprawdzenie czy poziomo i pionowo dla punktu start
      if (Math.abs(mp.x - pointsRef.current.start.x) <= DIST) {
        point = { x: pointsRef.current.start.x, y: mp.y };
      }
      if (Math.abs(mp.y - pointsRef.current.start.y) <= DIST) {
        point = { x: mp.x, y: pointsRef.current.start.y };
      }
      setPoints({ ...pointsRef.current, end: { x: point.x, y: point.y } });
    } else if (inMoving) {
      const vect = { x: mp.x - (saved?.startMove.x as number), y: mp.y - (saved?.startMove.y as number) };
      setPoints((prev) => ({
        start: { x: (saved?.start.x as number) + vect.x, y: (saved?.start.y as number) + vect.y },
        end: { x: (saved?.end.x as number) + vect.x, y: (saved?.end.y as number) + vect.y },
      }));
    }
  }, [mpLocal]);

  const rotateLine = () => {
    const centerX = (pointsRef.current.start.x + pointsRef.current.end.x) / 2;
    const centerY = (pointsRef.current.start.y + pointsRef.current.end.y) / 2;

    const rotate = (point: any, angle: any) => {
      const cosAngle = Math.cos(angle);
      const sinAngle = Math.sin(angle);

      const dx = point.x - centerX;
      const dy = point.y - centerY;

      const newX = cosAngle * dx - sinAngle * dy;
      const newY = sinAngle * dx + cosAngle * dy;

      return { x: Math.round(newX + centerX), y: Math.round(newY + centerY) };
    };

    const angle = (Math.PI / 180) * 90; // 90 stopni w radianach
    const newStart = rotate(pointsRef.current.start, angle);
    const newEnd = rotate(pointsRef.current.end, angle);

    // Aktualizacja stanu
    setPoints((prevPoints) => ({
      start: newStart,
      end: newEnd,
    }));
  };

  // Linia rysowana w górę od punktu start
  const getHelpLine = (point: { x: number; y: number }) => {
    const newLen = LEN / zoomScale;

    const direction = Math.atan2(
      pointsRef.current.end.y - pointsRef.current.start.y,
      pointsRef.current.end.x - pointsRef.current.start.x
    );

    const newX = point.x + newLen * Math.cos(direction - Math.PI / 2);
    const newY = point.y + newLen * Math.sin(direction - Math.PI / 2);

    return { x: newX, y: newY };
  };

  // Linia rysowana w dół od punktu start
  const getHelpLineSecond = (point: { x: number; y: number }) => {
    const newLen = LEN / zoomScale;

    const direction = Math.atan2(
      pointsRef.current.end.y - pointsRef.current.start.y,
      pointsRef.current.end.x - pointsRef.current.start.x
    );

    const newX = point.x - newLen * Math.cos(direction - Math.PI / 2);
    const newY = point.y - newLen * Math.sin(direction - Math.PI / 2);

    return { x: newX, y: newY };
  };

  const getInputLocation = () => {
    const z = -60 / zoomScale;

    const p1 = pointsRef.current.start;
    const p2 = pointsRef.current.end;
    const d = Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
    const dx = (p2.x - p1.x) / d;
    const dy = (p2.y - p1.y) / d;
    const cx = (p1.x + p2.x) / 2;
    const cy = (p1.y + p2.y) / 2;
    const x = cx + z * dy;
    const y = cy - z * dx;
    return { x, y };
  };

  const setPointer = (e: any) => {
    // e.target.getStage().container().style.cursor = "pointer";
    // e.target.attrs.fill = "#000000";
  };

  const deletepointer = (e: any) => {
    // e.target.getStage().container().style.cursor = "default";
    // e.target.attrs.fill = "#ffffff";
  };

  return (
    <Group>
      {/* Zestaw dwóch linii pomocniczych (w dół i w górę) */}
      <Line
        points={[
          pointsRef.current.start.x,
          pointsRef.current.start.y,
          getHelpLine(pointsRef.current.start).x,
          getHelpLine(pointsRef.current.start).y,
        ]}
        closed={false}
        stroke="#000000"
        strokeWidth={1 / zoomScale}
      />
      <Line
        points={[
          pointsRef.current.start.x,
          pointsRef.current.start.y,
          getHelpLineSecond(pointsRef.current.start).x,
          getHelpLineSecond(pointsRef.current.start).y,
        ]}
        closed={false}
        stroke="#000000"
        strokeWidth={1 / zoomScale}
      />

      {/* Zestaw dwóch linii pomocniczych (w dół i w górę) */}
      <Line
        points={[
          pointsRef.current.end.x,
          pointsRef.current.end.y,
          getHelpLine(pointsRef.current.end).x,
          getHelpLine(pointsRef.current.end).y,
        ]}
        closed={false}
        stroke="#000000"
        strokeWidth={1 / zoomScale}
      />
      <Line
        points={[
          pointsRef.current.end.x,
          pointsRef.current.end.y,
          getHelpLineSecond(pointsRef.current.end).x,
          getHelpLineSecond(pointsRef.current.end).y,
        ]}
        closed={false}
        stroke="#000000"
        strokeWidth={1 / zoomScale}
      />

      <Group x={getInputLocation().x} y={getInputLocation().y} scale={{ x: 1 / zoomScale, y: 1 / zoomScale }}>
        <Html>
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#ffffff",
              gap: "4px",
            }}
          >
            <DomaInputNumber
              style={{ width: "120px" }}
              addonAfter={"[m]"}
              value={unitValue}
              onChange={(v) => setUnitValue(v as number)}
            />
            <Button
              type="primary"
              onClick={() => {
                setShouldFinish(true);
              }}
            >
              OK
            </Button>
          </div>
        </Html>
      </Group>

      <Line
        points={[
          pointsRef.current.start.x,
          pointsRef.current.start.y,
          pointsRef.current.end.x,
          pointsRef.current.end.y,
        ]}
        closed={false}
        stroke="#2cb9f0"
        strokeWidth={25 / zoomScale}
        onMouseEnter={setPointer}
        onMouseLeave={deletepointer}
        onMouseDown={() => {
          setSaved({ start: pointsRef.current.start, end: pointsRef.current.end, startMove: mpLocal });
          setInMoving(true);
        }}
        onMouseUp={() => {
          setInMoving(false);
        }}
      />

      {generateTicks(pointsRef.current.start, pointsRef.current.end, 30, zoomScale)}

      <Circle
        x={pointsRef.current.start.x}
        y={pointsRef.current.start.y}
        radius={8 / zoomScale}
        strokeWidth={1 / zoomScale}
        stroke={"#000000"}
        fill="#ffffff"
        onMouseEnter={setPointer}
        onMouseLeave={deletepointer}
        onMouseDown={() => {
          setActivePoint(1);
        }}
        onMouseUp={() => setActivePoint(0)}
      />

      <Circle
        x={pointsRef.current.end.x}
        y={pointsRef.current.end.y}
        radius={8 / zoomScale}
        strokeWidth={1 / zoomScale}
        stroke={"#000000"}
        fill="#ffffff"
        onMouseEnter={setPointer}
        onMouseLeave={deletepointer}
        onMouseDown={() => setActivePoint(2)}
        onMouseUp={() => setActivePoint(0)}
      />
    </Group>
  );
};
export default DomaUnit;
