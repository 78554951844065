import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";

export interface IParentUserStatic {
  id: string;
  name: string;
  lastActivity: string;
}

export interface IParentInvestmentStatic {
  id: string;
  userId: string;
  reports: IParentReportStatic[];
}

export interface IParentReportStatic {
  id: string;
  created: string;
}

export interface IParentStatic {
  id: string | null;
  users: IParentUserStatic[];
  investments: IParentInvestmentStatic[];
}

const initialState: IParentStatic = {
  id: null,
  users: [],
  investments: [],
};

// Miejsce przechowywania ogólnych danych w programie
export const parentStaticSlice = createSlice({
  name: "parentStatic",
  initialState,
  reducers: {
    setParentStatic: (state, action: PayloadAction<IParentStatic>) => {
      return action.payload;
    },
    clearParentStatic: (state) => {
      return initialState;
    },
    setSavedParentStatic: (state, action: PayloadAction<IParentStatic>) => {
      return action.payload;
    },
  },
});

export const { setParentStatic, clearParentStatic, setSavedParentStatic } = parentStaticSlice.actions;
export default parentStaticSlice.reducer;
