import { Card, Input, Image } from "antd";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { getBasicUrlSystemImage, FALLBACK_IMAGE } from "../../../../../../helpers/Helpers";
import DomaLogoComponent from "../../../../../../components/SVGComponents/DomaLogoComponent";
import { PRIMARY_COLOR, DOMA_LOGO_COLOR_A } from "../../../../../../theme";

export default function SystemCard({ system, selectSystem, selected }) {
  const getImg = () => {
    if (system.imageUrl && system.imageUrl !== "") {
      return getBasicUrlSystemImage() + system.imageUrl;
    } else {
      return "";
    }
  };
  return (
    <Container hoverable onClick={() => selectSystem(system.id)} selected={selected}>
      <CardBody>
        <CardBodyCol>
          {system.imageUrl && system.imageUrl !== "" ? (
            <CardImage alt={system.name} src={getImg()} />
          ) : (
            <DomaLogoComponent style={{ maxWidth: "100px" }} colorA={DOMA_LOGO_COLOR_A} />
          )}
        </CardBodyCol>
        <CardBodyCol>
          <span style={{ fontWeight: "normal" }}>
            {system.owner && `[${system.owner}}]`}
            <p>
              <b>{system.name}</b>
            </p>
          </span>
        </CardBodyCol>
      </CardBody>
    </Container>
  );
}

const Container = styled(Card)`
  cursor: pointer;
  background: ${(props) => (props.selected ? PRIMARY_COLOR : "")};
  color: ${(props) => (props.selected ? "#ffffff" : "")};
`;

const CardBody = styled.div`
  display: flex;
  height: 120px;
`;

const CardBodyCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  font-size: 16px;
  font-weight: bold;
`;

const CardImage = styled.img`
  max-width: 150px;
`;
