import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditFilled,
  EditOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import React, { FC, useState } from "react";
import { Group, Line, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import { IMetalSheet, IRoofPoint, IRoofSlopePoint } from "../../../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { updateMetalSheet } from "../../../../../../redux/roofMetal/metalSheetsSlice";

type Props = {
  metalSheet: IMetalSheet;
  startPoint: { x: number; y: number }[];
  onClickEdit: any;
  zoomScale: number;
};

const MetalSheetComponent: FC<Props> = ({ metalSheet, startPoint, onClickEdit, zoomScale }) => {
  const dispatch = useAppDispatch();

  const inactiveColor = "#d8e3e7cc"; // Kolor dla nieaktywnego metalSheet
  const defaultColor = "#68f74ccc";

  const initialColor = metalSheet.active ? defaultColor : inactiveColor;
  const [fillColor, setFillColor] = useState(initialColor);

  const [showIcon, setShowIcon] = useState(false);

  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);
  const scale = useAppSelector((state) => state.drawingScale);

  const handleMouseEnter = (e: any) => {
    if (showIcon === false) {
      setShowIcon(true);
    }
  };

  const handleMouseLeave = (e: any) => {
    if (showIcon === true) {
      setShowIcon(false);
    }
  };

  const handleClickActivity = () => {
    dispatch(updateMetalSheet({ ...metalSheet, active: !metalSheet.active }));
    setFillColor(metalSheet.active ? inactiveColor : defaultColor); // Aktualizuje kolor po kliknięciu
  };

  const handleClickEdit = () => {
    onClickEdit();
  };

  // Funckja, która narysuje div w konva o wielkości połowy arkusza blachy
  const dividedButtons = () => {
    const msWidth = metalSheet.width;
    const msHeight = metalSheet.height;

    const height = calcMaterialsData.systemType !== 1 ? msHeight / 2 : msHeight;
    const start = calcMaterialsData.systemType !== 1 ? msHeight / 2 : 0;
    return (
      <Group>
        <Group x={startPoint[0].x} y={startPoint[0].y + start}>
          <Rect
            x={0}
            y={0}
            width={msWidth}
            height={height}
            fill={fillColor}
            onClick={() => handleClickActivity()}
          />
          <Html
            divProps={{
              style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: msWidth + "px",
                height: height + "px",
                cursor: "pointer",
                pointerEvents: "none",
              },
            }}
          >
            {metalSheet.active ? (
              <CloseCircleOutlined
                style={{ fontSize: `${16 / zoomScale}px`, color: "#000000", transform: "rotate(180deg)" }}
              />
            ) : (
              <CheckCircleOutlined
                style={{ fontSize: `${16 / zoomScale}px`, color: "#000000", transform: "rotate(180deg)" }}
              />
            )}
          </Html>
        </Group>

        {calcMaterialsData.systemType !== 1 && (
          <Group x={startPoint[0].x} y={startPoint[0].y}>
            <Rect
              x={0}
              y={0}
              width={msWidth}
              height={msHeight / 2}
              fill={"#d6c3c3"}
              onClick={() => handleClickEdit()}
            />
            <Html
              divProps={{
                style: {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: msWidth + "px",
                  height: msHeight / 2 + "px",
                  pointerEvents: "none",
                },
              }}
            >
              <EditFilled
                style={{ fontSize: `${16 / zoomScale}px`, color: "#000000", transform: "rotate(180deg)" }}
              />
            </Html>
          </Group>
        )}
      </Group>
    );
  };

  return (
    <Group onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Rect
        x={startPoint[0].x}
        y={startPoint[0].y}
        width={metalSheet.width}
        height={metalSheet.height}
        fill={fillColor}
        stroke={"#000000"}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        // onClick={handleClick}
        cursor="pointer"
        strokeWidth={5 / scale}
      />

      {!showIcon && metalSheet.active && (
        <Group
          x={startPoint[0].x + metalSheet.width / 2}
          y={startPoint[0].y + metalSheet.height / 2}
          offsetX={metalSheet.width / 2}
          offsetY={metalSheet.height / 2}
          rotation={180}
        >
          <Html
            divProps={{
              style: {
                position: "absolute",
                pointerEvents: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: metalSheet.width + "px",
                height: metalSheet.height + "px",
              },
            }}
          >
            <div>
              <div
                style={{
                  minWidth: "20px",
                  fontSize: `${16 / zoomScale}px`,
                  lineHeight: `${14 / zoomScale}px`,
                  fontWeight: "bold",
                  padding: "2px 2px",
                  background: "#ececec",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {metalSheet.type}
              </div>
              {calcMaterialsData.systemType !== 1 && (
                <div
                  style={{
                    color: "#353535",
                    textAlign: "center",
                    fontSize: `${14 / zoomScale}px`,
                    marginTop: `${8 / zoomScale}px`,
                  }}
                >
                  {(metalSheet.height / scale).toFixed(2)}m
                </div>
              )}
            </div>
          </Html>
        </Group>
      )}

      {showIcon && dividedButtons()}
    </Group>
  );
};

export default MetalSheetComponent;

const StyledActivityDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f14040;
`;

const StyledEditDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #9471d3;

  &:hover {
    background: #46424d;
  }
`;
