import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import { IAddProduct, IProduct, IResultItemResponse, IRootState, ISalesForm } from "../models/Models";
import { useForm } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  addCalcResultItem,
  addProductToOrder,
  deleteCalcResultItem,
  updateCalcResultItem,
} from "../redux/calculationProcess/calcResultSlice";
import DomaInputNumber from "../helpers/DomaInputNumber";
import { getDataProductsForSelect } from "../helpers/DomaFunctions";
import { v4 as uuidv4 } from "uuid";
import { createFilterOption, getProductPrice } from "../helpers/Helpers";
import { updateProductInReducer } from "../redux/general/productsSlice";
import client from "../api/ApiClient";
import { PRIMARY_COLOR } from "../theme";
import {
  addSavedAdditionalProductReducer,
  updateSavedAdditionalProductReducer,
} from "../redux/calculationProcess/savedAdditionalProducts";
import store from "../redux/store";

type Props = {
  open: boolean;
  onCancel: any;
  onOk: any;
  item: IResultItemResponse | null;
};

const ResultItemModal: FC<Props> = ({ item, open, onCancel, onOk }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const products = useAppSelector((state: IRootState) => state.products);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);

  // VAT jaki jest const vat = getState().calcResult.vat;
  const calcResult = useAppSelector((state: IRootState) => state.calcResult);

  const [toOrder, setToOrder] = useState<boolean>(false);

  const [priceOneItem, setPriceOneItem] = useState<number>(0);

  useEffect(() => {
    console.log("Reload ResultItemModal");
  });

  const onChangeSelectedProduct = (value: string) => {
    const product = products.find((x) => x.id === value);
    if (product) {
      const fetchData = async () => {
        try {
          const response = await client.get("/Users/fullProduct/" + product.id);
          if (response.status === 200) {
            console.log("Full product", response.data);
            dispatch(updateProductInReducer(response.data));
          }
        } catch (err) {}
      };
      fetchData();
    }
  };

  const onChangePriceOneItem = (value: number) => {
    const baseNettPriceOneItem = item?.price || 0; // Cena netto za 1 sztukę pozycji z produktu

    const priceTyped = value; // podana wartośc przez użytkownika

    const typeDiscount = form.getFieldValue("discountType"); // typ rabatu [%] lub [zł]
    const discountPart = form.getFieldValue("discountPart"); // przeznaaczenie rabatu [nett] lub [gross]
    const amount = form.getFieldValue("amount"); // ilość pozycji

    // Gdy typ rabatu cenowy to przeliczyć jaki bedzie rabat kwotowy na całą pozycje czyli wszystkie ilości
    if (typeDiscount === "price") {
      if (discountPart == "nett") {
        // Obecna wartośc netto dla produktów
        const sumNett = baseNettPriceOneItem * amount;

        // Jaka będzie wartośc netto przy wpisanej cenie netto
        const sumNettTyped = priceTyped * amount;

        // Różnica tych cen to nasza zniżka
        const distPrices = sumNett - sumNettTyped;
        const finalPrice = Number(distPrices.toFixed(4));

        form.setFieldsValue({ discount: finalPrice });
      } else {
        // Dla ceny brutto
        const vat = calcResult.vat || 0;

        // Obecna wartośc brutto dla produktów
        const sumGross = baseNettPriceOneItem * (1 + vat / 100) * amount;

        // Jaka będzie wartośc brutto przy wpisanej cenie brutto
        const sumGrossTyped = priceTyped * amount;

        // Różnica tych cen to nasza zniżka
        const distPrices = sumGross - sumGrossTyped;
        const finalPrice = Number(distPrices.toFixed(4));

        form.setFieldsValue({ discount: finalPrice });
      }
    } else if (typeDiscount === "%") {
      if (discountPart === "nett") {
        const perc = 100 - (priceTyped * 100) / baseNettPriceOneItem;
        const finalPerc = Number(perc.toFixed(4));

        form.setFieldsValue({ discount: finalPerc });
      } else {
        const vat = calcResult.vat || 0;
        console.log("vat", vat);
        const calculatedNettPriceTyped = priceTyped / (1 + vat / 100);

        const perc = 100 - (calculatedNettPriceTyped * 100) / baseNettPriceOneItem;
        const finalPerc = Number(perc.toFixed(4));

        form.setFieldsValue({ discount: finalPerc });
      }
    }
  };

  const isNew = () => {
    return item ? false : true;
  };

  const getInitialValues = () => {
    return {
      name: item?.name ? item?.name : "",
      amount: item?.amount ? item?.amount : 0,
      unitPrice: item?.price ? item?.price : 0,
      discount: item?.discount ? item?.discount : 0,
      discountType: item?.discountType,
      discountPart: item?.discountPart,
    };
  };

  const onFinish = (values: any) => {
    if (!isNew()) {
      // Gdy aktualziacja
      // console.log("AKTUALIZACJA");
      if (values.name.length > 0) {
        const model: IResultItemResponse = {
          id: item?.id as string,
          active: item?.active as boolean,
          productId: item?.productId as string,
          categoryCode: item?.categoryCode as string,
          name: values.name,
          amount: values.amount,
          unit: item?.salesFormName || "Sztuka",
          price: item?.price as number,
          salesFormCurrency: item?.salesFormCurrency || "PLN",
          salesFormName: item?.salesFormName || "Sztuka",
          discount: values.discount,
          discountType: values.discountType,
          discountPart: values.discountPart,
        };
        dispatch(updateCalcResultItem(model));

        // Sprawdzamy czy produkt jest w savedAdditionalProducts i go aktualizujemy
        const savedAdditionalProduct = store.getState().savedAdditionalProducts.find((x) => x.id === item?.id);
        if (savedAdditionalProduct) {
          dispatch(updateSavedAdditionalProductReducer(model));
        }

        onOk();
      }
    } else {
      // Gdy nowy produkt
      if (!toOrder) {
        // Jeśli istniejący produkt
        // console.log("ISTNIEJĄCY PRODUKT");
        const product = products.find((x) => x.id === values.name);

        if (product && values.amount > 0) {
          const category = productCategories?.find((x) => x.id === product?.productCategoryId);
          const company = companies?.find((x) => x.id === product.companyId);
          const productModel = productModels?.find((x) => x.id === product.productModelId);
          const color = productColors?.find((x) => x.id === product.productColorId);

          let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

          let productName = `${product.name} ${company?.name} ${productModel?.name} (${colorName}`;
          if (product.coating.length > 0) {
            productName += `, ${product.coating}`;
          }
          productName += ")";

          // Pobranie salesForm dla produktu
          // Taki, który ma amount większe od 0 i jeśli jest takich kform kilka to ten nja mniejszym amount
          const salesForms = product.salesForms.filter((x) => x.amount > 0);
          const salesForm = salesForms ? salesForms.sort((a, b) => a.amount - b.amount)[0] : null;

          const unit = salesForm ? salesForm.name : "Sztuka";
          const price = salesForm ? salesForm.price : 0;
          const currency = salesForm ? salesForm.currency : "PLN";

          const model: IResultItemResponse = {
            id: uuidv4(),
            active: true,
            productId: values.name as string,
            categoryCode: category?.code as string,
            name: `${productName}`,
            amount: values.amount,
            unit: unit,
            price: price,
            salesFormCurrency: currency,
            salesFormName: unit,
            discount: 0,
            discountType: "%",
            discountPart: "nett",
          };

          dispatch(addCalcResultItem(model));

          dispatch(addSavedAdditionalProductReducer(model));
          onOk();
        }
      } else {
        // Jeśli produkt na zamównienie
        // console.log("PRODUKT NA ZAMÓWIENIE");
        if (values.name.length > 0 && values.unitPrice > 0 && values.amount > 0) {
          var salesForm: ISalesForm = {
            id: uuidv4(),
            isActive: true,
            amount: values.amount,
            name: values.name,
            price: values.unitPrice,
            currency: "PLN",
            packageCapacity: 0,
            packageDiameter: 0,
            packageHeight: 0,
            packageLength: 0,
            packageWeight: 0,
            packageWidth: 0,
            waitingTime: 0,
            productUnavailable: false,
            madeToOrderProduct: false,
            productId: "",
          };

          let model: IAddProduct = {
            name: values.name,
            ean: "",
            active: true,
            coating: "",
            effectiveWidth: 0,
            lowerZipperLength: 0,
            upperZipperLength: 0,
            material: "",
            maxLength: 0,
            minLength: 0,
            moduleCount: 0,
            moduleLength: 0,
            producerId: "",
            shelfLife: "",
            warranty: "",
            storageTemperature: 0,
            companyId: "",
            externalId: "",
            productCategoryId: productCategories.find((x) => x.code === "others")?.id as string,
            productColorId: productColors.find((x) => x.name === "Brak")?.id as string,
            productDiscount: 0,
            stockQuantity: 0,
            width: 0,
            length: 0,
            weight: 0,
            capacity: 0,
            diameter: 0,
            height: 0,
            productModelId: "",
            salesForms: [salesForm],
            use: 0,
            calculationUnit: "szt.",
          };

          dispatch(addProductToOrder(model, values.amount, true));

          // dispatch(addSavedAdditionalProductReducer(model));

          onOk();
        }
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getButtonsInFooter = () => {
    const buttons = [];

    if (!isNew()) {
      buttons.push(
        <Button
          type="primary"
          key="delete"
          danger
          onClick={() => {
            dispatch(deleteCalcResultItem(item?.id as string));
            onCancel();
          }}
        >
          Usuń
        </Button>
      );
    }

    buttons.push(
      <Button key="back" onClick={onCancel}>
        Anuluj
      </Button>
    );

    if (isNew()) {
      buttons.push(
        <Button form="resultItemForm" key="submit" htmlType="submit" type="primary">
          Dodaj
        </Button>
      );
    } else {
      buttons.push(
        <Button form="resultItemForm" key="submit" htmlType="submit" type="primary">
          Zaktualizuj
        </Button>
      );
    }

    return buttons;
  };

  return (
    <Container
      title={isNew() ? "Dodaj dodatkowy produkt" : `${item?.name}`}
      open={open}
      onCancel={onCancel}
      centered
      width={"50%"}
      footer={getButtonsInFooter()}
    >
      <div>
        <Form
          id="resultItemForm"
          style={{ width: "100%", marginTop: "32px" }}
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={getInitialValues()}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {isNew() && (
            <div style={{ marginBottom: "16px" }}>
              <Checkbox checked={toOrder} onChange={(e) => setToOrder(e.target.checked)} />{" "}
              <span style={{ marginLeft: "8px" }}>Produkt na zamówienie</span>
            </div>
          )}

          {!isNew() || toOrder ? (
            <Form.Item label="Nazwa pozycji" name="name">
              <Input />
            </Form.Item>
          ) : (
            <Form.Item label="Wpisz i wyszukaj produkt na liście" name="name">
              <Select
                placeholder="Wybierz produkt!"
                options={getDataProductsForSelect(
                  products,
                  companies,
                  productCategories,
                  productColors,
                  productModels
                )}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={onChangeSelectedProduct}
              />
            </Form.Item>
          )}

          {toOrder && (
            <Form.Item label="Cena za jednostkę [zł]" name="unitPrice">
              <DomaInputNumber />
            </Form.Item>
          )}

          <Form.Item label="Ilość elementów [szt./jedn.]" name="amount">
            <DomaInputNumber />
          </Form.Item>

          {!isNew() && (
            <>
              <Form.Item label="Typ rabatu" name="discountType">
                <StyledRadioGroup>
                  <Radio.Button value="%">Procentowy [%]</Radio.Button>
                  <Radio.Button value="price">Kwotowy [zł]</Radio.Button>
                </StyledRadioGroup>
              </Form.Item>

              <Form.Item label="Przeznaczenie rabatu" name="discountPart">
                <StyledRadioGroup>
                  <Radio.Button value="nett">Dla kwoty netto</Radio.Button>
                  <Radio.Button value="gross">Dla kwoty brutto</Radio.Button>
                </StyledRadioGroup>
              </Form.Item>

              <Row>
                <Col span={7}>
                  <Form.Item
                    label={`Podaj wartość rabatu [${form.getFieldValue("discountType") === "%" ? "%" : "zł"}]`}
                    name="discount"
                  >
                    <DomaInputNumber style={{ minWidth: "120px" }} allowNegative />
                  </Form.Item>
                </Col>
                <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                  lub
                </Col>
                <Col span={13}>
                  <Tooltip
                    title={
                      <div style={{ textAlign: "center" }}>
                        Podaj wartość zgodnie z przeznaczeniem rabatu. <br />
                        Dla netto podaj wartość netto a dla brutto podaj wartość brutto.
                      </div>
                    }
                    color={PRIMARY_COLOR}
                  >
                    <Form.Item label="Podaj cenę (netto/brutto) za sztukę (automatyczny rabat) [zł]">
                      <DomaInputNumber
                        style={{ verticalAlign: "middle" }}
                        value={priceOneItem}
                        onChange={(value) => onChangePriceOneItem(value as number)}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
    </Container>
  );
};
export default ResultItemModal;

const Container = styled(Modal)`
  display: flex;
  width: 100%;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
