import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWindowHoleState {
  index: number;
  width: number;
  height: number;

  windowProducts: string[]; // Ids okien do otwóru
  additionProducts: string[]; // Ids dodatków do otwóru jak kołnierz, roleta itp.
}

const initialState: IWindowHoleState[] = [];

export const windowHolesSlice = createSlice({
  name: "windowHoles",
  initialState,
  reducers: {
    addWindowHole: (state, action: PayloadAction<IWindowHoleState>) => {
      let item = action.payload;

      // Sprawdzamy największy index
      let maxIndex = 0;
      state.forEach((x) => {
        if (x.index > maxIndex) maxIndex = x.index;
      });

      item = { ...item, index: maxIndex + 1 };

      return [...state, item];
    },
    // Dodaj windowProdukt
    addWindowProductsToWindowHole: (state, action: PayloadAction<{ index: number; productIds: string[] }>) => {
      const { index, productIds } = action.payload;

      const windowHole = state.find((x) => x.index === index);

      if (windowHole) {
        windowHole.windowProducts = productIds;
      }
    },
    // Dodaj additionProdukt
    addAdditionProductsToWindowHole: (state, action: PayloadAction<{ index: number; productIds: string[] }>) => {
      const { index, productIds } = action.payload;

      const windowHole = state.find((x) => x.index === index);

      if (windowHole) {
        windowHole.additionProducts = productIds;
      }
    },
    setWindowHoles: (state, action: PayloadAction<IWindowHoleState[]>) => {
      let holes = action.payload;
      return [...holes];
    },
    deleteOneWindowHole: (state, action: PayloadAction<IWindowHoleState>) => {
      const windowHole = action.payload;

      return state.filter((o) => o.index !== windowHole.index);
    },
    resetWindowHoles: (state) => {
      // Czyszczenie dobranych produktów
      state.forEach((x) => {
        x.additionProducts = [];
        x.windowProducts = [];
      });
    },
    clearWindowHoles: (state) => {
      return initialState;
    },
    setSavedRoofWindowHoles: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addWindowHole,
  clearWindowHoles,
  resetWindowHoles,
  addAdditionProductsToWindowHole,
  addWindowProductsToWindowHole,
  deleteOneWindowHole,
  setSavedRoofWindowHoles,
  setWindowHoles,
} = windowHolesSlice.actions;
export default windowHolesSlice.reducer;
