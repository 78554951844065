import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilterSystem, IFilterSystemPosition } from "../../models/Models";

export interface IOtherAppStatuses {
  lastRoofSystemTab: "tiles" | "steel"; // ostatnio wybrana zakładka w systemach dachowych
}

const initialState: IOtherAppStatuses = {
  lastRoofSystemTab: "tiles",
};

// Kontener na dane materiałowe potrzebne do oblczeń
export const otherAppStatusesSlice = createSlice({
  name: "otherAppStatuses",
  initialState,
  reducers: {
    updateLastRoofSystemTab: (state, action: PayloadAction<"tiles" | "steel">) => {
      state.lastRoofSystemTab = action.payload;
    },
    clearOtherAppStatuses: (state) => {
      return initialState;
    },
    setSavedOtherAppStatuses: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { clearOtherAppStatuses, updateLastRoofSystemTab, setSavedOtherAppStatuses } =
  otherAppStatusesSlice.actions;
export default otherAppStatusesSlice.reducer;
