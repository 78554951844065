import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, Input, message, Radio, Row, Select } from "antd";
import {
  IAddFasteningSystem,
  IAddPreCoveringSystem,
  IFasteningSystem,
  IFasteningSystemAddition,
  IPreCoveringSystem,
  IPreCoveringSystemAddition,
  IProduct,
  IProductCategory,
  IRootState,
  ISelectOption,
} from "../../../models/Models";
import { addPreCoveringSystem, updatePreCoveringSystem } from "../../../redux/roofSystems/preCoveringSystemsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { CATEGORIES_ALL_ROOF, EXTRA_TILE_SYSTEM_RELATIONS, TILE_SYSTEM_RELATIONS } from "../RoofConstants";
import { createFilterOption } from "../../../helpers/Helpers";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { addFasteningSystem, updateFasteningSystem } from "../../../redux/roofSystems/fasteningSystemsSlice";
import ExtraProductFasteningSystemPosition from "./ExtraProductFasteningSystemPosition";
import SystemImageComponent from "../roofSystems/components/SystemImageComponent";
import SystemConfigurationDetailsSection from "../components/SystemConfigurationDetailsSection";

type Props = {
  system: IFasteningSystem | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const FasteningSystemDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);
  const statusApi = useAppSelector((state: IRootState) =>
    state.statusApi.find((x: any) => x.key === "addFasteningSystem")
  );

  const [fasteningSystemAdditions, setFasteningSystemAdditions] = useState<IFasteningSystemAddition[]>([]);

  const [imageFileName, setImageFileName] = useState<string>("");

  const fasteningSystems = useAppSelector((state: IRootState) => state.fasteningSystems);

  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-tiles-concrete",
            "basic-products-tiles-ceramic",
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
            "wood",
            "supplementary-products",
            "lightning-protection-systems",
            "thermal-insulation",
            "roof-walkways-and-snow-guards",
            "roofing-accessories",
            "fasteners",
            "preliminary-covering",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addFasteningSystem"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addFasteningSystem"));
    }
  }, [statusApi]);

  useEffect(() => {
    if (fasteningSystems) {
      const s = fasteningSystems.find((x) => x.id === system?.id);
      if (s) {
        setFasteningSystemAdditions(s.fasteningSystemAdditions);
      }
    }
  }, [fasteningSystems]);

  useEffect(() => {
    if (system && system.fasteningSystemAdditions && system.fasteningSystemAdditions.length > 0) {
      setFasteningSystemAdditions(system.fasteningSystemAdditions);
    }

    if (system?.imageUrl) {
      setImageFileName(system.imageUrl);
    }
  }, [system]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getInitialValues = () => {
    return isNew()
      ? {
          name: "",
          description: "",
          imageUrl: "",
        }
      : {
          name: system?.name,
          description: system?.description,
          imageUrl: system?.imageUrl,

          companyId: system?.companyId,
          modelId: system?.productModelId,
          colorId: system?.productColorId,
        };
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "System został utworzony!",
    });
  };

  const onFinish = (values: any, newVersion: boolean) => {
    if (isNew() || newVersion) {
      let s: IAddFasteningSystem = {
        name: values.name,
        description: values.description,
        // imageUrl: values.imageUrl,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        fasteningSystemAdditions: fasteningSystemAdditions,
      };
      dispatch(addFasteningSystem(s, successCallback));
    } else {
      let s: IFasteningSystem = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        // imageUrl: values.imageUrl,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        fasteningSystemAdditions: fasteningSystemAdditions,
      };
      dispatch(updateFasteningSystem(s));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  return (
    <Container>
      {contextHolder}
      <Form
        id="fasteningSystemDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Section title={"Ogólne dane systemu"}>
          <Form.Item
            label="Nazwa systemu"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę systemu!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis systemu" name="description" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>

          {/* <Form.Item label="Link do zdjęcia obrazującego system" name="imageUrl" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item> */}

          <SystemImageComponent
            systemId={system?.id || ""}
            systemName="fastener"
            imageFileName={imageFileName}
            setImageFileName={(name) => setImageFileName(name)}
          />
        </Section>

        {/* Sekcja do konfiguracji producenta, modelu oraz koloru */}
        <SystemConfigurationDetailsSection form={form} />

        <Section
          title={"Wszystkie produkty systemu (uzależnione od typu krawędzi, powierzchni dachu, itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newFasteningSystemAddition: IFasteningSystemAddition = {
                  id: uuidv4(),
                  productId: "",
                  use: 0,
                  relation: "none",
                  correction: 0,
                  fasteningSystemId: system?.id as string,
                };
                setFasteningSystemAdditions([newFasteningSystemAddition, ...fasteningSystemAdditions]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {fasteningSystemAdditions.map((addition) => {
            // console.log("additionMap", addition);
            return (
              <ExtraProductFasteningSystemPosition
                key={addition.id}
                addition={addition}
                selectedCategories={[
                  "basic-products-tiles-concrete",
                  "basic-products-tiles-ceramic",
                  "basic-products-sheet-metal",
                  "basic-products-cut-sheet-metal",
                  "basic-products-sheet-metal-trapezoidal",
                  "basic-products-sheet-metal-standing-seam",
                  "wood",
                  "supplementary-products",
                  "lightning-protection-systems",
                  "thermal-insulation",
                  "roof-walkways-and-snow-guards",
                  "roofing-accessories",
                  "fasteners",
                  "preliminary-covering",
                ]}
                readyOptions={productsSelectForAdditions}
                products={products}
                productCategories={productCategories}
                useDisabled={false}
                relationDisabled={false}
                correctionDisabled={false}
                relations={[...TILE_SYSTEM_RELATIONS]}
                updateAddition={(addition: any) => {
                  const newAdditions = [...fasteningSystemAdditions];
                  const index = newAdditions.findIndex((x) => x.id === addition.id);

                  if (index >= 0) {
                    newAdditions[index] = addition;
                    setFasteningSystemAdditions(newAdditions);
                  }
                }}
                deleteAddition={(addition: any) => {
                  const newAdditions = [...fasteningSystemAdditions].filter((x) => x.id !== addition.id);
                  setFasteningSystemAdditions(newAdditions);
                }}
              />
            );
          })}
        </Section>
      </Form>
    </Container>
  );
};

export default FasteningSystemDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
