import { Alert, Button, Form, Input, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { createFilterOption, getElementsForSelect } from "../../../helpers/Helpers";
import { addModel } from "../../../redux/general/productModelsSlice";
import { useAppSelector } from "../../../redux/hooks";

type Props = {
  open: boolean;
  onOk: any;
  onCancel: any;
  dispatch: any;
  defaultCompanyId: string | null;
};

const AddModelModal: FC<Props> = ({ open, onOk, onCancel, dispatch, defaultCompanyId }) => {
  const [form] = useForm();
  const companies = useAppSelector((state) => state.companies);

  const onFinish = (values: any) => {
    dispatch(addModel({ name: values.name, companyId: values.company }));
    onOk();
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Container
      title="Dodaj model produktu"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      centered
      footer={[
        <Button
          key="back"
          onClick={() => {
            onCancel();
          }}
        >
          Anuluj
        </Button>,
        ,
        <Button form="productModelForm" key="ok" type="primary" htmlType="submit">
          Dodaj
        </Button>,
      ]}
    >
      <Form
        id="productModelForm"
        style={{ width: "100%" }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        initialValues={{ company: defaultCompanyId }}
      >
        <Form.Item
          label="Nazwa modelu"
          name="name"
          rules={[{ required: true, message: "Podaj nazwę modelu produktu!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Producent"
          name="company"
          rules={[{ required: true, message: "Wybierz producenta!" }]}
          labelCol={{ span: 24 }}
        >
          <Select
            options={getElementsForSelect(companies)}
            showSearch
            filterOption={createFilterOption()}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

export default AddModelModal;

const Container = styled(Modal)``;
