import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { TEXT_COLOR } from "../../theme";

export default function PageHeader(props) {
  const { title } = props;
  const navigate = useNavigate();

  return (
    <Container>
      <Left>
        <ArrowLeftOutlined onClick={() => navigate(-1)} />
        <Title>{title}</Title>
      </Left>

      <Extra>{props?.extra}</Extra>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  height: 35px;
  color: ${TEXT_COLOR};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  display: flex;
  margin: 0 0 0 16px;

  font-size: 20px;
  font-weight: 600;
`;

const Extra = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px 0 0;
`;
