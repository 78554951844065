import { WarningFilled, WarningOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { FC, useEffect, useState } from "react";
import { Group, Line, Text } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import { EdgeType } from "../../../../../../models/Enums";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { getEdgeColor } from "../../others/RoofEdge";
import { IRSO, IRSOEdge, IRSOPoint } from "./Models/RoofSlopeOutlineModels";
import { updateRSOEdge } from "../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";

type Props = {
  rsoe: IRSOEdge;
  openEdgeModal: (rsoe: IRSOEdge) => void;
  colored?: boolean;
  zoomScale: number;
  selectedEdgeType?: EdgeType;
  fastEdgeMode?: boolean;
  isEdgeForDeclineVectorSelecting?: boolean;
  setSelectedEdgeForDeclineVector?: (edge: IRSOEdge | null) => void;
};

const RoofSlopeOutlineEdge: FC<Props> = ({
  rsoe,
  openEdgeModal,
  colored,
  zoomScale,
  fastEdgeMode,
  selectedEdgeType,
  isEdgeForDeclineVectorSelecting,
  setSelectedEdgeForDeclineVector,
}) => {
  const dispatch = useAppDispatch();
  const [edge, setEdge] = useState<IRSOEdge>(rsoe) || [];

  const [hover, setHover] = useState<boolean>(false);

  const rsoPoints = useAppSelector((state) => state.rsoPoints) || []; // wszystkie punkty na całgym rysunku

  useEffect(() => {
    if (rsoe) {
      setEdge(rsoe);
    }
  }, [rsoe]);

  const setPointer = (e: any) => {
    e.target.getStage().container().style.cursor = "pointer";
  };

  const deletepointer = (e: any) => {
    e.target.getStage().container().style.cursor = "default";
  };

  const onClickRoofEdge = () => {
    if (fastEdgeMode) {
      if (selectedEdgeType) {
        dispatch(updateRSOEdge({ ...rsoe, type: selectedEdgeType }));
        dispatch(invalidateCalculation());
      }
    } else if (isEdgeForDeclineVectorSelecting && setSelectedEdgeForDeclineVector) {
      setSelectedEdgeForDeclineVector(edge);
    } else {
      openEdgeModal(edge);
    }
  };

  const getPointsEdge = () => {
    const startPoint = rsoPoints.find((p) => p.id === edge.startPointId);
    const endPoint = rsoPoints.find((p) => p.id === edge.endPointId);

    if (startPoint && endPoint) {
      return [startPoint.x, startPoint.y, endPoint.x, endPoint.y];
    }

    return [];
  };

  const getColorLine = () => {
    const baseColor = colored ? getEdgeColor(edge.type) : "#000000";

    if (hover || rsoe.hover) {
      return "#737e6e";
    } else {
      return baseColor;
    }
  };

  const WIDTH_BOX_WARNING = 25;
  const HEIGHT_BOX_WARNING = 25;

  const getMiddlePoint = () => {
    const points = getPointsEdge();
    if (points.length === 4) {
      return [
        (points[0] + points[2]) / 2 - WIDTH_BOX_WARNING / 2 / zoomScale,
        (points[1] + points[3]) / 2 - HEIGHT_BOX_WARNING / 2 / zoomScale,
      ];
    }
    return [0, 0];
  };

  return (
    <>
      <Line
        name="rsoEdge"
        id={edge.id}
        key={edge.id}
        points={getPointsEdge()}
        stroke={getColorLine()}
        strokeWidth={hover || rsoe.hover ? 10 / zoomScale : 5 / zoomScale}
        hitStrokeWidth={20}
        onMouseEnter={(e) => {
          setPointer(e);
          setHover(true);
        }}
        onMouseLeave={(e) => {
          deletepointer(e);
          setHover(false);
        }}
        onClick={() => {
          onClickRoofEdge();
        }}
      />

      {edge.type === 99 && (
        <Group x={getMiddlePoint()[0]} y={getMiddlePoint()[1]}>
          <Html>
            <Tooltip title={<TooltipText>Niezdefiniowany typ krawędzi!</TooltipText>} color="#ff0000">
              <WarningFilled
                style={{
                  display: "flex",
                  fontSize: `${(WIDTH_BOX_WARNING - 5) / zoomScale}px`,
                  width: `${WIDTH_BOX_WARNING / zoomScale}px`,
                  height: `${HEIGHT_BOX_WARNING / zoomScale}px`,
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#e8e8e8",
                  color: "#ff0000",
                }}
                onClick={() => {
                  onClickRoofEdge();
                }}
              />
            </Tooltip>
          </Html>
        </Group>
      )}
    </>
  );
};

export default RoofSlopeOutlineEdge;

const TooltipText = styled.div`
  text-align: center;
`;

// import React from "react";
// import { FC, useEffect, useState } from "react";
// import { Group, Line } from "react-konva";
// import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
// import { updateRoofSlopeOutlineEdge } from "../../../../../../redux/roofSlopeOutline/roofSlopeOutlinesSlice";
// import { IRSO, IRSOEdge, IRSOPoint } from "./Models/RoofSlopeOutlineModels";

// type Props = {
//   rsoe: IRSOEdge;
//   openEdgeModal: (rsoe: IRSOEdge) => void;
// };

// const RoofSlopeOutlineEdge: FC<Props> = ({ rsoe, openEdgeModal }) => {
//   const dispatch = useAppDispatch();
//   const [edge, setEdge] = useState<IRSOEdge>(rsoe) || [];

//   const [hover, setHover] = useState<boolean>(false);

//   const roofSlopeOutlines = useAppSelector((state) => state.roofSlopeOutlines) || [];
//   const roofSlopeOutlineLinkedEdges = useAppSelector((state) => state.roofSlopeOutlineLinkedEdges) || [];

//   useEffect(() => {
//     if (rsoe) {
//       setEdge(rsoe);
//     }
//   }, [rsoe]);

//   const setPointer = (e: any) => {
//     e.target.getStage().container().style.cursor = "pointer";
//   };

//   const deletepointer = (e: any) => {
//     e.target.getStage().container().style.cursor = "default";
//   };

//   const onClickRoofEdge = () => {
//     openEdgeModal(edge);
//   };

//   return (
//     <Line
//       name="roofSlopeOutlineEdge"
//       id={edge.id}
//       key={edge.id}
//       points={[edge.startPoint.x, edge.startPoint.y, edge.endPoint.x, edge.endPoint.y]}
//       stroke={hover || rsoe.hover ? "#737e6e" : "#aca675"}
//       strokeWidth={hover || rsoe.hover ? 10 : 5}
//       hitStrokeWidth={20}
//       onMouseEnter={(e) => {
//         setPointer(e);
//         setHover(true);

//         // Sprawdzamy czy nie ma powiązanej krawędzi
//         const linkedEdge = roofSlopeOutlineLinkedEdges.find(
//           (rsole) => rsole.firstEdgeId === edge.id || rsole.secondEdgeId === edge.id
//         );

//         if (linkedEdge) {
//           // Jeśli istnieje to pobierz obie krawędzie z outlines
//           const edge1 = roofSlopeOutlines.flatMap((rso) => rso.edges).find((e) => e.id === linkedEdge.firstEdgeId);
//           const edge2 = roofSlopeOutlines
//             .flatMap((rso) => rso.edges)
//             .find((e) => e.id === linkedEdge.secondEdgeId);

//           if (edge1) {
//             dispatch(updateRoofSlopeOutlineEdge({ ...edge1, hover: true }));
//           }

//           if (edge2) {
//             dispatch(updateRoofSlopeOutlineEdge({ ...edge2, hover: true }));
//           }
//         }
//       }}
//       onMouseLeave={(e) => {
//         deletepointer(e);
//         setHover(false);

//         // Sprawdzamy czy nie ma powiązanej krawędzi
//         const linkedEdge = roofSlopeOutlineLinkedEdges.find(
//           (rsole) => rsole.firstEdgeId === edge.id || rsole.secondEdgeId === edge.id
//         );

//         if (linkedEdge) {
//           // Jeśli istnieje to pobierz obie krawędzie z outlines
//           const edge1 = roofSlopeOutlines.flatMap((rso) => rso.edges).find((e) => e.id === linkedEdge.firstEdgeId);
//           const edge2 = roofSlopeOutlines
//             .flatMap((rso) => rso.edges)
//             .find((e) => e.id === linkedEdge.secondEdgeId);

//           if (edge1) {
//             dispatch(updateRoofSlopeOutlineEdge({ ...edge1, hover: false }));
//           }

//           if (edge2) {
//             dispatch(updateRoofSlopeOutlineEdge({ ...edge2, hover: false }));
//           }
//         }
//       }}
//       onClick={() => {
//         onClickRoofEdge();
//       }}
//     />
//   );
// };

// export default RoofSlopeOutlineEdge;
