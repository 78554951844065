import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalculationRequest } from "../../models/Models";

export interface IRoofContourNumber {
  index: number;
}

const initialState: IRoofContourNumber = { index: 1 };

// Dane wejściowe do obliczeń -> przydatne gdy zmieniamy kąt na połaciach
export const roofContourNumberSlice = createSlice({
  name: "roofContourNumber",
  initialState,
  reducers: {
    upIndexRoofContourNumberInReducer: (state) => {
      return { ...state, index: state.index + 1 };
    },
    downIndexRoofContourNumberInReducer: (state) => {
      return { ...state, index: state.index - 1 };
    },
    clearRoofContourNumber: (state) => {
      return initialState;
    },
    setSavedRoofContourNumber: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  upIndexRoofContourNumberInReducer,
  downIndexRoofContourNumberInReducer,
  clearRoofContourNumber,
  setSavedRoofContourNumber,
} = roofContourNumberSlice.actions;
export default roofContourNumberSlice.reducer;
