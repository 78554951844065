import React, { FC, useEffect, useState } from "react";
import { Group } from "react-konva";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { v4 as uuidv4 } from "uuid";
import { saveDrawing } from "../../../../redux/roofs/roofDrawingStoreSlice";
import store from "../../../../redux/store";
import {
  getChimneySweepBenchIcon,
  getRoofStepIcon,
  getRoofVentIcon,
  getSnowFenceIcon,
} from "./RoofAccessoryElement";
import {
  addChimneySweepBench200,
  addChimneySweepBench300,
  addChimneySweepBench40,
  addChimneySweepBench80,
  addRoofStep,
  addRoofVent100,
  addRoofVent125,
  addRoofVent150,
  addSnowFence200,
  addSnowFence300,
} from "../../../../redux/roofs/roofAccessoriesSlice";
import { IRoofEdge, IRoofPlane } from "../../../../models/Models";
import { EdgeType } from "../../../../models/Enums";
import { invalidateCalculation } from "../../../../redux/calculationProcess/calcResultSlice";

type Props = {
  typeElement:
    | null
    | "roofStep"
    | "chimneySweepBench40"
    | "chimneySweepBench80"
    | "chimneySweepBench200"
    | "chimneySweepBench300"
    | "snowFence200"
    | "snowFence300"
    | "roofVent100"
    | "roofVent125"
    | "roofVent150";
  eventClickStage: any;
  scale: number;
  zoomScale: number;
  closeFunction: any;
  activeRoofPlane?: IRoofPlane | null;
};

const AddRoofAccessoryFunction: FC<Props> = ({
  typeElement,
  eventClickStage,
  closeFunction,
  activeRoofPlane,
  scale,
  zoomScale,
}) => {
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();

  const [eaveEdge, setEaveEdge] = useState<IRoofEdge | null>(null);

  const mousePosition = useAppSelector((state) => state.mousePosition); // położenie myszki w Stage
  const edges = useAppSelector((state) => state.roofEdges);
  const points = useAppSelector((state) => state.roofPoints);

  //   useEffect(() => {
  //     console.log("activeRoofPlane", activeRoofPlane)
  //   }, [activeRoofPlane]);

  useEffect(() => {
    // Na podstawie połaci trzeba ustalić jak obrócić element aby był równoloegle do okapu
    // Szukamy krawędzi okapu
    if (activeRoofPlane && edges) {
      for (const id of activeRoofPlane.edgeIds as []) {
        const e = edges.find((x) => x.id === id);
        if (e && e.type === EdgeType.Eaves) {
          setEaveEdge(e);
          break;
        }
      }
    }
  }, [activeRoofPlane, edges]);

  useEffect(() => {
    if (!firstRender) {
      // Wstawienie elementu orynnowania w wybranym miejscu czyli dodanie gdzieś do listy w reduxie która póxniej jest wyświetlana
      if (typeElement === "roofStep") {
        dispatch(addRoofStep({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: getRotationAngle() }));
      } else if (typeElement === "chimneySweepBench40") {
        dispatch(
          addChimneySweepBench40({
            id: uuidv4(),
            x: mousePosition.x,
            y: mousePosition.y,
            angle: getRotationAngle(),
          })
        );
      } else if (typeElement === "chimneySweepBench80") {
        dispatch(
          addChimneySweepBench80({
            id: uuidv4(),
            x: mousePosition.x,
            y: mousePosition.y,
            angle: getRotationAngle(),
          })
        );
      } else if (typeElement === "chimneySweepBench200") {
        dispatch(
          addChimneySweepBench200({
            id: uuidv4(),
            x: mousePosition.x,
            y: mousePosition.y,
            angle: getRotationAngle(),
          })
        );
      } else if (typeElement === "chimneySweepBench300") {
        dispatch(
          addChimneySweepBench300({
            id: uuidv4(),
            x: mousePosition.x,
            y: mousePosition.y,
            angle: getRotationAngle(),
          })
        );
      } else if (typeElement === "snowFence200") {
        dispatch(
          addSnowFence200({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: getRotationAngle() })
        );
      } else if (typeElement === "snowFence300") {
        dispatch(
          addSnowFence300({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: getRotationAngle() })
        );
      } else if (typeElement === "roofVent100") {
        dispatch(
          addRoofVent100({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: getRotationAngle() })
        );
      } else if (typeElement === "roofVent125") {
        dispatch(
          addRoofVent125({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: getRotationAngle() })
        );
      } else if (typeElement === "roofVent150") {
        dispatch(
          addRoofVent150({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y, angle: getRotationAngle() })
        );
      }

      dispatch(invalidateCalculation());

      dispatch(saveDrawing(store.getState()));

      closeFunction(); // Zamykanie funcji
    }
  }, [eventClickStage]);

  const calculateAngleLineToAxisX = (A: any, B: any) => {
    const angle = (Math.atan2(B.y - A.y, B.x - A.x) * 180) / Math.PI;
    return angle;
  };

  const getRotationAngle = () => {
    if (activeRoofPlane && eaveEdge && points) {
      const start = points.find((x) => x.id === eaveEdge?.startPointId);
      const end = points.find((x) => x.id === eaveEdge?.endPointId);

      if (start && end) {
        const angle = calculateAngleLineToAxisX(start, end);

        return angle;
      }
    }
    return 0;
  };

  return (
    <Group
      x={mousePosition.x}
      y={mousePosition.y}
      // scale={{ x: 1 / zoomScale, y: 1 / zoomScale }}
    >
      {typeElement === "roofStep" && getRoofStepIcon(scale, zoomScale, getRotationAngle())}
      {typeElement === "chimneySweepBench40" &&
        getChimneySweepBenchIcon(0.4, scale, zoomScale, getRotationAngle())}
      {typeElement === "chimneySweepBench80" &&
        getChimneySweepBenchIcon(0.8, scale, zoomScale, getRotationAngle())}
      {typeElement === "chimneySweepBench200" && getChimneySweepBenchIcon(2, scale, zoomScale, getRotationAngle())}
      {typeElement === "chimneySweepBench300" && getChimneySweepBenchIcon(3, scale, zoomScale, getRotationAngle())}
      {typeElement === "snowFence200" && getSnowFenceIcon(2, scale, zoomScale, getRotationAngle())}
      {typeElement === "snowFence300" && getSnowFenceIcon(3, scale, zoomScale, getRotationAngle())}
      {typeElement === "roofVent100" && getRoofVentIcon(0.1, scale, zoomScale, getRotationAngle())}
      {typeElement === "roofVent125" && getRoofVentIcon(0.125, scale, zoomScale, getRotationAngle())}
      {typeElement === "roofVent150" && getRoofVentIcon(0.15, scale, zoomScale, getRotationAngle())}
    </Group>
  );
};

export default AddRoofAccessoryFunction;
