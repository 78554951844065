import { InboxOutlined } from "@ant-design/icons";
import { Card, message, Image, Row, Col, Button } from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../api/ApiClient";
import DomaLogoComponent from "../../../components/SVGComponents/DomaLogoComponent";
import DSpin from "../../../helpers/DSpin";
import { FALLBACK_IMAGE, getBasicUrlSystemImage } from "../../../helpers/Helpers";

export interface UploadRequestOption<T = any> {
  onProgress?: (event: any) => void;
  onError?: (event: any | ProgressEvent, body?: T) => void;
  onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
  data?: Record<string, unknown>;
  filename?: string;
  file: Exclude<any, File | boolean> | RcFile;
  withCredentials?: boolean;
  action: string;
  headers?: any;
  method: any;
}

type Props = {
  additionalReportPageId: string;
  imageFileName: string;
  setImageFileName: (fileName: string) => void;
};

const AdditionalPageImage: FC<Props> = ({ additionalReportPageId, imageFileName, setImageFileName }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const fileUploadProps = {
    name: "file",
    action: "/ImagesStore/UploadAdditionalPageImage",
    customRequest(options: UploadRequestOption) {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();

      formData.append("file", file); // Dodaj plik

      setLoading(true);
      client
        .post(fileUploadProps.action, formData, {
          headers: {
            ...client.defaults.headers.common, // Zachowaj domyślne nagłówki
            "Content-Type": "multipart/form-data", // Nadpisz tylko Content-Type
          },
        })
        .then((response) => {
          message.success(`${file.name} został dodany.`);
          if (onSuccess) {
            onSuccess(response.data, file);

            var fileName = response.data;
            setImageFileName(fileName);
            setLoading(false);
          }
        })
        .catch((error) => {
          message.error(`${file.name} błąd dodawania.`);
          if (onError) {
            console.log(error);
            onError(error, error.response);
          }
        });
    },
    beforeUpload(file: any) {
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!isLt5M) {
        message.error("Rozmiar pliku przekracza limit 10 MB.");
      }
      return isLt5M; // Zwraca false, jeśli plik jest większy niż 2MB, co zatrzyma przesyłanie
    },
  };

  const getBasicLogoUrlImage = () => {
    return `https://s3.doma.pl/raport/`;
  };

  return (
    <Container>
      <Dragger {...fileUploadProps} showUploadList={false}>
        <DraggerContainer>
          {loading && <DomaSpin />}
          {!loading && imageFileName.length > 0 ? (
            <Image
              key={imageFileName}
              style={{
                objectFit: "contain",
                maxWidth: imageFileName.length === 0 ? "200px" : "600px",
                maxHeight: "600px",
              }} // lub "contain" zamiast "cover"
              preview={false}
              src={`${getBasicLogoUrlImage()}${imageFileName}`} // Dodanie znacznika czasu jako parametru zapytania
              // fallback={FALLBACK_IMAGE}
            />
          ) : (
            <DomaLogoComponent style={{ width: "200px", minHeight: "300px" }} />
          )}
        </DraggerContainer>
        <p className="ant-upload-text">Kliknij lub przenieś plik zdjęcia</p>
        <p className="ant-upload-hint">Wybierz tylko jeden plik. Plik może mieć maksymalnie 10MB.</p>
      </Dragger>
    </Container>
  );
};
export default AdditionalPageImage;

const Container = styled.div`
  height: 100%;
`;

const DraggerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; // Ustaw wysokość na wartość, która odpowiada Twoim potrzebom */
  margin-bottom: 16px;
  padding: 16px;
`;

const DomaSpin = styled(DSpin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
`;
