import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRSOEdge } from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

const initialState: IRSOEdge[] = [];

// addRSOPoints: (state, action: PayloadAction<IRSOPoint[]>) => {
//     let ps = action.payload;
//     // Dodaj tylko jeśli punkty nie istnieją
//     let newPoints = ps.filter((p) => !state.find((o) => o.id === p.id)); // Filtrujemy tylko te punkty które nie istnieją
//     return [...state, ...newPoints];
//   },

export const rsoEdgesSlice = createSlice({
  name: "rsoEdges",
  initialState,
  reducers: {
    addRSOEdge: (state, action: PayloadAction<IRSOEdge>) => {
      // Dodaj tylko wtedy gdy edge nie istnieje po id
      let p = action.payload;

      if (state.find((o) => o.id === p.id)) {
        return state;
      } else {
        return [...state, p];
      }
    },
    addRSOEdges: (state, action: PayloadAction<IRSOEdge[]>) => {
      // Dodaj tylko wtedy gdy krawędź nie istnieje po id
      let ps = action.payload;
      let newEdges = ps.filter((p) => !state.find((o) => o.id === p.id)); // Filtrujemy tylko te edges które nie istnieją
      return [...state, ...newEdges];
    },
    setRSOEdges: (state, action: PayloadAction<IRSOEdge[]>) => {
      let array = action.payload;
      return array;
    },
    updateRSOEdge: (state, action: PayloadAction<IRSOEdge>) => {
      let p = action.payload;
      return state.map((edge) => {
        if (edge.id === p.id) {
          return {
            ...edge,
            hover: p.hover,
            type: p.type,
            active: p.active,
            status: p.status,
          };
        } else {
          return edge;
        }
      });
    },
    removeRSOEdge: (state, action: PayloadAction<IRSOEdge>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRSOEdges: (state) => {
      return initialState;
    },
    setSavedRSOEdges: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRSOEdge,
  addRSOEdges,
  updateRSOEdge,
  setRSOEdges,
  removeRSOEdge,
  clearRSOEdges,
  setSavedRSOEdges,
} = rsoEdgesSlice.actions;
export default rsoEdgesSlice.reducer;
