import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct, IProductUseType, ISalesPackage } from "../../models/Models";

const initialState: Array<ISalesPackage> = [];

export const salesPackagesSlice = createSlice({
  name: "salesPackages",
  initialState,
  reducers: {
    addSalesPackagesInReducer: (state, action: PayloadAction<Array<ISalesPackage>>) => {
      let e = action.payload;
      return e;
    },
  },
});

export const { addSalesPackagesInReducer } = salesPackagesSlice.actions;
export default salesPackagesSlice.reducer;
