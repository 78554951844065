import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IRootState, ITileSystem } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { deleteTileSystem } from "../../../redux/roofSystems/tileSystemsSlice";
import TileSystemDetails from "./components/TileSystemDetails";

type Props = {};

const EditTileSystemPage: FC<Props> = () => {
  const { tileSystemId } = useParams();
  const [tileSystem, setTileSystem] = useState<ITileSystem | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteTileSystem")
  );

  useEffect(() => {
    const s = tileSystems.find((x) => x.id === tileSystemId);
    if (s) {
      setTileSystem(s);
    }
  }, [tileSystem]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteTileSystem"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${tileSystem?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && tileSystem && (
              <Button danger onClick={() => dispatch(deleteTileSystem(tileSystem))}>
                Usuń
              </Button>
            )}
            <Button type="primary" form="tileSystemDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>

            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy system
            </Button>
          </div>
        }
      />

      <Content>
        {tileSystem && (
          <TileSystemDetails
            system={tileSystem}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};

export default EditTileSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
