import { Button, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import client from "../../api/ApiClient";
import { useFirstRender } from "../../hooks/useFirstRender";
import PageHeader from "../../layout/components/PageHeader";
import {
  IAddPrincingSystemElement,
  IPrincingSystem,
  IPrincingSystemElement,
  IRootState,
  IUpdatePrincingSystem,
  IUpdatePrincingSystemElement,
} from "../../models/Models";
import {
  deletePrincingSystemsByIdnReducer,
  updatePrincingSystemInReducer,
} from "../../redux/contractors/princingSystemsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ContractorPrincingSystem from "./components/ContractorPrincingSystem";

type Props = {};

const EditContractorPrincingSystemPage: FC<Props> = () => {
  const { systemId } = useParams();

  const firstRender = useFirstRender();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [princingSystem, setPrincingSystem] = useState<IPrincingSystem | null>(null);

  const [updatePrincingSystem, setUpdatePrincingSystem] = useState<IUpdatePrincingSystem | null>(null);

  const princingSystems = useAppSelector((state: IRootState) => state.princingSystems);

  useEffect(() => {
    const s = princingSystems.find((x) => x.id === systemId);
    if (firstRender && s) {
      setPrincingSystem(s);
    }
  }, [princingSystem]);

  const addPrincingSystemElement = (element: IAddPrincingSystemElement) => {
    if (!princingSystem) return; // jeśli princingSystem jest null, przerwij funkcję

    // Tworzenie nowego elementu na podstawie przekazanych danych.
    // Zakładam, że możesz chcieć generować ID automatycznie, jeśli nie jest ono dostarczone.
    const newElement: IPrincingSystemElement = {
      id: "",
      name: element.name,
      relation: element.relation,
      price: element.price,
      currency: "PLN", // Domyślna waluta. W prawdziwej aplikacji prawdopodobnie będziesz chciał przekazać to jako argument lub użyć innego rozwiązania.
      princingSystemId: princingSystem.id, // Zakładam, że chcesz ustawić ID aktualnego systemu cenowego jako princingSystemId
    };

    // Aktualizujemy stan princingSystem z nową listą elementów
    setPrincingSystem({
      ...princingSystem,
      princingSystemElements: [...princingSystem.princingSystemElements, newElement],
    });
  };

  // const addPrincingSystemElement = (element: IAddPrincingSystemElement) => {
  //   // Sprawdzanie, czy princingSystem istnieje (choć w tym przypadku zawsze powinien być zainicjowany)
  //   if (princingSystem) {
  //     setPrincingSystem((prevState) => ({
  //       ...prevState, // zachowuje istniejące właściwości (name, description)
  //       princingSystemElements: [...prevState.princingSystemElements, element], // dodaje nowy element do tablicy elements
  //     }));
  //   }
  // };

  const updatePrincingSystemElement = (updatedElement: IUpdatePrincingSystemElement) => {
    if (!princingSystem) return; // jeśli princingSystem jest null, przerwij funkcję
    // Mapujemy przez każdy element w princingSystemElements
    const updatedPrincingSystemElements = princingSystem.princingSystemElements.map((element) => {
      // Jeśli ID aktualnie przetwarzanego elementu pasuje do ID updatedElement
      // to zwracamy zaktualizowany obiekt, w przeciwnym razie zwracamy oryginalny element
      if (element.id === updatedElement.id) {
        return {
          ...element,
          name: updatedElement.name,
          relation: updatedElement.relation,
          price: updatedElement.price,
        };
      }
      return element;
    });

    // Aktualizujemy stan princingSystem z zaktualizowaną listą elementów
    setPrincingSystem({
      ...princingSystem,
      princingSystemElements: [...updatedPrincingSystemElements],
    });
  };

  const deletePrincingSystemElement = (elementId: string) => {
    if (!princingSystem) return; // jeśli princingSystem jest null, przerwij funkcję

    // Filtruj listę elementów, aby usunąć ten o podanym ID
    const updatedElements = princingSystem.princingSystemElements.filter((element) => element.id !== elementId);

    // Aktualizuj stan princingSystem z nową listą elementów
    setPrincingSystem({
      ...princingSystem,
      princingSystemElements: updatedElements,
    });
  };

  const getModelToSaveSystem = () => {
    if (updatePrincingSystem) {
      return { ...updatePrincingSystem, princingSystemElements: [...princingSystem?.princingSystemElements!] };
    }

    const model: IUpdatePrincingSystem = {
      princingSystemId: princingSystem?.id!,
      name: princingSystem?.name!,
      description: princingSystem?.description!,
      princingSystemElements: [...princingSystem?.princingSystemElements!],
    };

    return model;
  };

  return (
    <div>
      <PageHeader
        title={"Edytuj system: " + princingSystem?.name}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              danger
              onClick={() => {
                const deleteSystem = async () => {
                  if (princingSystem?.id) {
                    try {
                      const response = await client.delete(
                        "/Contractor/deletePrincingSystem/" + princingSystem?.id
                      );
                      if (response.status === 204) {
                        message.success(`System wyceny ${princingSystem.name} został usunięty.`);
                        navigate(-1);
                        dispatch(deletePrincingSystemsByIdnReducer(princingSystem?.id));
                      }
                    } catch (err) {}
                  }
                };

                deleteSystem();
              }}
            >
              Usuń system
            </Button>
            <Button
              type="primary"
              onClick={() => {
                const updateSystem = async () => {
                  // console.log("SAVE MODEL", getModelToSaveSystem());
                  try {
                    const response = await client.put("/Contractor/updatePrincingSystem", getModelToSaveSystem());
                    if (response.status === 200) {
                      message.success(`System wyceny ${updatePrincingSystem?.name} został zaktualizowany.`);
                      dispatch(updatePrincingSystemInReducer(response.data));
                    }
                  } catch (err) {}
                };

                updateSystem();
              }}
            >
              Zapisz zmiany
            </Button>
          </div>
        }
      />
      {princingSystem && (
        <ContractorPrincingSystem
          princingSystem={princingSystem}
          updatePrincingSystemGeneralData={(obj: any) => {
            const model: IUpdatePrincingSystem = {
              princingSystemId: princingSystem.id,
              name: obj.name,
              description: obj.description,
              princingSystemElements: [],
            };
            setUpdatePrincingSystem(model);
          }}
          addPrincingSystemElement={addPrincingSystemElement}
          updatePrincingSystemElement={updatePrincingSystemElement}
          deletePrincingSystemElement={deletePrincingSystemElement}
        />
      )}
    </div>
  );
};
export default EditContractorPrincingSystemPage;
