import React from "react";
import { Progress } from "antd";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../theme";

interface CustomProgressProps {
  count: number;
  maxElements: number;
  status: "normal" | "exception" | "active" | "success";
}

const CustomProgress: React.FC<CustomProgressProps> = ({ count, maxElements, status }) => {
  // Obliczanie wartości procentowej
  const percent = Math.min(100, (count / maxElements) * 100);

  return <StyledProgress percent={percent} status={status} format={() => `${count}`} />;
};

export default CustomProgress;

const StyledProgress = styled(Progress)<{ status: string }>`
  /* Wysłane - normal */
  &.ant-progress-status-normal .ant-progress-bg {
    background-color: ${(props) => (props.status === "normal" ? PRIMARY_COLOR : "")}; /* Twój kolor sukcesu */
  }

  /* Utworzone - active */
  &.ant-progress-status-active .ant-progress-bg {
    background-color: ${(props) => (props.status === "active" ? PRIMARY_COLOR : "")}; /* Twój kolor aktywny */
  }
`;
