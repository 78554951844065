import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Collapse, List } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { saveDrawing } from "../../../../../../redux/roofs/roofDrawingStoreSlice";
import { removeRSOEdge } from "../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { removeRSOPlane, updateRSOPlane } from "../../../../../../redux/roofSlopeOutline/rsoPlanesSlice";
import store from "../../../../../../redux/store";
import { IRSO, IRSOEdge } from "./Models/RoofSlopeOutlineModels";
import { updateRSOEdge } from "../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { removeRSOHole, updateRSOHole } from "../../../../../../redux/roofSlopeOutline/rsoHolesSlice";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";

type Props = { openEditOutlineModal: (item: IRSO) => void };

const RoofSlopeOutlinesTable: FC<Props> = ({ openEditOutlineModal }) => {
  const dispatch = useAppDispatch();
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes) || [];
  const rsoEdges = useAppSelector((state) => state.rsoEdges) || [];
  const rsoHoles = useAppSelector((state) => state.rsoHoles) || [];
  const scale = useAppSelector((state) => state.drawingScale);

  // Czy połać item ma okreśłony wektor spadku (czy nie jest zerowy)
  const isPlaneHasDeclineVector = (item: IRSO) => {
    return item.declineVectoor.x !== 0 || item.declineVectoor.y !== 0;
  };

  // Pobierz wszystkie krawędzie które należą do tej połaci item i nie należą do żadnej innej połaci
  const getEdgesForPlane = (item: IRSO) => {
    if (item && item.edgeIds) {
      const onlyEdgesOnThisPlane: IRSOEdge[] = [];
      // Pobierz wszystkie krawędzie na tej połaci
      const edges = rsoEdges.filter((e) => item.edgeIds?.includes(e.id));

      for (const edge of edges) {
        // Pobierz wszystkie połacie, które mają tą krawędź
        const planes = rsoPlanes.filter((p) => p.edgeIds?.includes(edge.id));

        if (planes.length === 1) {
          onlyEdgesOnThisPlane.push(edge);
        }
      }
      return onlyEdgesOnThisPlane;
    }
  };

  // Pobierz wszystkie krawędzie otworów które należą do tej połaci item
  const getEdgesForHole = (item: IRSO) => {
    const holes = getHolesForPlane(item);
    let edges: IRSOEdge[] = [];

    for (const hole of holes) {
      const holeEdges = rsoEdges.filter((e) => hole.edgeIds?.includes(e.id));
      edges = [...edges, ...holeEdges];
    }

    return edges;
  };

  // Pobierz wszystkie otwory które należą do tej połaci item
  const getHolesForPlane = (item: IRSO) => {
    // Otwóry powiązane z item
    const holes = rsoHoles.filter((h) => h.rsoPlaneId === item.id);

    return holes;
  };

  return (
    <List
      size="small"
      dataSource={rsoPlanes}
      renderItem={(item) => (
        <StyledListItem
          hover={item.hover}
          done={isPlaneHasDeclineVector(item)}
          onMouseEnter={(e) => {
            dispatch(updateRSOPlane({ ...item, hover: true }));
          }}
          onMouseLeave={(e) => {
            dispatch(updateRSOPlane({ ...item, hover: false }));
          }}
        >
          <ItemContainer>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Checkbox
                checked={item.active}
                onChange={(e) => {
                  dispatch(updateRSOPlane({ ...item, active: !item.active }));

                  // Włącz/Wyłacz powiązane krawędzie
                  const relatedEdges = getEdgesForPlane(item);
                  if (relatedEdges) {
                    for (const edge of relatedEdges) {
                      dispatch(updateRSOEdge({ ...edge, active: !item.active }));
                    }
                  }

                  // Włącz/Wyłacz powiązane krawędzie otworów
                  const relatedHoleEdges = getEdgesForHole(item);
                  if (relatedHoleEdges) {
                    for (const edge of relatedHoleEdges) {
                      dispatch(updateRSOEdge({ ...edge, active: !item.active }));
                    }
                  }

                  // Włącz/Wyłacz powiązane otwory
                  const holes = getHolesForPlane(item);
                  if (holes) {
                    for (const hole of holes) {
                      dispatch(updateRSOHole({ ...hole, active: !item.active }));
                    }
                  }

                  dispatch(invalidateCalculation());

                  dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                }}
              />
              <RowsContainer>
                <TitleRow>Połać {item.calcId}</TitleRow>
                <SubTitleRow>
                  ∠{item.angle}° |{" "}
                  {isPlaneHasDeclineVector(item) ? (
                    <CheckOutlined style={{ color: "#adac5f" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#ff0000" }} />
                  )}
                </SubTitleRow>
              </RowsContainer>
            </div>

            <div style={{ display: "flex", gap: "8px" }}>
              <Button
                size="small"
                onClick={() => {
                  dispatch(removeRSOPlane(item)); // Usunięcie połaci

                  // Usunięcie krawędzi powiązanych z tą połacią z pominięciem wspólnych z innymi połaciami
                  const relatedEdges = getEdgesForPlane(item);
                  if (relatedEdges) {
                    for (const edge of relatedEdges) {
                      dispatch(removeRSOEdge(edge));
                    }
                  }

                  // Usunięcie krawędzi otworów w tej połaci
                  const relatedHoleEdges = getEdgesForHole(item);
                  if (relatedHoleEdges) {
                    for (const edge of relatedHoleEdges) {
                      dispatch(removeRSOEdge(edge));
                    }
                  }

                  // Usunięcie otworów w połaci
                  const holes = getHolesForPlane(item);
                  if (holes) {
                    for (const hole of holes) {
                      dispatch(removeRSOHole(hole));
                    }
                  }

                  dispatch(invalidateCalculation());

                  dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
                }}
                danger
              >
                <DeleteOutlined />
              </Button>
              <Button size="small" onClick={() => openEditOutlineModal(item)}>
                <EditOutlined />
              </Button>
            </div>
          </ItemContainer>
        </StyledListItem>
      )}
    />
  );
};
export default RoofSlopeOutlinesTable;

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StyledListItem = styled(List.Item)<{ hover: boolean; done: boolean }>`
  background-color: ${(props) => (props.hover ? "#c4c4c4" : props.done ? "#ffffff" : "#FF0000cc")};
  cursor: pointer;
`;

const RowsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
`;

const TitleRow = styled.div`
  width: 100%;
  font-size: 14px;
`;

const SubTitleRow = styled.div`
  width: 100%;
  font-size: 12px;
  color: #7a7a7a;
`;
