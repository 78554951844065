import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Collapse, Row } from "antd";
import React, { FC, memo, useEffect } from "react";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { clearGeometryData, updateGeometryDataValue } from "../../../../../../redux/roofs/geometryDataSlice";

type Props = {
  close: () => void;
};

const PanelFlowGeometryData: FC<Props> = memo(({ close }) => {
  const dispatch = useAppDispatch();

  const geometryData = useAppSelector((state) => state.geometryData);

  type RowComponentProps = {
    marginTop?: number;
    value: number;
    onChange: (value: number) => void;
    name: string;
    checked?: boolean;
    checkOnChange?: (value: boolean) => void;
  };

  const RowComponent: FC<RowComponentProps> = memo(({ marginTop, name, value, onChange }) => {
    const [localValue, setLocalValue] = React.useState<number | undefined>(value);

    const handleInputChange = (value: ValueType | null) => {
      setLocalValue(value === null ? undefined : Number(value));
    };

    const handleInputBlur = () => {
      onChange(localValue === undefined ? 0 : localValue);
    };

    return (
      <GutterRow style={{ marginTop: marginTop ? marginTop : "default" }}>
        <GutterCol span={13} style={{ display: "flex", justifyContent: "left", paddingLeft: "16px" }}>
          {name}
        </GutterCol>
        <GutterCol span={8} style={{ display: "flex", justifyContent: "right" }}>
          <DomaInputNumber value={localValue} onChange={handleInputChange} onBlur={handleInputBlur} />
        </GutterCol>
        <GutterCol span={2}>szt.</GutterCol>
      </GutterRow>
    );
  });

  return (
    <Container title="Punkty geometryczne dachu" size="small" bodyStyle={{ padding: "0px" }}>
      <SimpleContent size="small">
        <RowComponent
          name="Początek kalenicy poziomej"
          value={geometryData.startHorizontalRidgeCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "startHorizontalRidge", countValue: value }));
            dispatch(invalidateCalculation());
          }}
        />

        <RowComponent
          name="Początek kalenicy skośnej"
          value={geometryData.startAngledRidgeCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "startAngledRidge", countValue: value }));
            dispatch(invalidateCalculation());
          }}
          marginTop={8}
        />

        <RowComponent
          name="Koniec kalenicy poziomej"
          value={geometryData.endHorizontalRidgeCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "endHorizontalRidge", countValue: value }));
            dispatch(invalidateCalculation());
          }}
          marginTop={8}
        />

        <RowComponent
          name="Koniec kalenicy skośnej"
          value={geometryData.endAngledRidgeCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "endAngledRidge", countValue: value }));
            dispatch(invalidateCalculation());
          }}
          marginTop={8}
        />

        <RowComponent
          name="Początek kalenicy z trójnikiem"
          value={geometryData.initialRidgeThreeCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "initialRidgeThree", countValue: value }));
            dispatch(invalidateCalculation());
          }}
          marginTop={8}
        />

        <RowComponent
          name="Koniec kalenicy z trójnikiem"
          value={geometryData.endRidgeThreeCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "endRidgeThree", countValue: value }));
            dispatch(invalidateCalculation());
          }}
          marginTop={8}
        />

        <RowComponent
          name="Czwórnik (gąsior)"
          value={geometryData.ridgeFourCount}
          onChange={(value) => {
            dispatch(updateGeometryDataValue({ typeGeometryData: "ridgeFour", countValue: value }));
            dispatch(invalidateCalculation());
          }}
          marginTop={8}
        />
      </SimpleContent>

      <Row style={{ display: "flex", justifyContent: "space-between", gap: "8px", padding: "8px" }}>
        <Button
          type="primary"
          danger
          onClick={() => {
            dispatch(clearGeometryData());
            dispatch(invalidateCalculation());
          }}
        >
          Wyczyść dane!
        </Button>
        <Button type="primary" onClick={() => close()}>
          OK
        </Button>
      </Row>
    </Container>
  );
});
export default PanelFlowGeometryData;

const GutterRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GutterCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(Card)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
  margin-top: 66px;
  padding: 0px;
  width: 400px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 999;
`;

const MainCollapse = styled(Collapse)`
  background-color: #f5f5f5;
`;

const ContentPanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 8px !important;
  }
`;

const SimpleContent = styled(Card)`
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
