import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Form, InputNumber, Modal } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Group, Line, Text } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import {
  calculateChangeInputs,
  calculateRoof,
  calculateRoofWithChangeRoofStartZ,
} from "../../../../../api/ApiRoof";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import {
  addPointer,
  getDistTwoPoints,
  getPointsForCenetrRoofPart,
  removePointer,
} from "../../../../../helpers/Helpers";
import {
  IBasicPoint,
  ICalculateChangeInputsRequest,
  ICalculationPlane,
  ICalculationPoint,
  ICalculationRequest,
  ICalculationWithChangeRoofStartZRequest,
  IRoofEdge,
  IRoofPlane,
  IRoofPoint,
} from "../../../../../models/Models";
import { setRoofPlaneCalcIdDrawChimney } from "../../../../../redux/chimneys/drawChimneyProcessSlice";
import { updateConnectPlanesState } from "../../../../../redux/drawing/connectPlanesSlice";
import { IOutlinePoint } from "../../../../../redux/drawing/outlineSlice";
import {
  addPointMoveEdgeToDrawRoofProcess,
  addSelectedEdgeToDrawRoofProcess,
  deleteEdgeFromSelectedToDrawRoofProcess,
  endMoveEdgeDrawRoofProcess,
  IRoofProcessFunctionsStatus,
} from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import {
  ISavedOutline,
  updateSavedOutline,
  updateSavedOutlinePlane,
} from "../../../../../redux/drawing/savedOutlinesSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import { updateRoofPlaneAngleInputDataInReducer } from "../../../../../redux/roofs/inputDataSlice";
import store from "../../../../../redux/store";

export function determineWindingOrder(points: IOutlinePoint[]): "clockwise" | "counterclockwise" {
  let sum = 0;

  for (let i = 0; i < points.length; i++) {
    const current = points[i];
    const next = points[(i + 1) % points.length]; // Zapętlenie do pierwszego punktu na końcu

    // Obliczanie wyznacznika dla pary punktów: (current, next)
    // Wyznacznik to po prostu x1*y2 - x2*y1 dla wektora utworzonego przez punkty (x1, y1) i (x2, y2)
    sum += (next.x - current.x) * (next.y + current.y);
  }

  // Jeśli suma wyznaczników jest dodatnia, figura jest prawoskrętna
  // Jeśli suma wyznaczników jest ujemna, figura jest lewoskrętna
  return sum > 0 ? "clockwise" : "counterclockwise";
}

const getPointsForLineComponent = (pointIds: any, points: IRoofPoint[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x: IRoofPoint) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

const getFullPoints = (pointIds: any, points: IRoofPoint[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x) => x.id === id);
    if (p) {
      arr.push(p);
    }
  }
  return arr;
};

const getEavesInRoofPart = (roofPlane: IRoofPlane, roofEdges: IRoofEdge[]) => {
  const arr = [];
  const allEavesInRoof = roofEdges.filter((x) => x.type === 1);
  for (const e of allEavesInRoof) {
    if (roofPlane?.edgeIds?.includes(e.id)) {
      arr.push(e);
    }
  }
  return arr;
};

type Props = {
  roofPlane: IRoofPlane;
  roofPoints: IRoofPoint[];
  roofEdges: IRoofEdge[];
  inEditAngle?: boolean;
  lastAngle?: number;
  setLastAngle: any;
  inputData: ICalculationRequest;
  dispatch: any;
  setActiveRoofPlane: any;
  blockSetActiveRoofPlane: boolean;
  scale: number;
  scaleZoom: number;
  onClickRoofPlane?: any;
  showArea?: boolean;
  roofInProgress: boolean;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  dHtoMove?: number;
  setdHtoMove?: any;
  showAngle?: boolean;
  activeConnectPlanes?: boolean;
};

const RoofPlane: FC<Props> = ({
  roofPlane,
  roofPoints,
  roofEdges,
  inEditAngle,
  inputData,
  lastAngle,
  setLastAngle,
  dispatch,
  setActiveRoofPlane,
  blockSetActiveRoofPlane,
  scale,
  scaleZoom,
  onClickRoofPlane,
  showArea,
  roofInProgress,
  roofProcessFunctionsStatus,
  setdHtoMove,
  dHtoMove,
  showAngle,
  activeConnectPlanes,
}) => {
  const [hover, setHover] = useState<boolean>(false); // Czy myszka nad połacią

  const [openEditAngleModal, setOpenEditAngleModal] = useState(false);
  const [updatedAngle, setUpdatedAngle] = useState(false);
  const [newAngle, setNewAngle] = useState(0);

  const roofContourNumber = useAppSelector((state) => state.roofContourNumber);
  const outline = useAppSelector((state) => state.outline); // Kontury dachu
  const savedOutlines = useAppSelector((state) => state.savedOutlines);
  const roofPlanes = useAppSelector((state) => state.roofPlanes);

  const connectPlanes = useAppSelector((state) => state.connectPlanes);

  // const [dH, setDH] = useState<number>(0.5);

  const getdHToMove = () => {
    return dHtoMove ? dHtoMove : 0.5;
  };

  useEffect(() => {
    if (updatedAngle === true) {
      dispatch(calculateRoof(inputData));
      setUpdatedAngle(false);
    }
  }, [inputData]);

  useEffect(() => {
    setNewAngle(lastAngle as number);
  }, [lastAngle]);

  // Gdy myszką najedzie się na obiekt
  const onMouseEnter = () => {
    // console.log(`onMouseEnter roofPlane`, roofPlane);
    setActiveRoofPlane(roofPlane);
    setHover(true);
  };

  // Gdy myszką zjedzie się z obiektu
  const onMouseLeave = () => {
    setActiveRoofPlane(null);
    setHover(false);
  };

  const updateAngleClick = () => {
    const eavesInRoofPart = getEavesInRoofPart(roofPlane, roofEdges);

    if (eavesInRoofPart && eavesInRoofPart.length > 0) {
      const eaveInRoofPart = eavesInRoofPart[0];

      const startPointEave = roofPoints.find((x) => x.id === eaveInRoofPart.startPointId); // Punkt startowy okapu w połaci

      const inputToUpdate = inputData.points?.find((o) => o.x === startPointEave?.x && o.y === startPointEave?.y);

      setUpdatedAngle(true);
      dispatch(
        updateRoofPlaneAngleInputDataInReducer({
          x: inputToUpdate?.x as number,
          y: inputToUpdate?.y as number,
          angle: newAngle,
        })
      );
    }

    setOpenEditAngleModal(false);
  };

  const cancelModal = () => {
    setOpenEditAngleModal(false);
  };

  // Gdy kliknięta połąć w trakcie edycji kąta oraz podczas wybierania połaci do połączenia
  const onClick = (e: any) => {
    // console.log("onClick", roofPlane);

    if (activeConnectPlanes) {
      // roofPlane to połać - teraz trzeba znaleźć wszystkie krawędzie eaves dla tej połaci
      const eavesInRoofPart = getEavesInRoofPart(roofPlane, roofEdges);

      // Dla każdego okapu w połaci popbieram pełne punkty start i end
      for (const eave of eavesInRoofPart) {
        // Aktualne outline
        const selectedOutline = outline.find((x) => x.roofNumber === roofContourNumber.index);
        const selectedOutlineEdges = selectedOutline?.edges || [];
        const selectedOutlinePoints: IOutlinePoint[] = selectedOutline?.points || [];

        // Określić czy punkty w selectedOutlinePoints sa narysowane prawoskrętnie czy lewoskrętnie
        // Jeśli prawoskrętnie to znaczy że startPoint jest punktem końcowym okapu
        // Jeśli lewoskrętnie to znaczy że startPoint jest punktem początkowym okapu
        const clockwise = determineWindingOrder(selectedOutlinePoints) === "clockwise";

        let pointToUse = roofPoints.find((x) => x.id === eave.endPointId); // Punkt końcowy okapu w połaci

        if (clockwise) {
          // Prawoskrętnie
          pointToUse = roofPoints.find((x) => x.id === eave.startPointId); // Punkt startowy okapu w połaci
        }

        // Szukamy w selectedOutlinePoints punkt który ma x i y jak w startPoint
        const selectedPoint = selectedOutlinePoints.find((x) => x.x === pointToUse?.x && x.y === pointToUse?.y);

        if (selectedPoint) {
          // teraz szukamy w selectedOutlineEdges krawędzi która ma selectedPoint.id jako pierwsza pozycję
          const selectedEdge = selectedOutlineEdges.find((x) => x.points[0] === selectedPoint.id);

          if (selectedEdge) {
            if (roofProcessFunctionsStatus.selectedEdges.includes(selectedEdge.id)) {
              dispatch(deleteEdgeFromSelectedToDrawRoofProcess(selectedEdge.id));
            } else {
              dispatch(addSelectedEdgeToDrawRoofProcess(selectedEdge.id));
            }
          }
        }
      }

      dispatch(
        updateConnectPlanesState({
          ...connectPlanes,
          selectedPlaneIds: connectPlanes.selectedPlaneIds.includes(roofPlane.id)
            ? connectPlanes.selectedPlaneIds.filter((id) => id !== roofPlane.id)
            : [...connectPlanes.selectedPlaneIds, roofPlane.id],
        })
      );
    }

    if (inEditAngle) {
      setOpenEditAngleModal(true);
    }
    onClickRoofPlane();
    dispatch(setRoofPlaneCalcIdDrawChimney(roofPlane.calcId));
  };

  const getScaleFont = () => {
    return scaleZoom >= 0 ? scaleZoom : 1;
  };

  const moveRoofPlane = (value: number) => {
    // 1. Ustalenie id połaci dla której przesuwamy kalenice i obiektu połaci
    const roofPlaneCalcId = roofPlane.calcId;
    const currentRoofPlane = roofPlane;

    // 2. Pobrać wszystkie pełne punkty dla aktualnego roofPlane
    const fullPoints = getFullPoints(currentRoofPlane.pointIds, roofPoints);

    // 3. Ustalenie indexu dla savedOutline dla aktualnego roofPlane oraz pobirani eobiektu savedOutline
    let roofContourNumberIndex = 0;
    for (const outline of savedOutlines) {
      const equals = arePointSetsEqual(outline.points, fullPoints, 2); // Szukanie po punktach i czy conajmniej 2 punkty zgadzają się
      if (equals) {
        roofContourNumberIndex = outline.index;
        break;
      }
    }

    // console.log("roofContourNumberIndex", roofContourNumberIndex);

    const savedOutline = savedOutlines.find((x) => x.index === roofContourNumberIndex);
    // console.log("Obiekt savedOutline dla połaci:", savedOutline);

    // 4. Pobranie zapisanych plane dla aktualnego roofPlane
    const planes = savedOutline?.planes || [];
    // console.log("Planes dla wybranego savedOutline", planes);

    // 5. Pobranie plane z savedOutline dla aktualnego roofPlane
    // TODO - to co jest to pobiera ostatnią pozycję
    // const planeFromSavedOutlines = planes[roofPlaneCalcId - 1];

    // W currentRoofPlane są punkty. Znaleźc indekst w points dla savedOutline, który ma punkt z currentRoofPlane
    // Ten sam indeks będzie indexem dla planes
    const vectorX = currentRoofPlane.x;
    const vectorY = currentRoofPlane.y;

    let planeIndex = 0;
    for (const p of savedOutline?.planes || []) {
      let vectX = 0;
      if (p.x > 0) vectX = 1;
      if (p.x < 0) vectX = -1;
      if (p.x == -0) vectX = -0;

      let vectY = 0;
      if (p.y > 0) vectY = 1;
      if (p.y < 0) vectY = -1;
      if (p.y == -0) vectY = -0;

      if (vectX === vectorX && vectY === vectorY) {
        break;
      }
      planeIndex = planeIndex + 1;
    }

    const planeFromSavedOutlines = planes[planeIndex];

    // 6. Pobranie ostatniego dH z plane dla obliczanej połaci
    const lastDh = planeFromSavedOutlines?.dH || 0;

    // 7. Aktualizacja dH dla aktualnego roofPlane, tworząc całą nową kolekcję planes
    const newPlanes = planes.map((plane, index) => {
      if (index === planeIndex) {
        // Tworzenie głębokiej kopii obiektu i modyfikacja właściwości 'dH'
        return { ...plane, dH: lastDh + value };
      }
      return plane;
    });

    // 8. Aktualizacja savedOutline z nowymi danymi oraz aktualizacja w reducerze
    const newSavedOutline: ISavedOutline = {
      index: savedOutline?.index || 1,
      points: savedOutline?.points || [],
      planes: newPlanes,
    };
    dispatch(updateSavedOutline(newSavedOutline));

    // Przygotowanie całego savedOutlines z nową newSavedOutline
    const newSavedOutlines = [...savedOutlines].map((outline) => {
      if (outline.index === newSavedOutline.index) {
        return { ...newSavedOutline };
      } else {
        return { ...outline };
      }
    });

    // 9. Przygotowanie modelu do wysłania na backend
    const allPoints = newSavedOutlines.map((x) => x.points).flat() || [];
    const allPlanes = newSavedOutlines.map((x) => x.planes).flat() || [];

    const model: ICalculateChangeInputsRequest = {
      points: allPoints,
      planes: allPlanes,
      roofPlaneCalcId: roofPlaneCalcId,
      deltaH: value,
    };

    // Zapytanie do API o nowe inputy ze zmienionymi z dla wybranych punktów
    dispatch(calculateChangeInputs(model));
  };

  function arePointSetsEqual(
    set1: { x: number; y: number }[],
    set2: { x: number; y: number }[],
    minMatchingPoints: number = 2
  ) {
    const set1Points = new Set(set1.map((p) => `${parseFloat(p.x.toFixed(2))},${parseFloat(p.y.toFixed(2))}`));
    const set2Points = new Set(set2.map((p) => `${parseFloat(p.x.toFixed(2))},${parseFloat(p.y.toFixed(2))}`));

    let matchingPointsCount = 0;
    [...set1Points].forEach((p) => {
      if (set2Points.has(p)) {
        matchingPointsCount++;
      }
    });

    return matchingPointsCount >= minMatchingPoints;
  }

  return (
    <Group>
      <Html>
        <Modal
          title="Zmień kąt nachylenia wybranej połaci"
          open={openEditAngleModal}
          onOk={() => updateAngleClick()}
          onCancel={() => cancelModal()}
        >
          <Form style={{ marginTop: "16px" }}>
            <Form.Item label="Kąt nachylenia połaci">
              <DomaInputNumber value={newAngle} onChange={(value) => setLastAngle(value)} placeholder="Kąt" />
            </Form.Item>
          </Form>
        </Modal>
      </Html>
      <Line
        points={getPointsForLineComponent(roofPlane.pointIds, roofPoints)}
        fill={connectPlanes.selectedPlaneIds.includes(roofPlane.id) ? "#8f8f84B3" : "#cbcbb9B3"}
        // fill={roofInProgress ? "#aaaaaab3" : hover ? "#8f8f84B3" : "#cbcbb9B3"}
        closed={true}
        stroke={roofInProgress ? "#ff0000" : "#000000"}
        dash={roofInProgress ? [5, 5] : []}
        strokeWidth={2 / scaleZoom}
        onMouseOver={(e) => {
          onMouseEnter();
          if (!roofInProgress) {
            onMouseEnter();
            addPointer(e);
          } else {
          }
        }}
        onMouseLeave={(e) => {
          onMouseLeave();
          removePointer(e);
        }}
        onClick={(e) => {
          onClick(e);
        }}
      />

      {inEditAngle && getFullPoints(roofPlane.pointIds, roofPoints).length > 0 && (
        <Text
          fontStyle="bold"
          x={getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.x}
          y={getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.y}
          text={roofPlane.angle.toFixed(0).toString()}
        />
      )}
      {showArea && (
        <Text
          fontStyle="bold"
          fontSize={14 / getScaleFont()}
          x={getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.x}
          y={getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.y}
          text={`${(roofPlane.area / (scale * scale)).toFixed(2)} m²`}
        />
      )}
      {showAngle && (
        <Text
          fontStyle="bold"
          fontSize={14 / getScaleFont()}
          fill="#464646"
          x={getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.x}
          y={getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.y}
          text={`∢${roofPlane.angle.toFixed(1)}°`}
        />
      )}

      {roofProcessFunctionsStatus.inMoveEdge && (
        <Group
          x={(getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.x || 0) - 150 / 2}
          y={(getPointsForCenetrRoofPart(getFullPoints(roofPlane.pointIds, roofPoints))?.y || 0) - 30 / 2}
        >
          <Html>
            <StyledMoveRoofContainer>
              <InputNumber
                style={{ width: "100%" }}
                value={getdHToMove()}
                onChange={(e) => setdHtoMove(e as number)}
              />
              <StyledUpIcon onClick={() => moveRoofPlane(getdHToMove() * scale)} />
              <StyledDownIcon onClick={() => moveRoofPlane(-1 * getdHToMove() * scale)} />
            </StyledMoveRoofContainer>
          </Html>
        </Group>
      )}
    </Group>
  );
};

export default RoofPlane;

const StyledMoveRoofContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 150px;
  height: 30px;
  margin: 0px;
`;

const StyledUpIcon = styled(ArrowUpOutlined)`
  cursor: pointer;

  &:hover {
    color: #1890ff;
    border-radius: 10px;
  }
`;

const StyledDownIcon = styled(ArrowDownOutlined)`
  cursor: pointer;

  &:hover {
    color: #1890ff;
    border-radius: 10px;
  }
`;
