import React, { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { Button, Card, Checkbox, Col, Divider, Form, Input, List, message, Radio, Row, Upload } from "antd";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import PageHeader from "../../layout/components/PageHeader";
import DomaInputNumber from "../../helpers/DomaInputNumber";
import { v4 as uuidv4 } from "uuid";
import { useFirstRender } from "../../hooks/useFirstRender";
import ReportNotePosition from "./components/ReportNotePosition";
import ReportQuestionPosition from "./components/ReportQuestionPosition";
import AdditionalReportPagePosition from "./components/AdditionalReportPagePosition";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useForm } from "antd/es/form/Form";
import { IParentSettings, updateParentSettings } from "../../redux/general/parentSettingsSlice";
import LogoImageComponent from "./components/LogoImageComponent";
import { PRIMARY_COLOR } from "../../theme";
const { Dragger } = Upload;

export interface IReportNote {
  id: string;
  content: string;
  active: boolean;
  order: number;
}

export interface IReportQuestion {
  id: string;
  content: string;
  active: boolean;
  order: number;
}

export interface IAdditionalReportPage {
  id: string;
  title: string;
  imageUrl: string;
  active: boolean;
  order: number;
}

type Props = {};

const CompanyPage: FC<Props> = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const [reportNotes, setReportNotes] = useState<IReportNote[]>([]); // Podstawowe uwagi do ofert
  const [reportQuestions, setReportQuestions] = useState<IReportNote[]>([]); // Pytania do klienta na końcu oferty
  const [additionalReportPages, setAdditionalReportPages] = useState<IAdditionalReportPage[]>([]); // Dodatkowe strony informacyjne na końcu oferty

  const [imageFileName, setImageFileName] = useState<string>("");

  const parentSettings = useAppSelector((state) => state.parentSettings);

  useEffect(() => {
    if (parentSettings) {
      setReportNotes(parentSettings.reportNotes);
      setReportQuestions(parentSettings.reportQuestions);
      setAdditionalReportPages(parentSettings.additionalReportPages);

      setImageFileName(parentSettings.logoUrl);
    }
  }, [parentSettings]);

  const getInitialValues = () => {
    return {
      companyName: parentSettings.name,
      reportType: parentSettings.reportType,
      showProductCountInBasicType: parentSettings.showProductCountInBasicType,
      addCurrencyValueInNotes: parentSettings.addCurrencyValueInNotes,
      prefix: parentSettings.prefix,
      footerText: parentSettings.reportFooterText,
    };
  };

  const onFinish = (values: any) => {
    const data: IParentSettings = {
      id: parentSettings.id,
      logoUrl: imageFileName,
      name: values.companyName,
      reportType: values.reportType,
      showProductCountInBasicType: values.showProductCountInBasicType,
      addCurrencyValueInNotes: values.addCurrencyValueInNotes,
      prefix: values.prefix,
      reportFooterText: values.footerText,
      reportNotes: reportNotes || [],
      reportQuestions: reportQuestions || [],
      additionalReportPages: additionalReportPages || [],
    };

    dispatch(updateParentSettings(data));
  };

  const onFinishFailed = (errorInfo: any) => {};

  // W S3 nie usuwamy zdjecia
  const removeSystemImage = () => {
    setImageFileName("");
  };

  return (
    <Container>
      <Form
        form={form}
        id="parentSettingsForm"
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <PageHeader
          title={"Dane firmy"}
          extra={
            <Button type="primary" form="parentSettingsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
          }
        />

        <Section title={"Dane firmy"}>
          <Form.Item label="Nazwa firmy" name="companyName">
            <Input />
          </Form.Item>
        </Section>

        <Section title={"Konfiguracja ofert"} style={{ marginTop: "16px" }}>
          <Form.Item label="Domyślny typ raportu (automatycznie po wygenerowaniu)" name="reportType">
            <StyledRadioGroup>
              <Radio.Button value="basic">Uproszczony</Radio.Button>
              <Radio.Button value="full">Pełny</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item
            label="Czy pokazywać ilości produktów w uproszczonej wersji raportu?"
            name="showProductCountInBasicType"
          >
            <StyledRadioGroup>
              <Radio.Button value={true}>Tak</Radio.Button>
              <Radio.Button value={false}>Nie</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item label="Czy umieszczać informację w raporcie o kursie EUR?" name="addCurrencyValueInNotes">
            <StyledRadioGroup>
              <Radio.Button value={true}>Tak</Radio.Button>
              <Radio.Button value={false}>Nie</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item label="Prefix (w numerze projektu)" name="prefix">
            <Input maxLength={6} />
          </Form.Item>

          <Form.Item label="Treść stopki w raporcie" name="footerText">
            <Input />
          </Form.Item>
        </Section>

        <Section
          title={"Logo firmy"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              disabled={imageFileName !== undefined && imageFileName.length === 0}
              type="primary"
              danger
              onClick={() => removeSystemImage()}
            >
              Usuń zdjęcie systemu
            </Button>
          }
        >
          <LogoImageComponent
            parentId={parentSettings.id}
            imageFileName={imageFileName}
            setImageFileName={(name) => {
              setImageFileName(name);
            }}
          />
        </Section>

        <Section
          title={"Podstawowe uwagi do ofert"}
          style={{ width: "100%", marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newReportNote: IReportNote = {
                  id: uuidv4(),
                  content: "",
                  active: true,
                  order: reportNotes.length + 1,
                };

                setReportNotes([...reportNotes, newReportNote]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          <List
            dataSource={reportNotes}
            renderItem={(item) => (
              <List.Item style={{ width: "100%", margin: "0px", padding: "0px" }}>
                <ReportNotePosition
                  key={item.id}
                  reportNote={item}
                  updatePosition={(pos) => {
                    const newReportNotes = [...reportNotes];
                    const index = newReportNotes.findIndex((x) => x.id === pos.id);

                    if (index >= 0) {
                      newReportNotes[index] = pos;
                      setReportNotes(newReportNotes);
                    }
                  }}
                  deletePosition={(pos) => {
                    const newReportNotes = [...reportNotes].filter((x) => x.id !== pos.id);
                    setReportNotes(newReportNotes);
                  }}
                />
              </List.Item>
            )}
          />
        </Section>

        <Section
          title={"Pytania do klienta przed wygenerowaniem oferty"}
          style={{ width: "100%", marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newReportQuestion: IReportQuestion = {
                  id: uuidv4(),
                  content: "",
                  active: true,
                  order: reportQuestions.length + 1,
                };

                setReportQuestions([...reportQuestions, newReportQuestion]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          <List
            dataSource={reportQuestions}
            renderItem={(item) => (
              <List.Item style={{ width: "100%", margin: "0px", padding: "0px" }}>
                <ReportQuestionPosition
                  key={item.id}
                  reportQuestion={item}
                  updatePosition={(pos) => {
                    const newReportQuestions = [...reportQuestions];
                    const index = newReportQuestions.findIndex((x) => x.id === pos.id);

                    if (index >= 0) {
                      newReportQuestions[index] = pos;
                      setReportQuestions(newReportQuestions);
                    }
                  }}
                  deletePosition={(pos) => {
                    const newReportQuestions = [...reportQuestions].filter((x) => x.id !== pos.id);
                    setReportQuestions(newReportQuestions);
                  }}
                />
              </List.Item>
            )}
          />
        </Section>

        <Section
          title={"Dodatkowe strony informacyjne na końcu oferty"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newAdditionalReportPage: IAdditionalReportPage = {
                  id: uuidv4(),
                  title: "",
                  imageUrl: "",
                  active: true,
                  order: additionalReportPages.length + 1,
                };

                setAdditionalReportPages([...additionalReportPages, newAdditionalReportPage]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          <List
            dataSource={additionalReportPages}
            renderItem={(item) => (
              <List.Item style={{ width: "100%", padding: "16px 0px 16px 0px", margin: "0px" }}>
                <AdditionalReportPagePosition
                  key={item.id}
                  additionalReportPage={item}
                  updatePosition={(pos) => {
                    const newAdditionalReportPages = [...additionalReportPages];
                    const index = newAdditionalReportPages.findIndex((x) => x.id === pos.id);

                    if (index >= 0) {
                      newAdditionalReportPages[index] = pos;
                      setAdditionalReportPages(newAdditionalReportPages);
                    }
                  }}
                  deletePosition={(pos) => {
                    const newAdditionalReportPages = [...additionalReportPages].filter((x) => x.id !== pos.id);
                    setAdditionalReportPages(newAdditionalReportPages);
                  }}
                />
              </List.Item>
            )}
          />
        </Section>
      </Form>
    </Container>
  );
};
export default CompanyPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
`;

const Section = styled(Card)``;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
