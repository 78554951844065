import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IUser } from "../../models/Models";
import { AppThunk } from "../store";

const initialState: Array<IUser> = [];

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUserInReducer: (state, action: PayloadAction<IUser>) => {
      let user = action.payload;
      return [...state, user];
    },
    addUsersInReducer: (state, action: PayloadAction<Array<IUser>>) => {
      let users = action.payload;
      return users;
    },
    updateUserInReducer: (state, action: PayloadAction<IUser>) => {
      let user = action.payload;

      let index = state.findIndex((o) => o.id === user.id);
      state[index] = { ...state[index], ...user };
    },
    deleteUserInReducer: (state, action: PayloadAction<string>) => {
      const userId = action.payload;
      return state.filter((o) => o.id !== userId);
    },
  },
});

export const { addUserInReducer, addUsersInReducer, updateUserInReducer, deleteUserInReducer } =
  usersSlice.actions;
export default usersSlice.reducer;

// *******************************************************
// API
// *******************************************************

// Add user
export const addUser =
  (data: IUser): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .post("/Users/addUser", data)
      .then((response) => {
        if (response.status == 200) {
          dispatch(addUserInReducer(response.data));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Update user
export const updateUser =
  (data: IUser): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .post("/Users/updateUser", data)
      .then((response) => {
        if (response.status == 204) {
          dispatch(updateUserInReducer(data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete user
export const deleteUser =
  (data: IUser): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("User to delete", data.id);
    client
      .delete(`/Users/${data.id}`)
      .then((response) => {
        console.log(response);
        if (response.status == 204) {
          dispatch(deleteUserInReducer(data.id));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
