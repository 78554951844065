import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilterSystem, IFilterSystemPosition } from "../../models/Models";

const initialState: IFilterSystem = {
  filterForRoofSystem: {
    companyId: null,
    productModelId: null,
    productColorId: null,
  },

  filterForGutterSystem: {
    companyId: null,
    productModelId: null,
    productColorId: null,
  },

  filterForFasteningSystem: {
    companyId: null,
    productModelId: null,
    productColorId: null,
  },

  filterForPreCoveringSystem: {
    companyId: null,
    productModelId: null,
    productColorId: null,
  },
};

// Kontener na dane materiałowe potrzebne do oblczeń
export const filtersForSystemsSlice = createSlice({
  name: "filtersForSystems",
  initialState,
  reducers: {
    updateFilterForRoofSystem: (state, action: PayloadAction<IFilterSystemPosition>) => {
      state.filterForRoofSystem = action.payload;
    },
    updateFilterForGutterSystem: (state, action: PayloadAction<IFilterSystemPosition>) => {
      state.filterForGutterSystem = action.payload;
    },
    updateFilterForFasteningSystem: (state, action: PayloadAction<IFilterSystemPosition>) => {
      state.filterForFasteningSystem = action.payload;
    },
    updateFilterForPreCoveringSystem: (state, action: PayloadAction<IFilterSystemPosition>) => {
      state.filterForPreCoveringSystem = action.payload;
    },
    resetFilterForRoofSystem: (state) => {
      state.filterForRoofSystem = initialState.filterForRoofSystem;
    },
    resetFilterForGutterSystem: (state) => {
      state.filterForGutterSystem = initialState.filterForGutterSystem;
    },
    resetFilterForFasteningSystem: (state) => {
      state.filterForFasteningSystem = initialState.filterForFasteningSystem;
    },
    resetFilterForPreCoveringSystem: (state) => {
      state.filterForPreCoveringSystem = initialState.filterForPreCoveringSystem;
    },
    clearFiltersForSystems: (state) => {
      return initialState;
    },
    setSavedFiltersForSystems: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  clearFiltersForSystems,
  setSavedFiltersForSystems,
  updateFilterForRoofSystem,
  updateFilterForGutterSystem,
  updateFilterForFasteningSystem,
  updateFilterForPreCoveringSystem,
  resetFilterForRoofSystem,
  resetFilterForGutterSystem,
  resetFilterForFasteningSystem,
  resetFilterForPreCoveringSystem,
} = filtersForSystemsSlice.actions;
export default filtersForSystemsSlice.reducer;
