import React, { FC } from "react";
import { Group, Line } from "react-konva";
import { IRSOHole, IRSOPoint } from "./Models/RoofSlopeOutlineModels";

const getPointsForLineComponent = (pointIds: any, points: IRSOPoint[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x: IRSOPoint) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

type Props = {
  rsoHole: IRSOHole;
  rsoPoints: IRSOPoint[];
  scale: number;
  fill?: string;
};

const RoofSlopeHole: FC<Props> = ({ rsoHole, rsoPoints, scale, fill }) => {
  return (
    <Group>
      <Line
        points={getPointsForLineComponent(rsoHole.pointIds, rsoPoints)}
        fill={fill ? fill : "#ffffff"}
        closed={true}
        stroke="black"
        strokeWidth={2 / scale}
      />
    </Group>
  );
};

export default RoofSlopeHole;
