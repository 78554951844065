import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IInformations, INotification } from "../../models/Models";
import { AppDispatch } from "../store";

const initialState: IInformations = {
  loading: false,
  offersCreated: 0,
  offersAccepted: 0,
  offersRejected: 0,
  offersSent: 0,
  averagePriceOffers: 0,
  investmentsCount: 0,
  productsCount: 0,
  reportsCount: 0,
};

export const informationsSlice = createSlice({
  name: "informations",
  initialState,
  reducers: {
    setLoadingGetInformation: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInformationsInReducer: (state, action: PayloadAction<IInformations>) => {
      let e = action.payload;
      return e;
    },
    clearInformationsInReducer: (state, action: PayloadAction<string>) => {
      return initialState;
    },
  },
});

export const { setInformationsInReducer, setLoadingGetInformation, clearInformationsInReducer } =
  informationsSlice.actions;
export default informationsSlice.reducer;

// API
export const getInformationsFromApi = () => async (dispatch: AppDispatch) => {
  dispatch(setLoadingGetInformation(true));
  try {
    const response = await client.get("/Informations/getInformations");
    if (response.status === 200) {
      // console.log("getInformationsFromApi response", response);
      const data = response.data;
      dispatch(setInformationsInReducer({ ...data, loading: false }));
    }
  } catch (error) {
    console.error(error);
    dispatch(setLoadingGetInformation(false));
  }
};
