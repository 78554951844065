import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IAddProduct, IProduct, IUpdateProduct } from "../../models/Models";
import { addStatusApi } from "../other/statusApiSlice";
import { AppDispatch, AppThunk } from "../store";

const initialState: Array<IProduct> = [];

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    addProductsInReducer: (state, action: PayloadAction<IProduct[]>) => {
      let products = action.payload;
      return products;
    },
    addProductInReducer: (state, action: PayloadAction<IProduct>) => {
      let product = action.payload;
      return [...state, product];
    },
    updateProductInReducer: (state, action: PayloadAction<IProduct>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);

      state[index] = { ...state[index], ...update };
    },
    deleteProductInReducer: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      return state.filter((o) => o.id !== productId);
    },
  },
});

export const { addProductsInReducer, addProductInReducer, updateProductInReducer, deleteProductInReducer } =
  productsSlice.actions;
export default productsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add product
export const addProduct = (model: IAddProduct, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    console.log("Add product model", model);
    const response = await client.post("/Products", model);
    if (response.status === 201) {
      dispatch(addProductInReducer(response.data));
      // dispatch(addStatusApi({ key: "addProduct", type: "success", message: "" }));
      callback();
    }
  } catch (error) {
    // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
    console.error(error);
  }
};

// Update product
export const updateProduct = (model: IUpdateProduct, callback: () => void) => async (dispatch: AppDispatch) => {
  console.log(model);
  try {
    const response = await client.put("/Products", model);
    if (response.status === 200) {
      dispatch(updateProductInReducer(response.data));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};

// Delete product -> ONLY ADMIN
export const deleteProduct = (model: IProduct, callback: () => void) => async (dispatch: AppDispatch) => {
  try {
    const response = await client.delete(`/Products/${model.id}`);
    if (response.status == 204) {
      dispatch(deleteProductInReducer(model.id));
      dispatch(addStatusApi({ key: "deleteProduct", type: "success", message: "" }));
      callback();
    }
  } catch (error) {
    console.error(error);
  }
};
