import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IRoofPlane, IRoofPoint, IUser } from "../../models/Models";
import {
  IRSO,
  IRSOLinkedEdges,
} from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

const initialState: IRSOLinkedEdges[] = [];

export const rsoLinkedEdgesSlice = createSlice({
  name: "rsoLinkedEdges",
  initialState,
  reducers: {
    addRSOLinkedEdge: (state, action: PayloadAction<IRSOLinkedEdges>) => {
      let p = action.payload;
      return [...state, p];
    },
    addRSOLinkedEdges: (state, action: PayloadAction<IRSOLinkedEdges[]>) => {
      let ps = action.payload;
      return [...state, ...ps];
    },
    setRSOLinkedEdges: (state, action: PayloadAction<IRSOLinkedEdges[]>) => {
      let ps = action.payload;
      return ps;
    },
    updateRSOLinkedEdge: (state, action: PayloadAction<IRSOLinkedEdges>) => {
      let p = action.payload;
      return state.map((rsole) => {
        if (rsole.id === p.id) {
          return {
            ...rsole,
            type: p.type,
          };
        } else {
          return rsole;
        }
      });
    },
    removeRSOLinkedEdge: (state, action: PayloadAction<IRSOLinkedEdges>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRSOLinkedEdges: (state) => {
      return initialState;
    },
    setSavedRSOLinkedEdges: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRSOLinkedEdge,
  addRSOLinkedEdges,
  updateRSOLinkedEdge,
  setRSOLinkedEdges,
  removeRSOLinkedEdge,
  clearRSOLinkedEdges,
  setSavedRSOLinkedEdges,
} = rsoLinkedEdgesSlice.actions;
export default rsoLinkedEdgesSlice.reducer;
