import { Alert, Button, Card, Form, Modal, Select, Table } from "antd";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";
import styled from "styled-components";
import { roundNumber } from "../../../../../helpers/Calculator";
import {
  getEdgeLength,
  getEdgeNameByType,
  getExistingEdgeTypes,
  getFullArea,
  getFullLEngthEdgeByType,
} from "../../../../../helpers/Helpers";
import { IRoofEdge, IRoofHole, IRoofPlane, IRoofPoint } from "../../../../../models/Models";
import { invalidateCalculation } from "../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateRoofEdgeType } from "../../../../../redux/roofs/roofEdgesSlice";
import { updateRSOEdge } from "../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import RoofEdge from "../others/RoofEdge";
import RoofHole from "../others/RoofHole";
import RoofPlane from "../others/RoofPlane";
import View3DModal from "../others/View3DModal";
import ViewRoofPlaneModal from "../others/ViewRoofPlaneModal";
import { controlGeometryColumns, getEdgeTypesSelect } from "./GeometryStep";
import { IRSO, IRSOEdge, IRSOHole, IRSOPoint } from "./RoofSlopeOutline/Models/RoofSlopeOutlineModels";
import RoofSlopeHole from "./RoofSlopeOutline/RoofSlopeHole";
import RoofSlopeOutlineEdge from "./RoofSlopeOutline/RoofSlopeOutlineEdge";
import RoofSlopeOutlinePosition from "./RoofSlopeOutline/RoofSlopeOutlinePosition";

const SCALE_BY = 1.1;

type Props = {
  step: number;
};

const PreviewStep: FC<Props> = ({ step }) => {
  const dispatch = useAppDispatch();

  const isControl = () => (step === 3 ? true : false);

  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  const scaleState = useAppSelector((state) => state.drawingScale); // Wcześniej było 70 na sztywno
  const [scale, setScale] = useState<number>(scaleState);

  // Widocznośc modalu z podglądem 3D
  const [view3DModal, setView3DModal] = useState(false);

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  const roofPoints = useAppSelector((state) => state.roofPoints);
  const roofPlanes = useAppSelector((state) => state.roofPlanes);
  const roofEdges = useAppSelector((state) => state.roofEdges);
  const roofHoles = useAppSelector((state) => state.roofHoles);

  const rsoPoints = useAppSelector((state) => state.rsoPoints);
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes);
  const rsoEdges = useAppSelector((state) => state.rsoEdges);
  const rsoHoles = useAppSelector((state) => state.rsoHoles);

  const [selectedEdgeType, setSelectedEdgeType] = useState<number>(88); // 88 bo takiego nie ma i wyświetla wszystkie
  const [showEdgesColored, setShowEdgesColored] = useState<boolean>(true);

  // Modal do podglądu danych wybranej połaci ************************************
  const [roofPlaneToView, setRoofPlaneToView] = useState<IRoofPlane | IRSO | null>(null);
  const [viewRoofPlaneModal, setViewRoofPlaneModal] = useState<boolean>(false);

  // Modal do edycji krawędzi ************************************
  const [edgeToUpdate, setEdgeToUpdate] = useState<IRoofEdge | IRSOEdge | null>(null);
  const [editEdgeModal, setEditEdgeModal] = useState<boolean>(false);

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);
  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);

  const inputData = useAppSelector((state) => state.inputData);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    setScale(scaleState);
  }, [scaleState]);

  const isAutomatic = () => {
    return rsoPlanes && rsoPlanes.length > 0 ? false : true;
  };

  const getHolesForWindowsAndChimneysForAutomatic = () => {
    return roofHoles.filter((o) => o.type !== 1);
  };

  const getHolesForWindowsAndChimneysForOutlinePlane = () => {
    return rsoHoles.filter((o) => o.type !== 1);
  };

  const getHolesForBaysForAutomatic = () => {
    return roofHoles.filter((o) => o.type === 1);
  };

  const getHolesForBaysForOutlinePlane = () => {
    return rsoHoles.filter((o) => o.type === 1);
  };

  const getHolesForAutomatic = (holes: IRoofHole[]) => {
    return holes.map((o, i) => {
      return <RoofHole key={o.id} roofHole={o} roofPoints={roofPoints} scale={zoomScale} fill="#ffffff" />;
    });
  };

  const getHolesForOutlinePlane = (holes: IRSOHole[]) => {
    return holes.map((rsoh) => {
      if (rsoh.active) {
        return <RoofSlopeHole key={rsoh.id} rsoHole={rsoh} rsoPoints={rsoPoints} scale={scale} fill="#e8e8e8" />;
      }
    });
  };

  // const getHoles = () => {
  //   if (!isAutomatic()) {
  //     return rsoHoles.map((rsoh) => {
  //       if (rsoh.active) {
  //         return <RoofSlopeHole key={rsoh.id} rsoHole={rsoh} rsoPoints={rsoPoints} scale={scale} />;
  //       }
  //     });
  //   } else {
  //     // Gdy z automatu dach
  //     return roofHoles.map((o, i) => {
  //       return <RoofHole key={o.id} roofHole={o} roofPoints={roofPoints} scale={zoomScale} />;
  //     });
  //   }
  // };

  const getRoofPlanesOutlineSlope = () => {
    if (!isAutomatic()) {
      return rsoPlanes.map((rso) => {
        if (rso.active) {
          return (
            <RoofSlopeOutlinePosition
              key={rso.id}
              rso={rso}
              zoomScale={zoomScale}
              openEditOutlineModal={(o) => {
                setRoofPlaneToView(o);
                setViewRoofPlaneModal(true);
              }}
            />
          );
        }
      });
    } else {
      // Gdy z automatu dach
      return roofPlanes.map((o, i) => {
        return (
          <RoofPlane
            key={o.id}
            roofInProgress={false}
            roofProcessFunctionsStatus={roofProcessFunctionsStatus}
            roofPlane={o}
            roofPoints={roofPoints}
            roofEdges={roofEdges}
            inputData={inputData}
            inEditAngle={false}
            dispatch={dispatch}
            lastAngle={0}
            setLastAngle={(v: any) => null}
            setActiveRoofPlane={(v: any) => null}
            blockSetActiveRoofPlane={false}
            scaleZoom={zoomScale}
            scale={scale}
            showArea={false}
            onClickRoofPlane={() => {
              setRoofPlaneToView(o);
              setViewRoofPlaneModal(true);
            }}
          />
        );
      });
    }
  };

  const getRoofEdgesOutlineSlope = () => {
    if (!isAutomatic()) {
      return getRoofEdgesByTypeIRSOEgde(selectedEdgeType, rsoEdges).map((e) => {
        return (
          <RoofSlopeOutlineEdge
            key={e.id}
            rsoe={e}
            openEdgeModal={(e) => {
              setEdgeToUpdate(e);
              setEditEdgeModal(true);
            }}
            colored={showEdgesColored}
            zoomScale={zoomScale}
          />
        );
      });
    } else {
      // Gdy z automatu dach
      return getRoofEdgesByType(selectedEdgeType, roofEdges).map((o, i) => {
        return (
          <RoofEdge
            key={o.id}
            roofEdge={o}
            roofPoints={roofPoints}
            scale={zoomScale}
            showColored={showEdgesColored}
            clickEdge={(v: IRoofEdge) => {
              setEdgeToUpdate(v);
              setEditEdgeModal(true);
            }}
          />
        );
      });
    }
  };

  // // Funkcja, która narysuje krawędzie otworów
  // const getHolesEdges = () => {
  //   if (!isAutomatic()) {
  //     return rsoHoles.map((o, i) => {
  //       // Pętla po wszystkich krawędziach otworu
  //       return o.edges.map((e) => {
  //         return (
  //           <RoofSlopeOutlineEdge
  //             key={e.id}
  //             rsoe={e}
  //             openEdgeModal={(e) => {
  //               // setEdgeToUpdate(e);
  //               // setEditEdgeModal(true);
  //             }}
  //             colored={showEdgesColored}
  //             zoomScale={zoomScale}
  //           />
  //         );
  //       });
  //     });
  //   } else {
  //     // Gdy z automatu dach
  //     return roofHoles.map((o, i) => {
  //       // Pętla po wszystkich krawędziach otworu
  //       return o.edges.map((e) => {
  //         return (
  //           <RoofEdge
  //             key={e.id}
  //             roofEdge={e}
  //             roofPoints={roofPoints}
  //             scale={zoomScale}
  //             showColored={showEdgesColored}
  //             clickEdge={(v: IRoofEdge) => {
  //               setEdgeToUpdate(v);
  //               setEditEdgeModal(true);
  //             }}
  //           />
  //         );
  //       });
  //     });
  //   }
  // };

  const getRoofEdgesByTypeIRSOEgde = (type: number, rsoEdges: IRSOEdge[]) => {
    const edges = rsoEdges.filter((x) => x.type === type);

    if (edges.length > 0) {
      return edges;
    } else {
      return rsoEdges;
    }
  };

  const getRoofEdgesByType = (type: number, roofEdges: IRoofEdge[]) => {
    const edges = roofEdges.filter((x) => x.type === type);

    if (edges.length > 0) {
      return edges;
    } else {
      return roofEdges;
    }
  };

  const getTypeEdgesForSelect = (elements: Array<IRSOEdge | IRoofEdge>) => {
    const result = [];
    result.push({
      value: 88,
      label: (
        <span>
          <b>Wszystkie krawędzie</b>
        </span>
      ),
    });

    for (const a of getExistingEdgeTypes(elements)) {
      result.push({
        value: a,
        label: (
          <span>
            <b>{getEdgeNameByType(a)}</b>
          </span>
        ),
      });
    }
    return result;
  };

  const getControlDataTable = (
    points: IRoofPoint[] | IRSOPoint[],
    planes: IRoofPlane[] | IRSO[],
    edges: IRoofEdge[] | IRSOEdge[],
    scale: number
  ) => {
    const data = [];

    data.push({
      id: 1,
      name: <span>Suma powierzchni połaci</span>,
      amount: <b>{roundNumber(getFullArea(planes, points, scale))}</b>,
      unit: <span>m2</span>,
    });

    let i = 2;
    for (const edgeType of getExistingEdgeTypes(edges)) {
      data.push({
        id: i,
        name: <span>{getEdgeNameByType(edgeType)}</span>,
        amount: <b>{roundNumber(getFullLEngthEdgeByType(edges, points, scale, edgeType))}</b>,
        unit: <span>m</span>,
      });
      i++;
    }

    return data;
  };

  function isIRSO(object: any): object is IRSO {
    return object != null && "isRealCoordinates" in object;
  }

  const getModalForViewRoofPlane = () => {
    return (
      <ViewRoofPlaneModal
        open={viewRoofPlaneModal}
        roofPlaneObject={roofPlaneToView}
        roofHolesArray={roofHoles}
        roofPointsArray={roofPoints}
        rsoEdges={rsoEdges}
        rsoHoles={rsoHoles}
        rsoPlane={isIRSO(roofPlaneToView) ? roofPlaneToView : null}
        rsoPoints={rsoPoints}
        cancelViewRoofPlane={cancelViewRoofPlane}
        scale={scale}
      />
    );
  };

  const cancelViewRoofPlane = () => {
    setViewRoofPlaneModal(false);
    setRoofPlaneToView(null);
  };

  const updateEdgeClick = () => {
    if (isAutomatic()) {
      dispatch(updateRoofEdgeType(edgeToUpdate as IRoofEdge));
    } else if (!isAutomatic()) {
      dispatch(updateRSOEdge(edgeToUpdate as IRSOEdge));
    }

    dispatch(invalidateCalculation());

    setEditEdgeModal(false);
    setEdgeToUpdate(null);
  };

  const getEdgeLengthIRSOEdge = (edge: IRSOEdge, points: IRSOPoint[], scale: number): number => {
    const point1 = points.find((o) => o.id === edge.startPointId) as IRSOPoint;
    const point2 = points.find((o) => o.id === edge.endPointId) as IRSOPoint;

    const distanceX = point1.x - point2.x;
    const distanceY = point1.y - point2.y;
    const distanceZ = point1.z - point2.z;

    return Number((Math.sqrt(distanceX ** 2 + distanceY ** 2 + distanceZ ** 2) / scale).toFixed(2));
  };

  const getModalForUpdateEdge = () => {
    const start = roofPoints.find((x) => x.id === edgeToUpdate?.startPointId);
    const end = roofPoints.find((x) => x.id === edgeToUpdate?.endPointId);

    return (
      <Modal
        title="Dane wybranej krawędzi"
        centered
        open={editEdgeModal}
        onOk={() => updateEdgeClick()}
        onCancel={() => cancelUpdateEdge()}
        footer={[
          <Button key="back" onClick={cancelUpdateEdge}>
            Anuluj
          </Button>,
          <Button type="primary" key="ok" onClick={updateEdgeClick}>
            Zapisz
          </Button>,
        ]}
      >
        <Form style={{ marginTop: "16px" }}>
          <Form.Item label="Długość krawędzi:">
            {edgeToUpdate && (
              <b>
                {isAutomatic()
                  ? getEdgeLength(edgeToUpdate as IRoofEdge, roofPoints, scale)
                  : getEdgeLengthIRSOEdge(edgeToUpdate as IRSOEdge, rsoPoints, scale)}{" "}
                m
              </b>
            )}
          </Form.Item>
          <Form.Item label="Wybierz typ krawędzi">
            <Select
              value={edgeToUpdate?.type}
              onChange={(value) => {
                if (isAutomatic()) {
                  setEdgeToUpdate({ ...(edgeToUpdate as IRoofEdge), type: value });
                } else {
                  setEdgeToUpdate({ ...(edgeToUpdate as IRSOEdge), type: value });
                }
              }}
              placeholder="Wybierz typ krawędzi"
              options={getEdgeTypesSelect()}
            />
          </Form.Item>
        </Form>

        <Alert message="Możesz zmienić typ wybranej krawędzi, wybierając nowy typ." showIcon />
      </Modal>
    );
  };

  const cancelUpdateEdge = () => {
    setEditEdgeModal(false);
    setEdgeToUpdate(null);
  };

  function zoomStage(event: any) {
    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScaleX = s.scaleX();
      const oldScaleY = s.scaleY();
      const scaleFactor = event.evt.deltaY > 0 ? 1 / SCALE_BY : SCALE_BY;

      const newScaleX = oldScaleX * scaleFactor;
      const newScaleY = oldScaleY * scaleFactor;

      // Obliczenia uwzględniające odwrócenie skali
      // Załóżmy, że scaleX i scaleY mają wartości -1 lub 1
      const pointer = s.getPointerPosition();
      if (pointer) {
        const mousePointTo = {
          x: (pointer.x - s.x()) / oldScaleX,
          y: (pointer.y - s.y()) / oldScaleY,
        };

        s.scale({ x: newScaleX, y: newScaleY });

        const newPos = {
          x: pointer.x - mousePointTo.x * newScaleX,
          y: pointer.y - mousePointTo.y * newScaleY,
        };
        s.position(newPos);
      }

      s.batchDraw();
      setZoomScale(newScaleX); // Uwaga: Możesz potrzebować oddzielnych stanów dla scaleX i scaleY
    }
  }

  return (
    <div>
      <Container
        bodyStyle={{
          padding: "0px",
        }}
      >
        <>
          {view3DModal && (
            <View3DModal
              open={view3DModal}
              onOk={() => setView3DModal(false)}
              onCancel={() => setView3DModal(false)}
              roofPoints={isAutomatic() ? roofPoints : []}
              roofPlanes={isAutomatic() ? roofPlanes : []}
              roofEdges={isAutomatic() ? roofEdges : []}
              rsoPoints={!isAutomatic() ? rsoPoints : []}
              rsoPlanes={!isAutomatic() ? rsoPlanes : []}
              rsoEdges={!isAutomatic() ? rsoEdges : []}
            />
          )}

          {getModalForViewRoofPlane()}
          {getModalForUpdateEdge()}
        </>

        <MenuBar>
          {isControl() && (
            <MenuBarContent>
              {/* Left side */}
              <MenuButtonContainer>
                <Button onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
                  Podsumowanie geometryczne dachu
                </Button>
                <Button onClick={() => setView3DModal(true)}>Podgląd 3D</Button>
              </MenuButtonContainer>
              {/* Right side */}
              <MenuButtonContainer>
                <Button
                  type={showEdgesColored ? "primary" : "default"}
                  onClick={() => setShowEdgesColored((prev) => !prev)}
                >
                  Pokaż krawędzie w kolorach
                </Button>
                <Select
                  style={{ width: "200px" }}
                  value={selectedEdgeType}
                  options={isAutomatic() ? getTypeEdgesForSelect(roofEdges) : getTypeEdgesForSelect(rsoEdges)}
                  onChange={(value) => setSelectedEdgeType(value)}
                />
              </MenuButtonContainer>
            </MenuBarContent>
          )}
        </MenuBar>

        <StageParent ref={stageParent}>
          <Stage
            ref={stage}
            height={900}
            width={dimStageParent.width}
            style={{
              margin: "0",
              background: "#E8E8E8",
              borderRadius: "0 0 8px 8px",
              overflow: "hidden",
            }}
            onMouseMove={(e) => {}}
            onMouseDown={(e) => {
              if (e.evt.button === 1) {
                e.evt.preventDefault();
              }
            }}
            onContextMenu={(e) => {
              e.evt.preventDefault();
            }}
            onWheel={zoomStage}
            draggable
          >
            <Layer>
              {/* Otwóry w dachu */}
              {/* {getHoles()} */}

              {getHolesForAutomatic(roofHoles)}

              {/* Połacie dachu z podziałem na obie wersje rysownaia */}
              {getRoofPlanesOutlineSlope()}

              {/* {getHoles()} */}
              {getHolesForOutlinePlane(rsoHoles)}

              {/* Krawędzie dachu  z podziałem na obie wersje rysowania*/}
              {getRoofEdgesOutlineSlope()}
            </Layer>
          </Stage>
        </StageParent>
      </Container>

      <Table
        title={() => <b>Podsumowanie geometryczne dachu</b>}
        style={{ marginTop: "32px" }}
        columns={controlGeometryColumns}
        dataSource={getControlDataTable(
          isAutomatic() ? roofPoints : rsoPoints,
          isAutomatic() ? roofPlanes : rsoPlanes,
          isAutomatic() ? roofEdges : rsoEdges,
          scale
        )}
        pagination={false}
        rowKey={"id"}
      ></Table>
    </div>
  );
};

export default PreviewStep;

const Container = styled(Card)``;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 8px 8px 0 0;
  background: #bdbdbd;
`;

const MenuBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipText = styled.div`
  text-align: center;
`;

const StageParent = styled.div``;
