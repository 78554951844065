import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IRoofPoint, IUser } from "../../models/Models";

const initialState: Array<IRoofPoint> = [];

export const roofPointsSlice = createSlice({
  name: "roofPoints",
  initialState,
  reducers: {
    addRoofPoint: (state, action: PayloadAction<IRoofPoint>) => {
      let p = action.payload;
      return [...state, p];
    },
    addRoofPoints: (state, action: PayloadAction<Array<IRoofPoint>>) => {
      let ps = action.payload;
      return [...state, ...ps];
    },
    replaceRoofPoints: (state, action: PayloadAction<Array<IRoofPoint>>) => {
      let ps = action.payload;
      return ps;
    },
    removeRoofPoint: (state, action: PayloadAction<IRoofPoint>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRoofPoints: (state) => {
      return initialState;
    },
    setSavedRoofPoints: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRoofPoint,
  addRoofPoints,
  removeRoofPoint,
  replaceRoofPoints,
  clearRoofPoints,
  setSavedRoofPoints,
} = roofPointsSlice.actions;
export default roofPointsSlice.reducer;
