import { Dispatch } from "@reduxjs/toolkit";
import { acceptGutteringInRoof, getProductPrice, isClockwise } from "../helpers/Helpers";
import {
  IAddChimneyRequestModel,
  IAddWindowRequestModel,
  ICalcRoofMetal,
  ICalculateChangeInputsRequest,
  ICalculationPlane,
  ICalculationPoint,
  ICalculationRequest,
  ICalculationWithBayRequest,
  ICalculationWithChangeRoofStartZRequest,
  IMetalSheet,
  IResultItemResponse,
  IRoofEdge,
  IRoofHole,
  IRoofPlane,
  IRoofPlaneDirection,
  IRoofSlope,
} from "../models/Models";
import { convertPercentageToAngle } from "../modules/roofs/components/newRoof/RoofProcessMainModal";
import {
  IRSO,
  IRSOEdge,
  IRSOHole,
  IRSOPoint,
} from "../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";
import { clearAdditionalElements } from "../redux/calculationProcess/additionalElementsSlice";
import { clearCalcMaterialsData } from "../redux/calculationProcess/calcMaterialsDataSlice";
import { clearCalcResult } from "../redux/calculationProcess/calcResultSlice";
import { clearReport, clearReportInReducer } from "../redux/calculationProcess/reportSlice";
import { addWindow, clearWindows } from "../redux/calculationProcess/windowsSlice";
import { resetDrawChimneyProcess } from "../redux/chimneys/drawChimneyProcessSlice";
import { clearOutline } from "../redux/drawing/outlineSlice";
import {
  endMoveEdgeDrawRoofProcess,
  resetRoofProcessFunctionsStatus,
} from "../redux/drawing/roofProcessFunctionsStatusSlice";
import { resetDrawRoofProcess, resetRoofProcessStatus } from "../redux/drawing/roofProcessStatusSlice";
import {
  addSavedOutline,
  resetSavedOutlines,
  updateSavedOutline,
  updateSavedOutlines,
} from "../redux/drawing/savedOutlinesSlice";
import { clearGeneral } from "../redux/general/generalSlice";
import { clearReportsInReducer } from "../redux/general/reportsSlice";
import { clearMetalSheets, setMetalSheets } from "../redux/roofMetal/metalSheetsSlice";
import { clearRoofSlopes, setRoofSlopes } from "../redux/roofMetal/roofSlopesSlice";
import { addCalcDataInReducer, deleteCalcDataInReducer } from "../redux/roofs/calcDataSlice";
import { clearDrawingScale } from "../redux/roofs/drawingScaleSlice";
import { clearGeneralRoofData, updateWithBayOrDormer } from "../redux/roofs/generalRoofDataSlice";
import { clearGutteringElements } from "../redux/roofs/gutteringElementsSlice";
import { clearInputDataInReducer, updateInputDataInReducer } from "../redux/roofs/inputDataSlice";
import { clearDrawingStoreInReducer, saveDrawing } from "../redux/roofs/roofDrawingStoreSlice";
import { clearRoofEdges, replaceRoofEdges } from "../redux/roofs/roofEdgesSlice";
import { clearRoofHoles, replaceRoofHoles } from "../redux/roofs/roofHolesSlice";
import { clearRoofPlanes, replaceRoofPlanes } from "../redux/roofs/roofPlanesSlice";
import { clearRoofPoints, replaceRoofPoints } from "../redux/roofs/roofPointsSlice";
import { clearTemplateImage, deleteTemplateImage } from "../redux/roofs/templateImageSlice";
import store, { AppThunk } from "../redux/store";
import client, { calcClient } from "./ApiClient";
import { v4 as uuidv4 } from "uuid";
import { clearStatusApi } from "../redux/other/statusApiSlice";
import { clearMousePosition } from "../redux/drawing/mousePositionSlice";
import { clearRoofContourNumber } from "../redux/roofs/roofContourNumberSlice";
import { clearRSOLinkedEdges } from "../redux/roofSlopeOutline/rsoLinkedEdgesSlice";
import { clearRSOPoints } from "../redux/roofSlopeOutline/rsoPointsSlice";
import { clearRSOEdges } from "../redux/roofSlopeOutline/rsoEdgesSlice";
import { clearRSOPlanes } from "../redux/roofSlopeOutline/rsoPlanesSlice";
import { clearRSOHoles } from "../redux/roofSlopeOutline/rsoHolesSlice";
import { clearDomaGrid, setDomaGridInReducer } from "../redux/general/domaGridSlice";
import { clearRoofAccessoriesElements } from "../redux/roofs/roofAccessoriesSlice";
import { clearWindowHoles } from "../redux/calculationProcess/windowHolesSlice";
import { clearGeometryData } from "../redux/roofs/geometryDataSlice";
import { clearGroupDiscounts } from "../redux/calculationProcess/groupDiscountsSlice";
import { clearCurrencyValue } from "../redux/general/currencyValueSlice";
import { clearFiltersForSystems } from "../redux/calculationProcess/filtersForSystemsSlice";
import { clearAdditionalProducts } from "../redux/calculationProcess/additionalProductsSlice";
import { clearBayState } from "../redux/drawing/baysSlice";
import { clearConnectPlanesState } from "../redux/drawing/connectPlanesSlice";
import { clearResponsesApi, setGutteringDataResponsesApi } from "../redux/general/responsesApiSlice";
import { clearSavedAdditionalProductsReducer } from "../redux/calculationProcess/savedAdditionalProducts";

// Czyszczenie danych dla funkcji w trakcie rysowania
export const clearRoofProcessFunctionsStatus = (): AppThunk => (dispatch: Dispatch<any>) => {
  dispatch(clearRoofProcessFunctionsStatus());
};

// Czyszczenie stanu rysunku czyli typ, kąt nachylenia itp.
export const clearRoofProcessStatus = (): AppThunk => (dispatch: Dispatch<any>) => {
  dispatch(clearRoofProcessStatus());
};

// Czyszczenie wszytskiego oraz zapsianie stanu nowego czyli czysztego
export const clearData = (): AppThunk => (dispatch: Dispatch<any>) => {
  // console.log("CLEAR DATA");

  dispatch(clearGeneral());
  dispatch(clearStatusApi());
  dispatch(clearCalcResult());
  dispatch(clearMousePosition());
  dispatch(clearOutline());
  dispatch(clearCalcMaterialsData());
  dispatch(clearAdditionalElements());
  dispatch(clearTemplateImage());
  dispatch(clearDrawingScale());
  dispatch(deleteCalcDataInReducer());
  dispatch(clearInputDataInReducer());
  dispatch(clearDrawingStoreInReducer());
  dispatch(clearGeneralRoofData());
  dispatch(clearRoofPoints());
  dispatch(clearRoofPlanes());
  dispatch(clearRoofEdges());
  dispatch(clearRoofHoles());
  dispatch(resetDrawChimneyProcess());
  dispatch(clearWindows());
  dispatch(clearGutteringElements());
  dispatch(clearRoofContourNumber());
  dispatch(clearRoofSlopes());
  dispatch(clearMetalSheets());
  dispatch(clearRSOLinkedEdges());
  dispatch(clearRSOPoints());
  dispatch(clearRSOEdges());
  dispatch(clearRSOPlanes());
  dispatch(clearRSOHoles());
  dispatch(resetRoofProcessStatus());
  dispatch(resetRoofProcessFunctionsStatus());
  dispatch(resetSavedOutlines());
  dispatch(clearReportInReducer());

  dispatch(clearRoofAccessoriesElements());
  dispatch(clearWindowHoles());
  dispatch(clearGeometryData());

  dispatch(clearGroupDiscounts());

  dispatch(clearCurrencyValue());

  dispatch(clearFiltersForSystems());

  dispatch(clearAdditionalProducts());

  dispatch(clearSavedAdditionalProductsReducer());

  // Czyszczenie i ustawienie ponownie na zapisane
  dispatch(clearDomaGrid());
  const state = store.getState();
  if (state.profile.userSettings) {
    dispatch(
      setDomaGridInReducer({
        gridColor: state.profile.userSettings.gridColor,
        gridSize: state.profile.userSettings.gridSize,
        gridVisible: state.profile.userSettings.gridVisible,
        gridSnapping: state.profile.userSettings.gridSnapping,
      })
    );
  }

  dispatch(clearBayState());
  dispatch(clearConnectPlanesState());

  dispatch(clearResponsesApi());

  dispatch(saveDrawing(store.getState()));
};

function removeDuplicates(points: ICalculationPoint[]): ICalculationPoint[] {
  const uniquePoints = new Set<string>();
  const result: ICalculationPoint[] = [];

  for (const point of points) {
    const pointString = JSON.stringify(point);
    if (!uniquePoints.has(pointString)) {
      uniquePoints.add(pointString);
      result.push(point);
    }
  }

  return result;
}

// Obliczanie dachu (podstawowe)
export const calculateRoof =
  (object: ICalculationRequest): AppThunk =>
  (dispatch: Dispatch<any>) => {
    // console.log("CALC REQUEST", object);

    if (object.points.length < 3) return; // Blokuje gdy jest mniej niż 3 punkty

    dispatch(updateInputDataInReducer(object));

    const isAngles = store.getState().roofProcessStatus.typeSlope === "degrees" ? true : false;

    if (!isAngles) {
      let planes = object.planes.map((plane) => {
        return { ...plane, angle: convertPercentageToAngle(plane.angle as number) };
      });

      object = { ...object, planes: planes };
    }

    const currentIndex = store.getState().roofContourNumber.index;
    const savedOutlines = store.getState().savedOutlines;

    const savedOutline = savedOutlines.find((x) => x.index === currentIndex);

    console.log("SAVED OUTLINE", savedOutline);

    if (savedOutline) {
      // Jeśli jest to zaktualizować
      dispatch(updateSavedOutline({ index: currentIndex, planes: object.planes, points: object.points }));
    } else {
      // Jeśli nie ma to dodać do saved
      dispatch(addSavedOutline({ index: currentIndex, planes: object.planes, points: object.points }));
    }

    console.log("SAVED OUTLINES", savedOutlines);

    // Dodać do newPlanes wszystko co jest w savedOutlines poza curentIndex
    const newPlanes = [
      ...savedOutlines
        .filter((x) => x.index !== currentIndex)
        .map((x) => x.planes)
        .flat(),
      ...object.planes,
    ];

    // Pobrać wszystkie points z object.points oraz z savedOutlines
    const newPoints = [...savedOutlines.map((x) => x.points).flat(), ...object.points];

    const uniquePoints = removeDuplicates(newPoints);

    const newObject = { ...object, planes: newPlanes, points: uniquePoints }; // podmiana zmienionych planes i points

    console.log("CALC REQUEST", newObject);

    calcClient
      .post("/Calc/calc", newObject)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;

          // console.log("Dane z API");
          console.log("CALC RESPONSE", data);

          const points = data.points;
          const edges: Array<IRoofEdge> = data.edges;
          const planes: Array<IRoofPlane> = data.planes;
          // const holes: Array<IRoofHole> = data.holes;

          // Dodanie danych niezbędnych do dodania wykuszy
          dispatch(addCalcDataInReducer(data.calcData));

          // Points
          dispatch(replaceRoofPoints(points));

          //Planes
          let planesArray: Array<IRoofPlane> = [];
          for (const plane of planes) {
            const part: IRoofPlane = {
              id: plane.id,
              calcId: plane.calcId,
              pointIds: plane.pointIds,
              edgeIds: plane.edgeIds,
              x: plane.x,
              y: plane.y,
              angle: plane.angle,
              area: plane.area,
              type: plane.type,
              status: plane.status,
              pointCalcIds: plane.pointCalcIds,
              edgeCalcIds: plane.edgeCalcIds,
            };
            planesArray.push(part);
          }
          dispatch(replaceRoofPlanes(planesArray));

          // Edges
          const edgesArray: Array<IRoofEdge> = [];
          for (const e of edges) {
            const edge: IRoofEdge = {
              id: e.id,
              calcId: e.calcId,
              type: e.type,
              status: e.status,
              startPointId: e.startPointId,
              endPointId: e.endPointId,
              startPointCalcId: e.startPointCalcId,
              endPointCalcId: e.endPointCalcId,
            };

            edgesArray.push(edge);
          }
          dispatch(replaceRoofEdges(edgesArray));

          // ORYNNOWANIE ****************************************************************************************************************
          // Odczyt danych do orynnowania w punktach
          var gutteringData = data.gutteringData;
          // Wykonanie aktualizacji punktów
          // dispatch(acceptGutteringInRoof(gutteringData));
          dispatch(setGutteringDataResponsesApi(gutteringData));
          // ORYNNOWANIE ****************************************************************************************************************

          dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
        }
      })
      .catch((error) => {});
  };

// Obliczanie dachu z dodanymi wykuszami lub lukarnami
export const calculateRoofWithBay =
  (object: ICalculationWithBayRequest): AppThunk =>
  (dispatch: Dispatch<any>) => {
    console.log("REQUEST BAY", object);
    calcClient
      .post("/Calc/addBay", object)
      .then((response) => {
        if (response.status == 200) {
          console.log("RESPONSE ADD BAY", response.data);

          const data = response.data;
          const points = data.points;
          const edges: Array<IRoofEdge> = data.edges;
          const planes: Array<IRoofPlane> = data.planes;
          const holes: Array<IRoofHole> = data.holes;

          // Dodanie danych niezbędnych do dodania wykuszy
          dispatch(addCalcDataInReducer(data.calcData));

          // Points
          dispatch(replaceRoofPoints(points));

          //Planes
          let planesArray: Array<IRoofPlane> = [];
          for (const plane of planes) {
            const part: IRoofPlane = {
              id: plane.id,
              calcId: plane.calcId,
              pointIds: plane.pointIds,
              edgeIds: plane.edgeIds,
              x: plane.x,
              y: plane.y,
              angle: plane.angle,
              area: plane.area,
              type: plane.type,
              status: plane.status,
              pointCalcIds: plane.pointCalcIds,
              edgeCalcIds: plane.edgeCalcIds,
            };
            planesArray.push(part);
          }
          dispatch(replaceRoofPlanes(planesArray));

          // Edges
          const edgesArray: Array<IRoofEdge> = [];
          for (const e of edges) {
            const edge: IRoofEdge = {
              id: e.id,
              calcId: e.calcId,
              type: e.type,
              status: e.status,
              startPointId: e.startPointId,
              endPointId: e.endPointId,
              startPointCalcId: e.startPointCalcId,
              endPointCalcId: e.endPointCalcId,
            };

            edgesArray.push(edge);
          }
          dispatch(replaceRoofEdges(edgesArray));

          // console.log("LIST EDGES FROM API", edgesArray);

          // Holes
          let holesArray: Array<IRoofHole> = [];
          for (const h of holes) {
            const hole: IRoofHole = {
              id: h.id,
              calcId: h.calcId,
              pointIds: h.pointIds,
              pointCalcIds: h.pointCalcIds,
              roofPlaneId: h.roofPlaneId,
              type: h.type,
            };
            holesArray.push(hole);
          }
          dispatch(replaceRoofHoles(holesArray));

          dispatch(updateWithBayOrDormer(true));

          // ORYNNOWANIE ****************************************************************************************************************
          // Odczyt danych do orynnowania w punktach
          var gutteringData = data.gutteringData;
          // Wykonanie aktualizacji punktów
          // dispatch(acceptGutteringInRoof(gutteringData));
          dispatch(setGutteringDataResponsesApi(gutteringData));
          // ORYNNOWANIE ****************************************************************************************************************

          dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
        }
      })
      .catch((error) => {});
  };

// Obliczanie dachu z dodanym kominem
export const calculateRoofWithChimney =
  (object: IAddChimneyRequestModel): AppThunk =>
  (dispatch: Dispatch<any>) => {
    calcClient
      .post("/Calc/addChimney", object)
      .then((response) => {
        if (response.status == 200) {
          console.log("RESPONSE ADD CHIMNEY", response.data);

          const data = response.data;
          const points = data.points;
          const edges: Array<IRoofEdge> = data.edges;
          const planes: Array<IRoofPlane> = data.planes;
          const holes: Array<IRoofHole> = data.holes;

          // Dodanie danych niezbędnych do dodania wykuszy
          dispatch(addCalcDataInReducer(data.calcData));

          // Points
          dispatch(replaceRoofPoints(points));

          //Planes
          let planesArray: Array<IRoofPlane> = [];
          for (const plane of planes) {
            const part: IRoofPlane = {
              id: plane.id,
              calcId: plane.calcId,
              pointIds: plane.pointIds,
              edgeIds: plane.edgeIds,
              x: plane.x,
              y: plane.y,
              angle: plane.angle,
              area: plane.area,
              type: plane.type,
              status: plane.status,
              pointCalcIds: plane.pointCalcIds,
              edgeCalcIds: plane.edgeCalcIds,
            };
            planesArray.push(part);
          }
          dispatch(replaceRoofPlanes(planesArray));

          // Edges
          const edgesArray: Array<IRoofEdge> = [];
          for (const e of edges) {
            const edge: IRoofEdge = {
              id: e.id,
              calcId: e.calcId,
              type: e.type,
              status: e.status,
              startPointId: e.startPointId,
              endPointId: e.endPointId,
              startPointCalcId: e.startPointCalcId,
              endPointCalcId: e.endPointCalcId,
            };

            edgesArray.push(edge);
          }
          dispatch(replaceRoofEdges(edgesArray));

          // Holes
          let holesArray: Array<IRoofHole> = [];
          for (const h of holes) {
            const hole: IRoofHole = {
              id: h.id,
              calcId: h.calcId,
              pointIds: h.pointIds,
              pointCalcIds: h.pointCalcIds,
              roofPlaneId: h.roofPlaneId,
              type: h.type,
            };
            holesArray.push(hole);
          }
          dispatch(replaceRoofHoles(holesArray));

          // dispatch(updateWithBayOrDormer(true));

          // ORYNNOWANIE ****************************************************************************************************************
          // Odczyt danych do orynnowania w punktach
          var gutteringData = data.gutteringData;
          // Wykonanie aktualizacji punktów
          // dispatch(acceptGutteringInRoof(gutteringData));
          dispatch(setGutteringDataResponsesApi(gutteringData));
          // ORYNNOWANIE ****************************************************************************************************************

          dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
        }
      })
      .catch((error) => {});
  };

// Obliczanie dachu z dodanym oknem
export const calculateRoofWithWindow =
  (object: IAddWindowRequestModel): AppThunk =>
  (dispatch: Dispatch<any>) => {
    calcClient
      .post("/Calc/addWindow", object)
      .then((response) => {
        if (response.status == 200) {
          console.log("RESPONSE ADD WINDOW", response.data);
          const data = response.data;
          const points = data.points;
          const edges: Array<IRoofEdge> = data.edges;
          const planes: Array<IRoofPlane> = data.planes;
          const holes: Array<IRoofHole> = data.holes;

          // Dodanie danych niezbędnych do dodania wykuszy
          dispatch(addCalcDataInReducer(data.calcData));

          // Points
          dispatch(replaceRoofPoints(points));

          //Planes
          let planesArray: Array<IRoofPlane> = [];
          for (const plane of planes) {
            const part: IRoofPlane = {
              id: plane.id,
              calcId: plane.calcId,
              pointIds: plane.pointIds,
              edgeIds: plane.edgeIds,
              x: plane.x,
              y: plane.y,
              angle: plane.angle,
              area: plane.area,
              type: plane.type,
              status: plane.status,
              pointCalcIds: plane.pointCalcIds,
              edgeCalcIds: plane.edgeCalcIds,
            };
            planesArray.push(part);
          }
          dispatch(replaceRoofPlanes(planesArray));

          // Edges
          const edgesArray: Array<IRoofEdge> = [];
          for (const e of edges) {
            const edge: IRoofEdge = {
              id: e.id,
              calcId: e.calcId,
              type: e.type,
              status: e.status,
              startPointId: e.startPointId,
              endPointId: e.endPointId,
              startPointCalcId: e.startPointCalcId,
              endPointCalcId: e.endPointCalcId,
            };

            edgesArray.push(edge);
          }
          dispatch(replaceRoofEdges(edgesArray));

          // Holes
          let holesArray: Array<IRoofHole> = [];
          for (const h of holes) {
            const hole: IRoofHole = {
              id: h.id,
              calcId: h.calcId,
              pointIds: h.pointIds,
              pointCalcIds: h.pointCalcIds,
              roofPlaneId: h.roofPlaneId,
              type: h.type,
            };
            holesArray.push(hole);
          }
          dispatch(replaceRoofHoles(holesArray));

          // ORYNNOWANIE ****************************************************************************************************************
          // Odczyt danych do orynnowania w punktach
          var gutteringData = data.gutteringData;
          // Wykonanie aktualizacji punktów
          // dispatch(acceptGutteringInRoof(gutteringData));
          dispatch(setGutteringDataResponsesApi(gutteringData));
          // ORYNNOWANIE ****************************************************************************************************************

          dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
        }
      })
      .catch((error) => {});
  };

// Zmiana wysokości startu współrzędnej Z dla wybranej połaci
// !!!!!!! NIE UŻYWANE !!!!!!!
export const calculateRoofWithChangeRoofStartZ =
  (object: ICalculationWithChangeRoofStartZRequest): AppThunk =>
  (dispatch: Dispatch<any>) => {
    dispatch(updateInputDataInReducer(object));

    console.log("ChangeRoofStartZ request object", object);

    calcClient
      .post("/Calc/changeRoofStartZ", object)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;

          console.log("ChangeRoofStartZ z API", data);

          const points = data.points;
          const edges: Array<IRoofEdge> = data.edges;
          const planes: Array<IRoofPlane> = data.planes;

          // Dodanie danych niezbędnych do dodania wykuszy
          dispatch(addCalcDataInReducer(data.calcData));

          // Points
          dispatch(replaceRoofPoints(points));

          //Planes
          let planesArray: Array<IRoofPlane> = [];
          for (const plane of planes) {
            const part: IRoofPlane = {
              id: plane.id,
              calcId: plane.calcId,
              pointIds: plane.pointIds,
              edgeIds: plane.edgeIds,
              x: plane.x,
              y: plane.y,
              angle: plane.angle,
              area: plane.area,
              type: plane.type,
              status: plane.status,
              pointCalcIds: plane.pointCalcIds,
              edgeCalcIds: plane.edgeCalcIds,
            };
            planesArray.push(part);
          }
          dispatch(replaceRoofPlanes(planesArray));

          // Edges
          const edgesArray: Array<IRoofEdge> = [];
          for (const e of edges) {
            const edge: IRoofEdge = {
              id: e.id,
              calcId: e.calcId,
              type: e.type,
              status: e.status,
              startPointId: e.startPointId,
              endPointId: e.endPointId,
              startPointCalcId: e.startPointCalcId,
              endPointCalcId: e.endPointCalcId,
            };

            edgesArray.push(edge);
          }
          dispatch(replaceRoofEdges(edgesArray));

          // ORYNNOWANIE ****************************************************************************************************************
          // Odczyt danych do orynnowania w punktach
          var gutteringData = data.gutteringData;
          // Wykonanie aktualizacji punktów
          // dispatch(acceptGutteringInRoof(gutteringData));
          dispatch(setGutteringDataResponsesApi(gutteringData));
          // ORYNNOWANIE ****************************************************************************************************************

          dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!
        }
      })
      .catch((error) => {});
  };

// Zmiana wysokości startu współrzędnej Z dla wybranej połaci
export const calculateChangeInputs =
  (object: ICalculateChangeInputsRequest): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const state = getState();
    // const objectToSave = { ...object, calcData: state.calcData };
    // dispatch(updateInputDataInReducer(objectToSave));

    console.log("REQUEST CALC Z", object);

    calcClient
      .post("/Calc/changeInputs", object)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;
          console.log("RESPONSE CALC Z", data);

          const newPoints = data.points;

          // Pobierz aktualne SavedOutlines
          const currentOutlines = state.savedOutlines;

          // Aktualizuj SavedOutlines nowymi wartościami Z
          const updatedOutlines = currentOutlines.map((outline) => {
            return {
              ...outline,
              points: outline.points.map((point) => {
                const updatedPoint = newPoints.find((p: any) => p.x === point.x && p.y === point.y);
                return updatedPoint ? { ...point, z: updatedPoint.z } : point;
              }),
            };
          });

          // Dispatch akcji do zaktualizowania stanu
          dispatch(updateSavedOutlines(updatedOutlines));

          // **********************************************************************************************************************************
          // Wszytskie punkty we wszystkich savedOutlines
          const allPoints = updatedOutlines.map((x) => x.points).flat();
          const allPlanes = updatedOutlines.map((x) => x.planes).flat();

          const requestModel: ICalculationRequest = {
            points: allPoints,
            planes: allPlanes,
          };

          dispatch(calculateRoof(requestModel));

          // const requestModel: ICalculationWithChangeRoofStartZRequest = {
          //   calcData: store.getState().calcData,
          //   points: allPoints,
          //   planes: allPlanes,
          //   roofPlaneCalcId: 1, // nie ma znaczenia
          //   deltaH: 0, // nie ma znaczenia
          // };

          // dispatch(calculateRoofWithChangeRoofStartZ(requestModel));
        }
      })
      .catch((error) => {});
  };

export const generateCalcDataForRSO = (
  rsoPoints: IRSOPoint[],
  rsoPlanes: IRSO[],
  rsoEdges: IRSOEdge[],
  rsoHoles: IRSOHole[]
) => {
  const obj: {
    sizes: number[];
    contours: number[][];
    points: number[][];
    edges: number[][];
    planes: number[][];
  } = { sizes: [], contours: [], points: [], edges: [], planes: [] };

  // let newPoints = rsoPoints.map((x, i) => {
  //   return { ...x, calcId: i + 1 };
  // });

  // const newEdges = rsoEdges.map((x, i) => {
  //   return { ...x, calcId: i + 1 };
  // });

  // const newPlanes = rsoPlanes.map((x, i) => {
  //   return { ...x, calcId: i + 1 };
  // });

  // Wcześniej było dodawanie nowego calcId ale teraz jest przeniesione w inne miejsce
  let newPoints = rsoPoints.map((x, i) => {
    return { ...x };
  });

  const newEdges = rsoEdges.map((x, i) => {
    return { ...x };
  });

  const newPlanes = rsoPlanes.map((x, i) => {
    return { ...x };
  });

  const newHoles = rsoHoles.map((x, i) => {
    return { ...x };
  });

  // Punkty
  const points: number[][] = [];
  for (const point of newPoints) {
    points.push([point.calcId, point.type, 2, point.x, point.y, point.z]);
  }
  obj.points = points;

  // Edges
  const edges: number[][] = [];
  for (const edge of newEdges) {
    edges.push([edge.calcId, edge.type, 2, edge.startPointCalcId, edge.endPointCalcId, 0, 0]);
  }
  obj.edges = edges;

  // Planes
  const planes: number[][] = [];
  for (const plane of newPlanes) {
    planes.push([
      plane.calcId,
      1,
      2,
      -1 * plane.declineVectoor.y,
      plane.declineVectoor.x,
      plane.angle,
      plane.area,
    ]);
  }
  obj.planes = planes;

  // Contours
  const contours: number[][] = [];
  for (const plane of newPlanes) {
    if (plane.pointCalcIds) {
      for (const pointCalcId of plane.pointCalcIds) {
        const point = newPoints.find((x) => x.calcId === pointCalcId);

        if (point) {
          contours.push([plane.calcId, 0, point.calcId, 1, 1]);
        }
      }
    }

    // Sprawdzić czy sa jakieś otwory
    const holes = newHoles.filter((x) => x.rsoPlaneId === plane.id);

    if (holes && holes.length > 0) {
      for (const hole of holes) {
        if (hole.pointIds) {
          // Utworzyć kolekcję punktów z pełnymi danymi na podtsawie hole.pointIds
          let fullPoints =
            hole.pointIds.map((pointId) => {
              const point = newPoints.find((x) => x.id === pointId);
              if (point) {
                return point;
              }

              return { id: "", type: 0, x: 0, y: 0, z: 0, calcId: 0 };
            }) || [];

          const isRight = isClockwise(fullPoints);
          fullPoints = isRight ? fullPoints.reverse() : fullPoints;

          for (const point of fullPoints) {
            if (point) {
              contours.push([plane.calcId, hole.calcId, point.calcId, 1, 1]);
            }
          }
        }
      }
    }
  }
  obj.contours = contours;

  // Sizes
  const sizes: number[] = [];
  sizes.push(...[1000, points.length, edges.length, planes.length, contours.length, 0, 0, 0]);
  obj.sizes = sizes;

  return obj;
};

// Obliczanie dachu z dodanym oknem
export const calculateRoofMetalApi =
  (object: ICalcRoofMetal): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    // Dodanie calcData do obiektu zapytania
    const calcDataState = getState().calcData;
    object.calcData = calcDataState;

    console.log("REQUEST METAL", object);

    const state = getState();
    const isAutomatic = state.roofProcessStatus.type === "outline";

    if (!isAutomatic) {
      const calcDataRSO = generateCalcDataForRSO(state.rsoPoints, state.rsoPlanes, state.rsoEdges, state.rsoHoles);
      object.calcData = calcDataRSO;
    }

    calcClient
      .post("/Calc/calcRoofMetal", object)
      .then((response) => {
        if (response.status == 200) {
          const data = response.data;

          // Czyścimy kontenery z wynikami
          dispatch(clearRoofSlopes());
          dispatch(clearMetalSheets());

          console.log("Wynik obliczenia blachy", data);

          // zmianiamy active na true dla każdego metalSheet
          data.metalSheets.forEach((metalSheet: IMetalSheet) => {
            metalSheet.active = true;
          });

          dispatch(setRoofSlopes(data.roofSlopes));
          dispatch(setMetalSheets(data.metalSheets));
        }
      })
      .catch((error) => {
        console.log("BŁĄD obliczenia blachy");
      });
  };

export interface ICalcRoofMetalRequestModel {
  systemType: number; // 1 | 2 | 3
  systemId: string; // Id systemu
  layingDirection: number; // 1 | 2
  layingMethod: number; // 1 | 2 | 3
  overlapModuleCount: number; // Liczba całkowita
  holeAddition: number; // 0.5
  tolerance: number; // 0.15
  userHeight: number; // 3
  selectedRoofSlopeId?: number;
}

// TODO
export const calculateRoofMetal =
  (object: ICalcRoofMetalRequestModel, allPlanes: boolean): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const state = getState();
    const system = state.steelSystems.find((x) => x.id === object.systemId);
    const products = state.products;
    const scale = state.drawingScale;
    const roofSlopes = state.roofSlopes;

    console.log("CALC ROOF METAL", object);

    if (system) {
      // Długość jednego modułu
      let moduleLength = system.moduleLength;

      // typ pokrycia
      const method = object.systemType == 4 ? 3 : object.systemType;
      // algorytm układania blachy
      let algorithm = object.layingMethod; // dla typu 1
      if (method === 2) {
        algorithm = object.layingMethod;
      }

      // szerokość efektywna blachy
      let effectiveWidth = system.width;
      // wysokość efektywna blachy (również wysokość arkusza blachy)
      let effectiveHeight = system.length;
      // minimalna długość blachy
      let minHeight = system.minLength;
      // maksymalna długość blachy
      let maxHeight = system.maxLength;
      // odchylenie pionowe i poziome dla otwórów
      let deviation = object.tolerance || system.dimensionsTolerance;
      // dodatek blachy w otworach
      let extraSheetLengthForHoles = object.holeAddition || system.holeAddition;
      // kierunek układania blachy
      let globalDirection = object.layingDirection;
      // ilość zakładu blachy (należy obliczać jako wielokrotnośc modułu)
      let sheetMetalOverlap = moduleLength * (object.overlapModuleCount || system.modulesPerOverlap);

      // Gdy blacha cięta na wymiar to sa pewne zmiany danych
      if (method === 2) {
        // Wysokość/długość efektywna to wysokość modułu
        effectiveHeight = moduleLength;

        // // Wysokość/długość efektywna to wysokość modułu
        // effectiveHeight = moduleLength - system.upperZipperLength - system.lowerZipperLength;
        // // Maksymalna długość
        // const max = system.maxLength - system.upperZipperLength - system.lowerZipperLength; // Pomniejszamy max długość o zamki
        // const countModules = Math.floor(max / moduleLength); // obliczamy ilość całych modułów
        // const finalMaxHeight = countModules * moduleLength + system.upperZipperLength + system.lowerZipperLength; // obliczamy maksymalną długość balchy na podstawie ilości modułów
        // maxHeight = Number(finalMaxHeight.toFixed(6));

        // sheetMetalOverlap = system.upperZipperLength + system.lowerZipperLength;
      }

      const roofPlaneDirections: IRoofPlaneDirection[] = [];

      // Obliczamy maksymalną długość połaci jakie istneiją (rówież te ukryte jak przy krawędzi szczytowej)
      // Czyli pobierz maksymalne Number dla kolekcji roofSlopes
      const maxRoofSlopeNumber = Math.max(...roofSlopes.map((x) => x.number));
      const minRoofSlopeNumber = Math.min(...roofSlopes.map((x) => x.number)); // NIe można użyć bo jak zacznie się number od wiekszej liczby to system nie zadziała

      // Utworzymy nową tymczasową kolekcję dla roofSlopes, która będzie zawierała również ukryte połacie
      const roofSlopesTemp: IRoofSlope[] = [];
      for (let index = 1; index <= maxRoofSlopeNumber; index++) {
        const rs = roofSlopes.find((x) => x.number === index);
        if (rs) {
          roofSlopesTemp.push(rs);
        } else {
          const roofSlope: IRoofSlope = {
            number: index, // Potrzebne
            direction: 0, // Potrzebne
            id: "", // Nieważne ale musi być
            holes: [], // Nieważne ale musi być
            roofSlopePoints: [], // Nieważne ale musi być
          };
          roofSlopesTemp.push(roofSlope);
        }
      }

      if (!allPlanes) {
        for (const rs of roofSlopesTemp) {
          if (rs.number === object.selectedRoofSlopeId) {
            const roofPlaneDirection: IRoofPlaneDirection = {
              planeNumber: rs.number,
              direction: rs.direction === 3 ? 1 : 3,
            };
            roofPlaneDirections.push(roofPlaneDirection);
          } else {
            const roofPlaneDirection: IRoofPlaneDirection = {
              planeNumber: rs.number,
              direction: rs.direction,
            };
            roofPlaneDirections.push(roofPlaneDirection);
          }
        }
      }

      console.log("sheetMetalOverlap", sheetMetalOverlap);

      const model: ICalcRoofMetal = {
        scale: scale,
        method: method,
        algorithm: algorithm,
        halfSheetTilePattern: 4, // TODO - można to zmienić na 1, 2, 3, 4
        effectiveWidth: effectiveWidth,
        effectiveHeight: effectiveHeight,
        minHeight: minHeight,
        maxHeight: maxHeight,
        sheetMetalOverlap: sheetMetalOverlap,
        positiveVerticalDeviation: deviation,
        negativeVerticalDeviation: deviation,
        positiveHorizontalDeviation: deviation,
        negativeHorizontalDeviation: deviation,
        extraSheetLengthForHoles: extraSheetLengthForHoles,
        globalDirection: globalDirection,
        roofPlaneDirections: roofPlaneDirections,
      };

      // Czyścimy dane z poprzednich obliczeń jeśli były
      dispatch(clearRoofSlopes());
      dispatch(clearMetalSheets());

      dispatch(calculateRoofMetalApi(model));
    }
  };
