import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatusApi } from "../../models/Models";

const initialState: IStatusApi[] = [];

export const statusApiSlice = createSlice({
  name: "statusApi",
  initialState,
  reducers: {
    addStatusApi: (state, action: PayloadAction<IStatusApi>) => {
      let status = action.payload;
      const exist = state.find((x) => x.key === status.key);
      if (!exist) {
        return [...state, status];
      }
    },
    deleteStatusApi: (state, action: PayloadAction<string>) => {
      let statusKey = action.payload;
      return state.filter((x) => x.key !== statusKey);
    },
    clearStatusApi: (state) => {
      return initialState;
    },
  },
});

export const { addStatusApi, deleteStatusApi, clearStatusApi } = statusApiSlice.actions;
export default statusApiSlice.reducer;
