import { Alert, Button, Card, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState, useEffect, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getDataForSelect, getProductCategoryUrlCodeFromPathName } from "../../../helpers/DomaFunctions";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { createFilterOption, getElementsForCalculationUnit, getElementsForSelect } from "../../../helpers/Helpers";
import { IAddProduct, IProduct, IProductCategory, IRootState, ISalesForm } from "../../../models/Models";
import { addProduct, updateProduct, updateProductInReducer } from "../../../redux/general/productsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import AddModelModal from "./AddModelModal";
import AddProductColorModal from "./AddProductColorModal";
import { v4 as uuidv4 } from "uuid";
import SalesFormPosition from "./SalesFormPosition";
import { useFirstRender } from "../../../hooks/useFirstRender";
import client from "../../../api/ApiClient";
import { set } from "js-cookie";
import DSpin from "../../../helpers/DSpin";
import { initial } from "lodash";

const getProductCategoriesForSelect = (elements: Array<IProductCategory>) => {
  const result = [];
  for (const a of elements) {
    result.push({
      value: a.id,
      label: (
        <span>
          <b>{a.name}</b> ({a.description})
        </span>
      ),
    });
  }
  return result;
};

type Props = {
  product: IProduct | null;
  manuallySubmitForm?: boolean;
};

const ProductDetails: FC<Props> = ({ product, manuallySubmitForm }) => {
  const [form] = useForm();
  let { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const firstRender = useFirstRender();

  const [isLoading, setIsLoading] = useState(false);
  const [readyToShow, setReadyToShow] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState({}); // Dodanie stanu dla wartości początkowych formularza

  const companies = useAppSelector((state: IRootState) => state.companies);
  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);

  const products = useAppSelector((state: IRootState) => state.products);

  const [addProductColorModal, setAddProductColorModal] = useState<boolean>(false);
  const [addModelModal, setAddModelModal] = useState<boolean>(false); // Włączanie modala AddModel

  // Dla modala z salesForm
  const [salesForms, setSalesForms] = useState<ISalesForm[]>([]); // formy sprzedaży

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null); // Wybrany Producent. Używane do filtrowania modeli

  const statusApi = useAppSelector((state: IRootState) => state.statusApi.find((x) => x.key === "addProduct"));
  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addProduct"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addProduct"));
    }
  }, [statusApi]);

  // useEffect(() => {
  //   console.log("Zmiana w products");

  //   if (readyToShow === false && products) {
  //     const p = products.find((x) => x.id === product?.id);
  //     if (p && p.salesForms?.length > 0) {
  //       console.log("Produkt", p);
  //       console.log("Zmiana w products - ustawienie salesForms");
  //       setReadyToShow(true);
  //     }
  //   }
  // }, [products]);

  // Ustawianie wartości początkowych formularza
  useEffect(() => {
    if (products && product && !readyToShow) {
      const p = products.find((x) => x.id === product.id);
      if (p && p.salesForms?.length > 0) {
        const newInitialValues = {
          name: p?.name || "",
          externalId: p.externalId || "",
          category:
            p.productCategoryId || getCategoryByUrlCode(getProductCategoryUrlCodeFromPathName(pathname))?.id,
          company: p.companyId,
          color: p.productColorId || productColors.find((x) => x.name === "Brak")?.id,
          productModelId: p.productModelId,
          discount: p.productDiscount || 0,
          quantity: p.stockQuantity || 9999,
          width: p.width || 0,
          length: p.length || 0,
          height: p.height || 0,
          weight: p.weight || 0,
          diameter: p.diameter || 0,
          capacity: p.capacity || 0,
          coating: p.coating || "",
          ean: p.ean || "",
          effectiveWidth: p.effectiveWidth || 0,
          lowerZipperLength: p.lowerZipperLength || 0,
          upperZipperLength: p.upperZipperLength || 0,
          material: p.material || "",
          maxLength: p.maxLength || 0,
          minLength: p.minLength || 0,
          moduleCount: p.moduleCount || 0,
          moduleLength: p.moduleLength || 0,
          producerId: p.producerId || "",
          shelfLife: p.shelfLife || "",
          storageTemperature: p.storageTemperature || 0,
          warranty: p.warranty || "",
          use: p.use || 0,
          calculationUnit: p.calculationUnit || "szt.",
        };
        setFormInitialValues(newInitialValues);
        setReadyToShow(true);
      }
    }
  }, [products]);

  // Pobieramy pełny produkt z API
  useEffect(() => {
    // Ładowanie podstawowych danych nawet bez dostępu do pełnego produktu
    if (product) {
      loadData(product);

      if (product.salesForms?.length > 0) {
        setReadyToShow(true);
      }
    } else {
      loadData();
      setReadyToShow(true);
    }

    // Jeśli pierwszy render to pobieranie pełnego produktu
    if (product && firstRender && product.salesForms === undefined) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await client.get("/Users/fullProduct/" + product.id);
          if (response.status === 200) {
            console.log("Full product", response.data);
            dispatch(updateProductInReducer(response.data));

            // Ładowanie podstawowych danych
            loadData(response.data);

            setIsLoading(false);
          }
        } catch (err) {}
      };

      fetchData();
    }
  }, [product]);

  const loadData = (product?: IProduct) => {
    if (product?.salesForms) {
      setSalesForms(product.salesForms);
    } else {
      // Domyślne formy sprzedaży dla nowego produktu
      var salesFormsList = [];
      salesFormsList.push(getNewSalesForm("Sztuka", true, 1)); // Aktywne
      salesFormsList.push(getNewSalesForm("Opakowanie", false, 0)); // Wyłączone - tylko do podglądu ale też ręcznej aktywacji
      salesFormsList.push(getNewSalesForm("Paleta", false, 0)); // Wyłączone - tylko do podglądu ale też ręcznej aktywacji

      setSalesForms(salesFormsList);
    }

    if (product && product.companyId) {
      setSelectedCompany(product.companyId);
    }
  };

  const getNewSalesForm = (name: string, isActive: boolean, amount: number) => {
    var salesForm: ISalesForm = {
      id: uuidv4(),
      isActive: isActive,
      amount: amount,
      name: name,
      price: 0,
      currency: "PLN",
      packageCapacity: 0,
      packageDiameter: 0,
      packageHeight: 0,
      packageLength: 0,
      packageWeight: 0,
      packageWidth: 0,
      waitingTime: 0,
      productUnavailable: false,
      madeToOrderProduct: false,
      productId: product?.id as string,
    };

    return salesForm;
  };

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return product === null ? true : false;
  };

  const getCategoryByUrlCode = (productCategoryUrlCode: string) => {
    return productCategories.find((x) => x.urlCode === productCategoryUrlCode);
  };

  // const initialValues = {
  //   name: product?.name || "",
  //   externalId: product?.externalId || "",
  //   category:
  //     product?.productCategoryId || getCategoryByUrlCode(getProductCategoryUrlCodeFromPathName(pathname))?.id,
  //   company: product?.companyId,
  //   color: product?.productColorId || productColors.find((x) => x.name === "Brak")?.id,
  //   productModelId: product?.productModelId,
  //   discount: product?.productDiscount || 0,
  //   quantity: product?.stockQuantity || 9999,
  //   width: product?.width || 0,
  //   length: product?.length || 0,
  //   height: product?.height || 0,
  //   weight: product?.weight || 0,
  //   diameter: product?.diameter || 0,
  //   capacity: product?.capacity || 0,
  //   coating: product?.coating || "",
  //   ean: product?.ean || "",
  //   effectiveWidth: product?.effectiveWidth || 0,
  //   lowerZipperLength: product?.lowerZipperLength || 0,
  //   upperZipperLength: product?.upperZipperLength || 0,
  //   material: product?.material || "",
  //   maxLength: product?.maxLength || 0,
  //   minLength: product?.minLength || 0,
  //   moduleCount: product?.moduleCount || 0,
  //   moduleLength: product?.moduleLength || 0,
  //   producerId: product?.producerId || "",
  //   shelfLife: product?.shelfLife || "",
  //   storageTemperature: product?.storageTemperature || 0,
  //   warranty: product?.warranty || "",
  //   use: product?.use || 0,
  //   calculationUnit: product?.calculationUnit || "szt.",
  // };

  const manuallySubmit = async () => {
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const onFinish = (values: any, newVersion: boolean) => {
    const callback = () => navigate(-1); // Cofnięcie o stronę po pozytywnym zakończeniu działania

    let p: IAddProduct = {
      name: values.name,
      companyId: values.company,
      externalId: values.externalId,
      productCategoryId: values.category,
      productColorId: values.color,
      productDiscount: values.discount,
      stockQuantity: values.quantity,
      width: values.width,
      length: values.length,
      weight: values.weight,
      height: values.height,
      diameter: values.diameter,
      capacity: values.capacity,
      productModelId: values.productModelId,
      salesForms: salesForms,
      active: true,
      coating: values.coating,
      ean: values.ean,
      effectiveWidth: values.effectiveWidth,
      lowerZipperLength: values.lowerZipperLength,
      upperZipperLength: values.upperZipperLength,
      material: values.material,
      maxLength: values.maxLength,
      minLength: values.minLength,
      moduleCount: values.moduleCount,
      moduleLength: values.moduleLength,
      producerId: values.producerId,
      shelfLife: values.shelfLife,
      storageTemperature: values.storageTemperature,
      warranty: values.warranty,
      use: values.use,
      calculationUnit: values.calculationUnit,
    };

    if (isNew() || newVersion) {
      dispatch(addProduct(p, callback));
    } else {
      dispatch(updateProduct({ ...p, id: product?.id as string }, callback));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  function timeSince(date: string): string {
    let currentDate = new Date();
    let pastDate = new Date(date);
    let timeDiff = currentDate.getTime() - pastDate.getTime();
    let hoursDiff = Math.floor(timeDiff / 1000 / 60 / 60);
    let daysDiff = Math.floor(hoursDiff / 24);
    let remainingHours = hoursDiff % 24;
    let minutesDiff = Math.floor(timeDiff / 1000 / 60);
    if (minutesDiff < 60) {
      return `${minutesDiff} minut/y temu`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff} godziny i ${minutesDiff % 60} minuty temu`;
    } else {
      return `${daysDiff} dni ${remainingHours} godziny i ${minutesDiff % 60} minuty temu`;
    }
  }

  // Funkcja do obsługi zmiany wartości w polu select z Producentem
  const handleCompanySelectChange = (value: any) => {
    setSelectedCompany(value);
  };

  // Pobiera modele wszystkie lub tylko dla wybranego producenta (jeśli jest wybrany)
  const getModels = () => {
    return selectedCompany ? productModels.filter((x) => x.companyId === selectedCompany) : productModels;
  };

  const sortedSalesForms = (salesForms: ISalesForm[]) => {
    // Filtruj aktywne formularze sprzedaży
    const activeSalesForms = salesForms.filter((x) => x.isActive);

    // Filtruj nieaktywne formularze sprzedaży
    const inactiveSalesForms = salesForms.filter((x) => !x.isActive);

    // Sortuj pozycje sprzedaży od najmniejsześ ilośc jednostek podstawowych
    const sortedSalesForms = [...activeSalesForms, ...inactiveSalesForms].sort(
      (a: any, b: any) => a.amount - b.amount
    );

    return sortedSalesForms;
  };

  return (
    <Container>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <DSpin />
        </div>
      )}

      {readyToShow && (
        <Form
          id="productDetailsForm"
          style={{ width: "100%" }}
          form={form}
          initialValues={formInitialValues}
          onFinish={(values) => onFinish(values, false)}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Section title={"Szczegóły produktu"}>
            <Form.Item label="Kod producenta" name="producerId" labelCol={{ span: 24 }}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Numer produktu w zewnętrznym systemie (dla połączenia przez API z bazą produktów)"
              name="externalId"
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>

            <Form.Item label="EAN" name="ean" labelCol={{ span: 24 }}>
              <Input />
            </Form.Item>

            <Form.Item
              label="Kategoria produktu"
              name="category"
              rules={[{ required: true, message: "Wybierz kategorię produktu!" }]}
              labelCol={{ span: 24 }}
            >
              <Select options={getProductCategoriesForSelect(productCategories)} />
            </Form.Item>

            <Form.Item
              label="Nazwa produktu"
              name="name"
              rules={[{ required: true, message: "Podaj nazwę produktu!" }]}
              labelCol={{ span: 24 }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Producent"
              name="company"
              rules={[{ required: true, message: "Wybierz producenta!" }]}
              labelCol={{ span: 24 }}
            >
              <Select
                onChange={handleCompanySelectChange}
                options={getDataForSelect(companies)}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            <Form.Item
              label={
                <div>
                  Model produktu
                  <Button size="small" type="link" onClick={() => setAddModelModal(true)}>
                    <b>Zarządzaj modelami</b>
                  </Button>
                </div>
              }
              name="productModelId"
              rules={[{ required: true, message: "Wybierz model produktu!" }]}
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Wybierz model produktu!"
                options={getElementsForSelect(getModels())}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            {addModelModal && (
              <AddModelModal
                open={addModelModal}
                onOk={() => setAddModelModal(false)}
                onCancel={() => setAddModelModal(false)}
                dispatch={dispatch}
                defaultCompanyId={form.getFieldValue("company")}
              />
            )}

            <Form.Item
              label={
                <div>
                  Kolor produktu
                  <Button size="small" type="link" onClick={() => setAddProductColorModal(true)}>
                    <b>Zarządzaj kolorami</b>
                  </Button>
                </div>
              }
              name="color"
              labelCol={{ span: 24 }}
            >
              <Select
                placeholder="Wybierz kolor produktu!"
                options={getElementsForSelect(productColors)}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>

            {addProductColorModal && (
              <AddProductColorModal
                open={addProductColorModal}
                onOk={() => setAddProductColorModal(false)}
                onCancel={() => setAddProductColorModal(false)}
                dispatch={dispatch}
              />
            )}

            <Form.Item
              label={
                <span>
                  Stan magazynowy
                  <b style={{ color: "red" }}>
                    &nbsp;[Aktualizacja:{" "}
                    {new Date(product?.stockQuantityUpdated as string).toLocaleDateString("pl-PL")} (
                    {timeSince(product?.stockQuantityUpdated as string)})]
                  </b>
                </span>
              }
              name="quantity"
              labelCol={{ span: 24 }}
            >
              <DomaInputNumber />
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Rabat [%]
                  <b style={{ color: "red" }}>
                    &nbsp;[Aktualizacja:{" "}
                    {new Date(product?.productDiscountUpdated as string).toLocaleDateString("pl-PL")} (
                    {timeSince(product?.productDiscountUpdated as string)})]
                  </b>
                </span>
              }
              name="discount"
              labelCol={{ span: 24 }}
            >
              <DomaInputNumber />
            </Form.Item>
          </Section>

          <Section
            title={"Formy sprzedaży (sztuki, palety, opakowania itp.)"}
            style={{ marginTop: "16px" }}
            extra={
              <Button
                onClick={() => {
                  const newSalesForm: ISalesForm = {
                    id: uuidv4(),
                    isActive: true,
                    amount: 0,
                    name: "",
                    price: 0,
                    currency: "PLN",
                    madeToOrderProduct: false,
                    packageCapacity: 0,
                    packageDiameter: 0,
                    packageHeight: 0,
                    packageLength: 0,
                    packageWeight: 0,
                    packageWidth: 0,
                    productUnavailable: false,
                    waitingTime: 0,
                    productId: product?.id as string,
                  };
                  setSalesForms([newSalesForm, ...salesForms]);
                }}
              >
                Dodaj formę
              </Button>
            }
          >
            {sortedSalesForms(salesForms).map((salesForm) => {
              return (
                <SalesFormPosition
                  key={salesForm.id}
                  salesForm={salesForm}
                  updateSalesForm={(salesForm) => {
                    const newSalesForms = [...salesForms];
                    const index = salesForms.findIndex((x) => x.id === salesForm.id);

                    if (index >= 0) {
                      newSalesForms[index] = salesForm;
                      setSalesForms(newSalesForms);
                    }
                  }}
                  deleteSalesForm={(salesForm) => {
                    const newSalesForms = [...salesForms].filter((x) => x.id !== salesForm.id);
                    setSalesForms(newSalesForms);
                  }}
                />
              );
            })}
          </Section>

          <Section title={"Użycie produktu"} style={{ marginTop: "16px" }}>
            <Alert
              showIcon
              message="Wartości użycia produktu są ściśle związane z tym co jest wprowadzone w formach sprzedaży. Upewnij się, że wartości pozwolą uzyskać poprawny wynik w obliczeniach!"
              style={{ marginBottom: "16px" }}
            />
            <Form.Item
              label="Użycie (wartość liczbowa, która będzie uwzględniana w obliczeniach produktu)"
              name="use"
              labelCol={{ span: 24 }}
            >
              <DomaInputNumber min={0} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Jednostka obliczeniowa [mb, m2, szt., kg] (Wypełniane tylko informacyjnie - nie ma konsekwencji w obliczeniach)"
              name="calculationUnit"
              labelCol={{ span: 24 }}
            >
              <Select options={getElementsForCalculationUnit()} />
            </Form.Item>
          </Section>

          <Section title={"Dane fizyczne produktu oraz dodatkowe informacje"} style={{ marginTop: "16px" }}>
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Szerokość [m]" name="width" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label="Szerokość efektywna [m]" name="effectiveWidth" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Wysokość [m]" name="height" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Długość [m]" name="length" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Długość minimalna [m]" name="minLength" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Długość maksymalna [m]" name="maxLength" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item label="Długość modułu [m]" name="moduleLength" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Ilość modułów [szt.]" name="moduleCount" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Długość zamka górnego [m]" name="upperZipperLength" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Długość zamka dolnego [m]" name="lowerZipperLength" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  label="Temperatura przechowania [dni]"
                  name="storageTemperature"
                  labelCol={{ span: 16 }}
                >
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Waga [kg]" name="weight" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Średnica [m]" name="diameter" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Pojemność [L]" name="capacity" labelCol={{ span: 16 }}>
                  <DomaInputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Powłoka produktu" name="coating" labelCol={{ span: 5 }}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Materiał produktu" name="material" labelCol={{ span: 5 }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Okres trwałości" name="shelfLife" labelCol={{ span: 5 }}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Gwarancja" name="warranty" labelCol={{ span: 5 }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Section>
        </Form>
      )}
    </Container>
  );
};

export default ProductDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const AddColorClick = styled.span`
  font-weight: bold;
  cursor: pointer;
`;
