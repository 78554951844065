import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Content from "./layout/Content";
import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import DSpin from "./helpers/DSpin";
import { ConfigProvider } from "antd";
import plPL from "antd/lib/locale/pl_PL"; // Import polskiej lokalizacji

// Lista dostępnych stron
const pages = [
  {
    key: "",
    path: "/",
  },
  {
    key: "dachy",
    path: "/dachy",
  },
  {
    key: "materialy",
    path: "/materialy",
  },
  {
    key: "systemy-dachowe",
    path: "/systemy-dachowe",
  },
  {
    key: "systemy-rynnowe",
    path: "/systemy-rynnowe",
  },
  {
    key: "integracja-okien",
    path: "/integracja-okien",
  },
  {
    key: "systemy-krycia-wstepnego",
    path: "/systemy-krycia-wstepnego",
  },
  {
    key: "systemy-zamocowan",
    path: "/systemy-zamocowan",
  },
  {
    key: "grupy-produktowe",
    path: "/grupy-produktowe",
  },
  {
    key: "grupy-wyposazenie-techniczne",
    path: "/grupy-wyposazenie-techniczne",
  },
  {
    key: "uzytkownicy",
    path: "/uzytkownicy",
  },
  {
    key: "firma",
    path: "/firma",
  },
  {
    key: "profil",
    path: "/profil",
  },
  {
    key: "oferty",
    path: "/oferty",
  },
  {
    key: "producenci",
    path: "/producenci",
  },
  {
    key: "importMaterials",
    path: "/import-materialow",
  },
  {
    key: "importMaterialsFromFile",
    path: "/import-materialow/z-pliku",
  },
  {
    key: "importUpdateMaterialsFromFile",
    path: "/import-aktualizacja-produktow/z-pliku",
  },
  {
    key: "wycena-robocizny",
    path: "/wycena-robocizny",
  },
  {
    key: "contractorData",
    path: "/dane-wykonawcy",
  },
  {
    key: "konfiguracja-cennika-wykonawcy",
    path: "/konfiguracja-cennika-wykonawcy",
  },
  {
    key: "transfer-danych-wykonawcy",
    path: "/transfer-danych-wykonawcy",
  },
  {
    key: "konfiguracja-wykonawcy",
    path: "/konfiguracja-wykonawcy",
  },
  {
    key: "wykonawcy",
    path: "/wykonawcy",
  },
];

export default function Start() {
  const navigate = useNavigate();
  let { pathname } = useLocation();

  const profile = useSelector((state) => state.profile);
  const loadingState = useSelector((state) => state.loadingState);

  const getFirstSectionPathname = () => {
    return `/${pathname.split("/")[1]}`;
  };

  const [activePage, setActivePage] = useState(pages.find((p) => p.path === getFirstSectionPathname())?.key || "");

  useEffect(() => {
    setActivePage(pages.find((p) => p.path === getFirstSectionPathname())?.key || "");
  }, [pathname]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  const isModerator = () => {
    return profile.role === "moderator";
  };

  const isContractor = () => {
    return profile.role === "contractor";
  };

  // Funkcja odpowiedzialna za przekierowanie do wybranej strony
  const onClickMenuButton = (key) => {
    const page = pages.find((p) => p.key === key);
    if (page) {
      setActivePage(key);
      navigate(page.path);
    } else {
      navigate("/");
    }
  };

  // Dostosowany obiekt lokalizacji
  const customLocale = {
    ...plPL,
    Table: {
      ...plPL.Table,
      // Dostosuj teksty tooltipów dla sortowania
      sortTitle: "Kliknij, aby sortować",
    },
  };

  return (
    <ConfigProvider locale={customLocale}>
      <Container>
        <PageContainer>
          <BottomSidebar />
          <Sidebar
            activePage={activePage}
            onClickMenuButton={onClickMenuButton}
            isAdmin={isAdmin}
            isModerator={isModerator}
            isContractor={isContractor}
          />
          <Content
            style={loadingState.loading ? { pointerEvents: "none" } : {}}
            isAdmin={isAdmin}
            isModerator={isModerator}
          />

          {loadingState.loading && (
            <MaskOverlay>
              <DomaSpin />
            </MaskOverlay>
          )}
        </PageContainer>
      </Container>
    </ConfigProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
  background: #f8f7f7;
  /* position: relative; */
`;

// Coś co jest pod wąskim menu i zjamuje miejsce
const BottomSidebar = styled.div`
  width: 50px;
  min-width: 50px;
  max-width: 50px;

  @media (max-width: 991px) {
    display: none;
  }
`;

const MaskOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100%);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  pointer-events: none;
`;

const DomaSpin = styled(DSpin)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
