import React, { FC, useEffect, useState } from "react";
import {
  IAddSteelSystem,
  IProduct,
  IProductCategory,
  IRootState,
  ISelectOption,
  ISheetMetalProcess,
  ISteelSystem,
  ISteelSystemAddition,
} from "../../../../models/Models";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "antd/es/form/Form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { deleteStatusApi } from "../../../../redux/other/statusApiSlice";
import { addSteelSystem, updateSteelSystem } from "../../../../redux/roofSystems/steelSystemsSlice";
import DomaInputNumber from "../../../../helpers/DomaInputNumber";
import { createFilterOption } from "../../../../helpers/Helpers";
import {
  CATEGORIES_ALL_ROOF,
  EDGES_RELATIONS,
  EXTRA_TILE_SYSTEM_RELATIONS,
  TILE_SYSTEM_RELATIONS,
} from "../../RoofConstants";
import ExtraProductSteelSystemPosition from "./ExtraProductSteelSystemPosition";
import SheetMetalProcessPosition from "./SheetMetalProcessPosition";
import SystemImageComponent from "./SystemImageComponent";
import SystemConfigurationDetailsSection from "../../components/SystemConfigurationDetailsSection";
import { IProductsGroup } from "../../../../redux/productsGroups/productsGroupsSlice";
import { ITechnicalEquipmentGroup } from "../../../../redux/technicalEquipmentsGroups/technicalEquipmentsGroupsSlice";
import { PRIMARY_COLOR } from "../../../../theme";

type Props = {
  system: ISteelSystem | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const SteelSystemDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);
  const statusApi = useAppSelector((state: IRootState) => state.statusApi.find((x) => x.key === "addSteelSystem"));

  const [steelSystemAdditions, setSteelSystemAdditions] = useState<ISteelSystemAddition[]>([]);
  const [sheetMetalProcesses, setSheetMetalProcesses] = useState<ISheetMetalProcess[]>([]); // Obróbki blacharskie

  // const [productsGroups, setProductsGroups] = useState<string[]>([]);

  const [imageFileName, setImageFileName] = useState<string>("");

  const steelSystems = useAppSelector((state: IRootState) => state.steelSystems);

  const productsGroupsState = useAppSelector((state: IRootState) => state.productsGroups);
  const technicalEquipmentsGroupsState = useAppSelector((state: IRootState) => state.technicalEquipmentsGroups);

  const [productsSelectForSteel, setProductsSelectForSteel] = useState<ISelectOption[]>([]);
  const [productsSelectForSheetMetal, setProductsSelectForSheetMetal] = useState<ISelectOption[]>([]);
  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);
  const [productsSelectForAccessories, setProductsSelectForAccessories] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForSteel.length === 0) {
        setProductsSelectForSteel(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
          ])
        );
      }

      if (productsSelectForSheetMetal.length === 0) {
        setProductsSelectForSheetMetal(
          getProductsByCategoryForSelect(products, productCategories, ["supplementary-products"])
        );
      }

      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-tiles-concrete",
            "basic-products-tiles-ceramic",
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
            "wood",
            "supplementary-products",
            "lightning-protection-systems",
            "thermal-insulation",
            "roofing-accessories",
            "fasteners",
            "preliminary-covering",
          ])
        );
      }

      if (productsSelectForAccessories.length === 0) {
        setProductsSelectForAccessories(
          getProductsByCategoryForSelect(products, productCategories, [
            "roofing-accessories",
            "supplementary-products",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addSteelSystem"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addSteelSystem"));
    }
  }, [statusApi]);

  // Jesli coś zmieni się w grupach produktowych to ustawiamy ponownie dodatki i procesy
  useEffect(() => {
    if (steelSystems) {
      const s = steelSystems.find((x) => x.id === system?.id);
      if (s) {
        setSteelSystemAdditions(s.steelSystemAdditions);
        setSheetMetalProcesses(s.sheetMetalProcesses);
      }
    }
  }, [steelSystems]);

  useEffect(() => {
    if (system && system.steelSystemAdditions && system.steelSystemAdditions.length > 0) {
      setSteelSystemAdditions(system.steelSystemAdditions);
    }

    if (system && system.sheetMetalProcesses && system.sheetMetalProcesses.length > 0) {
      setSheetMetalProcesses(system.sheetMetalProcesses);
    }

    if (system?.imageUrl) {
      setImageFileName(system.imageUrl);
    }
  }, [system]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  const getInitialValues = () => {
    return isNew()
      ? {
          name: "",
          description: "",
          imageUrl: "",
          typeSteelSystem: 1,
          halfAvailable: false,
          basicSteelCorrection: 0,
          width: 0,
          length: 0,
          minLength: 0,
          maxLength: 0,
          moduleLength: 0,
          moduleCount: 0,
          upperZipperLength: 0,
          lowerZipperLength: 0,
          modulesPerOverlap: 0,
          dimensionsTolerance: 0,
          holeAddition: 0,
          // roofStepCount: 1,
          // chimneySweepBench40Count: 2,
          // chimneySweepBench80Count: 2,
          // chimneySweepBench200Count: 3,
          // chimneySweepBench300Count: 4,
          // snowFence200Count: 3,
          // snowFence300Count: 4,
          productsGroups: [],
        }
      : {
          name: system?.name,
          description: system?.description,
          imageUrl: system?.imageUrl,
          typeSteelSystem: system?.typeSteelSystem,
          halfAvailable: system?.halfAvailable || false,
          basicSteelProductId: system?.basicSteelProductId ? system?.basicSteelProductId : undefined,
          basicSteelCorrection: system?.basicSteelCorrection ? system?.basicSteelCorrection : 0,
          width: system?.width ? system?.width : 0,
          length: system?.length ? system?.length : 0,
          minLength: system?.minLength ? system?.minLength : 0,
          maxLength: system?.maxLength ? system?.maxLength : 0,
          moduleLength: system?.moduleLength ? system?.moduleLength : 0,
          moduleCount: system?.moduleCount ? system?.moduleCount : 0,
          upperZipperLength: system?.upperZipperLength ? system?.upperZipperLength : 0,
          lowerZipperLength: system?.lowerZipperLength ? system?.lowerZipperLength : 0,
          modulesPerOverlap: system?.modulesPerOverlap ? system?.modulesPerOverlap : 0,
          dimensionsTolerance: system?.dimensionsTolerance ? system?.dimensionsTolerance : 0,
          holeAddition: system?.holeAddition ? system?.holeAddition : 0,
          // roofSupportProductId: system?.roofSupportProductId,
          // roofSupportSnowFenceProductId: system?.roofSupportSnowFenceProductId,
          // chimneyBenchGripProductId: system?.chimneyBenchGripProductId,
          // roofStepProductId: system?.roofStepProductId,
          // roofStepCount: system?.roofStepCount,
          // chimneySweepBench40ProductId: system?.chimneySweepBench40ProductId,
          // chimneySweepBench40Count: system?.chimneySweepBench40Count,
          // chimneySweepBench80ProductId: system?.chimneySweepBench80ProductId,
          // chimneySweepBench80Count: system?.chimneySweepBench80Count,
          // chimneySweepBench200ProductId: system?.chimneySweepBench200ProductId,
          // chimneySweepBench200Count: system?.chimneySweepBench200Count,
          // chimneySweepBench300ProductId: system?.chimneySweepBench300ProductId,
          // chimneySweepBench300Count: system?.chimneySweepBench300Count,
          // snowFence200ProductId: system?.snowFence200ProductId,
          // snowFence200Count: system?.snowFence200Count,
          // snowFence300ProductId: system?.snowFence300ProductId,
          // snowFence300Count: system?.snowFence300Count,

          roofVent100ProductId: system?.roofVent100ProductId,
          roofVent100Extra1ProductId: system?.roofVent100Extra1ProductId,
          roofVent100Extra2ProductId: system?.roofVent100Extra2ProductId,
          roofVent125ProductId: system?.roofVent125ProductId,
          roofVent125Extra1ProductId: system?.roofVent125Extra1ProductId,
          roofVent125Extra2ProductId: system?.roofVent125Extra2ProductId,
          roofVent150ProductId: system?.roofVent150ProductId,
          roofVent150Extra1ProductId: system?.roofVent150Extra1ProductId,
          roofVent150Extra2ProductId: system?.roofVent150Extra2ProductId,

          productsGroups: system?.productsGroups,
          technicalEquipmentsGroupId: system?.technicalEquipmentsGroupId,

          companyId: system?.companyId,
          modelId: system?.productModelId,
          colorId: system?.productColorId,
        };
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "System został utworzony!",
    });
  };

  const onFinish = (values: any, newVersion: boolean) => {
    if (isNew() || newVersion) {
      let s: IAddSteelSystem = {
        name: values.name,
        description: values.description,
        // imageUrl: values.imageUrl,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        typeSteelSystem: values?.typeSteelSystem,
        halfAvailable: values.halfAvailable || false,

        basicSteelProductId: values?.basicSteelProductId,
        basicSteelCorrection: values?.basicSteelCorrection,
        width: values?.width,
        length: values?.length,
        minLength: values?.minLength,
        maxLength: values?.maxLength,
        moduleLength: values?.moduleLength,
        moduleCount: values?.moduleCount,
        upperZipperLength: values?.upperZipperLength,
        lowerZipperLength: values?.lowerZipperLength,
        modulesPerOverlap: values?.modulesPerOverlap,
        dimensionsTolerance: values?.dimensionsTolerance,
        holeAddition: values?.holeAddition,
        roofSupportProductId: values?.roofSupportProductId,
        roofSupportSnowFenceProductId: values?.roofSupportSnowFenceProductId,
        chimneyBenchGripProductId: values?.chimneyBenchGripProductId,
        // roofStepProductId: values?.roofStepProductId,
        // roofStepCount: values?.roofStepCount,
        // chimneySweepBench40ProductId: values?.chimneySweepBench40ProductId,
        // chimneySweepBench40Count: values?.chimneySweepBench40Count,
        // chimneySweepBench80ProductId: values?.chimneySweepBench80ProductId,
        // chimneySweepBench80Count: values?.chimneySweepBench80Count,
        // chimneySweepBench200ProductId: values?.chimneySweepBench200ProductId,
        // chimneySweepBench200Count: values?.chimneySweepBench200Count,
        // chimneySweepBench300ProductId: values?.chimneySweepBench300ProductId,
        // chimneySweepBench300Count: values?.chimneySweepBench300Count,
        // snowFence200ProductId: values?.snowFence200ProductId,
        // snowFence200Count: values?.snowFence200Count,
        // snowFence300ProductId: values?.snowFence300ProductId,
        // snowFence300Count: values?.snowFence300Count,
        roofVent100ProductId: values?.roofVent100ProductId,
        roofVent100Extra1ProductId: values?.roofVent100Extra1ProductId,
        roofVent100Extra2ProductId: values?.roofVent100Extra2ProductId,
        roofVent125ProductId: values?.roofVent125ProductId,
        roofVent125Extra1ProductId: values?.roofVent125Extra1ProductId,
        roofVent125Extra2ProductId: values?.roofVent125Extra2ProductId,
        roofVent150ProductId: values?.roofVent150ProductId,
        roofVent150Extra1ProductId: values?.roofVent150Extra1ProductId,
        roofVent150Extra2ProductId: values?.roofVent150Extra2ProductId,
        steelSystemAdditions: steelSystemAdditions,
        sheetMetalProcesses: sheetMetalProcesses,
        productsGroups: values?.productsGroups,
        technicalEquipmentsGroupId: values?.technicalEquipmentsGroupId,
      };
      dispatch(addSteelSystem(s, successCallback));
    } else {
      let s: ISteelSystem = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        // imageUrl: values.imageUrl,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        typeSteelSystem: values?.typeSteelSystem,
        halfAvailable: values?.halfAvailable || false,

        basicSteelProductId: values?.basicSteelProductId,
        basicSteelCorrection: values?.basicSteelCorrection,
        width: values?.width,
        length: values?.length,
        minLength: values?.minLength,
        maxLength: values?.maxLength,
        moduleLength: values?.moduleLength,
        moduleCount: values?.moduleCount,
        upperZipperLength: values?.upperZipperLength,
        lowerZipperLength: values?.lowerZipperLength,
        modulesPerOverlap: values?.modulesPerOverlap,
        dimensionsTolerance: values?.dimensionsTolerance,
        holeAddition: values?.holeAddition,
        roofSupportProductId: values?.roofSupportProductId,
        roofSupportSnowFenceProductId: values?.roofSupportSnowFenceProductId,
        chimneyBenchGripProductId: values?.chimneyBenchGripProductId,
        // roofStepProductId: values?.roofStepProductId,
        // roofStepCount: values?.roofStepCount,
        // chimneySweepBench40ProductId: values?.chimneySweepBench40ProductId,
        // chimneySweepBench40Count: values?.chimneySweepBench40Count,
        // chimneySweepBench80ProductId: values?.chimneySweepBench80ProductId,
        // chimneySweepBench80Count: values?.chimneySweepBench80Count,
        // chimneySweepBench200ProductId: values?.chimneySweepBench200ProductId,
        // chimneySweepBench200Count: values?.chimneySweepBench200Count,
        // chimneySweepBench300ProductId: values?.chimneySweepBench300ProductId,
        // chimneySweepBench300Count: values?.chimneySweepBench300Count,
        // snowFence200ProductId: values?.snowFence200ProductId,
        // snowFence200Count: values?.snowFence200Count,
        // snowFence300ProductId: values?.snowFence300ProductId,
        // snowFence300Count: values?.snowFence300Count,
        roofVent100ProductId: values?.roofVent100ProductId,
        roofVent100Extra1ProductId: values?.roofVent100Extra1ProductId,
        roofVent100Extra2ProductId: values?.roofVent100Extra2ProductId,
        roofVent125ProductId: values?.roofVent125ProductId,
        roofVent125Extra1ProductId: values?.roofVent125Extra1ProductId,
        roofVent125Extra2ProductId: values?.roofVent125Extra2ProductId,
        roofVent150ProductId: values?.roofVent150ProductId,
        roofVent150Extra1ProductId: values?.roofVent150Extra1ProductId,
        roofVent150Extra2ProductId: values?.roofVent150Extra2ProductId,
        steelSystemAdditions: steelSystemAdditions,
        sheetMetalProcesses: sheetMetalProcesses,
        productsGroups: values?.productsGroups,
        technicalEquipmentsGroupId: values?.technicalEquipmentsGroupId,
      };
      dispatch(updateSteelSystem(s));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  interface AccessoriesProductPositionProps {
    productLabel: string;
    productName: string;
    supportsCountName: string;
    supportsCountLabel: string;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    selectedCategories: string[];
    supportsDisabled: boolean;
    readyOptions?: any[];
  }

  const AccessoriesProductPosition: React.FC<AccessoriesProductPositionProps> = ({
    productLabel,
    productName,
    supportsCountName,
    supportsCountLabel,
    products,
    productCategories,
    selectedCategories,
    supportsDisabled,
    readyOptions,
  }) => {
    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          {!supportsDisabled && (
            <Form.Item label={supportsCountLabel} name={supportsCountName} labelCol={{ span: 24 }}>
              <DomaInputNumber allowNegative min={-100} max={200} style={{ width: "100%" }} />
            </Form.Item>
          )}
        </Col>
      </Row>
    );
  };

  const getProductsGroupsForSelect = (productsGroups: IProductsGroup[]) => {
    const result: { value: any; label: string }[] = [];

    for (const p of productsGroups) {
      result.push({
        value: p.id,
        label: p.name,
      });
    }
    return result;
  };

  const getTechnicalEquipmentsGroupsForSelect = (productsGroups: ITechnicalEquipmentGroup[]) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];

    for (const p of productsGroups) {
      result.push({
        value: p.id,
        label: p.name,
      });
    }
    return result;
  };

  return (
    <Container>
      {contextHolder}
      <Form
        id="steelSystemDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Section title={"Ogólne dane systemu"}>
          <Form.Item
            label="Nazwa systemu"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę systemu!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis systemu" name="description" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>

          {/* <Form.Item label="Link do zdjęcia obrazującego system" name="imageUrl" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item> */}

          <SystemImageComponent
            systemId={system?.id || ""}
            systemName="steel"
            imageFileName={imageFileName}
            setImageFileName={(name) => setImageFileName(name)}
          />

          <Form.Item label="Typ pokrycia" name="typeSteelSystem" labelCol={{ span: 24 }}>
            <StyledRadioGroup>
              <Radio.Button value={1}>Blachodachówka (arkusze)</Radio.Button>
              <Radio.Button value={2}>Blachodachówka cięta</Radio.Button>
              <Radio.Button value={3}>Blacha trapezowa</Radio.Button>
              <Radio.Button value={4}>Blacha na rąbek</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item
            label="Możliwość użycia połówek (dla blachodachówki w arkuszach)"
            name="halfAvailable"
            labelCol={{ span: 24 }}
          >
            <StyledRadioGroup>
              <Radio.Button value={true}>TAK</Radio.Button>
              <Radio.Button value={false}>NIE</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Alert
            showIcon
            message="Użycie połówek pozwala na użycie połówek arkuszy blachodachówki. W przypadku braku tej opcji, system będzie wykorzystywał pełne arkusze blachodachówki."
          />
        </Section>

        {/* Sekcja do konfiguracji producenta, modelu oraz koloru */}
        <SystemConfigurationDetailsSection form={form} />

        <Section
          title={"Podstawowy produkt systemu (blachodachówka, arkusz blachy)"}
          style={{ marginTop: "16px" }}
        >
          <Row gutter={16}>
            <Col span={22}>
              <Form.Item
                label={"Produkt podstawowy (arkusz blachy)"}
                name="basicSteelProductId"
                labelCol={{ span: 24 }}
              >
                <Select
                  placeholder="Wybierz produkt!"
                  options={
                    productsSelectForSteel.length > 0
                      ? productsSelectForSteel
                      : getProductsByCategoryForSelect(products, productCategories, [
                          "basic-products-sheet-metal",
                          "basic-products-cut-sheet-metal",
                          "basic-products-sheet-metal-trapezoidal",
                          "basic-products-sheet-metal-standing-seam",
                        ])
                  }
                  showSearch
                  filterOption={createFilterOption()}
                  filterSort={(optionA: any, optionB: any) =>
                    (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={(value) => {
                    const selectedProduct = products.find((p) => p.id === value);
                    if (selectedProduct) {
                      form.setFieldsValue({
                        width: selectedProduct.effectiveWidth,
                        length: selectedProduct.length,
                        minLength: selectedProduct.minLength,
                        maxLength: selectedProduct.maxLength,
                        moduleLength: selectedProduct.moduleLength,
                        moduleCount: selectedProduct.moduleCount,
                        upperZipperLength: selectedProduct.upperZipperLength,
                        lowerZipperLength: selectedProduct.lowerZipperLength,
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item label="Korekta w [%]" name="basicSteelCorrection" labelCol={{ span: 24 }}>
                <DomaInputNumber allowNegative min={-100} max={200} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label="Szerokość obliczeniowa arkusza [m]" name="width" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Długość/wysokość arkusza [m]" name="length" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Długość minimalna [m]" name="minLength" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Długość maksymalna [m]" name="maxLength" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label="Długość modułu [m]" name="moduleLength" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Ilość modułów [szt.]" name="moduleCount" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Długość zamka górnego [m]" name="upperZipperLength" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Długość zamka dolnego [m]" name="lowerZipperLength" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label="Ilość modułów na zakład [szt.]" name="modulesPerOverlap" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Tolerancja wymiarów [m]" name="dimensionsTolerance" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Dodatek na otwór [m]" name="holeAddition" labelCol={{ span: 24 }}>
                <DomaInputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Section>

        <Section
          title={"Pozostałe produkty systemu (uzależnione od typu krawędzi, powierzchni dachu, itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newSteelSystemAddition: ISteelSystemAddition = {
                  id: uuidv4(),
                  productId: "",
                  use: 0,
                  relation: "none",
                  correction: 0,
                  steelSystemId: system?.id as string,
                };
                setSteelSystemAdditions([newSteelSystemAddition, ...steelSystemAdditions]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {steelSystemAdditions.map((addition) => {
            return (
              <ExtraProductSteelSystemPosition
                key={addition.id}
                addition={addition}
                selectedCategories={[
                  "basic-products-tiles-concrete",
                  "basic-products-tiles-ceramic",
                  "basic-products-sheet-metal",
                  "basic-products-cut-sheet-metal",
                  "basic-products-sheet-metal-trapezoidal",
                  "basic-products-sheet-metal-standing-seam",
                  "wood",
                  "supplementary-products",
                  "lightning-protection-systems",
                  "thermal-insulation",
                  "roofing-accessories",
                  "fasteners",
                  "preliminary-covering",
                ]}
                readyOptions={productsSelectForAdditions}
                products={products}
                productCategories={productCategories}
                useDisabled={false}
                relationDisabled={false}
                correctionDisabled={false}
                relations={[...TILE_SYSTEM_RELATIONS]}
                updateAddition={(addition) => {
                  const newAdditions = [...steelSystemAdditions];
                  const index = newAdditions.findIndex((x) => x.id === addition.id);

                  if (index >= 0) {
                    newAdditions[index] = addition;
                    setSteelSystemAdditions(newAdditions);
                  }
                }}
                deleteAddition={(addition) => {
                  const newAdditions = [...steelSystemAdditions].filter((x) => x.id !== addition.id);
                  setSteelSystemAdditions(newAdditions);
                }}
              />
            );
          })}
        </Section>

        <Section
          title={"Obróbki blacharskie (krawędzi koszowej, pasa nadrynnowego, komina itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newSheetMetalProcesses: ISheetMetalProcess = {
                  id: uuidv4(),
                  productId: "",
                  sheetWidth: 0,
                  sheetLength: 0,
                  stripWidth: 0,
                  overlap: 0,
                  relation: "none",
                  correction: 0,
                  steelSystemId: system?.id as string,
                };

                setSheetMetalProcesses([newSheetMetalProcesses, ...sheetMetalProcesses]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {sheetMetalProcesses.map((process) => {
            return (
              <SheetMetalProcessPosition
                key={process.id}
                sheetMetalProcess={process}
                selectedCategories={["supplementary-products"]}
                readyOptions={productsSelectForSheetMetal}
                products={products}
                productCategories={productCategories}
                relations={[...EDGES_RELATIONS]}
                updateSheetMetalProcess={(process) => {
                  const newSheetMetalProcesses = [...sheetMetalProcesses];
                  const index = newSheetMetalProcesses.findIndex((x) => x.id === process.id);

                  if (index >= 0) {
                    newSheetMetalProcesses[index] = process;
                    setSheetMetalProcesses(newSheetMetalProcesses);
                  }
                }}
                deleteSheetMetalProcess={(process) => {
                  const newSheetMetalProcesses = [...sheetMetalProcesses].filter((x) => x.id !== process.id);
                  setSheetMetalProcesses(newSheetMetalProcesses);
                }}
              />
            );
          })}
        </Section>

        <Section title={"Grupy dodatkowych produktów"} style={{ marginTop: "16px" }}>
          <Form.Item label={"Nazwy grup produktowych"} name="productsGroups" labelCol={{ span: 24 }}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Wybierz grupy produktowe"
              options={getProductsGroupsForSelect(productsGroupsState)}
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Form.Item>
        </Section>

        <Section
          title={"Akcesoria dachowe, komunikacja oraz zabezpieczenia przeciwśniegowe"}
          style={{ marginTop: "16px" }}
        >
          <Form.Item
            label={"Grupa wyposażenia technicznego"}
            name="technicalEquipmentsGroupId"
            labelCol={{ span: 24 }}
          >
            <Select
              allowClear
              placeholder="Wybierz grupę wyposażenia technicznego"
              options={getTechnicalEquipmentsGroupsForSelect(technicalEquipmentsGroupsState)}
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              showSearch
            />
          </Form.Item>

          <Divider />

          {/* Kominek wentylacyjny 100*/}
          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 100 - produkt podstawowy"
            productName="roofVent100ProductId"
            supportsCountName="roofVent100Count"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 100 - produkt dodatkowy"
            productName="roofVent100Extra1ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 100 - produkt dodatkowy"
            productName="roofVent100Extra2ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <div style={{ marginTop: "32px" }}></div>

          {/* Kominek wentylacyjny 125*/}
          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 125 - produkt podstawowy"
            productName="roofVent125ProductId"
            supportsCountName="roofVent125Count"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 125 - produkt dodatkowy"
            productName="roofVent125Extra1ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 125 - produkt dodatkowy"
            productName="roofVent125Extra2ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <div style={{ marginTop: "32px" }}></div>

          {/* Kominek wentylacyjny 150*/}
          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 150 - produkt podstawowy"
            productName="roofVent150ProductId"
            supportsCountName="roofVent150Count"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 150 - produkt dodatkowy"
            productName="roofVent150Extra1ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={["roofing-accessories", "supplementary-products"]}
            readyOptions={productsSelectForAccessories}
            products={products}
            productCategories={productCategories}
            productLabel="Kominek wentylacyjny 150 - produkt dodatkowy"
            productName="roofVent150Extra2ProductId"
            supportsCountName=""
            supportsDisabled={true}
            supportsCountLabel=""
          />
        </Section>
      </Form>
    </Container>
  );
};

export default SteelSystemDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
