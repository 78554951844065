import React, { FC, useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Button, Card, Checkbox, Input, Modal, Statistic, Switch, Table, Tag, Tooltip } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { IProductToReport, IResultItemResponse, IRootState } from "../../../../../models/Models";
import {
  calculateOffer,
  updateActiveCalcResultItem,
  updateAmountCalcResultItem,
  updateStatusCalcResult,
} from "../../../../../redux/calculationProcess/calcResultSlice";
import OfferPrice from "../../../../../components/OfferPrice";
import ResultItemModal from "../../../../../components/ResultItemModal";
import OfferPriceModal from "../../../../../components/OfferPriceModal";
import OfferInformations from "../../../../../components/OfferInformations";
import {
  getGrossValue,
  getGrossValueWithAllDiscount,
  getItemNettPrice,
  getItemNettPriceWithAllDiscount,
  getNettValue,
  getNettValueWithAllDiscount,
  getNettValueWithPoductDiscount,
  getVAT,
  getVatValueFinal,
} from "../../../managers/DiscountManager";
import { setShouldRegenerateReport } from "../../../../../redux/calculationProcess/reportSlice";
import GroupDiscountModal from "../../../../../components/GroupDiscountModal";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { PRIMARY_COLOR } from "../../../../../theme";

export const BASIC_PRODUCTS = [
  "basic-products-sheet-metal-standing-seam",
  "basic-products-cut-sheet-metal",
  "basic-products-tiles-ceramic",
  "basic-products-sheet-metal-trapezoidal",
  "basic-products-sheet-metal",
  "basic-products-tiles-concrete",
];

export const PRELIMINARY_COVERING_PRODUCTS = ["preliminary-covering"];

export const ROOFING_ACCESSORIES_PRODUCTS = ["roofing-accessories"];

export const WOOD_PRODUCTS = ["wood"];

export const ROOF_WALKWAYS_PRODUCTS = ["roof-walkways-and-snow-guards"];

export const GUTTERING_PRODUCTS = ["guttering"];

export const FASTENERS_PRODUCTS = ["fasteners"];

export const WINDOWS_PRODUCTS = ["windows", "window-collars-and-accessories"];

export const THERMAL_PRODUCTS = ["thermal-insulation"];

export const LIGHTNING_PROTECTION_PRODUCTS = ["lightning-protection-systems"];

export const SUPPLEMENTARY_PRODUCTS = ["supplementary-products"];

export const OTHERS_PRODUCTS = ["others"];

export const LABOUR_PRODUCTS = ["labour"];

const resultColumns = [
  {
    title: "Id.",
    dataIndex: "id",
    width: "50px",
    sorter: (a: any, b: any) => a.id - b.id,
  },
  {
    title: "Aktywne",
    dataIndex: "active",
    width: "50px",
    align: "center" as const,
  },
  {
    title: "Nazwa produktu",
    dataIndex: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "Ilość",
    dataIndex: "amount",
    width: "50px",
    align: "center" as const,
  },
  {
    title: "J.m.",
    dataIndex: "unit",
    width: "80px",
    align: "center" as const,
  },
  {
    title: "Cena netto/j.m.",
    dataIndex: "netPrice",
    width: "110px",
    align: "center" as const,
  },
  {
    title: "Wartość netto",
    dataIndex: "netValue",
    width: "110px",
    align: "center" as const,
  },
  {
    title: "Rabat",
    dataIndex: "discount",
    width: "130px",
    align: "center" as const,
  },
  {
    title: "VAT",
    dataIndex: "vat",
    width: "100px",
    align: "center" as const,
  },
  {
    title: "Wartość brutto",
    dataIndex: "grossValue",
    width: "110px",
    align: "center" as const,
  },
  {
    title: "Akcja",
    dataIndex: "action",
    width: "100px",
    align: "center" as const,
  },
];

type Props = {};

const ResultStep: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const calcResult = useAppSelector((state: IRootState) => state.calcResult);
  const additionalElements = useAppSelector((state: IRootState) => state.additionalElements);

  const [groupDiscountModal, setGroupDiscountModal] = useState<boolean>(false);
  const [groupDiscountSelected, setGroupDiscountSelected] = useState<string | null>(null);

  const [resultItemModal, setResultItemModal] = useState<boolean>(false);
  const [resultItemEdit, setResultItemEdit] = useState<IResultItemResponse | null>(null);

  const [confirmRecalculateModal, setConfirmRecalculateModal] = useState(false); // State dla modala z potwierdzeniem obliczeni aponownego

  const initialOrder = useRef<number[]>([]); // Lista id produktów w posortowanej kolejności - potrzebne do późniejszego ustawiania w kolejności jaka była na początku
  const activeSorter = useRef<boolean>(true); // Czy włączone sortowanie wyników - wyłączane gdy juz są uzyskane wyniki i jest reczna zmiana ilości

  // Aktualizowanie tymczasowej sumy wszystkich cen po rabattach do wyświetlenia w OfferPrice
  useEffect(() => {}, [calcResult]);

  useEffect(() => {
    if (groupDiscountSelected !== null) {
      setGroupDiscountModal(true);
    } else {
      setGroupDiscountModal(false);
    }
  }, [groupDiscountSelected]);

  const recalculate = () => {
    // console.log("OBLICZ PONOWNIE");
    dispatch(calculateOffer(false));
    dispatch(updateStatusCalcResult({ ...calcResult, isCalculated: true }));
    dispatch(setShouldRegenerateReport(true));
  };

  // Funkcja zwracająca kolumnę cena jednostkowa netto
  const getNettPriceItemColumn = (item: IResultItemResponse) => {
    if (item) {
      if (item.discount > 0 || calcResult.offerDiscount > 0) {
        // Dwie linie
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ textDecoration: "line-through" }}>{getItemNettPrice(item.id).toFixed(2)}</span>
            <span style={{ color: PRIMARY_COLOR }}>{getItemNettPriceWithAllDiscount(item.id).toFixed(2)}</span>
          </div>
        );
      } else {
        // Jedna linia
        return <span style={{ color: PRIMARY_COLOR }}>{getItemNettPrice(item.id).toFixed(2)}</span>;
      }
    }
    return <span style={{ color: PRIMARY_COLOR }}>0</span>;
  };

  // Funkcja zwracająca kolumnę wartość netto
  const getNettValueItemColumn = (item: IResultItemResponse) => {
    if (item.discount > 0 || calcResult.offerDiscount > 0) {
      // Dwie linie
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ textDecoration: "line-through" }}>{getNettValue(item.id).toFixed(2)}</span>
          <span style={{ color: PRIMARY_COLOR }}>{getNettValueWithAllDiscount(item.id).toFixed(2)}</span>
        </div>
      );
    } else {
      // jedna linia
      return <span style={{ color: PRIMARY_COLOR }}>{getNettValue(item.id).toFixed(2)}</span>;
    }
  };

  // Funkcja zwracająca kolumnę wartość brutto
  const getGrossValueItemColumn = (item: IResultItemResponse) => {
    if (item.discount > 0 || calcResult.offerDiscount > 0) {
      // Dwie linie
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ textDecoration: "line-through" }}>{getGrossValue(item.id).toFixed(2)}</span>
          <span style={{ color: PRIMARY_COLOR }}>{getGrossValueWithAllDiscount(item.id).toFixed(2)}</span>
        </div>
      );
    } else {
      // jedna linia
      return <span style={{ color: PRIMARY_COLOR }}>{getGrossValue(item.id).toFixed(2)}</span>;
    }
  };

  // Funkcja zwracająca kolumnę wartość brutto
  const getVATColumn = (item: IResultItemResponse) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span style={{}}>{calcResult.vat}%</span>
        <span style={{ color: PRIMARY_COLOR }}>{getVatValueFinal(item.id).toFixed(2)}</span>
      </div>
    );
  };

  // Funkcja zwracająca kolumnę z rabatami
  const getDiscountColumn = (item: IResultItemResponse) => {
    const result = [];

    if (item.discount > 0) {
      //  Rabat na produkt
      let discountRow = "";

      if (item.discountType === "%") {
        discountRow = `P: ${item.discount.toFixed(2)} %`;
      } else {
        // Gdy rabat kwotowy
        if (item.discountPart === "nett") {
          discountRow = `P: -${item.discount.toFixed(2)} zł`;
        } else {
          discountRow = `P: -${item.discount.toFixed(2)} zł`;
        }
      }

      result.push(<span key={1}>{discountRow}</span>);
    }

    if (calcResult.offerDiscount > 0) {
      // Rabat na ofertę
      let discountRow = "";

      if (calcResult.offerDiscountType === "%") {
        discountRow = `O: ${calcResult.offerDiscount.toFixed(2)} %`;
      } else {
        // Gdy rabat kwotowy

        let sumNettValueWithProductDiscount = 0; // netto całość z rabatami produktowymi
        if (calcResult.data) {
          for (const item of calcResult.data) {
            sumNettValueWithProductDiscount += getNettValueWithPoductDiscount(item.id) as number;
          }
        }

        const allPercent =
          ((getNettValueWithPoductDiscount(item.id) as number) * 100) / sumNettValueWithProductDiscount; // Ile % stanowi produkt całości netto
        const eff = allPercent / 100;

        if (calcResult.offerDiscountPart === "nett") {
          const discountForItem = calcResult.offerDiscount * eff;
          discountRow = `O: -${discountForItem.toFixed(2)} zł`;
        } else {
          const vat = getVAT();
          const eff_0 = vat / 100;
          const vat_eff = 1 + eff_0;

          const offerDiscount = calcResult.offerDiscount / vat_eff;
          const discountForItem = offerDiscount * eff;

          discountRow = `O: -${discountForItem.toFixed(2)} zł`;
        }
      }

      result.push(<span key={2}>{discountRow}</span>);
    }

    if (item.discount === 0 && calcResult.offerDiscount === 0) {
      result.push(<span key={3}>Brak</span>);
    }

    return <div style={{ display: "flex", flexDirection: "column" }}>{result.map((o) => o)}</div>;
  };

  const getItemsData = (items: IResultItemResponse[]) => {
    const withSort = activeSorter.current;

    const data: {
      key: string;
      active: JSX.Element;
      id: number;
      name: string;
      amount: JSX.Element;
      unit: string;
      netPrice: JSX.Element;
      netValue: JSX.Element;
      rawNetValue: number; // tylko do sortowania
      discount: JSX.Element;
      vat: JSX.Element;
      grossValue: JSX.Element;
      action: JSX.Element;
    }[] = [];

    let i = 1;
    for (const item of items) {
      if (item) {
        const netValue =
          item.discount > 0 || calcResult.offerDiscount > 0
            ? getNettValueWithAllDiscount(item.id)
            : getNettValue(item.id);

        data.push({
          key: item.id,
          id: i,
          active: (
            <Checkbox
              checked={item.active}
              onChange={(e) => dispatch(updateActiveCalcResultItem({ id: item.id, value: e.target.checked }))}
            />
          ),
          name: item.name,
          amount: (
            <DomaInputNumber
              style={{ width: "70px" }}
              disabled={!item.active}
              value={item.amount}
              onChange={(value) => {
                // Aktualizacja Amount w Item
                dispatch(updateAmountCalcResultItem({ id: item.id, amount: value as number }));
              }}
            />
          ),
          unit: item.salesFormName,
          netPrice: getNettPriceItemColumn(item),
          netValue: getNettValueItemColumn(item),
          rawNetValue: netValue, // Używany do sortowania
          discount: getDiscountColumn(item),
          vat: getVATColumn(item),
          grossValue: getGrossValueItemColumn(item),
          action: (
            <Button
              disabled={!item.active}
              onClick={() => {
                setResultItemEdit(item);
                setResultItemModal(true);
              }}
            >
              Edytuj
            </Button>
          ),
        });
        i++;
      }
    }

    // data.sort((a, b) => b.rawNetValue - a.rawNetValue);

    if (withSort) {
      // Sortowanie według wartości netValue
      data.sort((a, b) => b.rawNetValue - a.rawNetValue);

      // Ustawiamy initaialOrder
      initialOrder.current = data.map((x) => x.id);

      // Ustawiamy flagę, że już jest posortowane
      activeSorter.current = false;
    } else {
      // Sortowanie według initialOrder
      data.sort((a, b) => initialOrder.current.indexOf(a.id) - initialOrder.current.indexOf(b.id));
    }

    // // Usuń rawNetValue, jeśli nie chcesz go eksponować poza tą funkcją
    // data.forEach(item => delete item.rawNetValue);

    return data;
  };

  const getSumePriceForGroup = (items: IResultItemResponse[]) => {
    let nettValue = 0;
    let grossValue = 0;

    for (const item of items.filter((x) => x.active)) {
      nettValue += getNettValueWithAllDiscount(item.id);
      grossValue += getGrossValueWithAllDiscount(item.id);
    }
    return (
      <div style={{ display: "flex", gap: "2px" }}>
        <Tooltip title="Wartość NETTO dla grupy." color={PRIMARY_COLOR}>
          <Tag style={{ display: "flex", justifyContent: "center", padding: "4px 8px 4px 8px", margin: "0px" }}>
            <b>{nettValue.toFixed(2)} zł</b>
          </Tag>
        </Tooltip>
        <span>/</span>
        <Tooltip title="Wartość BRUTTO dla grupy." color={PRIMARY_COLOR}>
          <Tag style={{ display: "flex", justifyContent: "center", padding: "4px 8px 4px 8px", margin: "0px" }}>
            <b>{grossValue.toFixed(2)} zł</b>
          </Tag>
        </Tooltip>
      </div>
    );
    // return ` ${nettValue} zł (${grossValue}) zł`;
  };

  return (
    <Container>
      {resultItemModal && (
        <ResultItemModal
          item={resultItemEdit}
          open={resultItemModal}
          onCancel={() => setResultItemModal(false)}
          onOk={() => setResultItemModal(false)}
        />
      )}

      {groupDiscountModal && (
        <GroupDiscountModal
          open={groupDiscountModal}
          onCancel={() => setGroupDiscountSelected(null)}
          onOk={() => setGroupDiscountSelected(null)}
          groupCode={groupDiscountSelected}
        />
      )}

      <PriceRow>
        <div style={{ display: "flex", gap: "16px" }}>
          <OfferPrice />
          {/* <OfferInformations /> */}
        </div>

        <div style={{ display: "flex", gap: "16px" }}>
          <Tooltip
            title={
              <div style={{ textAlign: "center" }}>
                <b>UWAGA!!!</b> Jeśli klikniesz <b>OBLICZ PONOWNIE</b> to wszystkie zmiany w ofercie zostaną
                usunięte i uzyskasz nową podstawową wycenę. Gdy cokolwiek zostanie zmienione w geometrii dachu
                należy wykonać operację ponownego obliczenia.
              </div>
            }
            placement="bottom"
            color={PRIMARY_COLOR}
          >
            <CalcButton type="default" onClick={() => setConfirmRecalculateModal(true)}>
              WYKONAJ NOWE OBLICZENIE DACHU
            </CalcButton>
          </Tooltip>

          {/* Modal z potwierdzeniem rysowania nowego dachu. */}
          {confirmRecalculateModal && (
            <Modal
              open={true}
              centered
              title="Oblicz ponownie"
              onCancel={() => setConfirmRecalculateModal(false)}
              footer={
                <div>
                  <Button
                    key="back"
                    onClick={() => {
                      setConfirmRecalculateModal(false);
                    }}
                  >
                    Anuluj
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => {
                      recalculate();
                      setConfirmRecalculateModal(false);
                    }}
                  >
                    Tak
                  </Button>
                </div>
              }
            >
              Czy na pewno chcesz obliczyć ponownie dach? Po tej operacji wszystkie zmiany (rabaty, dodane produkty
              i zmiana ich ilości) w ofercie zostaną usunięte i powstanie nowa podstawowa wycena.
            </Modal>
          )}

          <Tooltip
            title={
              <div style={{ textAlign: "center" }}>
                Dodaj dodatkowy produkt, który powinien znaleźć się w ofercie (np. dodatkowa membrana)
              </div>
            }
            placement="bottom"
            color={PRIMARY_COLOR}
          >
            <CalcButton
              style={{ maxWidth: "250px", background: "#ffffff", color: PRIMARY_COLOR }}
              onClick={() => {
                if (resultItemEdit !== null) {
                  setResultItemEdit(null);
                }
                setResultItemModal(true);
              }}
            >
              Dodaj dodatkowy produkt
            </CalcButton>
          </Tooltip>
        </div>
      </PriceRow>

      {/* Produkty podstawowe dachówki */}
      {calcResult.data?.some((x) => BASIC_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => BASIC_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) => BASIC_PRODUCTS.includes(x.categoryCode))) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty podstawowe
              {getSumePriceForGroup(calcResult.data.filter((x) => BASIC_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("basic-products")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => BASIC_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Akcesoria dachowe */}
      {calcResult.data?.some((x) => ROOFING_ACCESSORIES_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => ROOFING_ACCESSORIES_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        ROOFING_ACCESSORIES_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty akcesorii dachowych
              {getSumePriceForGroup(
                calcResult.data.filter((x) => ROOFING_ACCESSORIES_PRODUCTS.includes(x.categoryCode))
              )}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("roofing-accessories")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(
                calcResult.data.filter((x) => ROOFING_ACCESSORIES_PRODUCTS.includes(x.categoryCode))
              )}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Produkty krycie wstępne */}
      {calcResult.data?.some((x) => PRELIMINARY_COVERING_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => PRELIMINARY_COVERING_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        PRELIMINARY_COVERING_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty krycia wstępnego
              {getSumePriceForGroup(
                calcResult.data.filter((x) => PRELIMINARY_COVERING_PRODUCTS.includes(x.categoryCode))
              )}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("preliminary-covering")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(
                calcResult.data.filter((x) => PRELIMINARY_COVERING_PRODUCTS.includes(x.categoryCode))
              )}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Drewno */}
      {calcResult.data?.some((x) => WOOD_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => WOOD_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) => WOOD_PRODUCTS.includes(x.categoryCode))) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty drewniane
              {getSumePriceForGroup(calcResult.data.filter((x) => WOOD_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("wood")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => WOOD_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Komunikacja dachowa */}
      {calcResult.data?.some((x) => ROOF_WALKWAYS_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => ROOF_WALKWAYS_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        ROOF_WALKWAYS_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty komunikacji dachowej i zabezpieczenia przeciwśniegowego
              {getSumePriceForGroup(
                calcResult.data.filter((x) => ROOF_WALKWAYS_PRODUCTS.includes(x.categoryCode))
              )}
            </div>
          }
          extra={
            <Button onClick={() => setGroupDiscountSelected("roof-walkways-and-snow-guards")}>
              Rabat grupowy
            </Button>
          }
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(
                calcResult.data.filter((x) => ROOF_WALKWAYS_PRODUCTS.includes(x.categoryCode))
              )}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* System rynnowy */}
      {calcResult.data?.some((x) => GUTTERING_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => GUTTERING_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        GUTTERING_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty systemu orynnowania
              {getSumePriceForGroup(calcResult.data.filter((x) => GUTTERING_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("guttering")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => GUTTERING_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Technika zamocowań */}
      {calcResult.data?.some((x) => FASTENERS_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => FASTENERS_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        FASTENERS_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty techniki zamocowań
              {getSumePriceForGroup(calcResult.data.filter((x) => FASTENERS_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("fasteners")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => FASTENERS_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Okna i dodatki */}
      {calcResult.data?.some((x) => WINDOWS_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => WINDOWS_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        WINDOWS_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty okien i dodatków okiennych
              {getSumePriceForGroup(calcResult.data.filter((x) => WINDOWS_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("windows")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => WINDOWS_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Termoizolacje */}
      {calcResult.data?.some((x) => THERMAL_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => THERMAL_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        THERMAL_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty termoizolacji
              {getSumePriceForGroup(calcResult.data.filter((x) => THERMAL_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("thermal-insulation")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => THERMAL_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* System odgromowy */}
      {calcResult.data?.some((x) => LIGHTNING_PROTECTION_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => LIGHTNING_PROTECTION_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        LIGHTNING_PROTECTION_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty systemu odgromowego
              {getSumePriceForGroup(
                calcResult.data.filter((x) => LIGHTNING_PROTECTION_PRODUCTS.includes(x.categoryCode))
              )}
            </div>
          }
          extra={
            <Button onClick={() => setGroupDiscountSelected("lightning-protection-systems")}>Rabat grupowy</Button>
          }
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(
                calcResult.data.filter((x) => LIGHTNING_PROTECTION_PRODUCTS.includes(x.categoryCode))
              )}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Produkty uzupełniające */}
      {calcResult.data?.some((x) => SUPPLEMENTARY_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => SUPPLEMENTARY_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        SUPPLEMENTARY_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty uzupełniające
              {getSumePriceForGroup(
                calcResult.data.filter((x) => SUPPLEMENTARY_PRODUCTS.includes(x.categoryCode))
              )}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("supplementary-products")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(
                calcResult.data.filter((x) => SUPPLEMENTARY_PRODUCTS.includes(x.categoryCode))
              )}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Produkty na zamówienie */}
      {calcResult.data?.some((x) => OTHERS_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => OTHERS_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        OTHERS_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Produkty na zamówienie
              {getSumePriceForGroup(calcResult.data.filter((x) => OTHERS_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("others")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => OTHERS_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}

      {/* Robocizna */}
      {calcResult.data?.some((x) => LABOUR_PRODUCTS.includes(x.categoryCode)) && (
        <Section
          title={
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Tooltip
                title={<TooltipText>Włącz/wyłącz wszystkie produkty w grupie.</TooltipText>}
                color={PRIMARY_COLOR}
                placement="topRight"
              >
                <Switch
                  checked={calcResult.data
                    .filter((x) => LABOUR_PRODUCTS.includes(x.categoryCode))
                    .every((x) => x.active)}
                  onChange={(checked) => {
                    if (calcResult.data) {
                      for (const item of calcResult.data?.filter((x) =>
                        LABOUR_PRODUCTS.includes(x.categoryCode)
                      )) {
                        dispatch(updateActiveCalcResultItem({ id: item.id, value: checked }));
                      }
                    }
                  }}
                />
              </Tooltip>
              Robocizna
              {getSumePriceForGroup(calcResult.data.filter((x) => LABOUR_PRODUCTS.includes(x.categoryCode)))}
            </div>
          }
          extra={<Button onClick={() => setGroupDiscountSelected("labour")}>Rabat grupowy</Button>}
        >
          {calcResult.data && (
            <StyledTable
              columns={resultColumns}
              pagination={false}
              dataSource={getItemsData(calcResult.data.filter((x) => LABOUR_PRODUCTS.includes(x.categoryCode)))}
              rowKey="id"
              rowClassName={(record: any) => {
                if (record.active && record.active.props && record.active.props.checked === false) {
                  return "inactiveRow";
                }
                return "";
              }}
            />
          )}
        </Section>
      )}
    </Container>
  );
};
export default ResultStep;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledTable = styled(Table)`
  & .inactiveRow {
    background-color: #f5f5f5 !important;
    color: #ccc !important;
    opacity: 0.5;
  }
`;

const PriceRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  width: 100%;
  height: 100%;
`;

const CalcButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: center;
`;

const TooltipText = styled.div`
  text-align: center;
`;
