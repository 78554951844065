import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdditionalElement, IResultItemResponse } from "../../models/Models";

const initialState: IResultItemResponse[] = [];

export const windowsSlice = createSlice({
  name: "windows",
  initialState,
  reducers: {
    addWindow: (state, action: PayloadAction<IResultItemResponse>) => {
      let item = action.payload;

      // Sprawdzamy czy już istnieje to zwiększamy amount
      const index = state.findIndex((x) => x.productId === item.productId);

      if (index >= 0) {
        const elements = [...state];
        elements[index] = { ...elements[index], amount: elements[index].amount + item.amount };

        return [...elements];
      } else {
        // Gdy nie ma to dodajemy
        return [...state, item];
      }
    },
    setWindows: (state, action: PayloadAction<IResultItemResponse[]>) => {
      let windows = action.payload;
      return [...windows];
    },
    deleteWindow: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      return state.filter((o) => o.productId === productId);
    },
    clearWindows: (state) => {
      return initialState;
    },
    setSavedRoofWindows: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { addWindow, deleteWindow, setWindows, clearWindows, setSavedRoofWindows } = windowsSlice.actions;
export default windowsSlice.reducer;
