import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalculationRequest } from "../../models/Models";

const initialState: ICalculationRequest = { points: [], planes: [] };

// Dane wejściowe do obliczeń -> przydatne gdy zmieniamy kąt na połaciach
export const inputDataSlice = createSlice({
  name: "inputData",
  initialState,
  reducers: {
    updateInputDataInReducer: (state, action: PayloadAction<ICalculationRequest>) => {
      let data = action.payload;
      return data;
    },
    updateRoofPlaneAngleInputDataInReducer: (
      state,
      action: PayloadAction<{ x: number; y: number; angle: number }>
    ) => {
      let { x, y, angle } = action.payload;
      let toUpdate = state.points.find((o) => o.x === x && o.y === y);

      if (toUpdate) {
        // toUpdate.angle = angle;
      }
    },
    clearInputDataInReducer: (state) => {
      return initialState;
    },
    setSavedInputData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  updateInputDataInReducer,
  updateRoofPlaneAngleInputDataInReducer,
  clearInputDataInReducer,
  setSavedInputData,
} = inputDataSlice.actions;
export default inputDataSlice.reducer;
