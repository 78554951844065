import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGeneral {
  investmentId: string | null; // Jeśli null to nie ma żadnego
  reportId: string | null; // Jeśli null to nie ma żadnego
}

const initialState: IGeneral = {
  investmentId: null,
  reportId: null,
};

// Miejsce przechowywania ogólnych danych w programie
export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setInvestmentIdGeneral: (state, action: PayloadAction<string | null>) => {
      let investmentId = action.payload;
      return { ...state, investmentId: investmentId };
    },
    setReportIdGeneral: (state, action: PayloadAction<string | null>) => {
      let reportId = action.payload;
      return { ...state, reportId: reportId };
    },
    clearGeneral: (state) => {
      return initialState;
    },
    setSavedGeneral: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setInvestmentIdGeneral, setReportIdGeneral, setSavedGeneral, clearGeneral } = generalSlice.actions;
export default generalSlice.reducer;
