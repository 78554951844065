import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IAddPrincingSystem, IPrincingSystem, IUpdateActivePriceSystemRequestModel } from "../../models/Models";
import { AppDispatch } from "../store";

const initialState: IPrincingSystem[] = [];

export const princingSystemsSlice = createSlice({
  name: "princingSystems",
  initialState,
  reducers: {
    setPrincingSystemsInReducer: (state, action: PayloadAction<IPrincingSystem[]>) => {
      let list = action.payload;
      return list;
    },
    addPrincingSystemInReducer: (state, action: PayloadAction<IPrincingSystem>) => {
      let ps = action.payload;
      return [...state, ps];
    },
    updatePrincingSystemInReducer: (state, action: PayloadAction<IPrincingSystem>) => {
      let data = action.payload;

      let index = state.findIndex((o) => o.id === data.id);
      state[index] = { ...state[index], ...data };
    },
    updateActivePriceSystemInReducer: (state, action: PayloadAction<{ id: string; active: boolean }>) => {
      let { id, active } = action.payload;

      // znajdujemy indeks obiektu o podanym id
      const index = state.findIndex((item) => item.id === id);

      // jeśli taki obiekt istnieje, aktualizujemy pole active
      if (index !== -1) {
        state[index].active = active;
      }
    },
    deletePrincingSystemsByContractorIdInReducer: (state, action: PayloadAction<string>) => {
      const contractorId = action.payload;
      return state.filter((o) => o.contractorId !== contractorId);
    },
    deletePrincingSystemsByIdnReducer: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      return state.filter((o) => o.id !== id);
    },
  },
});

export const {
  setPrincingSystemsInReducer,
  addPrincingSystemInReducer,
  updateActivePriceSystemInReducer,
  deletePrincingSystemsByContractorIdInReducer,
  deletePrincingSystemsByIdnReducer,
  updatePrincingSystemInReducer,
} = princingSystemsSlice.actions;
export default princingSystemsSlice.reducer;

// API

// Add product
export const addPrincingSystem =
  (model: IAddPrincingSystem, callback: () => void) => async (dispatch: AppDispatch) => {
    try {
      const response = await client.post("/Contractor/addPrincingSystem", model);
      if (response.status === 200) {
        dispatch(addPrincingSystemInReducer(response.data));
        callback();
      }
    } catch (error) {
      // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
      console.error(error);
    }
  };

// Update active position
export const updateActivePriceSystem =
  (model: IUpdateActivePriceSystemRequestModel, callback: () => void) => async (dispatch: AppDispatch) => {
    try {
      const response = await client.put("/Contractor/updateActivePriceSystem", model);
      if (response.status === 204) {
        dispatch(updateActivePriceSystemInReducer({ id: model.priceSystemId, active: model.active }));
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
