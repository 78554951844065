import React, { FC } from "react";
import styled from "styled-components";
import { Alert, Button, Card, Checkbox, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../redux/general/actions/profileActions";
import { IProfile, IUpdateUserSettings } from "../../../models/Models";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useForm } from "antd/es/form/Form";
import { updateUserSettings } from "../../../api/ApiUser";

type Props = {
  profile: IProfile;
};

const UserSettingsCard: FC<Props> = ({ profile }) => {
  const dispatch = useAppDispatch();
  const [form] = useForm();

  const userSettings = useAppSelector((state) => state.profile.userSettings);

  const onFinish = (values: any) => {
    // const data = { fullName: values.fullName, contactEmail: values.contactEmail, phone: values.phone };
    // dispatch(updateProfile(data));
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  const getInitialValues = () => {
    return {
      gridColor: userSettings ? userSettings.gridColor : "#cacaca",
      gridSize: userSettings ? userSettings.gridSize : 1,
      gridVisible: userSettings ? userSettings.gridVisible : true,
      gridSnapping: userSettings ? userSettings.gridSnapping : false,
    };
  };

  const saveUserSettings = () => {
    const { gridColor, gridSize, gridVisible, gridSnapping } = form.getFieldsValue();
    const model: IUpdateUserSettings = {
      gridColor,
      gridSize,
      gridVisible,
      gridSnapping,
    };

    dispatch(updateUserSettings(model));
  };

  return (
    <Container
      title="Ustawienia użytkownika"
      extra={
        <Button type="primary" htmlType="submit" onClick={() => saveUserSettings()}>
          Zapisz zmiany
        </Button>
      }
    >
      {/* Podsekcja Ustawienia Siatki w rysunku */}
      <Card type="inner" title="Siatka w polu rysunkowym (ustawienia domyślne)">
        <Form
          form={form}
          initialValues={getInitialValues()}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="Kolor siatki" name="gridColor">
            <Input />
          </Form.Item>

          <Form.Item label="Rozmiar siatki [m]" name="gridSize">
            <DomaInputNumber />
          </Form.Item>

          <Form.Item label="Widocznośc siatki" name="gridVisible" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <Form.Item label="Przyciąganie do siatki" name="gridSnapping" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
};
export default UserSettingsCard;

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
