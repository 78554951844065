import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Card, Col, Form, Input, List, Radio, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../../../theme";
import DomaLogoComponent from "../../../../../../components/SVGComponents/DomaLogoComponent";
import { getBasicUrlSystemImage, getFilteredSystems, getSystemImg } from "../../../../../../helpers/Helpers";
import { IGutterSystem, IPreCoveringSystem, IRootState } from "../../../../../../models/Models";
import { useAppSelector } from "../../../../../../redux/hooks";
import SystemCard from "./SystemCard";
import SystemFilteringComponent from "./SystemFilteringComponent";

type Props = {
  selectPreCoveringSystemId: any;
  selectedPreCoveringSystemId: string | null;
};

const PreCoveringSystem: FC<Props> = ({ selectPreCoveringSystemId, selectedPreCoveringSystemId }) => {
  const preCoveringSystemsState = useAppSelector((state: IRootState) => state.preCoveringSystems) || [];
  //   const externalGutterSystems = useAppSelector((state) => state.externalGutterSystems) || [];

  const [preCoveringSystems, setPreCoveringSystems] = useState<IPreCoveringSystem[]>(preCoveringSystemsState);

  const filtersForSystems = useAppSelector((state) => state.filtersForSystems);

  const [searchResults, setSearchResults] = useState<IGutterSystem[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [view, setView] = useState<"list" | "cards">("cards");

  useEffect(() => {
    if (selectedPreCoveringSystemId) {
      const systems = [...preCoveringSystemsState].sort((a, b) => {
        if (a.id === selectedPreCoveringSystemId) return -1;
        if (b.id === selectedPreCoveringSystemId) return 1;
        return 0;
      });
      setPreCoveringSystems(systems);
    } else {
      setPreCoveringSystems(preCoveringSystemsState);
    }
  }, [preCoveringSystemsState]);

  const search = (text: string) => {
    let result: any[] = [];

    if (text.length > 0) {
      // Dzielimy wprowadzony tekst na słowa, ignorując wielkość liter
      // Używamy wyrażenia regularnego, aby podzielić tekst według spacji, przecinka, średnika lub dwukrotnego wystąpienia spacji
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      for (const p of getFilteredSystems([...preCoveringSystems], filtersForSystems.filterForPreCoveringSystem)) {
        // Sprawdzamy, czy każde słowo występuje w ciągu znaków 'name'
        const matched = inputWords.every((word) => p.name.toLowerCase().includes(word));

        if (matched) {
          result = [...result, p];
        }
      }
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <Container>
      <SystemFilteringComponent typeSystem="preCovering" />

      <Form.Item label="Znajdź system krycia wstępnego" labelCol={{ span: 24 }} style={{ marginBottom: "32px" }}>
        <SearchInput
          placeholder="Wpisz nazwę systemu krycia wstępnego..."
          onChange={(e) => {
            search(e.target.value);
            setSearchText(e.target.value);
          }}
          allowClear
        />
      </Form.Item>

      <Row style={{ display: "flex", width: "100%", justifyContent: "right" }}>
        <StyledRadioGroup style={{ marginTop: "16px" }} value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button value="list">
            <BarsOutlined />
          </Radio.Button>
          <Radio.Button value="cards">
            <AppstoreOutlined />
          </Radio.Button>
        </StyledRadioGroup>
      </Row>

      <Systems>
        {view === "cards" ? (
          <SystemList
            grid={{
              xxl: 3,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
            dataSource={
              searchText.length > 0
                ? searchResults
                : getFilteredSystems([...preCoveringSystems], filtersForSystems.filterForPreCoveringSystem)
            }
            renderItem={(item: any, index) => (
              <List.Item style={{ margin: "8px", padding: "0px" }}>
                <SystemCard
                  key={item.id}
                  system={item}
                  selectSystem={(id: string) => selectPreCoveringSystemId(id)}
                  selected={selectedPreCoveringSystemId === item.id ? true : false}
                />
              </List.Item>
            )}
            pagination={{
              pageSize: 9,
            }}
          />
        ) : (
          <List
            dataSource={
              searchText.length > 0
                ? searchResults
                : getFilteredSystems([...preCoveringSystems], filtersForSystems.filterForPreCoveringSystem)
            }
            pagination={{
              pageSize: 20,
            }}
            renderItem={(item: any, index) => (
              <ContainerListItem
                selected={selectedPreCoveringSystemId === item.id ? true : false}
                onClick={() => selectPreCoveringSystemId(item.id)}
                style={{ cursor: "pointer" }}
              >
                <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                  {item.imageUrl && item.imageUrl !== "" ? (
                    <CardImage alt={item.name} src={getSystemImg(item)} />
                  ) : (
                    <DomaLogoComponent />
                  )}
                </Col>
                <Col span={23} style={{ width: "100%", padding: "16px", marginLeft: "16px" }}>
                  <b>{item.name}</b>
                </Col>
              </ContainerListItem>
            )}
            style={{ marginTop: "16px" }}
          />
        )}
      </Systems>
    </Container>
  );
};
export default PreCoveringSystem;

const Container = styled.div``;

const SearchInput = styled(Input)``;

const Systems = styled.div``;

const SystemList = styled(List)`
  margin-top: 16px;
`;

const CardImage = styled.img`
  max-width: 75px;
`;

const ContainerListItem = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.selected ? "#4096ff" : "")};
  color: ${(props) => (props.selected ? "#ffffff !important" : "")};
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
