import { message } from "antd";
import Konva from "konva";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSO, IRSOEdge, IRSOLinkedEdges, IRSOPoint, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { EdgeType } from "../../../../../../../models/Enums";
import { addRSOLinkedEdge } from "../../../../../../../redux/roofSlopeOutline/rsoLinkedEdgesSlice";

const TOLERANCE = 10;

interface Function1HandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  close: () => void;
}

const Function2Handler: FC<Function1HandlerProps> = ({ stageRef, point0Ref, close }) => {
  const dispatch = useAppDispatch();

  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  // Potrzebny pierwszy klikniety krawędz a drugi jak jest wybrana to analizuje i konczy działanie wiec nie potrzebuje jej przechowywać
  const [firstSelectedEdge, setFirstSelectedEdge] = useState<string | null>(null); // Id pierwszej wybranej rawędzi
  const firstSelectedEdgeRef = useRef(firstSelectedEdge);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
  }, [mpLocal]);

  useEffect(() => {
    firstSelectedEdgeRef.current = firstSelectedEdge;
  }, [firstSelectedEdge]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        if (e.target.hasName("rsoEdge")) {
          const edgeId = e.target.id();

          if (firstSelectedEdgeRef.current === null) {
            setFirstSelectedEdge(edgeId);
          } else {
            if (firstSelectedEdgeRef.current.length > 0) {
              bindEdgesFunction(firstSelectedEdgeRef.current, edgeId);
              setFirstSelectedEdge(null); // Resetowanie po powiązaniu
              close();
            }
          }
        }
      } else if (e.evt.button === 2) {
        // console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      if (startPointPosition) {
        const scaledMp: RSOPoint2D = { x: startPointPosition.x, y: startPointPosition.y };
        setMpLocal(scaledMp);
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  // Funkcja powiązująca krawędzie
  const bindEdgesFunction = (edgeId1: string, edgeId2: string) => {
    console.log(`Powiązuję krawędzie: ${edgeId1} i ${edgeId2}`);

    //TODO dodać logię która sprawdza czy edgeIds sa poprawne i istnieją na liście krawędzi
    // TODo dodac logię, która sprawdza czy wybrana jedna z edges nie jest juz z czymś powiązana - jeśli tak to trzeba usunąc powiązanie
    const rsoe: IRSOLinkedEdges = {
      id: uuidv4(),
      firstEdgeId: edgeId1,
      secondEdgeId: edgeId2,
      type: EdgeType.Empty,
    };
    dispatch(addRSOLinkedEdge(rsoe));
  };

  return null;
};

export default Function2Handler;
