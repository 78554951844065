import { Button, Card } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useFirstRender } from "../../../../../../hooks/useFirstRender";
import { IProduct, IProductCategory, IRootState, ISelectOption } from "../../../../../../models/Models";
import {
  addAdditionalProductsInReducer,
  IAdditionalProduct,
} from "../../../../../../redux/calculationProcess/additionalProductsSlice";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import AdditionalProductPosition from "./AdditionalProductPosition";

type Props = {};

const AdditionalProductsSection: FC<Props> = ({}) => {
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const products = useAppSelector((state: IRootState) => state.products);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);

  const additionalProducts = useAppSelector((state: IRootState) => state.additionalProducts);

  const [additionalProductsState, setAdditionalProductsState] = useState<IAdditionalProduct[]>([]);

  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);

  // useEffect(() => {
  //   console.log("Render AdditionalProductsSection");
  // });

  useEffect(() => {
    if (firstRender && additionalProducts) {
      setAdditionalProductsState(additionalProducts);
    }
  }, [firstRender, additionalProducts]);

  useEffect(() => {
    if (!firstRender) {
      dispatch(addAdditionalProductsInReducer([...additionalProductsState]));
    }
  }, [additionalProductsState]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "supplementary-products",
            "lightning-protection-systems",
            "roof-walkways-and-snow-guards",
            "roofing-accessories",
            "fasteners",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  return (
    <Container
      title="Elementy dodatkowe na dach"
      extra={
        <Button
          type="primary"
          onClick={() => {
            const newAdditionalProduct: IAdditionalProduct = {
              id: uuidv4(),
              productId: "",
              amount: 0,
            };
            setAdditionalProductsState([newAdditionalProduct, ...additionalProductsState]);
            dispatch(invalidateCalculation());
          }}
        >
          Dodaj produkt
        </Button>
      }
    >
      {additionalProductsState.map((additionalProduct, index) => {
        return (
          <AdditionalProductPosition
            key={additionalProduct.id}
            addition={additionalProduct}
            readyOptions={productsSelectForAdditions}
            products={products}
            productCategories={productCategories}
            updateAddition={(addition) => {
              const newAdditions = [...additionalProductsState];
              const index = newAdditions.findIndex((x) => x.id === addition.id);
              if (index >= 0) {
                newAdditions[index] = addition;
                setAdditionalProductsState(newAdditions);
                dispatch(invalidateCalculation());
              }
            }}
            deleteAddition={(addition) => {
              const newAdditions = [...additionalProductsState].filter((x) => x.id !== addition.id);
              setAdditionalProductsState(newAdditions);
              dispatch(invalidateCalculation());
            }}
          />
        );
      })}
    </Container>
  );
};
export default AdditionalProductsSection;

const Container = styled(Card)``;
