import { Button } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import GutterSystemDetails from "./components/GutterSystemDetails";

type Props = {};

const AddGutterSystemPage: FC<Props> = () => {
  return (
    <Container>
      <PageHeader
        title={"Dodaj nowy system rynnowy"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="primary" form="gutterSystemDetailsForm" key="submit" htmlType="submit">
              Dodaj system
            </Button>
          </div>
        }
      />

      <Content>
        <GutterSystemDetails system={null} />
      </Content>
    </Container>
  );
};
export default AddGutterSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
