import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";

export interface IAdditionalProduct {
  id: string;
  productId: string | null;
  amount: number;
}

const initialState: IAdditionalProduct[] = [];

export const additionalProductsSlice = createSlice({
  name: "additionalProducts",
  initialState,
  reducers: {
    addAdditionalProductsInReducer: (state, action: PayloadAction<IAdditionalProduct[]>) => {
      let products = action.payload;
      return products;
    },
    addAdditionalProductInReducer: (state, action: PayloadAction<IAdditionalProduct>) => {
      let product = action.payload;
      return [...state, product];
    },
    updateAdditionalProductInReducer: (state, action: PayloadAction<IAdditionalProduct>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);
      state[index] = { ...state[index], ...update };
    },
    deleteAdditionalProductInReducer: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      return state.filter((o) => o.id !== productId);
    },
    clearAdditionalProducts: (state) => {
      return initialState;
    },
    setSavedAdditionalProducts: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addAdditionalProductInReducer,
  addAdditionalProductsInReducer,
  clearAdditionalProducts,
  deleteAdditionalProductInReducer,
  setSavedAdditionalProducts,
  updateAdditionalProductInReducer,
} = additionalProductsSlice.actions;
export default additionalProductsSlice.reducer;
