import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, message, Radio, Row, Switch } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { useFirstRender } from "../../../hooks/useFirstRender";
import { IAdditionalReportPage } from "../CompanyPage";
import AdditionalPageImage from "./AdditionalPageImage";
import { PRIMARY_COLOR } from "../../../theme";

type Props = {
  additionalReportPage: IAdditionalReportPage;
  updatePosition: (pos: IAdditionalReportPage) => void;
  deletePosition: (pos: IAdditionalReportPage) => void;
};

const AdditionalReportPagePosition: FC<Props> = ({ additionalReportPage, updatePosition, deletePosition }) => {
  const firstRender = useFirstRender();

  const [title, setTitle] = React.useState<string>(additionalReportPage.title);
  const [imageUrl, setImageUrl] = React.useState<string>(additionalReportPage.imageUrl);
  const [active, setActive] = React.useState<boolean>(additionalReportPage.active);
  const [order, setOrder] = React.useState<number>(additionalReportPage.order);

  useEffect(() => {
    if (!firstRender) {
      updatePosition({ id: additionalReportPage.id, title, imageUrl, active, order });
    }
  }, [title, imageUrl, active, order]);

  // W S3 nie usuwamy zdjecia
  const removeImage = () => {
    setImageUrl("");
  };

  return (
    <Row gutter={16} style={{ width: "100%", margin: "0px", padding: "0px" }}>
      <Col span={10} style={{ paddingRight: "16px" }}>
        <Form.Item
          label="Czy aktywne i dostępne w raporcie?"
          labelCol={{ span: 24 }}
          //   style={{ display: "flex", alignItems: "center" }}
        >
          <StyledRadioGroup
            value={active}
            onChange={(e) => {
              setActive(e.target.value);
            }}
          >
            <Radio.Button value={true}>TAK</Radio.Button>
            <Radio.Button value={false}>NIE</Radio.Button>
          </StyledRadioGroup>
          {/* <Switch checked={active} onChange={(e) => setActive(e)} /> */}
        </Form.Item>

        <Form.Item label="Priorytet wyświetlania w raporcie" labelCol={{ span: 24 }}>
          <DomaInputNumber value={order} onChange={(e) => setOrder(e as number)} min={0} />
        </Form.Item>

        <Form.Item label="Czy chcesz usunąć całą stronę? Nie będzie można jej przywrócić" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              deletePosition(additionalReportPage);
            }}
          >
            Usuń stronę
          </Button>
        </Form.Item>

        <Form.Item label="Czy chcesz usunąć załączony obraz na stronę?" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              removeImage();
            }}
          >
            Usuń zdjęcie
          </Button>
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item label="Tytuł strony" labelCol={{ span: 24 }}>
              <Input value={title} onChange={(e) => setTitle(e.target.value)} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={14}>
        <AdditionalPageImage
          additionalReportPageId={additionalReportPage.id}
          imageFileName={imageUrl}
          setImageFileName={(name) => {
            setImageUrl(name);
          }}
        />
      </Col>
    </Row>
  );
};
export default AdditionalReportPagePosition;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
