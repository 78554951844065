import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Card, Form, Input, Segmented, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { IRootState, ISteelSystem, ITileSystem } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getCompanyNameById, getProductColorNameById, getProductModelNameById } from "../../../helpers/Helpers";
import { useEffectOnce } from "react-use";
import { useFirstRender } from "../../../hooks/useFirstRender";
import { updateLastRoofSystemTab } from "../../../redux/calculationProcess/otherAppStatusesSlice";
import { debounce } from "lodash";
import { PRIMARY_COLOR } from "../../../theme";

const columns = [
  {
    title: "Nazwa systemu",
    dataIndex: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "Producent",
    dataIndex: "companyName",
    width: 100,
    align: "center" as const,
    sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
    render: (companyName: string) =>
      companyName.length > 0 && (
        <Tag color={PRIMARY_COLOR} style={{ textAlign: "center", width: "100%" }}>
          {companyName}
        </Tag>
      ),
  },
  {
    title: "Model",
    dataIndex: "productModelName",
    align: "center" as const,
    width: 100,
    sorter: (a: any, b: any) => a.productModelName.localeCompare(b.productModelName),
    render: (productModelName: string) =>
      productModelName && (
        <Tag color="#87d068" style={{ textAlign: "center", width: "100%" }}>
          {productModelName}
        </Tag>
      ),
  },
  {
    title: "Kolor",
    dataIndex: "productColorName",
    align: "center" as const,
    width: 100,
    sorter: (a: any, b: any) => a.productColorName.localeCompare(b.productColorName),
    render: (productColorName: string) =>
      productColorName && (
        <Tag color={PRIMARY_COLOR} style={{ textAlign: "center", width: "100%" }}>
          {productColorName}
        </Tag>
      ),
  },
  {
    title: "Akcja",
    dataIndex: "action",
    align: "center" as const,
    width: 100,
  },
];

const RoofSystemsPage: FC = () => {
  const navigate = useNavigate();
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();

  const [activeSegment, setActiveSegment] = useState<string>("tiles");

  const companies = useAppSelector((state: IRootState) => state.companies);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const productColors = useAppSelector((state: IRootState) => state.productColors);

  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems);
  const steelSystems = useAppSelector((state: IRootState) => state.steelSystems);

  const otherAppStatuses = useAppSelector((state: IRootState) => state.otherAppStatuses);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<ITileSystem[] | ISteelSystem[]>([]);

  useEffect(() => {
    if (firstRender && otherAppStatuses.lastRoofSystemTab) {
      if (activeSegment !== otherAppStatuses.lastRoofSystemTab) {
        setActiveSegment(otherAppStatuses.lastRoofSystemTab);
      }
    }
  }, [firstRender, otherAppStatuses]);

  // WYSZUKIWARKA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // Tworzymy debounced wersję funkcji search
  const debouncedSearch = useCallback(
    debounce((text) => {
      search(text);
    }, 500),
    [activeSegment]
  );

  // Efekt, który obserwuje zmiany searchTerm i wywołuje debouncedSearch
  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch, tileSystems, steelSystems]);

  const search = (text: string) => {
    let result: ITileSystem[] | ISteelSystem[] = [];

    if (text.length > 0) {
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      if (activeSegment === "tiles") {
        result = tileSystems.filter((system) => {
          const systemName = system.name.toLowerCase();
          const comapnyName = getCompanyNameById(system.companyId as string, companies).toLowerCase();
          const productModelName = getProductModelNameById(
            system.productModelId as string,
            productModels
          ).toLowerCase();
          const productColorName = getProductColorNameById(
            system.productColorId as string,
            productColors
          ).toLowerCase();

          return (
            inputWords.every((word) => systemName.includes(word)) ||
            inputWords.every((word) => comapnyName.includes(word)) ||
            inputWords.every((word) => productModelName.includes(word)) ||
            inputWords.every((word) => productColorName.includes(word))
          );
        });
      } else {
        result = steelSystems.filter((system) => {
          const systemName = system.name.toLowerCase();
          const companyName = getCompanyNameById(system.companyId as string, companies).toLowerCase();
          const productModelName = getProductModelNameById(
            system.productModelId as string,
            productModels
          ).toLowerCase();
          const productColorName = getProductColorNameById(
            system.productColorId as string,
            productColors
          ).toLowerCase();

          return inputWords.every(
            (word) =>
              systemName.includes(word) ||
              companyName.includes(word) ||
              productModelName.includes(word) ||
              productColorName.includes(word)
          );
        });
      }

      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const getTileSystemsData = (tileSystems: ITileSystem[]) => {
    const data: {
      key: string;
      name: string;
      companyName: string;
      productModelName: string;
      productColorName: string;
      action: JSX.Element;
    }[] = [];

    if (tileSystems) {
      const sortedTileSystems = [...tileSystems].sort((a, b) => a.name.localeCompare(b.name));
      for (const s of sortedTileSystems) {
        data.push({
          key: s.id,
          name: s.name,
          companyName: getCompanyNameById(s.companyId as string, companies),
          productModelName: getProductModelNameById(s.productModelId as string, productModels),
          productColorName: getProductColorNameById(s.productColorId as string, productColors),
          action: <Button onClick={() => navigate(`/systemy-dachowe/dachowka/${s.id}/edytuj`)}>Edytuj</Button>,
        });
      }
    }

    return data;
  };

  const getSteelSystemsData = (steelSystems: ISteelSystem[]) => {
    const data: {
      key: string;
      name: string;
      companyName: string;
      productModelName: string;
      productColorName: string;
      action: JSX.Element;
    }[] = [];

    if (steelSystems) {
      const sortedSteelSystems = [...steelSystems].sort((a, b) => a.name.localeCompare(b.name));
      for (const s of sortedSteelSystems) {
        data.push({
          key: s.id,
          name: s.name,
          companyName: getCompanyNameById(s.companyId as string, companies),
          productModelName: getProductModelNameById(s.productModelId as string, productModels),
          productColorName: getProductColorNameById(s.productColorId as string, productColors),
          action: (
            <Button onClick={() => navigate(`/systemy-dachowe/blachodachowka/${s.id}/edytuj`)}>Edytuj</Button>
          ),
        });
      }
    }

    return data;
  };

  const getTileSystemsCount = (tileSystems: ITileSystem[]) => {
    return tileSystems ? tileSystems.length : 0;
  };

  const getSteelSystemsCount = (steelSystems: ISteelSystem[]) => {
    return steelSystems ? steelSystems.length : 0;
  };

  return (
    <Container>
      <Segmented
        block
        options={[
          {
            label: (
              <span>
                Systemy pokryć dachowych z <b>dachówką</b>
              </span>
            ),
            value: "tiles",
          },
          {
            label: (
              <span>
                Systemy pokryć dachowych z <b>blachą dachową</b>
              </span>
            ),
            value: "steel",
          },
        ]}
        value={activeSegment}
        onChange={(v) => {
          setActiveSegment(v.valueOf() as string);
          dispatch(updateLastRoofSystemTab(v.valueOf() as "tiles" | "steel"));

          setSearchTerm("");
        }}
        size="large"
      />

      {activeSegment === "tiles" ? (
        <Section
          title={
            <div>
              Systemy pokrycia dachowego dachówką
              <Tag style={{ padding: "4px 8px 4px 8px", marginLeft: "8px" }}>
                {getTileSystemsCount(searchResults.length > 0 ? (searchResults as ITileSystem[]) : tileSystems)}{" "}
                szt.
              </Tag>
            </div>
          }
          extra={
            <Button type="primary" onClick={() => navigate(`/systemy-dachowe/dachowka/dodaj`)}>
              Dodaj system z dachówką
            </Button>
          }
        >
          <div style={{ marginBottom: "16px" }}>
            <Form.Item
              label="Znajdź system pokrycia dachowego"
              labelCol={{ span: 24 }}
              style={{ marginBottom: "32px" }}
            >
              <SearchInput
                value={searchTerm}
                placeholder="Wpisz nazwę systemu, producenta, model lub kolor..."
                onChange={(e) => setSearchTerm(e.target.value)}
                allowClear
              />
            </Form.Item>

            {searchTerm.length > 0 && searchResults.length === 0 && (
              <Alert
                showIcon
                type="error"
                message="Brak systemów spełniających kryteria wyszukiwania!"
                style={{ marginTop: "16px" }}
              />
            )}
          </div>
          <Table
            columns={columns}
            dataSource={getTileSystemsData(
              searchResults.length > 0 ? (searchResults as ITileSystem[]) : tileSystems
            )}
            pagination={{ defaultPageSize: 10 }}
          />
        </Section>
      ) : (
        <Section
          title={
            <div>
              Systemy pokrycia dachowego blachą
              <Tag style={{ padding: "4px 8px 4px 8px", marginLeft: "8px" }}>
                {getSteelSystemsCount(searchResults.length > 0 ? (searchResults as ISteelSystem[]) : steelSystems)}{" "}
                szt.
              </Tag>
            </div>
          }
          extra={
            <Button type="primary" onClick={() => navigate(`/systemy-dachowe/blachodachowka/dodaj`)}>
              Dodaj system z blachą dachową
            </Button>
          }
        >
          <div style={{ marginBottom: "16px" }}>
            <Form.Item
              label="Znajdź system pokrycia dachowego"
              labelCol={{ span: 24 }}
              style={{ marginBottom: "32px" }}
            >
              <SearchInput
                value={searchTerm}
                placeholder="Wpisz nazwę systemu, producenta, model lub kolor..."
                onChange={(e) => setSearchTerm(e.target.value)}
                allowClear
              />
            </Form.Item>

            {searchTerm.length > 0 && searchResults.length === 0 && (
              <Alert
                showIcon
                type="error"
                message="Brak systemów spełniających kryteria wyszukiwania!"
                style={{ marginTop: "16px" }}
              />
            )}
          </div>

          <Table
            columns={columns}
            dataSource={getSteelSystemsData(
              searchResults.length > 0 ? (searchResults as ISteelSystem[]) : steelSystems
            )}
            pagination={{ defaultPageSize: 10 }}
          />
        </Section>
      )}
    </Container>
  );
};

export default RoofSystemsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)``;

const SearchInput = styled(Input)``;
