import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type GroupDiscountType =
  | "basicProducts"
  | "windowCollarsAndAccessories"
  | "guttering"
  | "windows"
  | "wood"
  | "others"
  | "supplementaryProducts"
  | "lightningProtectionSystems"
  | "thermalInsulation"
  | "roofWalkwaysAndSnowGuards"
  | "roofingAccessories"
  | "fasteners"
  | "preliminaryCovering"
  | "labour";

export interface IGroupDiscountsState {
  basicProductsDiscountValue: number;
  basicProductsDiscountType: "%" | "price";
  basicProductsDiscountPart: "nett" | "gross";

  gutteringDiscountValue: number;
  gutteringDiscountType: "%" | "price";
  gutteringDiscountPart: "nett" | "gross";

  windowsDiscountValue: number;
  windowsDiscountType: "%" | "price";
  windowsDiscountPart: "nett" | "gross";

  woodDiscountValue: number;
  woodDiscountType: "%" | "price";
  woodDiscountPart: "nett" | "gross";

  othersDiscountValue: number;
  othersDiscountType: "%" | "price";
  othersDiscountPart: "nett" | "gross";

  supplementaryProductsDiscountValue: number;
  supplementaryProductsDiscountType: "%" | "price";
  supplementaryProductsDiscountPart: "nett" | "gross";

  lightningProtectionSystemsDiscountValue: number;
  lightningProtectionSystemsDiscountType: "%" | "price";
  lightningProtectionSystemsDiscountPart: "nett" | "gross";

  thermalInsulationDiscountValue: number;
  thermalInsulationDiscountType: "%" | "price";
  thermalInsulationDiscountPart: "nett" | "gross";

  roofWalkwaysAndSnowGuardsDiscountValue: number;
  roofWalkwaysAndSnowGuardsDiscountType: "%" | "price";
  roofWalkwaysAndSnowGuardsDiscountPart: "nett" | "gross";

  roofingAccessoriesDiscountValue: number;
  roofingAccessoriesDiscountType: "%" | "price";
  roofingAccessoriesDiscountPart: "nett" | "gross";

  fastenersDiscountValue: number;
  fastenersDiscountType: "%" | "price";
  fastenersDiscountPart: "nett" | "gross";

  preliminaryCoveringDiscountValue: number;
  preliminaryCoveringDiscountType: "%" | "price";
  preliminaryCoveringDiscountPart: "nett" | "gross";

  labourDiscountValue: number;
  labourDiscountType: "%" | "price";
  labourDiscountPart: "nett" | "gross";
}

const initialState: IGroupDiscountsState = {
  basicProductsDiscountValue: 0,
  basicProductsDiscountType: "%",
  basicProductsDiscountPart: "nett",

  gutteringDiscountValue: 0,
  gutteringDiscountType: "%",
  gutteringDiscountPart: "nett",

  windowsDiscountValue: 0,
  windowsDiscountType: "%",
  windowsDiscountPart: "nett",

  woodDiscountValue: 0,
  woodDiscountType: "%",
  woodDiscountPart: "nett",

  othersDiscountValue: 0,
  othersDiscountType: "%",
  othersDiscountPart: "nett",

  supplementaryProductsDiscountValue: 0,
  supplementaryProductsDiscountType: "%",
  supplementaryProductsDiscountPart: "nett",

  lightningProtectionSystemsDiscountValue: 0,
  lightningProtectionSystemsDiscountType: "%",
  lightningProtectionSystemsDiscountPart: "nett",

  thermalInsulationDiscountValue: 0,
  thermalInsulationDiscountType: "%",
  thermalInsulationDiscountPart: "nett",

  roofWalkwaysAndSnowGuardsDiscountValue: 0,
  roofWalkwaysAndSnowGuardsDiscountType: "%",
  roofWalkwaysAndSnowGuardsDiscountPart: "nett",

  roofingAccessoriesDiscountValue: 0,
  roofingAccessoriesDiscountType: "%",
  roofingAccessoriesDiscountPart: "nett",

  fastenersDiscountValue: 0,
  fastenersDiscountType: "%",
  fastenersDiscountPart: "nett",

  preliminaryCoveringDiscountValue: 0,
  preliminaryCoveringDiscountType: "%",
  preliminaryCoveringDiscountPart: "nett",

  labourDiscountValue: 0,
  labourDiscountType: "%",
  labourDiscountPart: "nett",
};

export const groupDiscountsSlice = createSlice({
  name: "groupDiscounts",
  initialState,
  reducers: {
    setGroupDiscounts: (state, action: PayloadAction<IGroupDiscountsState>) => {
      return action.payload;
    },
    updateGroupDiscountByType: (
      state,
      action: PayloadAction<{
        typeGroup: GroupDiscountType;
        discountValue: number;
        discountType: "%" | "price";
        discountPart: "nett" | "gross";
      }>
    ) => {
      const { discountPart, discountType, discountValue, typeGroup } = action.payload;
      return {
        ...state,
        [typeGroup.toString() + "DiscountValue"]: discountValue,
        [typeGroup.toString() + "DiscountType"]: discountType,
        [typeGroup.toString() + "DiscountPart"]: discountPart,
      };
    },
    clearGroupDiscounts: (state) => {
      return initialState;
    },
    setSavedGroupDiscounts: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setGroupDiscounts, updateGroupDiscountByType, clearGroupDiscounts, setSavedGroupDiscounts } =
  groupDiscountsSlice.actions;
export default groupDiscountsSlice.reducer;
