import { DeleteFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import Konva from "konva";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import { Circle, Group, Line, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import { IRootState } from "../../../../models/Models";
import { invalidateCalculation } from "../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteGutteringElement, IGutteringElement } from "../../../../redux/roofs/gutteringElementsSlice";

type Props = {
  element: IGutteringElement | null;
  typeElement:
    | "gutterDrainPipe"
    | "gutterInternalCorner"
    | "gutterExternalCorner"
    | "gutterEndLeft"
    | "gutterEndRight";
  zoomScale: number;
};

export const getGutterDrainPipeIcon = (x: number, y: number, scale: number, toDelete: boolean) => {
  return <Circle radius={8} fill={"blue"} />;
};

export const getGutterInternalCornerIcon = (x: number, y: number, scale: number) => {
  const l = 15;

  const points = [0, 0, 0, l, 0.2 * l, l, 0.2 * l, 0.2 * l, l, 0.2 * l, l, 0];
  const points2 = [0.2 * l, l, 0.2 * l, 0.2 * l, l, 0.2 * l, l, 0.6 * l, 0.6 * l, 0.6 * l, 0.6 * l, l];
  const points3 = [0.6 * l, l, 0.6 * l, 0.6 * l, l, 0.6 * l, l, l];
  return (
    <Group>
      <Line x={x} y={y} points={points} stroke={"#9e9e9e"} fill="#9e9e9e" strokeWidth={1} closed={true} />
      <Line x={x} y={y} points={points3} stroke={"transparent"} fill="transparent" strokeWidth={1} closed={true} />
      <Line x={x} y={y} points={points2} stroke={"#1100fa"} fill="#7167ff" strokeWidth={1} closed={true} />
    </Group>
  );
};

export const getGutterExternalCornerIcon = (x: number, y: number, scale: number) => {
  const l = 15;

  const points = [0, 0, 0, l, 0.4 * l, l, 0.4 * l, 0.4 * l, l, 0.4 * l, l, 0];
  const points2 = [0.4 * l, l, 0.4 * l, 0.4 * l, l, 0.4 * l, l, l];
  return (
    <Group>
      <Line x={x} y={y} points={points2} stroke={"#9e9e9e"} fill="#9e9e9e" strokeWidth={1} closed={true} />
      <Line x={x} y={y} points={points} stroke={"#1100fa"} fill="#7167ff" strokeWidth={1} closed={true} />
    </Group>
  );
};

export const getGutterEndLeftIcon = (x: number, y: number, scale: number) => {
  const l = 15;

  const points = [0, 0, 0, l, 0.5 * l, l, 0.5 * l, 0];
  const points2 = [0.5 * l, 0, 0.5 * l, l, l, l, l, 0];
  return (
    <Group>
      <Line x={x} y={y} points={points2} stroke={"#9e9e9e"} fill="#9e9e9e" strokeWidth={1} closed={true} />
      <Line x={x} y={y} points={points} stroke={"#1100fa"} fill="#7167ff" strokeWidth={1} closed={true} />
    </Group>
  );
};

export const getGutterEndRightIcon = (x: number, y: number, scale: number) => {
  const l = 15;

  const points = [0, 0, 0, l, 0.5 * l, l, 0.5 * l, 0];
  const points2 = [0.5 * l, 0, 0.5 * l, l, l, l, l, 0];
  return (
    <Group>
      <Line x={x} y={y} points={points} stroke={"#9e9e9e"} fill="#9e9e9e" strokeWidth={1} closed={true} />
      <Line x={x} y={y} points={points2} stroke={"#1100fa"} fill="#7167ff" strokeWidth={1} closed={true} />
    </Group>
  );
};

const GutteringElement: FC<Props> = ({ element, typeElement, zoomScale }) => {
  const dispatch = useAppDispatch();

  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  const handleMouseEnter = (e: any) => {
    setShowDeleteIcon(true);
    e.target.getStage().container().style.cursor = "pointer";
    e.target.stroke("red");
    e.target.draw();
  };

  const handleMouseLeave = (e: any) => {
    setShowDeleteIcon(false);
    e.target.getStage().container().style.cursor = "default";
    e.target.stroke("transparent");
    e.target.draw();
  };

  return (
    <Group x={element ? element.x : 10} y={element ? element.y : 10}>
      {typeElement === "gutterDrainPipe" && getGutterDrainPipeIcon(0, 0, zoomScale, showDeleteIcon)}
      {typeElement === "gutterInternalCorner" && getGutterInternalCornerIcon(0, 0, zoomScale)}
      {typeElement === "gutterExternalCorner" && getGutterExternalCornerIcon(0, 0, zoomScale)}
      {typeElement === "gutterEndLeft" && getGutterEndLeftIcon(0, 0, zoomScale)}
      {typeElement === "gutterEndRight" && getGutterEndRightIcon(0, 0, zoomScale)}

      {typeElement === "gutterDrainPipe" && element !== null && (
        <Rect // Dodajemy prostokąt jako tło
          x={-10}
          y={-10}
          width={20}
          height={20}
          stroke={showDeleteIcon ? "red" : "transparent"}
          strokeWidth={1}
          fill="transparent"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={() => {
            if (showDeleteIcon === true) {
              dispatch(deleteGutteringElement({ id: element?.id as string, type: typeElement }));
              dispatch(invalidateCalculation());
            }
          }}
        />
      )}

      {typeElement !== "gutterDrainPipe" && element !== null && (
        <Rect // Dodajemy prostokąt jako tło
          x={-2}
          y={-2}
          width={20}
          height={20}
          stroke={showDeleteIcon ? "red" : "transparent"}
          strokeWidth={1}
          fill="transparent"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={() => {
            if (showDeleteIcon === true) {
              dispatch(deleteGutteringElement({ id: element?.id as string, type: typeElement }));
              dispatch(invalidateCalculation());
            }
          }}
        />
      )}

      {showDeleteIcon && element !== null && (
        <Group x={typeElement === "gutterDrainPipe" ? 12 : 20} y={typeElement === "gutterDrainPipe" ? -10 : -3}>
          <Rect x={0} y={0} width={20} height={20} />

          <Html>
            <DeleteFilled style={{ color: "red" }} />
          </Html>
        </Group>
      )}
    </Group>
  );
};

export default memo(GutteringElement);
