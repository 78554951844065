import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { ICompany, IReportOffer } from "../../models/Models";
import { AppDispatch } from "../store";

export interface IDomaModel {
  id: string;
  name: string;
}

export interface IDomaProduct {
  id: string;
  name: string;
  companyId: string;
  domaModelId: string;
  domaModel: IDomaModel;
  productCategoryId: string;
  productColorId: string;
}

export interface IDomaCompanyWithProducts {
  id: string;
  companyName: string; // Nazwa firmy
  productsCount: number;
  models: string[]; // Nazwy modeli
}

export interface IImportedInfo {
  companiesWithProducts: IDomaCompanyWithProducts[];
  loading: boolean;
}

const initialState: IImportedInfo = { companiesWithProducts: [], loading: false };

export const importedInfoSlice = createSlice({
  name: "importedInfo",
  initialState,
  reducers: {
    setCompaniesWithProductsImportedInfoReducer: (state, action: PayloadAction<IDomaCompanyWithProducts[]>) => {
      const elements = action.payload;
      return { ...state, companiesWithProducts: elements };
    },
    setLoadingImportedInfoReducer: (state, action: PayloadAction<boolean>) => {
      const loading = action.payload;
      return { ...state, loading: loading };
    },
    clearImportedInfoReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setCompaniesWithProductsImportedInfoReducer,
  setLoadingImportedInfoReducer,
  clearImportedInfoReducer,
} = importedInfoSlice.actions;
export default importedInfoSlice.reducer;

// *********************************************************************************************************************
// API
// *********************************************************************************************************************

// Pobierz info o producentach i porduktach w DOMA
export const getCompaniesWithProductsInfo = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Import/getCompaniesWithProductsInfo");
    if (response.status === 200) {
      dispatch(setCompaniesWithProductsImportedInfoReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

// Importuj produkty i producentów wybranych do hurtowni
export const importCompaniesWithProducts = (companyIds: string[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoadingImportedInfoReducer(true));
    const response = await client.post("/Import/importCompaniesWithProducts", { domaCompanyIds: companyIds });
    if (response.status === 200) {
      dispatch(setLoadingImportedInfoReducer(false));
      window.location.reload();
    }
  } catch (error) {
    dispatch(setLoadingImportedInfoReducer(false));
  }
};
