import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IFasteningSystem, IRootState } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { deleteFasteningSystem } from "../../../redux/roofSystems/fasteningSystemsSlice";
import FasteningSystemDetails from "./FasteningSystemDetails";

type Props = {};

const EditFasteningSystemPage: FC<Props> = () => {
  const { fasteningSystemId } = useParams();
  const [fasteningSystem, setFasteningSystem] = useState<IFasteningSystem | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const fasteningSystems = useAppSelector((state: IRootState) => state.fasteningSystems);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteFasteningSystem")
  );

  useEffect(() => {
    const s = fasteningSystems.find((x) => x.id === fasteningSystemId);
    if (s) {
      setFasteningSystem(s);
    }
  }, [fasteningSystem]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteFasteningSystem"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${fasteningSystem?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && fasteningSystem && (
              <Button danger onClick={() => dispatch(deleteFasteningSystem(fasteningSystem))}>
                Usuń
              </Button>
            )}
            <Button type="primary" form="fasteningSystemDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy system
            </Button>
          </div>
        }
      />

      <Content>
        {fasteningSystem && (
          <FasteningSystemDetails
            system={fasteningSystem}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};
export default EditFasteningSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
