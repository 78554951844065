import React, { CSSProperties, FC, Key, memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  CollapseProps,
  Divider,
  Form,
  Input,
  Popconfirm,
  Progress,
  Row,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IStateToSave } from "../../../redux/reduxHelper";
import { setSavedRoofCalcData } from "../../../redux/roofs/calcDataSlice";
import { setSavedRoofPoints } from "../../../redux/roofs/roofPointsSlice";
import { setSavedRoofPlanes } from "../../../redux/roofs/roofPlanesSlice";
import { setSavedRoofEdges } from "../../../redux/roofs/roofEdgesSlice";
import { setSavedRoofHoles } from "../../../redux/roofs/roofHolesSlice";
import { clearData } from "../../../api/ApiRoof";
import { setSavedRoofAdditionalElements } from "../../../redux/calculationProcess/additionalElementsSlice";
import { setSavedRoofCalcMaterialsData } from "../../../redux/calculationProcess/calcMaterialsDataSlice";
import { setSavedRoofCalcResults } from "../../../redux/calculationProcess/calcResultSlice";
import { setSavedDrawChimneyProcess } from "../../../redux/chimneys/drawChimneyProcessSlice";
import { setSavedDrawingScale } from "../../../redux/roofs/drawingScaleSlice";
import { setSavedGeneral } from "../../../redux/general/generalSlice";
import { setSavedGeneralRoofData } from "../../../redux/roofs/generalRoofDataSlice";
import { setSavedInputData } from "../../../redux/roofs/inputDataSlice";
import { setSavedOutline } from "../../../redux/drawing/outlineSlice";
import {
  setSavedRoofReport,
  updateStatusReport,
  updateTypeReport,
} from "../../../redux/calculationProcess/reportSlice";
import { setSavedRoofDrawingStore } from "../../../redux/roofs/roofDrawingStoreSlice";
import { setSavedGutteringElements } from "../../../redux/roofs/gutteringElementsSlice";
import { setSavedRoofProcessFunctionsStatus } from "../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { setSavedRoofProcessStatus } from "../../../redux/drawing/roofProcessStatusSlice";
import { setSavedRoofContourNumber } from "../../../redux/roofs/roofContourNumberSlice";
import { setSavedRoofSlopes } from "../../../redux/roofMetal/roofSlopesSlice";
import { setSavedMetalSheets } from "../../../redux/roofMetal/metalSheetsSlice";
import { setSavedRSOLinkedEdges } from "../../../redux/roofSlopeOutline/rsoLinkedEdgesSlice";
import { setSavedRSOPoints } from "../../../redux/roofSlopeOutline/rsoPointsSlice";
import { setSavedRSOPlanes } from "../../../redux/roofSlopeOutline/rsoPlanesSlice";
import { setSavedRSOEdges } from "../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { setSavedRSOHoles } from "../../../redux/roofSlopeOutline/rsoHolesSlice";
import { setSavedOutlines } from "../../../redux/drawing/savedOutlinesSlice";
import { setSavedRoofAccessoriesElements } from "../../../redux/roofs/roofAccessoriesSlice";
import { setSavedRoofWindowHoles } from "../../../redux/calculationProcess/windowHolesSlice";
import { setSavedGeometryData } from "../../../redux/roofs/geometryDataSlice";
import { setSavedTemplateImage } from "../../../redux/roofs/templateImageSlice";
import { setSavedGroupDiscounts } from "../../../redux/calculationProcess/groupDiscountsSlice";
import { setSavedCurrencyValue } from "../../../redux/general/currencyValueSlice";
import InvestmentsComponent, { IInvestmentTable } from "./components/InvestmentsComponent";
import { setSavedFiltersForSystems } from "../../../redux/calculationProcess/filtersForSystemsSlice";
import { setSavedAdditionalProducts } from "../../../redux/calculationProcess/additionalProductsSlice";
import { setSavedOtherAppStatuses } from "../../../redux/calculationProcess/otherAppStatusesSlice";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusCircleTwoTone,
  NotificationOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import PageHeader from "../../../layout/components/PageHeader";
import { IReportOffer, IUser } from "../../../models/Models";
import client from "../../../api/ApiClient";
import { deleteReport, updateReportDataInReducer, updateUserReport } from "../../../redux/general/reportsSlice";
import { useNavigate } from "react-router-dom";
import { deleteInvestment, updateInvestmentActivity } from "../../../redux/general/investmentsSlice";
import { is } from "immer/dist/internal";
import { type } from "os";
import { debounce, size } from "lodash";
import { setSavedResponsesApi } from "../../../redux/general/responsesApiSlice";
import { PRIMARY_COLOR } from "../../../theme";
import {
  addSavedAdditionalProductReducer,
  setSavedAdditionalProductsReducer,
} from "../../../redux/calculationProcess/savedAdditionalProducts";
const { Panel } = Collapse;

// Wczytywanie zapisanego projektu na serwerze
export const dispatchSelectedState = (savedState: IStateToSave, dispatch: any) => {
  // Czyszczenie store
  dispatch(clearData());

  // console.log("savedState.templateImage", savedState.templateImage);

  dispatch(setSavedRoofCalcResults(savedState.calcResult));
  dispatch(setSavedGeneral(savedState.general));
  dispatch(setSavedOutline(savedState.outline));
  dispatch(setSavedRoofReport({ ...savedState.report, generated: true }));
  dispatch(setSavedRoofCalcMaterialsData(savedState.calcMaterialsData));
  dispatch(setSavedRoofAdditionalElements(savedState.additionalElements));
  dispatch(setSavedTemplateImage(savedState.templateImage));
  dispatch(setSavedDrawingScale(savedState.drawingScale));
  dispatch(setSavedRoofCalcData(savedState.calcData));
  dispatch(setSavedInputData(savedState.inputData));
  dispatch(setSavedRoofDrawingStore(savedState.roofDrawingStore));
  dispatch(setSavedGeneralRoofData(savedState.generalRoofData));
  dispatch(setSavedRoofPoints(savedState.roofPoints));
  dispatch(setSavedRoofPlanes(savedState.roofPlanes));
  dispatch(setSavedRoofEdges(savedState.roofEdges));
  dispatch(setSavedRoofHoles(savedState.roofHoles));
  dispatch(setSavedDrawChimneyProcess(savedState.drawChimneyProcess));
  dispatch(setSavedGutteringElements(savedState.gutteringElements));
  dispatch(setSavedRoofContourNumber(savedState.roofContourNumber));
  dispatch(setSavedRoofSlopes(savedState.roofSlopes));
  dispatch(setSavedMetalSheets(savedState.metalSheets));
  dispatch(setSavedRSOLinkedEdges(savedState.rsoLinkedEdges));
  dispatch(setSavedRSOPoints(savedState.rsoPoints));
  dispatch(setSavedRSOPlanes(savedState.rsoPlanes));
  dispatch(setSavedRSOEdges(savedState.rsoEdges));
  dispatch(setSavedRSOHoles(savedState.rsoHoles));
  dispatch(setSavedRoofProcessStatus(savedState.roofProcessStatus));
  dispatch(setSavedRoofProcessFunctionsStatus(savedState.roofProcessFunctionsStatus));
  dispatch(setSavedOutlines(savedState.savedOutlines));

  dispatch(setSavedRoofAccessoriesElements(savedState.roofAccessories));
  dispatch(setSavedRoofWindowHoles(savedState.windowHoles));

  dispatch(setSavedGeometryData(savedState.geometryData));

  dispatch(setSavedGroupDiscounts(savedState.groupDiscounts));

  // Załadowanie eur do tempEur po załadowaniu projektu
  // savedState.currencyValue.tempEur = savedState.currencyValue.eur;
  dispatch(setSavedCurrencyValue(savedState.currencyValue));

  dispatch(setSavedFiltersForSystems(savedState.filtersForSystems));

  dispatch(setSavedAdditionalProducts(savedState.additionalProducts));
  // dispatch(setSavedOtherAppStatuses(savedState.otherAppStatuses)); // nie jest związane z konkretnym projektem

  dispatch(setSavedResponsesApi(savedState.responsesApi));

  dispatch(setSavedAdditionalProductsReducer(savedState.savedAdditionalProducts));
};

// Kolumny w głównej tabeli inwestycji
const columns = [
  { title: "Twórca", dataIndex: "investmentUser", key: "investmentUser", width: "150px" },
  { title: "Nazwa", dataIndex: "investmentName", key: "investmentName", width: "100%" },
  {
    title: "Utworzono",
    dataIndex: "investmentCreated",
    key: "investmentCreated",
    width: "100px",
    align: "center" as "center",
  },
  {
    title: "Ważność",
    dataIndex: "investmentValidity",
    key: "investmentValidity",
    width: "100px",
    align: "center" as "center",
  },
  {
    title: "Oferty",
    dataIndex: "investmentOffers",
    key: "investmentOffers",
    width: "50px",
    align: "center" as "center",
  },
  {
    title: "Aktywna",
    dataIndex: "investmentActive",
    key: "investmentActive",
    width: "50px",
    align: "center" as "center",
  },
  {
    title: "Akcje",
    dataIndex: "investmentActions",
    key: "investmentActions",
    align: "center" as "center",
  },
];

// Konfiguracja kolumn w tabeli ofert
const projectColumns = [
  { title: "Numer oferty", dataIndex: "reportNumber", key: "reportNumber", width: "50px" },
  { title: "Nazwa", dataIndex: "reportName", key: "reportName", width: "auto" },
  { title: "Cena", dataIndex: "reportPrice", key: "reportPrice", width: "150px", align: "center" as "center" },
  { title: "VAT", dataIndex: "reportVat", key: "reportVat", width: "50px", align: "center" as "center" },
  {
    title: "Opcje",
    dataIndex: "reportOptions",
    key: "reportOptions",
    width: "180px",
    align: "center" as "center",
  },
  {
    title: "Aktywna",
    dataIndex: "reportActive",
    key: "reportActive",
    width: "50px",
    align: "center" as "center",
  },
  {
    title: "Akcje",
    dataIndex: "reportActions",
    key: "reportActions",
    width: "100px",
    align: "center" as "center",
  },
];

const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

type Props = {};

const ReportsPage: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const reportsState = useAppSelector((state) => state.reports);
  const investmentsState = useAppSelector((state) => state.investments);
  const usersState = useAppSelector((state) => state.users);

  const tileSystems = useAppSelector((state) => state.tileSystems);
  const steelSystems = useAppSelector((state) => state.steelSystems);
  const gutterSystems = useAppSelector((state) => state.gutterSystems);

  const [searchUserTerm, setSearchUserTerm] = useState("");
  const [searchUserResults, setSearchUserResults] = useState<IUser[]>([]);

  // Tworzymy debounced wersję funkcji search
  const debouncedSearchUser = useCallback(
    debounce((text) => {
      searchUser(text);
    }, 500),
    []
  );

  // Efekt, który obserwuje zmiany searchTerm i wywołuje debouncedSearch
  useEffect(() => {
    debouncedSearchUser(searchUserTerm);
  }, [searchUserTerm, debouncedSearchUser, usersState]);

  const searchUser = (text: string) => {
    let result: IUser[] = [];

    if (text.length > 0) {
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      for (const user of usersState) {
        const userWords = `${user.fullName} ${user.email} ${user.role}`
          .toLowerCase()
          .split(/[\s,;]+/)
          .filter((word: string) => word.trim() !== "");

        let found = true;
        for (const word of inputWords) {
          if (!userWords.some((userWord) => userWord.includes(word))) {
            found = false;
            break;
          }
        }

        if (found) {
          result.push(user);
        }
      }
      setSearchUserResults(result);
    } else {
      setSearchUserResults([]);
    }
  };

  const isAdmin = () => {
    return profile.role === "admin";
  };

  const [investments, setInvestments] = useState<any[]>([]); // Inwestycje do wyświetlenia w tabeli
  const [searchInvestments, setSearchInvestments] = useState<string>(""); // Inwestycje do wyświetlenia w tabeli

  const [filters, setFilters] = useState({
    all: true, // Wszystkie inwestycje lub tylko moje
    userId: "", // Id użytkownika, którego inwestycje chcemy obejrzeć
    typeOrder: "newest" || "oldest", // Najnowsze, najstarsze
    typeActive: ["active", "inactive"], // Wszystkie, Aktywne, nieaktywne
    typeDate: ["valid", "expired"], // Ważne, nie ważne
  }); // Filtry

  useEffect(() => {
    // Gdy zmiana w filtrach to aktualizujemy inwestycje w inwestments
    prepareInvestments();

    setSearchUserTerm("");
  }, [filters, investmentsState, reportsState, searchInvestments]);

  const getMyUserObject = () => {
    const myUser = usersState.find((u) => u.email === profile.email);

    if (myUser) {
      return myUser;
    }
    return null;
  };

  const getReportsForInvestment = (investmentId: string) => {
    const reportsInInvestment = [...reportsState].filter((o) => o.investmentId === investmentId);
    return reportsInInvestment;
  };

  const getFirstReportForInvestment = (investmentId: string) => {
    const reportsInInvestment = [...reportsState].filter((o) => o.investmentId === investmentId);

    if (reportsInInvestment && reportsInInvestment.length > 0) {
      return reportsInInvestment[0];
    }
    return null;
  };

  const getProjectAndInvestorNameSystemsName = (investmentId: string) => {
    const report = getFirstReportForInvestment(investmentId);
    return `${report?.projectName} (${report?.investor})`;
  };

  const getReportSystemsName = (report: IReportOffer) => {
    const tileSystem = tileSystems.find((x) => x.id === report.roofSystemId);
    const steelSystem = steelSystems.find((x) => x.id === report.roofSystemId);
    const gutterSystem = gutterSystems.find((x) => x.id === report.gutterSystemId);

    const system = tileSystem ? tileSystem : steelSystem ? steelSystem : null;
    const systemName = system?.name ? system?.name : "";

    const gutterSystemName = gutterSystem?.name ? gutterSystem?.name : "";

    return `${systemName} + ${gutterSystemName}`;
  };

  const getCountReportsForInvestment = (investmentId: string | null) => {
    if (!investmentId) return 0;

    const reportsInInvestment = getReportsForInvestment(investmentId);
    return reportsInInvestment.length || 0;
  };

  const getLink = (reportCode: string, investmentId: string) => {
    const investment = investmentsState.find((i) => i.id === investmentId);
    return `https://raport.doma.pl/${investment?.code}/${reportCode}`;
  };

  const editReport = (investmentId: string, reportId: string) => {
    let toEditReportId = reportId;

    if (investmentId.length > 0) {
      const reports = getReportsForInvestment(investmentId);

      if (reports.length > 0) {
        const report = reports[0];
        toEditReportId = report.id;
      }
    }

    client
      .get(`/AppReports/reportData/${toEditReportId}`)
      .then((response) => {
        if (response.status == 200) {
          dispatch(updateReportDataInReducer({ reportId: toEditReportId, data: response.data }));

          const report = reportsState.find((x) => x.id === toEditReportId);
          const investment = investmentsState.find((x) => x.id === report?.investmentId);
          if (report && investment) {
            dispatchSelectedState(response.data, dispatch);

            if (investmentId.length > 0) {
              navigate("/dachy", { state: { goToMaterials: true } });
            } else {
              navigate("/dachy", { state: { goToResults: true } });
            }
          }
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {});
  };

  // Funkcja przygotowująca zestaw danych do wyświetlenia w tabeli i dodania do state investments
  const prepareInvestments = () => {
    let data: any[] = [];

    // Filtrowanie investmentsState po filtrach
    let filteredInvestments = [...investmentsState];
    if (!filters.all) {
      const myUser = getMyUserObject();
      if (myUser) {
        filteredInvestments = filteredInvestments.filter((i) => i.userId === myUser.id);
      }
    }

    // Filtr po userId
    if (filters.userId.length > 0) {
      filteredInvestments = filteredInvestments.filter((i) => i.userId === filters.userId);
    }

    // Filtrowanie po aktivności
    if (filters.typeActive.includes("active") && filters.typeActive.includes("inactive")) {
      filteredInvestments = filteredInvestments;
    } else if (filters.typeActive.includes("active")) {
      filteredInvestments = filteredInvestments.filter((i) => i.active);
    } else if (filters.typeActive.includes("inactive")) {
      filteredInvestments = filteredInvestments.filter((i) => !i.active);
    }

    // Filtrowanie po czy ważne czy przeterminowane
    if (filters.typeDate.includes("valid") && filters.typeDate.includes("expired")) {
      filteredInvestments = filteredInvestments;
    } else if (filters.typeDate.includes("valid")) {
      filteredInvestments = filteredInvestments.filter((i) => {
        const reports = getReportsForInvestment(i.id);
        if (reports.length > 0) {
          const report = reports.sort((a, b) => Date.parse(b.expiryDate) - Date.parse(a.expiryDate))[0];
          return Date.parse(report.expiryDate) > Date.now();
        }
        return false;
      });
    } else if (filters.typeDate.includes("expired")) {
      filteredInvestments = filteredInvestments.filter((i) => {
        const reports = getReportsForInvestment(i.id);
        if (reports.length > 0) {
          const report = reports.sort((a, b) => Date.parse(b.expiryDate) - Date.parse(a.expiryDate))[0];
          return Date.parse(report.expiryDate) < Date.now();
        }
        return false;
      });
    }

    // Filtr po dacie
    if (filters.typeOrder === "newest") {
      filteredInvestments = [...filteredInvestments].sort(
        (a, b) => Date.parse(b?.created) - Date.parse(a?.created)
      );
    } else if (filters.typeOrder === "oldest") {
      filteredInvestments = [...filteredInvestments].sort(
        (a, b) => Date.parse(a?.created) - Date.parse(b?.created)
      );
    }

    // Gdy wyszukiwanie to inne filtry nie mają znaczenia
    // Szukamy po numerze projektu oraz po nazwie
    const inputWords = searchInvestments
      .toLowerCase()
      .split(/[\s,;]+/)
      .filter((word: string) => word.trim() !== "");

    if (inputWords.length > 0) {
      filteredInvestments = [...investmentsState].filter((i) => {
        const reports = getReportsForInvestment(i.id);

        let found = false;
        for (const report of reports) {
          const reportWords = `${report.projectNumber} ${report.projectName} ${report.investor}`
            .toLowerCase()
            .split(/[\s,;]+/)
            .filter((word) => word.trim() !== "");

          let allWordsFound = true;
          for (const word of inputWords) {
            if (!reportWords.some((reportWord) => reportWord.includes(word))) {
              allWordsFound = false;
              break;
            }
          }

          if (allWordsFound) {
            found = true;
            break;
          }
        }
        return found;
      });
    }

    for (const r of filteredInvestments) {
      const reports = getReportsForInvestment(r.id);

      // Pobieramy raport który ma najdłuższy czas ważności
      const report = reports.sort((a, b) => Date.parse(b.expiryDate) - Date.parse(a.expiryDate))[0];

      let daysToExpiryPercentage = 0;
      let daysToExpiry = 0;
      let validText = `${daysToExpiry} dni`;

      let created = "";

      if (report) {
        // Dzień utworzenia
        const createdDate = new Date(report.created);

        // Dzień zakończenia
        const expiryDate = new Date(report.expiryDate);

        // Ilośc dni ważności
        const days = Math.floor((expiryDate.getTime() - createdDate.getTime()) / (1000 * 60 * 60 * 24));

        // Obliczamy ile dni pozostało do ważności
        daysToExpiry = Math.floor((Date.parse(report.expiryDate) - Date.now()) / (1000 * 60 * 60 * 24)) || 0;

        // 100% = days, ile to będzie procentowo dla daysToExpiry
        daysToExpiryPercentage = Math.floor((daysToExpiry / days) * 100);

        validText = `${daysToExpiry} dni`;

        // Jeśli to dziś to ile godzin
        if (daysToExpiry === 0) {
          const hours = Math.floor((Date.parse(report.expiryDate) - Date.now()) / (1000 * 60 * 60)) || 0;
          validText = `${hours} h`;
        }

        if (daysToExpiry < 0) {
          validText = `Koniec`;
        }

        created = new Date(r.created).toLocaleDateString();

        // Jeśli cretaed to dziś to napis DZIŚ
        if (created === new Date().toLocaleDateString()) {
          created = "Dziś";
        }

        // Jeśli wczoraj to napis Wczoraj
        if (created === new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString()) {
          created = "Wczoraj";
        }
      }

      if (r !== null && reports?.length > 0) {
        data.push({
          key: r.id,
          investmentUser: r.userName,
          investmentName: getProjectAndInvestorNameSystemsName(r.id),
          investmentCreated: created,
          investmentValidity: (
            <Progress
              size="small"
              type="circle"
              percent={daysToExpiryPercentage}
              format={() => validText}
              status={daysToExpiry > 3 ? "active" : "exception"}
            />
          ),
          investmentOffers: reports.length,
          investmentActive: (
            <Switch
              checked={r.active}
              onChange={(checked, e) => {
                e.stopPropagation();
                dispatch(updateInvestmentActivity({ id: r.id, active: checked }));
              }}
            />
          ),
          investmentActions: (
            <div style={{ display: "flex", gap: "8px" }}>
              <Popconfirm
                title="Usuń inwestycję"
                description="Czy na pewno chcesz usunąć inwestycję wraz z ofertami?"
                onConfirm={(e) => {
                  e?.stopPropagation();
                  dispatch(deleteInvestment(r.id));
                }}
                okText="Tak"
                cancelText="Nie"
                placement="topRight"
              >
                <Button
                  danger
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>

              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  editReport(r.id, "");
                }}
              >
                Dodaj ofertę
              </Button>
            </div>
          ),
          projects: reports.map((report) => {
            return {
              key: report.id,
              reportNumber: report.projectNumber,
              reportName: getReportSystemsName(report),
              reportPrice: (
                <div style={{ display: "flex", flexDirection: "column", gap: "8px", justifyContent: "center" }}>
                  <Tooltip title={<div style={{ textAlign: "center" }}>Cena netto.</div>} color={PRIMARY_COLOR}>
                    <Tag style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      N: <b style={{ marginLeft: "4px" }}>{report.totalNettPrice.toFixed(2)} zł</b>
                    </Tag>
                  </Tooltip>

                  <Tooltip title={<div style={{ textAlign: "center" }}>Cena brutto.</div>} color={PRIMARY_COLOR}>
                    <Tag style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      B: <b style={{ marginLeft: "4px" }}>{report.totalPrice.toFixed(2)} zł</b>
                    </Tag>
                  </Tooltip>
                </div>
              ),
              reportVat: report.vat + "%",
              reportOptions: (
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                  <Select
                    value={report.type}
                    onChange={(value: string) => dispatch(updateTypeReport(report.id, value))}
                    style={{ width: "100%" }}
                  >
                    <Select.Option value="basic">Uproszczony</Select.Option>
                    <Select.Option value="full">Pełny</Select.Option>
                  </Select>
                  <Select
                    value={report.status}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      dispatch(updateStatusReport(report.id, e));
                    }}
                  >
                    <Select.Option value="created">Utworzony</Select.Option>
                    <Select.Option value="sent">Wysłany</Select.Option>
                    <Select.Option value="accepted">Zaakceptowany</Select.Option>
                    <Select.Option value="rejected">Odrzucony</Select.Option>
                  </Select>
                </div>
              ),
              reportActive: (
                <Switch
                  checked={report.active}
                  onChange={(checked) => {
                    dispatch(updateUserReport({ ...report, active: checked }));
                  }}
                />
              ),
              reportActions: (
                <div style={{ display: "flex", gap: "8px" }}>
                  <Popconfirm
                    title="Usuń ofertę"
                    description="Czy na pewno chcesz usunąć ofertę?"
                    onConfirm={() => {
                      dispatch(deleteReport(report.id));
                    }}
                    okText="Tak"
                    cancelText="Nie"
                    placement="topRight"
                  >
                    <Button disabled={getCountReportsForInvestment(report.investmentId) <= 1} danger>
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      editReport("", report.id);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      openInNewTab(getLink(report.urlCode, report.investmentId as string));
                    }}
                  >
                    Podgląd
                  </Button>
                </div>
              ),
            };
          }),
        });
      }
    }

    // Ustawienie danych w state investments
    setInvestments(data);
  };

  const expandedRowRender = (record: any) => {
    return <Table columns={projectColumns} dataSource={record.projects} pagination={false} />;
  };

  // Funkcja pobierająca ilość inwestycji dla userId - uwzględnia tylko inwestycje gdzie jest min 1 raport
  const getCountInvestmentsForUser = (userId: string) => {
    const investmentsForUser = [...investmentsState].filter((i) => i.userId === userId);
    let count = 0;

    for (const r of investmentsForUser) {
      const reports = getReportsForInvestment(r.id);
      if (reports.length > 0) {
        count++;
      }
    }

    return count;
  };

  const rowClassName = (record: any, index: any) => {
    // Przykład: przypisanie klasy dla wierszy, które nie są zagnieżdżone
    // Załóżmy, że mamy flagę w danych wskazującą na zagnieżdżenie
    return record.isNested ? "" : "main-row";
  };

  return (
    <Container>
      <PageHeader title={"Oferty"} />

      <Card style={{ marginTop: "-16px" }}>
        <Container style={{ display: "flex", width: "100%" }}>
          {isAdmin() && (
            <>
              <Row gutter={[16, 16]} wrap>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Button
                    style={{ width: "100%" }}
                    type={filters.all ? "default" : "primary"}
                    onClick={() => {
                      if (filters.all === true) {
                        setFilters({ ...filters, all: false, userId: "" });
                      }
                    }}
                  >
                    Moje inwestycje
                  </Button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Button
                    style={{ width: "100%" }}
                    type={filters.all ? "primary" : "default"}
                    onClick={() => {
                      if (isAdmin() && filters.all === false) {
                        setFilters({ ...filters, all: true, userId: "" });
                      }
                    }}
                  >
                    Wszystkie inwestycje
                  </Button>
                </Col>
              </Row>
              <Divider style={{ margin: "0px" }} />
            </>
          )}

          <Row gutter={[16, 16]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                style={{ width: "100%" }}
                type={filters.typeOrder === "newest" ? "primary" : "default"}
                onClick={() => {
                  if (filters.typeOrder !== "newest") {
                    setFilters({ ...filters, typeOrder: "newest" });
                  }
                }}
              >
                Od najnowszych
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                style={{ width: "100%" }}
                type={filters.typeOrder === "oldest" ? "primary" : "default"}
                onClick={() => {
                  if (filters.typeOrder !== "oldest") {
                    setFilters({ ...filters, typeOrder: "oldest" });
                  }
                }}
              >
                Od najstarszych
              </Button>
            </Col>
          </Row>

          <Row gutter={[16, 16]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                style={{ width: "100%" }}
                type={filters.typeActive.includes("active") ? "primary" : "default"}
                onClick={() => {
                  if (!filters.typeActive.includes("active")) {
                    const updatedTypeActive = new Set(filters.typeActive);
                    updatedTypeActive.add("active");

                    setFilters({ ...filters, typeActive: Array.from(updatedTypeActive) });
                  } else {
                    if (filters.typeActive.length > 1) {
                      const updatedTypeActive = new Set(filters.typeActive);
                      updatedTypeActive.delete("active");

                      setFilters({ ...filters, typeActive: Array.from(updatedTypeActive) });
                    }
                  }
                }}
              >
                Aktywne inwestycje
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                style={{ width: "100%" }}
                type={filters.typeActive.includes("inactive") ? "primary" : "default"}
                onClick={() => {
                  if (!filters.typeActive.includes("inactive")) {
                    const updatedTypeActive = new Set(filters.typeActive);
                    updatedTypeActive.add("inactive");

                    setFilters({ ...filters, typeActive: Array.from(updatedTypeActive) });
                  } else {
                    if (filters.typeActive.length > 1) {
                      const updatedTypeActive = new Set(filters.typeActive);
                      updatedTypeActive.delete("inactive");

                      setFilters({ ...filters, typeActive: Array.from(updatedTypeActive) });
                    }
                  }
                }}
              >
                Nieaktywne inwestycje
              </Button>
            </Col>
          </Row>

          <Row gutter={[16, 16]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                style={{ width: "100%" }}
                type={filters.typeDate.includes("valid") ? "primary" : "default"}
                onClick={() => {
                  if (!filters.typeDate.includes("valid")) {
                    const updatedTypeDate = new Set(filters.typeDate);
                    updatedTypeDate.add("valid");

                    setFilters({ ...filters, typeDate: Array.from(updatedTypeDate) });
                  } else {
                    if (filters.typeDate.length > 1) {
                      const updatedTypeDate = new Set(filters.typeDate);
                      updatedTypeDate.delete("valid");

                      setFilters({ ...filters, typeDate: Array.from(updatedTypeDate) });
                    }
                  }
                }}
              >
                Z ważnym terminem
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                style={{ width: "100%" }}
                type={filters.typeDate.includes("expired") ? "primary" : "default"}
                onClick={() => {
                  if (!filters.typeDate.includes("expired")) {
                    const updatedTypeDate = new Set(filters.typeDate);
                    updatedTypeDate.add("expired");

                    setFilters({ ...filters, typeDate: Array.from(updatedTypeDate) });
                  } else {
                    if (filters.typeDate.length > 1) {
                      const updatedTypeDate = new Set(filters.typeDate);
                      updatedTypeDate.delete("expired");

                      setFilters({ ...filters, typeDate: Array.from(updatedTypeDate) });
                    }
                  }
                }}
              >
                Po terminie
              </Button>
            </Col>
          </Row>

          <Row gutter={[16, 16]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                disabled
                style={{ width: "100%" }}
                onClick={() => {
                  if (filters.all === true) {
                    setFilters({ ...filters });
                  }
                }}
              >
                Z utworzonym raportem
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                disabled
                style={{ width: "100%" }}
                onClick={() => {
                  if (filters.all === true) {
                    setFilters({ ...filters });
                  }
                }}
              >
                Z wysłanym raportem
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                disabled
                style={{ width: "100%" }}
                onClick={() => {
                  if (filters.all === true) {
                    setFilters({ ...filters });
                  }
                }}
              >
                Z zaakceptowanym raportem
              </Button>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Button
                disabled
                style={{ width: "100%" }}
                onClick={() => {
                  if (filters.all === true) {
                    setFilters({ ...filters });
                  }
                }}
              >
                Z odrzuconym raportem
              </Button>
            </Col>
          </Row>

          {isAdmin() && filters.all === true && (
            <>
              <Divider style={{ margin: "0px" }} />
              <Form.Item label="Znajdź użytkownika" labelCol={{ span: 24 }} style={{ marginBottom: "32px" }}>
                <SearchInput
                  placeholder="Wpisz nazwę użytkownika..."
                  value={searchUserTerm}
                  onChange={(e) => setSearchUserTerm(e.target.value)}
                  allowClear
                />
              </Form.Item>

              <Row gutter={[16, 16]} wrap>
                {(searchUserTerm && searchUserTerm.length > 0 ? searchUserResults : usersState).map(
                  (user, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
                      <Badge.Ribbon
                        text={getCountInvestmentsForUser(user.id)}
                        color={getCountInvestmentsForUser(user.id) === 0 ? "#ff0000" : PRIMARY_COLOR}
                      >
                        <Button
                          style={{ width: "100%" }}
                          type={filters.userId === user.id ? "primary" : "default"}
                          onClick={() => {
                            if (filters.userId === user.id) {
                              setFilters({ ...filters, userId: "" });
                            } else {
                              setFilters({ ...filters, userId: user.id });
                            }
                          }}
                        >
                          {user.fullName}
                        </Button>
                      </Badge.Ribbon>
                    </Col>
                  )
                )}
              </Row>
            </>
          )}
        </Container>
      </Card>

      <Card>
        <Form.Item label="Znajdź inwestycję lub ofertę" labelCol={{ span: 24 }} style={{ marginBottom: "32px" }}>
          <SearchInput
            placeholder="Wpisz numer projektu, nazwę inwestycji, inwestora..."
            value={searchInvestments}
            onChange={(e) => setSearchInvestments(e.target.value)}
            allowClear
          />
        </Form.Item>
      </Card>

      <Card title={"Inwestycje z ofertami"}>
        <StyledTable
          rowClassName={rowClassName}
          columns={columns}
          dataSource={investments}
          expandedRowRender={expandedRowRender}
          rowHoverable={false}
          expandRowByClick
        />
      </Card>

      {/* <Card title={"Moje inwestycje z ofertami"}>
        <InvestmentsComponent fullInvestments={false} />
      </Card> */}

      {/* {isAdmin() && (
        <Card title={"Wszystkie inwestycje w sklepie"}>
          <InvestmentsComponent fullInvestments={true} />
        </Card>
      )} */}
    </Container>
  );
};
export default ReportsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledTable = styled(Table)`
  // Styl dla głównych wierszy
  .main-row {
    background-color: #f5f4f4; // normalny kolor tła

    &:hover {
      background-color: #eeeeee; // kolor tła przy najechaniu kursorem
    }
  }

  .ant-table-container > .ant-table-content > table > .ant-table-thead > tr > th {
    background-color: ${PRIMARY_COLOR}; // Kolor tła głównych nagłówków
    color: white; // Kolor tekstu
  }

  // Dla zagnieżdżonych tabel zostawiamy domyślny styl
  .ant-table-container .ant-table-container .ant-table-content .ant-table-thead > tr > th {
    background-color: transparent; // Przywróć domyślny styl dla zagnieżdżonych
    color: inherit; // Przywróć domyślny kolor tekstu
  }
`;

const SearchInput = styled(Input)``;
