import { Alert, Card, Col, Form, Row, Select } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { createFilterOption, getElementsForSelect } from "../../../helpers/Helpers";
import { useAppSelector } from "../../../redux/hooks";

type Props = {
  form: any;
};

const SystemConfigurationDetailsSection: FC<Props> = ({ form }) => {
  const companies = useAppSelector((state) => state.companies);
  const productColors = useAppSelector((state) => state.productColors);
  const productModels = useAppSelector((state) => state.productModels);

  const [modelSelectKey, setModelSelectKey] = useState(Math.random());

  const availableCompanies = () => {
    return [...getElementsForSelect(companies), { value: null, label: "Brak producenta" }];
  };

  const availableColors = () => {
    return [...getElementsForSelect(productColors), { value: null, label: "Brak koloru" }];
  };

  // Memoizacja dostępnych opcji dla modeli
  const availableModels = () => {
    const selectedCompanyId = form.getFieldValue("companyId");

    if (selectedCompanyId === null || selectedCompanyId === "null") {
      return [{ value: null, label: "Brak modelu" }];
    } else {
      const models = productModels.filter((model) => model.companyId === selectedCompanyId);
      return [...getElementsForSelect(models), { value: null, label: "Brak modelu" }];
    }
  };

  const onChangeValueInComapny = (value: string) => {
    form.setFieldsValue({ modelId: undefined });
    form.setFieldsValue({ colorId: undefined });

    setModelSelectKey(Math.random()); // Generuje nowy klucz aby przeładować komponent
  };

  return (
    <Section title={"Szczegóły konfiguracyjne"} style={{ marginTop: "16px" }}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Producent" name="companyId" labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz producenta podstawowych produktów!"
              options={availableCompanies()}
              onChange={onChangeValueInComapny}
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Model" name="modelId" labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz model podstawowych produktów!"
              options={availableModels()}
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Kolor" name="colorId" labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz kolor podstawowych produktów!"
              options={availableColors()}
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Alert
        showIcon
        type="info"
        message={"Informacje niezbędne do filtrowania podczas wyboru systemu do konkretnej kalkulacji dachu."}
      />
    </Section>
  );
};
export default SystemConfigurationDetailsSection;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
