import { Button, Card, Spin, Table, Tag } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DSpin from "../../../helpers/DSpin";
import PageHeader from "../../../layout/components/PageHeader";
import { IRootState } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getCompaniesWithProductsInfo,
  IDomaCompanyWithProducts,
  importCompaniesWithProducts,
} from "../../../redux/import/importedInfoSlice";

const importedCompaniesColumns = [
  {
    title: "Nazwa firmy",
    dataIndex: "name",
  },
  {
    title: "Modele produktów",
    dataIndex: "models",
  },
  {
    title: "Ilość produktów",
    dataIndex: "countProducts",
    width: "150px",
    align: "center" as "center",
  },
];

type Props = {};

const ImportMaterialsPage: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const importedInfo = useAppSelector((state: IRootState) => state.importedInfo);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    dispatch(getCompaniesWithProductsInfo());
  }, []);

  useEffect(() => {
    if (importedInfo.companiesWithProducts) {
      setLoading(false);
    }
  }, [importedInfo.companiesWithProducts]);

  const getItemsData = (items: IDomaCompanyWithProducts[]) => {
    const data: {
      key: string;
      id: number;
      name: string;
      models: JSX.Element;
      countProducts: number;
    }[] = [];

    let i = 1;
    for (const item of items) {
      data.push({
        key: item.id,
        id: i,
        name: item.companyName,
        models: (
          <div>
            {getProductModels([item]).map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </div>
        ),
        countProducts: item.productsCount,
      });
      i++;
    }
    return data;
  };

  const getProductModels = (items: IDomaCompanyWithProducts[]) => {
    const productModels: string[] = [];

    for (const company of items) {
      for (const model of company.models) {
        if (model) {
          if (!productModels.includes(model)) {
            productModels.push(model);
          }
        }
      }
    }
    return productModels;
  };

  const handleTableChange = (selectedRowKeys: any, selectedRows: any) => {
    setSelectedItems(selectedRows);
  };

  const importSelectedItems = () => {
    // List domaCompanyId do pobrania produktów
    const selectedCompanyIdList = selectedItems.map((item: any) => item.key);
    // Wywołanie funkcji importującej

    dispatch(importCompaniesWithProducts(selectedCompanyIdList));
  };

  return (
    <Container>
      <PageHeader title={"Zarządzanie produktami"} />

      <Button
        type="default"
        onClick={() => navigate(`/import-aktualizacja-produktow/z-pliku`)}
        style={{ height: "200px", fontWeight: "bold", fontSize: "20px" }}
      >
        Wczytaj aktualizację cen z Excela
      </Button>

      <Button
        type="default"
        onClick={() => navigate(`/import-materialow/z-pliku`)}
        style={{ height: "200px", fontWeight: "bold", fontSize: "20px" }}
      >
        Wczytaj nowe produkty z Excela
      </Button>
    </Container>
  );
};
export default ImportMaterialsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
`;

const DomaSpin = styled(DSpin)``;

const Section = styled(Card)``;
