import { DeleteOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  List,
  message,
  Spin,
  Tooltip,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { all } from "axios";
import { getMaxListeners } from "process";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import DomaInputNumber from "../helpers/DomaInputNumber";
import DSpin from "../helpers/DSpin";
import { copyTextToClipboard } from "../helpers/Helpers";
import { IRootState } from "../models/Models";
import {
  generateReport,
  updateAdditionalReportPagesInReducer,
  updateReport,
  updateReportInformationsRequestData,
  updateReportNotesInReducer,
  updateReportQuestionsInReducer,
} from "../redux/calculationProcess/reportSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import OfferDataModal, { IInformationReport } from "./OfferDataModal";
import { v4 as uuidv4 } from "uuid";
import { IAdditionalReportPage } from "../modules/admin/CompanyPage";
import { set } from "js-cookie";
import { useFirstRender } from "../hooks/useFirstRender";
import { PRIMARY_COLOR } from "../theme";

export interface INotePosition {
  id: string;
  content: string;
  order: number;
  active: boolean;
}

export interface IQuestionPosition {
  id: string;
  content: string;
  answer: string;
  order: number;
  active: boolean;
}

type Props = {};

const OfferInformations: FC<Props> = () => {
  const [form] = useForm();
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const [waitingApi, setWaitingApi] = useState<boolean>(false);

  const [offerDataModal, setOfferDataModal] = useState<boolean>(false);

  const general = useAppSelector((state: IRootState) => state.general);
  const report = useAppSelector((state: IRootState) => state.report);
  const reports = useAppSelector((state: IRootState) => state.reports);
  const investments = useAppSelector((state) => state.investments);

  const [reportNotes, setReportNotes] = useState<INotePosition[]>([]);
  const [reportQuestions, setReportQuestions] = useState<IQuestionPosition[]>([]);
  const [additionalReportPages, setAdditionalReportPages] = useState<IAdditionalReportPage[]>([]);

  const parentSettings = useAppSelector((state: IRootState) => state.parentSettings);
  const currencyValue = useAppSelector((state: IRootState) => state.currencyValue);

  // useEffect(() => {
  //   console.log("RELOAD");
  // });

  useEffect(() => {
    if (report && parentSettings) {
      // Uwagi ogólne
      if (reportNotes.length === 0 && firstRender) {
        let notes: INotePosition[] = [];
        if (report.reportNotes?.length === 0) {
          notes = parentSettings.reportNotes.filter((x) => x.active === true) || [];
          dispatch(updateReportNotesInReducer(notes));
        } else {
          notes = report.reportNotes || [];
        }
        setReportNotes(notes);
      }

      // Pytania dodatkowe
      if (reportQuestions.length === 0 && firstRender) {
        if (report.reportQuestions?.length === 0) {
          const questions: IQuestionPosition[] = [];
          for (const q of parentSettings.reportQuestions.filter((x) => x.active === true)) {
            questions.push({
              id: q.id,
              content: q.content,
              answer: "",
              order: q.order,
              active: q.active,
            });
          }
          if (questions.length > 0) {
            setReportQuestions(questions || []);
            dispatch(updateReportQuestionsInReducer(questions));
          }
        } else {
          if (report.reportQuestions?.length > 0) {
            const questions: IQuestionPosition[] = [];
            for (const q of report.reportQuestions) {
              questions.push({
                id: q.id,
                content: q.content,
                answer: q.answer,
                order: q.order,
                active: q.active,
              });
            }
            if (questions.length > 0) {
              setReportQuestions(questions || []);
            }
          }
        }
      }

      // Dodatkowe strony
      if (additionalReportPages.length === 0 && firstRender) {
        if (report.additionalReportPages?.length === 0) {
          const elements = parentSettings.additionalReportPages.filter((x) => x.active === true) || [];
          setAdditionalReportPages(elements || []);
          dispatch(updateAdditionalReportPagesInReducer(elements || []));
        } else {
          setAdditionalReportPages(report.additionalReportPages || []);
        }
      }
    }
  }, [parentSettings, report]);

  const getCurrencyValue = () => {
    console.log("CURRENCY", currencyValue);
    return currencyValue?.tempEur || 0;
  };

  const getActiveReportCode = () => {
    const activeReport = reports.find((r) => r.id === general.reportId);
    const investment = investments.find((i) => i.id === activeReport?.investmentId);
    return `${investment?.code}/${activeReport?.urlCode}`;
  };

  const getReportUrl = () => {
    return `https://raport.doma.pl/${getActiveReportCode()}`;
  };

  useEffect(() => {
    // console.log("REPORT", report);
    if (report.generated) {
      setWaitingApi(false);
    }
  }, [report]);

  const isReadyToGenerateReport = () => {
    if (report.requestData.projectName && report.requestData.investor) {
      return true;
    }

    return false;
  };

  const successCopyUrl = (text: string) => {
    messageApi.open({
      type: "success",
      content: <span>Link do raportu został skopiowany do schowka.</span>,
    });
  };

  const getInitialValues = () => {
    return {
      projectName: report.requestData.projectName,
      investor: report.requestData.investor,
      phone: report.requestData.phone,
      email: report.requestData.email,
      zipCode: report.requestData.zipCode,
      street: report.requestData.street,
      houseNumber: report.requestData.houseNumber,
      city: report.requestData.city,
      validDays: report.requestData.validDays,
    };
  };

  const onChangeForm = (changedValues: any, allValues: any) => {
    // console.log(allValues);
    const model: IInformationReport = {
      projectName: allValues.projectName,
      investor: allValues.investor,
      phone: allValues.phone,
      email: allValues.email,
      zipCode: allValues.zipCode,
      street: allValues.street,
      houseNumber: allValues.houseNumber,
      city: allValues.city,
      validDays: allValues.validDays,
    };

    // console.log("CHANGE");

    dispatch(updateReportInformationsRequestData(model));
  };

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  };

  const setExampleValues = () => {
    const model: IInformationReport = {
      projectName: `Domek jednorodzinny (projekt ABAQUS A${getRandomInt(1, 99999)})`,
      investor: "Janusz Kowalski",
      phone: "123456789",
      email: "klient@poczta.com",
      zipCode: "83-340",
      street: "Adama Mickiewicza",
      houseNumber: "55",
      city: "Sierakowice",
      validDays: 14,
    };
    form.setFieldsValue(model);
    dispatch(updateReportInformationsRequestData(model));
  };

  return (
    <Container>
      {contextHolder}
      <Section
        title="Dane oferty"
        extra={
          <Button type="dashed" onClick={() => setExampleValues()}>
            <span style={{ color: "#ababab" }}>Wstaw przykładowe dane</span>
          </Button>
        }
      >
        {waitingApi && <DomaSpin />}

        <Form
          id="offerDataForm"
          style={{ width: "100%" }}
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={getInitialValues()}
          onValuesChange={onChangeForm}
        >
          <Form.Item label="Nazwa projektu" name="projectName">
            <Input />
          </Form.Item>

          <Form.Item label="Inwestor" name="investor">
            <Input />
          </Form.Item>

          <RowTable>
            <Form.Item label="Telefon" name="phone" style={{ width: "100%" }}>
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" style={{ width: "100%" }}>
              <Input />
            </Form.Item>
          </RowTable>

          <RowTable>
            <Form.Item label="Ulica" name="street" style={{ width: "100%" }}>
              <Input />
            </Form.Item>

            <Form.Item label="Numer domu/mieszkania" name="houseNumber" style={{ width: "100%" }}>
              <Input />
            </Form.Item>
          </RowTable>

          <RowTable>
            <Form.Item label="Kod pocztowy" name="zipCode" style={{ width: "100%" }}>
              <Input />
            </Form.Item>

            <Form.Item label="Miejscowość" name="city" style={{ width: "100%" }}>
              <Input />
            </Form.Item>
          </RowTable>

          <Form.Item label="Ważność oferty [dni]" name="validDays">
            <DomaInputNumber />
          </Form.Item>
        </Form>
      </Section>

      <Section
        title="Ogólne uwagi do oferty"
        extra={
          <Button
            onClick={() => {
              const newNotes = parentSettings.reportNotes.filter((x) => x.active === true) || [];
              setReportNotes(newNotes);
              dispatch(updateReportNotesInReducer(newNotes));
            }}
          >
            Przywróć domyślne
          </Button>
        }
      >
        <List
          dataSource={[...reportNotes].sort((a, b) => a.order - b.order)}
          renderItem={(item) => (
            <List.Item
              style={{
                width: "100%",
                padding: "16px 0px 16px 0px",
                opacity: item.active ? "1" : "0.5",
              }}
            >
              <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}>
                <Col>
                  <Checkbox
                    checked={item.active}
                    onChange={(e) => {
                      const newNotes = [...reportNotes].map((n) => {
                        if (n.id === item.id) {
                          return { ...n, active: e.target.checked };
                        }
                        return n;
                      });
                      setReportNotes(newNotes);
                      dispatch(updateReportNotesInReducer(newNotes));
                    }}
                  />
                </Col>
                <Col style={{ pointerEvents: item.active ? "auto" : "none", width: "100%" }}>
                  <Input
                    value={item.content}
                    onChange={(e) => {
                      const newNotes = [...reportNotes].map((n) => {
                        if (n.id === item.id) {
                          return { ...n, content: e.target.value };
                        }
                        return n;
                      });
                      setReportNotes(newNotes);
                      dispatch(updateReportNotesInReducer(newNotes));
                    }}
                  />
                </Col>
                {/* <Col
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    pointerEvents: item.active ? "auto" : "none",
                  }}
                >
                  <Button
                    danger
                    onClick={() => {
                      const newNotes = [...reportNotes].filter((n) => n.id !== item.id);
                      setReportNotes(newNotes);
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </Col> */}
              </Row>
            </List.Item>
          )}
        />
      </Section>

      <Section
        title="Dodatkowe uwagi"
        extra={
          <div style={{ display: "flex", gap: "16px" }}>
            <Button
              onClick={() => {
                const newReportQuestions = parentSettings.reportQuestions.filter((x) => x.active === true) || [];
                const newReportQuestionpositions: IQuestionPosition[] = [];

                for (const q of newReportQuestions) {
                  newReportQuestionpositions.push({
                    id: q.id,
                    content: q.content,
                    answer: "",
                    order: q.order,
                    active: q.active,
                  });
                }
                setReportQuestions(newReportQuestionpositions);
                dispatch(updateReportQuestionsInReducer(newReportQuestionpositions));
              }}
            >
              Przywróć domyślne
            </Button>
            <Button
              onClick={() => {
                const newPosition: IQuestionPosition = {
                  id: uuidv4(),
                  answer: `Oferta ważna przy kursie EUR nie przekraczającym ${getCurrencyValue().toFixed(
                    2
                  )} zł - powyżej tej kwoty prosimy o jej aktualizację.`,
                  content: "Informacja o kursie waluty",
                  order: reportQuestions.length + 1,
                  active: true,
                };

                setReportQuestions([...reportQuestions, newPosition]);
                dispatch(updateReportQuestionsInReducer([...reportQuestions, newPosition]));
              }}
            >
              Dodaj informację o kursie waluty
            </Button>
            <Button
              onClick={() => {
                const newPosition: IQuestionPosition = {
                  id: uuidv4(),
                  content: "Dodatkowa uwaga",
                  answer: "",
                  order: reportQuestions.length + 1,
                  active: true,
                };
                setReportQuestions([...reportQuestions, newPosition]);
                dispatch(updateReportQuestionsInReducer([...reportQuestions, newPosition]));
              }}
            >
              Dodaj dodatkową pozycję
            </Button>
          </div>
        }
      >
        <List
          dataSource={[...reportQuestions].sort((a, b) => a.order - b.order)}
          renderItem={(item) => (
            <List.Item
              style={{
                width: "100%",
                padding: "16px 0px 16px 0px",
                opacity: item.active ? "1" : "0.5",
              }}
            >
              <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}>
                <Col>
                  <Row>&nbsp;</Row>
                  <Row>
                    <Checkbox
                      checked={item.active}
                      onChange={(e) => {
                        const newNotes = [...reportQuestions].map((n) => {
                          if (n.id === item.id) {
                            return { ...n, active: e.target.checked };
                          }
                          return n;
                        });
                        setReportQuestions(newNotes);
                        dispatch(updateReportQuestionsInReducer(newNotes));
                      }}
                    />
                  </Row>
                </Col>
                <Col style={{ pointerEvents: item.active ? "auto" : "none", width: "100%" }}>
                  <Row>{item.content}</Row>
                  <Row>
                    <Input
                      value={item.answer}
                      onChange={(e) => {
                        const newNotes = [...reportQuestions].map((n) => {
                          if (n.id === item.id) {
                            return { ...n, answer: e.target.value };
                          }
                          return n;
                        });
                        setReportQuestions(newNotes);
                        dispatch(updateReportQuestionsInReducer(newNotes));
                      }}
                    />
                  </Row>
                </Col>
                <Col
                  style={{
                    pointerEvents: item.active ? "auto" : "none",
                  }}
                >
                  <Row>&nbsp;</Row>
                  <Row>
                    <Button
                      danger
                      onClick={() => {
                        const newNotes = [...reportQuestions].filter((n) => n.id !== item.id);
                        setReportQuestions(newNotes);
                        dispatch(updateReportQuestionsInReducer(newNotes));
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Section>

      <Section
        title="Dodatkowe strony w raporcie"
        extra={
          <Button
            onClick={() => {
              const newNotes = parentSettings.additionalReportPages.filter((x) => x.active === true) || [];
              setAdditionalReportPages(newNotes);
              dispatch(updateAdditionalReportPagesInReducer(newNotes));
            }}
          >
            Przywróć domyślne
          </Button>
        }
      >
        <List
          dataSource={[...additionalReportPages].sort((a, b) => a.order - b.order)}
          renderItem={(item) => (
            <List.Item
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "8px",
                opacity: item.active ? "1" : "0.5",
              }}
            >
              <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px" }}>
                <Col>
                  <Checkbox
                    checked={item.active}
                    onChange={(e) => {
                      const newNotes = [...additionalReportPages].map((n) => {
                        if (n.id === item.id) {
                          return { ...n, active: e.target.checked };
                        }
                        return n;
                      });
                      setAdditionalReportPages(newNotes);
                      dispatch(updateAdditionalReportPagesInReducer(newNotes));
                    }}
                  />
                </Col>
                <Col style={{ pointerEvents: item.active ? "auto" : "none", width: "100%" }}>
                  <div style={{ color: "#000000" }}>{item.title}</div>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Section>

      <Section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "8px",
            }}
          >
            <Alert
              message={
                <>
                  Domyślnie, po utworzeniu projekt (oferta) otrzymuje status <b>Utworzony</b> i jest przedstawiony
                  w wersji <b>uproszczonej</b>.
                </>
              }
              type="info"
              showIcon
              style={{ marginBottom: "16px" }}
            />

            {isReadyToGenerateReport() ? (
              <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <OfferButtonsRow>
                  {report.generated && !report.shouldRegenerated && (
                    <a style={{ width: "100%" }} target="_blank" rel="noopener noreferrer" href={getReportUrl()}>
                      {report.generated && <Button style={{ width: "100%" }}>Podgląd raportu</Button>}
                    </a>
                  )}

                  {report.generated && !report.shouldRegenerated && (
                    <Button
                      style={{ width: "100%", background: "#caf1a0" }}
                      onClick={() => {
                        copyTextToClipboard(getReportUrl());
                        successCopyUrl(getActiveReportCode());
                      }}
                    >
                      Skopiuj link do raportu
                    </Button>
                  )}
                </OfferButtonsRow>

                <Tooltip
                  title={
                    <div style={{ textAlign: "center" }}>
                      Zastosuj gdy masz już przygotowaną ofertę i rozwiązanie zostało przeliczone ze zmienionymi
                      materiałami lub geometrią. Spowoduje to dodanie dodatkowej oferty do tej inwestycji.
                    </div>
                  }
                  color={PRIMARY_COLOR}
                >
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      setWaitingApi(true);
                      dispatch(generateReport());
                    }}
                  >
                    {report.generated && !report.shouldRegenerated
                      ? "Wygeneruj dodatkowy raport do tej inwestycji"
                      : "Wygeneruj raport"}
                  </Button>
                </Tooltip>

                {report.generated && (
                  <Tooltip
                    title={
                      <div style={{ textAlign: "center" }}>
                        Zastosuj gdy chcesz zaktualizować bierzącą ofertę. Spowoduje to nadpisanie istniejącej
                        oferty.
                      </div>
                    }
                    color={PRIMARY_COLOR}
                  >
                    <Button
                      style={{ width: "100%" }}
                      type="primary"
                      onClick={() => {
                        dispatch(updateReport(report.responseData?.id as string));
                      }}
                    >
                      Aktualizuj wygenerowany raport
                    </Button>
                  </Tooltip>
                )}
              </div>
            ) : (
              <Alert
                message="Aby wygenerować ofertę, wypełnij wszystkie informacje o projekcie!"
                type="error"
                showIcon
              />
            )}
          </div>
        </div>
      </Section>
    </Container>

    // <Container
    //   bodyStyle={{
    //     display: "flex",
    //     flexDirection: "column",
    //     opacity: waitingApi ? "0.5" : "1",
    //     pointerEvents: waitingApi ? "none" : "auto",
    //   }}
    // >
    //   {contextHolder}

    //   {waitingApi && <DomaSpin style={{ position: "absolute", zIndex: "999" }} />}

    //   {offerDataModal && (
    //     <OfferDataModal
    //       open={offerDataModal}
    //       onCancel={() => {
    //         setOfferDataModal(false);
    //       }}
    //       onOk={() => setOfferDataModal(false)}
    //       report={report}
    //     />
    //   )}

    //   <Top>
    //     {/* <Side></Side> */}
    //     <Title>Dane oferty</Title>
    //     {/* <Side>
    //       <Button onClick={() => setOfferDataModal(true)}>Edytuj dane</Button>
    //     </Side> */}
    //   </Top>

    //   <Content>
    //     <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "16px" }}>
    //       <Form
    //         id="offerDataForm"
    //         style={{ width: "100%" }}
    //         form={form}
    //         autoComplete="off"
    //         layout="vertical"
    //         initialValues={getInitialValues()}
    //         onValuesChange={onChangeForm}
    //       >
    //         <Form.Item label="Nazwa projektu" name="projectName">
    //           <Input />
    //         </Form.Item>

    //         <Form.Item label="Inwestor" name="investor">
    //           <Input />
    //         </Form.Item>

    //         <RowTable>
    //           <Form.Item label="Telefon" name="phone" style={{ width: "100%" }}>
    //             <Input />
    //           </Form.Item>
    //           <Form.Item label="Email" name="email" style={{ width: "100%" }}>
    //             <Input />
    //           </Form.Item>
    //         </RowTable>

    //         <RowTable>
    //           <Form.Item label="Ulica" name="street" style={{ width: "100%" }}>
    //             <Input />
    //           </Form.Item>

    //           <Form.Item label="Numer domu/mieszkania" name="houseNumber" style={{ width: "100%" }}>
    //             <Input />
    //           </Form.Item>
    //         </RowTable>

    //         <RowTable>
    //           <Form.Item label="Kod pocztowy" name="zipCode" style={{ width: "100%" }}>
    //             <Input />
    //           </Form.Item>

    //           <Form.Item label="Miejscowość" name="city" style={{ width: "100%" }}>
    //             <Input />
    //           </Form.Item>
    //         </RowTable>

    //         <Form.Item label="Ważność oferty [dni]" name="validDays">
    //           <DomaInputNumber />
    //         </Form.Item>
    //       </Form>
    //     </div>

    //     <Divider />

    //     {/* <Row>
    //       <Label>Nazwa projektu:</Label>
    //       <Value>{report.requestData.projectName}</Value>
    //     </Row>
    //     <Row>
    //       <Label>Inwestor:</Label>
    //       <Value>{report.requestData.investor}</Value>
    //     </Row>
    //     <Row>
    //       <Label>Kontakt:</Label>
    //       <Value>
    //         {report.requestData.email}, {report.requestData.phone}
    //       </Value>
    //     </Row>
    //     <Row>
    //       <Label>Adres:</Label>
    //       <Value>
    //         {report.requestData.street} {report.requestData.houseNumber}, {report.requestData.zipCode}{" "}
    //         {report.requestData.city}
    //       </Value>
    //     </Row>
    //     <Row>
    //       <Label>Ważność oferty:</Label>
    //       <Value>{report.requestData.validDays}</Value>
    //     </Row> */}
    //   </Content>

    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "flex-end",
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         width: "100%",
    //         gap: "8px",
    //       }}
    //     >
    //       {isReadyToGenerateReport() ? (
    //         <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "16px" }}>
    //           <OfferButtonsRow>
    //             {report.generated && !report.shouldRegenerated && (
    //               <a style={{ width: "100%" }} target="_blank" rel="noopener noreferrer" href={getReportUrl()}>
    //                 {report.generated && <Button style={{ width: "100%" }}>ZOBACZ RAPORT</Button>}
    //               </a>
    //             )}

    //             {report.generated && !report.shouldRegenerated && (
    //               <Button
    //                 style={{ width: "100%", background: "#caf1a0" }}
    //                 onClick={() => {
    //                   copyTextToClipboard(getReportUrl());
    //                   successCopyUrl(getActiveReportCode());
    //                 }}
    //               >
    //                 SKOPIUJ LINK
    //               </Button>
    //             )}
    //           </OfferButtonsRow>

    //           <Button
    //             style={{ width: "100%" }}
    //             onClick={() => {
    //               setWaitingApi(true);
    //               dispatch(generateReport());
    //             }}
    //           >
    //             {report.generated && !report.shouldRegenerated ? "WYGENERUJ NOWY RAPORT" : "WYGENERUJ RAPORT"}
    //           </Button>

    //           {report.generated && (
    //             <Button
    //               style={{ width: "100%" }}
    //               type="primary"
    //               onClick={() => {
    //                 dispatch(updateReport(report.responseData?.id as string));
    //               }}
    //             >
    //               AKTUALIZUJ WYGENEROWNY RAPORT
    //             </Button>
    //           )}
    //         </div>
    //       ) : (
    //         <Alert
    //           message="Aby wygenerować ofertę, wypełnij wszystkie informacje o projekcie!"
    //           type="error"
    //           showIcon
    //         />
    //       )}
    //     </div>
    //   </div>
    // </Container>
  );
};
export default OfferInformations;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  width: 100%;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
`;
const Side = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000072;
  font-size: 14px;
`;

const Content = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  gap: 4px;
`;

const Row = styled.div`
  width: 100%;
  color: #00000072;
  font-size: 14px;
  display: flex;
`;

const Label = styled.div`
  width: 150px;
  color: #00000072;
  font-size: 14px;
`;

const Value = styled.div`
  width: 100%;
  color: #000000;
  font-size: 14px;
`;

const DomaSpin = styled(DSpin)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: "999";
`;

const OfferButtonsRow = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;

  @media (max-width: 1150px) {
    flex-direction: column;
  }
`;

const RowTable = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 32px;
`;
