import { Card, Col, Form, List, Row, Select, Tag } from "antd";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { createFilterOption, getProductsByCategoryForSelect } from "../../../../../../helpers/Helpers";
import { IProduct, IRootState, IWindowIntegration } from "../../../../../../models/Models";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import {
  addAdditionProductsToWindowHole,
  addWindowProductsToWindowHole,
  IWindowHoleState,
} from "../../../../../../redux/calculationProcess/windowHolesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

type Props = {};

const OtherMaterialData: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);

  const windowHoles = useAppSelector((state) => state.windowHoles);

  const getOnlyProductsForCategory = (
    products: IProduct[],
    selectedBySize: boolean,
    targetWidth: number,
    targetHeight: number,
    selectedCategory: string
  ) => {
    const category = productCategories.find((x) => x.code === selectedCategory);
    const windowProducts = products.filter((x) => x.productCategoryId === category?.id);

    if (selectedBySize === true) {
      const matchingWindows = windowProducts.filter(
        (product) => product.width === targetWidth && product.height === targetHeight
      );
      const otherWindows = windowProducts.filter(
        (product) => !(product.width === targetWidth && product.height === targetHeight)
      );

      // Połączenie pasujących okien z pozostałymi
      return [...matchingWindows, ...otherWindows];
    }

    return windowProducts;
  };

  return (
    <Container>
      <List
        dataSource={windowHoles}
        renderItem={(x, i) => (
          <List.Item key={i} style={{ padding: "16px 0px 16px 0px" }}>
            <Row gutter={16} style={{ width: "100%" }}>
              <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                <Tag
                  style={{
                    display: "flex",
                    marginTop: "16px",
                    marginBottom: "16px",
                    fontSize: "14px",
                    height: "100%",
                    fontWeight: "bold",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  Otwór <br />
                  {(x.width * 100).toFixed(0)}x{(x.height * 100).toFixed(0)} [cm]
                </Tag>
              </Col>
              <Col span={10} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item label="Okna" labelCol={{ span: 24 }} style={{ width: "100%" }}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Wybierz produkt!"
                    value={x.windowProducts}
                    options={getProductsByCategoryForSelect(
                      getOnlyProductsForCategory(products, true, x.width, x.height, "windows"),
                      productCategories,
                      ["windows"],
                      companies,
                      productColors,
                      productModels
                    )}
                    filterOption={createFilterOption()}
                    showSearch
                    onChange={(value: string[]) => {
                      dispatch(addWindowProductsToWindowHole({ index: x.index, productIds: value }));
                      dispatch(invalidateCalculation());
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={10} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item label="Kołnierze i/lub inne produkty" labelCol={{ span: 24 }} style={{ width: "100%" }}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Wybierz produkt!"
                    value={x.additionProducts}
                    options={getProductsByCategoryForSelect(
                      getOnlyProductsForCategory(
                        products,
                        true,
                        x.width,
                        x.height,
                        "window-collars-and-accessories"
                      ),
                      productCategories,
                      ["window-collars-and-accessories"],
                      companies,
                      productColors,
                      productModels
                    )}
                    showSearch
                    filterOption={createFilterOption()}
                    onChange={(value: string[]) => {
                      dispatch(addAdditionProductsToWindowHole({ index: x.index, productIds: value }));
                      dispatch(invalidateCalculation());
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Container>
  );
};

export default OtherMaterialData;

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

const Section = styled(Card)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const TitleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
`;

const SubTitleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-style: italic;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const FormContainer = styled.div`
  width: 100%;
  justify-content: left;
`;
