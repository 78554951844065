export enum EdgeType {
  Eaves = 1, // okap
  EdgeRidge = 3, // kalenica narożna
  Valley = 4, // koszowa
  Ridge = 5, // kalenica pozioma
  LeftRake = 7, // szczytowa lewa - Zamiana tymczasowa z prawym
  RightRake = 6, // szczytowa prawa - Zamiana tymczasowa z lewym
  Hole = 9, // krawędź wokół otworu
  RoofWall = 10,
  Window = 11,
  Chimney = 12,
  Empty = 99,
}
