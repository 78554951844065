import { Button, Modal } from "antd";
import React, { FC, useRef } from "react";
import {
  IAddPrincingSystemElement,
  IPrincingSystemElement,
  IUpdatePrincingSystemElement,
} from "../../../models/Models";
import PrincingPosition, { RefObject } from "./PrincingPosition";

type Props = {
  princingSystemElement: IPrincingSystemElement | null;
  closeModal?: any;
  addPrincingSystemElement?: any;
  updatePrincingSystemElement?: any;
  deletePrincingSystemElement?: any;
};

const EditPrincingPosition: FC<Props> = ({
  princingSystemElement,
  closeModal,
  updatePrincingSystemElement,
  deletePrincingSystemElement,
}) => {
  const childRef = useRef<RefObject>(null);

  // Funkcja która pobiera dane z dziecka i podaje dalej do zapisu
  const handleOkClick = () => {
    if (childRef.current) {
      const formData = childRef.current.getFormData();

      if (princingSystemElement) {
        const model: IUpdatePrincingSystemElement = {
          id: princingSystemElement.id,
          name: formData.name,
          relation: formData.relation,
          price: formData.price,
        };
        updatePrincingSystemElement(model);
      }
      closeModal();
    }
  };

  // Funkcja która pobiera dane z dziecka i podaje dalej do zapisu
  const handleDeleteClick = () => {
    if (princingSystemElement) {
      deletePrincingSystemElement(princingSystemElement.id);
    }
    closeModal();
  };

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="delete" danger onClick={() => handleDeleteClick()}>
        Usuń
      </Button>
    );

    buttons.push(
      <Button key="back" onClick={() => closeModal()}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button key="ok" type="primary" onClick={() => handleOkClick()}>
        Zapisz
      </Button>
    );

    return buttons;
  };
  return (
    <Modal
      centered
      width={"60%"}
      open={true}
      title={`Edytuj pozycję: ${princingSystemElement?.name}`}
      footer={getButtonsInFooter()}
    >
      <PrincingPosition ref={childRef} princingSystemElement={princingSystemElement} />
    </Modal>
  );
};
export default EditPrincingPosition;
