import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IRoofPlane, IRoofPoint, IUser } from "../../models/Models";

const initialState: Array<IRoofPlane> = [];

export const roofPlanesSlice = createSlice({
  name: "roofPlanes",
  initialState,
  reducers: {
    addRoofPlane: (state, action: PayloadAction<IRoofPlane>) => {
      let p = action.payload;
      return [...state, p];
    },
    addRoofPlanes: (state, action: PayloadAction<Array<IRoofPlane>>) => {
      let ps = action.payload;
      return [...state, ...ps];
    },
    replaceRoofPlanes: (state, action: PayloadAction<Array<IRoofPlane>>) => {
      let ps = action.payload;
      return ps;
    },
    removeRoofPlane: (state, action: PayloadAction<IRoofPlane>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRoofPlanes: (state) => {
      return initialState;
    },
    setSavedRoofPlanes: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRoofPlane,
  addRoofPlanes,
  replaceRoofPlanes,
  removeRoofPlane,
  clearRoofPlanes,
  setSavedRoofPlanes,
} = roofPlanesSlice.actions;
export default roofPlanesSlice.reducer;
