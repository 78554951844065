import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMetalSheet } from "../../models/Models";

const initialState: IMetalSheet[] = [];

export const metalSheetsSlice = createSlice({
  name: "metalSheets",
  initialState,
  reducers: {
    addMetalSheet: (state, action: PayloadAction<IMetalSheet>) => {
      let data = action.payload;
      return [...state, data];
    },
    setMetalSheets: (state, action: PayloadAction<IMetalSheet[]>) => {
      let data = action.payload;
      return data;
    },
    updateMetalSheet: (state, action: PayloadAction<IMetalSheet>) => {
      const index = state.findIndex((sheet) => sheet.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
    removeMetalSheet: (state, action: PayloadAction<IMetalSheet>) => {
      let e = action.payload;
      return state.filter((o) => o.id !== e.id);
    },
    clearMetalSheets: (state) => {
      return initialState;
    },
    setSavedMetalSheets: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  setMetalSheets,
  addMetalSheet,
  removeMetalSheet,
  updateMetalSheet,
  clearMetalSheets,
  setSavedMetalSheets,
} = metalSheetsSlice.actions;
export default metalSheetsSlice.reducer;
