import Konva from "konva";
import { debounce, throttle } from "lodash";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Circle, Group, Line, Text } from "react-konva";
import { EdgeType } from "../../../../../models/Enums";
import { IBasicPoint, IRoofEdge, IRoofPlane } from "../../../../../models/Models";
import {
  IBayObject,
  IBaysState,
  updateAllBayState,
  updateBayObject,
} from "../../../../../redux/drawing/baysSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

// Obliczanie kąta nachylenia odcinka między punktami
const angleRadians = (p1: IBasicPoint, p2: IBasicPoint) => Math.atan2(p2.y - p1.y, p2.x - p1.x);

// Obliczanie przesunięcia tekstu względem linii
const getOffsetX = (angleRadians: number) => 10 * Math.cos(angleRadians + Math.PI / 2);

const getOffsetY = (angleRadians: number) => 10 * Math.sin(angleRadians + Math.PI / 2);

// Funkcja zwracająca długośc pomiedzy dwoma punktami IBAsicPoint oraz uwzględniająca skalę scale
const getLength = (p1: IBasicPoint, p2: IBasicPoint, scale: number) =>
  Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2) / scale;

// Funkcja obliczająca równanie prostej Ax + By + C = 0 na podstawie dwóch punktów
function lineEquationFromTwoPoints(p1: IBasicPoint, p2: IBasicPoint) {
  const A = p2.y - p1.y;
  const B = p1.x - p2.x;
  const C = A * p1.x + B * p1.y;
  return { A, B, C };
}

// Funkcja znajdująca punkt przecięcia dwóch linii
function findIntersection(
  line1: { A: number; B: number; C: number },
  line2: { A: number; B: number; C: number }
): IBasicPoint | null {
  const determinant = line1.A * line2.B - line2.A * line1.B;

  if (determinant === 0) {
    // Linie są równoległe
    return null;
  } else {
    const x = (line2.B * line1.C - line1.B * line2.C) / determinant;
    const y = (line1.A * line2.C - line2.A * line1.C) / determinant;
    return { x, y };
  }
}

// Funkcja główna
function findParallelIntersection(
  p0: IBasicPoint,
  p1: IBasicPoint,
  p10r: IBasicPoint,
  pwR: IBasicPoint
): IBasicPoint | null {
  // Obliczanie równania linii p0p1
  const lineP0P1 = lineEquationFromTwoPoints(p0, p1);

  // Równanie linii równoległej przechodzącej przez pwR
  const lineParallel = { ...lineP0P1, C: lineP0P1.A * pwR.x + lineP0P1.B * pwR.y };

  // Obliczanie równania linii p1p10r
  const lineP1P10r = lineEquationFromTwoPoints(p1, p10r);

  // Znajdowanie punktu przecięcia
  const intersection = findIntersection(lineP1P10r, lineParallel);
  return intersection;
}

function findPointBeyond(startPoint: IBasicPoint, endPoint: IBasicPoint, distance: number): IBasicPoint {
  // Krok 1: Oblicz wektor między startPoint i endPoint
  const vector = {
    x: endPoint.x - startPoint.x,
    y: endPoint.y - startPoint.y,
  };

  // Krok 2: Oblicz długość wektora (odległość między punktami)
  const length = Math.sqrt(vector.x ** 2 + vector.y ** 2);

  // Krok 3: Znormalizuj wektor do wektora jednostkowego
  const unitVector = {
    x: vector.x / length,
    y: vector.y / length,
  };

  let result = {
    x: endPoint.x + unitVector.x * distance,
    y: endPoint.y + unitVector.y * distance,
  };

  if (isNaN(result.x) || isNaN(result.y)) {
    result = {
      x: endPoint.x,
      y: endPoint.y,
    };
  }
  return result;
}

const getPointsForMoreLengthLine = (p1: IBasicPoint, p2: IBasicPoint, x: number) => {
  // Obliczanie wektora linii (od p1 do p2)
  const lineVector: IBasicPoint = {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };
  // Długość wektora linii
  const lineLength: number = Math.sqrt(lineVector.x ** 2 + lineVector.y ** 2);
  // Obliczanie wektora jednostkowego (normalizacja)
  const unitVector: IBasicPoint = {
    x: lineVector.x / lineLength,
    y: lineVector.y / lineLength,
  };

  // Nowe punkty po wydłużeniu linii
  const newP1: IBasicPoint = {
    x: p1.x - unitVector.x * x,
    y: p1.y - unitVector.y * x,
  };
  const newP2: IBasicPoint = {
    x: p2.x + unitVector.x * x,
    y: p2.y + unitVector.y * x,
  };

  return { newP1, newP2 };
};

function getClosestPointOnLine(p1: IBasicPoint, p2: IBasicPoint, currentPos: IBasicPoint) {
  const lineVec = { x: p2.x - p1.x, y: p2.y - p1.y };
  const p1ToMouseVec = { x: currentPos.x - p1.x, y: currentPos.y - p1.y };

  const lineLength = Math.sqrt(lineVec.x * lineVec.x + lineVec.y * lineVec.y);
  const lineVecNormalized = { x: lineVec.x / lineLength, y: lineVec.y / lineLength };

  // Projektowanie wektora od p1 do myszy na linię (p1,p2)
  const t = p1ToMouseVec.x * lineVecNormalized.x + p1ToMouseVec.y * lineVecNormalized.y;

  // Ograniczenie t do naszej linii, aby nie wychodzić poza p1 i p2
  const clampedT = Math.max(0, Math.min(lineLength, t));

  // Obliczenie końcowej pozycji na linii
  const closestPoint = { x: p1.x + lineVecNormalized.x * clampedT, y: p1.y + lineVecNormalized.y * clampedT };

  return closestPoint;
}

type Props = {
  scale: number; // skala wymiaru
  zoomScale: number;
  plane: IRoofPlane | null;
  point0Ref: React.RefObject<Konva.Circle>;
};

// Funkcja zaczyna działać od momentu kliknięcia w modalu przycisku dodaj na rysunku i działa do momentu wygenerowania nowego dachu z wykuszem
const AddBayFunction: FC<Props> = ({ scale, zoomScale, plane, point0Ref }) => {
  const dispatch = useAppDispatch();
  const groupRef = useRef<Konva.Group>(null); // Ref do przechowywania odniesienia

  const mp = useAppSelector((state) => state.mousePosition);
  const edges = useAppSelector((state) => state.roofEdges);
  const points = useAppSelector((state) => state.roofPoints);
  const planes = useAppSelector((state) => state.roofPlanes);

  const [isDragging, setIsDragging] = useState(false); // Czy przeciągamy myszką punkt podczas edycji

  const [eaveEdges, setEaveEdges] = useState<IRoofEdge[]>([]); // Lista krawędzi okapu

  // Wybrana krawędź okapu i płaszczyzna do dodania wykusza
  const [selectedEdgeAndPlane, setSelectedEdgeAndPlane] = useState<{ edge: IRoofEdge; plane: IRoofPlane } | null>(
    null
  );

  const [addBayStatus, setAddBayStatus] = useState<"adding" | "editing">("adding"); // Status dodawania wykusza
  const baysState = useAppSelector((state) => state.baysState); // Główny redux z danymi wykusza

  // useEffect(() => {
  //   console.log("RENDER");
  // }, []);

  // Po załadowaniu wszystkich krawędzi wybieramy tylko okapy i zapisujemy je
  useEffect(() => {
    // Uruchamiamy tylko wtedy gdy nie ma jeszcze krawędzi okapu
    if (eaveEdges.length === 0 && edges) {
      const allEaveEdges = [];
      for (const edge of edges) {
        if (edge.type === EdgeType.Eaves) {
          allEaveEdges.push(edge);
        }
      }
      setEaveEdges(allEaveEdges);
    }
  }, [edges]);

  useEffect(() => {
    if (baysState && baysState.bayObject && baysState.startPoint && baysState.endPoint) {
      // console.log("RENDER BAYSTATE");
      generateDrawBayObject(
        baysState.startPoint,
        baysState.endPoint,
        baysState.bayObject.p0,
        baysState.w1,
        baysState.w10,
        baysState.w2,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );
    }
  }, [baysState.w1, baysState.w10, baysState.w2, baysState.h1, baysState.overhangFront, baysState.overhangSide]);

  // Podczas dodawania obiketu musi generowac się na żywo obrazek i zapisywać w REDUX aby mógł być wyświetlany na rysunku
  // To samo co w funkcji addBayToDrawing ale poza zmianą statusu na editing
  useEffect(() => {
    // Zadziała tylko gdy w trakcie dodawania i gdy mam ydane krawędiz oraz połaci
    if (addBayStatus === "adding" && selectedEdgeAndPlane) {
      // Wygenerowanie danych do obiektu i zapis w REDUX
      const point1 = points.find((p) => p.id === selectedEdgeAndPlane.edge.startPointId);
      const startPoint: IBasicPoint = { x: point1?.x as number, y: point1?.y as number }; // Punkt startowy krawędzi okapu

      const point2 = points.find((p) => p.id === selectedEdgeAndPlane.edge.endPointId);
      const endPoint: IBasicPoint = { x: point2?.x as number, y: point2?.y as number }; // Punkt końcowy krawędzi okapu

      // Szukamy punktu na okapie i w poblizu myszki
      const nearestPoint = getClosestPointOnLine(startPoint, endPoint, mp);

      // Aktualizacja danych w REDUX - obliczenie i zapisanie danych
      generateDrawBayObject(
        startPoint,
        endPoint,
        nearestPoint,
        baysState.w1,
        baysState.w10,
        baysState.w2,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );
    }
  }, [mp]);

  // Funkcja wywoływana w momencie kliknięcia na krawędiz okapu i dodanie rysunku
  const addBayToDrawing = () => {
    // Zadziała tylko gdy  wtrakcie dodawania i gdy mam ydane krawędiz oraz połaci
    if (addBayStatus === "adding" && selectedEdgeAndPlane) {
      // Wygenerowanie danych do obiektu i zapis w REDUX
      const point1 = points.find((p) => p.id === selectedEdgeAndPlane.edge.startPointId);
      const startPoint: IBasicPoint = { x: point1?.x as number, y: point1?.y as number }; // Punkt startowy krawędzi okapu

      const point2 = points.find((p) => p.id === selectedEdgeAndPlane.edge.endPointId);
      const endPoint: IBasicPoint = { x: point2?.x as number, y: point2?.y as number }; // Punkt końcowy krawędzi okapu

      // Szukamy punktu na okapie i w poblizu myszki
      const nearestPoint = getClosestPointOnLine(startPoint, endPoint, mp);

      // Aktualizacja danych w REDUX - obliczenie i zapisanie danych
      generateDrawBayObject(
        startPoint,
        endPoint,
        nearestPoint,
        baysState.w1,
        baysState.w10,
        baysState.w2,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );

      // Zmiana statusu na edycję
      setAddBayStatus("editing");
    }
  };

  // Gdy najedziemy myszka na krawędź okapu to określamy jaka to krawędź oraz do jakiej połaci należy
  const handleMouseEnter = (e: any, edge: IRoofEdge) => {
    if (addBayStatus === "adding") {
      // Jeśli już wybrano krawędź i płaszczyznę to nie można wybrać kolejnej i od razu przerywamy działanie funkcji
      if (selectedEdgeAndPlane) {
        return;
      }

      // Po najechaniu myszka mamy od razu dostęp do wybranej krawędzi
      const selectedEdge = edge;

      // Szukamy roofPlane, do której należy krawędź
      const selectedPlane = planes.find((p) => p.edgeIds?.includes(selectedEdge.id));

      if (selectedEdge && selectedPlane) {
        setSelectedEdgeAndPlane({ edge: selectedEdge, plane: selectedPlane });
      } else {
        setSelectedEdgeAndPlane(null);
      }
    }
  };

  // Gdy opuszcamy myszkę w trakcie dodawania wykusza z krawędzi okapu ale zanim został dodany wykusz
  const handleMouseLeave = (e: any, edge: IRoofEdge) => {
    if (addBayStatus === "adding" && selectedEdgeAndPlane) {
      setSelectedEdgeAndPlane(null); // Czyścimy wybraną krawędź i płaszczyznę

      // Czyścimy dane w REDUX ale tylko bayObject
      dispatch(updateBayObject(null));
    }
  };

  // Funkcja do sprawdzenia - TODO
  const handleMouseMove = (e: any) => {
    e.evt.preventDefault();
    e.evt.stopPropagation();
  };

  // Funkcja generująca dane do rysunku oraz aktualizująca dane w REDUX
  const generateDrawBayObject = (
    p1: IBasicPoint,
    p2: IBasicPoint,
    p0: IBasicPoint,
    w1: number,
    w10: number,
    w2: number,
    h1: number,
    overhangFront: number,
    overhangSide: number,
    angle?: number,
    type?: "bay" | "bay-rectangle"
  ) => {
    type = type || "bay";
    w1 = w1 * scale;
    w10 = w10 * scale;
    w2 = w2 * scale;
    h1 = (h1 / 2) * scale;
    overhangFront = overhangFront * scale;
    overhangSide = overhangSide * scale;

    const insertPoint = p0; // Punkt na krawędzi okapu, na którym ma być dodany wykusz

    if (type === "bay") {
      // Zaznaczamy punkt Pw1 na lini prostopadłej do krawędzi okapu i w odległości w1 od punktu insertPoint
      const x1 = insertPoint.x + (w1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y1 = insertPoint.y - (w1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P10L w odległości h1 od punktu insertPoint na lini krawędzi okapu po lewej stronie i w góę o w10
      const x1bis = insertPoint.x + (w10 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2); // Pomocnicze położenie punktu
      const y1bis = insertPoint.y - (w10 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      const x10L = x1bis - (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y10L = y1bis - (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P10R w odległości h1 od punktu insertPoint na lini krawędzi okapu po prawej stronie i w góę o w10
      const x10R = x1bis + (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y10R = y1bis + (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt Pw2 na lini prostopadłej do krawędzi okapu i w odległości w2 od punktu insertPoint
      const x2 = insertPoint.x - (w2 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y2 = insertPoint.y + (w2 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P0L w odległości h1 od punktu insertPoint na lini krawędzi okapu po lewej stronie
      const x0L = insertPoint.x - (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y0L = insertPoint.y - (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P0R w odległości h1 od punktu insertPoint na lini krawędzi okapu po prawej stronie
      const x0R = insertPoint.x + (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y0R = insertPoint.y + (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P2L w odległości h1 od punktu insertPoint na lini krawędzi okapu po lewej stronie
      const x2L = x2 - (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y2L = y2 - (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P2R w odległości h1 od punktu insertPoint na lini krawędzi okapu po prawej stronie
      const x2R = x2 + (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y2R = y2 + (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      let p10L = { x: x10L, y: y10L };
      let p2L = { x: x2L, y: y2L };
      let p10R = { x: x10R, y: y10R };
      let p2R = { x: x2R, y: y2R };

      // Domyśłne punkty dla okpaów
      let pwL = { x: x2L, y: y2L };
      let pwR = { x: x2R, y: y2R };
      let pwLg = { x: x10L, y: y10L };
      let pwRg = { x: x10R, y: y10R };

      // Jeśli okap frontowy jest
      if (overhangFront > 0) {
        // Obliczamy położenie pwL (jest to punkt na linii prostopadłej do p2L, p2R w odległości overhangFront od punktu p2L)
        pwL = findPointBeyond(p10L, p2L, -1 * overhangFront);

        // Obliczamy położenie pwR (jest to punkt na linii prostopadłej do p2L, p2R w odległości overhangFront od punktu p2R)
        pwR = findPointBeyond(p10R, p2R, -1 * overhangFront);
      }

      // Jeśli okap boczny jest
      if (overhangSide > 0) {
        // Obliczamy położenie pwLg (jest to punkt na linii prostopadłej do p0L, p2L w odległości overhangSide od punktu p0L)
        pwL = findPointBeyond(pwR, pwL, -1 * overhangSide);

        // Obliczamy położenie pwRg (jest to punkt na linii prostopadłej do p0R, p2R w odległości overhangSide od punktu p0R)
        pwR = findPointBeyond(pwL, pwR, -1 * overhangSide);

        // Znajdowanie punktu przecięcia dla R
        const newPwRg = findParallelIntersection(insertPoint, { x: x1, y: y1 }, { x: x10R, y: y10R }, pwR);
        if (newPwRg) pwRg = newPwRg;

        // Znajdowanie punktu przecięcia dla L
        const newPwLg = findParallelIntersection(insertPoint, { x: x1, y: y1 }, { x: x10L, y: y10L }, pwL);
        if (newPwLg) pwLg = newPwLg;
      }

      const bayModel: IBayObject = {
        p0: insertPoint,
        p1: { x: x1, y: y1 },
        p2: { x: x2, y: y2 },
        p10L: { x: x10L, y: y10L },
        p10R: { x: x10R, y: y10R },
        p0L: { x: x0L, y: y0L },
        p0R: { x: x0R, y: y0R },
        p2L: { x: x2L, y: y2L },
        p2R: { x: x2R, y: y2R },
        pw: { x: x2, y: y2 },
        pwL: pwL,
        pwR: pwR,
        pwLg: pwLg,
        pwRg: pwRg,
      };

      const edgeId = selectedEdgeAndPlane?.edge.id as string;
      const planeId = selectedEdgeAndPlane?.plane.id as string;

      const model: IBaysState = {
        roofPlaneId: planeId,
        roofEdgeId: edgeId,
        startPoint: p1,
        endPoint: p2,
        w1: w1 / scale,
        w10: w10 / scale,
        w2: w2 / scale,
        h1: (h1 * 2) / scale,
        overhangFront: overhangFront / scale,
        overhangSide: overhangSide / scale,
        bayObject: bayModel,
        angle: 0,
        bayType: "bay",
      };

      dispatch(updateAllBayState(model));
    } else {
      // Zaznaczamy punkt Pw1 na lini prostopadłej do krawędzi okapu i w odległości w1 od punktu insertPoint
      const x1 = insertPoint.x + (w1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y1 = insertPoint.y - (w1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P10L w odległości h1 od punktu insertPoint na lini krawędzi okapu po lewej stronie i w góę o w1
      const x1bis = insertPoint.x + (w1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2); // Pomocnicze położenie punktu
      const y1bis = insertPoint.y - (w1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      const x10L = x1bis - (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y10L = y1bis - (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P10R w odległości h1 od punktu insertPoint na lini krawędzi okapu po prawej stronie i w góę o w10
      const x10R = x1bis + (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y10R = y1bis + (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt Pw2 na lini prostopadłej do krawędzi okapu i w odległości w2 od punktu insertPoint
      const x2 = insertPoint.x - (w2 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y2 = insertPoint.y + (w2 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P0L w odległości h1 od punktu insertPoint na lini krawędzi okapu po lewej stronie
      const x0L = insertPoint.x - (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y0L = insertPoint.y - (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P0R w odległości h1 od punktu insertPoint na lini krawędzi okapu po prawej stronie
      const x0R = insertPoint.x + (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y0R = insertPoint.y + (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P2L w odległości h1 od punktu insertPoint na lini krawędzi okapu po lewej stronie
      const x2L = x2 - (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y2L = y2 - (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      // Zaznaczamy punkt P2R w odległości h1 od punktu insertPoint na lini krawędzi okapu po prawej stronie
      const x2R = x2 + (h1 * (p2.x - p1.x)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const y2R = y2 + (h1 * (p2.y - p1.y)) / Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);

      let p10L = { x: x10L, y: y10L };
      let p2L = { x: x2L, y: y2L };
      let p10R = { x: x10R, y: y10R };
      let p2R = { x: x2R, y: y2R };

      // Domyślne punkty dla okpaów
      let pwL = { x: x2L, y: y2L };
      let pwR = { x: x2R, y: y2R };
      let pwLg = { x: x10L, y: y10L };
      let pwRg = { x: x10R, y: y10R };

      // Jeśli okap frontowy jest
      if (overhangFront > 0) {
        // Obliczamy położenie pwL (jest to punkt na linii prostopadłej do p2L, p2R w odległości overhangFront od punktu p2L)
        pwL = findPointBeyond(p10L, p2L, -1 * overhangFront);

        // Obliczamy położenie pwR (jest to punkt na linii prostopadłej do p2L, p2R w odległości overhangFront od punktu p2R)
        pwR = findPointBeyond(p10R, p2R, -1 * overhangFront);
      }

      // Jeśli okap boczny jest
      if (overhangSide > 0) {
        // Obliczamy położenie pwLg (jest to punkt na linii prostopadłej do p0L, p2L w odległości overhangSide od punktu p0L)
        pwL = findPointBeyond(pwR, pwL, -1 * overhangSide);

        // Obliczamy położenie pwRg (jest to punkt na linii prostopadłej do p0R, p2R w odległości overhangSide od punktu p0R)
        pwR = findPointBeyond(pwL, pwR, -1 * overhangSide);

        // Znajdowanie punktu przecięcia dla R
        const newPwRg = findParallelIntersection(insertPoint, { x: x1, y: y1 }, { x: x10R, y: y10R }, pwR);
        if (newPwRg) pwRg = newPwRg;

        // Znajdowanie punktu przecięcia dla L
        const newPwLg = findParallelIntersection(insertPoint, { x: x1, y: y1 }, { x: x10L, y: y10L }, pwL);
        if (newPwLg) pwLg = newPwLg;
      }

      const bayModel: IBayObject = {
        p0: insertPoint,
        p1: { x: x1, y: y1 },
        p2: { x: x2, y: y2 },
        p10L: { x: x10L, y: y10L },
        p10R: { x: x10R, y: y10R },
        p0L: { x: x0L, y: y0L },
        p0R: { x: x0R, y: y0R },
        p2L: { x: x2L, y: y2L },
        p2R: { x: x2R, y: y2R },
        pw: { x: x2, y: y2 },
        pwL: pwL,
        pwR: pwR,
        pwLg: pwLg,
        pwRg: pwRg,
      };

      const edgeId = selectedEdgeAndPlane?.edge.id as string;
      const planeId = selectedEdgeAndPlane?.plane.id as string;

      const model: IBaysState = {
        roofPlaneId: planeId,
        roofEdgeId: edgeId,
        startPoint: p1,
        endPoint: p2,
        w1: w1 / scale,
        w10: w10 / scale,
        w2: w2 / scale,
        h1: (h1 * 2) / scale,
        overhangFront: overhangFront / scale,
        overhangSide: overhangSide / scale,
        bayObject: bayModel,
        angle: angle as number,
        bayType: "bay-rectangle",
      };

      dispatch(updateAllBayState(model));
    }
  };

  // Rozpoczęcie przemieszczenia punktu
  const handleDragStart = () => {
    setIsDragging(true);
  };

  // Funkcje przemieszczania punktów - P0
  const handleDragMoveP0 = (e: any) => {
    // Tylko wtedy gdy mamy obiekt wykusza już zdefiniowany
    if (baysState && baysState.bayObject) {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };

      if (isDragging && point && baysState.startPoint && baysState.endPoint) {
        // Gdy myszka przemieszca się wzdłóż lini od punktu startowego do końcowego (w przybliżeniu)
        point = getClosestPointOnLine(baysState.startPoint, baysState.endPoint, point);

        e.target.position(point);

        generateDrawBayObject(
          baysState.startPoint,
          baysState.endPoint,
          point,
          baysState.w1,
          baysState.w10,
          baysState.w2,
          baysState.h1,
          baysState.overhangFront,
          baysState.overhangSide,
          baysState.angle,
          baysState.bayType
        );
      }
    }
  };

  const handleDragMoveP1 = (e: any) => {
    const p1 = baysState.bayObject?.p0 as IBasicPoint;
    const p2 = baysState.bayObject?.p1 as IBasicPoint;

    const { newP1, newP2 } = getPointsForMoreLengthLine(p1, p2, 5); // Wydłuża linie o 5 jednostek w obie strony

    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };
    // Uzyskanie pozycji na linii najbliższej bieżącej pozycji myszy
    const newPoint = getClosestPointOnLine(newP1, newP2, point);

    e.target.position(newPoint);

    if (isDragging && baysState.bayObject && newPoint && baysState.startPoint && baysState.endPoint) {
      // Obliczamy nową odległość w1 (pomiędzy scaledMp a p0)
      let w1 = Math.sqrt(
        (newPoint.x - baysState.bayObject.p0.x) ** 2 + (newPoint.y - baysState.bayObject.p0.y) ** 2
      );

      if (isNaN(w1)) w1 = 0;

      generateDrawBayObject(
        baysState.startPoint,
        baysState.endPoint,
        baysState.bayObject.p0,
        w1 / scale,
        baysState.w10,
        baysState.w2,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );
    }
  };

  const handleDragMoveP2 = (e: any) => {
    const p1 = baysState.bayObject?.p0 as IBasicPoint;
    const p2 = baysState.bayObject?.p1 as IBasicPoint;

    const { newP1, newP2 } = getPointsForMoreLengthLine(p1, p2, 9999); // Wydłuża linie o 5 jednostek w obie strony

    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };
    // Uzyskanie pozycji na linii najbliższej bieżącej pozycji myszy
    const newPoint = getClosestPointOnLine(newP1, newP2, point);

    e.target.position(newPoint);

    if (isDragging && baysState.bayObject && newPoint && baysState.startPoint && baysState.endPoint) {
      // Obliczamy wektor lini od baysState.bayObject.p0 do point
      const pointVec: IBasicPoint = {
        x: newPoint.x - baysState.bayObject.p0.x,
        y: newPoint.y - baysState.bayObject.p0.y,
      };
      const lineVec = { x: newP2.x - newP1.x, y: newP2.y - newP1.y };

      // Iloczyn skalarny wektorów
      const dotProduct = lineVec.x * pointVec.x + lineVec.y * pointVec.y;

      // Obliczamy nową odległość w2 (pomiędzy scaledMp a p0)
      let w2 = Math.sqrt(
        (newPoint.x - baysState.bayObject.p0.x) ** 2 + (newPoint.y - baysState.bayObject.p0.y) ** 2
      );
      if (isNaN(w2)) w2 = 0;

      if (w2 > 0 && w2 < 2) {
        w2 = 0;
      }

      if (dotProduct > 0) {
        w2 = -w2;
      } else {
        // generateDrawBayObject(
        //   baysState.startPoint,
        //   baysState.endPoint,
        //   baysState.bayObject.p0,
        //   baysState.w1,
        //   baysState.w10,
        //   w2,
        //   baysState.h1
        // );
      }

      generateDrawBayObject(
        baysState.startPoint,
        baysState.endPoint,
        baysState.bayObject.p0,
        baysState.w1,
        baysState.w10,
        w2 / scale,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );
    }
  };

  const handleDragMoveP10L = (e: any) => {
    const p1 = baysState.bayObject?.p10L as IBasicPoint;
    const p2 = baysState.bayObject?.p0L as IBasicPoint;

    const { newP1, newP2 } = getPointsForMoreLengthLine(p1, p2, 5); // Wydłuża linie o 5 jednostek w obie strony

    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };
    // Uzyskanie pozycji na linii najbliższej bieżącej pozycji myszy
    const newPoint = getClosestPointOnLine(newP1, newP2, point);

    e.target.position(newPoint);

    if (isDragging && baysState.bayObject && newPoint && baysState.startPoint && baysState.endPoint) {
      // Obliczamy nową odległość w10 (pomiędzy scaledMp a poL)
      let w10 = Math.sqrt(
        (newPoint.x - baysState.bayObject.p0L.x) ** 2 + (newPoint.y - baysState.bayObject.p0L.y) ** 2
      );

      if (isNaN(w10)) w10 = 0;

      generateDrawBayObject(
        baysState.startPoint,
        baysState.endPoint,
        baysState.bayObject.p0,
        baysState.w1,
        w10 / scale,
        baysState.w2,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );
    }
  };

  const handleDragMoveP10R = (e: any) => {
    const p1 = baysState.bayObject?.p10R as IBasicPoint;
    const p2 = baysState.bayObject?.p0R as IBasicPoint;

    const { newP1, newP2 } = getPointsForMoreLengthLine(p1, p2, 5); // Wydłuża linie o 5 jednostek w obie strony

    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };
    // Uzyskanie pozycji na linii najbliższej bieżącej pozycji myszy
    const newPoint = getClosestPointOnLine(newP1, newP2, point);

    e.target.position(newPoint);

    if (isDragging && baysState.bayObject && newPoint && baysState.startPoint && baysState.endPoint) {
      // Obliczamy nową odległość w10 (pomiędzy scaledMp a poL)
      let w10 = Math.sqrt(
        (newPoint.x - baysState.bayObject.p0R.x) ** 2 + (newPoint.y - baysState.bayObject.p0R.y) ** 2
      );

      if (isNaN(w10)) w10 = 0;

      generateDrawBayObject(
        baysState.startPoint,
        baysState.endPoint,
        baysState.bayObject.p0,
        baysState.w1,
        w10 / scale,
        baysState.w2,
        baysState.h1,
        baysState.overhangFront,
        baysState.overhangSide,
        baysState.angle,
        baysState.bayType
      );
    }
  };

  const handleDragMoveP0L = (e: any) => {
    if (baysState.bayObject && baysState.startPoint && baysState.endPoint) {
      const p1 = baysState.startPoint;
      const p2 = baysState.endPoint;

      const { newP1, newP2 } = getPointsForMoreLengthLine(p1, p2, 500); // Wydłuża linie o 5 jednostek w obie strony

      const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
      let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };
      // Uzyskanie pozycji na linii najbliższej bieżącej pozycji myszy
      const newPoint = getClosestPointOnLine(newP1, newP2, point);

      e.target.position(newPoint);

      if (isDragging && baysState.bayObject && newPoint) {
        // Obliczamy nową odległość h1 (pomiędzy scaledMp a poL)
        let h1 = Math.sqrt(
          (newPoint.x - baysState.bayObject.p0.x) ** 2 + (newPoint.y - baysState.bayObject.p0.y) ** 2
        );

        if (isNaN(h1)) h1 = 0;

        generateDrawBayObject(
          baysState.startPoint,
          baysState.endPoint,
          baysState.bayObject.p0,
          baysState.w1,
          baysState.w10,
          baysState.w2,
          (h1 * 2) / scale,
          baysState.overhangFront,
          baysState.overhangSide,
          baysState.angle,
          baysState.bayType
        );
      }
    }
  };

  const handleDragMoveP0R = (e: any) => {
    if (baysState.bayObject && baysState.startPoint && baysState.endPoint) {
      const p1 = baysState.startPoint;
      const p2 = baysState.endPoint;

      const { newP1, newP2 } = getPointsForMoreLengthLine(p1, p2, 500); // Wydłuża linie o 5 jednostek w obie strony

      const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
      let point = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };
      // Uzyskanie pozycji na linii najbliższej bieżącej pozycji myszy
      const newPoint = getClosestPointOnLine(newP1, newP2, point);

      e.target.position(newPoint);

      if (isDragging && baysState.bayObject && newPoint) {
        // Obliczamy nową odległość h1 (pomiędzy scaledMp a poL)
        let h1 = Math.sqrt(
          (newPoint.x - baysState.bayObject.p0.x) ** 2 + (newPoint.y - baysState.bayObject.p0.y) ** 2
        );

        if (isNaN(h1)) h1 = 0;

        generateDrawBayObject(
          baysState.startPoint,
          baysState.endPoint,
          baysState.bayObject.p0,
          baysState.w1,
          baysState.w10,
          baysState.w2,
          (h1 * 2) / scale,
          baysState.overhangFront,
          baysState.overhangSide,
          baysState.angle,
          baysState.bayType
        );
      }
    }
  };

  // Koniec przemieszczania punktu
  const handleDragEnd = () => {
    setIsDragging(false);
  };

  // Funkcja rysująca obiekt na rysunku
  const drawBayOnDraw = (baysState: IBaysState) => {
    if (baysState && baysState.bayObject) {
      const bayObject = baysState.bayObject;
      const isDormer = baysState.w2 < 0;

      return (
        <Group>
          {/* Narysujemy polilinię zamknięto pomiedzy tylko  punktami P2 P2L p10L P1 P2 */}
          <Line
            points={[
              bayObject.p2.x,
              bayObject.p2.y,
              bayObject.p2L.x,
              bayObject.p2L.y,
              bayObject.p10L.x,
              bayObject.p10L.y,
              bayObject.p1.x,
              bayObject.p1.y,
              bayObject.p2.x,
              bayObject.p2.y,
            ]}
            // stroke={"#000000"}
            // strokeWidth={2 / zoomScale}
            closed
            fill={"#b8b8b8b3"}
            opacity={0.8}
            listening={false}
          />

          {/* Narysujemy polilinię zamknięto pomiedzy tylko  punktami P2 P2R p10R P1 P2 */}
          <Line
            points={[
              bayObject.p2.x,
              bayObject.p2.y,
              bayObject.p2R.x,
              bayObject.p2R.y,
              bayObject.p10R.x,
              bayObject.p10R.y,
              bayObject.p1.x,
              bayObject.p1.y,
              bayObject.p2.x,
              bayObject.p2.y,
            ]}
            // stroke={"#000000"}
            // strokeWidth={2 / zoomScale}
            closed
            fill={"#b8b8b8b3"}
            opacity={0.8}
            listening={false}
          />

          {/* Linie wykusza */}

          {/* Linia P1 -> P10L */}
          <Line
            points={[bayObject.p1.x, bayObject.p1.y, bayObject.p10L.x, bayObject.p10L.y]}
            stroke={"#000000"}
            strokeWidth={2 / zoomScale}
            listening={false}
          />

          {/* Linia P1 -> P10R */}
          <Line
            points={[bayObject.p1.x, bayObject.p1.y, bayObject.p10R.x, bayObject.p10R.y]}
            stroke={"#000000"}
            strokeWidth={2 / zoomScale}
            listening={false}
          />

          {/* Linia P1 -> P0 */}
          {baysState.bayType === "bay" && !isDormer && (
            <Line
              points={[bayObject.p1.x, bayObject.p1.y, bayObject.p0.x, bayObject.p0.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {baysState.bayType === "bay" && isDormer && (
            <Line
              points={[bayObject.p1.x, bayObject.p1.y, bayObject.p2.x, bayObject.p2.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {baysState.bayType === "bay-rectangle" && (
            <Line
              points={[bayObject.p1.x, bayObject.p1.y, bayObject.p2.x, bayObject.p2.y]}
              stroke={"#9c9c9c"}
              strokeWidth={2 / zoomScale}
              dash={[5, 5]}
            />
          )}

          {/* Linia P0 -> P2 */}
          {baysState.bayType === "bay" && (
            <Line
              points={[bayObject.p0.x, bayObject.p0.y, bayObject.p2.x, bayObject.p2.y]}
              stroke={isDormer ? "#555555" : "#000000"}
              strokeWidth={2 / zoomScale}
              dash={isDormer ? [5, 5] : [0]}
              listening={false}
            />
          )}

          {/* Linia P10L -> P0L */}
          {!isDormer && (
            <Line
              points={[bayObject.p10L.x, bayObject.p10L.y, bayObject.p0L.x, bayObject.p0L.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {isDormer && (
            <Line
              points={[bayObject.p10L.x, bayObject.p10L.y, bayObject.p2L.x, bayObject.p2L.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {isDormer && (
            <Line
              points={[bayObject.p2L.x, bayObject.p2L.y, bayObject.p0L.x, bayObject.p0L.y]}
              stroke={"#555555"}
              strokeWidth={2 / zoomScale}
              dash={[5, 5]}
              listening={false}
            />
          )}

          {/* Linia P0L -> P2L */}
          {!isDormer && (
            <Line
              points={[bayObject.p0L.x, bayObject.p0L.y, bayObject.p2L.x, bayObject.p2L.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {/* Linia P10R -> P0R */}
          {!isDormer && (
            <Line
              points={[bayObject.p10R.x, bayObject.p10R.y, bayObject.p0R.x, bayObject.p0R.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {isDormer && (
            <Line
              points={[bayObject.p10R.x, bayObject.p10R.y, bayObject.p2R.x, bayObject.p2R.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {isDormer && (
            <Line
              points={[bayObject.p0R.x, bayObject.p0R.y, bayObject.p2R.x, bayObject.p2R.y]}
              stroke={"#555555"}
              strokeWidth={2 / zoomScale}
              dash={[5, 5]}
              listening={false}
            />
          )}

          {/* Linia P0R -> P2R */}
          {!isDormer && (
            <Line
              points={[bayObject.p0R.x, bayObject.p0R.y, bayObject.p2R.x, bayObject.p2R.y]}
              stroke={"#000000"}
              strokeWidth={2 / zoomScale}
              listening={false}
            />
          )}

          {/* Linia P2 -> P2L */}
          <Line
            points={[bayObject.p2.x, bayObject.p2.y, bayObject.p2L.x, bayObject.p2L.y]}
            stroke={"#000000"}
            strokeWidth={2 / zoomScale}
            listening={false}
          />

          {/* Linia P2 -> P2R */}
          <Line
            points={[bayObject.p2.x, bayObject.p2.y, bayObject.p2R.x, bayObject.p2R.y]}
            stroke={"#000000"}
            strokeWidth={2 / zoomScale}
            listening={false}
          />

          {/* Punkt P0 */}
          <Circle
            x={bayObject.p0.x}
            y={bayObject.p0.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#4c00ff"
            listening={addBayStatus === "adding" ? false : true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMoveP0}
            draggable
          />

          {/* Punkt P0L */}
          <Circle
            x={bayObject.p0L.x}
            y={bayObject.p0L.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            listening={addBayStatus === "adding" ? false : true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMoveP0L}
            draggable
          />

          {/* Punkt P0R */}
          <Circle
            x={bayObject.p0R.x}
            y={bayObject.p0R.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            listening={addBayStatus === "adding" ? false : true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMoveP0R}
            draggable
          />

          {/* Punkt P1 */}
          <Circle
            x={bayObject.p1.x}
            y={bayObject.p1.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            listening={addBayStatus === "adding" ? false : true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMoveP1}
            draggable
          />

          {/* Punkt P10L */}
          {baysState.bayType === "bay" && baysState.w10 != 0 && (
            <Circle
              x={bayObject.p10L.x}
              y={bayObject.p10L.y}
              radius={7 / zoomScale}
              strokeWidth={1 / zoomScale}
              stroke={"#000000"}
              fill="#ffffff"
              listening={addBayStatus === "adding" ? false : true}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMoveP10L}
              draggable
            />
          )}

          {/* Gdy w10 jest === 0 to należy punkt2 przesunąć wzdłóż P0l i P0R w prawą o 20 */}
          {baysState.w10 === 0 && (
            <Circle
              x={findPointBeyond(bayObject.p0L, bayObject.p10L, 20).x}
              y={findPointBeyond(bayObject.p0L, bayObject.p10L, 20).y}
              radius={7 / zoomScale}
              strokeWidth={1 / zoomScale}
              stroke={"#000000"}
              fill="#ffffff"
              listening={addBayStatus === "adding" ? false : true}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMoveP10L}
              draggable
            />
          )}

          {/* Punkt P10R */}
          {baysState.bayType === "bay" && baysState.w10 != 0 && (
            <Circle
              x={bayObject.p10R.x}
              y={bayObject.p10R.y}
              radius={7 / zoomScale}
              strokeWidth={1 / zoomScale}
              stroke={"#000000"}
              fill="#ffffff"
              listening={addBayStatus === "adding" ? false : true}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragMove={handleDragMoveP10R}
              draggable
            />
          )}

          {/* Punkt P2 */}
          <Circle
            x={bayObject.p2.x}
            y={bayObject.p2.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            listening={addBayStatus === "adding" ? false : true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragMove={handleDragMoveP2}
            draggable
          />

          {/* Punkt P2L */}
          {/* <Circle
            x={bayObject.p2L.x}
            y={bayObject.p2L.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            listening={false}
          /> */}

          {/* Punkt P2R */}
          {/* <Circle
            x={bayObject.p2R.x}
            y={bayObject.p2R.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            listening={false}
          /> */}

          {/* Okapy */}

          {/* Punkt PWL */}
          {/* <Circle
            x={bayObject.pwL.x}
            y={bayObject.pwL.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ff0000"
            listening={addBayStatus === "adding" ? false : true}
            // onDragStart={handleDragStart}
            // onDragEnd={handleDragEnd}
            // onDragMove={handleDragMoveP0R}
            // draggable
          /> */}

          {/* Punkt PWR */}
          {/* <Circle
            x={bayObject.pwR.x}
            y={bayObject.pwR.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ff0000"
            listening={addBayStatus === "adding" ? false : true}
            // onDragStart={handleDragStart}
            // onDragEnd={handleDragEnd}
            // onDragMove={handleDragMoveP0R}
            // draggable
          /> */}

          {/* Punkt PWLg */}
          {/* <Circle
            x={bayObject.pwLg.x}
            y={bayObject.pwLg.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ff0000"
            listening={addBayStatus === "adding" ? false : true}
            // onDragStart={handleDragStart}
            // onDragEnd={handleDragEnd}
            // onDragMove={handleDragMoveP0R}
            // draggable
          /> */}

          {/* Punkt PWRg */}
          {/* <Circle
            x={bayObject.pwRg.x}
            y={bayObject.pwRg.y}
            radius={7 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ff0000"
            listening={addBayStatus === "adding" ? false : true}
            // onDragStart={handleDragStart}
            // onDragEnd={handleDragEnd}
            // onDragMove={handleDragMoveP0R}
            // draggable
          /> */}

          {/* Linie dla okapów */}
          {/* Linia od PWL do PWLg */}
          {baysState.overhangSide > 0 && (
            <Line
              points={[bayObject.pwL.x, bayObject.pwL.y, bayObject.pwLg.x, bayObject.pwLg.y]}
              stroke={"#ff0000"}
              strokeWidth={2 / zoomScale}
              dash={[5, 5]}
              listening={false}
            />
          )}

          {/* Linia od PWR do PWRg */}
          {baysState.overhangSide > 0 && (
            <Line
              points={[bayObject.pwR.x, bayObject.pwR.y, bayObject.pwRg.x, bayObject.pwRg.y]}
              stroke={"#ff0000"}
              strokeWidth={2 / zoomScale}
              dash={[5, 5]}
              listening={false}
            />
          )}

          {/* Linia od PWL do PWR */}
          {baysState.overhangFront > 0 && (
            <Line
              points={[bayObject.pwL.x, bayObject.pwL.y, bayObject.pwR.x, bayObject.pwR.y]}
              stroke={"#ff0000"}
              strokeWidth={2 / zoomScale}
              dash={[5, 5]}
              listening={false}
            />
          )}

          {/* Wymiar po środku odcinka pomiedzy punktami od baysState.startPoint a baysState.bayObject.p0L*/}
          {baysState && baysState.startPoint && baysState.endPoint && (
            <Text
              x={
                (baysState.startPoint.x + baysState.bayObject.p0L.x) / 2 +
                getOffsetX(angleRadians(baysState.startPoint, baysState.bayObject.p0L))
              }
              y={
                (baysState.startPoint.y + baysState.bayObject.p0L.y) / 2 +
                getOffsetY(angleRadians(baysState.startPoint, baysState.bayObject.p0L))
              }
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={0}
              text={`${getLength(baysState.startPoint, baysState.bayObject.p0L, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(baysState.startPoint, baysState.bayObject.p0L) * 180) / Math.PI}
            />
          )}

          {/* Wymiar po środku odcinka pomiedzy punktami od baysState.endPoint a baysState.bayObject.p0R*/}
          {baysState && baysState.startPoint && baysState.endPoint && (
            <Text
              x={
                (baysState.endPoint.x + baysState.bayObject.p0R.x) / 2 +
                getOffsetX(angleRadians(baysState.bayObject.p0R, baysState.endPoint))
              }
              y={
                (baysState.endPoint.y + baysState.bayObject.p0R.y) / 2 +
                getOffsetY(angleRadians(baysState.bayObject.p0R, baysState.endPoint))
              }
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={0}
              text={`${getLength(baysState.bayObject.p0R, baysState.endPoint, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(baysState.bayObject.p0R, baysState.endPoint) * 180) / Math.PI}
            />
          )}

          {baysState && baysState.startPoint && baysState.endPoint && (
            <Text
              x={
                (baysState.bayObject.p2L.x + baysState.bayObject.p2R.x) / 2 +
                getOffsetX(angleRadians(baysState.bayObject.p2L, baysState.bayObject.p2R))
              }
              y={
                (baysState.bayObject.p2L.y + baysState.bayObject.p2R.y) / 2 +
                getOffsetY(angleRadians(baysState.bayObject.p2L, baysState.bayObject.p2R))
              }
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={-5}
              text={`${getLength(baysState.bayObject.p2L, baysState.bayObject.p2R, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(baysState.bayObject.p2L, baysState.bayObject.p2R) * 180) / Math.PI}
            />
          )}

          {/* Wymiar wcięcia lewego czyli od p0L do p10L */}
          {baysState && baysState.startPoint && baysState.endPoint && baysState.w10 != 0 && !isDormer && (
            <Text
              x={
                (bayObject.p0L.x + bayObject.p10L.x) / 2 + getOffsetX(angleRadians(bayObject.p0L, bayObject.p10L))
              }
              y={
                (bayObject.p0L.y + bayObject.p10L.y) / 2 + getOffsetY(angleRadians(bayObject.p0L, bayObject.p10L))
              }
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={30}
              text={`${getLength(bayObject.p0L, bayObject.p10L, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(bayObject.p0L, bayObject.p10L) * 180) / Math.PI}
            />
          )}

          {/* Wymiar wcięcia lewego czyli od p2L do p10L dla dormer*/}
          {baysState && baysState.startPoint && baysState.endPoint && baysState.w10 != 0 && isDormer && (
            <Text
              x={
                (bayObject.p2L.x + bayObject.p10L.x) / 2 + getOffsetX(angleRadians(bayObject.p2L, bayObject.p10L))
              }
              y={
                (bayObject.p2L.y + bayObject.p10L.y) / 2 + getOffsetY(angleRadians(bayObject.p2L, bayObject.p10L))
              }
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={30}
              text={`${getLength(bayObject.p2L, bayObject.p10L, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(bayObject.p2L, bayObject.p10L) * 180) / Math.PI}
            />
          )}

          {/* Wymiar wcięcia prawego czyli od p0R do p10R */}
          {baysState &&
            baysState.startPoint &&
            baysState.endPoint &&
            baysState.w10 != 0 &&
            baysState.w10 > 0 &&
            !isDormer && (
              <Text
                x={
                  (bayObject.p0R.x + bayObject.p10R.x) / 2 +
                  getOffsetX(angleRadians(bayObject.p0R, bayObject.p10R))
                }
                y={
                  (bayObject.p0R.y + bayObject.p10R.y) / 2 +
                  getOffsetY(angleRadians(bayObject.p0R, bayObject.p10R))
                }
                width={100}
                align={"center"}
                offsetX={50}
                offsetY={0}
                text={`${getLength(bayObject.p0R, bayObject.p10R, scale).toFixed(2)}`}
                fontSize={14 / zoomScale}
                fill={"#252222"}
                listening={false}
                rotation={(angleRadians(bayObject.p0R, bayObject.p10R) * 180) / Math.PI}
              />
            )}

          {/* Wymiar wcięcia prawego czyli od p2R do p10R dla dormer */}
          {baysState &&
            baysState.startPoint &&
            baysState.endPoint &&
            baysState.w10 != 0 &&
            baysState.w10 > 0 &&
            isDormer && (
              <Text
                x={
                  (bayObject.p2R.x + bayObject.p10R.x) / 2 +
                  getOffsetX(angleRadians(bayObject.p2R, bayObject.p10R))
                }
                y={
                  (bayObject.p2R.y + bayObject.p10R.y) / 2 +
                  getOffsetY(angleRadians(bayObject.p2R, bayObject.p10R))
                }
                width={100}
                align={"center"}
                offsetX={50}
                offsetY={0}
                text={`${getLength(bayObject.p2R, bayObject.p10R, scale).toFixed(2)}`}
                fontSize={14 / zoomScale}
                fill={"#252222"}
                listening={false}
                rotation={(angleRadians(bayObject.p2R, bayObject.p10R) * 180) / Math.PI}
              />
            )}

          {/* Wymiar od p0 do p2 dla dormer */}
          {baysState && baysState.bayType === "bay" && baysState.startPoint && baysState.endPoint && isDormer && (
            <Text
              x={(bayObject.p0.x + bayObject.p2.x) / 2 + getOffsetX(angleRadians(bayObject.p0, bayObject.p2))}
              y={(bayObject.p0.y + bayObject.p2.y) / 2 + getOffsetY(angleRadians(bayObject.p0, bayObject.p2))}
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={0}
              text={`${getLength(bayObject.p0, bayObject.p2, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(bayObject.p0, bayObject.p2) * 180) / Math.PI}
            />
          )}

          {baysState &&
            baysState.bayType === "bay-rectangle" &&
            baysState.startPoint &&
            baysState.endPoint &&
            !isDormer && (
              <Text
                x={
                  (bayObject.p2L.x + bayObject.p0L.x) / 2 + getOffsetX(angleRadians(bayObject.p2L, bayObject.p0L))
                }
                y={
                  (bayObject.p2L.y + bayObject.p0L.y) / 2 + getOffsetY(angleRadians(bayObject.p2L, bayObject.p0L))
                }
                width={100}
                align={"center"}
                offsetX={50}
                offsetY={30}
                text={`${getLength(bayObject.p2L, bayObject.p0L, scale).toFixed(2)}`}
                fontSize={14 / zoomScale}
                fill={"#252222"}
                listening={false}
                rotation={(angleRadians(bayObject.p2L, bayObject.p0L) * 180) / Math.PI}
              />
            )}

          {baysState &&
            baysState.bayType === "bay-rectangle" &&
            baysState.startPoint &&
            baysState.endPoint &&
            !isDormer && (
              <Text
                x={
                  (bayObject.p2R.x + bayObject.p0R.x) / 2 + getOffsetX(angleRadians(bayObject.p2R, bayObject.p0R))
                }
                y={
                  (bayObject.p2R.y + bayObject.p0R.y) / 2 + getOffsetY(angleRadians(bayObject.p2R, bayObject.p0R))
                }
                width={100}
                align={"center"}
                offsetX={50}
                offsetY={0}
                text={`${getLength(bayObject.p2R, bayObject.p0R, scale).toFixed(2)}`}
                fontSize={14 / zoomScale}
                fill={"#252222"}
                listening={false}
                rotation={(angleRadians(bayObject.p2R, bayObject.p0R) * 180) / Math.PI}
              />
            )}

          {baysState &&
            baysState.bayType === "bay-rectangle" &&
            baysState.startPoint &&
            baysState.endPoint &&
            isDormer && (
              <Text
                x={
                  (bayObject.p0L.x + bayObject.p2L.x) / 2 + getOffsetX(angleRadians(bayObject.p0L, bayObject.p2L))
                }
                y={
                  (bayObject.p0L.y + bayObject.p2L.y) / 2 + getOffsetY(angleRadians(bayObject.p0L, bayObject.p2L))
                }
                width={100}
                align={"center"}
                offsetX={50}
                offsetY={30}
                text={`${getLength(bayObject.p0L, bayObject.p2L, scale).toFixed(2)}`}
                fontSize={14 / zoomScale}
                fill={"#252222"}
                listening={false}
                rotation={(angleRadians(bayObject.p0L, bayObject.p2L) * 180) / Math.PI}
              />
            )}

          {baysState &&
            baysState.bayType === "bay-rectangle" &&
            baysState.startPoint &&
            baysState.endPoint &&
            isDormer && (
              <Text
                x={
                  (bayObject.p0R.x + bayObject.p2R.x) / 2 + getOffsetX(angleRadians(bayObject.p0R, bayObject.p2R))
                }
                y={
                  (bayObject.p0R.y + bayObject.p2R.y) / 2 + getOffsetY(angleRadians(bayObject.p0R, bayObject.p2R))
                }
                width={100}
                align={"center"}
                offsetX={50}
                offsetY={0}
                text={`${getLength(bayObject.p0R, bayObject.p2R, scale).toFixed(2)}`}
                fontSize={14 / zoomScale}
                fill={"#252222"}
                listening={false}
                rotation={(angleRadians(bayObject.p0R, bayObject.p2R) * 180) / Math.PI}
              />
            )}

          {/* Wymiar od p2 do p0 */}
          {baysState && baysState.bayType === "bay" && baysState.startPoint && baysState.endPoint && !isDormer && (
            <Text
              x={(bayObject.p2.x + bayObject.p0.x) / 2 + getOffsetX(angleRadians(bayObject.p2, bayObject.p0))}
              y={(bayObject.p2.y + bayObject.p0.y) / 2 + getOffsetY(angleRadians(bayObject.p2, bayObject.p0))}
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={0}
              text={`${getLength(bayObject.p2, bayObject.p0, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(bayObject.p2, bayObject.p0) * 180) / Math.PI}
            />
          )}

          {/* Wymiar od p0 do p1 */}
          {baysState && baysState.bayType === "bay" && baysState.startPoint && baysState.endPoint && !isDormer && (
            <Text
              x={(bayObject.p0.x + bayObject.p1.x) / 2 + getOffsetX(angleRadians(bayObject.p0, bayObject.p1))}
              y={(bayObject.p0.y + bayObject.p1.y) / 2 + getOffsetY(angleRadians(bayObject.p0, bayObject.p1))}
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={0}
              text={`${getLength(bayObject.p0, bayObject.p1, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(bayObject.p0, bayObject.p1) * 180) / Math.PI}
            />
          )}

          {/* Wymiar od p2 do p1 */}
          {baysState && baysState.startPoint && baysState.endPoint && (
            <Text
              x={(bayObject.p2.x + bayObject.p1.x) / 2 + getOffsetX(angleRadians(bayObject.p2, bayObject.p1))}
              y={(bayObject.p2.y + bayObject.p1.y) / 2 + getOffsetY(angleRadians(bayObject.p2, bayObject.p1))}
              width={100}
              align={"center"}
              offsetX={50}
              offsetY={30}
              text={`${getLength(bayObject.p2, bayObject.p1, scale).toFixed(2)}`}
              fontSize={14 / zoomScale}
              fill={"#252222"}
              listening={false}
              rotation={(angleRadians(bayObject.p2, bayObject.p1) * 180) / Math.PI}
            />
          )}

          {/* Wymiar od p10L do p0L */}
        </Group>
      );
    }
  };

  return (
    <Group
      ref={groupRef}
      onClick={() => {
        addBayToDrawing();
      }}
    >
      {/* Rysujemy wszystkie linie krawędzi okapu na potrzeby tej funkcji */}
      {eaveEdges.map((edge) => {
        const p1 = points.find((p) => p.id === edge.startPointId);
        const p2 = points.find((p) => p.id === edge.endPointId);

        // Jeśli status editing to tylko podświetla wybraną krawędź
        const showThisEdge = addBayStatus === "editing" ? edge.id === selectedEdgeAndPlane?.edge.id : true;

        if (showThisEdge && p1 && p2) {
          return (
            <Line
              key={edge.id}
              points={[p1.x, p1.y, p2.x, p2.y]}
              stroke={
                addBayStatus === "adding"
                  ? "#340aec"
                  : selectedEdgeAndPlane?.edge?.id === edge.id
                  ? "#340aec"
                  : "#000000"
              }
              strokeWidth={
                addBayStatus === "adding" && selectedEdgeAndPlane?.edge?.id === edge.id
                  ? 7 / zoomScale
                  : 4 / zoomScale
              }
              onMouseEnter={(e) => {
                handleMouseEnter(e, edge);
              }}
              onMouseLeave={(e) => {
                handleMouseLeave(e, edge);
              }}
              onMouseMove={(e) => {
                handleMouseMove(e);
              }}
            />
          );
        }
        return null;
      })}

      {/* Rysujemy punkt na krawędzi okapu, na którym ma być dodany wykusz */}
      {((baysState.bayObject && addBayStatus === "adding") ||
        (baysState.bayObject && addBayStatus === "editing")) &&
        drawBayOnDraw(baysState)}
    </Group>
  );
};
export default AddBayFunction;
