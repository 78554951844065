import React, { FC } from "react";
import { Group, Line } from "react-konva";
import { IRoofHole, IRoofPoint } from "../../../../../models/Models";

const getPointsForLineComponent = (pointIds: any, points: IRoofPoint[]) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x: IRoofPoint) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

type Props = {
  roofHole: IRoofHole;
  roofPoints: IRoofPoint[];
  scale: number;
  fill?: string;
};

const RoofHole: FC<Props> = ({ roofHole, roofPoints, scale, fill }) => {
  return (
    <Group>
      <Line
        points={getPointsForLineComponent(roofHole.pointIds, roofPoints)}
        fill={fill ? fill : "#ffffff"}
        closed={true}
        stroke="black"
        strokeWidth={5}
      />
    </Group>
  );
};

export default RoofHole;
