import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IBasicPoint } from "../../models/Models";
import { AppThunk } from "../store";

export interface IDrawChimneyProcess {
  isActive: boolean; // czy aktywny proces rysowania komina
  isReadyToCalc: boolean; // czy zamknięty obwód i gotowe do obliczenia w kalkulatorze
  points: IBasicPoint[];
  roofPlaneId: number; // numer calcId dla plane na którym jest rysowane
}

const initialState: IDrawChimneyProcess = {
  isActive: false,
  isReadyToCalc: false,
  points: [],
  roofPlaneId: 0,
};

export const drawChimneyProcessSlice = createSlice({
  name: "drawChimneyProcess",
  initialState,
  reducers: {
    addPointDrawChimney: (state, action: PayloadAction<IBasicPoint>) => {
      const p = action.payload;
      const points = [...state.points, p];
      return { ...state, points: points };
    },
    setRedyToCalcDrawChimney: (state) => {
      return { ...state, isReadyToCalc: true };
    },
    setRoofPlaneCalcIdDrawChimney: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      return { ...state, roofPlaneId: id };
    },
    resetDrawChimneyProcess: (state) => {
      return initialState;
    },
    setSavedDrawChimneyProcess: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addPointDrawChimney,
  setRedyToCalcDrawChimney,
  setRoofPlaneCalcIdDrawChimney,
  resetDrawChimneyProcess,
  setSavedDrawChimneyProcess,
} = drawChimneyProcessSlice.actions;
export default drawChimneyProcessSlice.reducer;
