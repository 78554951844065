import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { IAddProduct, IAddTileSystem, IProduct, ITileSystem, IUpdateProduct } from "../../models/Models";
import { addStatusApi } from "../other/statusApiSlice";
import { AppThunk } from "../store";

const initialState: ITileSystem[] = [];

export const tileSystemsSlice = createSlice({
  name: "tileSystems",
  initialState,
  reducers: {
    addTileSystemsInReducer: (state, action: PayloadAction<ITileSystem[]>) => {
      let tileSystems = action.payload;
      return tileSystems;
    },
    addTileSystemInReducer: (state, action: PayloadAction<ITileSystem>) => {
      let tileSystem = action.payload;
      return [...state, tileSystem];
    },
    updateTileSystemInReducer: (state, action: PayloadAction<ITileSystem>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);
      state[index] = { ...state[index], ...update };
    },
    deleteTileSystemInReducer: (state, action: PayloadAction<string>) => {
      const tileSystemId = action.payload;
      return state.filter((o) => o.id !== tileSystemId);
    },
  },
});

export const {
  addTileSystemsInReducer,
  addTileSystemInReducer,
  updateTileSystemInReducer,
  deleteTileSystemInReducer,
} = tileSystemsSlice.actions;
export default tileSystemsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add tile system
export const addTileSystem =
  (data: IAddTileSystem, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("ADD MODEL", data);
    client
      .put("/TileSystems", data)
      .then((response) => {
        console.log("ADD RESPONSE", response);
        if (response.status == 201) {
          dispatch(addTileSystemInReducer(response.data));
          callback();
          // dispatch(addStatusApi({ key: "addTileSystem", type: "success", message: "" }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addTileSystem", type: "error", message: "Błąd dodania systemu!" }));
        // console.error(error);
      });
  };

// Update tile system
export const updateTileSystem =
  (data: ITileSystem): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE MODEL", data);
    client
      .post(`/TileSystems`, data)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATE RESPONSE", response.data);
          dispatch(updateTileSystemInReducer(response.data));

          message.open({
            type: "success",
            content: "System został zaktualizowany!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete tile system -> ONLY ADMIN
export const deleteTileSystem =
  (data: ITileSystem): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/TileSystems/${data.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteTileSystemInReducer(data.id));
          dispatch(addStatusApi({ key: "deleteTileSystem", type: "success", message: "" }));

          message.open({
            type: "success",
            content: "System został usunięty!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
