import { createSlice, Dispatch, PayloadAction, Store } from "@reduxjs/toolkit";
import { IAuth, IRootState, ISignIn } from "../../models/Models";
import { setContractorPriceListInReducer } from "../contractors/contractorPriceListSlice";
import { setContractorServicesInReducer } from "../contractors/contractorServicesSlice";
import { addContractorInReducer } from "../contractors/contractorSlice";
import { setPrincingSystemsInReducer } from "../contractors/princingSystemsSlice";
import { addCompaniesInReducer } from "../general/companiesSlice";
import { setDomaGridInReducer } from "../general/domaGridSlice";
import { addParentSettingsInReducer } from "../general/parentSettingsSlice";
import { setParentStatic } from "../general/parentStaticSlice";
import { addPriceUnitsInReducer } from "../general/priceUnitsSlice";
import { addProductUseTypesInReducer } from "../general/productUseTypesSlice";
import { addProductUseUnitsInReducer } from "../general/productUseUnitsSlice";
import { addProfileInReducer } from "../general/profileSlice";
import { addSalesPackagesInReducer } from "../general/salesPackagesSlice";
import { addUsersInReducer } from "../general/usersSlice";
import { setWindowIntegrationInReducer } from "../general/windowIntegrationsSlice";
import { addNotificationTypesInReducer } from "../informations/notificationTypesSlice";
import { addSystemActivityTypesInReducer } from "../informations/systemActivityTypesSlice";
import { addProductsGroupsInReducer } from "../productsGroups/productsGroupsSlice";
import { addFasteningSystemsInReducer } from "../roofSystems/fasteningSystemsSlice";
import { setGutterSystemAdditionTypessInReducer } from "../roofSystems/gutterSystemAdditionTypesSlice";
import { addGutterSystemsInReducer } from "../roofSystems/gutterSystemsSlice";
import { addPreCoveringSystemsInReducer } from "../roofSystems/preCoveringSystemsSlice";
import { setSteelSystemAdditionTypessInReducer } from "../roofSystems/steelSystemAdditionTypesSlice";
import { addSteelSystemsInReducer } from "../roofSystems/steelSystemsSlice";
import { setTileSystemAdditionTypessInReducer } from "../roofSystems/tileSystemAdditionTypesSlice";
import { addTileSystemsInReducer } from "../roofSystems/tileSystemsSlice";
import { addTechnicalEquipmentsGroupsInReducer } from "../technicalEquipmentsGroups/technicalEquipmentsGroupsSlice";

const initialState: IAuth = { token: "", messageType: "error", message: "" };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    addTokenInReducer: (state, action: PayloadAction<IAuth>) => {
      const auth = action.payload;
      state.token = auth.token;
      state.messageType = auth.messageType;
      state.message = auth.message;
    },
    clearAuthInReducer: (state) => {
      state.token = "";
      state.messageType = "";
      state.message = "";
    },
    addMessageInReducer: (state, action: PayloadAction<IAuth>) => {
      const auth = action.payload;

      state.messageType = auth.messageType;
      state.message = auth.message;
    },
  },
});

export const { addTokenInReducer, clearAuthInReducer, addMessageInReducer } = authSlice.actions;
export default authSlice.reducer;

// API ********************************************************************************

// Przypisz wszystkie dane do swoich miejsc w redux
export const dispatchAllInitialData = (data: IRootState, dispatch: Dispatch) => {
  // dispatch(addProductsInReducer(data.products));
  // dispatch(addProductCategoriesInReducer(data.productCategories));
  // dispatch(addProductColorsInReducer(data.productColors));
  // dispatch(setReportsInReducer(data.reports));
  // dispatch(setInvestmentsInReducer(data.investments));
  // dispatch(setProductModelsReducer(data.productModels));
  dispatch(addProfileInReducer(data.profile));
  if (data.profile.userSettings) {
    dispatch(
      setDomaGridInReducer({
        gridColor: data.profile.userSettings.gridColor,
        gridSize: data.profile.userSettings.gridSize,
        gridVisible: data.profile.userSettings.gridVisible,
        gridSnapping: data.profile.userSettings.gridSnapping,
      })
    );
  }

  dispatch(addNotificationTypesInReducer(data.notificationTypes));
  dispatch(addSystemActivityTypesInReducer(data.systemActivityTypes));

  dispatch(addUsersInReducer(data.users));
  dispatch(addCompaniesInReducer(data.companies));
  dispatch(addProductUseTypesInReducer(data.productUseTypes));
  dispatch(addPriceUnitsInReducer(data.priceUnits));
  dispatch(addSalesPackagesInReducer(data.salesPackages));
  dispatch(addProductUseUnitsInReducer(data.productUseUnits));
  dispatch(addTileSystemsInReducer(data.tileSystems));
  dispatch(setTileSystemAdditionTypessInReducer(data.tileSystemAdditionTypes));

  dispatch(addSteelSystemsInReducer(data.steelSystems));
  dispatch(setSteelSystemAdditionTypessInReducer(data.steelSystemAdditionTypes));

  dispatch(addPreCoveringSystemsInReducer(data.preCoveringSystems));
  dispatch(addFasteningSystemsInReducer(data.fasteningSystems));

  dispatch(setWindowIntegrationInReducer(data.windowIntegration));

  dispatch(addGutterSystemsInReducer(data.gutterSystems));
  dispatch(setGutterSystemAdditionTypessInReducer(data.gutterSystemAdditionTypes));
  dispatch(setParentStatic(data.parentStatic));
  dispatch(setContractorServicesInReducer(data.contractorServices));
  dispatch(setContractorPriceListInReducer(data.contractorPriceLists));
  dispatch(setPrincingSystemsInReducer(data.princingSystems));
  // dispatch(setParentContractorsInReducer(data.parentContractors));

  dispatch(addProductsGroupsInReducer(data.productsGroups));
  dispatch(addTechnicalEquipmentsGroupsInReducer(data.technicalEquipmentsGroups));

  dispatch(addParentSettingsInReducer(data.parentSettings));

  if (data.profile.contractor) {
    dispatch(addContractorInReducer(data.profile.contractor));
  }
};
