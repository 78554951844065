// Function1Handler.tsx
import { message } from "antd";
import Konva from "konva";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Circle, Group, Layer, Line, Rect, Text } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSO, IRSOEdge, IRSOPoint, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { EdgeType } from "../../../../../../../models/Enums";
import {
  addRSOPoints,
  setRSOPoints,
  updateRSOPoints,
} from "../../../../../../../redux/roofSlopeOutline/rsoPointsSlice";
import {
  addRSOEdges,
  setRSOEdges,
  updateRSOEdge,
} from "../../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import {
  addRSOPlane,
  addRSOPlanes,
  setRSOPlanes,
} from "../../../../../../../redux/roofSlopeOutline/rsoPlanesSlice";
import { getDistTwoPoints, getGridPoints, middlePoint, SEARCH_RADIUS } from "../../../../../../../helpers/Helpers";
import { GRID_OFFSET } from "../../GeometryStep";
import { IBasicPoint, IPoint } from "../../../../../../../models/Models";
import { IDomaGrid } from "../../../../../../../redux/general/domaGridSlice";
import { convertPercentageToAngle } from "../../../../newRoof/RoofProcessMainModal";
import { invalidateCalculation } from "../../../../../../../redux/calculationProcess/calcResultSlice";

const TOLERANCE = 5;

interface Function1HandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  shouldCloseOutline: boolean;
  undoLastPoint: boolean; // Triger do cofnięcia ostatniego punktu
  accetpUndo: () => void; // Potwierdzenie cofnięcia ostatniego punktu
  close: () => void;
  gridPoints: IPoint[];
  newPointAdd: any;
  setNewPointAdd: (e: any) => void;
}

const Function1Handler2: FC<Function1HandlerProps> = ({
  stageRef,
  point0Ref,
  shouldCloseOutline,
  undoLastPoint,
  accetpUndo,
  close,
  gridPoints,
  newPointAdd,
  setNewPointAdd,
}) => {
  const dispatch = useAppDispatch();

  let calcIdNumber = useRef<number>(1);

  const scale = useAppSelector((state) => state.drawingScale);
  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);

  // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 });
  const mpLocalRef = useRef(mpLocal);

  // Tu będą wszystkie punkty z wczesniej narysowanych połaci
  const rsoPoints = useAppSelector((state) => state.rsoPoints) || []; // IRSOPoint[]
  const rsoEdges = useAppSelector((state) => state.rsoEdges) || []; // IRSOEdge[]
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes) || []; // IRSO[]

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);

  // Punkty dla obrysu połaci aktualnie rysowanej - tylko dla rysunku tymczasowego
  // Informacja
  // Jeśli jest wybrany punkt, który istnieje to tu tworzy sie jego kopia i podczas tworzenia docelowego punktu
  // jest kontrola czy już istnieje i nie jest dodawany ale id zgadza się i współrzędne
  const [points, setPoints] = useState<IRSOPoint[]>([]);
  const pointsRef = useRef(points);

  // Najbliższy istniejący punkt
  const [nearestPoint, setNearestPoint] = useState<IRSOPoint | null>(null); // Jesli null to nie ma punktu najblizszego
  const nearestPointRef = useRef(nearestPoint);

  // Najbliższy punkt na lini poziomej, pionowej lub obu lub n alini prostej z innym punktem jako punkt 2d tymczasowy
  const [alignedPoint, setAlignedPoint] = useState<RSOPoint2D | null>(null); // Jesli null to nie ma punktu najblizszego
  const alignedPointRef = useRef(alignedPoint);

  // Funkcja, która tworzy listę wsyztskich punktów do analizy nearestPoint czyli wszystkie na rysunku oraz wszystkie z bierzącego rysunku czyli points
  const getAllPoints = () => {
    const allPoints: IRSOPoint[] = [...rsoPoints, ...pointsRef.current];
    return allPoints;
  };

  const domaGrid = useAppSelector((state) => state.domaGrid);
  const domaGridRef = useRef(domaGrid);

  // Uwzględnij że może być podane w stopniach lub w procentach
  const getDefaultSlope = () => {
    if (roofProcessStatus.typeSlope === "degrees") {
      return roofProcessStatus.slope;
    } else {
      // Zakładając, że roofProcessStatus.slope jest w procentach
      return convertPercentageToAngle(roofProcessStatus.slope);
    }
  };

  const isGridSnapping = (domaGrid: IDomaGrid) => {
    if (domaGrid && domaGrid.gridVisible && domaGrid.gridSnapping) {
      return true;
    }
    return false;
  };

  // Czy śledzenie punktów
  const isTrackingPoint = roofProcessFunctionsStatus.pointTracking;

  // Odświeżanie referencji do points bo po wywołaniu setPoints zmienia się referencja do obiektu wewnętrznego
  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    pointsRef.current = points;
    mpLocalRef.current = mpLocal;
    nearestPointRef.current = nearestPoint;
    alignedPointRef.current = alignedPoint;
    domaGridRef.current = domaGrid;
  }, [points, mpLocal, nearestPoint, alignedPoint, domaGrid]);

  // funkcja dodająca punkty z pola i strzałek
  useEffect(() => {
    if (newPointAdd != null && pointsRef.current.length > 0) {
      let x: number = points[points.length - 1].x;
      let y: number = points[points.length - 1].y;

      if (newPointAdd.direction === "up") {
        y = y - newPointAdd.value;
      } else if (newPointAdd.direction === "down") {
        y = y + newPointAdd.value;
      } else if (newPointAdd.direction === "left") {
        x = x - newPointAdd.value;
      } else if (newPointAdd.direction === "right") {
        x = x + newPointAdd.value;
      }

      const newMp: IRSOPoint = {
        id: uuidv4(),
        calcId: calcIdNumber.current, // TODO - Na razie wszędzie 0 -> może będzie potrzebne później iterowane
        type: 1, // 1 – punkt jest na dachu (połaci nachylonej),
        status: 1, // 1 – punkt leży na obrysie dachu
        x: x,
        y: y,
        z: 0,
      };
      setPoints([...pointsRef.current, newMp]);
      calcIdNumber.current = calcIdNumber.current + 1;
    }
    setNewPointAdd(null);
  }, [newPointAdd]);

  useEffect(() => {
    if (shouldCloseOutline) {
      finishRoofSlopeDrawing();
    }
  }, [shouldCloseOutline]);

  useEffect(() => {
    if (undoLastPoint) {
      if (pointsRef.current.length > 0) {
        const newPoints = [...pointsRef.current];
        newPoints.splice(newPoints.length - 1, 1);
        setPoints(newPoints);
      }
      accetpUndo();
    }
  }, [undoLastPoint]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        // 1. Sprawdzamy czy jest jakiś punkt w insertPoint i go dodajemy

        // Jeśli istnieje punkt istniejący w pobliżu
        if (nearestPointRef.current) {
          // Jeśli ten punkt jest pierwsszym punktem to koniec funkcji
          if (pointsRef.current.length > 0 && nearestPointRef.current.id === pointsRef.current[0].id) {
            finishRoofSlopeDrawing();
            return;
          }

          // Dodajemy kopię najbliższego punktu (nearestPoint) do listy points
          const newPoint = { ...nearestPointRef.current, calcId: calcIdNumber.current };
          setPoints([...pointsRef.current, newPoint]);
          calcIdNumber.current = calcIdNumber.current + 1;
        } else if (alignedPointRef.current) {
          const newPoint: IRSOPoint = {
            id: uuidv4(),
            calcId: calcIdNumber.current, // TODO - Na razie wszędzie 0 -> może będzie potrzebne później iterowane
            type: 1, // 1 – punkt jest na dachu (połaci nachylonej),
            status: 1, // 1 – punkt leży na obrysie dachu
            x: alignedPointRef.current.x,
            y: alignedPointRef.current.y,
            z: 0, // Na początek domyślnie 0
          };
          setPoints([...pointsRef.current, newPoint]);
          calcIdNumber.current = calcIdNumber.current + 1;
        } else {
          // Jeśli nie ma punktu to wstawiamy ten punkt w którym znajduje się myszka
          const newMp: IRSOPoint = {
            id: uuidv4(),
            calcId: calcIdNumber.current, // TODO - Na razie wszędzie 0 -> może będzie potrzebne później iterowane
            type: 1, // 1 – punkt jest na dachu (połaci nachylonej),
            status: 1, // 1 – punkt leży na obrysie dachu
            x: mpLocalRef.current.x,
            y: mpLocalRef.current.y,
            z: 0, // Na początek domyślnie 0
          };
          setPoints([...pointsRef.current, newMp]);
          calcIdNumber.current = calcIdNumber.current + 1;
        }
      } else if (e.evt.button === 2) {
        console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();

        if (pointsRef.current.length > 0) {
          finishRoofSlopeDrawing();
        }
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      if (startPointPosition) {
        const scaledMp: RSOPoint2D = { x: startPointPosition.x, y: startPointPosition.y };

        // Jeśłi śledzenie to wszytskie punkty a jeśli nie to tylko bierzące
        const allPoints = getAllPoints();

        let ap: RSOPoint2D | null = null;

        let changedX = false;
        let changedY = false;

        // Wyrównaj nowy punkt z ostatnim punktem, jeśli jest w zasięgu tolerancji
        for (const p of allPoints) {
          if (Math.abs(scaledMp.x - p.x) <= TOLERANCE) {
            if (!ap) ap = { x: 0, y: 0 };
            ap.x = p.x;
            changedX = true;
          }
          if (Math.abs(scaledMp.y - p.y) <= TOLERANCE) {
            if (!ap) ap = { x: 0, y: 0 };
            ap.y = p.y;
            changedY = true;
          }
        }

        if (ap && changedX && !changedY) {
          ap.y = scaledMp.y;
        } else if (ap && changedY && !changedX) {
          ap.x = scaledMp.x;
        }
        setAlignedPoint(ap);

        setMpLocal(scaledMp);

        // console.log("isGridSnapping", isGridSnapping(domaGridRef.current));

        // Filtracja gridPoints do tych w obrębie searchRadius
        let filteredGridPoints: IPoint[] = [];
        if (isGridSnapping(domaGridRef.current)) {
          filteredGridPoints = gridPoints.filter(
            (gp) => Math.abs(gp.x - scaledMp.x) <= SEARCH_RADIUS && Math.abs(gp.y - scaledMp.y) <= SEARCH_RADIUS
          );
        }

        // Sprawdz, czy najechalismy na istniejacy punkt
        const nearestExistingPoint = [...getAllPoints(), ...filteredGridPoints].find(
          (p) => Math.abs(p.x - scaledMp.x) <= TOLERANCE && Math.abs(p.y - scaledMp.y) <= TOLERANCE
        );

        // Ustaw nearestPoint, jesli istnieje
        if (nearestExistingPoint) {
          let pointToSet: IRSOPoint;

          if ("status" in nearestExistingPoint) {
            // nearestExistingPoint jest już typu IRSOPoint
            pointToSet = nearestExistingPoint as IRSOPoint;
          } else {
            // Konwertuj IPoint na IRSOPoint
            pointToSet = {
              id: nearestExistingPoint.id ? nearestExistingPoint.id : uuidv4(),
              x: nearestExistingPoint.x ? nearestExistingPoint.x : 0,
              y: nearestExistingPoint.y ? nearestExistingPoint.y : 0,
              z: nearestExistingPoint.z ? nearestExistingPoint.z : 0,
              calcId: calcIdNumber.current, // TODO - Na razie wszędzie 0 -> może będzie potrzebne później iterowane
              type: 1, // 1 – punkt jest na dachu (połaci nachylonej),
              status: 1, // 1 – punkt leży na obrysie dachu
            };
          }

          setNearestPoint(pointToSet);
          // calcIdNumber.current = calcIdNumber.current + 1;
        } else {
          setNearestPoint(null);
        }
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  const finishRoofSlopeDrawing = () => {
    // 1. Przygotować listę punktów
    const points: IRSOPoint[] = [];
    for (const p of pointsRef.current) {
      // Sprawdzamy czy punkt był pobrany (kopiowany) z istniejących punktów
      const isPointExist = rsoPoints.find((rp) => rp.id === p.id);

      if (!isTrackingPoint || !isPointExist) {
        // Jeśli punkt nie istnieje to trzeba go dodać do listy punktów
        const point: IRSOPoint = {
          id: uuidv4(),
          calcId: p.calcId, // TODO - Na razie wszędzie 0 -> może będzie potrzebne później iterowane
          type: 1, // 1 – punkt jest na dachu (połaci nachylonej),
          status: 1, // 1 – punkt leży na obrysie dachu
          x: p.x,
          y: p.y,
          z: 0, // Na początek domyślnie 0
        };
        points.push(point);
      } else {
        // A jeśli istniał to tylko dodajemy
        points.push(p);
      }
    }

    // Pobierz największe calcId jakie jest w istniejacych krawędziach rsoEdges
    let maxCalcId = 0;
    if (rsoEdges && rsoEdges.length > 0) {
      maxCalcId = rsoEdges?.reduce((prev, current) => (prev.calcId > current.calcId ? prev : current)).calcId;
    }

    // 2. Przygotować krawędzie o typie empty
    const edges: IRSOEdge[] = [];
    for (let i = 0; i < pointsRef.current.length; i++) {
      const startPoint = pointsRef.current[i];
      const endPoint = i === pointsRef.current.length - 1 ? pointsRef.current[0] : pointsRef.current[i + 1];

      const startPointId = points.find((p) => p.x === startPoint.x && p.y === startPoint.y)?.id;
      const endPointId = points.find((p) => p.x === endPoint.x && p.y === endPoint.y)?.id;

      if (startPointId && endPointId) {
        // Sprawdzić czy taka krawędź z tymi punktmai juz nie istnieje (sprawdza po id punktów)
        // const existedEdge = rsoEdges.find((e) => e.startPointId === startPointId && e.endPointId === endPointId);

        const existedEdge = rsoEdges.find(
          (e) =>
            (e.startPointId === startPointId && e.endPointId === endPointId) ||
            (e.startPointId === endPointId && e.endPointId === startPointId)
        );

        if (!existedEdge) {
          const edge: IRSOEdge = {
            id: uuidv4(),
            calcId: maxCalcId + i + 1,
            // startPoint: startPoint, // nie jest potrzebne
            // endPoint: endPoint, // nie jest potrzebne
            startPoint: { x: 0, y: 0 },
            endPoint: { x: 0, y: 0 },
            type: EdgeType.Empty,
            status: 2, // 2 – zdefiniowano oba punkty
            startPointId: startPointId,
            endPointId: endPointId,
            startPointCalcId: 0,
            endPointCalcId: 0,
            active: true,
            hover: false,
          };

          edges.push(edge);
        } else {
          edges.push(existedEdge);
        }
      } else {
        console.log("Błąd w punktach");
        // Jeśłi błąd to może trzeba zmienić porównaie punktów z jakims przybliżeniem
      }
    }

    // 3. Przygotować połać
    const plane: IRSO = {
      id: uuidv4(),
      active: true,
      // points: [...pointsRef.current], nie jest już potrzebne
      points: [],
      edges: [], // było edges
      angle: getDefaultSlope(),
      linkedEdges: [],
      declineVectoor: { x: 0, y: 0 },
      isRealCoordinates: false,
      hover: false,
      pointIds: points.map((p) => p.id),
      edgeIds: edges.map((e) => e.id),
      pointCalcIds: [],
      edgeCalcIds: [],
      calcId: getLastRSOPlaneCalcId() + 1,
      type: 1, // 1 – połaci nachylonej
      status: 2, //2 – obrys połaci zamknięty
      area: 0, // TODO - do obliczenia,
      x: 0,
      y: 0,
    };

    setPoints([]);

    // Dodanie wszystkich punktów, krawędzi i połaci do redux
    dispatch(addRSOPoints(points));
    dispatch(addRSOEdges(edges));
    dispatch(addRSOPlane(plane));

    dispatch(invalidateCalculation());

    // // Przebudowa wszystki calcId
    // let newPoints = rsoPoints.map((x, i) => {
    //   return { ...x, calcId: i + 1 };
    // });

    // const newEdges = rsoEdges.map((x, i) => {
    //   return { ...x, calcId: i + 1 };
    // });

    // const newPlanes = rsoPlanes.map((x, i) => {
    //   return { ...x, calcId: i + 1 };
    // });

    // // Aktualizacja nowych danych
    // dispatch(setRSOPoints(newPoints));
    // dispatch(setRSOEdges(newEdges));
    // dispatch(setRSOPlanes(newPlanes));

    close();
  };

  const getLastRSOPlaneCalcId = () => {
    if (rsoPlanes.length > 0) {
      return rsoPlanes[rsoPlanes.length - 1].calcId;
    }
    return 0;
  };

  // Funkcja sprawdza, czy dany punkt jest na tej samej linii poziomej lub pionowej.
  const isPointOnLine = (mousePos: RSOPoint2D, point: RSOPoint2D): "horizontal" | "vertical" | "both" | null => {
    let horizontal = false;
    let vertical = false;

    if (Math.abs(mousePos.x - point.x) <= TOLERANCE) {
      vertical = true;
    }

    if (Math.abs(mousePos.y - point.y) <= TOLERANCE) {
      horizontal = true;
    }

    if (horizontal && vertical) {
      return "both";
    } else if (horizontal) {
      return "horizontal";
    } else if (vertical) {
      return "vertical";
    } else {
      return null;
    }
  };

  const getScaleFont = () => {
    // return scaleZoom >= 0 ? scaleZoom : 1;
    return 1;
  };

  const getPointForText = () => {
    if (pointsRef.current && pointsRef.current.length > 0) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      return middlePoint(lastPoint, mpLocalRef.current);
    }
    return { x: -9999, y: -9999 };
  };

  const getLengthForText = () => {
    if (pointsRef.current && pointsRef.current.length > 0) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];

      let lastPointToDist = mpLocalRef.current;
      if (nearestPointRef.current) {
        lastPointToDist = nearestPointRef.current;
      }

      const px = getDistTwoPoints(lastPoint, lastPointToDist);
      const val = px / scale;

      return `${val.toFixed(2)}`;
    }
    return "Błąd wymiaru";
  };

  // Potrzebuję wektor kierunkowy poprzedniej krawędzi - czyli zacznie działać gdy dodajemy 3 punktu lub kolejny
  const getDirectionVectorLastPart = () => {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      return { x: lastPoint.x - prevPoint.x, y: lastPoint.y - prevPoint.y };
    }
    return { x: 0, y: 0 };
  };

  // Funkjca zwracajaca dwa punkty z ostatniej lini
  const getLastPart = () => {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      return [prevPoint, lastPoint];
    }
    return null;
  };

  function getPointOnParallelLine() {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      const dx = lastPoint.x - prevPoint.x;
      const dy = lastPoint.y - prevPoint.y;

      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      const mp = { x: startPointPosition?.x || 0, y: startPointPosition?.y || 0 };

      if (dx === 0) {
        // Linia pionowa, zwróć punkt na tej samej współrzędnej x, ale z y z lastPoint
        return { x: lastPoint.x, y: mp.y };
      } else if (dy === 0) {
        // Linia pozioma, zwróć punkt na tej samej współrzędnej y, ale z x z lastPoint
        return { x: mp.x, y: lastPoint.y };
      } else {
        const slope = dy / dx;
        const invSlope = -dx / dy; // Współczynnik kierunkowy prostej prostopadłej
        const intercept = mp.y - invSlope * mp.x; // Wyraz wolny prostej prostopadłej

        // Oblicz współrzędne punktu na linii równoległej do AB
        const px = (intercept + slope * prevPoint.x - prevPoint.y) / (slope - invSlope);
        const py = slope * (px - prevPoint.x) + prevPoint.y;
        return { x: px, y: py };
      }
    }
    return null; // Jeśli nie ma wystarczającej liczby punktów
  }

  //

  // Funkcja zwracająca ługośc z uwzglednienim scale od getPointOnParallelLine do ostatniego punktu
  const getLengthForText1 = () => {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];

      const px = getDistTwoPoints(getPointOnParallelLine() || { x: 0, y: 0 }, lastPoint);
      const val = px / scale;

      return `${val.toFixed(2)}`;
    }
    return "Błąd wymiaru";
  };

  // Funkcja zwracajaca punkt do umieszczenia wymiaru getLengthForText1 - na środku pomiedzy punktami getPointOnParallelLine ostatnim punktem
  const getPointForText1 = () => {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      const dx = lastPoint.x - prevPoint.x;
      const dy = lastPoint.y - prevPoint.y;

      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      const mp = { x: startPointPosition?.x || 0, y: startPointPosition?.y || 0 };

      if (dx === 0) {
        // Linia pionowa, zwróć punkt na tej samej współrzędnej x, ale z y z lastPoint
        return middlePoint({ x: lastPoint.x, y: mp.y }, lastPoint);
      } else if (dy === 0) {
        // Linia pozioma, zwróć punkt na tej samej współrzędnej y, ale z x z lastPoint
        return middlePoint({ x: mp.x, y: lastPoint.y }, lastPoint);
      } else {
        const slope = dy / dx;
        const invSlope = -dx / dy; // Współczynnik kierunkowy prostej prostopadłej
        const intercept = mp.y - invSlope * mp.x; // Wyraz wolny prostej prostopadłej

        // Oblicz współrzędne punktu na linii równoległej do AB
        const px = (intercept + slope * prevPoint.x - prevPoint.y) / (slope - invSlope);
        const py = slope * (px - prevPoint.x) + prevPoint.y;

        return middlePoint({ x: px, y: py }, lastPoint);
      }
    }
    return { x: -9999, y: -9999 }; // Jeśli nie ma wystarczającej liczby punktów
  };

  // Funkcja zwracająca ługośc z uwzglednienim scale od getPointOnParallelLine do mp czyli położeni amyszki
  const getLengthForText2 = () => {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      let lastPointToDist = mpLocalRef.current;
      if (nearestPointRef.current) {
        lastPointToDist = nearestPointRef.current;
      }

      const px = getDistTwoPoints(getPointOnParallelLine() || { x: 0, y: 0 }, lastPointToDist);
      const val = px / scale;

      return `${val.toFixed(2)}`;
    }
    return "Błąd wymiaru";
  };

  // Funkcja zwracajaca punkt do umieszczenia wymiaru getLengthForText2 - na środku pomiedzy punktami getPointOnParallelLine i mp
  const getPointForText2 = () => {
    if (pointsRef.current.length > 1) {
      const lastPoint = pointsRef.current[pointsRef.current.length - 1];
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      const dx = lastPoint.x - prevPoint.x;
      const dy = lastPoint.y - prevPoint.y;

      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      const mp = { x: startPointPosition?.x || 0, y: startPointPosition?.y || 0 };

      if (dx === 0) {
        // Linia pionowa, zwróć punkt na tej samej współrzędnej x, ale z y z lastPoint
        return middlePoint({ x: lastPoint.x, y: mp.y }, mp);
      } else if (dy === 0) {
        // Linia pozioma, zwróć punkt na tej samej współrzędnej y, ale z x z lastPoint
        return middlePoint({ x: mp.x, y: lastPoint.y }, mp);
      } else {
        const slope = dy / dx;
        const invSlope = -dx / dy; // Współczynnik kierunkowy prostej prostopadłej
        const intercept = mp.y - invSlope * mp.x; // Wyraz wolny prostej prostopadłej

        // Oblicz współrzędne punktu na linii równoległej do AB
        const px = (intercept + slope * prevPoint.x - prevPoint.y) / (slope - invSlope);
        const py = slope * (px - prevPoint.x) + prevPoint.y;

        return middlePoint({ x: px, y: py }, mp);
      }
    }
    return { x: -9999, y: -9999 }; // Jeśli nie ma wystarczającej liczby punktów
  };

  // Funkcja zwracająca odległość między dwoma punktami położeniem myszki i punktem przedostanim
  const getLengthForText3 = () => {
    if (pointsRef.current.length > 1) {
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      let lastPointToDist = mpLocalRef.current;
      if (nearestPointRef.current) {
        lastPointToDist = nearestPointRef.current;
      }

      const px = getDistTwoPoints(lastPointToDist, prevPoint);
      const val = px / scale;

      return `${val.toFixed(2)}`;
    }
    return "Błąd wymiaru";
  };

  // Funkcja zwracajaca punkt do umieszczenia wymiaru getLengthForText3 - na środku pomiedzy punktami przedostatni punkt i mp
  const getPointForText3 = () => {
    if (pointsRef.current.length > 1) {
      const prevPoint = pointsRef.current[pointsRef.current.length - 2];

      const dx = prevPoint.x - mpLocalRef.current.x;
      const dy = prevPoint.y - mpLocalRef.current.y;

      if (dx === 0) {
        // Linia pionowa, zwróć punkt na tej samej współrzędnej x, ale z y z lastPoint
        return middlePoint({ x: prevPoint.x, y: mpLocalRef.current.y }, prevPoint);
      } else if (dy === 0) {
        // Linia pozioma, zwróć punkt na tej samej współrzędnej y, ale z x z lastPoint
        return middlePoint({ x: mpLocalRef.current.x, y: prevPoint.y }, prevPoint);
      } else {
        const slope = dy / dx;
        const invSlope = -dx / dy; // Współczynnik kierunkowy prostej prostopadłej
        const intercept = mpLocalRef.current.y - invSlope * mpLocalRef.current.x; // Wyraz wolny prostej prostopadłej

        // Oblicz współrzędne punktu na linii równoległej do AB
        const px = (intercept + slope * prevPoint.x - prevPoint.y) / (slope - invSlope);
        const py = slope * (px - prevPoint.x) + prevPoint.y;

        return middlePoint({ x: px, y: py }, prevPoint);
      }
    }
    return { x: -9999, y: -9999 }; // Jeśli nie ma wystarczającej liczby punktów
  };

  return (
    <Group>
      <Line
        points={points
          .flatMap((p) => [p.x, p.y])
          .concat(
            alignedPointRef.current
              ? [alignedPointRef.current.x, alignedPointRef.current.y]
              : [mpLocal.x, mpLocal.y]
          )}
        closed={true}
        fill="#d0fabdcc"
        stroke="#ff0000cc"
        strokeWidth={4}
      />

      {nearestPoint && <Circle x={nearestPoint.x} y={nearestPoint.y} radius={10} fill={"#969696"} />}

      {alignedPointRef.current && (
        <Group>
          <Line
            points={[-9999, alignedPointRef.current.y, 9999, alignedPointRef.current.y]}
            stroke={"gray"}
            strokeWidth={0.5}
          />
          <Line
            points={[alignedPointRef.current.x, -99999, alignedPointRef.current.x, 99999]}
            stroke={"gray"}
            strokeWidth={0.5}
          />
          <Circle x={alignedPointRef.current?.x} y={alignedPointRef.current?.y} radius={4} fill="#c4c4c4cc" />
        </Group>
      )}

      <Text
        fontStyle="bold"
        fontSize={14 / getScaleFont()}
        x={getPointForText()?.x}
        y={getPointForText()?.y}
        text={getLengthForText()}
      />

      {/* Punkt na linii równoległej do ostatniej lini: */}
      {getPointOnParallelLine() && (
        <Circle
          x={getPointOnParallelLine()?.x}
          y={getPointOnParallelLine()?.y}
          radius={2}
          fill="#4e4c4ccc"
          stroke={"#000000"}
          strokeWidth={1}
        />
      )}

      {/* Linia przerywana z wymiarem od getPointOnParallelLine do mp */}
      {getPointOnParallelLine() && (
        <Line
          points={[getPointOnParallelLine()?.x || 0, getPointOnParallelLine()?.y || 0, mpLocal.x, mpLocal.y]}
          stroke={"#000000"}
          strokeWidth={1}
          dash={[5, 5]}
        />
      )}

      {/* Tekst z wymiarem od getPointOnParallelLine do mp */}
      <Text
        fontStyle="bold"
        fontSize={14 / getScaleFont()}
        x={getPointForText2()?.x}
        y={getPointForText2()?.y}
        text={getLengthForText2().toString()}
      />

      {/* Linia przerywana z wymiarem od getPointOnParallelLine do ostatniego punktu */}
      {getPointOnParallelLine() && getLastPart() && (
        <Line
          points={[
            getPointOnParallelLine()?.x || 0,
            getPointOnParallelLine()?.y || 0,
            pointsRef.current[pointsRef.current.length - 1].x,
            pointsRef.current[pointsRef.current.length - 1].y,
          ]}
          stroke={"#000000"}
          strokeWidth={1}
          dash={[5, 5]}
        />
      )}

      {/* Tekst z wymiarem od getPointOnParallelLine do ostatniego punktu */}
      <Text
        fontStyle="bold"
        fontSize={14 / getScaleFont()}
        x={getPointForText1()?.x}
        y={getPointForText1()?.y}
        text={getLengthForText1()?.toString()}
      />

      {/* Linia przerywana z wymiarem od getPointOnParallelLine do przedostniego punktu */}
      {getPointOnParallelLine() && getLastPart() && (
        <Line
          points={[
            mpLocal.x,
            mpLocal.y,
            pointsRef.current[pointsRef.current.length - 2].x,
            pointsRef.current[pointsRef.current.length - 2].y,
          ]}
          stroke={"#000000"}
          strokeWidth={1}
          dash={[5, 5]}
        />
      )}

      {/* Tekst z wymiarem od mp do przedostatniego punktu */}
      <Text
        fontStyle="bold"
        fontSize={14 / getScaleFont()}
        x={getPointForText3()?.x}
        y={getPointForText3()?.y}
        text={getLengthForText3()?.toString()}
      />
    </Group>
  );
};

export default Function1Handler2;
