import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProductCategory } from "../../models/Models";

const initialState: Array<IProductCategory> = [];

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState,
  reducers: {
    addProductCategoriesInReducer: (state, action: PayloadAction<Array<IProductCategory>>) => {
      let productCategories = action.payload;
      return productCategories;
    },
  },
});

export const { addProductCategoriesInReducer } = productCategoriesSlice.actions;
export default productCategoriesSlice.reducer;
