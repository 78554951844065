import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { IPoint } from "../../models/Models";
import { AppThunk } from "../store";
import {
  resetRoofProcessStatus,
  selectRoofProcessStatusType,
  updateRoofProcessStatus,
} from "./roofProcessStatusSlice";

export interface IRoofProcessFunctionsStatus {
  isActive: boolean; // Czy aktywny proces rysowania dachu (może być widoczny modal lub ukryty np. podczas pobierania skali rysunku)
  isVisible: boolean; // Czy widoczne elementy związane z przygotowaniem do rysowania dachu (założenie jest, że jest to jakiś modal)
  withTemplate: boolean | undefined; // Czy z podkładem rysunkowym. Undefined oznacza, że jeszcze nie zostało to ustalone
  confirmedTemplate: boolean | undefined; // Czy potwierdzony podkład rysunkowy. Undefined oznacza, że jeszcze nie zostało to ustalone. True to że dodany a false że potwierdzony brak podkładu
  currentPoints: IPoint[]; // Punkty podczas rysowania obrysu dachu
  loadingDrawingScale: boolean; // Czy w trakcie pobierania skali rysunku

  inDrawOutline: boolean; // Czy w trakcie rysowania obrysu dachu
  inDrawUnit: boolean; // czy w trakcie rysowania jednostki
  inSelectManyEdges: boolean; // czy w trakcie wybierania krawędzi dla jednej połaci
  inMoveEdge: boolean; // czy w trakcie przesunięcia rzutu kalenicy aby zmienić wysokośc połaci i połaci przynależących
  isCloseOutline: boolean; // czy zamknięty obwód dachu
  selectedEdges: string[];
  pointsForMove: any[];
  pointTracking: boolean;
}

const initialState: IRoofProcessFunctionsStatus = {
  isActive: false,
  isVisible: false,
  withTemplate: undefined,
  confirmedTemplate: undefined,
  currentPoints: [],
  loadingDrawingScale: false,
  inDrawOutline: false,
  inDrawUnit: false,
  inSelectManyEdges: false,
  inMoveEdge: false,
  isCloseOutline: false,
  selectedEdges: [],
  pointsForMove: [],
  pointTracking: false,
};

export const roofProcessFunctionsStatusSlice = createSlice({
  name: "roofProcessFunctionsStatus",
  initialState,
  reducers: {
    setRoofProcessFunctionsStatus: (state, action: PayloadAction<IRoofProcessFunctionsStatus>) => {
      return action.payload;
    },
    updateRoofProcessFunctionsStatus: (state, action: PayloadAction<IRoofProcessFunctionsStatus>) => {
      return action.payload;
    },
    resetRoofProcessFunctionsStatus: (state) => {
      return initialState;
    },
    setSavedRoofProcessFunctionsStatus: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  setRoofProcessFunctionsStatus,
  updateRoofProcessFunctionsStatus,
  resetRoofProcessFunctionsStatus,
  setSavedRoofProcessFunctionsStatus,
} = roofProcessFunctionsStatusSlice.actions;
export default roofProcessFunctionsStatusSlice.reducer;

// Funkcja uruchaiająca proces rysowania dachu ale już z predefiniowanym typem rysowania
// Wywołane kliknięciem w jeden z typów rysowania
export const startRoofProcessWithType =
  (type: "preset" | "outline" | "general"): AppThunk =>
  (dispatch: Dispatch<any>) => {
    dispatch(
      updateRoofProcessFunctionsStatus({
        isActive: true,
        isVisible: true,
        withTemplate: undefined,
        confirmedTemplate: undefined,
        currentPoints: [],
        loadingDrawingScale: false,
        inDrawOutline: false,
        inDrawUnit: false,
        inSelectManyEdges: false,
        inMoveEdge: false,
        isCloseOutline: false,
        selectedEdges: [],
        pointsForMove: [],
        pointTracking: false,
      })
    );
    dispatch(selectRoofProcessStatusType(type));
  };

// Uruchamia nowy cąły proces rysowania dachu
// Wywołane kliknięciem Nowy projekt
export const startNewRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  // const { isActive, isVisible, withTemplate, currentPoints } = getState().roofProcessFunctionsStatus;

  // TODO tu chtba będzie również wywopołanie (clearData() który czyści wszytsko w reduxie co związane z rysownaiem dachu
  dispatch(resetRoofProcessStatus()); // Resetuje wszystkie ustawienia rysunku
  dispatch(
    updateRoofProcessFunctionsStatus({
      isActive: true,
      isVisible: true,
      withTemplate: undefined,
      confirmedTemplate: undefined,
      currentPoints: [],
      loadingDrawingScale: false,
      inDrawOutline: false,
      inDrawUnit: false,
      inSelectManyEdges: false,
      inMoveEdge: false,
      isCloseOutline: false,
      selectedEdges: [],
      pointsForMove: [],
      pointTracking: false,
    })
  );
};

// Kończy proces przygotowania do rysunku dachu
// Wywołane kliknięciem Anuluj
export const finishNewRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const {
    isActive,
    isVisible,
    withTemplate,
    currentPoints,
    confirmedTemplate,
    loadingDrawingScale,
    inDrawOutline,
    inDrawUnit,
    inSelectManyEdges,
    inMoveEdge,
    isCloseOutline,
    selectedEdges,
    pointsForMove,
  } = getState().roofProcessFunctionsStatus;
  dispatch(
    updateRoofProcessFunctionsStatus({
      isActive: true,
      isVisible: false,
      withTemplate,
      currentPoints,
      confirmedTemplate,
      loadingDrawingScale: false,
      inDrawOutline: inDrawOutline,
      inDrawUnit: inDrawUnit,
      inSelectManyEdges: inSelectManyEdges,
      inMoveEdge: inMoveEdge,
      isCloseOutline: isCloseOutline,
      selectedEdges: selectedEdges,
      pointsForMove: pointsForMove,
      pointTracking: false,
    })
  );
};

// Zamyka cały proces rysowania dachu i resetuje wszystkie dane
// Wywołane kliknięciem Anuluj
export const cancelRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  dispatch(resetRoofProcessStatus()); // Resetuje wszystkie ustawienia rysunku
  dispatch(resetRoofProcessFunctionsStatus()); // Resetuje wszystkie ustawienia rysunku
};

export const updateRoofProcessWithTemplate =
  (value: boolean, confirmTemplate?: boolean): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const {
      isActive,
      isVisible,
      withTemplate,
      confirmedTemplate,
      currentPoints,
      loadingDrawingScale,
      inDrawOutline,
      inDrawUnit,
      inSelectManyEdges,
      inMoveEdge,
      isCloseOutline,
      selectedEdges,
      pointsForMove,
    } = getState().roofProcessFunctionsStatus;
    dispatch(
      updateRoofProcessFunctionsStatus({
        isActive,
        isVisible,
        withTemplate: value,
        confirmedTemplate: confirmTemplate !== undefined ? confirmTemplate : confirmedTemplate,
        currentPoints,
        loadingDrawingScale,
        inDrawOutline: inDrawOutline,
        inDrawUnit: inDrawUnit,
        inSelectManyEdges: inSelectManyEdges,
        inMoveEdge: inMoveEdge,
        isCloseOutline: isCloseOutline,
        selectedEdges: selectedEdges,
        pointsForMove: pointsForMove,
        pointTracking: false,
      })
    );
  };

// Funkcja aktywująca pobieranie skali dla rysunku
export const startLoadingDrawingScale = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const {
    isActive,
    isVisible,
    withTemplate,
    confirmedTemplate,
    currentPoints,
    loadingDrawingScale,
    inDrawOutline,
    inDrawUnit,
    inSelectManyEdges,
    inMoveEdge,
    isCloseOutline,
    selectedEdges,
    pointsForMove,
  } = getState().roofProcessFunctionsStatus;
  dispatch(
    updateRoofProcessFunctionsStatus({
      isActive,
      isVisible: false,
      withTemplate,
      confirmedTemplate,
      currentPoints,
      loadingDrawingScale: true,
      inDrawOutline: inDrawOutline,
      inDrawUnit: inDrawUnit,
      inSelectManyEdges: inSelectManyEdges,
      inMoveEdge: inMoveEdge,
      isCloseOutline: isCloseOutline,
      selectedEdges: selectedEdges,
      pointsForMove: pointsForMove,
      pointTracking: false,
    })
  );
};

// Funkcja kończąca pobieranie skali dla rysunku
export const finishLoadingDrawingScale = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const {
    isActive,
    isVisible,
    withTemplate,
    confirmedTemplate,
    currentPoints,
    loadingDrawingScale,
    inDrawOutline,
    inDrawUnit,
    inSelectManyEdges,
    inMoveEdge,
    isCloseOutline,
    selectedEdges,
    pointsForMove,
  } = getState().roofProcessFunctionsStatus;
  dispatch(
    updateRoofProcessFunctionsStatus({
      isActive,
      isVisible: true,
      withTemplate,
      confirmedTemplate,
      currentPoints,
      loadingDrawingScale: false,
      inDrawOutline: inDrawOutline,
      inDrawUnit: inDrawUnit,
      inSelectManyEdges: inSelectManyEdges,
      inMoveEdge: inMoveEdge,
      isCloseOutline: isCloseOutline,
      selectedEdges: selectedEdges,
      pointsForMove: pointsForMove,
      pointTracking: false,
    })
  );
};

// Funkcja kończąca rysownaie obrysu dachu
export const endDrawOutlineRoof = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const {
    isActive,
    isVisible,
    withTemplate,
    confirmedTemplate,
    currentPoints,
    loadingDrawingScale,
    inDrawOutline,
    inDrawUnit,
    inSelectManyEdges,
    inMoveEdge,
    isCloseOutline,
    selectedEdges,
    pointsForMove,
  } = getState().roofProcessFunctionsStatus;
  dispatch(
    updateRoofProcessFunctionsStatus({
      isActive,
      isVisible,
      withTemplate,
      confirmedTemplate,
      currentPoints,
      loadingDrawingScale,
      inDrawOutline: false,
      inDrawUnit: inDrawUnit,
      inSelectManyEdges: inSelectManyEdges,
      inMoveEdge: inMoveEdge,
      isCloseOutline: true,
      selectedEdges: selectedEdges,
      pointsForMove: pointsForMove,
      pointTracking: false,
    })
  );
};

export const addPointMoveEdgeToDrawRoofProcess =
  (point: any): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const rpfs = getState().roofProcessFunctionsStatus;

    const points = [...rpfs.pointsForMove, point];

    dispatch(
      updateRoofProcessFunctionsStatus({
        ...rpfs,
        pointsForMove: [...points],
      })
    );
  };

export const startMoveEdgeDrawRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      inMoveEdge: true,
    })
  );
};

export const startSelectManyEdgesDrawRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      inSelectManyEdges: true,
    })
  );
};

export const endMoveEdgeDrawRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      inMoveEdge: false,
      pointsForMove: [],
    })
  );
};

export const deleteEdgeFromSelectedToDrawRoofProcess =
  (edgeId: string): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const rpfs = getState().roofProcessFunctionsStatus;

    const edges = [...rpfs.selectedEdges].filter((o) => o !== edgeId);

    dispatch(
      updateRoofProcessFunctionsStatus({
        ...rpfs,
        selectedEdges: edges,
      })
    );
  };

export const addSelectedEdgeToDrawRoofProcess =
  (edgeId: string): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const rpfs = getState().roofProcessFunctionsStatus;

    const edges = [...rpfs.selectedEdges, edgeId];

    dispatch(
      updateRoofProcessFunctionsStatus({
        ...rpfs,
        selectedEdges: edges,
      })
    );
  };

export const clearSelectedEdgeToDrawRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      selectedEdges: [],
      inSelectManyEdges: false,
    })
  );
};

export const startDrawNewRoofOutline = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      inDrawOutline: true,
    })
  );
};

export const cancelDrawNewRoofOutline = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      inDrawOutline: false,
      isActive: false,
    })
  );
};

export const startDrawNextRoofOutline = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rpfs = getState().roofProcessFunctionsStatus;

  dispatch(
    updateRoofProcessFunctionsStatus({
      ...rpfs,
      isActive: true,
      inDrawOutline: true,
    })
  );
};

export const updatePointTracking =
  (pointTracking: boolean): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const rpfs = getState().roofProcessFunctionsStatus;

    dispatch(
      updateRoofProcessFunctionsStatus({
        ...rpfs,
        pointTracking: pointTracking,
      })
    );
  };
