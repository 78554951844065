import { Store } from "@reduxjs/toolkit";
import React, { FC, useEffect, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { useStore } from "react-redux";
import { getNearestPoint } from "../../../../../helpers/Helpers";
import { useFirstRender } from "../../../../../hooks/useFirstRender";
import { IBasicPoint, IPoint } from "../../../../../models/Models";
import {
  addPointDrawChimney,
  setRedyToCalcDrawChimney,
  setRoofPlaneCalcIdDrawChimney,
} from "../../../../../redux/chimneys/drawChimneyProcessSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

const getFlatPoints = (points: IBasicPoint[]): number[] => points.map((p) => [p.x, p.y]).flat();

// CONSTANTS
const DIST = 5;

type Props = {
  active: boolean;
  eventClickStage: any;
  scaleZoom: number;
  endDraw: any;
  generateChimney: any;
  gridPoints: IPoint[];
};

const DrawChimney: FC<Props> = ({ active, eventClickStage, scaleZoom, endDraw, generateChimney, gridPoints }) => {
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();
  const points: IBasicPoint[] = useAppSelector((state) => state.drawChimneyProcess.points);
  const store: Store = useStore();

  const gridSettings = useAppSelector((state) => state.domaGrid);
  const isGridSnapping = gridSettings && gridSettings.gridVisible && gridSettings.gridSnapping ? true : false;

  useEffect(() => {
    if (!firstRender) {
      if (active) {
        if (eventClickStage?.evt.button === 0) {
          const mp: IBasicPoint = store.getState().mousePosition;
          if (points) {
            const mpn = getNearestPoint(mp, points, DIST, gridPoints, isGridSnapping);
            dispatch(addPointDrawChimney(mpn.point));
            //   dispatch(setRoofPlaneCalcIdDrawChimney(activeRoofPlaneId));
            if (points.length > 0 && mp) {
              if (mp && mpn.point && mpn.point.x === points[0].x && mpn.point.y === points[0].y) {
                generateChimney();
              }
            }
          }
        }

        // Jeśli naciśnięto prawy przycisk myszy
        if (eventClickStage?.evt.button === 2 && points.length > 0) {
          dispatch(addPointDrawChimney(points[0])); // Dodaj pierwszy punkt jako ostatni
          generateChimney();
        }
      }
    }
  }, [eventClickStage]);

  return null;
};
export default DrawChimney;
