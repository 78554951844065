import { combineReducers } from "redux";
import { AnyAction, configureStore, getDefaultMiddleware, ThunkAction } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import additionalElementsSlice from "./calculationProcess/additionalElementsSlice";
import calcMaterialsDataSlice from "./calculationProcess/calcMaterialsDataSlice";
import calcResultSlice from "./calculationProcess/calcResultSlice";
import companiesSlice from "./general/companiesSlice";
import priceUnitsSlice from "./general/priceUnitsSlice";
import productCategoriesSlice from "./general/productCategoriesSlice";
import productColorsSlice from "./general/productColorsSlice";
import productsSlice from "./general/productsSlice";
import productUseTypesSlice from "./general/productUseTypesSlice";
import productUseUnitsSlice from "./general/productUseUnitsSlice";
import profileSlice from "./general/profileSlice";
import salesPackagesSlice from "./general/salesPackagesSlice";
import usersSlice from "./general/usersSlice";
import statusApiSlice from "./other/statusApiSlice";
import calcDataSlice from "./roofs/calcDataSlice";
import drawingScaleSlice from "./roofs/drawingScaleSlice";
import generalRoofDataSlice from "./roofs/generalRoofDataSlice";
import inputDataSlice from "./roofs/inputDataSlice";
import roofDrawingStoreSlice from "./roofs/roofDrawingStoreSlice";
import roofEdgesSlice from "./roofs/roofEdgesSlice";
import roofHolesSlice from "./roofs/roofHolesSlice";
import roofPlanesSlice from "./roofs/roofPlanesSlice";
import roofPointsSlice from "./roofs/roofPointsSlice";
import templateImageSlice from "./roofs/templateImageSlice";
import gutterSystemsSlice from "./roofSystems/gutterSystemsSlice";
import tileSystemsSlice from "./roofSystems/tileSystemsSlice";
import mousePositionSlice from "./drawing/mousePositionSlice";
import drawChimneyProcessSlice from "./chimneys/drawChimneyProcessSlice";
import windowsSlice from "./calculationProcess/windowsSlice";
import reportsSlice from "./general/reportsSlice";
import outlineSlice from "./drawing/outlineSlice";
import gutterSystemAdditionTypesSlice from "./roofSystems/gutterSystemAdditionTypesSlice";
import tileSystemAdditionTypesSlice from "./roofSystems/tileSystemAdditionTypesSlice";
import investmentsSlice from "./general/investmentsSlice";
import generalSlice from "./general/generalSlice";
import productModelsSlice from "./general/productModelsSlice";
import importedInfoSlice from "./import/importedInfoSlice";
import loadingStateSlice from "./loading/loadingStateSlice";
import parentStaticSlice from "./general/parentStaticSlice";
import gutteringElementsSlice from "./roofs/gutteringElementsSlice";
import contractorSlice from "./contractors/contractorSlice";
import contractorServicesSlice from "./contractors/contractorServicesSlice";
import contractorPriceListSlice from "./contractors/contractorPriceListSlice";
import roofContourNumberSlice from "./roofs/roofContourNumberSlice";
import princingSystemsSlice from "./contractors/princingSystemsSlice";
import parentContractorsSlice from "./contractors/parentContractorsSlice";
import externalTileSystemsSlice from "./external/externalTileSystemsSlice";
import externalGutterSystemsSlice from "./external/externalGutterSystemsSlice";
import externalPrincingSystemsSlice from "./external/externalPrincingSystemsSlice";
import roofSlopesSlice from "./roofMetal/roofSlopesSlice";
import metalSheetsSlice from "./roofMetal/metalSheetsSlice";
import  rsoLinkedEdgesSlice from "./roofSlopeOutline/rsoLinkedEdgesSlice";
import rsoPointsSlice from "./roofSlopeOutline/rsoPointsSlice";
import rsoEdgesSlice from "./roofSlopeOutline/rsoEdgesSlice";
import rsoPlanesSlice from "./roofSlopeOutline/rsoPlanesSlice";
import reportSlice from "./calculationProcess/reportSlice";
import resetSlice, { resetRedux } from "./resetSlice";
import roofProcessStatusSlice from "./drawing/roofProcessStatusSlice";
import roofProcessFunctionsStatusSlice from "./drawing/roofProcessFunctionsStatusSlice";
import savedOutlinesSlice from "./drawing/savedOutlinesSlice";
import rsoHolesSlice from "./roofSlopeOutline/rsoHolesSlice";
import steelSystemsSlice from "./roofSystems/steelSystemsSlice";
import steelSystemAdditionTypesSlice from "./roofSystems/steelSystemAdditionTypesSlice";
import sidebarStatusSlice from "./other/sidebarStatusSlice";
import domaGridSlice from "./general/domaGridSlice";
import notificationsSlice from "./informations/notificationsSlice";
import notificationTypesSlice from "./informations/notificationTypesSlice";
import systemActivitiesSlice from "./informations/systemActivitiesSlice";
import systemActivityTypesSlice from "./informations/systemActivityTypesSlice";
import informationsSlice from "./informations/informationsSlice";
import preCoveringSystemsSlice from "./roofSystems/preCoveringSystemsSlice";
import fasteningSystemsSlice from "./roofSystems/fasteningSystemsSlice";
import roofAccessoriesSlice from "./roofs/roofAccessoriesSlice";
import windowHolesSlice from "./calculationProcess/windowHolesSlice";
import windowIntegrationsSlice from "./general/windowIntegrationsSlice";
import geometryDataSlice from "./roofs/geometryDataSlice";
import groupDiscountsSlice from "./calculationProcess/groupDiscountsSlice";
import currencyValueSlice from "./general/currencyValueSlice";
import filtersForSystemsSlice from "./calculationProcess/filtersForSystemsSlice";
import productsGroupsSlice from "./productsGroups/productsGroupsSlice";
import technicalEquipmentsGroupsSlice from "./technicalEquipmentsGroups/technicalEquipmentsGroupsSlice";
import parentSettingsSlice from "./general/parentSettingsSlice";
import additionalProductsSlice from "./calculationProcess/additionalProductsSlice";
import otherAppStatusesSlice from "./calculationProcess/otherAppStatusesSlice";
import baysSlice from "./drawing/baysSlice";
import connectPlanesSlice from "./drawing/connectPlanesSlice";
import responsesApiSlice from "./general/responsesApiSlice";
import savedAdditionalProductsSlice from "./calculationProcess/savedAdditionalProducts";

export const rootReducer = combineReducers({
  general: generalSlice,
  auth: authSlice,
  loadingState: loadingStateSlice,
    statusApi: statusApiSlice,
    calcResult: calcResultSlice, // obiekt z wszystkimi wynikami obliczeń i późniejszymi modyfikacjami do raportu
    mousePosition: mousePositionSlice,
    outline: outlineSlice,
    profile: profileSlice,
    users: usersSlice,
    companies: companiesSlice,
    productCategories: productCategoriesSlice,
    products: productsSlice,
    productUseTypes: productUseTypesSlice,
    productColors: productColorsSlice,
    priceUnits: priceUnitsSlice,
    productUseUnits: productUseUnitsSlice,
    salesPackages: salesPackagesSlice,
    reports: reportsSlice,
    report: reportSlice,
    tileSystems: tileSystemsSlice,
    steelSystems: steelSystemsSlice,
    gutterSystems: gutterSystemsSlice,
    calcMaterialsData: calcMaterialsDataSlice,
    additionalElements: additionalElementsSlice,
    templateImage: templateImageSlice,
    drawingScale: drawingScaleSlice,
    calcData: calcDataSlice,
    inputData: inputDataSlice,
    roofDrawingStore: roofDrawingStoreSlice,
    generalRoofData: generalRoofDataSlice,
    roofPoints: roofPointsSlice,
    roofPlanes: roofPlanesSlice,
    roofEdges: roofEdgesSlice,
    roofHoles: roofHolesSlice,
    drawChimneyProcess: drawChimneyProcessSlice,
    windows: windowsSlice,
    gutterSystemAdditionTypes: gutterSystemAdditionTypesSlice,
    tileSystemAdditionTypes: tileSystemAdditionTypesSlice,
    steelSystemAdditionTypes: steelSystemAdditionTypesSlice,
    investments: investmentsSlice,
    productModels: productModelsSlice,
    importedInfo: importedInfoSlice,
    parentStatic: parentStaticSlice,
    reset: resetSlice,
    gutteringElements: gutteringElementsSlice,
    contractor: contractorSlice,
    contractorServices: contractorServicesSlice,
    contractorPriceLists: contractorPriceListSlice,
    roofContourNumber: roofContourNumberSlice,
    princingSystems: princingSystemsSlice,
    parentContractors: parentContractorsSlice,
    externalTileSystems: externalTileSystemsSlice,
    externalGutterSystems: externalGutterSystemsSlice,
    externalPrincingSystems: externalPrincingSystemsSlice,
    roofSlopes: roofSlopesSlice,
    metalSheets: metalSheetsSlice,
    rsoLinkedEdges: rsoLinkedEdgesSlice,
    rsoPoints: rsoPointsSlice,
    rsoEdges: rsoEdgesSlice,
    rsoPlanes: rsoPlanesSlice,
    rsoHoles: rsoHolesSlice,
    roofProcessStatus: roofProcessStatusSlice,
    roofProcessFunctionsStatus: roofProcessFunctionsStatusSlice,
    savedOutlines: savedOutlinesSlice,
    sidebarStatus: sidebarStatusSlice,
    domaGrid: domaGridSlice,
    notifications: notificationsSlice,
    notificationTypes: notificationTypesSlice,
    systemActivities: systemActivitiesSlice,
    systemActivityTypes: systemActivityTypesSlice,
    informations: informationsSlice,
    preCoveringSystems: preCoveringSystemsSlice,
    fasteningSystems: fasteningSystemsSlice,
    roofAccessories: roofAccessoriesSlice,
    windowHoles: windowHolesSlice,
    windowIntegration: windowIntegrationsSlice,
    geometryData: geometryDataSlice,
    groupDiscounts: groupDiscountsSlice,
    currencyValue: currencyValueSlice,
    filtersForSystems: filtersForSystemsSlice,
    productsGroups: productsGroupsSlice,
    technicalEquipmentsGroups: technicalEquipmentsGroupsSlice,
    parentSettings: parentSettingsSlice,
    additionalProducts: additionalProductsSlice,
    otherAppStatuses: otherAppStatusesSlice,
    baysState: baysSlice,
    connectPlanes: connectPlanesSlice,
    responsesApi: responsesApiSlice,
    savedAdditionalProducts: savedAdditionalProductsSlice,
});

// Reducer resetujący gdy trzeba
const rootReducerWithReset = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === resetRedux.type) {
    // Resetujemy wszystkie slice'y do swoich stanów początkowych
    state = undefined;
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer: rootReducerWithReset,
  middleware: getDefaultMiddleware({ serializableCheck: false }),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;

// export type RootState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>