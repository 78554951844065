import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IRootState, ISteelSystem } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { deleteSteelSystem } from "../../../redux/roofSystems/steelSystemsSlice";
import SteelSystemDetails from "./components/SteelSystemDetails";

type Props = {};

const EditSteelSystemPage: FC<Props> = () => {
  const { steelSystemId } = useParams();
  const [steelSystem, setSteelSystem] = useState<ISteelSystem | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const steelSystems = useAppSelector((state: IRootState) => state.steelSystems);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteSteelSystem")
  );

  useEffect(() => {
    const s = steelSystems.find((x) => x.id === steelSystemId);
    if (s) {
      setSteelSystem(s);
    }
  }, [steelSystem]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteSteelSystem"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${steelSystem?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && steelSystem && (
              <Button danger onClick={() => dispatch(deleteSteelSystem(steelSystem))}>
                Usuń
              </Button>
            )}
            <Button type="primary" form="steelSystemDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>

            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy system
            </Button>
          </div>
        }
      />

      <Content>
        {steelSystem && (
          <SteelSystemDetails
            system={steelSystem}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};

export default EditSteelSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
