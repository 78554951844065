import { Alert, Button, Card, Checkbox, Input, Select, Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../api/ApiClient";
import PageHeader from "../../../layout/components/PageHeader";
import { IContractor } from "../../../models/Models";
import {
  addParentContractorInReducer,
  deleteContractorInReducer,
  setParentContractorsInReducer,
} from "../../../redux/contractors/parentContractorsSlice";
import {
  deletePrincingSystemsByContractorIdInReducer,
  setPrincingSystemsInReducer,
} from "../../../redux/contractors/princingSystemsSlice";
import {
  addExternalPrincingSystemsInReducer,
  deleteExternalPrincingSystemsByContractorIdInReducer,
} from "../../../redux/external/externalPrincingSystemsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const columns = [
  {
    title: "Nazwa wykonawcy",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    align: "center" as "center",
    width: "200px",
  },
  // {
  //   title: "Podgląd systemów",
  //   dataIndex: "preview",
  //   key: "preview",
  //   align: "center" as "center",
  //   width: "100px",
  // },
  {
    title: "Usuń",
    dataIndex: "delete",
    key: "delete",
    align: "center" as "center",
    width: "100px",
  },
];

type Props = {};

const ContractorsPage: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const [code, setCode] = useState<string>("");

  const contractor = useAppSelector((state) => state.contractor);
  const contractors = useAppSelector((state) => state.parentContractors);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get("/Contractor/allRelatedContractors");
        if (response.status === 200) {
          dispatch(setParentContractorsInReducer(response.data));
        }
      } catch (err) {}
    };

    fetchData();
  }, []);

  const handleCodeClick = async () => {
    try {
      const model = {
        code: code,
      };

      const response = await client.post("/Contractor/acceptInvitation", model);
      if (response.status === 200) {
        // W odpowiedzi dostajemy obiekt wykonawcy i musimy go dodać do listy
        // dispatch(addParentContractorInReducer(response.data));
        // dispatch(setPrincingSystemsInReducer(response.data.princingSystems));

        // Akceptacja to dodają się zewnętrzne systemy i tam trzeba to dodać
        dispatch(addParentContractorInReducer(response.data.contractor));
        dispatch(addExternalPrincingSystemsInReducer(response.data.externalPrincingSystems));
      }
    } catch (err) {}
  };

  const getContractorsData = (contractors: IContractor[]) => {
    const data: {
      key: string;
      name: string;
      email: string;
      // preview: JSX.Element;
      delete: JSX.Element;
    }[] = [];

    if (contractors) {
      for (const c of contractors) {
        data.push({
          key: c.id,
          name: `${contractor.id === c.id ? `Moje konto wykonawcy (${c.name})` : c.name}`,
          email: "",
          // preview: (
          //   <Button disabled onClick={() => null}>
          //     Podgląd systemów
          //   </Button>
          // ),
          delete: (
            <Button
              disabled={contractor.id === c.id}
              onClick={() => {
                const deleteContractor = async () => {
                  try {
                    const response = await client.delete("/Contractor/deleteRelation/" + c.id);
                    if (response.status === 204) {
                      dispatch(deleteContractorInReducer(c.id));
                      // dispatch(deletePrincingSystemsByContractorIdInReducer(c.id)); // nie powinno to być użyteczne
                      dispatch(deleteExternalPrincingSystemsByContractorIdInReducer(c.id));
                    }
                  } catch (err) {}
                };

                deleteContractor();
              }}
            >
              Usuń powiązanie
            </Button>
          ),
        });
      }
    }

    return data;
  };
  return (
    <Container>
      <PageHeader title={"Wykonawcy"} />

      <Section title={"Dodaj wykonawcę do listy zatwierdzonych profili"}>
        <Alert message="Aby dodać wykonawcę należy uzyskać kod zaproszeniowy!" type="info" showIcon />
        <div style={{ display: "flex", alignItems: "center", gap: "16px", marginTop: "16px" }}>
          <div>Wprowadź uzyskany kod wykonawcy</div>
          <Input value={code} onChange={(e) => setCode(e.target.value)} />
          <Button type="primary" onClick={() => handleCodeClick()}>
            Dodaj wykonawcę
          </Button>
        </div>
      </Section>

      <Section title={"Lista przypisanych wykonawców do tego sklepu"}>
        <Table
          dataSource={getContractorsData(contractors)}
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
        />
      </Section>
    </Container>
  );
};
export default ContractorsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
