import React, { FC, useEffect, useState } from "react";
import roofProcessFunctionsStatusSlice, {
  cancelRoofProcess,
} from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import RoofProcessMainModal from "../../newRoof/RoofProcessMainModal";

export type RoofProcessStep =
  | "selectDrawType" // wybór rysowania dachu - auto lub obrys połaci
  | "selectTemplateOption" // wybór z podkładem lub bez
  | "modifyTemplate" // w trakcie modyfikacji podkładu
  | "getUnit" // w trakcie rysowania i wprowadzania jednostki
  | "inputSlope" // w trakcie wprowadzania nachylenia
  | "drawRoof" // w trakcie rysowania dachu
  | "default"; //  oznacza, że nie ma żadnego procesu rysowania dachu

type Props = {
  step: number;
};

const RoofProcessManager: FC<Props> = ({ step }) => {
  const dispatch = useAppDispatch();

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);
  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);
  const templateImage = useAppSelector((state) => state.templateImage);

  const [currentStep, setCurrentStep] = useState<RoofProcessStep>("default");

  useEffect(() => {
    changeCurrentStep();
  }, [roofProcessStatus, roofProcessFunctionsStatus]);

  // Zmiania typu procesu rysowania dachu czyli to co widac na ekranie. Np. wybór rysowania dachu lub wybór podkładu
  const changeCurrentStep = () => {
    if (
      roofProcessStatus.type === "undefined" &&
      roofProcessFunctionsStatus.withTemplate === undefined &&
      templateImage.string64 === null
    ) {
      setCurrentStep("selectDrawType");
    } else if (roofProcessStatus.type !== "undefined" && roofProcessFunctionsStatus.withTemplate === undefined) {
      setCurrentStep("selectTemplateOption");
    } else if (
      roofProcessStatus.type !== "undefined" &&
      roofProcessFunctionsStatus.withTemplate === false &&
      roofProcessFunctionsStatus.confirmedTemplate === undefined
    ) {
      // Jeśłi ręczny obrys to
      // Jełsi automat to
      //   setCurrentStep("getUnit");
    } else if (
      roofProcessStatus.type !== "undefined" &&
      roofProcessFunctionsStatus.withTemplate === true &&
      templateImage.string64 === null
    ) {
      setCurrentStep("modifyTemplate");
    } else if (
      roofProcessStatus.type !== "undefined" &&
      roofProcessFunctionsStatus.withTemplate === true &&
      templateImage.string64 !== null
    ) {
      setCurrentStep("inputSlope");
    } else if (
      roofProcessStatus.type !== "undefined" &&
      roofProcessFunctionsStatus.withTemplate === false &&
      templateImage.string64 === null &&
      roofProcessFunctionsStatus.confirmedTemplate !== undefined
    ) {
      setCurrentStep("inputSlope");
    }
  };

  const cancelProcess = () => {
    setCurrentStep("default");
    dispatch(cancelRoofProcess());
  };

  return (
    <>
      {roofProcessFunctionsStatus.isActive && roofProcessFunctionsStatus.isVisible && (
        <RoofProcessMainModal
          roofProcessStatus={roofProcessStatus}
          roofProcessFunctionsStatus={roofProcessFunctionsStatus}
          currentStep={currentStep}
          onCancel={cancelProcess}
        />
      )}
    </>
  );
};
export default RoofProcessManager;
