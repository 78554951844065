import { Point } from "@react-three/drei";
import React, { FC, useEffect } from "react";
import { Arrow, Circle, Group, Line } from "react-konva";
import { IBasicPoint, IRoofPoint } from "../../../../../models/Models";
import { useAppSelector } from "../../../../../redux/hooks";

type Props = {
  scale: number;
};

const VectorSlopeComponent: FC<Props> = ({ scale }) => {
  const connectPlanes = useAppSelector((state) => state.connectPlanes); // Redux połączenia połaci

  const roofPlanes = useAppSelector((state) => state.roofPlanes); // Lista połaci
  const roofEdges = useAppSelector((state) => state.roofEdges); // Lista krawędzi
  const roofPoints = useAppSelector((state) => state.roofPoints); // Lista punktów

  const [angle, setAngle] = React.useState<number>(0);

  useEffect(() => {
    if (connectPlanes && connectPlanes.angle !== angle) {
      setAngle(connectPlanes.angle || 0);
    }
  }, [connectPlanes]);

  const findAveragePoint = (points: IBasicPoint[]): IBasicPoint => {
    const sum = points.reduce(
      (acc, point) => {
        acc.x += point.x;
        acc.y += point.y;
        return acc;
      },
      { x: 0, y: 0 }
    );

    return {
      x: sum.x / points.length,
      y: sum.y / points.length,
    };
  };

  const getCenterSelectedPlanes = () => {
    // console.log("START getCenterSelectedPlanes");
    const selectedPlanes = roofPlanes.filter((p) => connectPlanes.selectedPlaneIds.includes(p.id));

    const allPoints: IRoofPoint[] = [];
    for (const plane of selectedPlanes) {
      const planePoints = roofPoints.filter((p) => plane.pointIds?.includes(p.id));

      // Dodaj punkty do allPoints - ale tylko jeśli nie ma danego punktuu tam
      for (const point of planePoints) {
        if (!allPoints.find((p) => p.id === point.id)) {
          allPoints.push(point);
        }
      }
    }

    // console.log("AllPoints", allPoints);

    const finalPoint = findAveragePoint(allPoints.map((p) => ({ x: p.x, y: p.y })));

    // console.log("FinalPoint", finalPoint);

    return finalPoint;
  };

  const center = getCenterSelectedPlanes();

  if (connectPlanes.selectedPlaneIds.length === 0) return null;

  return (
    <Group>
      <Circle x={center.x} y={center.y} radius={7} fill="red" />
      <Arrow
        points={[0, 0, 100, 0]} // Zaczynamy od 0,0 i idziemy do 100,0
        x={center.x} // Ustawiamy x strzałki na center.x
        y={center.y} // Ustawiamy y strzałki na center.y
        rotation={angle - 90}
        stroke="black"
        strokeWidth={3}
      />
    </Group>
  );
};
export default VectorSlopeComponent;
