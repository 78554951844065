import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import React, { FC, useEffect } from "react";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import { useFirstRender } from "../../../hooks/useFirstRender";
import { IReportQuestion } from "../CompanyPage";

type Props = {
  reportQuestion: IReportQuestion;
  updatePosition: (pos: IReportQuestion) => void;
  deletePosition: (pos: IReportQuestion) => void;
};

const ReportQuestionPosition: FC<Props> = ({ reportQuestion, updatePosition, deletePosition }) => {
  const firstRender = useFirstRender();

  const [active, setActive] = React.useState<boolean>(reportQuestion.active);
  const [order, setOrder] = React.useState<number>(reportQuestion.order);
  const [content, setContent] = React.useState<string>(reportQuestion.content);

  useEffect(() => {
    if (!firstRender) {
      updatePosition({ id: reportQuestion.id, active, order, content });
    }
  }, [active, order, content]);

  return (
    <Row gutter={16} style={{ width: "100%", margin: "0px", padding: "0px" }}>
      <Col span={1}>
        <Form.Item label="Wł." labelCol={{ span: 24 }}>
          <Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Priorytet" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={order}
            onChange={(e) => setOrder(e as number)}
            min={0}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={20}>
        <Form.Item
          label="Treść pytania (dodaj równiez znak ? lub inny zgodnie z oczekiwaniami)."
          labelCol={{ span: 24 }}
        >
          <Input value={content} onChange={(e) => setContent(e.target.value)} style={{ width: "100%" }} />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              deletePosition(reportQuestion);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};
export default ReportQuestionPosition;
