import React, { FC, useState } from "react";
import { Button, Form, Input, InputNumber, Modal, Popconfirm } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { addCompany, deleteCompany, updateCompany } from "../../redux/general/companiesSlice";
import DomaInputNumber from "../../helpers/DomaInputNumber";

export default function CompanyModal({ company, addCompanyModalOpen, setAddCompanyModalOpen }) {
  const dispatch = useAppDispatch();

  const isNew = () => {
    return company ? false : true;
  };

  const isEdit = () => {
    return company ? true : false;
  };

  const deleteCompanyOnClick = () => {
    dispatch(deleteCompany(company));
    setAddCompanyModalOpen(false);
  };

  const getButtonsInFooter = () => {
    const buttons = [];

    if (isEdit()) {
      buttons.push(
        <Popconfirm
          title="Usuń producenta z bazy danych"
          description="Czy na pewno chcesz usunąć producenta z bazy danych? Wszystkie produkty tego producenta również zostaną usunięte."
          onConfirm={() => {
            deleteCompanyOnClick();
          }}
          okText="Tak"
          cancelText="Nie"
          placement="topRight"
        >
          <Button type="primary" key="delete" danger>
            Usuń
          </Button>
        </Popconfirm>
      );
    }

    buttons.push(
      <Button key="back" onClick={handleCancel}>
        Anuluj
      </Button>
    );

    if (isNew()) {
      buttons.push(
        <Button form="companyForm" key="submit" htmlType="submit" type="primary">
          Dodaj
        </Button>
      );
    } else {
      buttons.push(
        <Button form="companyForm" key="submit" htmlType="submit" type="primary">
          Zaktualizuj
        </Button>
      );
    }

    return buttons;
  };

  const onFinish = (values) => {
    if (isNew()) {
      // Add new company
      const newCompany = {
        name: values.name,
        description: values.description,
        palletFee: values.palletFee,
      };
      dispatch(addCompany(newCompany));
    } else {
      // Update company
      const companyToUpdate = {
        ...company,
        name: values.name,
        description: values.description,
        palletFee: values.palletFee,
      };
      dispatch(updateCompany(companyToUpdate));
    }
    setAddCompanyModalOpen(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  const handleCancel = () => {
    setAddCompanyModalOpen(false);
  };

  const getInitialValues = () => {
    if (isEdit()) {
      return { name: company.name, description: company.description, palletFee: company.palletFee };
    } else {
      return {
        name: "",
        description: "",
        palletFee: 0,
      };
    }
  };

  return (
    <Modal
      open={addCompanyModalOpen}
      centered
      width={"60%"}
      title={isNew() ? "Dodaj nowego producenta" : "Edytuj producenta"}
      onCancel={handleCancel}
      footer={getButtonsInFooter()}
    >
      <Form
        style={{ marginTop: "32px" }}
        id="companyForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={getInitialValues()}
      >
        <Form.Item
          label="Nazwa producenta"
          name="name"
          rules={[{ required: true, message: "Podaj nazwę producenta!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Opis" name="description">
          <Input />
        </Form.Item>

        <Form.Item label="Kaucja za paletę [zł]" name="palletFee">
          <DomaInputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
}
