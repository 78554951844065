import { Spin } from "antd";
import React from "react";
import styled, { keyframes } from "styled-components";

const DSpin: React.FC = () => {
  return <Spin indicator={<MyCustomSpinner />} />;
};

export default DSpin;

// Definicja animacji obrotu
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Stylowany komponent SVG z animacją
const StyledSpinner = styled.svg`
  animation: ${rotate} 2s linear infinite;
  width: 30px;
  height: 30px;
`;

const MyCustomSpinner: React.FC = () => (
  <StyledSpinner viewBox="0 0 367 367">
    <path
      d="m2076.46 746.934 19.2 34.95h-3.95l-17.4-31.65-17.35 31.65h-3.95l19.2-34.95h4.25Z"
      style={{
        fill: "#3f8bfa",
        fillRule: "nonzero",
      }}
      transform="translate(-17622.38 -6378.4) scale(8.58369)"
    />
    <circle
      cx={1612.67}
      cy={948.65}
      r={21.24}
      style={{
        fill: "#3f8bfa",
      }}
      transform="matrix(.95009 0 0 .95009 -1349.128 -680.705)"
    />
  </StyledSpinner>
);
