import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICurrencyValue {
  eur: number;
  tempEur: number;
}

const initialState: ICurrencyValue = {
  eur: 0,
  tempEur: 0,
};

// Miejsce przechowywania ogólnych danych w programie
export const currencyValueSlice = createSlice({
  name: "currencyValue",
  initialState,
  reducers: {
    setCurrencyValue: (state, action: PayloadAction<number>) => {
      return { ...state, eur: action.payload };
    },
    setTempCurrencyValue: (state, action: PayloadAction<number>) => {
      return { ...state, tempEur: action.payload };
    },
    clearCurrencyValue: (state) => {
      return initialState;
    },
    setSavedCurrencyValue: (state, action: PayloadAction<any>) => {
      const payload = action.payload; // currencyValue
      const currencyValue = { ...payload, tempEur: payload.eur };

      return currencyValue;
    },
  },
});

export const { clearCurrencyValue, setCurrencyValue, setTempCurrencyValue, setSavedCurrencyValue } =
  currencyValueSlice.actions;
export default currencyValueSlice.reducer;
