import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { IProduct, IProductCategory, IRootState, ISelectOption, ISheetMetalProcess } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import {
  addProductsGroup,
  IAddProductsGroup,
  IProductsGroup,
  IProductsGroupAddition,
  updateProductsGroup,
} from "../../../redux/productsGroups/productsGroupsSlice";
import {
  CATEGORIES_ALL_ROOF,
  CATEGORIES_TECHNICAL_EQUIPMENTS,
  EDGES_RELATIONS,
  TILE_SYSTEM_RELATIONS,
} from "../RoofConstants";
import SheetMetalProcessPosition from "../roofSystems/components/SheetMetalProcessPosition";
import {
  addTechnicalEquipmentsGroup,
  IAddTechnicalEquipmentGroup,
  ITechnicalEquipmentGroup,
  updateTechnicalEquipmentGroup,
} from "../../../redux/technicalEquipmentsGroups/technicalEquipmentsGroupsSlice";
import { createFilterOption } from "../../../helpers/Helpers";
import DomaInputNumber from "../../../helpers/DomaInputNumber";

type Props = {
  system: ITechnicalEquipmentGroup | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const TechnicalEquipmentsGroupsDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);
  const statusApi = useAppSelector((state: IRootState) =>
    state.statusApi.find((x: any) => x.key === "addPreCoveringSystem")
  );

  const [productsSelectForBasic, setProductsSelectForBasic] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForBasic.length === 0) {
        setProductsSelectForBasic(
          getProductsByCategoryForSelect(products, productCategories, [
            "roof-walkways-and-snow-guards",
            "roofing-accessories",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addTechnicalEquipmentGroup"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addTechnicalEquipmentGroup"));
    }
  }, [statusApi]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  const getInitialValues = () => {
    return isNew()
      ? {
          name: "",
          description: "",
          roofStepCount: 1,
          chimneySweepBench40Count: 2,
          chimneySweepBench80Count: 2,
          chimneySweepBench200Count: 3,
          chimneySweepBench300Count: 4,
          snowFence200Count: 3,
          snowFence300Count: 4,
        }
      : {
          name: system?.name,
          description: system?.description,
          roofSupportProductId: system?.roofSupportProductId,
          roofSupportSnowFenceProductId: system?.roofSupportSnowFenceProductId,
          chimneyBenchGripProductId: system?.chimneyBenchGripProductId,
          roofStepProductId: system?.roofStepProductId,
          roofStepCount: system?.roofStepCount,
          chimneySweepBench40ProductId: system?.chimneySweepBench40ProductId,
          chimneySweepBench40Count: system?.chimneySweepBench40Count,
          chimneySweepBench80ProductId: system?.chimneySweepBench80ProductId,
          chimneySweepBench80Count: system?.chimneySweepBench80Count,
          chimneySweepBench200ProductId: system?.chimneySweepBench200ProductId,
          chimneySweepBench200Count: system?.chimneySweepBench200Count,
          chimneySweepBench300ProductId: system?.chimneySweepBench300ProductId,
          chimneySweepBench300Count: system?.chimneySweepBench300Count,
          snowFence200ProductId: system?.snowFence200ProductId,
          snowFence200Count: system?.snowFence200Count,
          snowFence300ProductId: system?.snowFence300ProductId,
          snowFence300Count: system?.snowFence300Count,
        };
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "Grupa wyposażenia technicznego została dodana!",
    });

    navigate(-1);
  };

  const onFinish = (values: any, newVersion: boolean) => {
    if (isNew() || newVersion) {
      let s: IAddTechnicalEquipmentGroup = {
        name: values.name,
        description: values.description,
        roofSupportProductId: values?.roofSupportProductId,
        roofSupportSnowFenceProductId: values?.roofSupportSnowFenceProductId,
        chimneyBenchGripProductId: values?.chimneyBenchGripProductId,
        roofStepProductId: values?.roofStepProductId,
        roofStepCount: values?.roofStepCount,
        chimneySweepBench40ProductId: values?.chimneySweepBench40ProductId,
        chimneySweepBench40Count: values?.chimneySweepBench40Count,
        chimneySweepBench80ProductId: values?.chimneySweepBench80ProductId,
        chimneySweepBench80Count: values?.chimneySweepBench80Count,
        chimneySweepBench200ProductId: values?.chimneySweepBench200ProductId,
        chimneySweepBench200Count: values?.chimneySweepBench200Count,
        chimneySweepBench300ProductId: values?.chimneySweepBench300ProductId,
        chimneySweepBench300Count: values?.chimneySweepBench300Count,
        snowFence200ProductId: values?.snowFence200ProductId,
        snowFence200Count: values?.snowFence200Count,
        snowFence300ProductId: values?.snowFence300ProductId,
        snowFence300Count: values?.snowFence300Count,
      };

      dispatch(addTechnicalEquipmentsGroup(s, successCallback));
    } else {
      let s: ITechnicalEquipmentGroup = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        roofSupportProductId: values?.roofSupportProductId,
        roofSupportSnowFenceProductId: values?.roofSupportSnowFenceProductId,
        chimneyBenchGripProductId: values?.chimneyBenchGripProductId,
        roofStepProductId: values?.roofStepProductId,
        roofStepCount: values?.roofStepCount,
        chimneySweepBench40ProductId: values?.chimneySweepBench40ProductId,
        chimneySweepBench40Count: values?.chimneySweepBench40Count,
        chimneySweepBench80ProductId: values?.chimneySweepBench80ProductId,
        chimneySweepBench80Count: values?.chimneySweepBench80Count,
        chimneySweepBench200ProductId: values?.chimneySweepBench200ProductId,
        chimneySweepBench200Count: values?.chimneySweepBench200Count,
        chimneySweepBench300ProductId: values?.chimneySweepBench300ProductId,
        chimneySweepBench300Count: values?.chimneySweepBench300Count,
        snowFence200ProductId: values?.snowFence200ProductId,
        snowFence200Count: values?.snowFence200Count,
        snowFence300ProductId: values?.snowFence300ProductId,
        snowFence300Count: values?.snowFence300Count,
      };

      dispatch(updateTechnicalEquipmentGroup(s));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  interface AccessoriesProductPositionProps {
    productLabel: string;
    productName: string;
    supportsCountName: string;
    supportsCountLabel: string;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    selectedCategories: string[];
    supportsDisabled: boolean;
    readyOptions?: any[];
  }

  const AccessoriesProductPosition: React.FC<AccessoriesProductPositionProps> = ({
    productLabel,
    productName,
    supportsCountName,
    supportsCountLabel,
    products,
    productCategories,
    selectedCategories,
    supportsDisabled,
    readyOptions,
  }) => {
    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          {!supportsDisabled && (
            <Form.Item label={supportsCountLabel} name={supportsCountName} labelCol={{ span: 24 }}>
              <DomaInputNumber allowNegative min={-100} max={200} style={{ width: "100%" }} />
            </Form.Item>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <Container>
      {contextHolder}
      <Form
        id="technicalEquipmentsGroupDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Section title={"Ogólne dane grupy wyposażenia technicznego"}>
          <Form.Item
            label="Nazwa grupy wyposażenia technicznego"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę grupy wyposażenia technicznego!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis grupy" name="description" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
        </Section>

        <Section
          title={"Produkty wyposażenia technicznego dachu (komunikacja, zabezpieczenia przeciwśniegowe)"}
          style={{ marginTop: "16px" }}
        >
          {/* Wspornik dachowy (dodawany jako dodatek do stopni dachowych i ław kominiarskich) */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Wspornik dachowy (dodawany jako dodatek do stopni dachowych i ław kominiarskich)"
            productName="roofSupportProductId"
            supportsCountName="roofSupportCount"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Mocownik - kołyska (dodawany jako dodatek do stopni dachowych i ław kominiarskich)"
            productName="chimneyBenchGripProductId"
            supportsCountName="chimneyBenchGripCount"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          {/* Stopień dachowy */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Stopień dachowy"
            productName="roofStepProductId"
            supportsCountName="roofStepCount"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników [szt.]"
          />

          {/* Ława kominiarska 40/60cm */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Ława kominiarska (40/60)cm"
            productName="chimneySweepBench40ProductId"
            supportsCountName="chimneySweepBench40Count"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników i kołysek [szt.]"
          />

          {/* Ława kominiarska 80/100cm */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Ława kominiarska (80/100)cm"
            productName="chimneySweepBench80ProductId"
            supportsCountName="chimneySweepBench80Count"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników i kołysek [szt.]"
          />

          {/* Ława kominiarska 200cm */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Ława kominiarska 200cm"
            productName="chimneySweepBench200ProductId"
            supportsCountName="chimneySweepBench200Count"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników i kołysek [szt.]"
          />

          {/* Ława kominiarska 300cm */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Ława kominiarska 300cm"
            productName="chimneySweepBench300ProductId"
            supportsCountName="chimneySweepBench300Count"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników i kołysek [szt.]"
          />

          <Divider />

          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Wspornik dachowy (dodawany jako dodatek do płotków śniegowych)"
            productName="roofSupportSnowFenceProductId"
            supportsCountName="roofSupportSnowFenceCount"
            supportsDisabled={true}
            supportsCountLabel=""
          />

          {/* Płotek śniegowy 200cm */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Płotek śniegowy 200cm"
            productName="snowFence200ProductId"
            supportsCountName="snowFence200Count"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników [szt.]"
          />

          {/* Płotek śniegowy 300cm */}
          <AccessoriesProductPosition
            selectedCategories={CATEGORIES_TECHNICAL_EQUIPMENTS}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Płotek śniegowy 300cm"
            productName="snowFence300ProductId"
            supportsCountName="snowFence300Count"
            supportsDisabled={false}
            supportsCountLabel="Ilość wsporników [szt.]"
          />
        </Section>
      </Form>
    </Container>
  );
};

export default TechnicalEquipmentsGroupsDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
