import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { IProduct, ISheetMetalProcess } from "../../models/Models";
import { AppThunk } from "../store";

export interface IProductsGroup {
  id: string;
  name: string;
  description: string;
  productsGroupAdditions: IProductsGroupAddition[];
  sheetMetalProcesses: ISheetMetalProcess[];
}

export interface IProductsGroupAddition {
  id: string;
  productId?: string | null;
  use: number;
  correction: number;
  relation: string;
  productsGroupId: string;
}

// Omit definuje to samo i wskazuej sie co ma być pominięte
export type IAddProductsGroup = Omit<IProductsGroup, "id">;

const initialState: IProductsGroup[] = [];

export const productsGroupsSlice = createSlice({
  name: "productsGroups",
  initialState,
  reducers: {
    addProductsGroupsInReducer: (state, action: PayloadAction<IProductsGroup[]>) => {
      let productsGroups = action.payload;
      return productsGroups;
    },
    addproductsGroupInReducer: (state, action: PayloadAction<IProductsGroup>) => {
      let productsGroup = action.payload;
      return [...state, productsGroup];
    },
    updateProductsGroupInReducer: (state, action: PayloadAction<IProductsGroup>) => {
      let update = action.payload;
      console.log("UPDATE", update);
      let index = state.findIndex((x) => x.id === update.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...update };
      }
    },
    deleteProductsGroupInReducer: (state, action: PayloadAction<string>) => {
      const productsGroupId = action.payload;
      return state.filter((o) => o.id !== productsGroupId);
    },
  },
});

export const {
  addProductsGroupsInReducer,
  addproductsGroupInReducer,
  updateProductsGroupInReducer,
  deleteProductsGroupInReducer,
} = productsGroupsSlice.actions;
export default productsGroupsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add products group
export const addProductsGroup =
  (data: IAddProductsGroup, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("ADD PRODUCTS GROUP", data);
    client
      .put("/ProductsGroups", data)
      .then((response) => {
        console.log("ADD PRODUCTS GROUP RESPONSE", response);
        if (response.status == 201) {
          dispatch(addproductsGroupInReducer(response.data));
          callback();
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

// Update products group
export const updateProductsGroup =
  (data: IProductsGroup): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE PRODUCTS GROUP", data);
    client
      .post("/ProductsGroups", data)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATE PRODUCTS GROUP RESPONSE", response);
          dispatch(updateProductsGroupInReducer(response.data));

          message.open({
            type: "success",
            content: "Grupa produktowa została zaktualizowana!",
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

// Delete products group
export const deleteProductsGroup =
  (productsGroup: IProductsGroup, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/ProductsGroups/${productsGroup.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteProductsGroupInReducer(productsGroup.id));

          callback();

          message.open({
            type: "success",
            content: "Grupa została usunięta!",
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };
