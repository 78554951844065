import store, { AppThunk } from "../store";
import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IProductToReport, IRoofEdge, IRoofHole, IRoofPlane, IRoofPoint } from "../../models/Models";
import { IInformationReport } from "../../components/OfferDataModal";
import {
  addReportInReducer,
  updateDataReportInReducer,
  updateFullReportInReducer,
  updateStatusReportInReducer,
  updateTypeReportInReducer,
} from "../general/reportsSlice";
import { getStateToSave } from "../reduxHelper";
import { setReportIdGeneral } from "../general/generalSlice";
import {
  getGrossValueSum,
  getGrossValueSumWithAllDiscount,
  getNettValueSum,
  getNettValueSumWithAllDiscount,
  getProductsWithDiscount,
} from "../../modules/roofs/managers/DiscountManager";
import { getFullArea, getRoofSizes } from "../../helpers/Helpers";
import {
  IRSO,
  IRSOEdge,
  IRSOHole,
  IRSOPoint,
} from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";
import { setCurrencyValue } from "../general/currencyValueSlice";
import { IAdditionalReportPage, IReportNote, IReportQuestion } from "../../modules/admin/CompanyPage";
import { INotePosition, IQuestionPosition } from "../../components/OfferInformations";

export interface ISummaryElement {
  name: string;
  edgeType: number | null;
  amount: number;
  unit: string;
}

export interface IRoofData {
  roofPoints: IRoofPoint[] | IRSOPoint[];
  roofPlanes: IRoofPlane[] | IRSO[];
  roofEdges: IRoofEdge[] | IRSOEdge[];
  roofHoles: IRoofHole[] | IRSOHole[];
  scale: number;
  roofSizes: any;
  // tu pewnie będzie więcej danych
}

export interface IReportRequestData {
  projectName: string;
  investor: string;
  phone: string;
  email: string;
  zipCode: string;
  street: string;
  houseNumber: string;
  city: string;
  validDays: number;

  type: string;
  status: string;

  summaryElements: ISummaryElement[];

  vat: number;

  roofSystemId: string | null;
  gutterSystemId: string | null;
  preCoveringSystemId: string | null;
  fasteningSystemId: string | null;

  totalNettCatalogPrice: number;
  totalVatCatalogPrice: number;
  totalCatalogPrice: number;
  totalNettPrice: number;
  totalVatPrice: number;
  totalPrice: number;

  products: IProductToReport[]; // Wszystkie dane materiałowe czyli produkty dachu

  investmentId: string | null;
  data: string | null;
  roofData: string | null; // Wszystkie dane geometryczne dachu jako JSON

  templateImageFileName: string;

  reportNotes: string[];
  reportQuestions: string[];
  additionalReportPages: string[];
}

export interface IUpdateReportRequestData {
  id: string;
  projectName: string;
  investor: string;
  phone: string;
  email: string;
  zipCode: string;
  street: string;
  houseNumber: string;
  city: string;
  validDays: number;

  type: string;
  status: string;

  summaryElements: ISummaryElement[];

  vat: number;

  roofSystemId: string | null;
  gutterSystemId: string | null;
  preCoveringSystemId: string | null;
  fasteningSystemId: string | null;

  totalNettCatalogPrice: number;
  totalVatCatalogPrice: number;
  totalCatalogPrice: number;
  totalNettPrice: number;
  totalVatPrice: number;
  totalPrice: number;

  products: IProductToReport[]; // Wszystkie dane materiałowe czyli produkty dachu

  data: string | null;
  roofData: string | null; // Wszystkie dane geometryczne dachu jako JSON

  reportNotes: string[];
  reportQuestions: string[];
  additionalReportPages: string[];
}

export interface IReportResponseData {
  id: string;
  urlCode: string;
}

export interface IReport {
  generated: boolean; // czy wygenrowany
  shouldRegenerated: boolean; // czy zostały wprowadzone zmiany i trzeba ponownie wygenerować raport
  requestData: IReportRequestData;
  responseData: IReportResponseData | null;

  reportNotes: INotePosition[];
  reportQuestions: IQuestionPosition[];
  additionalReportPages: IAdditionalReportPage[];
}

const initialState: IReport = {
  generated: false,
  shouldRegenerated: false,
  requestData: {
    projectName: "",
    investor: "",
    phone: "",
    email: "",
    zipCode: "",
    street: "",
    houseNumber: "",
    city: "",
    validDays: 14,
    vat: 23,
    totalCatalogPrice: 0,
    totalNettCatalogPrice: 0,
    totalVatCatalogPrice: 0,
    totalNettPrice: 0,
    totalPrice: 0,
    totalVatPrice: 0,
    roofSystemId: "",
    gutterSystemId: null,
    fasteningSystemId: null,
    preCoveringSystemId: null,
    products: [],
    investmentId: null,
    roofData: null,
    data: null,
    type: "basic",
    status: "created",
    templateImageFileName: "",
    reportNotes: [],
    reportQuestions: [],
    additionalReportPages: [],
    summaryElements: [],
  },
  responseData: null,

  reportNotes: [],
  reportQuestions: [],
  additionalReportPages: [],
};

// Kontener na dane materiałowe potrzebne do oblczeń
export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    addReportUrlInReducer: (state, action: PayloadAction<string>) => {
      let reportUrl = action.payload;
      return { ...state, urlCode: reportUrl };
    },
    updateReportRequestData: (state, action: PayloadAction<IReportRequestData>) => {
      const requestData = action.payload;
      return { ...state, requestData: requestData };
    },
    updateReportNotesInReducer: (state, action: PayloadAction<INotePosition[]>) => {
      const notes = action.payload;
      return { ...state, reportNotes: notes };
    },
    updateReportQuestionsInReducer: (state, action: PayloadAction<IQuestionPosition[]>) => {
      const questions = action.payload;
      return { ...state, reportQuestions: questions };
    },
    updateAdditionalReportPagesInReducer: (state, action: PayloadAction<IAdditionalReportPage[]>) => {
      const pages = action.payload;
      return { ...state, additionalReportPages: pages };
    },
    setShouldRegenerateReport: (state, action: PayloadAction<boolean>) => {
      const value = action.payload;
      return { ...state, shouldRegenerated: value };
    },
    updateReportInformationsRequestData: (state, action: PayloadAction<IInformationReport>) => {
      const data = action.payload;
      let requestData: IReportRequestData = { ...state.requestData };
      requestData = {
        ...requestData,
        projectName: data.projectName,
        investor: data.investor,
        phone: data.phone,
        email: data.email,
        zipCode: data.zipCode,
        street: data.street,
        houseNumber: data.houseNumber,
        city: data.city,
        validDays: data.validDays,
      };
      return { ...state, requestData: requestData };
    },
    setReportResponseData: (state, action: PayloadAction<IReportResponseData>) => {
      const responseData = action.payload;
      return { ...state, responseData: responseData, generated: true };
    },
    clearReportInReducer: (state) => {
      return initialState;
    },
    setSavedRoofReport: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addReportUrlInReducer,
  updateReportRequestData,
  setShouldRegenerateReport,
  updateReportInformationsRequestData,
  setReportResponseData,
  clearReportInReducer,
  setSavedRoofReport,
  updateAdditionalReportPagesInReducer,
  updateReportNotesInReducer,
  updateReportQuestionsInReducer,
} = reportSlice.actions;
export default reportSlice.reducer;

// API ********************************************************************************

export const clearReport = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  dispatch(clearReportInReducer());
};

// Generuj raport
export const generateReport = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const roofSystemId = getState().calcMaterialsData.roofSystemId;
  const gutterSystemId = getState().calcMaterialsData.gutterSystemId;
  const preCoveringSystemId = getState().calcMaterialsData.preCoveringSystemId;
  const fasteningSystemId = getState().calcMaterialsData.fasteningSystemId;

  const calcResultData = getState().calcResult.data; // Wszystkie produkty do raportu
  const currencyValueState = getState().currencyValue;
  const vat = getState().calcResult.vat;
  const activeInvestmentId = getState().general.investmentId;
  let requestModel = getState().report.requestData;
  if (calcResultData) {
    const products = getProductsWithDiscount(calcResultData);
    // console.log("calcResultData", calcResultData);
    // console.log("PRODUCTS", products);
    requestModel = { ...requestModel, products: products };
    console.table(products);
  }

  const isAutomatic: any = getState().roofProcessStatus.type === "outline";

  // CENY
  requestModel = { ...requestModel, vat: vat };
  requestModel = { ...requestModel, totalNettCatalogPrice: Number(getNettValueSum().toFixed(2)) };
  requestModel = {
    ...requestModel,
    totalVatCatalogPrice: Number((getGrossValueSum() - getNettValueSum()).toFixed(2)),
  };
  requestModel = { ...requestModel, totalCatalogPrice: Number(getGrossValueSum().toFixed(2)) };

  requestModel = { ...requestModel, totalNettPrice: Number(getNettValueSumWithAllDiscount().toFixed(2)) };
  requestModel = {
    ...requestModel,
    totalVatPrice: Number((getGrossValueSumWithAllDiscount() - getNettValueSumWithAllDiscount()).toFixed(2)),
  };
  requestModel = { ...requestModel, totalPrice: Number(getGrossValueSumWithAllDiscount().toFixed(2)) };

  requestModel = { ...requestModel, roofSystemId: roofSystemId };
  requestModel = { ...requestModel, gutterSystemId: gutterSystemId };
  requestModel = { ...requestModel, preCoveringSystemId: preCoveringSystemId };
  requestModel = { ...requestModel, fasteningSystemId: fasteningSystemId };

  let roofData: IRoofData | null = null;

  let roofSizes = getRoofSizes(
    getState().roofPoints,
    getState().roofPlanes,
    getState().roofEdges,
    getState().drawingScale
  );

  // Dodanie roofData
  if (isAutomatic) {
    roofData = {
      roofPoints: getState().roofPoints,
      roofPlanes: getState().roofPlanes,
      roofEdges: getState().roofEdges,
      roofHoles: getState().roofHoles,
      scale: getState().drawingScale,
      roofSizes: roofSizes,
    };
  } else {
    roofSizes = getRoofSizes(
      getState().rsoPoints,
      getState().rsoPlanes,
      getState().rsoEdges,
      getState().drawingScale
    );

    roofData = {
      roofPoints: getState().rsoPoints,
      roofPlanes: getState().rsoPlanes,
      roofEdges: getState().rsoEdges,
      roofHoles: getState().rsoHoles,
      scale: getState().drawingScale,
      roofSizes: roofSizes,
    };
  }

  // Ogólne dane geometryczne - pole dachu i długości krawędzi
  const summaryElements: ISummaryElement[] = roofSizes.summaryData;

  requestModel = { ...requestModel, summaryElements: summaryElements };

  const reportType = getState().parentSettings.reportType || "basic";

  requestModel = { ...requestModel, type: reportType };
  requestModel = { ...requestModel, status: "created" };

  requestModel = { ...requestModel, roofData: JSON.stringify(roofData) };
  requestModel = { ...requestModel, investmentId: activeInvestmentId };
  requestModel = { ...requestModel, data: "" };

  // Jeśli jest zdjęcie szablonu to dodaj do raportu
  const templateImageFileName = getState().templateImage.templateImageFileName;

  requestModel = { ...requestModel, templateImageFileName: templateImageFileName ? templateImageFileName : "" };

  // Ogólne uwagi
  const reportNotesState = getState().report.reportNotes;
  const reportNotes: string[] = reportNotesState?.filter((x) => x.active).map((x) => x.content) || [];

  // Pytania
  const reportQuestionsState = getState().report.reportQuestions;
  const reportQuestions: string[] = reportQuestionsState?.filter((x) => x.active).map((x) => x.answer) || [];

  // Dodatkowe strony
  const additionalReportPages = getState().report.additionalReportPages;
  const additionalReportPagesData: string[] =
    additionalReportPages?.filter((x) => x.active).map((x) => x.id) || [];

  requestModel = {
    ...requestModel,
    reportNotes: reportNotes,
    reportQuestions: reportQuestions,
    additionalReportPages: additionalReportPagesData,
  };

  console.log("CREATE REPORT MODEL", requestModel);
  client
    .post("/AppReports/report", requestModel)
    .then((response) => {
      if (response.status == 200) {
        console.log("REPORT RESPONSE", response.data);
        const res: IReportResponseData = {
          id: response.data.id,
          urlCode: response.data.urlCode,
        };
        dispatch(setReportResponseData(res));
        dispatch(setShouldRegenerateReport(false));
        dispatch(addReportInReducer(response.data)); // dodanie raportu na listę
        dispatch(setReportIdGeneral(response.data.id));

        dispatch(setCurrencyValue(currencyValueState.tempEur));

        const appState = getStateToSave(store.getState()); // stan reduxa do zapisania
        const model = { id: response.data.id, data: JSON.stringify(appState) };
        client
          .put("/AppReports/updateDataReport", model)
          .then((response) => {
            if (response.status == 204) {
              dispatch(updateDataReportInReducer({ id: model.id, data: model.data }));
            } else {
              throw new Error(response.statusText);
            }
          })
          .catch((error) => {});
      } else {
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
      // console.error(error);
    });
};

// Aktualizacja raportu
export const updateReport =
  (reportId: string): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    // Jeśłi aktualizacja czyli taki raport istnieje w systemie
    // Pobierz istniejący raport
    const report = getState().reports.find((x) => x.id === reportId);

    let reportType = "basic";
    let reportStatus = "created";
    if (report) {
      reportType = report.type;
      reportStatus = report.status;
    }

    const roofSystemId = getState().calcMaterialsData.roofSystemId;
    const gutterSystemId = getState().calcMaterialsData.gutterSystemId;
    const preCoveringSystemId = getState().calcMaterialsData.preCoveringSystemId;
    const fasteningSystemId = getState().calcMaterialsData.fasteningSystemId;

    const calcResultData = getState().calcResult.data; // Wszystkie produkty do raportu
    const vat = getState().calcResult.vat;
    const currencyValueState = getState().currencyValue;
    // const activeInvestmentId = getState().general.investmentId;

    const isAutomatic = getState().roofProcessStatus.type === "outline";

    const requestData = getState().report.requestData;

    dispatch(setCurrencyValue(currencyValueState.tempEur));

    const appState = getStateToSave(getState()); // stan reduxa do zapisania

    let roofData: IRoofData | null = null;

    let roofSizes = getRoofSizes(
      getState().roofPoints,
      getState().roofPlanes,
      getState().roofEdges,
      getState().drawingScale
    );

    // Dodanie roofData
    if (isAutomatic) {
      roofData = {
        roofPoints: getState().roofPoints,
        roofPlanes: getState().roofPlanes,
        roofEdges: getState().roofEdges,
        roofHoles: getState().roofHoles,
        scale: getState().drawingScale,
        roofSizes: roofSizes,
      };
    } else {
      roofSizes = getRoofSizes(
        getState().rsoPoints,
        getState().rsoPlanes,
        getState().rsoEdges,
        getState().drawingScale
      );

      roofData = {
        roofPoints: getState().rsoPoints,
        roofPlanes: getState().rsoPlanes,
        roofEdges: getState().rsoEdges,
        roofHoles: getState().rsoHoles,
        scale: getState().drawingScale,
        roofSizes: roofSizes,
      };
    }

    // Ogólne dane geometryczne - pole dachu i długości krawędzi
    const summaryElements: ISummaryElement[] = roofSizes.summaryData;

    let requestModel: IUpdateReportRequestData = {
      id: reportId,
      projectName: requestData.projectName,
      investor: requestData.investor,
      phone: requestData.phone,
      email: requestData.email,
      zipCode: requestData.zipCode,
      street: requestData.street,
      houseNumber: requestData.houseNumber,
      city: requestData.city,
      validDays: requestData.validDays,
      roofSystemId: roofSystemId,
      gutterSystemId: gutterSystemId,
      preCoveringSystemId: preCoveringSystemId,
      fasteningSystemId: fasteningSystemId,
      vat: vat,
      totalNettCatalogPrice: Number(getNettValueSum().toFixed(2)),
      totalVatCatalogPrice: Number((getGrossValueSum() - getNettValueSum()).toFixed(2)),
      totalCatalogPrice: Number(getGrossValueSum().toFixed(2)),
      totalNettPrice: Number(getNettValueSumWithAllDiscount().toFixed(2)),
      totalVatPrice: Number((getGrossValueSumWithAllDiscount() - getNettValueSumWithAllDiscount()).toFixed(2)),
      totalPrice: Number(getGrossValueSumWithAllDiscount().toFixed(2)),
      products: calcResultData ? getProductsWithDiscount(calcResultData) : [],
      data: JSON.stringify(appState),
      roofData: JSON.stringify(roofData),
      type: reportType,
      status: reportStatus,
      summaryElements: summaryElements,
      reportNotes: [],
      reportQuestions: [],
      additionalReportPages: [],
    };

    // Ogólne uwagi
    const reportNotesState = getState().report.reportNotes;
    const reportNotes: string[] = reportNotesState?.filter((x) => x.active).map((x) => x.content) || [];

    // Pytania
    const reportQuestionsState = getState().report.reportQuestions;
    const reportQuestions: string[] = reportQuestionsState?.filter((x) => x.active).map((x) => x.answer) || [];

    // Dodatkowe strony
    const additionalReportPages = getState().report.additionalReportPages;
    const additionalReportPagesData: string[] =
      additionalReportPages?.filter((x) => x.active).map((x) => x.id) || [];

    requestModel = {
      ...requestModel,
      reportNotes: reportNotes,
      reportQuestions: reportQuestions,
      additionalReportPages: additionalReportPagesData,
    };

    console.log("UPDATE REPORT MODEL", requestModel);

    client
      .put("/AppReports/update", requestModel)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATED REPORT RESPONSE", response.data);

          dispatch(updateFullReportInReducer(response.data));
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
        // console.error(error);
      });
  };

// Aktualizacja statusu raportu
export const updateStatusReport =
  (reportId: string, status: string): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const model = {
      id: reportId,
      status: status,
    };

    client
      .put("/AppReports/updateStatus", model)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATED STATUS REPORT RESPONSE", response.data);

          dispatch(updateStatusReportInReducer({ id: model.id, status: model.status }));
        }
      })
      .catch((error) => {});
  };

// Aktualizacja typu raportu
export const updateTypeReport =
  (reportId: string, type: string): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const model = {
      id: reportId,
      type: type,
    };

    client
      .put("/AppReports/updateType", model)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATED TYPE REPORT RESPONSE", response.data);

          dispatch(updateTypeReportInReducer({ id: model.id, type: model.type }));
        }
      })
      .catch((error) => {});
  };
