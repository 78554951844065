import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { AppThunk } from "../store";

export interface ITechnicalEquipmentGroup {
  id: string;
  name: string;
  description: string;

  roofSupportProductId?: string;
  roofSupportSnowFenceProductId?: string;
  chimneyBenchGripProductId?: string;
  roofStepProductId?: string;
  roofStepCount: number;
  chimneySweepBench40ProductId?: string;
  chimneySweepBench40Count: number;
  chimneySweepBench80ProductId?: string;
  chimneySweepBench80Count: number;
  chimneySweepBench200ProductId?: string;
  chimneySweepBench200Count: number;
  chimneySweepBench300ProductId?: string;
  chimneySweepBench300Count: number;
  snowFence200ProductId?: string;
  snowFence200Count: number;
  snowFence300ProductId?: string;
  snowFence300Count: number;
}

// Omit definuje to samo i wskazuej sie co ma być pominięte
export type IAddTechnicalEquipmentGroup = Omit<ITechnicalEquipmentGroup, "id">;

const initialState: ITechnicalEquipmentGroup[] = [];

export const technicalEquipmentsGroupsSlice = createSlice({
  name: "technicalEquipmentsGroups",
  initialState,
  reducers: {
    addTechnicalEquipmentsGroupsInReducer: (state, action: PayloadAction<ITechnicalEquipmentGroup[]>) => {
      let technicalEquipmentsGroups = action.payload;
      return technicalEquipmentsGroups;
    },
    addTechnicalEquipmentGroupInReducer: (state, action: PayloadAction<ITechnicalEquipmentGroup>) => {
      let technicalEquipmentGroup = action.payload;
      return [...state, technicalEquipmentGroup];
    },
    updateTechnicalEquipmentGroupInReducer: (state, action: PayloadAction<ITechnicalEquipmentGroup>) => {
      let update = action.payload;
      console.log("UPDATE", update);
      let index = state.findIndex((x) => x.id === update.id);
      if (index !== -1) {
        state[index] = { ...state[index], ...update };
      }
    },
    deleteTechnicalEquipmentGroupInReducer: (state, action: PayloadAction<string>) => {
      const technicalEquipmentGroupId = action.payload;
      return state.filter((o) => o.id !== technicalEquipmentGroupId);
    },
  },
});

export const {
  addTechnicalEquipmentsGroupsInReducer,
  addTechnicalEquipmentGroupInReducer,
  updateTechnicalEquipmentGroupInReducer,
  deleteTechnicalEquipmentGroupInReducer,
} = technicalEquipmentsGroupsSlice.actions;
export default technicalEquipmentsGroupsSlice.reducer;

// *********************************************************
// API
// *********************************************************

export const addTechnicalEquipmentsGroup =
  (data: IAddTechnicalEquipmentGroup, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .put("/TechnicalEquipmentsGroups", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addTechnicalEquipmentGroupInReducer(response.data));
          callback();
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

// Update technical equipments group
export const updateTechnicalEquipmentGroup =
  (data: ITechnicalEquipmentGroup): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .post("/TechnicalEquipmentsGroups", data)
      .then((response) => {
        if (response.status == 200) {
          dispatch(updateTechnicalEquipmentGroupInReducer(response.data));

          message.open({
            type: "success",
            content: "Grupa techniczna została zaktualizowana!",
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

// Delete technical equipments group
export const deleteTechnicalEquipmentGroup =
  (technicalEquipmentGroup: ITechnicalEquipmentGroup, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/TechnicalEquipmentsGroups/${technicalEquipmentGroup.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteTechnicalEquipmentGroupInReducer(technicalEquipmentGroup.id));

          callback();

          message.open({
            type: "success",
            content: "Grupa techniczna została usunięta!",
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };
