import { withWarn } from "antd/es/modal/confirm";
import React, { FC, useEffect, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { rotate } from "../../../../../helpers/Calculator";
import { EdgeType } from "../../../../../models/Enums";
import { IProduct, IRoofEdge, IRoofPlane } from "../../../../../models/Models";
import { useAppSelector } from "../../../../../redux/hooks";

type Props = {
  scale: number; // skala wymiaru
  zoomScale: number;
  plane: IRoofPlane | null;
};

const BayInDraw: FC<Props> = ({ scale, zoomScale, plane }) => {
  const mp = useAppSelector((state) => state.mousePosition);
  const edges = useAppSelector((state) => state.roofEdges);
  const points = useAppSelector((state) => state.roofPoints);

  const [eaveEdge, setEaveEdge] = useState<IRoofEdge | null>(null);

  useEffect(() => {
    // Szukamy krawędzi okapu
    if (plane && edges) {
      for (const id of plane.edgeIds as []) {
        const e = edges.find((x) => x.id === id);
        if (e && e.type === EdgeType.Eaves) {
          setEaveEdge(e);
          break;
        }
      }
    }
  }, [plane, edges]);

  const calculateAngleLineToAxisX = (A: any, B: any) => {
    const angle = (Math.atan2(B.y - A.y, B.x - A.x) * 180) / Math.PI;
    return angle;
  };

  const getRotatedPoints = (extraAngle: number) => {
    const lineLength = 100;

    // Zmniejszamy wysokośc okna o kąt spadku
    const slopeAngle = plane?.angle as number;

    const basicPoints = [
      { x: mp.x, y: mp.y },
      { x: mp.x, y: mp.y + lineLength },
    ];

    const start = points.find((x) => x.id === eaveEdge?.startPointId);
    const end = points.find((x) => x.id === eaveEdge?.endPointId);

    const angle = -1 * (calculateAngleLineToAxisX(start, end) + extraAngle);

    const result = [];

    for (const p of basicPoints) {
      result.push(rotate(mp.x, mp.y, p.x, p.y, angle));
    }

    return result.flat();
  };

  const getWindowOutline = () => {
    return (
      <Group>
        <Line
          points={getRotatedPoints(0)}
          closed={false}
          stroke="#585858"
          strokeWidth={2 / zoomScale}
          fill="#ffffff80"
        />
        <Line
          points={getRotatedPoints(plane?.angle as number)}
          closed={false}
          stroke="#585858"
          strokeWidth={2 / zoomScale}
          fill="#ffffff80"
        />
        <Line
          points={getRotatedPoints(-1 * (plane?.angle as number))}
          closed={false}
          stroke="#585858"
          strokeWidth={2 / zoomScale}
          fill="#ffffff80"
        />
      </Group>
    );
  };

  return (
    <Group>
      <Circle
        x={mp.x}
        y={mp.y}
        radius={4 / zoomScale}
        strokeWidth={1 / zoomScale}
        stroke={"#000000"}
        fill="#ffffff"
      />

      {points && eaveEdge && getWindowOutline()}
    </Group>
  );
};
export default BayInDraw;
