import { Alert, Button, Col, Form, message, Row } from "antd";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../../../../api/ApiClient";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { useFirstRender } from "../../../../../../hooks/useFirstRender";
import { IRootState } from "../../../../../../models/Models";
import { setTempCurrencyValue } from "../../../../../../redux/general/currencyValueSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

export interface CurrencyRateResponse {
  rates: { mid: number }[];
}

type Props = {};

const CurrencyValue: FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const firstRender = useFirstRender();

  const report = useAppSelector((state: IRootState) => state.report);
  const reports = useAppSelector((state: IRootState) => state.reports);
  const investments = useAppSelector((state: IRootState) => state.investments);
  const currencyValueState = useAppSelector((state: IRootState) => state.currencyValue);

  // Ustawienie początkowej wartości `currencyValue` na wartość z Reduxa lub 0, jeśli nie jest dostępna
  const [currencyValue, setCurrencyValue] = useState<number>(currencyValueState.tempEur || 0);

  useEffect(() => {
    dispatch(setTempCurrencyValue(currencyValue));
  }, [currencyValue]);

  useEffect(() => {
    if (firstRender && currencyValueState) {
      if (currencyValueState.tempEur === 0) {
        // Gdy tempEur jest 0 to pobierz aktualną wartość z API
        // console.log("currencyValueStateCC", currencyValueState);
        fetchCurrencyValue();
      } else {
        setCurrencyValue(currencyValueState.tempEur);
      }
    }
  }, [currencyValueState]);

  const fetchCurrencyValue = async (date?: Date): Promise<number | undefined> => {
    // console.log("fetchCurrencyValue");
    let baseUrl = "https://api.nbp.pl/api/exchangerates/rates/a/eur";
    const format = "?format=json";

    // Pomocnicza funkcja do sprawdzania, czy response jest poprawny
    const isValidResponse = (response: any): boolean =>
      response && response.status === 200 && response.data && response.data.rates.length > 0;

    // Pomocnicza funkcja do formatowania daty do YYYY-MM-DD
    const formatDate = (date: Date): string => {
      return date.toISOString().split("T")[0];
    };

    // Spróbuj pobrać kurs waluty dla danej daty lub dla najbliższego wcześniejszego dnia roboczego
    let attempts = 0; // Licznik prób, aby uniknąć nieskończonej pętli
    let currentDate = date ? new Date(date) : new Date();
    while (attempts < 7) {
      // Zakładamy, że cofnięcie się o 7 dni zawsze znajdzie dzień roboczy
      let dateString = formatDate(currentDate);
      let url = `${baseUrl}/${dateString}/${format}`;

      try {
        const response = await axios.get<CurrencyRateResponse>(url);
        if (isValidResponse(response)) {
          message.success(`Kurs waluty dla daty: ${dateString}`);
          setCurrencyValue(response.data.rates[0].mid);
          return response.data.rates[0].mid; // Zwraca średni kurs waluty
        }
      } catch (err) {
        console.error(`Błąd podczas pobierania kursu waluty dla daty ${dateString}: `, err);
        // Jeśli dostaniemy błąd, to cofamy datę o jeden dzień i próbujemy ponownie
        currentDate.setDate(currentDate.getDate() - 1);
      }

      attempts++;
    }

    console.error("Nie udało się znaleźć kursu waluty dla żadnej z próbowanych dat.");
    return undefined; // Zwraca undefined, jeśli nie udało się pobrać kursu
  };

  const getCreatedDateFromInvestmentForReport = () => {
    if (report && reports) {
      const fullReport = reports.find((r) => r.id === report.responseData?.id);

      // console.log("FULLREPORT", fullReport);
      if (fullReport?.created) {
        return new Date(fullReport.created);
      }
    }
    return undefined;
  };

  const getReportCreatedDate = () => {
    const date = getCreatedDateFromInvestmentForReport();

    // console.log("DATE", date);
    return date;
  };

  return (
    <Container>
      <Alert
        showIcon
        type="warning"
        message={
          "Jeśli wybrane systemy zawierają produkty z ceną w obcej walucie, ustal aktualną wartość tej waluty dla bieżących kalkulacji."
        }
      />
      <Row gutter={16} style={{ marginTop: "16px" }}>
        <Col span={4}>
          <Form.Item label="Wartość EUR w PLN" labelCol={{ span: 24 }} style={{ width: "100%" }}>
            <DomaInputNumber
              value={currencyValue}
              onChange={(value) => {
                setCurrencyValue(value as number);
                dispatch(setTempCurrencyValue(value as number));
              }}
              placeholder="Wartość waluty"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6} style={{ display: "flex", gap: "16px" }}>
          {/* Pobrane ze zapisanego Slice */}
          <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
            <Button disabled={!report.generated} onClick={() => setCurrencyValue(currencyValueState.eur)}>
              Wartość kursu z ostatniego obliczenia
            </Button>
          </Form.Item>
          {/* Pobrane z API (np. NBP) */}
          <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
            <Button
              disabled={!report.generated}
              onClick={() => fetchCurrencyValue(getReportCreatedDate()) || new Date()}
            >
              Wartość kursu na dzień założenia projektu (NBP)
            </Button>
          </Form.Item>
          {/* Pobrane z API (np. NBP) */}
          <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
            <Button onClick={() => fetchCurrencyValue()}>Aktualna wartość kursu (NBP)</Button>
          </Form.Item>
        </Col>
      </Row>
    </Container>
  );
};
export default CurrencyValue;

const Container = styled.div``;
