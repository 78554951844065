import { Alert, Button, Card, Col, Divider, Form, Input, message, Row, Select, Switch } from "antd";
import useForm from "antd/es/form/hooks/useForm";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DomaInputNumber from "../../../../helpers/DomaInputNumber";
import { createFilterOption } from "../../../../helpers/Helpers";
import {
  IAddGutterSystem,
  IGutterSystem,
  IGutterSystemAddition,
  IProduct,
  IProductCategory,
  IRootState,
  ISelectOption,
  ISheetMetalProcess,
} from "../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteStatusApi } from "../../../../redux/other/statusApiSlice";
import { addGutterSystem, updateGutterSystem } from "../../../../redux/roofSystems/gutterSystemsSlice";
import { v4 as uuidv4 } from "uuid";
import {
  CATEGORIES_ALL_ROOF,
  CATEGORIES_GUTTERS,
  EDGES_RELATIONS,
  TILE_SYSTEM_RELATIONS,
} from "../../RoofConstants";
import ExtraProductGutterSystemPosition from "./ExtraProductGutterSystemPosition";
import SheetMetalProcessPosition from "../../roofSystems/components/SheetMetalProcessPosition";
import SystemImageComponent from "../../roofSystems/components/SystemImageComponent";
import SystemConfigurationDetailsSection from "../../components/SystemConfigurationDetailsSection";

type Props = {
  system: IGutterSystem | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const GutterSystemDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);

  const [gutterSystemAdditions, setGutterSystemAdditions] = useState<IGutterSystemAddition[]>([]);
  const [sheetMetalProcesses, setSheetMetalProcesses] = useState<ISheetMetalProcess[]>([]); // Obróbki blacharskie

  const [imageFileName, setImageFileName] = useState<string>("");

  const gutterSystems = useAppSelector((state: IRootState) => state.gutterSystems);

  const [productsSelectForGuttering, setProductsSelectForGuttering] = useState<ISelectOption[]>([]);
  const [productsSelectForSheetMetal, setProductsSelectForSheetMetal] = useState<ISelectOption[]>([]);
  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForGuttering.length === 0) {
        setProductsSelectForGuttering(getProductsByCategoryForSelect(products, productCategories, ["guttering"]));
      }

      if (productsSelectForSheetMetal.length === 0) {
        setProductsSelectForSheetMetal(
          getProductsByCategoryForSelect(products, productCategories, ["supplementary-products"])
        );
      }

      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "guttering",
            "wood",
            "supplementary-products",
            "thermal-insulation",
            "roofing-accessories",
            "fasteners",
            "preliminary-covering",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  const statusApi = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "addGutterSystem")
  );

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addGutterSystem"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addGutterSystem"));
    }
  }, [statusApi]);

  // Jesli coś zmieni się w systemach rynnowych to ustawiamy ponownie dodatki i procesy
  useEffect(() => {
    if (gutterSystems) {
      const s = gutterSystems.find((x) => x.id === system?.id);
      if (s) {
        setGutterSystemAdditions(s.gutterSystemAdditions);
        setSheetMetalProcesses(s.sheetMetalProcesses);
      }
    }
  }, [gutterSystems]);

  useEffect(() => {
    if (system && system.gutterSystemAdditions && system.gutterSystemAdditions.length > 0) {
      setGutterSystemAdditions(system.gutterSystemAdditions);
    }

    if (system && system.sheetMetalProcesses && system.sheetMetalProcesses.length > 0) {
      setSheetMetalProcesses(system.sheetMetalProcesses);
    }

    if (system?.imageUrl) {
      setImageFileName(system.imageUrl);
    }
  }, [system]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  const initialValues = {
    name: system?.name ? system?.name : "",
    description: system?.description ? system?.description : "",
    imageUrl: system?.imageUrl ? system?.imageUrl : "",

    gutter1ProductId: system?.gutter1ProductId ? system?.gutter1ProductId : undefined,
    gutter1Length: system?.gutter1Length ? system?.gutter1Length : 0,
    gutter1Relation: system?.gutter1Relation ? system?.gutter1Relation : "edge-eave",
    gutter1Correction: system?.gutter1Correction ? system?.gutter1Correction : 0,

    gutter2ProductId: system?.gutter2ProductId ? system?.gutter2ProductId : undefined,
    gutter2Length: system?.gutter2Length ? system?.gutter2Length : 0,
    gutter2Relation: system?.gutter2Relation ? system?.gutter2Relation : "edge-eave",
    gutter2Correction: system?.gutter2Correction ? system?.gutter2Correction : 0,

    gutter3ProductId: system?.gutter3ProductId ? system?.gutter3ProductId : undefined,
    gutter3Length: system?.gutter3Length ? system?.gutter3Length : 0,
    gutter3Relation: system?.gutter3Relation ? system?.gutter3Relation : "edge-eave",
    gutter3Correction: system?.gutter3Correction ? system?.gutter3Correction : 0,

    gutterOverlap: system?.gutterOverlap ? system?.gutterOverlap : 0,

    gutterConnectorProductId: system?.gutterConnectorProductId ? system?.gutterConnectorProductId : undefined,
    gutterConnectorUse: system?.gutterConnectorUse ? system?.gutterConnectorUse : 1,
    gutterConnectorRelation: system?.gutterConnectorRelation ? system?.gutterConnectorRelation : "none",
    gutterConnectorCorrection: system?.gutterConnectorCorrection ? system?.gutterConnectorCorrection : 0,

    internalCornerProductId: system?.internalCornerProductId ? system?.internalCornerProductId : undefined,
    internalCornerUse: system?.internalCornerUse ? system?.internalCornerUse : 1,
    internalCornerRelation: system?.internalCornerRelation ? system?.internalCornerRelation : "none",
    internalCornerCorrection: system?.internalCornerCorrection ? system?.internalCornerCorrection : 0,

    additionalElementInternalCornerProductId: system?.additionalElementInternalCornerProductId
      ? system?.additionalElementInternalCornerProductId
      : undefined,
    additionalElementInternalCornerUse: system?.additionalElementInternalCornerUse
      ? system?.additionalElementInternalCornerUse
      : 1,
    additionalElementInternalCornerRelation: system?.additionalElementInternalCornerRelation
      ? system?.additionalElementInternalCornerRelation
      : "none",
    additionalElementInternalCornerCorrection: system?.additionalElementInternalCornerCorrection
      ? system?.additionalElementInternalCornerCorrection
      : 0,

    outerCornerProductId: system?.outerCornerProductId ? system?.outerCornerProductId : undefined,
    outerCornerUse: system?.outerCornerUse ? system?.outerCornerUse : 1,
    outerCornerRelation: system?.outerCornerRelation ? system?.outerCornerRelation : "none",
    outerCornerCorrection: system?.outerCornerCorrection ? system?.outerCornerCorrection : 0,

    additionalElementOuterCornerProductId: system?.additionalElementOuterCornerProductId
      ? system?.additionalElementOuterCornerProductId
      : undefined,
    additionalElementOuterCornerUse: system?.additionalElementOuterCornerUse
      ? system?.additionalElementOuterCornerUse
      : 1,
    additionalElementOuterCornerRelation: system?.additionalElementOuterCornerRelation
      ? system?.additionalElementOuterCornerRelation
      : "none",
    additionalElementOuterCornerCorrection: system?.additionalElementOuterCornerCorrection
      ? system?.additionalElementOuterCornerCorrection
      : 0,

    useConnectorsInCorners: system?.useConnectorsInCorners ?? false,

    hookProductId: system?.hookProductId ? system?.hookProductId : undefined,
    hookUse: system?.hookUse ? system?.hookUse : 1,
    hookRelation: system?.hookRelation ? system?.hookRelation : "none",
    hookCorrection: system?.hookCorrection ? system?.hookCorrection : 0,

    hookSpacing: system?.hookSpacing ? system?.hookSpacing : 0.6,

    leftCapProductId: system?.leftCapProductId ? system?.leftCapProductId : undefined,
    leftCapUse: system?.leftCapUse ? system?.leftCapUse : 1,
    leftCapRelation: system?.leftCapRelation ? system?.leftCapRelation : "none",
    leftCapCorrection: system?.leftCapCorrection ? system?.leftCapCorrection : 0,

    firstAdditionalElementLeftCapProductId: system?.firstAdditionalElementLeftCapProductId
      ? system?.firstAdditionalElementLeftCapProductId
      : undefined,
    firstAdditionalElementLeftCapUse: system?.firstAdditionalElementLeftCapUse
      ? system?.firstAdditionalElementLeftCapUse
      : 1,
    firstAdditionalElementLeftCapRelation: system?.firstAdditionalElementLeftCapRelation
      ? system?.firstAdditionalElementLeftCapRelation
      : "none",
    firstAdditionalElementLeftCapCorrection: system?.firstAdditionalElementLeftCapCorrection
      ? system?.firstAdditionalElementLeftCapCorrection
      : 0,

    secondAdditionalElementLeftCapProductId: system?.secondAdditionalElementLeftCapProductId
      ? system?.secondAdditionalElementLeftCapProductId
      : undefined,
    secondAdditionalElementLeftCapUse: system?.secondAdditionalElementLeftCapUse
      ? system?.secondAdditionalElementLeftCapUse
      : 1,
    secondAdditionalElementLeftCapRelation: system?.secondAdditionalElementLeftCapRelation
      ? system?.secondAdditionalElementLeftCapRelation
      : "none",
    secondAdditionalElementLeftCapCorrection: system?.secondAdditionalElementLeftCapCorrection
      ? system?.secondAdditionalElementLeftCapCorrection
      : 0,

    rightCapProductId: system?.rightCapProductId ? system?.rightCapProductId : undefined,
    rightCapUse: system?.rightCapUse ? system?.rightCapUse : 1,
    rightCapRelation: system?.rightCapRelation ? system?.rightCapRelation : "none",
    rightCapCorrection: system?.rightCapCorrection ? system?.rightCapCorrection : 0,

    firstAdditionalElementRightCapProductId: system?.firstAdditionalElementRightCapProductId
      ? system?.firstAdditionalElementRightCapProductId
      : undefined,
    firstAdditionalElementRightCapUse: system?.firstAdditionalElementRightCapUse
      ? system?.firstAdditionalElementRightCapUse
      : 1,
    firstAdditionalElementRightCapRelation: system?.firstAdditionalElementRightCapRelation
      ? system?.firstAdditionalElementRightCapRelation
      : "none",
    firstAdditionalElementRightCapCorrection: system?.firstAdditionalElementRightCapCorrection
      ? system?.firstAdditionalElementRightCapCorrection
      : 0,

    secondAdditionalElementRightCapProductId: system?.secondAdditionalElementRightCapProductId
      ? system?.secondAdditionalElementRightCapProductId
      : undefined,
    secondAdditionalElementRightCapUse: system?.secondAdditionalElementRightCapUse
      ? system?.secondAdditionalElementRightCapUse
      : 1,
    secondAdditionalElementRightCapRelation: system?.secondAdditionalElementRightCapRelation
      ? system?.secondAdditionalElementRightCapRelation
      : "none",
    secondAdditionalElementRightCapCorrection: system?.secondAdditionalElementRightCapCorrection
      ? system?.secondAdditionalElementRightCapCorrection
      : 0,

    exactCountRequired: system?.exactCountRequired ?? true,

    drainPipe1ProductId: system?.drainPipe1ProductId ? system?.drainPipe1ProductId : undefined,
    drainPipe1Length: system?.drainPipe1Length ? system?.drainPipe1Length : 0,
    drainPipe1Relation: system?.drainPipe1Relation ? system?.drainPipe1Relation : "none",
    drainPipe1Correction: system?.drainPipe1Correction ? system?.drainPipe1Correction : 0,

    drainPipe2ProductId: system?.drainPipe2ProductId ? system?.drainPipe2ProductId : undefined,
    drainPipe2Length: system?.drainPipe2Length ? system?.drainPipe2Length : 0,
    drainPipe2Relation: system?.drainPipe2Relation ? system?.drainPipe2Relation : "none",
    drainPipe2Correction: system?.drainPipe2Correction ? system?.drainPipe2Correction : 0,

    drainPipe3ProductId: system?.drainPipe3ProductId ? system?.drainPipe3ProductId : undefined,
    drainPipe3Length: system?.drainPipe3Length ? system?.drainPipe3Length : 0,
    drainPipe3Relation: system?.drainPipe3Relation ? system?.drainPipe3Relation : "none",
    drainPipe3Correction: system?.drainPipe3Correction ? system?.drainPipe3Correction : 0,

    drainPipeOverlap: system?.drainPipeOverlap ? system?.drainPipeOverlap : 0,

    drainFunnelProductId: system?.drainFunnelProductId ? system?.drainFunnelProductId : undefined,
    drainFunnelUse: system?.drainFunnelUse ? system?.drainFunnelUse : 1,
    drainFunnelRelation: system?.drainFunnelRelation ? system?.drainFunnelRelation : "none",
    drainFunnelCorrection: system?.drainFunnelCorrection ? system?.drainFunnelCorrection : 0,

    drainPipeElbowProductId: system?.drainPipeElbowProductId ? system?.drainPipeElbowProductId : undefined,
    drainPipeElbowUse: system?.drainPipeElbowUse ? system?.drainPipeElbowUse : 1,
    drainPipeElbowRelation: system?.drainPipeElbowRelation ? system?.drainPipeElbowRelation : "none",
    drainPipeElbowCorrection: system?.drainPipeElbowCorrection ? system?.drainPipeElbowCorrection : 0,

    drainPipeHolderProductId: system?.drainPipeHolderProductId ? system?.drainPipeHolderProductId : undefined,
    drainPipeHolderUse: system?.drainPipeHolderUse ? system?.drainPipeHolderUse : 1,
    drainPipeHolderRelation: system?.drainPipeHolderRelation ? system?.drainPipeHolderRelation : "none",
    drainPipeHolderCorrection: system?.drainPipeHolderCorrection ? system?.drainPipeHolderCorrection : 0,

    drainPipeHolderPinProductId: system?.drainPipeHolderPinProductId
      ? system?.drainPipeHolderPinProductId
      : undefined,
    drainPipeHolderPinUse: system?.drainPipeHolderPinUse ? system?.drainPipeHolderPinUse : 1,
    drainPipeHolderPinRelation: system?.drainPipeHolderPinRelation ? system?.drainPipeHolderPinRelation : "none",
    drainPipeHolderPinCorrection: system?.drainPipeHolderPinCorrection ? system?.drainPipeHolderPinCorrection : 0,

    drainPipeHolderSpacing: system?.drainPipeHolderSpacing ? system?.drainPipeHolderSpacing : 2,

    firstAdditionalElementHeightWallProductId: system?.firstAdditionalElementHeightWallProductId
      ? system?.firstAdditionalElementHeightWallProductId
      : undefined,
    firstAdditionalElementHeightWallUse: system?.firstAdditionalElementHeightWallUse
      ? system?.firstAdditionalElementHeightWallUse
      : 1,
    firstAdditionalElementHeightWallRelation: system?.firstAdditionalElementHeightWallRelation
      ? system?.firstAdditionalElementHeightWallRelation
      : "none",
    firstAdditionalElementHeightWallCorrection: system?.firstAdditionalElementHeightWallCorrection
      ? system?.firstAdditionalElementHeightWallCorrection
      : 0,

    secondAdditionalElementHeightWallProductId: system?.secondAdditionalElementHeightWallProductId
      ? system?.secondAdditionalElementHeightWallProductId
      : undefined,
    secondAdditionalElementHeightWallUse: system?.secondAdditionalElementHeightWallUse
      ? system?.secondAdditionalElementHeightWallUse
      : 1,
    secondAdditionalElementHeightWallRelation: system?.secondAdditionalElementHeightWallRelation
      ? system?.secondAdditionalElementHeightWallRelation
      : "none",
    secondAdditionalElementHeightWallCorrection: system?.secondAdditionalElementHeightWallCorrection
      ? system?.secondAdditionalElementHeightWallCorrection
      : 0,

    companyId: system?.companyId ? system?.companyId : undefined,
    modelId: system?.productModelId ? system?.productModelId : undefined,
    colorId: system?.productColorId ? system?.productColorId : undefined,
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "System został utworzony!",
    });
  };

  const onFinish = (values: any, newVersion: boolean) => {
    let s: IAddGutterSystem = {
      name: values.name,
      description: values.description,
      // imageUrl: values.imageUrl,
      imageUrl: imageFileName,
      companyId: values?.companyId,
      productColorId: values?.colorId,
      productModelId: values?.modelId,

      gutter1ProductId: values.gutter1ProductId,
      gutter1Length: values.gutter1Length,
      gutter1Relation: values.gutter1Relation,
      gutter1Correction: values.gutter1Correction,

      gutter2ProductId: values.gutter2ProductId,
      gutter2Length: values.gutter2Length,
      gutter2Relation: values.gutter2Relation,
      gutter2Correction: values.gutter2Correction,

      gutter3ProductId: values.gutter3ProductId,
      gutter3Length: values.gutter3Length,
      gutter3Relation: values.gutter3Relation,
      gutter3Correction: values.gutter3Correction,

      gutterOverlap: values.gutterOverlap,

      gutterConnectorProductId: values.gutterConnectorProductId,
      gutterConnectorUse: values.gutterConnectorUse,
      gutterConnectorRelation: values.gutterConnectorRelation,
      gutterConnectorCorrection: values.gutterConnectorCorrection,

      internalCornerProductId: values.internalCornerProductId,
      internalCornerUse: values.internalCornerUse,
      internalCornerRelation: values.internalCornerRelation,
      internalCornerCorrection: values.internalCornerCorrection,

      additionalElementInternalCornerProductId: values.additionalElementInternalCornerProductId,
      additionalElementInternalCornerUse: values.additionalElementInternalCornerUse,
      additionalElementInternalCornerRelation: values.additionalElementInternalCornerRelation,
      additionalElementInternalCornerCorrection: values.additionalElementInternalCornerCorrection,

      outerCornerProductId: values.outerCornerProductId,
      outerCornerUse: values.outerCornerUse,
      outerCornerRelation: values.outerCornerRelation,
      outerCornerCorrection: values.outerCornerCorrection,

      additionalElementOuterCornerProductId: values.additionalElementOuterCornerProductId,
      additionalElementOuterCornerUse: values.additionalElementOuterCornerUse,
      additionalElementOuterCornerRelation: values.additionalElementOuterCornerRelation,
      additionalElementOuterCornerCorrection: values.additionalElementOuterCornerCorrection,

      useConnectorsInCorners: values.useConnectorsInCorners,

      hookProductId: values.hookProductId,
      hookUse: values.hookUse,
      hookRelation: values.hookRelation,
      hookCorrection: values.hookCorrection,

      hookSpacing: values.hookSpacing,

      leftCapProductId: values.leftCapProductId,
      leftCapUse: values.leftCapUse,
      leftCapRelation: values.leftCapRelation,
      leftCapCorrection: values.leftCapCorrection,

      firstAdditionalElementLeftCapProductId: values.firstAdditionalElementLeftCapProductId,
      firstAdditionalElementLeftCapUse: values.firstAdditionalElementLeftCapUse,
      firstAdditionalElementLeftCapRelation: values.firstAdditionalElementLeftCapRelation,
      firstAdditionalElementLeftCapCorrection: values.firstAdditionalElementLeftCapCorrection,

      secondAdditionalElementLeftCapProductId: values.secondAdditionalElementLeftCapProductId,
      secondAdditionalElementLeftCapUse: values.secondAdditionalElementLeftCapUse,
      secondAdditionalElementLeftCapRelation: values.secondAdditionalElementLeftCapRelation,
      secondAdditionalElementLeftCapCorrection: values.secondAdditionalElementLeftCapCorrection,

      rightCapProductId: values.rightCapProductId,
      rightCapUse: values.rightCapUse,
      rightCapRelation: values.rightCapRelation,
      rightCapCorrection: values.rightCapCorrection,

      firstAdditionalElementRightCapProductId: values.firstAdditionalElementRightCapProductId,
      firstAdditionalElementRightCapUse: values.firstAdditionalElementRightCapUse,
      firstAdditionalElementRightCapRelation: values.firstAdditionalElementRightCapRelation,
      firstAdditionalElementRightCapCorrection: values.firstAdditionalElementRightCapCorrection,

      secondAdditionalElementRightCapProductId: values.secondAdditionalElementRightCapProductId,
      secondAdditionalElementRightCapUse: values.secondAdditionalElementRightCapUse,
      secondAdditionalElementRightCapRelation: values.secondAdditionalElementRightCapRelation,
      secondAdditionalElementRightCapCorrection: values.secondAdditionalElementRightCapCorrection,

      exactCountRequired: values.exactCountRequired,

      drainPipe1ProductId: values.drainPipe1ProductId,
      drainPipe1Length: values.drainPipe1Length,
      drainPipe1Relation: values.drainPipe1Relation,
      drainPipe1Correction: values.drainPipe1Correction,

      drainPipe2ProductId: values.drainPipe2ProductId,
      drainPipe2Length: values.drainPipe2Length,
      drainPipe2Relation: values.drainPipe2Relation,
      drainPipe2Correction: values.drainPipe2Correction,

      drainPipe3ProductId: values.drainPipe3ProductId,
      drainPipe3Length: values.drainPipe3Length,
      drainPipe3Relation: values.drainPipe3Relation,
      drainPipe3Correction: values.drainPipe3Correction,

      drainPipeOverlap: values.drainPipeOverlap,

      drainFunnelProductId: values.drainFunnelProductId,
      drainFunnelUse: values.drainFunnelUse,
      drainFunnelRelation: values.drainFunnelRelation,
      drainFunnelCorrection: values.drainFunnelCorrection,

      drainPipeElbowProductId: values.drainPipeElbowProductId,
      drainPipeElbowUse: values.drainPipeElbowUse,
      drainPipeElbowRelation: values.drainPipeElbowRelation,
      drainPipeElbowCorrection: values.drainPipeElbowCorrection,

      drainPipeHolderProductId: values.drainPipeHolderProductId,
      drainPipeHolderUse: values.drainPipeHolderUse,
      drainPipeHolderRelation: values.drainPipeHolderRelation,
      drainPipeHolderCorrection: values.drainPipeHolderCorrection,

      drainPipeHolderPinProductId: values.drainPipeHolderPinProductId,
      drainPipeHolderPinUse: values.drainPipeHolderPinUse,
      drainPipeHolderPinRelation: values.drainPipeHolderPinRelation,
      drainPipeHolderPinCorrection: values.drainPipeHolderPinCorrection,

      drainPipeHolderSpacing: values.drainPipeHolderSpacing,

      firstAdditionalElementHeightWallProductId: values.firstAdditionalElementHeightWallProductId,
      firstAdditionalElementHeightWallUse: values.firstAdditionalElementHeightWallUse,
      firstAdditionalElementHeightWallRelation: values.firstAdditionalElementHeightWallRelation,
      firstAdditionalElementHeightWallCorrection: values.firstAdditionalElementHeightWallCorrection,

      secondAdditionalElementHeightWallProductId: values.secondAdditionalElementHeightWallProductId,
      secondAdditionalElementHeightWallUse: values.secondAdditionalElementHeightWallUse,
      secondAdditionalElementHeightWallRelation: values.secondAdditionalElementHeightWallRelation,
      secondAdditionalElementHeightWallCorrection: values.secondAdditionalElementHeightWallCorrection,

      gutterSystemAdditions: gutterSystemAdditions,
      sheetMetalProcesses: sheetMetalProcesses,
    };

    if (isNew() || newVersion) {
      dispatch(addGutterSystem(s, successCallback));
    } else {
      let se: IGutterSystem = { ...s, id: system?.id as string };
      dispatch(updateGutterSystem(se));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  interface MainGutterProductPositionProps {
    productLabel: string;
    productName: string;
    lengthName: string;
    relationName: string;
    relationDisabled: boolean;
    correctionName: string;
    correctionDisabled: boolean;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
    selectedCategories: string[];
    readyOptions?: any[];
  }

  const MainGutterProductPosition: React.FC<MainGutterProductPositionProps> = ({
    productLabel,
    productName,
    lengthName,
    relationName,
    relationDisabled,
    correctionName,
    correctionDisabled,
    products,
    productCategories,
    relations,
    selectedCategories,
    readyOptions,
  }) => {
    const onProductChange = (productId: any) => {
      const selectedProduct = products.find((p) => p.id === productId);
      if (selectedProduct) {
        form.setFieldsValue({ [lengthName]: selectedProduct.length });
      }
    };

    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={onProductChange}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Dł. elementu [m]" name={lengthName} labelCol={{ span: 24 }}>
            <DomaInputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zależność" name={relationName} labelCol={{ span: 24 }}>
            <Select
              disabled={relationDisabled}
              placeholder="Wybierz zależność!"
              options={relations.sort((a, b) => a.index - b.index)}
              dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
              filterOption={createFilterOption()}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Korekta w [%]" name={correctionName} labelCol={{ span: 24 }}>
            <DomaInputNumber
              disabled={correctionDisabled}
              allowNegative
              min={-100}
              max={200}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  interface OtherProductPositionProps {
    productLabel: string;
    productName: string;
    useName: string;
    useDisabled: boolean;
    relationName: string;
    relationDisabled: boolean;
    correctionName: string;
    correctionDisabled: boolean;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
    selectedCategories: string[];
    readyOptions?: any[];
  }

  const OtherProductPosition: React.FC<OtherProductPositionProps> = ({
    productLabel,
    productName,
    useName,
    useDisabled,
    relationName,
    relationDisabled,
    correctionName,
    correctionDisabled,
    products,
    productCategories,
    relations,
    selectedCategories,
    readyOptions,
  }) => {
    const onProductChange = (productId: any) => {
      const selectedProduct = products.find((p) => p.id === productId);
      if (selectedProduct) {
        form.setFieldsValue({ [useName]: selectedProduct.use });
      }
    };

    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={onProductChange}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Użycie" name={useName} labelCol={{ span: 24 }}>
            <DomaInputNumber disabled={useDisabled} min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zależność" name={relationName} labelCol={{ span: 24 }}>
            <Select
              disabled={relationDisabled}
              placeholder="Wybierz zależność!"
              options={relations.sort((a, b) => a.index - b.index)}
              dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
              filterOption={createFilterOption()}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Korekta w [%]" name={correctionName} labelCol={{ span: 24 }}>
            <DomaInputNumber
              disabled={correctionDisabled}
              allowNegative
              min={-100}
              max={200}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Container>
      {contextHolder}
      <Form
        id="gutterSystemDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Section title={"Ogólne dane systemu"}>
          <Form.Item
            label="Nazwa systemu"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę systemu!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis systemu" name="description">
            <Input />
          </Form.Item>

          {/* <Form.Item label="Link do zdjęcia obrazującego system" name="imageUrl">
            <Input />
          </Form.Item> */}

          <SystemImageComponent
            systemId={system?.id || ""}
            systemName="guttering"
            imageFileName={imageFileName}
            setImageFileName={(name) => setImageFileName(name)}
          />
        </Section>

        {/* Sekcja do konfiguracji producenta, modelu oraz koloru */}
        <SystemConfigurationDetailsSection form={form} />

        <Section title={"Główna część systemu rynnowego (wzdłuż okapu)"} style={{ marginTop: "16px" }}>
          {/* Rynna 1*/}
          <MainGutterProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Rynna 1"
            productName="gutter1ProductId"
            lengthName="gutter1Length"
            relationName="gutter1Relation"
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="gutter1Correction"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Rynna 2*/}
          <MainGutterProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Rynna 2"
            productName="gutter2ProductId"
            lengthName="gutter2Length"
            relationName="gutter2Relation"
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="gutter2Correction"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Rynna 3*/}
          <MainGutterProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Rynna 3"
            productName="gutter3ProductId"
            lengthName="gutter3Length"
            relationName="gutter3Relation"
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="gutter3Correction"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Form.Item label="Zakład rynien [m]" name="gutterOverlap" labelCol={{ span: 24 }}>
            <DomaInputNumber disabled />
          </Form.Item>

          {/* Łącznik rynien*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Łącznik rynien"
            productName="gutterConnectorProductId"
            useName="gutterConnectorUse"
            relationName="gutterConnectorRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="gutterConnectorCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Łącznik w narożniku wewnętrznym*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Narożnik wewnętrzny"
            productName="internalCornerProductId"
            useName="internalCornerUse"
            relationName="internalCornerRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="internalCornerCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Dodatkowy produkt w narożniku wewnętrznym */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt w narożniku wewnętrznym"
            productName="additionalElementInternalCornerProductId"
            useName="additionalElementInternalCornerUse"
            relationName="additionalElementInternalCornerRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="additionalElementInternalCornerCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Łącznik w narożniku zewnętrznym*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Narożnik zewnętrzny"
            productName="outerCornerProductId"
            useName="outerCornerUse"
            relationName="outerCornerRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="outerCornerCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Dodatkowy produkt w narożniku zewnętrznym */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt w narożniku zewnętrznym"
            productName="additionalElementOuterCornerProductId"
            useName="additionalElementOuterCornerUse"
            relationName="additionalElementOuterCornerRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="additionalElementOuterCornerCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Form.Item
            label="Czy uwzględniać łączniki w narożnikach wewnętrznych i zewnętrznych?"
            name="useConnectorsInCorners"
            valuePropName="checked"
            labelCol={{ span: 24 }}
          >
            <Switch />
          </Form.Item>

          {/* Hak do montowania rynien*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Hak do montowania rynien"
            productName="hookProductId"
            useName="hookUse"
            relationName="hookRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="hookCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Form.Item label="Maksymalny rozstaw haków [m]" name="hookSpacing" labelCol={{ span: 24 }}>
            <DomaInputNumber />
          </Form.Item>

          <Alert
            showIcon
            message="Dokładny dobór ilości haków sprawdza każde łączenia oraz zakończenia rynien. W przeciwnym razie upraszcza dobór czyli bierze tylko pod uwagę łączną długość okapów oraz rozstaw haków."
            style={{ marginBottom: "16px" }}
          />
          <Form.Item
            label="Dokładny dobór ilości haków"
            name="exactCountRequired"
            valuePropName="checked"
            labelCol={{ span: 4 }}
          >
            <Switch />
          </Form.Item>

          {/* Zaślepka lewa rynny*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Zaślepka lewa rynny"
            productName="leftCapProductId"
            useName="leftCapUse"
            relationName="leftCapRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="leftCapCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Pierwszy dodatkowy produkt z lewą zaślepką rynny */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt z lewą zaślepką rynny"
            productName="firstAdditionalElementLeftCapProductId"
            useName="firstAdditionalElementLeftCapUse"
            relationName="firstAdditionalElementLeftCapRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="firstAdditionalElementLeftCapCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Drugi dodatkowy produkt z lewą zaślepką rynny */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt z lewą zaślepką rynny"
            productName="secondAdditionalElementLeftCapProductId"
            useName="secondAdditionalElementLeftCapUse"
            relationName="secondAdditionalElementLeftCapRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="secondAdditionalElementLeftCapCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Zaślepka prawa rynny*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Zaślepka prawa rynny"
            productName="rightCapProductId"
            useName="rightCapUse"
            relationName="rightCapRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="rightCapCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Pierwszy dodatkowy produkt z prawą zaślepką rynny */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt z prawą zaślepką rynny"
            productName="firstAdditionalElementRightCapProductId"
            useName="firstAdditionalElementRightCapUse"
            relationName="firstAdditionalElementRightCapRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="firstAdditionalElementRightCapCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Drugi dodatkowy produkt z prawą zaślepką rynny */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt z prawą zaślepką rynny"
            productName="secondAdditionalElementRightCapProductId"
            useName="secondAdditionalElementRightCapUse"
            relationName="secondAdditionalElementRightCapRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="secondAdditionalElementRightCapCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Divider style={{ marginBottom: "0px", marginTop: "32px" }} />

          <Section
            title="Pozostałe produkty wzdłuż okapu"
            headStyle={{ padding: "0px" }}
            bodyStyle={{ padding: "0px", marginTop: "16px" }}
            style={{ borderWidth: "0px" }}
            extra={
              <Button
                type="primary"
                onClick={() => {
                  const newGutterSystemAddition: IGutterSystemAddition = {
                    id: uuidv4(),
                    productId: "",
                    use: 0,
                    relation: "edge-eave",
                    correction: 0,
                    type: "edge",
                    gutterSystemId: system?.id as string,
                  };
                  setGutterSystemAdditions([newGutterSystemAddition, ...gutterSystemAdditions]);
                }}
              >
                Dodaj
              </Button>
            }
          >
            {gutterSystemAdditions
              .filter((x) => x.type === "edge")
              .map((addition) => {
                return (
                  <ExtraProductGutterSystemPosition
                    key={addition.id}
                    addition={addition}
                    selectedCategories={[
                      "guttering",
                      "wood",
                      "supplementary-products",
                      "thermal-insulation",
                      "roofing-accessories",
                      "fasteners",
                      "preliminary-covering",
                    ]}
                    readyOptions={productsSelectForAdditions}
                    products={products}
                    productCategories={productCategories}
                    useDisabled={false}
                    relationDisabled={true}
                    correctionDisabled={false}
                    relations={[...TILE_SYSTEM_RELATIONS]}
                    updateAddition={(addition) => {
                      const newAdditions = [...gutterSystemAdditions];
                      const index = newAdditions.findIndex((x) => x.id === addition.id);

                      if (index >= 0) {
                        newAdditions[index] = addition;
                        setGutterSystemAdditions(newAdditions);
                      }
                    }}
                    deleteAddition={(addition) => {
                      const newAdditions = [...gutterSystemAdditions].filter((x) => x.id !== addition.id);
                      setGutterSystemAdditions(newAdditions);
                    }}
                  />
                );
              })}
          </Section>

          <Divider style={{ marginBottom: "0px", marginTop: "32px" }} />

          <Section
            title={"Obróbki blacharskie na krawędzi okapu"}
            headStyle={{ padding: "0px" }}
            bodyStyle={{ padding: "0px", marginTop: "16px" }}
            style={{ borderWidth: "0px" }}
            extra={
              <Button
                type="primary"
                onClick={() => {
                  const newSheetMetalProcesses: ISheetMetalProcess = {
                    id: uuidv4(),
                    productId: "",
                    sheetWidth: 0,
                    sheetLength: 0,
                    stripWidth: 0,
                    overlap: 0,
                    relation: "edge-eave",
                    correction: 0,
                    gutterSystemId: system?.id as string,
                  };

                  setSheetMetalProcesses([newSheetMetalProcesses, ...sheetMetalProcesses]);
                }}
              >
                Dodaj
              </Button>
            }
          >
            {sheetMetalProcesses.map((process) => {
              return (
                <SheetMetalProcessPosition
                  key={process.id}
                  sheetMetalProcess={process}
                  selectedCategories={["supplementary-products"]}
                  readyOptions={productsSelectForSheetMetal}
                  products={products}
                  productCategories={productCategories}
                  relations={[...EDGES_RELATIONS]}
                  updateSheetMetalProcess={(process) => {
                    const newSheetMetalProcesses = [...sheetMetalProcesses];
                    const index = newSheetMetalProcesses.findIndex((x) => x.id === process.id);

                    if (index >= 0) {
                      newSheetMetalProcesses[index] = process;
                      setSheetMetalProcesses(newSheetMetalProcesses);
                    }
                  }}
                  deleteSheetMetalProcess={(process) => {
                    const newSheetMetalProcesses = [...sheetMetalProcesses].filter((x) => x.id !== process.id);
                    setSheetMetalProcesses(newSheetMetalProcesses);
                  }}
                />
              );
            })}
          </Section>
        </Section>

        <Section title={"Punkty spustowe"} style={{ marginTop: "16px" }}>
          {/* Rura spustowa priorytet 1*/}
          <MainGutterProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Rura spustowa 1"
            productName="drainPipe1ProductId"
            lengthName="drainPipe1Length"
            relationName="drainPipe1Relation"
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainPipe1Correction"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Rura spustowa priorytet 2*/}
          <MainGutterProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Rura spustowa 2"
            productName="drainPipe2ProductId"
            lengthName="drainPipe2Length"
            relationName="drainPipe2Relation"
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainPipe2Correction"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Rura spustowa priorytet 3*/}
          <MainGutterProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Rura spustowa 3"
            productName="drainPipe3ProductId"
            lengthName="drainPipe3Length"
            relationName="drainPipe3Relation"
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainPipe3Correction"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Form.Item label="Zakład rur spustowych [m]" name="drainPipeOverlap" labelCol={{ span: 24 }}>
            <DomaInputNumber disabled />
          </Form.Item>

          {/* Główny łącznik rynny i rury spustowej*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Główny łącznik rynny i rury spustowej (sztucer, lejek)"
            productName="drainFunnelProductId"
            useName="drainFunnelUse"
            relationName="drainFunnelRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainFunnelCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Kolanko rury spustowej*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Kolanko rury spustowej"
            productName="drainPipeElbowProductId"
            useName="drainPipeElbowUse"
            relationName="drainPipeElbowRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainPipeElbowCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Uchwyt rury spustowej*/}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Uchwyt rury spustowej"
            productName="drainPipeHolderProductId"
            useName="drainPipeHolderUse"
            relationName="drainPipeHolderRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainPipeHolderCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Trzpień uchwytu */}
          <OtherProductPosition
            selectedCategories={["guttering"]}
            readyOptions={productsSelectForGuttering}
            products={products}
            productCategories={productCategories}
            productLabel="Trzpień uchwytu"
            productName="drainPipeHolderPinProductId"
            useName="drainPipeHolderPinUse"
            relationName="drainPipeHolderPinRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="drainPipeHolderPinCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Form.Item
            label="Rozstaw uchwytów rury spustowej [m]"
            name="drainPipeHolderSpacing"
            labelCol={{ span: 24 }}
          >
            <DomaInputNumber disabled />
          </Form.Item>

          {/* Pierwszy dodatkowy produkt wzdłuż wysokości ściany */}
          <OtherProductPosition
            selectedCategories={[
              "guttering",
              "wood",
              "supplementary-products",
              "thermal-insulation",
              "roofing-accessories",
              "fasteners",
            ]}
            readyOptions={productsSelectForAdditions}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt wzdłuż wysokości ściany (rozkład na mb ściany)"
            productName="firstAdditionalElementHeightWallProductId"
            useName="firstAdditionalElementHeightWallUse"
            relationName="firstAdditionalElementHeightWallRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="firstAdditionalElementHeightWallCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          {/* Drugi dodatkowy produkt wzdłuż wysokości ściany */}
          <OtherProductPosition
            selectedCategories={[
              "guttering",
              "wood",
              "supplementary-products",
              "thermal-insulation",
              "roofing-accessories",
              "fasteners",
            ]}
            readyOptions={productsSelectForAdditions}
            products={products}
            productCategories={productCategories}
            productLabel="Dodatkowy produkt wzdłuż wysokości ściany (rozkład na mb ściany)"
            productName="secondAdditionalElementHeightWallProductId"
            useName="secondAdditionalElementHeightWallUse"
            relationName="secondAdditionalElementHeightWallRelation"
            useDisabled={true}
            relationDisabled={true}
            correctionDisabled={true}
            correctionName="secondAdditionalElementHeightWallCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />

          <Divider style={{ marginBottom: "0px", marginTop: "32px" }} />

          <Section
            title="Pozostałe produkty na punkt spustowy"
            headStyle={{ padding: "0px" }}
            bodyStyle={{ padding: "0px", marginTop: "16px" }}
            style={{ borderWidth: "0px" }}
            extra={
              <Button
                type="primary"
                onClick={() => {
                  const newGutterSystemAddition: IGutterSystemAddition = {
                    id: uuidv4(),
                    productId: "",
                    use: 0,
                    relation: "none",
                    correction: 0,
                    type: "point",
                    gutterSystemId: system?.id as string,
                  };
                  setGutterSystemAdditions([newGutterSystemAddition, ...gutterSystemAdditions]);
                }}
              >
                Dodaj
              </Button>
            }
          >
            {gutterSystemAdditions
              .filter((x) => x.type === "point")
              .map((addition) => {
                return (
                  <ExtraProductGutterSystemPosition
                    key={addition.id}
                    addition={addition}
                    selectedCategories={[
                      "guttering",
                      "wood",
                      "supplementary-products",
                      "thermal-insulation",
                      "roofing-accessories",
                      "fasteners",
                    ]}
                    readyOptions={productsSelectForAdditions}
                    products={products}
                    productCategories={productCategories}
                    useDisabled={false}
                    relationDisabled={true}
                    correctionDisabled={false}
                    relations={[...TILE_SYSTEM_RELATIONS]}
                    updateAddition={(addition) => {
                      const newAdditions = [...gutterSystemAdditions];
                      const index = newAdditions.findIndex((x) => x.id === addition.id);

                      if (index >= 0) {
                        newAdditions[index] = addition;
                        setGutterSystemAdditions(newAdditions);
                      }
                    }}
                    deleteAddition={(addition) => {
                      const newAdditions = [...gutterSystemAdditions].filter((x) => x.id !== addition.id);
                      setGutterSystemAdditions(newAdditions);
                    }}
                  />
                );
              })}
          </Section>
        </Section>

        <Section
          title="Pozostałe produkty do oferty"
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newGutterSystemAddition: IGutterSystemAddition = {
                  id: uuidv4(),
                  productId: "",
                  use: 0,
                  relation: "general",
                  correction: 0,
                  type: "general",
                  gutterSystemId: system?.id as string,
                };
                setGutterSystemAdditions([newGutterSystemAddition, ...gutterSystemAdditions]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {gutterSystemAdditions
            .filter((x) => x.type === "general")
            .map((addition) => {
              return (
                <ExtraProductGutterSystemPosition
                  key={addition.id}
                  addition={addition}
                  selectedCategories={[
                    "guttering",
                    "wood",
                    "supplementary-products",
                    "thermal-insulation",
                    "roofing-accessories",
                    "fasteners",
                  ]}
                  readyOptions={productsSelectForAdditions}
                  products={products}
                  productCategories={productCategories}
                  useDisabled={false}
                  relationDisabled={true}
                  correctionDisabled={true}
                  relations={[...TILE_SYSTEM_RELATIONS]}
                  updateAddition={(addition) => {
                    const newAdditions = [...gutterSystemAdditions];
                    const index = newAdditions.findIndex((x) => x.id === addition.id);

                    if (index >= 0) {
                      newAdditions[index] = addition;
                      setGutterSystemAdditions(newAdditions);
                    }
                  }}
                  deleteAddition={(addition) => {
                    const newAdditions = [...gutterSystemAdditions].filter((x) => x.id !== addition.id);
                    setGutterSystemAdditions(newAdditions);
                  }}
                />
              );
            })}
        </Section>
      </Form>
    </Container>
  );
};

export default GutterSystemDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
