import { Button, Checkbox, Form, Modal, Switch, Tag } from "antd";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Circle, Group, Layer, Line, Stage, Text } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import {
  getPointsRotatedRoofPartByPoints,
  movePointsRoofPartToLeftCorner,
  roundNumber,
} from "../../../../../helpers/Calculator";
import {
  calculatePolygonArea3D,
  getEdgeLength,
  getFullPointsById,
  getRoofPlaneArea,
  getTwoPoints3DLength,
  middlePoint,
} from "../../../../../helpers/Helpers";
import { IPoint, IRoofHole, IRoofPlane, IRoofPoint } from "../../../../../models/Models";
import {
  IRSO,
  IRSODeclineVector,
  IRSOEdge,
  IRSOHole,
  IRSOPoint,
} from "../steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

const SCALE_BY = 1.1;

const getPointsForLineComponent = (points: IRoofPoint[] | IRSOPoint[]) => {
  let arr = [];
  if (points && points.length > 0) {
    for (const p of points) {
      if (p) {
        arr.push(p.x);
        arr.push(p.y);
      }
    }
  }

  return arr;
};

type Props = {
  open: boolean;
  roofPlaneObject: IRoofPlane | null;
  roofHolesArray?: IRoofHole[];
  roofPointsArray: IRoofPoint[];

  rsoPlane: IRSO | null;
  rsoPoints: IRSOPoint[];
  rsoEdges: IRSOEdge[];
  rsoHoles: IRSOHole[];

  scale: number;
  cancelViewRoofPlane: any;
};

const ViewRoofPlaneModal: FC<Props> = ({
  open,
  roofPlaneObject,
  roofHolesArray,
  roofPointsArray,
  scale,
  cancelViewRoofPlane,
  rsoPlane,
  rsoPoints,
  rsoEdges,
  rsoHoles,
}) => {
  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  const [roofPlane, setRoofPlane] = useState<IRoofPlane | null>(null);
  const [roofHoles, setRoofHoles] = useState<IRoofHole[] | undefined>(roofHolesArray);
  const [roofPoints, setRoofPoints] = useState<IRoofPoint[]>(roofPointsArray);

  const [visibleDimensions, setVisibleDimensions] = useState<boolean>(true);

  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  useEffect(() => {
    if (roofPlaneObject) {
      setRoofPlane(roofPlaneObject);
    }
  }, [roofPlaneObject]);

  useEffect(() => {
    if (roofHolesArray) {
      setRoofHoles(roofHolesArray);
    }
  }, [roofHolesArray]);

  useEffect(() => {
    if (roofPointsArray) {
      setRoofPoints(roofPointsArray);
    }
  }, [roofPointsArray]);

  const isAutomatic = () => {
    return rsoEdges && rsoEdges.length > 0 ? false : true;
  };

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, [stageParent.current]);

  const rotatePointsWithDeclineVector = (
    points: IRoofPoint[] | IRSOPoint[],
    dropVector: { x: number; y: number },
    center: IRoofPoint | IRSOPoint
  ) => {
    const angle = Math.PI / 2 - Math.atan2(-1 * dropVector.y, -1 * dropVector.x);
    return points.map((point) => {
      const shiftedPoint = { ...point, x: point.x - center.x, y: point.y - center.y };
      const rotatedPoint = rotatePoint(shiftedPoint, angle);
      return { ...rotatedPoint, x: rotatedPoint.x + center.x, y: rotatedPoint.y + center.y };
    });
  };

  const rotatePoint = (point: IRoofPoint | IRSOPoint, angle: number) => {
    return {
      ...point,
      x: Math.cos(angle) * point.x - Math.sin(angle) * point.y,
      y: Math.sin(angle) * point.x + Math.cos(angle) * point.y,
    };
  };

  const flattenRoofPlane = (points: IRoofPoint[] | IRSOPoint[], angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees * Math.PI) / 180;
    return points.map((point) => {
      return {
        ...point,
        y: point.y / Math.cos(angleInRadians),
      };
    });
  };

  const getPointsForPlane = () => {
    let finalPoints: IRoofPoint[] | IRSOPoint[] = [];

    if (isAutomatic()) {
      if (roofPlane && roofPlane?.angle) {
        const points: IRoofPoint[] | IRSOPoint[] = [];

        // Pobranie punktów z obiektu
        const pointsIds = roofPlane?.pointIds as string[];
        for (const id of pointsIds) {
          const p = roofPoints.find((x) => x.id === id);
          if (p) points.push(p);
        }

        const rotatedPoints = getPointsRotatedRoofPartByPoints(points);

        const planeAngle = roofPlane?.angle || 0;
        finalPoints = flattenRoofPlane(rotatedPoints, planeAngle);
      }
    } else {
      if (rsoPlane && rsoPlane?.declineVectoor && rsoPlane?.angle) {
        const points: IRoofPoint[] | IRSOPoint[] = [];

        // Pobranie punktów z obiektu
        const pointsIds = rsoPlane?.pointIds as string[];
        for (const id of pointsIds) {
          const p = rsoPoints.find((x) => x.id === id);
          if (p) points.push(p);
        }

        const center = points[0];

        const rotatedPoints = rotatePointsWithDeclineVector(
          points,
          rsoPlane?.declineVectoor || { x: 0, y: 0 },
          center
        );

        const planeAngle = rsoPlane?.angle || 0;
        finalPoints = flattenRoofPlane(rotatedPoints, planeAngle);
      }
    }

    return finalPoints;
  };

  const getFirstPointRoofPlane = (plane: IRoofPlane) => {
    if (plane && plane.pointIds && plane.pointIds.length > 0) {
      const firstId = plane.pointIds[0];
      const p = roofPoints.find((x) => x.id === firstId);
      return p;
    }
  };

  const getFirstPointRSOPlane = (plane: IRSO) => {
    if (plane && plane.pointIds && plane.pointIds.length > 0) {
      const firstId = plane.pointIds[0];
      const p = rsoPoints.find((x) => x.id === firstId);
      return p;
    }
  };

  const getPointsForHole = (
    hole: IRoofHole | IRSOHole,
    angle: number,
    declineVector?: IRSODeclineVector,
    center?: IRoofPoint | IRSOPoint
  ) => {
    let finalPoints: IRoofPoint[] | IRSOPoint[] = [];

    if (isAutomatic()) {
      const roofPlanePoints: IRoofPoint[] = [];
      // Pobranie punktów z obiektu
      const roofPlanePointIds = roofPlane?.pointIds as string[];
      for (const id of roofPlanePointIds) {
        const p = roofPoints.find((x) => x.id === id);
        if (p) roofPlanePoints.push(p);
      }

      // Punkty otworu
      const points: IRoofPoint[] | IRSOPoint[] = [];

      // Pobranie punktów z obiektu
      const pointsIds = hole.pointIds as string[];
      for (const id of pointsIds) {
        const p = roofPoints.find((x) => x.id === id);
        if (p) points.push(p);
      }

      let rotatedPoints = getPointsRotatedRoofPartByPoints([...roofPlanePoints, ...points]);

      // Weź tylko te punkty które są w points
      rotatedPoints = rotatedPoints.filter((x) => points.find((p) => p.id === x.id));

      const planeAngle = roofPlane?.angle || 0;
      finalPoints = flattenRoofPlane(rotatedPoints, planeAngle);
    } else {
      const points: IRoofPoint[] | IRSOPoint[] = [];

      // Pobranie punktów z obiektu
      const pointsIds = hole.pointIds as string[];
      for (const id of pointsIds) {
        const p = rsoPoints.find((x) => x.id === id);
        if (p) points.push(p);
      }

      const rotatedPoints = rotatePointsWithDeclineVector(
        points,
        declineVector || { x: 0, y: 0 },
        center ? center : points[0]
      );

      const planeAngle = angle || 0;
      finalPoints = flattenRoofPlane(rotatedPoints, planeAngle);
    }

    return finalPoints;
  };

  const getSegments = (points: IPoint[]) => {
    const segments: { startPoint?: IPoint; endPoint?: IPoint; length?: number; setPoint: IPoint }[] = [];

    const sPoints = isAutomatic() ? roofPoints : rsoPoints;

    if (points && points.length > 0) {
      for (let i = 0; i < points.length - 1; i++) {
        const startPoint = sPoints.find((o) => o.id === points[i].id);
        const endPoint = sPoints.find((o) => o.id === points[i + 1].id);
        segments.push({
          startPoint: points[i],
          endPoint: points[i + 1],
          length: getTwoPoints3DLength(startPoint as IPoint, endPoint as IPoint, scale),
          setPoint: middlePoint(points[i], points[i + 1]),
        });
      }

      // Dodanie odcinka między ostatnim punktem a pierwszym (aby utworzyć polygon)
      const sp = sPoints.find((o) => o.id === points[points.length - 1].id);
      const ep = sPoints.find((o) => o.id === points[0].id);
      segments.push({
        startPoint: points[points.length - 1],
        endPoint: points[0],
        length: getTwoPoints3DLength(sp as IPoint, ep as IPoint, scale),
        setPoint: middlePoint(points[points.length - 1], points[0]),
      });
    }

    return segments;
  };

  const showDimensions = (points: IRoofPoint[] | IRSOPoint[]) => {
    const segments: { startPoint?: IPoint; endPoint?: IPoint; length?: number; setPoint: IPoint }[] = [];

    const segmentPoints = getSegments(points);
    for (const s of segmentPoints) {
      segments.push(s);
    }

    const result = [];

    let k = 0;
    for (const line of segments) {
      result.push(
        <Text
          key={k}
          fontStyle="bold"
          fontSize={12 / zoomScale}
          x={line.setPoint.x}
          y={line.setPoint.y}
          text={`${line.length?.toFixed(2)}`}
        />
      );
      k++;
    }

    return result;
  };

  const getHoleArea = (hole: IRoofHole | IRSOHole, scale: number, slopeAngle: number) => {
    const points: IRoofPoint[] | IRSOPoint = [];

    const allPoints = isAutomatic() ? roofPoints : rsoPoints;

    // Pobranie punktów z obiektu
    const pointsIds = hole.pointIds as string[];
    for (const id of pointsIds) {
      const p = allPoints.find((x) => x.id === id);
      if (p) points.push(p);
    }

    return calculatePolygonArea3D(points, scale, slopeAngle);
  };

  const getSumHolesArea = (holes: IRoofHole[] | IRSOHole[] | undefined, scale: number, slopeAngle: number) => {
    let area = 0;

    if (!holes) return 0;

    for (const h of holes) {
      area += getHoleArea(h, scale, slopeAngle);
    }

    return area;
  };

  const getRoofCenter = () => {
    const points = getPointsForPlane();
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const point of points) {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
      maxX = Math.max(maxX, point.x);
      maxY = Math.max(maxY, point.y);
    }

    const centerX = (minX + maxX) / 2;
    const centerY = (minY + maxY) / 2;

    return { x: centerX, y: centerY };
  };

  // Pobierz offset dla x i y aby środek rysunku był na środku pola stage
  const getOffsetForDraw = () => {
    const roofCenter = getRoofCenter();
    const stageCenterX = dimStageParent.width / 2;
    const stageCenterY = dimStageParent.height / 2;

    const offsetX = stageCenterX - roofCenter.x;
    const offsetY = stageCenterY - roofCenter.y;

    return { x: offsetX, y: offsetY };
  };

  function zoomStage(event: any) {
    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScaleX = s.scaleX();
      const oldScaleY = s.scaleY();
      const scaleFactor = event.evt.deltaY > 0 ? 1 / SCALE_BY : SCALE_BY;

      const newScaleX = oldScaleX * scaleFactor;
      const newScaleY = oldScaleY * scaleFactor;

      // Obliczenia uwzględniające odwrócenie skali
      // Załóżmy, że scaleX i scaleY mają wartości -1 lub 1
      const pointer = s.getPointerPosition();
      if (pointer) {
        const mousePointTo = {
          x: (pointer.x - s.x()) / oldScaleX,
          y: (pointer.y - s.y()) / oldScaleY,
        };

        s.scale({ x: newScaleX, y: newScaleY });

        const newPos = {
          x: pointer.x - mousePointTo.x * newScaleX,
          y: pointer.y - mousePointTo.y * newScaleY,
        };
        s.position(newPos);
      }

      s.batchDraw();
      setZoomScale(newScaleX); // Uwaga: Możesz potrzebować oddzielnych stanów dla scaleX i scaleY
    }
  }

  return (
    <Modal
      title="Dane wybranej połaci dachu"
      width={1200}
      centered
      open={open}
      onOk={() => cancelViewRoofPlane()}
      onCancel={() => cancelViewRoofPlane()}
      footer={[
        <Button key="back" onClick={cancelViewRoofPlane}>
          Anuluj
        </Button>,
        <Button type="primary" key="ok" onClick={cancelViewRoofPlane}>
          Ok
        </Button>,
      ]}
    >
      <StageParent ref={stageParent}>
        <div style={{ marginLeft: "8px", marginTop: "8px", position: "fixed", zIndex: "9" }}>
          <Switch checked={visibleDimensions} onChange={(v) => setVisibleDimensions(v)} />
          <span style={{ marginLeft: "8px" }}>Długości rzeczywiste</span>
        </div>

        <Stage
          ref={stage}
          height={700}
          width={dimStageParent.width}
          style={{ margin: "0", background: "#E8E8E8", borderRadius: "8px" }}
          onContextMenu={(e) => {
            e.evt.preventDefault();
          }}
          onWheel={zoomStage}
        >
          <Layer draggable x={getOffsetForDraw().x} y={getOffsetForDraw().y}>
            <Group>
              <Line
                points={getPointsForLineComponent(getPointsForPlane())}
                fill="#bed8bbcc"
                stroke="#000000cc"
                closed={true}
                strokeWidth={2 / zoomScale}
              />

              {rsoHoles &&
                rsoHoles?.map((o, i) => {
                  if (o.rsoPlaneId === rsoPlane?.id) {
                    return (
                      <Line
                        key={i}
                        points={getPointsForLineComponent(
                          getPointsForHole(
                            o,
                            rsoPlane?.angle || 0,
                            rsoPlane?.declineVectoor,
                            rsoPlane ? getFirstPointRSOPlane(rsoPlane) : undefined
                          )
                        )}
                        fill="#E8E8E8"
                        stroke="#000000cc"
                        closed={true}
                        strokeWidth={2 / zoomScale}
                      />
                    );
                  }
                })}

              {roofHoles &&
                roofHoles?.map((o, i) => {
                  if (o.roofPlaneId === roofPlane?.id) {
                    return (
                      <Line
                        key={i}
                        points={getPointsForLineComponent(
                          getPointsForHole(
                            o,
                            roofPlane?.angle || 0,
                            { x: 0, y: 0 },
                            roofPlane ? getFirstPointRoofPlane(roofPlane) : undefined
                          )
                        )}
                        fill="#E8E8E8"
                        stroke="#000000cc"
                        closed={true}
                        strokeWidth={2 / zoomScale}
                      />
                    );
                  }
                })}

              {visibleDimensions && showDimensions(getPointsForPlane())}

              {visibleDimensions &&
                rsoHoles &&
                rsoPlane &&
                rsoHoles.map((o, i) => {
                  if (o.rsoPlaneId === roofPlane?.id) {
                    showDimensions(
                      getPointsForHole(
                        o,
                        rsoPlane?.angle || 0,
                        rsoPlane?.declineVectoor,
                        getFirstPointRSOPlane(rsoPlane)
                      )
                    );
                  }
                })}

              {visibleDimensions &&
                roofHoles &&
                roofPlane &&
                roofHoles.map((o, i) => {
                  if (o.roofPlaneId === roofPlane?.id) {
                    return showDimensions(
                      getPointsForHole(o, roofPlane?.angle || 0, { x: 0, y: 0 }, getFirstPointRoofPlane(roofPlane))
                    );
                  }
                })}

              {/* <Circle x={0} y={0} radius={10} fill="blue" /> */}
              {/* <Circle x={getRoofCenter().x} y={getRoofCenter().y} radius={10} fill="red" /> */}
            </Group>
          </Layer>
        </Stage>
      </StageParent>

      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <span>Pole powierzchni netto:</span>
            <Tag style={{ margin: "0", marginLeft: "8px", padding: "8px", color: "#000" }} color="#51ff00">
              {isAutomatic() && roofPlane && (
                <b>
                  {getRoofPlaneArea(roofPlane, scale).toFixed(2)}
                  m²
                </b>
              )}
              {!isAutomatic() && rsoPlane && (
                <b>
                  {getRoofPlaneArea(rsoPlane, scale).toFixed(2)}
                  m²
                </b>
              )}
            </Tag>
          </div>

          <div>
            <span>Pole powierzchni brutto:</span>
            <Tag style={{ margin: "0", marginLeft: "8px", padding: "8px" }}>
              {roofPlane && (
                <b>
                  {isAutomatic() && roofPlane && (
                    <b>
                      {(
                        getRoofPlaneArea(roofPlane, scale) +
                        getSumHolesArea(
                          roofHoles?.filter((o) => o.roofPlaneId === roofPlane?.id),
                          scale,
                          roofPlane?.angle || 0
                        )
                      ).toFixed(2)}
                      m²
                    </b>
                  )}

                  {!isAutomatic() && rsoPlane && (
                    <b>
                      {(
                        getRoofPlaneArea(rsoPlane, scale) +
                        getSumHolesArea(
                          rsoHoles?.filter((o) => o.rsoPlaneId === rsoPlane?.id),
                          scale,
                          roofPlane?.angle || 0
                        )
                      ).toFixed(2)}
                      m²
                    </b>
                  )}
                </b>
              )}
            </Tag>
          </div>

          <div>
            <span>Pole powierzchni otworów:</span>
            <Tag style={{ margin: "0", marginLeft: "8px", padding: "8px" }}>
              {roofPlane && (
                <b>
                  {getSumHolesArea(
                    isAutomatic()
                      ? roofHoles?.filter((o) => o.roofPlaneId === roofPlane?.id)
                      : rsoHoles.filter((o) => o.rsoPlaneId === rsoPlane?.id),
                    scale,
                    isAutomatic() ? roofPlane?.angle || 0 : rsoPlane?.angle || 0
                  ).toFixed(2)}
                  m²
                </b>
              )}
            </Tag>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewRoofPlaneModal;

const StageParent = styled.div`
  margin-top: 16px;
`;

// import { Button, Checkbox, Form, Modal, Switch, Tag } from "antd";
// import Konva from "konva";
// import { KonvaEventObject } from "konva/lib/Node";
// import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
// import { Group, Layer, Line, Stage, Text } from "react-konva";
// import { Html } from "react-konva-utils";
// import styled from "styled-components";
// import {
//   getPointsRotatedRoofPartByPoints,
//   movePointsRoofPartToLeftCorner,
//   roundNumber,
// } from "../../../../../helpers/Calculator";
// import {
//   getEdgeLength,
//   getFullPointsById,
//   getRoofPlaneArea,
//   getTwoPoints3DLength,
//   middlePoint,
// } from "../../../../../helpers/Helpers";
// import { IPoint, IRoofHole, IRoofPlane, IRoofPoint } from "../../../../../models/Models";
// import { IRSO, IRSOEdge, IRSOHole, IRSOPoint } from "../steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

// const getPointsForLineComponent = (points: IPoint[]) => {
//   let arr = [];
//   for (const p of points) {
//     if (p) {
//       arr.push(p.x);
//       arr.push(p.y);
//     }
//   }
//   return arr;
// };

// function calculatePolygonArea(points: IPoint[], scale: number) {
//   var area = 0;
//   var j = points.length - 1;

//   for (var i = 0; i < points.length; i++) {
//     area += (points[j].x + points[i].x) * (points[j].y - points[i].y);
//     j = i;
//   }

//   return Math.abs(area / 2) / scale ** 2;
// }

// // Funkcja, która dostosuje punkty połaci do stage i wyśrodkuje obiekt
// const fitDrawToScreen = (points: IRoofPoint[], areaWidth: number, areaHeight: number) => {
//   // Zakładamy że min X i min Y wynoszą 0 (tak jest przesunięty obraz)
//   //   const minX = [...points].sort((a, b) => a.x - b.x)[0];
//   //   const minY = [...points].sort((a, b) => a.y - b.y)[0];

//   var scaleFactor = getNewScale(points, areaWidth, areaHeight);

//   const scaledPoints: IPoint[] = [];

//   for (const p of points) {
//     scaledPoints.push({ ...p, x: p.x * scaleFactor, y: p.y * scaleFactor });
//   }

//   const maxX = [...scaledPoints].sort((a, b) => b.x - a.x)[0];
//   const maxY = [...scaledPoints].sort((a, b) => b.y - a.y)[0];

//   const freeDistOnX = areaWidth - maxX.x; // Szerokość wolnej przestrzeni w osi x
//   const freeDistOnY = areaHeight - maxY.y; // Szerokość wolnej przestrzeni w osi y

//   const result: IPoint[] = []; // wynikowe punkty

//   for (const p of scaledPoints) {
//     result.push({ ...p, x: p.x + freeDistOnX / 2, y: p.y + freeDistOnY / 2 });
//   }

//   return result;
// };

// // Funkcja, która dostosuje punkty połaci do okna i wyśrodkuje obiekt
// const fitHoleToScreen = (
//   points: IRoofPoint[],
//   areaWidth: number,
//   areaHeight: number,
//   outlinePoints: IRoofPoint[]
// ) => {
//   var scaleFactor = getNewScale(outlinePoints, areaWidth, areaHeight);

//   // Przekalowane punkty na obwodzie -> potrzebne do ustalenia min i max X i Y
//   const scaledOutlinePoints: IPoint[] = [];
//   for (const p of outlinePoints) {
//     scaledOutlinePoints.push({ ...p, x: p.x * scaleFactor, y: p.y * scaleFactor });
//   }

//   // Przeskalowane punkty otworu
//   const scaledPoints: IPoint[] = [];
//   for (const p of points) {
//     scaledPoints.push({ ...p, x: p.x * scaleFactor, y: p.y * scaleFactor });
//   }

//   const maxX = [...scaledOutlinePoints].sort((a, b) => b.x - a.x)[0];
//   const maxY = [...scaledOutlinePoints].sort((a, b) => b.y - a.y)[0];

//   const freeDistOnX = areaWidth - maxX.x; // Szerokość wolnej przestrzeni w osi x
//   const freeDistOnY = areaHeight - maxY.y; // Szerokość wolnej przestrzeni w osi y

//   const result: IPoint[] = []; // wynikowe punkty

//   for (const p of scaledPoints) {
//     result.push({ ...p, x: p.x + freeDistOnX / 2, y: p.y + freeDistOnY / 2 });
//   }

//   return result;
// };

// const getNewScale = (points: IPoint[], areaWidth: number, areaHeight: number) => {
//   if (points) {
//     const maxX = [...points].sort((a, b) => b.x - a.x)[0];
//     const maxY = [...points].sort((a, b) => b.y - a.y)[0];

//     if (maxX && maxX.x >= areaWidth) {
//       const newWidth = 0.8 * areaWidth;
//       return newWidth / maxX.x;
//     }

//     if (maxY && maxY.y >= areaHeight) {
//       const newHeight = 0.8 * areaHeight;
//       return newHeight / maxY.y;
//     }
//   }

//   return 1;
// };

// type Props = {
//   open: boolean;
//   roofPlane: IRoofPlane | null;
//   roofHoles?: IRoofHole[];
//   roofPoints: IRoofPoint[];

//   rsoPlane: IRSO | null;
//   rsoPoints: IRSOPoint[];
//   rsoEdges: IRSOEdge[];
//   rsoHoles: IRSOHole[];

//   scale: number;
//   cancelViewRoofPlane: any;
// };

// const ViewRoofPlaneModal: FC<Props> = ({
//   open,
//   roofPlane,
//   roofHoles,
//   roofPoints,
//   scale,
//   cancelViewRoofPlane,
//   rsoPlane,
//   rsoPoints,
//   rsoEdges,
//   rsoHoles,
// }) => {
//   const [points, setPoints] = useState<IPoint[]>([]);
//   const [holePoints, setHolePoints] = useState<{ roofHole: IRoofHole; points: IPoint[] }[]>([]);

//   const [visibleDimensions, setVisibleDimensions] = useState<boolean>(true);

//   // Konfiguracja Stage
//   const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
//   const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
//   const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

//   const isAutomatic = () => {
//     return rsoEdges && rsoEdges.length > 0 ? false : true;
//   };

//   // Przypisanie wymiarów parent stage
//   useLayoutEffect(() => {
//     if (stageParent.current) {
//       setDimStageParent({
//         width: stageParent.current.offsetWidth,
//         height: stageParent.current.offsetHeight,
//       });
//     }
//   }, [stageParent.current]);

//   // WAŻNE -> DO PORPAWY OBLICZEŃ -> wymiary okna nie spą prawidłowe gdyż w punktach zaznaczają się takie jak na rzucie
//   // Podczas wydłużania na sztywno są tylko zablokowane najwyższe punkty na połaci a otwór podlega działaniom jak każdy inny punkt
//   // zablokowane powinny być tez punkty najwyższe otworu
//   useEffect(() => {
//     if (roofPlane && roofPoints) {
//       // 1. Bierzemy wszytskie punkty, które mają coś wspólnego z tą połacią czyli punkty obwodu i punkty wszystkich otwórów. Musi tak byc aby poprawnie dokonać obrotu
//       const outlinePointIds: string[] = roofPlane.pointIds as string[]; // id punktów na obwodzie połaci
//       let holePointIds: string[] = []; // id punktów należące do wszystkich otworów w połaci
//       const holesInPlane = roofHoles?.filter((x) => x.roofPlaneId === roofPlane.id); // Otwory należące do połaci
//       for (const h of holesInPlane as IRoofHole[]) {
//         holePointIds = [...holePointIds, ...(h.pointIds as string[])];
//       }

//       const allPointIds = [...outlinePointIds, ...holePointIds]; // Lista id wszystkich punktów na połaci (razem z otworami)

//       // console.log("OPOPOPOFF", getFullPointsById(allPointIds, roofPoints));

//       // 2. Obracamy wszystkie punkty
//       const rotatedPoints: IRoofPoint[] = getPointsRotatedRoofPartByPoints(
//         getFullPointsById(allPointIds, roofPoints)
//       );

//       // 2.1 Wydłużamy punkty zgodnie z kątem nachylenia połaci (aby uzyskać rzeczywtisty obraz czyli prostopadły widok na połać) WAŻNE!!!
//       // 2.2.1 Pobieramy najwyższy punkt (1 lub kilka jeśli sa na tym samym poziomie y). Te punkty pozostają bez zmian na rysunku
//       const pointWithMinY = [...rotatedPoints].sort((a, b) => a.y - b.y)[0];
//       const allPointsWithMinY = rotatedPoints.filter((o) => roundNumber(o.y) === roundNumber(pointWithMinY.y));

//       // 2.2.2 Obliczamy mnożnik dla wydłużenia pozycji y
//       const alphaInRadians = (roofPlane.angle * Math.PI) / 180;
//       const cosAlpha = Math.cos(alphaInRadians);
//       const factor = 1 / cosAlpha;

//       const minY = pointWithMinY.y;

//       // 2.2.3 Mnożymy pozycje y wszystkich punktów poza tymi z listy maxY
//       const realRotatedPoints: IRoofPoint[] = [];
//       for (const point of rotatedPoints) {
//         if (!allPointsWithMinY.some((x) => x.id === point.id)) {
//           const dist = point.y - minY;
//           const newDist = dist * factor;

//           realRotatedPoints.push({ ...point, y: minY + newDist });
//         } else {
//           realRotatedPoints.push({ ...point });
//         }
//       }

//       // 3. Przemieszczamy obrócony rysunek do lewego górnego rogu
//       const movedPoints: IRoofPoint[] = movePointsRoofPartToLeftCorner(realRotatedPoints, 0);

//       // 4. Gotowe obrócone punkty dla obwodu połaci
//       const outlinePoints: IRoofPoint[] = [];
//       for (const id of outlinePointIds) {
//         const p = movedPoints.find((x) => x.id === id);
//         if (p) outlinePoints.push(p);
//       }

//       // 5. Wydzielamy gotowe punkty dla obwodu połaci i dopasowujemy do ekranu (status = 1 to punkty na obwodzie)
//       const outline = fitDrawToScreen(outlinePoints, dimStageParent.width, dimStageParent.height);
//       setPoints(outline); // Przypisanie gotowych punktów do state

//       //   console.log("POLE", calculatePolygonArea(outline, scale)); // Do testu czy w modalu jest dobrze narysowany rzeczywisty kład połaci. Pole zgadza się z tym z kalkulatora

//       // 6. Przygotowanie obiektów dla otworów wraz w gotowymi i obróconymi punktami
//       const holeListWithPoints: { roofHole: IRoofHole; points: IRoofPoint[] }[] = [];
//       for (const h of holesInPlane as IRoofHole[]) {
//         let pointsInHole: IRoofPoint[] = [];
//         for (const id of h.pointIds as string[]) {
//           const p = movedPoints.find((x) => x.id === id);
//           if (p) pointsInHole.push(p);
//         }
//         holeListWithPoints.push({ roofHole: h, points: pointsInHole });
//       }

//       // 7. Wydzielamy gotowe punkty dla otwórów i dopasowujemy do ekranu (status = 1 to punkty na obwodzie)
//       const fitHoleObjects = [];
//       for (const h of holeListWithPoints) {
//         const fitPoints = fitHoleToScreen(h.points, dimStageParent.width, dimStageParent.height, outlinePoints);
//         fitHoleObjects.push({ roofHole: h.roofHole, points: fitPoints });
//       }
//       setHolePoints(fitHoleObjects);
//     }
//   }, [open, roofPlane, roofPoints, roofHoles, dimStageParent]);

//   const getSegments = (points: IPoint[]) => {
//     const segments: { startPoint?: IPoint; endPoint?: IPoint; length?: number; setPoint: IPoint }[] = [];

//     if (points && points.length > 0) {
//       for (let i = 0; i < points.length - 1; i++) {
//         const startPoint = roofPoints.find((o) => o.id === points[i].id);
//         const endPoint = roofPoints.find((o) => o.id === points[i + 1].id);
//         segments.push({
//           startPoint: points[i],
//           endPoint: points[i + 1],
//           length: getTwoPoints3DLength(startPoint as IPoint, endPoint as IPoint, scale),
//           setPoint: middlePoint(points[i], points[i + 1]),
//         });
//       }

//       // Dodanie odcinka między ostatnim punktem a pierwszym (aby utworzyć polygon)
//       const sp = roofPoints.find((o) => o.id === points[points.length - 1].id);
//       const ep = roofPoints.find((o) => o.id === points[0].id);
//       segments.push({
//         startPoint: points[points.length - 1],
//         endPoint: points[0],
//         length: getTwoPoints3DLength(sp as IPoint, ep as IPoint, scale),
//         setPoint: middlePoint(points[points.length - 1], points[0]),
//       });
//     }

//     return segments;
//   };

//   const showDimensions = () => {
//     const segments: { startPoint?: IPoint; endPoint?: IPoint; length?: number; setPoint: IPoint }[] = [];

//     const segmentPoints = getSegments(points);
//     for (const s of segmentPoints) {
//       segments.push(s);
//     }

//     for (const h of holePoints) {
//       const segmentPoints = getSegments(h.points);
//       for (const s of segmentPoints) {
//         segments.push(s);
//       }
//     }

//     const result = [];

//     let k = 0;
//     for (const line of segments) {
//       result.push(
//         <Text
//           key={k}
//           fontStyle="bold"
//           fontSize={12}
//           x={line.setPoint.x}
//           y={line.setPoint.y}
//           text={`${line.length?.toFixed(2)}`}
//         />
//       );
//       k++;
//     }

//     return result;
//   };

//   const getAllHolesInPlaneArea = (
//     holes: {
//       roofHole: IRoofHole;
//       points: IPoint[];
//     }[],
//     scale: number
//   ) => {
//     let area = 0;

//     for (const h of holes) {
//       area += calculatePolygonArea(h.points, scale);
//     }

//     return area;
//   };

//   return (
//     <Modal
//       title="Dane wybranej połaci dachu"
//       width={1000}
//       centered
//       open={open}
//       onOk={() => cancelViewRoofPlane()}
//       onCancel={() => cancelViewRoofPlane()}
//       footer={[
//         <Button key="back" onClick={cancelViewRoofPlane}>
//           Anuluj
//         </Button>,
//         <Button type="primary" key="ok" onClick={cancelViewRoofPlane}>
//           Ok
//         </Button>,
//       ]}
//     >
//       <StageParent ref={stageParent}>
//         <Stage
//           ref={stage}
//           height={500}
//           width={dimStageParent.width}
//           style={{ margin: "0", background: "#E8E8E8", borderRadius: "8px" }}
//           onContextMenu={(e) => {
//             e.evt.preventDefault();
//           }}
//           draggable
//         >
//           <Layer>
//             <Html>
//               <div style={{ marginLeft: "8px", marginTop: "8px" }}>
//                 <Switch checked={visibleDimensions} onChange={(v) => setVisibleDimensions(v)} />
//                 <span style={{ marginLeft: "8px" }}>Długości rzeczywiste</span>
//               </div>
//             </Html>
//             <Group>
//               <Line
//                 points={getPointsForLineComponent(points)}
//                 fill="#bed8bbcc"
//                 stroke="#000000cc"
//                 closed={true}
//                 strokeWidth={2}
//               />

//               {holePoints?.map((o, i) => {
//                 return (
//                   <Line
//                     key={i}
//                     points={getPointsForLineComponent(o.points)}
//                     fill="#E8E8E8"
//                     stroke="#000000cc"
//                     closed={true}
//                     strokeWidth={2}
//                   />
//                 );
//               })}

//               {visibleDimensions && showDimensions()}
//             </Group>
//           </Layer>
//         </Stage>
//       </StageParent>

//       <div style={{ marginTop: "16px", marginBottom: "16px" }}>
//         <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
//           <div>
//             <span>Pole powierzchni netto:</span>
//             <Tag style={{ margin: "0", marginLeft: "8px", padding: "8px", color: "#000" }} color="#51ff00">
//               {roofPlane && <b>{getRoofPlaneArea(roofPlane, scale)} m²</b>}
//             </Tag>
//           </div>

//           <div>
//             <span>Pole powierzchni brutto:</span>
//             <Tag style={{ margin: "0", marginLeft: "8px", padding: "8px" }}>
//               {roofPlane && (
//                 <b>
//                   {(getRoofPlaneArea(roofPlane, scale) + getAllHolesInPlaneArea(holePoints, scale)).toFixed(2)} m²
//                 </b>
//               )}
//             </Tag>
//           </div>

//           <div>
//             <span>Pole powierzchni otworów:</span>
//             <Tag style={{ margin: "0", marginLeft: "8px", padding: "8px" }}>
//               {roofPlane && <b>{getAllHolesInPlaneArea(holePoints, scale).toFixed(2)} m²</b>}
//             </Tag>
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default ViewRoofPlaneModal;

// const StageParent = styled.div`
//   margin-top: 16px;
// `;
