import { Button, Card, Input, Table, Tag } from "antd";
import { all } from "axios";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import MaterialCategoryCard from "../../components/MaterialCategoryCard";
import { ICompany, IProduct, IProductCategory, IRootState } from "../../models/Models";
import { useAppSelector } from "../../redux/hooks";
import debounce from "lodash/debounce";
import { PRIMARY_COLOR } from "../../theme";

const BAD = "#ff0000";
const GOOD = "#7c71f7";

const searchResultsColumns = [
  {
    title: "Nazwa produktu",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => a.nameString.localeCompare(b.nameString),
  },
  {
    title: "Producent",
    dataIndex: "company",
    key: "company",
    width: "200px",
    align: "center" as "center",
    sorter: (a: any, b: any) => a.company.localeCompare(b.company),
    render: (company: string) =>
      company && (
        <Tag color={PRIMARY_COLOR} style={{ textAlign: "center", width: "100%" }}>
          {company}
        </Tag>
      ),
  },
  {
    title: "Kategoria",
    dataIndex: "category",
    key: "category",
    align: "center" as "center",
    width: "150px",
  },
  {
    title: "Kod producenta",
    dataIndex: "producerId",
    key: "producerId",
    align: "center" as "center",
    width: "150px",
    sorter: (a: any, b: any) => a.producerId.localeCompare(b.producerId),
    render: (producerId: string) =>
      producerId && <Tag style={{ textAlign: "center", width: "100%" }}>{producerId}</Tag>,
  },
  {
    title: "Kod sklepu",
    dataIndex: "externalId",
    key: "externalId",
    align: "center" as "center",
    width: "150px",
    sorter: (a: any, b: any) => a.externalId.localeCompare(b.externalId),
    render: (externalId: string) =>
      externalId && <Tag style={{ textAlign: "center", width: "100%" }}>{externalId}</Tag>,
  },
  {
    title: "Edytuj",
    dataIndex: "action",
    key: "action",
    align: "center" as "center",
    width: "100px",
  },
];

export default function MaterialsPage() {
  const navigate = useNavigate();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const products = useAppSelector((state: IRootState) => state.products);
  const productModels = useAppSelector((state: IRootState) => state.productModels);

  const productsRef = useRef(products);
  const productsCategoriesRef = useRef(productCategories);
  const companiesRef = useRef(companies);
  const productColorsRef = useRef(productColors);
  const productModelsRef = useRef(productModels);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IProduct[]>([]);

  useEffect(() => {
    if (productsRef.current !== products) {
      productsRef.current = products;
    }
  }, [products]);

  useEffect(() => {
    if (productsCategoriesRef.current !== productCategories) {
      productsCategoriesRef.current = productCategories;
    }
  }, [productCategories]);

  useEffect(() => {
    if (companiesRef.current !== companies) {
      companiesRef.current = companies;
    }
  }, [companies]);

  useEffect(() => {
    if (productColorsRef.current !== productColors) {
      productColorsRef.current = productColors;
    }
  }, [productColors]);

  useEffect(() => {
    if (productModelsRef.current !== productModels) {
      productModelsRef.current = productModels;
    }
  }, [productModels]);

  // Tworzymy debounced wersję funkcji search
  const debouncedSearch = useCallback(
    debounce((text) => {
      search(text);
    }, 500),
    []
  );

  // Efekt, który obserwuje zmiany searchTerm i wywołuje debouncedSearch
  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch, products, productCategories, companies, productColors, productModels]);

  const search = (text: string) => {
    let result: IProduct[] = [];

    if (text.length > 0) {
      // console.log("searching for: ", text);

      // Dzielimy wprowadzony tekst na słowa, ignorując wielkość liter
      // Używamy wyrażenia regularnego, aby podzielić tekst według spacji, przecinka, średnika lub dwukrotnego wystąpienia spacji
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      for (const p of productsRef.current) {
        const categoryName = productsCategoriesRef.current
          .find((x) => x.id === p.productCategoryId)
          ?.name.toLowerCase() as string;
        const colorName = productColorsRef.current
          .find((x) => x.id === p.productColorId)
          ?.name.toLowerCase() as string;
        const companyName = companiesRef.current.find((x) => x.id === p.companyId)?.name.toLowerCase() as string;
        const productModelName = productModelsRef.current
          .find((x) => x.id === p.productModelId)
          ?.name.toLowerCase() as string;

        // Sprawdzamy, czy każde słowo występuje w którymkolwiek z ciągów znaków
        const matched = inputWords.every((word) => {
          const inName = p.name.toLowerCase().includes(word);
          const inProducer = p.producerId.toLowerCase().includes(word);
          const inCoating = p.coating.toLowerCase().includes(word);
          const inCategory = categoryName ? categoryName.includes(word) : false;
          const inColor = colorName ? colorName.includes(word) : false;
          const inCompany = companyName ? companyName.includes(word) : false;
          const inModel = productModelName ? productModelName.includes(word) : false;
          return inName || inProducer || inCoating || inCategory || inColor || inCompany || inModel;
        });

        if (matched) {
          result = [...result, p];
        }
      }
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const getProductsData = (products: IProduct[], categories: IProductCategory[], companies: ICompany[]) => {
    const data: {
      key: string;
      name: JSX.Element;
      company: string;
      category: string;
      producerId: string;
      externalId: string;
      action: JSX.Element;
    }[] = [];
    for (const p of products) {
      let productName = `${p.name} ${productModels.find((x) => x.id === p.productModelId)?.name} (${
        productColors.find((x) => x.id === p.productColorId)?.name
      }`;
      if (p.coating.length > 0) {
        productName += `, ${p.coating}`;
      }
      productName += ")";

      data.push({
        key: p.id,
        name: <span>{productName}</span>,
        company: companies.find((x) => x.id === p.companyId)?.name as string,
        category: categories.find((x) => x.id === p.productCategoryId)?.name as string,
        producerId: p.producerId,
        externalId: p.externalId,
        action: <Button onClick={() => navigate(`/materialy/${p.id}/edytuj`)}>Edytuj</Button>,
      });
    }
    return data;
  };

  return (
    <Container>
      <SearchSection title="Wyszukaj produkt">
        <SearchInput
          placeholder="Wyszukaj produkt (nazwa produktu, producenta, kategorii, kolor produktu)..."
          onChange={(e) => setSearchTerm(e.target.value)}
          allowClear
        />

        {searchTerm && searchTerm.length > 0 && searchResults && searchResults.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "32px",
              color: "#616161",
            }}
          >
            Brak produktów spełniających kryteria
          </div>
        )}

        {searchResults && searchResults.length > 0 && (
          <SearchResults>
            <Table
              columns={searchResultsColumns}
              dataSource={getProductsData(searchResults, productCategories, companies)}
              pagination={{ pageSize: 5 }}
              rowKey="key"
            />
          </SearchResults>
        )}
      </SearchSection>

      {[...productCategories.filter((o) => o.code !== "labour")]
        .sort((a, b) => a.index - b.index)
        .map((o) => (
          <MaterialCategoryCard key={o.id} productCategory={o} />
        ))}

      <Section onClick={() => navigate(`/import-materialow`)}>
        <ImportContent>Zarządzaj produktami</ImportContent>
      </Section>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`;

const Section = styled(Card)`
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #afafaf;
    color: #ffffff;
  }
`;

const SearchSection = styled(Card)`
  width: 100%;
`;

const ImportContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const SearchInput = styled(Input)``;

const SearchResults = styled.div`
  margin-top: 32px;
`;
