import { InboxOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Statistic,
  Table,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { set } from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../api/ApiClient";
import PageHeader from "../../../layout/components/PageHeader";
import { UploadRequestOption } from "../../contractors/components/ContractorDataTransfer";
import UpdateProductListPosition from "./UpdateProductListPosition";

export interface IImportProductsRequestModel {
  file: any;
  companyId: string;
}

export interface IProductToUpdatePrice {
  productId: string;
  productName: string;
  salesForms: ISalesFormToUpdatePrice[];
}

export interface ISalesFormToUpdatePrice {
  salesFormId: string;
  salesFormName: string;
  oldPrice: number;
  newPrice: number;
}

type Props = {};

const ImportUpdateProductsFromExcel: FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [productsToUpdate, setProductsToUpdate] = useState<IProductToUpdatePrice[]>([]);
  const [updatedProductCount, setUpdatedProductCount] = useState<number>(0);
  const [existingProductsCount, setExistingProductsCount] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const fileUploadProps = {
    name: "file",
    action: "/DataTransfer/verifyUpdatePrices",
    customRequest(options: UploadRequestOption) {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();

      formData.append("file", file); // Dołącz plik

      setLoading(true);

      client
        .post(fileUploadProps.action, formData, {
          headers: {
            ...client.defaults.headers.common, // Zachowaj domyślne nagłówki
            "Content-Type": "multipart/form-data", // Nadpisz tylko Content-Type
          },
        })
        .then((response) => {
          if (onSuccess) {
            if (response.data.productsToUpdatePrice.length === 0) {
              // console.log(response);
              message.error(
                "Błędny plik lub brak produktów do aktualizacji cen w pliku Excel! " + response.data,
                15
              );
            } else {
              message.success("Pomyślnie wczytano produkty do aktualizacji cen z pliku Excel.", 5);

              setProductsToUpdate(response.data.productsToUpdatePrice);
              setUpdatedProductCount(response.data.updatedProductCount);
              setExistingProductsCount(response.data.existingProductsCount);
            }

            setLoading(false);
          }
        })
        .catch((error) => {
          if (onError) {
            console.log(error);
            message.error(
              "Błędny plik lub brak produktów do aktualizacji cen w pliku Excel! " + error?.response?.data,
              15
            );

            setLoading(false);
          }
        });
    },
  };

  const confirmUpdatePrices = async () => {
    try {
      const model = productsToUpdate;

      const response = await client.post("/DataTransfer/updatePrices", model);
      if (response.status === 200) {
        const data = response.data;
        message.success(`Pomyślnie zaktualizowano ceny ${data} produktów w bazie danych.`, 5);
      } else {
        message.error("Wystąpił błąd podczas aktualizacji cen produktów w bazie danych!", 8);
      }
    } catch (err) {
      message.error("Wystąpił błąd podczas aktualizacji cen produktów w bazie danych!", 8);
    }
  };

  return (
    <Container>
      <PageHeader
        title={"Aktualizacja cen z pliku Excel"}
        extra={
          productsToUpdate.length > 0 && (
            <div style={{ display: "flex", gap: "16px" }}>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setProductsToUpdate([]);
                }}
              >
                Anuluj
              </Button>
              <Popconfirm
                title="Aktualizacja cen produktów"
                description="Aktualizacja cen produktów z pliku Excel spowoduje zmianę cen w bazie danych. Czy na pewno chcesz kontynuować?"
                onConfirm={() => {
                  confirmUpdatePrices();
                }}
                okText="Tak"
                cancelText="Nie"
                placement="topRight"
              >
                <Button type="primary">Aktualizuj ceny produktów</Button>
              </Popconfirm>
            </div>
          )
        }
      />
      <>
        {productsToUpdate.length > 0 && (
          <>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px" }}>
              <Card style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Statistic
                  title="Ilość pozycji z nowa ceną w pliku excel"
                  value={updatedProductCount}
                  valueStyle={{ textAlign: "center" }}
                />
              </Card>
              <Card
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: existingProductsCount !== updatedProductCount ? "red" : "#34fc66",
                }}
              >
                <Statistic
                  title="Ilość istniejących produktów w aplikacji (bazie danych)"
                  value={existingProductsCount}
                  valueStyle={{ textAlign: "center" }}
                />
              </Card>
            </div>
            {existingProductsCount !== updatedProductCount ? (
              <Alert
                message="Uwaga!"
                description={
                  <div>
                    Ilość pozycji z nową ceną w pliku Excel nie zgadza się z ilością istniejących produktów w
                    aplikacji.
                    <br />
                    Oznacza to, że przeprowadzenie aktualizacji cen produktów może nie dotyczyć wszystkich
                    produktów.
                    <br />
                    Sprawdź plik Excel czy wszystkie produkty mają dobrze przypisaną cenę - sprawdź wszystkie formy
                    sprzedażowe (Struktura musi zgadzać się z szablonem pliku Excel).
                  </div>
                }
                type="error"
                showIcon
              />
            ) : (
              <Alert
                message="Wszystko jest ok!"
                description={
                  <div>
                    Ilość pozycji z nową ceną w pliku Excel zgadza się z ilością istniejących produktów w
                    aplikacji.
                    <br />
                    Oznacza to, że aktualizacja cen produktów może być przeprowadzona bez problemów.
                  </div>
                }
                type="success"
                showIcon
              />
            )}
          </>
        )}
      </>

      <Section loading={loading}>
        {productsToUpdate.length === 0 ? (
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Dragger {...fileUploadProps} showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Kliknij lub przenieś plik EXCEL z produtami + nowe ceny</p>
                <p className="ant-upload-hint">
                  Wybierz tylko jeden plik EXCEL. Plik musi mieć strukturę zgodną z wymaganiami.
                </p>
              </Dragger>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <List
                itemLayout="horizontal"
                dataSource={productsToUpdate}
                pagination={{
                  pageSize: 50,
                }}
                renderItem={(item, i) => (
                  <List.Item key={item.productId}>
                    <UpdateProductListPosition key={i} productToUpdate={item} />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}
      </Section>
    </Container>
  );
};
export default ImportUpdateProductsFromExcel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)``;
