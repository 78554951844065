import { ArrowsAltOutlined, CloseOutlined, RedoOutlined, ShrinkOutlined, UndoOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, Col, Divider, Form, Radio, Row, Tooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import { calculateRoof } from "../../../../../../api/ApiRoof";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { EdgeType } from "../../../../../../models/Enums";
import { ICalculationPlane, ICalculationPoint, ICalculationRequest } from "../../../../../../models/Models";
import {} from "../../../../../../redux/drawing/baysSlice";
import {
  clearConnectPlanesState,
  updateConnectPlanesState,
} from "../../../../../../redux/drawing/connectPlanesSlice";
import {
  IOutlineEdge,
  IOutlinePoint,
  regenerateEdgesForOutline,
  setOutlineEdges,
} from "../../../../../../redux/drawing/outlineSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { PRIMARY_COLOR } from "../../../../../../theme";
import { determineWindingOrder } from "../../others/RoofPlane";

type Props = {
  close: () => void;
  scale: number;
};

const PanelFlowConnectPlanes: FC<Props> = ({ close, scale }) => {
  const dispatch = useAppDispatch();

  const connectPlanes = useAppSelector((state) => state.connectPlanes); // Redux połączenia połaci

  const roofPlanes = useAppSelector((state) => state.roofPlanes); // Lista połaci
  const roofEdges = useAppSelector((state) => state.roofEdges); // Lista krawędzi
  const roofPoints = useAppSelector((state) => state.roofPoints); // Lista punktów

  const outline = useAppSelector((state) => state.outline); // Kontury dachu
  const roofContourNumber = useAppSelector((state) => state.roofContourNumber); // Numer konturu dachu - aktualny

  // Czyścimy dane podczas uruchomienia komponentu
  useEffect(() => {
    dispatch(clearConnectPlanesState());
  }, []);

  // Ustalamy kąt nachylenia dla kierunku wektora spadku gdy zmieniaja sie wybrane połacie
  useEffect(() => {
    // console.log("Refresh selectedPlaneIds");

    // Pobieramy wszystkie zaznaczone połacie
    const selectedPlanes = roofPlanes.filter((plane) => connectPlanes.selectedPlaneIds.includes(plane.id));
    // console.log("selectedPlanes", selectedPlanes);

    // Na początek działanie tylko dla pierwszej zaznaczonej połaci
    if (selectedPlanes.length == 1) {
      const plane = selectedPlanes[0];
      // Pobieramy krawędzie wszystkie dla połaci
      const allEdgesInPlane = roofEdges.filter((edge) => plane.edgeIds?.includes(edge.id)) || [];

      // Sprawdzamy czy są jakieś krawędzie okapu
      const eaveEdges = allEdgesInPlane.filter((edge) => edge.type === EdgeType.Eaves);

      // Jeśli sa okapy o wybieramy pierwszy z nich i pobieramy startPoint i endPoint dla nich
      if (eaveEdges.length > 0) {
        const eaveEdge = eaveEdges[0];
        const startPoint = roofPoints.find((point) => point.id === eaveEdge.startPointId);
        const endPoint = roofPoints.find((point) => point.id === eaveEdge.endPointId);

        if (startPoint && endPoint) {
          // Obliczamy kąt nachylenia dla kierunku wektora spadku
          let angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x) * (180 / Math.PI);

          // TYMCZASOWO
          angle += 180;

          if (angle != undefined && connectPlanes.angle != angle) {
            // console.log("angle", angle);
            // Aktualizujemy kąt
            dispatch(updateConnectPlanesState({ ...connectPlanes, angle: angle }));
          }
        }
      }
    }
  }, [connectPlanes.selectedPlaneIds]);

  // Wywołanie funkcji połączenia połaci - do API
  const connectSelectedPlanes = () => {
    // const angle = connectPlanes.angle;

    // const radian = angle * (Math.PI / 180);
    // const x = Math.cos(radian); // Dla kąta 0, cos(0) = 1
    // const y = Math.sin(radian); // Dla kąta 0, sin(0) = 0

    // // Ustawianie vectorSlope na podstawie kąta
    // const vectorSlope = {
    //   startPoint: { x: 0, y: 0 },
    //   endPoint: { x: x, y: y }, // Dla kąta 0, to będzie { x: 1, y: 0 }
    // };

    // // console.log("vectorSlope", vectorSlope);

    // // Ponieważ oczekujemy, że vectorNormal będzie miał te same wartości co vectorSlope
    // // Dla kąta 0, możemy po prostu skopiować wartości bez dodatkowych transformacji
    // const vectorNormal = { ...vectorSlope.endPoint };

    // // Teraz pobieramy listę wybranych połaci
    // const selectedPlanes = roofPlanes.filter((plane) => connectPlanes.selectedPlaneIds.includes(plane.id));

    // // Wybieramy odpowiednie outline (bo może być kilka na rysunku
    // const selectedOutline = outline.find((o) => o.roofNumber === roofContourNumber.index);

    // // Pobieramy wszystkie krawędzie dla wybranego konturu
    // const edgesForContour = selectedOutline?.edges || [];

    // const eaveEdgesToCalc = [];

    // // console.log("roofPoints", roofPoints);

    // // console.log("roofEdges", roofEdges);

    // const allEaveEdges = roofEdges.filter((edge) => edge.type === EdgeType.Eaves);

    // // console.log("allEaveEdges", allEaveEdges);

    // // console.log("selectedOutline", selectedOutline);

    // let j = 1;
    // for (const plane of selectedPlanes) {
    //   const edgeIds = plane.edgeIds || [];
    //   const eaveEdges = roofEdges.filter((edge) => edgeIds.includes(edge.id) && edge.type === EdgeType.Eaves);

    //   // console.log("eaveEdges in plane", eaveEdges);

    //   // Dodajemy wszystki bo moze byc wiecej niz 1 krawedz okapu
    //   for (let eaveEdge of eaveEdges) {
    //     // Znaleźć numberId w edgesForContour dla eaveEdge

    //     // Pobieramy id z edgesForContour
    //     const newCalcId = edgesForContour.findIndex((e) => e.numberId === j);

    //     // eaveEdge = { ...eaveEdge, calcId: newCalcId };
    //     eaveEdgesToCalc.push(eaveEdge);
    //   }
    //   j++;
    // }

    // // console.log("eaveEdgesToCalc", eaveEdgesToCalc);

    // // Pobieramy wszystkie indeksy zaznaczonych edges
    // const indexes: number[] = [];
    // for (const selectedEdge of eaveEdgesToCalc) {
    //   indexes.push(selectedEdge.calcId);
    // }

    // // Sortowanie indeksów od najmniejszych
    // indexes.sort((a, b) => a - b);

    // const points: ICalculationPoint[] = [];
    // for (const o of outline) {
    //   if (o.points.length > 0) {
    //     for (const p of o.points) {
    //       points.push({ x: p.x, y: p.y, z: p.z ? p.z : 0 });
    //     }
    //   }
    // }

    // let planes: ICalculationPlane[] = [];

    // // console.log("indexes", indexes);

    // // Tworzenie grupowanego obiektu plane, jeśli istnieją indeksy do grupowania
    // if (indexes.length > 0) {
    //   const groupedEdges = indexes.map((index) => edgesForContour[index]);
    //   const plane: ICalculationPlane = {
    //     index: selectedOutline?.roofNumber as number,
    //     edgeCount: groupedEdges.length,
    //     // x: vectorNormal.x,
    //     // y: vectorNormal.y,
    //     x: -1,
    //     y: 0,
    //     angle: 40, // Przykładowa wartość, dostosuj zgodnie z potrzebami
    //     dH: 0,
    //   };
    //   // Umieszczanie grupowanego plane na początku listy planes w miejscu pierwszego zgrupowanego indeksu
    //   planes[indexes[0]] = plane;
    // }

    // // Tworzenie obiektów plane dla pozostałych krawędzi
    // edgesForContour.forEach((edge, i) => {
    //   if (!indexes.includes(i)) {
    //     const plane: ICalculationPlane = {
    //       index: selectedOutline?.roofNumber as number,
    //       edgeCount: edge.points.length - 1,
    //       x: 0,
    //       y: 0,
    //       angle: edge.slope as number,
    //       dH: 0,
    //     };
    //     planes[i] = plane;
    //   }
    // });

    // // Usuwamy z listy null i konwertujemy ją na typ ICalculationPlane[]
    // planes = planes.filter((plane) => plane !== null) as ICalculationPlane[];

    // const calculationRequestModel: ICalculationRequest = {
    //   points: points,
    //   planes: planes,
    // };

    // // Aktualizacja outline

    // // console.log("indexes", indexes);

    // // const selectedEdges = indexes.map((index) => edgesForContour[index]).map((e) => e.id); // Tylko lista id
    // // console.log("selectedEdges", selectedEdges);
    // // dispatch(regenerateEdgesForOutline(selectedEdges));

    // // Zrobić dobr aaktualizację OUTLINES

    // // dispatch(calculateRoof(calculationRequestModel));

    // Określamy kierunek lewy czy prawy

    // Aktualne outline
    const selectedOutline = outline.find((x) => x.roofNumber === roofContourNumber.index);
    const selectedOutlinePoints: IOutlinePoint[] = selectedOutline?.points || [];
    const clockwise = determineWindingOrder(selectedOutlinePoints) === "clockwise";

    let angle = connectPlanes.angle;
    if (clockwise) {
      angle = 180 + angle;
    }

    const radian = angle * (Math.PI / 180);
    const x = Math.cos(radian); // Dla kąta 0, cos(0) = 1
    const y = Math.sin(radian); // Dla kąta 0, sin(0) = 0

    // Ustawianie vectorSlope na podstawie kąta
    const vectorSlope = {
      startPoint: { x: 0, y: 0 },
      endPoint: { x: x, y: y }, // Dla kąta 0, to będzie { x: 1, y: 0 }
    };

    const vectorNormal = { ...vectorSlope.endPoint };
    const vectArr = [vectorNormal.x, vectorNormal.y]; // Jako tablica x i y

    dispatch(regenerateEdgesForOutline(vectArr));
  };

  const isActiveConnectButton = connectPlanes.selectedPlaneIds.length > 1;

  return (
    <Container title="Łączenie połaci" size="small" bodyStyle={{ padding: "0px" }}>
      <SimpleContent size="small" headStyle={{ backgroundColor: "#f5f5f5" }}>
        {connectPlanes.selectedPlaneIds.length < 2 ? (
          <Alert showIcon type="warning" message="Zaznacz na rysunku co najmniej dwie połacie do połączenia!" />
        ) : (
          <>
            <Alert
              showIcon
              type="info"
              message="Strzałka na rysunku wskazuje kierunek spadku dla generowanej połaci."
            />
            <Alert
              showIcon
              type="info"
              style={{ marginTop: "8px" }}
              message="Obracaj kąt aby dostosować kierunek spadku do rzeczywistego kierunku spadku dachu."
            />
            <Form.Item label="Kierunek" style={{ margin: "0px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", gap: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", fontSize: "18px" }}>∢[°]</div>
                <DomaInputNumber
                  // disabled
                  value={Number(connectPlanes.angle.toFixed(2) || 0)}
                  onChange={(value) => {
                    // Sprawdź, czy wartość jest pusta lub niezdefiniowana, jeśli tak, ustaw na 0
                    const updatedValue = value === "" || value === undefined ? 0 : value;
                    dispatch(updateConnectPlanesState({ ...connectPlanes, angle: Number(updatedValue) }));
                  }}
                  allowNegative
                  style={{ width: "100%" }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", gap: "8px", width: "100%" }}>
                  <Tooltip
                    zIndex={9999999}
                    title={
                      <div style={{ textAlign: "center" }}>
                        Obróć kierunek spadku w lewo o <b>0,1°</b>
                      </div>
                    }
                    placement="topRight"
                    color={PRIMARY_COLOR}
                  >
                    <Button
                      onClick={() => {
                        dispatch(
                          updateConnectPlanesState({
                            ...connectPlanes,
                            angle: connectPlanes.angle - 1,
                          })
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      <UndoOutlined />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    zIndex={9999999}
                    title={
                      <div style={{ textAlign: "center" }}>
                        Obróć kierunek spadku w prawo o <b>0,1°</b>
                      </div>
                    }
                    placement="topRight"
                    color={PRIMARY_COLOR}
                  >
                    <Button
                      onClick={() => {
                        dispatch(
                          updateConnectPlanesState({
                            ...connectPlanes,
                            angle: connectPlanes.angle + 1,
                          })
                        );
                      }}
                      style={{ width: "100%" }}
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </Form.Item>
          </>
        )}
        {/* 
        <Form.Item label="Wybierz połacie, które chcesz połączyć" style={{ margin: "8px" }}>
          <StyledCheckboxGroup style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {roofPlanes.map((plane) => (
              <Button
                key={plane.id}
                type={connectPlanes.selectedPlaneIds.includes(plane.id) ? "primary" : "default"}
                onClick={() => {
                  dispatch(
                    updateConnectPlanesState({
                      ...connectPlanes,
                      selectedPlaneIds: connectPlanes.selectedPlaneIds.includes(plane.id)
                        ? connectPlanes.selectedPlaneIds.filter((id) => id !== plane.id)
                        : [...connectPlanes.selectedPlaneIds, plane.id],
                    })
                  );
                }}
              >
                {plane.id}
              </Button>
            ))}
          </StyledCheckboxGroup>
        </Form.Item> */}
      </SimpleContent>
      <Row style={{ display: "flex", justifyContent: "space-between", gap: "8px", padding: "8px" }}>
        <Button
          disabled={!isActiveConnectButton}
          type="primary"
          onClick={() => {
            connectSelectedPlanes();
            dispatch(clearConnectPlanesState());
          }}
        >
          Połącz zaznaczone połacie
        </Button>
        <Button
          type="default"
          onClick={() => {
            close();
          }}
        >
          Zakończ
        </Button>
      </Row>
    </Container>
  );
};
export default PanelFlowConnectPlanes;

const Container = styled(Card)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
  margin-top: 66px;
  padding: 0px;
  width: 400px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 9999999;
`;

const SimpleContent = styled(Card)`
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
`;

const StyledCheckboxGroup = styled(Checkbox.Group)``;
