import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IRootState } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import {
  deleteTechnicalEquipmentGroup,
  ITechnicalEquipmentGroup,
} from "../../../redux/technicalEquipmentsGroups/technicalEquipmentsGroupsSlice";
import TechnicalEquipmentsGroupsDetails from "./TechnicalEquipmentsGroupsDetails";

type Props = {};

const EditTechnicalEquipmentsGroupsPage: FC<Props> = () => {
  const { groupId } = useParams();

  const [technicalEquipmentsGroup, setTechnicalEquipmentsGroup] = useState<ITechnicalEquipmentGroup | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const technicalEquipmentsGroups = useAppSelector((state: IRootState) => state.technicalEquipmentsGroups);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteTechnicalEquipmentsGroup")
  );

  useEffect(() => {
    const s = technicalEquipmentsGroups.find((x) => x.id === groupId);
    if (s) {
      setTechnicalEquipmentsGroup(s);
    }
  }, [technicalEquipmentsGroup]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteTechnicalEquipmentsGroup"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${technicalEquipmentsGroup?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && technicalEquipmentsGroup && (
              <Button
                danger
                onClick={() =>
                  dispatch(deleteTechnicalEquipmentGroup(technicalEquipmentsGroup, () => navigate(-1)))
                }
              >
                Usuń
              </Button>
            )}
            <Button type="primary" form="technicalEquipmentsGroupDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nową grupę
            </Button>
          </div>
        }
      />

      <Content>
        {technicalEquipmentsGroup && (
          <TechnicalEquipmentsGroupsDetails
            system={technicalEquipmentsGroup}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};
export default EditTechnicalEquipmentsGroupsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
