import { Button, Card, Form, Input } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import ChangePasswordCard from "./ChangePasswordCard";
import ProfileInfoCard from "./ProfileInfoCard";
import UserSettingsCard from "./UserSettingsCard";

export default function ProfilePage() {
  const profile = useSelector((state) => state.profile);

  return (
    <Container>
      <PageHeader title={"Profil użytkownika"} />

      <ProfileInfoCard profile={profile} />
      <ChangePasswordCard error={profile.error ? profile.error : null} />
      <UserSettingsCard profile={profile} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
