import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../redux/auth/actions/authActions";

export default function SignIn() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const loadingState = useSelector((state) => state.loadingState);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onClickSignIn = (e) => {
    e.preventDefault();
    let user = { email: email, password: password };
    dispatch(signIn(user));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSignIn(e);
    }
  };

  return (
    <Container>
      <SubTitle>Zaloguj się do aplikacji</SubTitle>
      <FormContainer>
        <Form layout="vertical">
          <Form.Item label="Email">
            <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
          </Form.Item>
          <Form.Item label="Hasło">
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Hasło"
              onKeyPress={handleKeyPress}
            />
          </Form.Item>
          <Form.Item>
            <LoginButton type="primary" onClick={(e) => onClickSignIn(e)} loading={loadingState.loading}>
              {loadingState.loading ? "Trwa logowanie..." : "Zaloguj"}
            </LoginButton>
          </Form.Item>
          {auth.messageType === "error" && auth.message && (
            <CustomAlert message={auth.message} type={auth.messageType} />
          )}
        </Form>
      </FormContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  width: 400px;
`;

const Title = styled.h1`
  text-align: center;
`;
const SubTitle = styled.h3`
  text-align: center;
`;

const FormContainer = styled.div``;

const LoginButton = styled(Button)`
  width: 100%;
`;

const RegisterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;

  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;

const CustomAlert = styled(Alert)`
  text-align: center;
  margin-bottom: 16px;
`;
