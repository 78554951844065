import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDomaGrid {
  gridColor: string;
  gridSize: number;
  gridVisible: boolean;
  gridSnapping: boolean;
}

const initialState: IDomaGrid = {
  gridColor: "#cacaca",
  gridSize: 1,
  gridVisible: true,
  gridSnapping: false,
};

// Miejsce przechowywania ogólnych danych w programie
export const domaGridSlice = createSlice({
  name: "domaGrid",
  initialState,
  reducers: {
    setDomaGridInReducer: (state, action: PayloadAction<IDomaGrid>) => {
      return action.payload;
    },
    clearDomaGrid: (state) => {
      return initialState;
    },
    setSavedDomaGrid: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setDomaGridInReducer, clearDomaGrid, setSavedDomaGrid } = domaGridSlice.actions;
export default domaGridSlice.reducer;
