import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoofHole, IRoofPlane } from "../../models/Models";

const initialState: Array<IRoofHole> = [];

export const roofHolesSlice = createSlice({
  name: "roofHoles",
  initialState,
  reducers: {
    addRoofHole: (state, action: PayloadAction<IRoofHole>) => {
      let p = action.payload;
      return [...state, p];
    },
    addRoofHoles: (state, action: PayloadAction<Array<IRoofHole>>) => {
      let ps = action.payload;
      return [...state, ...ps];
    },
    replaceRoofHoles: (state, action: PayloadAction<Array<IRoofHole>>) => {
      let ps = action.payload;
      return ps;
    },
    removeRoofHole: (state, action: PayloadAction<IRoofHole>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRoofHoles: (state) => {
      return initialState;
    },
    setSavedRoofHoles: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { addRoofHole, addRoofHoles, replaceRoofHoles, removeRoofHole, clearRoofHoles, setSavedRoofHoles } =
  roofHolesSlice.actions;
export default roofHolesSlice.reducer;
