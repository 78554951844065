import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import {
  ICalcMaterialsData,
  ICalculationRequest,
  IFasteningSystem,
  IGeneralRoofData,
  IMetalSheet,
  IPreCoveringSystem,
  IRoofEdge,
  IRoofHole,
  IRoofPlane,
  IRoofPoint,
  IRoofSlope,
  IWindowIntegration,
} from "../../models/Models";
import {
  IRSO,
  IRSOEdge,
  IRSOHole,
  IRSOLinkedEdges,
  IRSOPoint,
} from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";
import { setSavedRoofCalcMaterialsData } from "../calculationProcess/calcMaterialsDataSlice";
import { ICalcResult, setSavedRoofCalcResults } from "../calculationProcess/calcResultSlice";
import { IReport, setSavedRoofReport } from "../calculationProcess/reportSlice";
import { IWindowHoleState, setWindowHoles } from "../calculationProcess/windowHolesSlice";
import { setWindowIntegrationInReducer } from "../general/windowIntegrationsSlice";
import { setWindows } from "../calculationProcess/windowsSlice";
import {
  IDrawChimneyProcess,
  resetDrawChimneyProcess,
  setSavedDrawChimneyProcess,
} from "../chimneys/drawChimneyProcessSlice";
import { IOutline, setSavedOutline } from "../drawing/outlineSlice";
import {
  IRoofProcessFunctionsStatus,
  setSavedRoofProcessFunctionsStatus,
} from "../drawing/roofProcessFunctionsStatusSlice";
import { IRoofProcessStatus, setSavedRoofProcessStatus } from "../drawing/roofProcessStatusSlice";
import { ISavedOutline, setSavedOutlines } from "../drawing/savedOutlinesSlice";
import { IDomaGrid, setSavedDomaGrid } from "../general/domaGridSlice";
import { setMetalSheets } from "../roofMetal/metalSheetsSlice";
import { setRoofSlopes } from "../roofMetal/roofSlopesSlice";
import { setRSOEdges } from "../roofSlopeOutline/rsoEdgesSlice";
import { setRSOHoles } from "../roofSlopeOutline/rsoHolesSlice";
import { setRSOLinkedEdges } from "../roofSlopeOutline/rsoLinkedEdgesSlice";
import { setRSOPlanes } from "../roofSlopeOutline/rsoPlanesSlice";
import { setRSOPoints } from "../roofSlopeOutline/rsoPointsSlice";
import { addFasteningSystem, addFasteningSystemsInReducer } from "../roofSystems/fasteningSystemsSlice";
import { addPreCoveringSystemsInReducer } from "../roofSystems/preCoveringSystemsSlice";
import store, { AppThunk, RootState } from "../store";
import { addCalcDataInReducer } from "./calcDataSlice";
import { setSavedDrawingScale } from "./drawingScaleSlice";
import { updateGeneralRoofData } from "./generalRoofDataSlice";
import { IGutteringElements, setGutteringElements } from "./gutteringElementsSlice";
import { updateInputDataInReducer } from "./inputDataSlice";
import { IRoofAccessoryElements, setRoofAccessoriesElements } from "./roofAccessoriesSlice";
import { IRoofContourNumber, setSavedRoofContourNumber } from "./roofContourNumberSlice";
import { replaceRoofEdges } from "./roofEdgesSlice";
import { replaceRoofHoles } from "./roofHolesSlice";
import { replaceRoofPlanes } from "./roofPlanesSlice";
import { replaceRoofPoints } from "./roofPointsSlice";
import { IAdditionalProduct, setSavedAdditionalProducts } from "../calculationProcess/additionalProductsSlice";
import { IOtherAppStatuses, setSavedOtherAppStatuses } from "../calculationProcess/otherAppStatusesSlice";

// Sprawdzić czy nie będa inne pola potrzebne do zapisu stanu
interface IRoofDrawingStoreElement {
  roofPoints: IRoofPoint[];
  roofPlanes: IRoofPlane[];
  roofEdges: IRoofEdge[];
  roofHoles: IRoofHole[];
  rsoPoints: IRSOPoint[];
  rsoEdges: IRSOEdge[];
  rsoPlanes: IRSO[];
  rsoHoles: IRSOHole[];
  rsoLinkedEdges: IRSOLinkedEdges[];
  metalSheets: IMetalSheet[];
  roofSlopes: IRoofSlope[];
  inputData: ICalculationRequest;
  gutteringElements: IGutteringElements;
  calcData: any;
  generalRoofData: IGeneralRoofData;
  domaGrid: IDomaGrid;
  roofProcessStatus: IRoofProcessStatus;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  outline: IOutline[];
  savedOutlines: ISavedOutline[];
  roofContourNumber: IRoofContourNumber;
  drawingScale: number;
  calcMaterialsData: ICalcMaterialsData;
  // drawingStore: IRoofDrawingStore;
  drawChimneyProcess: IDrawChimneyProcess;
  calcResult: ICalcResult;
  report: IReport;
  // preCoveringSystems: IPreCoveringSystem[];
  // fasteningSystems: IFasteningSystem[];
  // roofAccessories: IRoofAccessoryElements;
  windowHoles: IWindowHoleState[];
  // windowIntegration: IWindowIntegration;
  additionalProducts: IAdditionalProduct[];
  otherAppStatuses: IOtherAppStatuses;
  savedAdditionalProducts: any;
}

interface IRoofDrawingStore {
  active: number | null; // Numer/Index aktywnego zapisu
  container: IRoofDrawingStoreElement[]; // Tablica zawierająca wszystkie zapisy
}

const initialState: IRoofDrawingStore = { active: null, container: [] };

// Conterner pamięci dla całego rysunku dachu (przechowywanie historii zmian rysunku, tylko gdy zapytania do kalkulatora)
export const roofDrawingStoreSlice = createSlice({
  name: "roofDrawingStore",
  initialState,
  reducers: {
    updateDrawingStoreInReducer: (state, action: PayloadAction<IRoofDrawingStore>) => {
      let data = action.payload;
      return data;
    },
    updateActiveDrawingStoreInReducer: (state, action: PayloadAction<number>) => {
      let index = action.payload;
      return { ...state, active: index };
    },
    clearDrawingStoreInReducer: (state) => {
      return initialState;
    },
    setSavedRoofDrawingStore: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  updateDrawingStoreInReducer,
  updateActiveDrawingStoreInReducer,
  clearDrawingStoreInReducer,
  setSavedRoofDrawingStore,
} = roofDrawingStoreSlice.actions;
export default roofDrawingStoreSlice.reducer;

// ******************************************************************************************************
// FUNCTIONS
// ******************************************************************************************************

// EXPORTS ******************************************************************************************************

// Zapisanie nowego stanu
// Jeśli aktualny index nie jest ostatni to zanim zostanie dokonany zapis to wszystko co ma wyższy indeks powinno być usunięte (nie da się tego przywrócić)
export const saveDrawing =
  (state: RootState): AppThunk =>
  (dispatch: Dispatch) => {
    const dataToSave: IRoofDrawingStoreElement = {
      roofPoints: state.roofPoints,
      roofPlanes: state.roofPlanes,
      roofEdges: state.roofEdges,
      roofHoles: state.roofHoles,
      calcData: state.calcData,
      inputData: state.inputData,
      generalRoofData: state.generalRoofData,
      gutteringElements: state.gutteringElements,
      rsoPoints: state.rsoPoints,
      rsoEdges: state.rsoEdges,
      rsoPlanes: state.rsoPlanes,
      rsoHoles: state.rsoHoles,
      rsoLinkedEdges: state.rsoLinkedEdges,
      metalSheets: state.metalSheets,
      roofSlopes: state.roofSlopes,
      domaGrid: state.domaGrid,
      roofProcessFunctionsStatus: state.roofProcessFunctionsStatus,
      roofProcessStatus: state.roofProcessStatus,
      outline: state.outline,
      savedOutlines: state.savedOutlines,
      roofContourNumber: state.roofContourNumber,
      drawingScale: state.drawingScale,
      calcMaterialsData: state.calcMaterialsData,
      // drawingStore: state.roofDrawingStore,
      drawChimneyProcess: state.drawChimneyProcess,
      calcResult: state.calcResult,
      report: state.report,
      // fasteningSystems: state.fasteningSystems,
      // preCoveringSystems: state.preCoveringSystems,
      // roofAccessories: state.roofAccessories,
      windowHoles: state.windowHoles,
      // windowIntegration: state.windowIntegration,
      additionalProducts: state.additionalProducts,
      otherAppStatuses: state.otherAppStatuses,
      savedAdditionalProducts: state.savedAdditionalProducts,
    };

    const rds = state.roofDrawingStore;
    const elements = [...rds.container];

    // Usunąć poprzednie gdy activeIndex nie jest na ostatniej pozycji
    if ((state.roofDrawingStore.active as number) < state.roofDrawingStore.container.length - 1) {
      const countToDelete =
        state.roofDrawingStore.container.length - 1 - (state.roofDrawingStore.active as number);

      if (countToDelete > 0) {
        elements.splice(elements.length - countToDelete, countToDelete);
      }
    }

    const newArr = [...elements, dataToSave];

    const maxCount = 10; // Maksymalna ilość zapisów w pamięci
    // Ograniczenie do 10 ostatnich elementów
    while (newArr.length > maxCount) {
      newArr.shift(); // Usuwa najstarszy element
    }

    let index = rds.active;

    if (rds.active === null) {
      index = 0;
    } else {
      index = Math.min((index as number) + 1, maxCount - 1); // Ograniczenie indeksu do zakresu 0- maxCount - 1
      // index = (index as number) + 1;
    }

    dispatch(updateDrawingStoreInReducer({ active: index, container: newArr }));
  };

// Załadoanie zapisu o indeksie niższym o 1 od obecnego (jeśli taki istnieje)
export const undoDrawing =
  (state: RootState): AppThunk =>
  (dispatch: Dispatch<any>) => {
    const index = (state.roofDrawingStore.active as number) - 1;
    const getLastSave = state.roofDrawingStore.container[index];

    dispatch(loadDrawingSave(getLastSave));
    dispatch(updateActiveDrawingStoreInReducer(index));
  };

// Załadowanie zapisu o indeksie wyższym o 1 od aktualnego (jeśli taki istnieje)
export const redoDrawing =
  (state: RootState): AppThunk =>
  (dispatch: Dispatch<any>) => {
    const index = (state.roofDrawingStore.active as number) + 1;
    const getNextSave = state.roofDrawingStore.container[index];

    dispatch(loadDrawingSave(getNextSave));
    dispatch(updateActiveDrawingStoreInReducer(index));
  };

// Czy możliwe cofnięcie zapisu
export const isPossibleUndo = (state: RootState): boolean => {
  const rds = state.roofDrawingStore;

  if (rds.active === null) return false;

  if ((rds.active as number) > 0 && (rds.active as number) <= rds.container.length - 1) {
    return true;
  }

  return false;
};

// Czy możliwe wrócenie do zapisu przed cofnięciem
export const isPossibleRedo = (state: RootState): boolean => {
  const rds = state.roofDrawingStore;

  if (rds.active === null) return false;

  if ((rds.active as number) >= 0 && (rds.active as number) < rds.container.length - 1) {
    return true;
  }

  return false;
};

// PRIVATES ******************************************************************************************************

// Ładowanie wybranego zapisu
const loadDrawingSave =
  (element: IRoofDrawingStoreElement): AppThunk =>
  (dispatch: Dispatch) => {
    dispatch(replaceRoofPoints(element.roofPoints));
    dispatch(replaceRoofPlanes(element.roofPlanes));
    dispatch(replaceRoofEdges(element.roofEdges));
    dispatch(replaceRoofHoles(element.roofHoles));
    dispatch(addCalcDataInReducer(element.calcData));
    dispatch(updateInputDataInReducer(element.inputData));
    dispatch(updateGeneralRoofData(element.generalRoofData));
    // dispatch(setWindows(element.windows));
    dispatch(setGutteringElements(element.gutteringElements));
    dispatch(setRSOPoints(element.rsoPoints));
    dispatch(setRSOEdges(element.rsoEdges));
    dispatch(setRSOHoles(element.rsoHoles));
    dispatch(setRSOPlanes(element.rsoPlanes));
    dispatch(setRSOLinkedEdges(element.rsoLinkedEdges));
    dispatch(setMetalSheets(element.metalSheets));
    dispatch(setRoofSlopes(element.roofSlopes));
    dispatch(setSavedDomaGrid(element.domaGrid));
    dispatch(setSavedRoofProcessStatus(element.roofProcessStatus));
    dispatch(setSavedRoofProcessFunctionsStatus(element.roofProcessFunctionsStatus));
    dispatch(setSavedOutline(element.outline));
    dispatch(setSavedOutlines(element.savedOutlines));
    dispatch(setSavedRoofContourNumber(element.roofContourNumber));
    dispatch(setSavedDrawingScale(element.drawingScale));
    dispatch(setSavedRoofCalcMaterialsData(element.calcMaterialsData));
    // dispatch(setSavedRoofDrawingStore(element.drawingStore));
    dispatch(setSavedDrawChimneyProcess(element.drawChimneyProcess));
    dispatch(setSavedRoofCalcResults(element.calcResult));
    dispatch(setSavedRoofReport(element.report));
    // dispatch(addPreCoveringSystemsInReducer(element.preCoveringSystems));
    // dispatch(addFasteningSystemsInReducer(element.fasteningSystems));
    // dispatch(setRoofAccessoriesElements(element.roofAccessories));
    dispatch(setWindowHoles(element.windowHoles));
    // dispatch(setWindowIntegrationInReducer(element.windowIntegration));
    dispatch(setSavedAdditionalProducts(element.additionalProducts));
    dispatch(setSavedOtherAppStatuses(element.otherAppStatuses));
    dispatch(setSavedAdditionalProducts(element.savedAdditionalProducts));
  };
