import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalculationRequest } from "../../models/Models";

export interface IResponsesApi {
  gutteringData: any;
}

const initialState: IResponsesApi = { gutteringData: null };

// Dane wejściowe do obliczeń -> przydatne gdy zmieniamy kąt na połaciach
export const responsesApiSlice = createSlice({
  name: "responsesApi",
  initialState,
  reducers: {
    setGutteringDataResponsesApi: (state, action: PayloadAction<any>) => {
      return { ...state, gutteringData: action.payload };
    },
    clearResponsesApi: (state) => {
      return initialState;
    },
    setSavedResponsesApi: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setGutteringDataResponsesApi, clearResponsesApi, setSavedResponsesApi } = responsesApiSlice.actions;
export default responsesApiSlice.reducer;
