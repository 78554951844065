import { Modal } from "antd";
import React, { FC, useRef } from "react";
import { IAddPrincingSystemElement } from "../../../models/Models";
import PrincingPosition, { RefObject } from "./PrincingPosition";

type Props = {
  closeModal?: any;
  addPrincingSystemElement?: any;
};

const AddPrincingPosition: FC<Props> = ({ closeModal, addPrincingSystemElement }) => {
  const childRef = useRef<RefObject>(null);

  const handleOkClick = () => {
    if (childRef.current) {
      const formData = childRef.current.getFormData();

      const model: IAddPrincingSystemElement = {
        name: formData.name,
        relation: formData.relation,
        price: formData.price,
      };

      addPrincingSystemElement(model);
      closeModal();
    }
  };

  return (
    <Modal
      centered
      width={"60%"}
      open={true}
      title={"Dodaj pozycję do systemu"}
      onOk={() => {
        handleOkClick();
      }}
      onCancel={() => closeModal()}
    >
      <PrincingPosition ref={childRef} />
    </Modal>
  );
};
export default AddPrincingPosition;
