// Relacje związane z pokryciem dachu typu dachówka (ceramiczna lub cementowa)
export const TILE_SYSTEM_RELATIONS = [
  {
    value: "none",
    label: "Brak",
    index: 0,
  },
  {
    value: "general",
    label: "Oferta [x]",
    index: 1,
  },
  {
    value: "area",
    label: "Powierzchnia dachu [x/m2]",
    index: 2,
  },
  {
    value: "edge-ridge-all",
    label: "Krawędź kalenicy poziomej i skośnej [x/mb]",
    index: 3,
  },
  {
    value: "edge-ridge-horizontal",
    label: "Krawędź kalenicy poziomej [x/mb]",
    index: 4,
  },
  {
    value: "edge-ridge-diagonal",
    label: "Krawędź kalenicy skośnej [x/mb]",
    index: 5,
  },
  {
    value: "edge-eave",
    label: "Krawędź okapu [x/mb]",
    index: 6,
  },
  {
    value: "edge-valley",
    label: "Krawędź koszowa [x/mb]",
    index: 7,
  },
  {
    value: "edge-gable-all",
    label: "Krawędź szczytowa lewa i prawa [x/mb]",
    index: 8,
  },
  {
    value: "edge-gable-left",
    label: "Krawędź szczytowa lewa [x/mb]",
    index: 9,
  },
  {
    value: "edge-gable-right",
    label: "Krawędź szczytowa prawa [x/mb]",
    index: 10,
  },
  {
    value: "edge-roof-wall",
    label: "Krawędź dach-ściana [x/mb]",
    index: 11,
  },
  {
    value: "edge-window",
    label: "Krawędź okna [x/mb]",
    index: 12,
  },
  {
    value: "edge-chimney",
    label: "Krawędź komina [x/mb]",
    index: 13,
  },
  {
    value: "window",
    label: "Okno [x/szt.]",
    index: 14,
  },
  {
    value: "start-horizontal-ridge",
    label: "Początek kalenicy poziomej [x/szt.]",
    index: 15,
  },
  {
    value: "start-angled-ridge",
    label: "Początek kalenicy skośnej - grzbietu [x/szt.]",
    index: 16,
  },
  {
    value: "end-horizontal-ridge",
    label: "Koniec kalenicy poziomej [x/szt.]",
    index: 17,
  },
  {
    value: "end-angled-ridge",
    label: "Koniec kalenicy skośnej - grzbietu [x/szt.]",
    index: 18,
  },
  {
    value: "initial-ridge-three",
    label: "Początek kalenicy z trójnikiem [x/szt.]",
    index: 19,
  },
  {
    value: "end-ridge-three",
    label: "Koniec kalenicy z trójnikiem [x/szt.]",
    index: 20,
  },
  {
    value: "ridge-four",
    label: "Grzbiet z czwórnikiem (gąsior) [x/szt.]",
    index: 21,
  },
];

// Relacje związane z pokryciem dachu typu dachówka (ceramiczna lub cementowa)
export const EDGES_RELATIONS = [
  {
    value: "none",
    label: "Brak",
    index: 0,
  },
  {
    value: "edge-ridge-all",
    label: "Krawędź kalenicy poziomej i skośnej [x/mb]",
    index: 3,
  },
  {
    value: "edge-ridge-horizontal",
    label: "Krawędź kalenicy poziomej [x/mb]",
    index: 4,
  },
  {
    value: "edge-ridge-diagonal",
    label: "Krawędź kalenicy skośnej [x/mb]",
    index: 5,
  },
  {
    value: "edge-eave",
    label: "Krawędź okapu [x/mb]",
    index: 6,
  },
  {
    value: "edge-valley",
    label: "Krawędź koszowa [x/mb]",
    index: 7,
  },
  {
    value: "edge-gable-all",
    label: "Krawędź szczytowa lewa i prawa [x/mb]",
    index: 8,
  },
  {
    value: "edge-gable-left",
    label: "Krawędź szczytowa lewa [x/mb]",
    index: 9,
  },
  {
    value: "edge-gable-right",
    label: "Krawędź szczytowa prawa [x/mb]",
    index: 10,
  },
  {
    value: "edge-roof-wall",
    label: "Krawędź dach-ściana [x/mb]",
    index: 11,
  },
  {
    value: "edge-window",
    label: "Krawędź okna [x/mb]",
    index: 12,
  },
  {
    value: "edge-chimney",
    label: "Krawędź komina [x/mb]",
    index: 13,
  },
];

// Będzie to połączone wraz z TILE_SYSTEM_RELATIONS dla elementów dodatkowych
export const EXTRA_TILE_SYSTEM_RELATIONS = [
  {
    value: "roofStep",
    label: "Stopień dachowy [x/szt.]",
    index: 101,
  },
  {
    value: "chimneySweepBench40",
    label: "Ława kominiarska 40cm [x/szt.]",
    index: 102,
  },
  {
    value: "chimneySweepBench80",
    label: "Ława kominiarska 80cm [x/szt.]",
    index: 103,
  },
  {
    value: "chimneySweepBench200",
    label: "Ława kominiarska 200cm [x/szt.]",
    index: 104,
  },
  {
    value: "chimneySweepBench300",
    label: "Ława kominiarska 300cm [x/szt.]",
    index: 105,
  },
  {
    value: "snowFence200",
    label: "Płotek śniegowy 200cm [x/szt.]",
    index: 106,
  },
  {
    value: "snowFence300",
    label: "Płotek śniegowy 300cm [x/szt.]",
    index: 107,
  },
];

// Wszystkie kategorie produktów
export const CATEGORIES_ALL_ROOF = [
  "basic-products-tiles-concrete",
  "basic-products-tiles-ceramic",
  "basic-products-sheet-metal",
  "basic-products-cut-sheet-metal",
  "basic-products-sheet-metal-trapezoidal",
  "basic-products-sheet-metal-standing-seam",
  "window-collars-and-accessories",
  "guttering",
  "windows",
  "wood",
  "others",
  "supplementary-products",
  "lightning-protection-systems",
  "thermal-insulation",
  "roof-walkways-and-snow-guards",
  "roofing-accessories",
  "fasteners",
  "preliminary-covering",
  // "labour",
];

export const CATEGORIES_TECHNICAL_EQUIPMENTS = ["roof-walkways-and-snow-guards", "roofing-accessories"];

export const CATEGORIES_GUTTERS = ["guttering"];
