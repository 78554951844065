import { InboxOutlined } from "@ant-design/icons";
import { Button, Card, message } from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import React from "react";
import styled from "styled-components";
import client from "../../../api/ApiClient";
import PageHeader from "../../../layout/components/PageHeader";

export interface UploadRequestOption<T = any> {
  onProgress?: (event: any) => void;
  onError?: (event: any | ProgressEvent, body?: T) => void;
  onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
  data?: Record<string, unknown>;
  filename?: string;
  file: Exclude<any, File | boolean> | RcFile;
  withCredentials?: boolean;
  action: string;
  headers?: any;
  method: any;
}

export default function ContractorDataTransfer() {
  const fileUploadProps = {
    name: "file",
    // action: "/DomaDataTransfer/import",
    action: "/",
    customRequest(options: UploadRequestOption) {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();
      formData.append("file", file);

      client
        .post(fileUploadProps.action, formData, {
          headers: {
            ...client.defaults.headers.common, // Zachowaj domyślne nagłówki
            "Content-Type": "multipart/form-data", // Nadpisz tylko Content-Type
          },
        })
        .then((response) => {
          message.success(`${file.name} file uploaded successfully`);
          if (onSuccess) {
            onSuccess(response.data, file);
          }
        })
        .catch((error) => {
          message.error(`${file.name} file upload failed.`);
          if (onError) {
            onError(error, error.response);
          }
        });
    },
  };

  return (
    <Container>
      <PageHeader
        title={"Transfer danych wykonawcy"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="primary" disabled>
              Eksportuj do pliku EXCEL
            </Button>
          </div>
        }
      />

      <Section title={"Wczytaj dane do systemu"}>
        <Dragger {...fileUploadProps} showUploadList={false} disabled>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Kliknij lub przenieś plik EXCEL z danymi</p>
          <p className="ant-upload-hint">
            Wybierz tylko jeden plik EXCEL. Plik musi mieć strukturę zgodną z wymaganiami.
          </p>

          <p style={{ marginTop: "32px", color: "red", fontWeight: "bold" }}>
            UWAGA!!! Będzie aktywne po ustaleniu struktury danych wykonawczych i po zaimplemetnowaniu funkcji
            import/export
          </p>
        </Dragger>
      </Section>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
