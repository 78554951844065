import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IInvestment, IUpdateActivity, IUpdateReport } from "../../models/Models";
import { AppThunk } from "../store";

const initialState: IInvestment[] = [];

export const investmentsSlice = createSlice({
  name: "investments",
  initialState,
  reducers: {
    setInvestmentsInReducer: (state, action: PayloadAction<IInvestment[]>) => {
      let investments = action.payload;
      return [...investments];
    },
    addInvestmentInReducer: (state, action: PayloadAction<IInvestment>) => {
      let investment = action.payload;
      return [...state, investment];
    },
    updateInvestmentActivityInReducer: (state, action: PayloadAction<{ id: string; active: boolean }>) => {
      let data = action.payload;

      let index = state.findIndex((o) => o.id === data.id);
      state[index] = { ...state[index], active: data.active };
    },
    deleteInvestmentInReducer: (state, action: PayloadAction<string>) => {
      let investmentId = action.payload;
      return state.filter((o) => o.id !== investmentId);
    },
    clearInvestmentsInReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setInvestmentsInReducer,
  addInvestmentInReducer,
  updateInvestmentActivityInReducer,
  deleteInvestmentInReducer,
  clearInvestmentsInReducer,
} = investmentsSlice.actions;
export default investmentsSlice.reducer;

// API

export const updateInvestmentActivity =
  (data: IUpdateActivity): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .put("/Investments/updateActivity", data)
      .then((response) => {
        if (response.status == 204) {
          dispatch(updateInvestmentActivityInReducer({ id: data.id, active: data.active }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };

export const deleteInvestment =
  (investmentId: string): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/Investments/${investmentId}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteInvestmentInReducer(investmentId));
        }
      })
      .catch((error) => {
        // console.error(error);
      });
  };
