import { IRoofPoint } from "../models/Models";

export const roundNumber = (num: any) => {
  return Number((Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2));
};

function getSpPoint(A: any, B: any, C: any) {
  const x1 = A.x,
    y1 = A?.y,
    x2 = B?.x,
    y2 = B?.y,
    x3 = C?.x,
    y3 = C?.y;
  const px = x2 - x1,
    py = y2 - y1,
    dAB = px * px + py * py;
  const u = ((x3 - x1) * px + (y3 - y1) * py) / dAB;
  const x = x1 + u * px,
    y = y1 + u * py;
  return { x: x, y: y }; //this is D
}

export const getAngleTwoLines = (A1: any, A2: any, B1: any, B2: any) => {
  var dAx = A2.x - A1.x;
  var dAy = A2.y - A1.y;
  var dBx = B2.x - B1.x;
  var dBy = B2.y - B1.y;

  var angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);

  return -1 * angle * (180 / Math.PI);
};

export function rotate(cx: any, cy: any, x: any, y: any, angle: any) {
  var radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (x - cx) + sin * (y - cy) + cx,
    ny = cos * (y - cy) - sin * (x - cx) + cy;
  return [nx, ny];
}

export const getAngleForRotatePlane = (points: IRoofPoint[]) => {
  // Kolekcja nowych punktów -> kopiujemy tamte i  będziemy zmieniac x, y
  // Mają te same id ale nowe współrzędne tymczasowe dla tego ekranu
  const newPointCollection: IRoofPoint[] = [...points];

  //1. Bierzemy punkt z max z i najbardziej po lewej
  const maxZ: number = roundNumber(Math.max(...newPointCollection.map((o) => o.z)));

  const maxZPoints: IRoofPoint[] = newPointCollection.filter((o) => roundNumber(o.z) === maxZ);
  const minXInMaxZPoints: number = roundNumber(Math.min(...maxZPoints.map((o) => o.x)));

  // Punkt do rotacji czyli ten najwyżej po lewej
  const pCR =
    maxZPoints.length > 1 ? maxZPoints.find((o) => roundNumber(o.x) === minXInMaxZPoints) : maxZPoints[0];

  const eff = 0.00001; // Kalulator czasami zamiast 0 dla z wzraca bardzo małą liczbę dlatego porównaie do 0 nie działa i trzeba troszkę dać przestrzeni na wynik
  // Zakres to od -eff do +eff
  let pointsWithZ0 = newPointCollection.filter((o) => o.z <= eff && o.z >= -1 * eff); // Nie działa to dla lukarn i wykuszy bo nie ma żaden punkt pozycji 0

  // Dla lukarn i wykuszy
  if (!pointsWithZ0 || pointsWithZ0.length === 0) {
    const minZ = roundNumber(Math.min(...newPointCollection.map((o) => o.z)));
    pointsWithZ0 = newPointCollection.filter((o) => roundNumber(o.z) === minZ);
  }

  const finalList: IRoofPoint[] = [];

  const A = pointsWithZ0[0];
  let B = pointsWithZ0[1];

  const C = pCR;
  const D = getSpPoint(A, B, C);

  const N00 = { x: 0, y: 0 };
  const N01 = { x: 0, y: 1 };

  let angle = getAngleTwoLines(pCR, D, N00, N01);

  return angle;
};

// Tu jest jakis błąd -> ogólnie ddziała ale są przypadki że nie
export const getPointsRotatedRoofPartByPoints = (points: IRoofPoint[]) => {
  // Kolekcja nowych punktów -> kopiujemy tamte i  będziemy zmieniac x, y
  // Mają te same id ale nowe współrzędne tymczasowe dla tego ekranu
  const newPointCollection: IRoofPoint[] = [...points];

  //1. Bierzemy punkt z max z i najbardziej po lewej
  const maxZ: number = roundNumber(Math.max(...newPointCollection.map((o) => o.z)));

  const maxZPoints: IRoofPoint[] = newPointCollection.filter((o) => roundNumber(o.z) === maxZ);
  const minXInMaxZPoints: number = roundNumber(Math.min(...maxZPoints.map((o) => o.x)));

  // Punkt do rotacji czyli ten najwyżej po lewej
  const pCR =
    maxZPoints.length > 1 ? maxZPoints.find((o) => roundNumber(o.x) === minXInMaxZPoints) : maxZPoints[0];

  const eff = 0.00001; // Kalulator czasami zamiast 0 dla z wzraca bardzo małą liczbę dlatego porównaie do 0 nie działa i trzeba troszkę dać przestrzeni na wynik
  // Zakres to od -eff do +eff
  let pointsWithZ0 = newPointCollection.filter((o) => o.z <= eff && o.z >= -1 * eff); // Nie działa to dla lukarn i wykuszy bo nie ma żaden punkt pozycji 0

  // Dla lukarn i wykuszy
  if (!pointsWithZ0 || pointsWithZ0.length === 0) {
    const minZ = roundNumber(Math.min(...newPointCollection.map((o) => o.z)));
    pointsWithZ0 = newPointCollection.filter((o) => roundNumber(o.z) === minZ);
  }

  const finalList: IRoofPoint[] = [];

  const A = pointsWithZ0[0];
  let B = pointsWithZ0[1];

  const C = pCR;
  const D = getSpPoint(A, B, C);

  const N00 = { x: 0, y: 0 };
  const N01 = { x: 0, y: 1 };

  let angle = getAngleTwoLines(pCR, D, N00, N01);

  for (let p of newPointCollection) {
    if (pCR) {
      if (pCR.id === p.id) {
        finalList.push(p);
        continue;
      }

      const rot = rotate(pCR.x, pCR.y, p.x, p.y, angle);

      const pp = { ...p, x: rot[0], y: rot[1] };
      finalList.push(pp);
    }
  }

  return finalList;
};

export const movePointsRoofPartToLeftCorner = (points: IRoofPoint[], dist: number) => {
  const result: IRoofPoint[] = [];

  const minX = [...points].sort((a, b) => a.x - b.x)[0];
  const minY = [...points].sort((a, b) => a.y - b.y)[0];

  const moveX = roundNumber(minX.x) - dist;
  const moveY = roundNumber(minY.y) - dist;

  for (const p of points) {
    result.push({ ...p, x: p.x - moveX, y: p.y - moveY });
  }

  return result;
};
