import { Button, Form, Modal, Radio, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC } from "react";
import styled from "styled-components";
import DomaInputNumber from "../helpers/DomaInputNumber";
import { IResultItemResponse } from "../models/Models";
import {
  BASIC_PRODUCTS,
  FASTENERS_PRODUCTS,
  GUTTERING_PRODUCTS,
  LABOUR_PRODUCTS,
  LIGHTNING_PROTECTION_PRODUCTS,
  OTHERS_PRODUCTS,
  PRELIMINARY_COVERING_PRODUCTS,
  ROOFING_ACCESSORIES_PRODUCTS,
  ROOF_WALKWAYS_PRODUCTS,
  SUPPLEMENTARY_PRODUCTS,
  THERMAL_PRODUCTS,
  WINDOWS_PRODUCTS,
  WOOD_PRODUCTS,
} from "../modules/roofs/components/roofSteps/steps/ResultStep";
import {
  addOrUpdateDiscountGroupCalc,
  updateGroupDiscountsCalcResultsItem,
} from "../redux/calculationProcess/calcResultSlice";
import { GroupDiscountType, updateGroupDiscountByType } from "../redux/calculationProcess/groupDiscountsSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { PRIMARY_COLOR } from "../theme";

type Props = {
  open: boolean;
  onCancel: any;
  onOk: any;
  groupCode: string | null;
};

const GroupDiscountModal: FC<Props> = ({ open, onCancel, onOk, groupCode }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const calcResult = useAppSelector((state) => state.calcResult);

  const getInitialValues = () => {
    const discountGroup = calcResult.discountGroups.find((x) => x.code === groupCode);

    return {
      discountType: discountGroup ? discountGroup.discountType : "%",
      discountPart: discountGroup ? discountGroup.discountPart : "nett",
      discount: discountGroup ? discountGroup.discount : 0,
    };
  };

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="back" onClick={onCancel}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button form="groupDiscountForm" key="submit" htmlType="submit" type="primary">
        Ok
      </Button>
    );

    return buttons;
  };

  const onFinish = (values: any) => {
    // console.log("values", values);

    const results: IResultItemResponse[] = [];

    // Pobrać wszystkie pozycje oferty w grupie
    let elements: IResultItemResponse[] | undefined = [];

    let discountType: GroupDiscountType | null = null;

    if (groupCode === "basic-products") {
      discountType = "basicProducts";
      elements = calcResult?.data?.filter((x) => BASIC_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "roofing-accessories") {
      discountType = "roofingAccessories";
      elements = calcResult?.data?.filter((x) => ROOFING_ACCESSORIES_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "preliminary-covering") {
      discountType = "preliminaryCovering";
      elements = calcResult?.data?.filter((x) => PRELIMINARY_COVERING_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "wood") {
      discountType = "wood";
      elements = calcResult?.data?.filter((x) => WOOD_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "roof-walkways-and-snow-guards") {
      discountType = "roofWalkwaysAndSnowGuards";
      elements = calcResult?.data?.filter((x) => ROOF_WALKWAYS_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "guttering") {
      discountType = "guttering";
      elements = calcResult?.data?.filter((x) => GUTTERING_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "fasteners") {
      discountType = "fasteners";
      elements = calcResult?.data?.filter((x) => FASTENERS_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "windows") {
      discountType = "windows";
      elements = calcResult?.data?.filter((x) => WINDOWS_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "thermal-insulation") {
      discountType = "thermalInsulation";
      elements = calcResult?.data?.filter((x) => THERMAL_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "lightning-protection-systems") {
      discountType = "lightningProtectionSystems";
      elements = calcResult?.data?.filter((x) => LIGHTNING_PROTECTION_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "supplementary-products") {
      discountType = "supplementaryProducts";
      elements = calcResult?.data?.filter((x) => SUPPLEMENTARY_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "others") {
      discountType = "others";
      elements = calcResult?.data?.filter((x) => OTHERS_PRODUCTS.includes(x.categoryCode));
    } else if (groupCode === "labour") {
      discountType = "labour";
      elements = calcResult?.data?.filter((x) => LABOUR_PRODUCTS.includes(x.categoryCode));
    }

    if (elements) {
      for (const item of elements) {
        const model: IResultItemResponse = {
          ...item,
          discount: values.discount,
          discountType: values.discountType,
          discountPart: values.discountPart,
        };

        results.push(model);
      }
    }

    // Aktualizacja rabatu grupowego
    if (groupCode) {
      dispatch(
        addOrUpdateDiscountGroupCalc({
          code: groupCode,
          discount: values.discount,
          discountType: values.discountType,
          discountPart: values.discountPart,
        })
      );
    }

    // Zapisanie rabatu grupowego do innych obliczeń
    if (discountType) {
      dispatch(
        updateGroupDiscountByType({
          typeGroup: discountType,
          discountValue: values.discount,
          discountType: values.discountType,
          discountPart: values.discountPart,
        })
      );
    }

    dispatch(updateGroupDiscountsCalcResultsItem(results));
    onOk();
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getFullNameGroubByCode = (code: string | null) => {
    if (code === "basic-products") {
      return "Produkty podstawowe";
    } else if (code === "roofing-accessories") {
      return "Produkty akcesorii dachowych";
    } else if (code === "preliminary-covering") {
      return "Produkty krycia wstępnego";
    } else if (code === "wood") {
      return "Produkty drewniane";
    } else if (code === "roof-walkways-and-snow-guards") {
      return "Produkty komunikacji dachowej i zabezpieczenia przeciwśniegowego";
    } else if (code === "guttering") {
      return "Produkty systemu orynnowania";
    } else if (code === "fasteners") {
      return "Produkty techniki zamocowań";
    } else if (code === "windows") {
      return "Produkty okien i dodatków okiennych";
    } else if (code === "thermal-insulation") {
      return "Produkty termoizolacji";
    } else if (code === "lightning-protection-systems") {
      return "Produkty systemu odgromowego";
    } else if (code === "supplementary-products") {
      return "Produkty uzupełniające";
    } else if (code === "others") {
      return "Produkty na zamówienie lub inne";
    } else if (code === "labour") {
      return "Robocizna";
    }

    return "";
  };

  return (
    <Container
      title={
        <span>
          Rabat grupowy dla grupy: <b>{getFullNameGroubByCode(groupCode)}</b>
        </span>
      }
      open={open}
      onCancel={onCancel}
      centered
      width={"50%"}
      footer={getButtonsInFooter()}
    >
      <Form
        id="groupDiscountForm"
        style={{ width: "100%", marginTop: "16px" }}
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={getInitialValues()}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Typ rabatu" name="discountType">
          <StyledRadioGroup>
            <Radio.Button value="%">Procentowy [%]</Radio.Button>
            <Radio.Button value="price">Kwotowy [zł]</Radio.Button>
          </StyledRadioGroup>
        </Form.Item>

        <Form.Item label="Przeznaczenie rabatu" name="discountPart">
          <StyledRadioGroup>
            <Radio.Button value="nett">Dla kwoty netto</Radio.Button>
            <Radio.Button value="gross">Dla kwoty brutto</Radio.Button>
          </StyledRadioGroup>
        </Form.Item>

        <Form.Item label="Wartość rabatu [%/zł]" name="discount">
          <DomaInputNumber />
        </Form.Item>
      </Form>
    </Container>
  );
};
export default GroupDiscountModal;

const Container = styled(Modal)`
  display: flex;
  width: 100%;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
