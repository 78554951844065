import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Card } from "antd";
import { PRIMARY_COLOR, TEXT_COLOR } from "../../../../theme";

type Props = {
  title: string;
  active: boolean;
  disabled?: boolean;
  open?: () => void;
};

const RoofStep: FC<Props> = ({ title, active, disabled, open }) => {
  return (
    <Container $active={active} disabled={disabled} onClick={!disabled ? open : () => null}>
      {title}
    </Container>
  );
};

export default RoofStep;

const Container = styled(Card).attrs((props: { $active?: boolean; disabled?: boolean }) => props)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 50px;

  font-weight: bold;
  font-size: 14px;
  color: #5a5a5a;
  text-align: center;

  ${(props) =>
    props.disabled
      ? css`
          background: #cbcbcb;
          opacity: 0.4;
        `
      : css`
          cursor: pointer;
          background: ${() => (props.$active ? PRIMARY_COLOR : "")};
          color: ${() => (props.$active ? "#fff" : TEXT_COLOR)};

          &:hover {
            color: #fff;
            background-color: ${PRIMARY_COLOR};
          }
        `}
`;
