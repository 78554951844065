import { DeleteFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { FC, memo, useEffect, useRef, useState } from "react";
import { Circle, Group, Line, Rect, Text } from "react-konva";
import { Html } from "react-konva-utils";
import { IBasicPoint } from "../../../../models/Models";
import { invalidateCalculation } from "../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { deleteRoofAccessoriesElement, IRoofAccessoryElement } from "../../../../redux/roofs/roofAccessoriesSlice";

export const getRoofStepIcon = (scale: number, zoomScale: number, angle: number) => {
  const width = 0.14 * scale;
  const length = 0.25 * scale;

  const lineWidth = (1 * scale) / 50;

  return (
    <Group rotation={angle} listening={false}>
      <Rect width={width} height={length} strokeWidth={lineWidth / 8} stroke="#1f1f1f" fill="#a3a3a3" />
      <Line points={[width / 2, 0, width / 2, length]} stroke="#1f1f1f" strokeWidth={lineWidth} />
    </Group>
  );
};

export const getChimneySweepBenchIcon = (
  chimneySweepBenchLength: number,
  scale: number,
  zoomScale: number,
  angle: number
) => {
  const width = 0.15 * scale;
  const length = chimneySweepBenchLength * scale;
  const lineWidth = (1 * scale) / 25;

  let countSupports = 2;

  if (chimneySweepBenchLength === 0.4 || chimneySweepBenchLength === 0.8) {
    countSupports = 2;
  } else if (chimneySweepBenchLength === 2 || chimneySweepBenchLength === 3) {
    countSupports = 3;
  }

  const linesArray = [];

  if (countSupports === 2) {
    linesArray.push(0.1);
    linesArray.push(chimneySweepBenchLength - 0.1);
  } else if (countSupports === 3) {
    linesArray.push(0.1);
    linesArray.push(chimneySweepBenchLength / 2);
    linesArray.push(chimneySweepBenchLength - 0.1);
  }

  return (
    <Group
      // x={0 + length / 2} // positionX to początkowa pozycja x grupy przed dodaniem offsetu
      // y={0} // positionY to początkowa pozycja y grupy
      // offsetX={length / 2} // Ustawienie punktu obrotu na środek szerokości grupy
      // offsetY={width / 2} // Ustawienie punktu obrotu na środek wysokości grupy, jeśli chcesz obracać wokół środka wysokości
      rotation={angle}
      listening={false}
    >
      <Rect width={length} height={width} strokeWidth={lineWidth / 8} stroke="#1f1f1f" fill="#a3a3a3" />
      {linesArray.map((line, i) => {
        return (
          <Line key={i} points={[line * scale, 0, line * scale, width]} stroke="#1f1f1f" strokeWidth={lineWidth} />
        );
      })}
    </Group>
  );
};

export const getSnowFenceIcon = (snowFenceLength: number, scale: number, zoomScale: number, angle: number) => {
  const width = 0.15 * scale;
  const length = snowFenceLength * scale;
  const lineWidth = (1 * scale) / 50;

  // Liczba fal na długości płotu
  const waveCount = 24;

  // Wysokość fali
  const waveHeight = width / 8;

  // Tworzenie punktów dla fali
  let points = [];
  for (let i = 0; i <= waveCount; i++) {
    const x = (i / waveCount) * length;
    const y = i % 2 === 0 ? width / 2 - waveHeight : width / 2 + waveHeight;
    points.push(x, y);
  }

  return (
    <Group rotation={angle} listening={false}>
      <Rect width={length} height={width} strokeWidth={lineWidth / 8} stroke="#1f1f1f" fill="#a3a3a3" />
      <Line points={points} stroke="#1f1f1f" strokeWidth={lineWidth / 2} />
    </Group>
  );
};

export const getRoofVentIcon = (diameter: number, scale: number, zoomScale: number, rotationAngle: number) => {
  const dim = diameter * scale;
  const lineWidth = (1 * scale) / 50;

  return <Circle radius={dim} fill={"#a3a3a3"} strokeWidth={lineWidth / 8} stroke="#1f1f1f" listening={false} />;
};

const getElementDimensions = (typeElement: string, scale: number, zoomScale: number) => {
  switch (typeElement) {
    case "roofStep":
      return { width: 0.14 * scale, height: 0.25 * scale };
    case "chimneySweepBench40":
    case "chimneySweepBench80":
    case "chimneySweepBench200":
    case "chimneySweepBench300":
      return {
        width:
          (typeElement === "chimneySweepBench40"
            ? 0.4
            : typeElement === "chimneySweepBench80"
            ? 0.8
            : typeElement === "chimneySweepBench200"
            ? 2
            : 3) * scale,
        height: 0.15 * scale,
      };
    case "snowFence200":
    case "snowFence300":
      return { width: (typeElement === "snowFence200" ? 2 : 3) * scale, height: 0.15 * scale };
    case "roofVent100":
    case "roofVent125":
    case "roofVent150":
      const diameter =
        (typeElement === "roofVent100" ? 0.1 : typeElement === "roofVent125" ? 0.125 : 0.15) * scale;
      return { width: diameter * 2, height: diameter * 2 };
    default:
      return { width: 0, height: 0 };
  }
};

type Props = {
  element: IRoofAccessoryElement | null;
  typeElement:
    | "roofStep"
    | "chimneySweepBench40"
    | "chimneySweepBench80"
    | "chimneySweepBench200"
    | "chimneySweepBench300"
    | "snowFence200"
    | "snowFence300"
    | "roofVent100"
    | "roofVent125"
    | "roofVent150";
  scale: number;
  zoomScale: number;
  activeDelete: boolean;
};

const RoofAccessoryElement: FC<Props> = ({ element, typeElement, scale, zoomScale, activeDelete }) => {
  const dispatch = useAppDispatch();

  const mousePosition = useAppSelector((state) => state.mousePosition); // położenie myszki w Stage

  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  const { width, height } = getElementDimensions(typeElement, scale, zoomScale);

  const handleMouseEnter = (e: any) => {
    setShowDeleteIcon(true);
    e.target.getStage().container().style.cursor = "pointer";
    e.target.stroke("red");
    e.target.draw();
  };

  const handleMouseLeave = (e: any) => {
    setShowDeleteIcon(false);
    e.target.getStage().container().style.cursor = "default";
    e.target.stroke("transparent");
    e.target.draw();
  };

  const getXYForOutlineDelete = (typeElement: string, scale: number, scaleZoom: number) => {
    let dim = 0;

    if (typeElement === "roofVent100") {
      dim = 0.1 * scale;
    } else if (typeElement === "roofVent125") {
      dim = 0.125 * scale;
    } else if (typeElement === "roofVent150") {
      dim = 0.15 * scale;
    } else {
      dim = 0;
    }

    return { x: -dim, y: -dim };
  };

  type CustomTooltipProps = {
    x: number;
    y: number;
  };

  const CustomTooltip: FC<CustomTooltipProps> = ({ x, y }) => {
    return <Text x={20} y={20} text="Usuń!" fontFamily="Calibri" fontSize={12} fill="#000000" />;
  };

  const getRotationForComponent = (typeElement: string, angle: number) => {
    if (typeElement === "roofVent100" || typeElement === "roofVent125" || typeElement === "roofVent150") {
      return 0;
    } else {
      return angle;
    }
  };

  return (
    <Group x={element ? element.x : 0} y={element ? element.y : 0}>
      {typeElement === "roofStep" && getRoofStepIcon(scale, zoomScale, element?.angle as number)}
      {typeElement === "chimneySweepBench40" &&
        getChimneySweepBenchIcon(0.4, scale, zoomScale, element?.angle as number)}
      {typeElement === "chimneySweepBench80" &&
        getChimneySweepBenchIcon(0.8, scale, zoomScale, element?.angle as number)}
      {typeElement === "chimneySweepBench200" &&
        getChimneySweepBenchIcon(2, scale, zoomScale, element?.angle as number)}
      {typeElement === "chimneySweepBench300" &&
        getChimneySweepBenchIcon(3, scale, zoomScale, element?.angle as number)}
      {typeElement === "snowFence200" && getSnowFenceIcon(2, scale, zoomScale, element?.angle as number)}
      {typeElement === "snowFence300" && getSnowFenceIcon(3, scale, zoomScale, element?.angle as number)}
      {typeElement === "roofVent100" && getRoofVentIcon(0.1, scale, zoomScale, element?.angle as number)}
      {typeElement === "roofVent125" && getRoofVentIcon(0.125, scale, zoomScale, element?.angle as number)}
      {typeElement === "roofVent150" && getRoofVentIcon(0.15, scale, zoomScale, element?.angle as number)}

      {element !== null && activeDelete && (
        <Rect
          rotation={getRotationForComponent(typeElement, element?.angle as number)}
          x={getXYForOutlineDelete(typeElement, scale, zoomScale).x}
          y={getXYForOutlineDelete(typeElement, scale, zoomScale).y}
          width={width}
          height={height}
          stroke={showDeleteIcon ? "red" : "transparent"}
          strokeWidth={5}
          fill="transparent"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onMouseDown={() => {
            if (showDeleteIcon === true) {
              dispatch(deleteRoofAccessoriesElement({ id: element?.id as string, type: typeElement }));
              dispatch(invalidateCalculation());
            }
          }}
        />
      )}

      {showDeleteIcon && <CustomTooltip x={mousePosition.x} y={mousePosition.y} />}
    </Group>
  );
};

export default memo(RoofAccessoryElement);
