import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IExternalPrincingSystem {
  id: string;
  name: string;
  owner: string;
  contractorId?: string;
}

const initialState: IExternalPrincingSystem[] = [];

export const externalPrincingSystemsSlice = createSlice({
  name: "externalPrincingSystems",
  initialState,
  reducers: {
    setExternalPrincingSystemsInReducer: (state, action: PayloadAction<IExternalPrincingSystem[]>) => {
      let list = action.payload;
      return list;
    },
    addExternalPrincingSystemsInReducer: (state, action: PayloadAction<IExternalPrincingSystem[]>) => {
      let eps = action.payload;
      return [...state, ...eps];
    },
    deleteExternalPrincingSystemsByContractorIdInReducer: (state, action: PayloadAction<string>) => {
      const contractorId = action.payload;
      return state.filter((o) => o.contractorId !== contractorId);
    },
  },
});

export const {
  setExternalPrincingSystemsInReducer,
  addExternalPrincingSystemsInReducer,
  deleteExternalPrincingSystemsByContractorIdInReducer,
} = externalPrincingSystemsSlice.actions;
export default externalPrincingSystemsSlice.reducer;
