import { Button, Col, Collapse, Form, Row, Select } from "antd";
import { set } from "js-cookie";
import React, { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { createFilterOption, getElementsForSelect } from "../../../../../../helpers/Helpers";
import { useFirstRender } from "../../../../../../hooks/useFirstRender";
import {
  resetFilterForFasteningSystem,
  resetFilterForGutterSystem,
  resetFilterForPreCoveringSystem,
  resetFilterForRoofSystem,
  updateFilterForFasteningSystem,
  updateFilterForGutterSystem,
  updateFilterForPreCoveringSystem,
  updateFilterForRoofSystem,
} from "../../../../../../redux/calculationProcess/filtersForSystemsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

type Props = {
  typeSystem: "roof" | "gutter" | "preCovering" | "fastening";
};

const SystemFilteringComponent: FC<Props> = ({ typeSystem }) => {
  const dispatch = useAppDispatch();
  const firstRender = useFirstRender();

  const [isPanelActive, setIsPanelActive] = useState<boolean>(typeSystem === "roof" ? true : false);

  const filtersForSystems = useAppSelector((state) => state.filtersForSystems);

  const companies = useAppSelector((state) => state.companies);
  const productColors = useAppSelector((state) => state.productColors);
  const productModels = useAppSelector((state) => state.productModels);

  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);

  useEffect(() => {
    const currentFilterSystem = getCurrentFilterSystem(typeSystem);
    if (typeSystem !== "roof" && currentFilterSystem) {
      const hasInitialValue =
        currentFilterSystem.companyId !== null ||
        currentFilterSystem.productModelId !== null ||
        currentFilterSystem.productColorId !== null;
      setIsPanelActive(hasInitialValue);
    }
  }, [typeSystem, filtersForSystems]);

  useEffect(() => {
    const currentFilterSystem = getCurrentFilterSystem(typeSystem);
    if (currentFilterSystem) {
      setSelectedCompany(currentFilterSystem.companyId as string);
      setSelectedModel(currentFilterSystem.productModelId as string);
      setSelectedColor(currentFilterSystem.productColorId as string);
    }
  }, [filtersForSystems]);

  const getCurrentFilterSystem = (typeSystem: "roof" | "gutter" | "preCovering" | "fastening") => {
    if (typeSystem === "roof") {
      return filtersForSystems.filterForRoofSystem;
    } else if (typeSystem === "gutter") {
      return filtersForSystems.filterForGutterSystem;
    } else if (typeSystem === "preCovering") {
      return filtersForSystems.filterForPreCoveringSystem;
    } else if (typeSystem === "fastening") {
      return filtersForSystems.filterForFasteningSystem;
    }

    return null;
  };

  // Memoizacja dostępnych opcji dla producentów
  const availableCompanies = useMemo(() => {
    // console.log("Get available companies");
    return [...getElementsForSelect(companies), { value: null, label: "Brak producenta" }];
  }, [companies]);

  // Memoizacja dostępnych opcji dla modeli
  const availableModels = useMemo(() => {
    if (selectedCompany === null || selectedCompany === "null") {
      return [{ value: null, label: "Brak modelu" }];
    } else {
      const models = productModels.filter((model) => model.companyId === selectedCompany);
      return [...getElementsForSelect(models), { value: null, label: "Brak modelu" }];
    }
  }, [selectedCompany]);

  // Memoizacja dostępnych opcji dla kolorów
  const availableColors = useMemo(() => {
    // console.log("Get available colors");
    return [...getElementsForSelect(productColors), { value: null, label: "Brak koloru" }];
  }, [productColors]);

  const updateCompany = (value: string | null) => {
    if (typeSystem === "roof") {
      dispatch(
        updateFilterForRoofSystem({
          companyId: value,
          productModelId: selectedModel,
          productColorId: selectedColor,
        })
      );
    } else if (typeSystem === "gutter") {
      dispatch(
        updateFilterForGutterSystem({
          companyId: value,
          productModelId: selectedModel,
          productColorId: selectedColor,
        })
      );
    } else if (typeSystem === "preCovering") {
      dispatch(
        updateFilterForPreCoveringSystem({
          companyId: value,
          productModelId: selectedModel,
          productColorId: selectedColor,
        })
      );
    } else if (typeSystem === "fastening") {
      dispatch(
        updateFilterForFasteningSystem({
          companyId: value,
          productModelId: selectedModel,
          productColorId: selectedColor,
        })
      );
    }
  };

  const updateModel = (value: string | null) => {
    if (typeSystem === "roof") {
      dispatch(
        updateFilterForRoofSystem({
          companyId: selectedCompany,
          productModelId: value,
          productColorId: selectedColor,
        })
      );
    } else if (typeSystem === "gutter") {
      dispatch(
        updateFilterForGutterSystem({
          companyId: selectedCompany,
          productModelId: value,
          productColorId: selectedColor,
        })
      );
    } else if (typeSystem === "preCovering") {
      dispatch(
        updateFilterForPreCoveringSystem({
          companyId: selectedCompany,
          productModelId: value,
          productColorId: selectedColor,
        })
      );
    } else if (typeSystem === "fastening") {
      dispatch(
        updateFilterForFasteningSystem({
          companyId: selectedCompany,
          productModelId: value,
          productColorId: selectedColor,
        })
      );
    }
  };

  const updateColor = (value: string | null) => {
    if (typeSystem === "roof") {
      dispatch(
        updateFilterForRoofSystem({
          companyId: selectedCompany,
          productModelId: selectedModel,
          productColorId: value,
        })
      );
    } else if (typeSystem === "gutter") {
      dispatch(
        updateFilterForGutterSystem({
          companyId: selectedCompany,
          productModelId: selectedModel,
          productColorId: value,
        })
      );
    } else if (typeSystem === "preCovering") {
      dispatch(
        updateFilterForPreCoveringSystem({
          companyId: selectedCompany,
          productModelId: selectedModel,
          productColorId: value,
        })
      );
    } else if (typeSystem === "fastening") {
      dispatch(
        updateFilterForFasteningSystem({
          companyId: selectedCompany,
          productModelId: selectedModel,
          productColorId: value,
        })
      );
    }
  };

  const clearFilters = () => {
    if (typeSystem === "roof") {
      dispatch(resetFilterForRoofSystem());
    } else if (typeSystem === "gutter") {
      dispatch(resetFilterForGutterSystem());
    } else if (typeSystem === "preCovering") {
      dispatch(resetFilterForPreCoveringSystem());
    } else if (typeSystem === "fastening") {
      dispatch(resetFilterForFasteningSystem());
    }
  };

  return (
    <SectionCollapseContainer
      activeKey={isPanelActive ? "1" : undefined}
      onChange={(key) => {
        key[0] === "1" ? setIsPanelActive(true) : setIsPanelActive(false);
      }}
    >
      <Collapse.Panel
        header={"Filtruj wyniki po nazwie producenta, modelu i/lub kolorze."}
        key="1"
        extra={
          <Button
            danger
            onClick={(e) => {
              e.stopPropagation();

              clearFilters();
            }}
          >
            Resetuj filtrowanie
          </Button>
        }
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Producent" labelCol={{ span: 24 }}>
              <Select
                placeholder="Wybierz producenta podstawowych produktów!"
                options={availableCompanies}
                value={selectedCompany}
                onChange={(value) => updateCompany(value)}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Model" labelCol={{ span: 24 }}>
              <Select
                placeholder="Wybierz model podstawowych produktów!"
                options={availableModels}
                value={selectedModel}
                onChange={(value) => updateModel(value)}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Kolor" labelCol={{ span: 24 }}>
              <Select
                placeholder="Wybierz kolor podstawowych produktów!"
                options={availableColors}
                value={selectedColor}
                onChange={(value) => updateColor(value)}
                showSearch
                filterOption={createFilterOption()}
                filterSort={(optionA: any, optionB: any) =>
                  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Collapse.Panel>
    </SectionCollapseContainer>
  );
};
export default SystemFilteringComponent;

const SectionCollapseContainer = styled(Collapse)`
  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    padding: 8px 8px !important;
  }

  margin-bottom: 16px;
`;
