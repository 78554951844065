import { Card, Spin } from "antd";
import { is } from "immer/dist/internal";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { IRootState } from "../../../../models/Models";
import { calculateOffer, updateStatusCalcResult } from "../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import RoofStep from "./RoofStep";

type Props = {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  calcLabour?: boolean;
};

const RoofSteps: FC<Props> = ({ activeStep, setActiveStep, calcLabour }) => {
  const dispatch = useAppDispatch();

  const scale = useAppSelector((state) => state.drawingScale);
  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);
  const metalSheets = useAppSelector((state) => state.metalSheets);

  const calcResult = useAppSelector((state: IRootState) => state.calcResult);
  const general = useAppSelector((state: IRootState) => state.general);

  const isRoofPlanesExist = () => {
    // return roofPlanesState && roofPlanesState.length > 0 && !drawRoofProcess.isActive ? true : false;
    return true;
  };

  const selectStep = (number: number) => {
    setActiveStep(number);
  };

  const isActive = (number: number): boolean => {
    return number === activeStep ? true : false;
  };

  // const isReadyToCalc = () => {
  //   return !calcResult.isCalculated && calcMaterialsData.roofSystemId ? true : false;
  // };

  const isReadyToCalc = () => {
    let isReady = calcMaterialsData.roofSystemId !== null && calcMaterialsData?.roofSystemId !== "";

    if (isReady) {
      if (calcMaterialsData.systemType === 0 || calcMaterialsData.systemType === -1) {
        // TU nic nie trzeba sprawdzać
      } else {
        isReady = metalSheets.length > 0 ? true : false;
      }
    }

    return isReady;
  };

  const isReadyToShowResult = () => {
    return calcResult.isCalculated ? true : false;
  };

  const isReadyToGetReport = () => {
    return calcResult.isCalculated ? true : false;
  };

  // TODO: Funkcja wywołująca obliczenia i przekierowanie do strony WYNIKI
  // Funkcja będzie równiez potrzebna do wywołania na stronie wyniki jako OBLICZ PONOWNIE
  // Może zawartość tej funkcji trzeba przenieśc gdzies indziej i tylko ja tu wywołać
  const calculate = (scale: number) => {
    // Sprawdza czy jest puste investmentId w General co skutkuje potrzebą utworzenia nowej inwestycji
    const shouldCreateNewInvestment = general.investmentId === null || general.investmentId === "" ? true : false;

    dispatch(calculateOffer(shouldCreateNewInvestment));
    selectStep(6); // Będzie tylko tu. W Oblicz ponownie nie będzie to potrzebne bo już będzie na tym Step
  };

  return (
    <Container>
      <Section>
        <RoofStep title="Geometria" active={isActive(1)} open={() => selectStep(1)} />

        <RoofStep
          title="Podgląd"
          active={isActive(3)}
          open={() => selectStep(3)}
          disabled={!isRoofPlanesExist()}
        />

        {!calcLabour && (
          <>
            <RoofStep
              title="Materiały"
              active={isActive(4)}
              open={() => selectStep(4)}
              disabled={!isRoofPlanesExist()}
            />
            {/* <RoofStep
              title="Elementy dodatkowe"
              active={isActive(5)}
              open={() => selectStep(5)}
              disabled={!isRoofPlanesExist()}
            /> */}

            {/* Na początku jest wyłączony przycisk OBLICZ */}
            {/* Jeśłi są wypełnione wszystkie dane aby dokonać obliczeń to aktywuje się przycisk OBLICZ */}
            {/* Gdy obliczenia są wykonane to zmienia się na Wyniki */}
            {/* W Wynikach przycisk do ponownego obliczenia */}
            {/* W wynikach wykrywac czy zostało cos zmienione i powinno zostac przeliczone ponownie */}
            {isReadyToShowResult() && (
              <RoofStep title="Podsumowanie" active={isActive(6)} open={() => selectStep(6)} />
            )}
            {!isReadyToShowResult() && (
              <LastButton disabled={isReadyToCalc() ? false : true} onClick={() => calculate(scale)}>
                PODSUMOWANIE
              </LastButton>
            )}

            {isReadyToGetReport() && (
              <RoofStep
                title="Oferta"
                active={isActive(7)}
                open={() => selectStep(7)}
                // disabled={!isRoofPlanesExist()}
              />
            )}
          </>
        )}
      </Section>
    </Container>
  );
};

export default RoofSteps;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const LastButton = styled(Card).attrs((props: { $active?: boolean; disabled?: boolean }) => props)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 50px;
  background: #469218;

  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;

  cursor: pointer;

  ${(props) =>
    props.disabled
      ? css`
          background: #cbcbcb;
          opacity: 0.4;
          color: #5a5a5a;
        `
      : css`
          background: ${() => (props.$active ? "#4b802b" : "")};
          color: ${() => (props.$active ? "#fff" : "")};

          &:hover {
            color: #fff;
            background-color: #4b802b;
          }
        `}
`;
