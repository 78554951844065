import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IRoofEdge, IRoofPlane, IRoofPoint, IUser } from "../../models/Models";

const initialState: Array<IRoofEdge> = [];

export const roofEdgesSlice = createSlice({
  name: "roofEdges",
  initialState,
  reducers: {
    addRoofEdge: (state, action: PayloadAction<IRoofEdge>) => {
      let e = action.payload;
      return [...state, e];
    },
    addRoofEdges: (state, action: PayloadAction<Array<IRoofEdge>>) => {
      let es = action.payload;
      return [...state, ...es];
    },
    replaceRoofEdges: (state, action: PayloadAction<Array<IRoofEdge>>) => {
      let es = action.payload;
      return es;
    },
    updateRoofEdgeType: (state, action: PayloadAction<IRoofEdge>) => {
      let model = action.payload;

      let index = state.findIndex((o) => o.id === model.id);
      state[index] = { ...state[index], type: model.type };
    },
    removeRoofEdge: (state, action: PayloadAction<IRoofEdge>) => {
      let e = action.payload;
      return state.filter((o) => o.id !== e.id);
    },
    clearRoofEdges: (state) => {
      return initialState;
    },
    setSavedRoofEdges: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRoofEdge,
  addRoofEdges,
  replaceRoofEdges,
  removeRoofEdge,
  clearRoofEdges,
  updateRoofEdgeType,
  setSavedRoofEdges,
} = roofEdgesSlice.actions;
export default roofEdgesSlice.reducer;
