import * as React from "react";
const DomaLogoComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    viewBox="0 0 1643 316"
    {...props}
  >
    <path
      d="M0 239.66V156.86C0 146.42 1.93999 136.65 5.81999 127.54C9.68999 118.44 15.06 110.46 21.93 103.59C28.79 96.7299 36.77 91.2799 45.88 87.2499C54.98 83.2199 64.6 81.2099 74.75 81.2099H233.64C246.77 81.2099 259.3 84.6399 271.24 91.4999L277.95 95.5299V2.87988H308.39V315.74H277.94V301.42L271.23 305.45C259.29 312.32 246.76 315.74 233.63 315.74H74.74C64.59 315.74 54.97 313.73 45.87 309.7C36.76 305.67 28.79 300.23 21.92 293.36C15.05 286.5 9.68 278.44 5.81 269.19C1.93 259.96 0 250.11 0 239.66ZM30.88 156.86V239.66C30.88 245.93 32 251.82 34.24 257.34C36.48 262.86 39.61 267.63 43.64 271.66C47.67 275.69 52.37 278.9 57.74 281.28C63.11 283.67 68.78 284.86 74.75 284.86H233.64C239.91 284.86 245.72 283.67 251.1 281.28C256.47 278.9 261.17 275.68 265.2 271.66C269.23 267.63 272.36 262.86 274.6 257.34C276.84 251.82 277.96 245.93 277.96 239.66V156.86C277.96 144.33 273.71 133.74 265.2 125.08C256.7 116.43 246.18 112.1 233.65 112.1H74.74C68.77 112.1 63.1 113.3 57.73 115.68C52.36 118.07 47.66 121.27 43.63 125.3C39.6 129.33 36.47 134.03 34.23 139.4C32 144.78 30.88 150.6 30.88 156.86Z"
      fill="#6D6D6D"
    />
    <path
      d="M462.34 81.2197H621.23C631.67 81.2197 641.44 83.2297 650.55 87.2597C659.65 91.2897 667.56 96.7397 674.27 103.6C680.98 110.47 686.35 118.45 690.38 127.55C694.41 136.65 696.42 146.42 696.42 156.87V239.67C696.42 250.12 694.41 259.96 690.38 269.21C686.35 278.46 680.98 286.52 674.27 293.38C667.56 300.25 659.65 305.69 650.55 309.72C641.44 313.75 631.67 315.76 621.23 315.76H462.34C452.19 315.76 442.57 313.75 433.47 309.72C424.36 305.69 416.39 300.25 409.52 293.38C402.65 286.52 397.28 278.46 393.41 269.21C389.53 259.96 387.59 250.12 387.59 239.67V156.87C387.59 146.43 389.53 136.66 393.41 127.55C397.28 118.45 402.65 110.47 409.52 103.6C416.38 96.7397 424.36 91.2897 433.47 87.2597C442.57 83.2397 452.2 81.2197 462.34 81.2197ZM418.48 156.86V239.66C418.48 245.93 419.6 251.82 421.84 257.34C424.08 262.86 427.21 267.63 431.24 271.66C435.27 275.69 439.97 278.9 445.34 281.28C450.71 283.67 456.38 284.86 462.35 284.86H621.24C627.51 284.86 633.32 283.67 638.7 281.28C644.07 278.9 648.77 275.68 652.8 271.66C656.83 267.63 659.96 262.86 662.2 257.34C664.44 251.82 665.56 245.93 665.56 239.66V156.86C665.56 144.33 661.31 133.74 652.8 125.08C644.3 116.43 633.78 112.1 621.25 112.1H462.34C456.37 112.1 450.7 113.3 445.33 115.68C439.96 118.07 435.26 121.27 431.23 125.3C427.2 129.33 424.07 134.03 421.83 139.4C419.6 144.78 418.48 150.6 418.48 156.86Z"
      fill="#6D6D6D"
    />
    <path
      d="M1090.29 81.2197C1100.73 81.2197 1110.43 83.2297 1119.38 87.2597C1128.33 91.2897 1136.24 96.7397 1143.1 103.6C1149.96 110.47 1155.33 118.45 1159.21 127.55C1163.08 136.65 1165.03 146.42 1165.03 156.87V315.76H1134.15V156.86C1134.15 150.59 1133.03 144.78 1130.79 139.4C1128.55 134.03 1125.42 129.33 1121.39 125.3C1117.36 121.27 1112.66 118.07 1107.29 115.68C1101.92 113.29 1096.25 112.1 1090.28 112.1H986.437V315.75H955.557V112.11H807.857V315.76H776.977V81.2197H1090.29Z"
      fill="#6D6D6D"
    />
    <path
      d="M1468.91 0L1642.37 315.76H1606.68L1449.48 29.82L1292.73 315.76H1257.04L1430.5 0H1468.91Z"
      fill={props.colorA || "#13B7DD"}
    />
    <path
      d="M1449.71 214.73C1461.44 214.73 1470.95 205.221 1470.95 193.49C1470.95 181.76 1461.44 172.25 1449.71 172.25C1437.98 172.25 1428.47 181.76 1428.47 193.49C1428.47 205.221 1437.98 214.73 1449.71 214.73Z"
      fill={props.colorA || "#13B7DD"}
    />
  </svg>
);
export default DomaLogoComponent;
