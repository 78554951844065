import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INotificationType } from "../../models/Models";

const initialState: INotificationType[] = [];

export const notificationTypesSlice = createSlice({
  name: "notificationTypes",
  initialState,
  reducers: {
    addNotificationTypesInReducer: (state, action: PayloadAction<Array<INotificationType>>) => {
      let e = action.payload;
      return e;
    },
  },
});

export const { addNotificationTypesInReducer } = notificationTypesSlice.actions;
export default notificationTypesSlice.reducer;
