import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, Input, message, Radio, Row, Select } from "antd";
import { IProduct, IProductCategory, IRootState, ISelectOption, ISheetMetalProcess } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import {
  addProductsGroup,
  IAddProductsGroup,
  IProductsGroup,
  IProductsGroupAddition,
  updateProductsGroup,
} from "../../../redux/productsGroups/productsGroupsSlice";
import ExtraProductProductsGroupsPosition from "./ExtraProductProductsGroupsPosition";
import { CATEGORIES_ALL_ROOF, EDGES_RELATIONS, TILE_SYSTEM_RELATIONS } from "../RoofConstants";
import SheetMetalProcessPosition from "../roofSystems/components/SheetMetalProcessPosition";

type Props = {
  system: IProductsGroup | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const ProductsGroupsDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);
  const statusApi = useAppSelector((state: IRootState) =>
    state.statusApi.find((x: any) => x.key === "addPreCoveringSystem")
  );

  const [productsGroupAdditions, setProductsGroupAdditions] = useState<IProductsGroupAddition[]>([]);
  const [sheetMetalProcesses, setSheetMetalProcesses] = useState<ISheetMetalProcess[]>([]);

  const productsGroups = useAppSelector((state: IRootState) => state.productsGroups);

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addProductsGroup"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addProductsGroup"));
    }
  }, [statusApi]);

  // Jesli coś zmieni się w grupach produktowych to ustawiamy ponownie dodatki i procesy
  useEffect(() => {
    if (productsGroups) {
      const s = productsGroups.find((x) => x.id === system?.id);
      if (s) {
        setProductsGroupAdditions(s.productsGroupAdditions);
        setSheetMetalProcesses(s.sheetMetalProcesses);
      }
    }
  }, [productsGroups]);

  useEffect(() => {
    if (system && system.productsGroupAdditions && system.productsGroupAdditions.length > 0) {
      setProductsGroupAdditions(system.productsGroupAdditions);
    }

    if (system && system.sheetMetalProcesses && system.sheetMetalProcesses.length > 0) {
      setSheetMetalProcesses(system.sheetMetalProcesses);
    }
  }, [system]);

  const [productsSelectForSheetMetal, setProductsSelectForSheetMetal] = useState<ISelectOption[]>([]);
  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForSheetMetal.length === 0) {
        setProductsSelectForSheetMetal(
          getProductsByCategoryForSelect(products, productCategories, ["supplementary-products"])
        );
      }

      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-tiles-concrete",
            "basic-products-tiles-ceramic",
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
            "wood",
            "supplementary-products",
            "lightning-protection-systems",
            "thermal-insulation",
            "roof-walkways-and-snow-guards",
            "roofing-accessories",
            "fasteners",
            "preliminary-covering",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  const getInitialValues = () => {
    return isNew()
      ? {
          name: "",
          description: "",
        }
      : {
          name: system?.name,
          description: system?.description,
        };
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "Grupa produktowa została utworzona!",
    });

    navigate(-1);
  };

  const onFinish = (values: any, newVersion: boolean) => {
    if (isNew() || newVersion) {
      let s: IAddProductsGroup = {
        name: values.name,
        description: values.description,
        productsGroupAdditions: productsGroupAdditions,
        sheetMetalProcesses: sheetMetalProcesses,
      };

      // Sprawdzam czy każda grupa ma przypisane produkty - jeśli nie to usuń
      const productsGroupAdditionsFiltered = [...productsGroupAdditions].filter((x) => x.productId !== "");
      s.productsGroupAdditions = productsGroupAdditionsFiltered;

      const sheetMetalProcessesFiltered = [...sheetMetalProcesses].filter((x) => x.productId !== "");
      s.sheetMetalProcesses = sheetMetalProcessesFiltered;

      dispatch(addProductsGroup(s, successCallback));
    } else {
      let s: IProductsGroup = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        productsGroupAdditions: productsGroupAdditions,
        sheetMetalProcesses: sheetMetalProcesses,
      };

      // Sprawdzam czy każda grupa ma przypisane produkty - jeśli nie to usuń
      const productsGroupAdditionsFiltered = [...productsGroupAdditions].filter((x) => x.productId !== "");
      s.productsGroupAdditions = productsGroupAdditionsFiltered;

      const sheetMetalProcessesFiltered = [...sheetMetalProcesses].filter((x) => x.productId !== "");
      s.sheetMetalProcesses = sheetMetalProcessesFiltered;

      dispatch(updateProductsGroup(s));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Container>
      {contextHolder}
      <Form
        id="productsGroupDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Section title={"Ogólne dane grupy produktowej"}>
          <Form.Item
            label="Nazwa grupy produktowej"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę grupy produktowej!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis grupy" name="description" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
        </Section>

        <Section
          title={"Produkty grupy (uzależnione od typu krawędzi, powierzchni dachu, itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newProductsGroupAddition: IProductsGroupAddition = {
                  id: uuidv4(),
                  productId: "",
                  use: 0,
                  relation: "none",
                  correction: 0,
                  productsGroupId: system?.id as string,
                };
                setProductsGroupAdditions([newProductsGroupAddition, ...productsGroupAdditions]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {productsGroupAdditions.map((addition) => {
            return (
              <ExtraProductProductsGroupsPosition
                key={addition.id}
                addition={addition}
                selectedCategories={[
                  "basic-products-tiles-concrete",
                  "basic-products-tiles-ceramic",
                  "basic-products-sheet-metal",
                  "basic-products-cut-sheet-metal",
                  "basic-products-sheet-metal-trapezoidal",
                  "basic-products-sheet-metal-standing-seam",
                  "wood",
                  "supplementary-products",
                  "lightning-protection-systems",
                  "thermal-insulation",
                  "roof-walkways-and-snow-guards",
                  "roofing-accessories",
                  "fasteners",
                  "preliminary-covering",
                ]}
                readyOptions={productsSelectForAdditions}
                products={products}
                productCategories={productCategories}
                useDisabled={false}
                relationDisabled={false}
                correctionDisabled={false}
                relations={[...TILE_SYSTEM_RELATIONS]}
                updateAddition={(addition: any) => {
                  const newAdditions = [...productsGroupAdditions];
                  const index = newAdditions.findIndex((x) => x.id === addition.id);

                  if (index >= 0) {
                    newAdditions[index] = addition;
                    setProductsGroupAdditions(newAdditions);
                  }
                }}
                deleteAddition={(addition: any) => {
                  const newAdditions = [...productsGroupAdditions].filter((x) => x.id !== addition.id);
                  setProductsGroupAdditions(newAdditions);
                }}
              />
            );
          })}
        </Section>

        <Section
          title={"Obróbki blacharskie (krawędzi koszowej, pasa nadrynnowego, komina itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newSheetMetalProcesses: ISheetMetalProcess = {
                  id: uuidv4(),
                  productId: "",
                  sheetWidth: 0,
                  sheetLength: 0,
                  stripWidth: 0,
                  overlap: 0,
                  relation: "none",
                  correction: 0,
                  tileSystemId: system?.id as string,
                };

                setSheetMetalProcesses([newSheetMetalProcesses, ...sheetMetalProcesses]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {sheetMetalProcesses.map((process) => {
            return (
              <SheetMetalProcessPosition
                key={process.id}
                sheetMetalProcess={process}
                selectedCategories={["supplementary-products"]}
                readyOptions={productsSelectForSheetMetal}
                products={products}
                productCategories={productCategories}
                relations={[...EDGES_RELATIONS]}
                updateSheetMetalProcess={(process) => {
                  const newSheetMetalProcesses = [...sheetMetalProcesses];
                  const index = newSheetMetalProcesses.findIndex((x) => x.id === process.id);

                  if (index >= 0) {
                    newSheetMetalProcesses[index] = process;
                    setSheetMetalProcesses(newSheetMetalProcesses);
                  }
                }}
                deleteSheetMetalProcess={(process) => {
                  const newSheetMetalProcesses = [...sheetMetalProcesses].filter((x) => x.id !== process.id);
                  setSheetMetalProcesses(newSheetMetalProcesses);
                }}
              />
            );
          })}
        </Section>
      </Form>
    </Container>
  );
};

export default ProductsGroupsDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
