import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Collapse, Form, Image, Radio, Row, Switch, Tooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

const DEFAULT_WINDOW_HOLES = [
  { width: 55, height: 78 },
  { width: 55, height: 98 },
  { width: 66, height: 98 },
  { width: 78, height: 98 },
  { width: 94, height: 98 },
  { width: 66, height: 118 },
  { width: 78, height: 118 },
  { width: 97, height: 118 },
  { width: 114, height: 118 },
  { width: 66, height: 140 },
  { width: 78, height: 140 },
  { width: 94, height: 140 },
  { width: 114, height: 140 },
  { width: 134, height: 140 },
  { width: 78, height: 160 },
  { width: 94, height: 160 },
  { width: 114, height: 160 },
  { width: 134, height: 160 },
];

const DEFAULT_HATCH_HOLES = [
  { width: 46, height: 55 },
  { width: 46, height: 75 },
  { width: 54, height: 75 },
  { width: 54, height: 83 },
  { width: 86, height: 86 },
  { width: 86, height: 87 },
  { width: 94, height: 98 },
];

// const DEFAULT_HATCH_HOLES = [
//   { width: 66, height: 78 },
//   { width: 66, height: 98 },
//   { width: 66, height: 118 },
//   { width: 78, height: 98 },
//   { width: 78, height: 118 },
//   { width: 94, height: 98 },
// ];

export interface IWindowHole {
  width: number;
  height: number;
}

type Props = {
  close: () => void;
  isAutomatic: boolean;
  readyForWindowPoint: boolean;
  setReadyForWindowPoint: any;
  setSelectedWindowHole: any;
  onCancelClick: any;
};

const PanelFlowWindowHoles: FC<Props> = ({
  close,
  isAutomatic,
  readyForWindowPoint,
  setReadyForWindowPoint,
  setSelectedWindowHole,
  onCancelClick,
}) => {
  const dispatch = useAppDispatch();

  // Pola na wymiary do akceptacji
  const [holeWidth, setHoleWidth] = useState<number>(0);
  const [holeHeight, setHoleHeight] = useState<number>(0);

  // Pola na wymiary niestandardowe do akceptacji
  const [customHoleWidth, setCustomHoleWidth] = useState<number>(0);
  const [customHoleHeight, setCustomHoleHeight] = useState<number>(0);

  // Czy aktywne standardowe wymiary okien dachowych
  const [activeCustomDimension, setActiveCustomDimension] = useState<boolean>(false);

  useEffect(() => {
    if (activeCustomDimension) {
      setHoleWidth(customHoleWidth);
      setHoleHeight(customHoleHeight);
    }
  }, [customHoleWidth, customHoleHeight, activeCustomDimension]);

  const chunkArray = (array: any[], chunkSize: number) => {
    let result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const onClickAddHole = () => {
    setSelectedWindowHole({ width: holeWidth, height: holeHeight });
    setReadyForWindowPoint(true);
  };

  const getChunkedData = (array: any[], size: number) => chunkArray(array, size);

  type HoleVersionComponentProps = {
    width: number;
    height: number;
  };

  const HoleVersionComponent: FC<HoleVersionComponentProps> = ({ width, height }) => {
    return (
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col span={4}>
          <Checkbox
            checked={holeWidth === width && holeHeight === height}
            onChange={(e) => {
              setHoleWidth(width);
              setHoleHeight(height);
            }}
          />
        </Col>
        <Col span={20}>
          <Row>
            <Col span={4} style={{ textAlign: "right", fontWeight: "bold" }}>
              {width}
            </Col>
            <Col span={3} style={{ textAlign: "center" }}>
              x
            </Col>
            <Col span={6} style={{ fontWeight: "bold" }}>
              {height}
            </Col>
            <Col span={6}>[cm]</Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Container bodyStyle={{ padding: "0px" }}>
      <SimpleContent
        title="Standardowe wymiary okien"
        size="small"
        headStyle={{ backgroundColor: "#f5f5f5" }}
        bodyStyle={{
          opacity: !activeCustomDimension && !readyForWindowPoint ? 1 : 0.5,
          pointerEvents: !activeCustomDimension && !readyForWindowPoint ? "all" : "none",
        }}
      >
        {getChunkedData(DEFAULT_WINDOW_HOLES, 2).map((chunk, index) => {
          return (
            <Row key={index} style={{ display: "flex" }}>
              {chunk.map((hole, idx) => (
                <Col key={idx} span={12}>
                  <HoleVersionComponent width={hole.width} height={hole.height} />
                </Col>
              ))}
            </Row>
          );
        })}
      </SimpleContent>

      <SimpleContent
        title="Standardowe wymiary wyłazów dachowych"
        size="small"
        headStyle={{ backgroundColor: "#f5f5f5" }}
        bodyStyle={{
          opacity: !activeCustomDimension && !readyForWindowPoint ? 1 : 0.5,
          pointerEvents: !activeCustomDimension && !readyForWindowPoint ? "all" : "none",
        }}
      >
        {getChunkedData(DEFAULT_HATCH_HOLES, 2).map((chunk, index) => {
          return (
            <Row key={index} style={{ display: "flex" }}>
              {chunk.map((hole, idx) => (
                <Col key={idx} span={12}>
                  <HoleVersionComponent width={hole.width} height={hole.height} />
                </Col>
              ))}
            </Row>
          );
        })}
      </SimpleContent>

      <SimpleContent
        title={
          <Row>
            <Col span={3}>
              <Switch checked={activeCustomDimension} onChange={(checked) => setActiveCustomDimension(checked)} />
            </Col>
            <Col span={20} style={{ marginLeft: "4px", display: "flex", alignItems: "center" }}>
              Niestandardowy wymiar
            </Col>
          </Row>
        }
        size="small"
        headStyle={{
          backgroundColor: "#f5f5f5",
          opacity: !readyForWindowPoint ? 1 : 0.5,
          pointerEvents: !readyForWindowPoint ? "all" : "none",
        }}
      >
        <Row
          style={{
            display: "flex",
            gap: "16px",
            opacity: activeCustomDimension && !readyForWindowPoint ? 1 : 0.5,
            pointerEvents: activeCustomDimension && !readyForWindowPoint ? "all" : "none",
          }}
        >
          <Col span={11}>
            <Form.Item label="Szerokość otworu [cm]" style={{ display: "flex", width: "100%" }}>
              <DomaInputNumber
                value={customHoleWidth}
                onChange={(value) => setCustomHoleWidth(value as number)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Wysokość otworu [cm]" style={{ display: "flex", width: "100%" }}>
              <DomaInputNumber
                value={customHoleHeight}
                onChange={(value) => setCustomHoleHeight(value as number)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </SimpleContent>

      <Row style={{ display: "flex", justifyContent: "space-between", gap: "8px", padding: "8px" }}>
        <div style={{ display: "flex", gap: "16px" }}>
          <Button type="primary" onClick={() => onClickAddHole()} disabled={readyForWindowPoint}>
            Dodaj otwór
          </Button>
          <Button type="primary" danger onClick={() => onCancelClick()} disabled={!readyForWindowPoint}>
            Anuluj
          </Button>
        </div>

        <Button onClick={() => close()}>Zakończ</Button>
      </Row>
    </Container>
  );
};
export default PanelFlowWindowHoles;

const GutterRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GutterCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(Card)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
  margin-top: 66px;
  padding: 0px;
  width: 400px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 999;
`;

const SimpleContent = styled(Card)`
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
