import {
  ICompany,
  IPrincingDataModel,
  IPrincingEdge,
  IPrincingPlane,
  IPrincingRequestModel,
  IPrincingWindow,
  IProduct,
  IProductCategory,
  IProductColor,
  IProductModel,
  IResultItemResponse,
  IRootState,
} from "../models/Models";
import {
  getNettValueSumWithAllDiscount,
  getNettValueWithAllDiscount,
  getProductsWithDiscount,
} from "../modules/roofs/managers/DiscountManager";
import { useAppSelector } from "../redux/hooks";
import { getTwoPoints3DLength } from "./Helpers";
import store, { RootState } from "../redux/store";

export const getDataForSelect = (elements: Array<any>) => {
  const result = [];
  result.push({ value: "all", label: "Wszystkie" });
  if (elements) {
    for (const a of elements) {
      result.push({
        value: a.id,
        label: a.name,
      });
    }
  }
  return result;
};

export const getDataProductsForSelect = (
  products: IProduct[],
  companies: ICompany[],
  categories: IProductCategory[],
  colors: IProductColor[],
  productModels: IProductModel[]
) => {
  const result = [];
  for (const p of products) {
    const category = categories?.find((x) => x.id === p.productCategoryId);

    if (category && category.code === "others") continue; // Wykluczamy produkty na zamówienie

    const company = companies?.find((x) => x.id === p.companyId);
    const model = productModels?.find((x) => x.id === p.productModelId);
    const color = colors?.find((x) => x.id === p.productColorId);

    let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

    let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
    if (p.coating.length > 0) {
      productName += `, ${p.coating}`;
    }
    productName += ")";

    result.push({
      value: p.id,
      label: `${productName}`,
    });
  }
  return result;
};

export const getProductCategoryUrlCodeFromPathName = (pathname: string) => {
  return pathname.split("/")[2];
};

export const getDataForPrincing = (value: any, state: RootState) => {
  const activePriceSystemId = value || state.contractor.activePriceSystemId;
  const princingSystemId = activePriceSystemId || ""; // Id systemu do wyceny
  const scale = state.drawingScale;
  const roofPoints = state.rsoPoints.length > 0 ? state.rsoPoints : state.roofPoints;
  const roofPlanes = state.rsoPoints.length > 0 ? state.rsoPlanes : state.roofPlanes;
  const roofEdges = state.rsoPoints.length > 0 ? state.rsoEdges : state.roofEdges;
  const drainPipeCount = state.calcMaterialsData.drainPipeCount;

  let princingDataModel: IPrincingDataModel = {
    edges: [],
    planes: [],
    windows: [],
    chimneys: [],
    projectPrice: 0, // TODO pobrac cenę projektu
    additionalsPrice: 0, // TODO pobrac cenę za elementy dodatkowe
    gutterDrainPoints: drainPipeCount, // TODO ilość punktów spustowych orynnowania
  };

  const model: IPrincingRequestModel = {
    princingSystemId: princingSystemId,
    princingDataModel: princingDataModel,
  };

  const edges = [];
  for (const edg of roofEdges) {
    const s = roofPoints?.find((x) => x.id === edg.startPointId);
    const e = roofPoints?.find((x) => x.id === edg.endPointId);

    const sn = { x: s?.x as number, y: s?.y as number, z: s?.z as number };
    const en = { x: e?.x as number, y: e?.y as number, z: e?.z as number };

    const edge: IPrincingEdge = {
      length: getTwoPoints3DLength(sn, en, scale),
      type: edg.type || 0,
    };
    edges.push(edge);
  }
  princingDataModel.edges = edges;

  const planes = [];
  for (const p of roofPlanes) {
    const plane: IPrincingPlane = {
      area: Number((p.area / scale ** 2).toFixed(2)),
    };
    planes.push(plane);
  }
  princingDataModel.planes = planes;

  const windows = [];
  const windowsInState = state.windows;
  if (windowsInState) {
    for (const w of windowsInState) {
      const product = state.products.find((x) => x.id === w.productId);

      if (product) {
        const window: IPrincingWindow = {
          width: product.width,
          height: product.height,
        };

        // Dodajemy tyle rquntity ile jest sztuk tego okna
        for (let index = 0; index < w.amount; index++) {
          windows.push(window);
        }
      }
    }
    princingDataModel.windows = windows;
  }

  model.princingDataModel = princingDataModel;
  model.princingSystemId = princingSystemId;

  return model;
};
