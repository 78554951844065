import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRSO } from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

const initialState: IRSO[] = [];

export const rsoPlanesSlice = createSlice({
  name: "rsoPlanes",
  initialState,
  reducers: {
    addRSOPlane: (state, action: PayloadAction<IRSO>) => {
      let p = action.payload;
      return [...state, p];
    },
    addRSOPlanes: (state, action: PayloadAction<IRSO[]>) => {
      let ps = action.payload;
      return [...state, ...ps];
    },
    setRSOPlanes: (state, action: PayloadAction<IRSO[]>) => {
      let array = action.payload;
      return array;
    },
    updateRSOPlane: (state, action: PayloadAction<IRSO>) => {
      let p = action.payload;
      return state.map((rso) => {
        if (rso.id === p.id) {
          return {
            ...rso,
            angle: p.angle,
            hover: p.hover,
            active: p.active,
            declineVectoor: p.declineVectoor,
            area: p.area,
          };
        } else {
          return rso;
        }
      });
    },
    removeRSOPlane: (state, action: PayloadAction<IRSO>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRSOPlanes: (state) => {
      return initialState;
    },
    setSavedRSOPlanes: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRSOPlane,
  addRSOPlanes,
  updateRSOPlane,
  setRSOPlanes,
  removeRSOPlane,
  clearRSOPlanes,
  setSavedRSOPlanes,
} = rsoPlanesSlice.actions;
export default rsoPlanesSlice.reducer;
