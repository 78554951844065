import { Button, Card, Form, Input, InputNumber, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import PageHeader from "../../../layout/components/PageHeader";
import { IContractor } from "../../../models/Models";
import { updateContractorData } from "../../../redux/contractors/contractorSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

export default function ContractorDataPage() {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const contractorData = useAppSelector((state) => state.contractor);

  const [contractorDataState, setContractorDataState] = useState<{}>();

  useEffect(() => {
    if (contractorData) {
      setContractorDataState({
        name: contractorData.name,
        nip: contractorData.nip,
        city: contractorData.city,
        description: contractorData.description,
        email: contractorData.email,
        phone: contractorData.phone,
        postCode: contractorData.postCode,
        regon: contractorData.regon,
        street: contractorData.street,
      });
    }
  }, [contractorData]);

  const onFinish = (values: any) => {
    dispatch(
      updateContractorData(
        {
          ...contractorData,
          name: values.name,
          nip: values.nip,
          city: values.city,
          description: values.description,
          email: values.email,
          phone: values.phone,
          postCode: values.postCode,
          regon: values.regon,
          street: values.street,
        },
        () => message.success(`Dane wykonawcy zostały zaktualizowane.`)
      )
    );
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Container>
      <PageHeader
        title={"Dane wykonawcy"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="primary" form="contractorDataForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
          </div>
        }
      />

      <Section>
        {contractorDataState && (
          <Form
            id="contractorDataForm"
            style={{ width: "100%" }}
            form={form}
            autoComplete="off"
            layout="vertical"
            initialValues={contractorDataState}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="Nazwa wykonawcy" name="name">
              <Input />
            </Form.Item>

            <Form.Item label="Miasto" name="city">
              <Input />
            </Form.Item>

            <Form.Item label="Kod pocztowy" name="postCode">
              <Input />
            </Form.Item>

            <Form.Item label="Ulica" name="street">
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>

            <Form.Item label="Telefon" name="phone">
              <Input />
            </Form.Item>

            <Form.Item label="NIP" name="nip">
              <Input />
            </Form.Item>

            <Form.Item label="REGON" name="regon">
              <Input />
            </Form.Item>

            <Form.Item label="Opis" name="description">
              <Input />
            </Form.Item>
          </Form>
        )}
      </Section>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
