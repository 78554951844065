import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
import DomaInputNumber from "../../../../helpers/DomaInputNumber";
import { createFilterOption } from "../../../../helpers/Helpers";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { IProduct, IProductCategory, IRootState, ISteelSystemAddition } from "../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

interface ExtraProductSteelSystemPositionProps {
  addition?: ISteelSystemAddition;
  useDisabled: boolean;
  relationDisabled: boolean;
  correctionDisabled: boolean;
  products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
  productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
  relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
  selectedCategories: string[];
  readyOptions?: any[];
  updateAddition: (addition: ISteelSystemAddition) => void;
  deleteAddition: (addition: ISteelSystemAddition) => void;
}

interface AdditionState {
  productId: string | null;
  use: number;
  relation: string;
  correction: number;
}

const ExtraProductSteelSystemPosition: React.FC<ExtraProductSteelSystemPositionProps> = ({
  addition,
  useDisabled,
  relationDisabled,
  correctionDisabled,
  products,
  productCategories,
  relations,
  selectedCategories,
  readyOptions,
  updateAddition,
  deleteAddition,
}) => {
  const firstRender = useFirstRender();

  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);

  const [additionState, setAdditionState] = React.useState<AdditionState>({
    productId: null,
    use: 0,
    relation: "none",
    correction: 0,
  });

  useEffect(() => {
    setAdditionState({
      productId: addition?.productId || null,
      use: addition?.use || 0,
      relation: addition?.relation || "none",
      correction: addition?.correction || 0,
    });
  }, [addition]);

  useEffect(() => {
    if (
      addition &&
      !firstRender &&
      (additionState.use !== addition.use ||
        additionState.correction !== addition.correction ||
        additionState.relation !== addition.relation ||
        additionState.productId !== addition.productId)
    ) {
      updateAddition({
        ...addition,
        ...additionState,
      });
    }
  }, [additionState]);

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  const getProductUseByProductId = (id: string) => {
    const product = products.find((x) => x.id === id);
    return product?.use || 0;
  };

  return (
    <Row gutter={16} key={addition?.id}>
      <Col span={14}>
        <Form.Item label={"Nazwa produktu"} labelCol={{ span: 24 }}>
          <Select
            placeholder="Wybierz produkt!"
            value={additionState.productId}
            options={
              readyOptions
                ? readyOptions
                : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
            }
            showSearch
            filterOption={createFilterOption()}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={(value) => {
              setAdditionState((prevState) => ({
                ...prevState,
                productId: value,
                use: value ? getProductUseByProductId(value) : 0,
              }));
            }}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Użycie" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={additionState.use}
            disabled={useDisabled}
            min={0}
            style={{ width: "100%" }}
            onChange={(value) => {
              setAdditionState((prevState) => ({ ...prevState, use: value as number }));
            }}
          />
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item label="Zależność" labelCol={{ span: 24 }}>
          <Select
            value={additionState.relation}
            disabled={relationDisabled}
            placeholder="Wybierz zależność!"
            options={relations.sort((a, b) => a.index - b.index)}
            dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
            filterOption={createFilterOption()}
            showSearch
            onChange={(value) => {
              setAdditionState((prevState) => ({ ...prevState, relation: value }));
            }}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Korekta w [%]" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={additionState.correction}
            disabled={correctionDisabled}
            allowNegative
            min={-100}
            max={200}
            style={{ width: "100%" }}
            onChange={(value) => {
              setAdditionState((prevState) => ({ ...prevState, correction: value as number }));
            }}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              if (addition) {
                deleteAddition(addition);
              }
            }}
          >
            <DeleteOutlined />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ExtraProductSteelSystemPosition;
