import { Button } from "antd";
import React, { FC, memo, useEffect, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { Html } from "react-konva-utils";
import { getTwoPoints2DLength } from "../../../../../helpers/Helpers";
import { useFirstRender } from "../../../../../hooks/useFirstRender";
import { IBasicPoint } from "../../../../../models/Models";
import { IRoofProcessFunctionsStatus } from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { useAppSelector } from "../../../../../redux/hooks";
import { generateTicks } from "./DomaUnit";

// CONSTANTS
const DIST = 5;
const LEN = 70;

type Props = {
  zoomScale: number;
  shouldFinish: any;
  resetShouldFinish: any;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  setDistFromDraw: any;
  acceptDistance: any;
};

const DomaDistanceFromDraw: FC<Props> = memo(
  ({ zoomScale, shouldFinish, setDistFromDraw, acceptDistance, resetShouldFinish }) => {
    const firstRender = useFirstRender();
    const scale = useAppSelector((state) => state.drawingScale);

    const [points, setPoints] = useState<{ start: IBasicPoint; end: IBasicPoint }>({
      start: { x: 0, y: 0 },
      end: { x: 200, y: 0 },
    });

    const mp = useAppSelector((state) => state.mousePosition);

    // 0 - nic
    // 1 - start
    // 2 - end
    const [activePoint, setActivePoint] = useState<number>(0);

    const [saved, setSaved] = useState<{ start: IBasicPoint; end: IBasicPoint; startMove: IBasicPoint }>(); // zapisane punkty start, stop oraz położenie myszki podczas kliknięcia do przesunięcia
    const [inMoving, setInMoving] = useState<boolean>(false);

    const [isPlaced, setIsPlaced] = useState(false); // Czy wstawiony komponent

    useEffect(() => {
      function handleMouseUp(event: any) {
        if (event.button === 0) {
          setActivePoint(0);
          setInMoving(false);
          deletepointer(event.evt);
        }
      }

      function handleMouseRightDown(event: any) {
        event.preventDefault();

        rotateLine();
      }

      function handleMouseDown(event: any) {
        if (!isPlaced) {
          setIsPlaced(true);
          setPoints({
            start: { x: mp.x, y: mp.y },
            end: { x: mp.x + 200, y: mp.y },
          });
        }
      }

      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("contextmenu", handleMouseRightDown);
      window.addEventListener("mousedown", handleMouseDown);

      return () => {
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("contextmenu", handleMouseRightDown);
        window.removeEventListener("mousedown", handleMouseDown);
      };
    }, [points, mp]);

    useEffect(() => {
      if (shouldFinish === true) {
        setDistFromDraw(getLengthLine());
        resetShouldFinish();
      }
    }, [shouldFinish]);

    const handleFirstClick = () => {
      setIsPlaced(true);
      // Możesz tutaj również ustawić początkowe punkty komponentu
      setPoints({ start: mp, end: { x: mp.x, y: mp.y } });
    };

    const getLengthLine = () => {
      return Number((getTwoPoints2DLength(points.start, points.end, 1) / scale).toFixed(2));
    };

    useEffect(() => {
      if (activePoint === 1) {
        let point = mp;

        // Sprawdzenie czy poziomo i pionowo dla punktu start
        if (Math.abs(mp.x - points.end.x) <= DIST) {
          point = { x: points.end.x, y: mp.y };
        }
        if (Math.abs(mp.y - points.end.y) <= DIST) {
          point = { x: mp.x, y: points.end.y };
        }

        setPoints({ ...points, start: { x: point.x, y: point.y } });
      } else if (activePoint === 2) {
        let point = mp;
        // Sprawdzenie czy poziomo i pionowo dla punktu start
        if (Math.abs(mp.x - points.start.x) <= DIST) {
          point = { x: points.start.x, y: mp.y };
        }
        if (Math.abs(mp.y - points.start.y) <= DIST) {
          point = { x: mp.x, y: points.start.y };
        }

        setPoints({ ...points, end: { x: point.x, y: point.y } });
      } else if (inMoving) {
        const vect = { x: mp.x - (saved?.startMove.x as number), y: mp.y - (saved?.startMove.y as number) };

        setPoints((prev) => ({
          start: { x: (saved?.start.x as number) + vect.x, y: (saved?.start.y as number) + vect.y },
          end: { x: (saved?.end.x as number) + vect.x, y: (saved?.end.y as number) + vect.y },
        }));
      }
    }, [mp]);

    const rotateLine = () => {
      const centerX = (points.start.x + points.end.x) / 2;
      const centerY = (points.start.y + points.end.y) / 2;

      const rotate = (point: any, angle: any) => {
        const cosAngle = Math.cos(angle);
        const sinAngle = Math.sin(angle);

        const dx = point.x - centerX;
        const dy = point.y - centerY;

        const newX = cosAngle * dx - sinAngle * dy;
        const newY = sinAngle * dx + cosAngle * dy;

        return { x: Math.round(newX + centerX), y: Math.round(newY + centerY) };
      };

      const angle = (Math.PI / 180) * 90; // 90 stopni w radianach
      const newStart = rotate(points.start, angle);
      const newEnd = rotate(points.end, angle);

      // Aktualizacja stanu
      setPoints((prevPoints) => ({
        start: newStart,
        end: newEnd,
      }));
    };

    const getHelpLine = (point: { x: number; y: number }) => {
      const newLen = LEN / zoomScale;

      const direction = Math.atan2(points.end.y - points.start.y, points.end.x - points.start.x);

      const newX = point.x + newLen * Math.cos(direction - Math.PI / 2);
      const newY = point.y + newLen * Math.sin(direction - Math.PI / 2);

      return { x: newX, y: newY };
    };

    // Linia rysowana w dół od punktu start
    const getHelpLineSecond = (point: { x: number; y: number }) => {
      const newLen = LEN / zoomScale;

      const direction = Math.atan2(points.end.y - points.start.y, points.end.x - points.start.x);

      const newX = point.x - newLen * Math.cos(direction - Math.PI / 2);
      const newY = point.y - newLen * Math.sin(direction - Math.PI / 2);

      return { x: newX, y: newY };
    };

    const getInputLocation = () => {
      const z = -40 / zoomScale;

      const p1 = points.start;
      const p2 = points.end;
      const d = Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      const dx = (p2.x - p1.x) / d;
      const dy = (p2.y - p1.y) / d;
      const cx = (p1.x + p2.x) / 2;
      const cy = (p1.y + p2.y) / 2;
      const x = cx + z * dy;
      const y = cy - z * dx;
      return { x, y };
    };

    const setPointer = (e: any) => {
      // e.target.getStage().container().style.cursor = "move";
      // e.target.attrs.fill = "#000000";
    };

    const deletepointer = (e: any) => {
      //   if (!isMouseDown) {
      //     e.target.getStage().container().style.cursor = "default";
      //     e.target.attrs.fill = "#ffffff";
      //   }
    };

    return (
      <Group>
        <Group x={isPlaced ? 0 : mp.x} y={isPlaced ? 0 : mp.y}>
          {/* Zestaw dwóch linii pomocniczych (w dół i w górę) */}
          <Line
            points={[points.start.x, points.start.y, getHelpLine(points.start).x, getHelpLine(points.start).y]}
            closed={false}
            stroke="#000000"
            strokeWidth={1 / zoomScale}
          />
          <Line
            points={[
              points.start.x,
              points.start.y,
              getHelpLineSecond(points.start).x,
              getHelpLineSecond(points.start).y,
            ]}
            closed={false}
            stroke="#000000"
            strokeWidth={1 / zoomScale}
          />

          {/* Zestaw dwóch linii pomocniczych (w dół i w górę) */}
          <Line
            points={[points.end.x, points.end.y, getHelpLine(points.end).x, getHelpLine(points.end).y]}
            closed={false}
            stroke="#000000"
            strokeWidth={1 / zoomScale}
          />
          <Line
            points={[points.end.x, points.end.y, getHelpLineSecond(points.end).x, getHelpLineSecond(points.end).y]}
            closed={false}
            stroke="#000000"
            strokeWidth={1 / zoomScale}
          />

          <Group x={getInputLocation().x} y={getInputLocation().y} scale={{ x: 1 / zoomScale, y: 1 / zoomScale }}>
            <Html>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  gap: "4px",
                }}
              >
                <span>{getLengthLine()}m</span>
                <Button type="primary" onClick={() => acceptDistance()}>
                  OK
                </Button>
              </div>
            </Html>
          </Group>

          <Line
            points={[points.start.x, points.start.y, points.end.x, points.end.y]}
            closed={false}
            stroke="#2cb9f0"
            strokeWidth={25 / zoomScale}
            onMouseEnter={setPointer}
            onMouseLeave={deletepointer}
            onMouseDown={() => {
              setSaved({ start: points.start, end: points.end, startMove: mp });
              setInMoving(true);
            }}
            onMouseUp={() => {
              setInMoving(false);
            }}
          />

          {generateTicks(points.start, points.end, 30, zoomScale)}

          <Circle
            x={points.start.x}
            y={points.start.y}
            radius={8 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            onMouseEnter={setPointer}
            onMouseLeave={deletepointer}
            onMouseDown={() => {
              setActivePoint(1);
            }}
            onMouseUp={() => {
              setActivePoint(0);
            }}
          />
          <Circle
            x={points.end.x}
            y={points.end.y}
            radius={8 / zoomScale}
            strokeWidth={1 / zoomScale}
            stroke={"#000000"}
            fill="#ffffff"
            onMouseEnter={setPointer}
            onMouseLeave={deletepointer}
            onMouseDown={() => {
              setActivePoint(2);
            }}
            onMouseUp={() => {
              setActivePoint(0);
            }}
          />
        </Group>
        {/* <Circle x={0} y={0} radius={10} fill="black" />
        <Circle x={mp.x} y={mp.y} radius={10} fill="red" />
        <Circle x={points.start.x} y={points.start.y} radius={10} fill="yellow" /> */}
      </Group>
    );
  }
);
export default DomaDistanceFromDraw;
