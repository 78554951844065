import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { INotification } from "../../models/Models";
import { AppDispatch } from "../store";

export interface INotificationsState {
  loading: boolean;
  notifications: INotification[];
}

const initialState: INotificationsState = {
  loading: false,
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setLoadingNotificationsInReducer: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setNotificationsInReducer: (state, action: PayloadAction<INotification[]>) => {
      return { ...state, notifications: action.payload, loading: false };
    },
    clearNotificationsInReducer: (state, action: PayloadAction<string>) => {
      return initialState;
    },
  },
});

export const { setNotificationsInReducer, setLoadingNotificationsInReducer, clearNotificationsInReducer } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;

// API
export const getNotificationsFromApi = () => async (dispatch: AppDispatch) => {
  dispatch(setLoadingNotificationsInReducer(true));
  try {
    const response = await client.get("/Informations/getNotifications");
    if (response.status === 200) {
      const notifications = response.data;
      // console.log("getNotificationsFromApi response", notifications);
      dispatch(setNotificationsInReducer(notifications));
    }
  } catch (error) {
    console.error(error);
    dispatch(setLoadingNotificationsInReducer(false));
  }
};
