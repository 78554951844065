import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import Konva from "konva";
import { Stage, Layer, Circle, Transformer, Image, Group, Line } from "react-konva";
import { Alert, Button, Dropdown, Form, MenuProps, Modal, Select, Tooltip, notification, Card, Tag } from "antd";
import { KonvaEventObject } from "konva/lib/Node";
import {
  AlignCenterOutlined,
  MenuUnfoldOutlined,
  RedoOutlined,
  UndoOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DrawOutline from "../features/DrawOutline";
import Outline from "../others/Outline";
import {
  IAddChimneyRequestModel,
  IAddWindowRequestModel,
  IBasicPoint,
  IBayModel,
  ICalculateChangeInputsRequest,
  ICalculationPlane,
  ICalculationPoint,
  ICalculationRequest,
  ICalculationWithBayRequest,
  IContractor,
  IDataForBay,
  IGeneralRoofData,
  IPoint,
  IPrincingResponseModel,
  IRoofEdge,
  IRoofHole,
  IRoofPlane,
  IRoofPoint,
} from "../../../../../models/Models";
import MouseCross from "../others/MouseCross";
import {
  addTemplateImage,
  deleteTemplateImage,
  getTemplateImage,
  ITemplateImage,
  updateTemplatePositionImage,
  updateTemplateScaleImage,
  updateTemplateXYScaleImage,
  updateVisibleTemplateImage,
} from "../../../../../redux/roofs/templateImageSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  getDistTwoPoints,
  getEdgeLength,
  getGridPoints,
  getImageFromString64,
} from "../../../../../helpers/Helpers";
import { roundNumber } from "../../../../../helpers/Calculator";
import { updateDrawingScale } from "../../../../../redux/roofs/drawingScaleSlice";
import DefineUnit from "../features/DefineUnit";
import {
  calculateChangeInputs,
  calculateRoof,
  calculateRoofWithBay,
  calculateRoofWithChimney,
  calculateRoofWithWindow,
  clearData,
} from "../../../../../api/ApiRoof";
import RoofPlane from "../others/RoofPlane";
import {
  isPossibleRedo,
  isPossibleUndo,
  redoDrawing,
  undoDrawing,
} from "../../../../../redux/roofs/roofDrawingStoreSlice";
import store from "../../../../../redux/store";
import RoofBayModal from "../others/RoofBayModal";
import RoofHole from "../others/RoofHole";
import { updateRoofEdgeType } from "../../../../../redux/roofs/roofEdgesSlice";
import ViewRoofPlaneModal from "../others/ViewRoofPlaneModal";
import { saveDrawing } from "../../../../../redux/roofs/roofDrawingStoreSlice";
import View3DModal from "../others/View3DModal";
// import pdfjsLib from "pdfjs-dist";
import * as pdfjsLib from "pdfjs-dist";

// @ts-ignore
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import DomaUnit from "../others/DomaUnit";
import { updateMousePosition } from "../../../../../redux/drawing/mousePositionSlice";
import DrawChimney from "../others/DrawChimney";
import {
  IDrawChimneyProcess,
  resetDrawChimneyProcess,
} from "../../../../../redux/chimneys/drawChimneyProcessSlice";
import WindowInDraw from "../others/WindowInDraw";
import BayInDraw from "../others/BayInDraw";
import {
  addOutlinePoint,
  clearOutline,
  generateEdgesForOutline,
  IOutlinePoint,
  regenerateEdgesForOutline,
  setOutlinePoints,
} from "../../../../../redux/drawing/outlineSlice";
import DomaDistanceFromDraw from "../others/DomaDistanceFromDraw";
import BlockUi from "@availity/block-ui";
import AddGutteringElementFunction from "../../geometryGuttering/AddGutteringElementFunction";
import GutteringElement from "../../geometryGuttering/GutteringElement";
import {
  downIndexRoofContourNumberInReducer,
  upIndexRoofContourNumberInReducer,
} from "../../../../../redux/roofs/roofContourNumberSlice";
import { getDataForPrincing } from "../../../../../helpers/DomaFunctions";
import { getPrincingResult } from "../../../../../api/ApiContractors";
import roofProcessFunctionsStatusSlice, {
  cancelDrawNewRoofOutline,
  clearSelectedEdgeToDrawRoofProcess,
  endDrawOutlineRoof,
  endMoveEdgeDrawRoofProcess,
  resetRoofProcessFunctionsStatus,
  startDrawNewRoofOutline,
  startDrawNextRoofOutline,
  startMoveEdgeDrawRoofProcess,
  startRoofProcessWithType,
  startSelectManyEdgesDrawRoofProcess,
} from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { resetDrawRoofProcess } from "../../../../../redux/drawing/roofProcessStatusSlice";
import DSpin from "../../../../../helpers/DSpin";
import DomaGrid from "../../../../../helpers/DomaGrid";
import { setDomaGridInReducer } from "../../../../../redux/general/domaGridSlice";
import PanelFlowGeometry from "./Components/PanelFlowGutters";
import PanelFlowGutters from "./Components/PanelFlowGutters";
import PanelFlowRoofAccessories from "./Components/PanelFlowRoofAccessories";
import { TypeRoofAccessory } from "../../../../../redux/roofs/roofAccessoriesSlice";
import RoofAccessoryElement from "../../geometryRoofAccessories/RoofAccessoryElement";
import AddRoofAccessoryFunction from "../../geometryRoofAccessories/AddRoofAccessoryFunction";
import { addWindowHole } from "../../../../../redux/calculationProcess/windowHolesSlice";
import GutterSymbolAsEdge from "../others/GutterSymbolAsEdge";
import { EdgeType } from "../../../../../models/Enums";
import PanelFlowWindowHoles, { IWindowHole } from "../others/PanelFlowWindowHoles";
import { invalidateCalculation } from "../../../../../redux/calculationProcess/calcResultSlice";
import PanelFlowBays from "./Components/PanelFlowBays";
import AddBayFunction from "../others/AddBayFunction";
import PanelFlowConnectPlanes from "./Components/PanelFlowConnectPlanes";
import VectorSlopeComponent from "../others/VectorSlopeComponent";
import DimensionEdgeLine from "../others/DimensionEdgeLine";
import { PRIMARY_COLOR, TEXT_COLOR } from "../../../../../theme";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

// CONSTANTS
const DIST = 5;
const SCALE_BY = 1.1;
const LEFT_MOUSE = 0;
const SCROLL_MOUSE = 1;
const RIGHT_MOUSE = 2;
export const GRID_OFFSET = 1000;

Konva.dragButtons = [SCROLL_MOUSE]; // Określenie prawego przycisku myszki do funkcji draggable

type Props = {
  step: number;
};

interface IRoofFunction {
  code: string;
  name: string;
}

export type TypeGutteringElement =
  | null
  | "gutterDrainPipe"
  | "gutterInternalCorner"
  | "gutterExternalCorner"
  | "gutterEndLeft"
  | "gutterEndRight";

// Functions
const NEW_ROOF = {
  code: "newRoof",
  name: "Draw new Roof",
};

const DRAW_OUTLINE = {
  code: "drawOutline",
  name: "Draw outline roof",
};

const EDIT_ANGLES = {
  code: "editAngles",
  name: "Edit angles",
};

export const controlGeometryColumns = [
  {
    title: "Nazwa",
    dataIndex: "name",
  },
  {
    title: "Ilość",
    dataIndex: "amount",
    align: "center" as const,
  },
  {
    title: "Jendostka",
    dataIndex: "unit",
    align: "center" as const,
  },
];

export const getEdgeTypesSelect = () => {
  const result = [
    {
      value: 1,
      label: "Okap",
    },
    {
      value: 2,
      label: "Typ 2",
    },
    {
      value: 3,
      label: "Kalenica narożna",
    },
    {
      value: 4,
      label: "Koszowa",
    },
    {
      value: 5,
      label: "Kalenica",
    },
    {
      value: 7,
      label: "Szczytowa lewa",
    },
    {
      value: 6,
      label: "Szczytowa prawa",
    },
    {
      value: 8,
      label: "2 pionowe połacie",
    },
    {
      value: 9,
      label: "Otwór",
    },
    {
      value: 10,
      label: "Połać dach-ściana",
    },
    {
      value: 11,
      label: "Okno",
    },
    {
      value: 12,
      label: "Komin",
    },
    {
      value: 99,
      label: "Pusta",
    },
  ];

  return result;
};

const GeometryStep: FC<Props> = ({ step }) => {
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   console.log("Geometry Step RENDER");
  // });

  const calcResult = useAppSelector((state) => state.calcResult);

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);
  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);

  useEffect(() => {
    dispatch(saveDrawing(store.getState()));
  }, []);

  const isGeometry = () => (step === 1 ? true : false);
  const isHoles = () => (step === 2 ? true : false);

  const [activeFunction, setActiveFunction] = useState<IRoofFunction | null>(null);

  const roofContourNumber = useAppSelector((state) => state.roofContourNumber);

  // Tylko do testu czy działą
  const savedAdditionalProducts = useAppSelector((state) => state.savedAdditionalProducts);
  useEffect(() => {
    console.log("savedAdditionalProducts", savedAdditionalProducts);
  }, [savedAdditionalProducts]);

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage
  const [eventMoveStage, setEventMoveStage] = useState<KonvaEventObject<MouseEvent> | null>(null);
  const [eventClickStage, setEventClickStage] = useState<KonvaEventObject<MouseEvent> | null>(null);

  // const [outlinePoints, setOutlinePoints] = useState<IPoint[]>([]); // Points for actually drawing outline
  const outlinePoints =
    useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber.index))?.points || []; // Nowa wersja z redux

  // useEffect(() => {
  //   console.log(roofContourNumber);
  // }, [roofContourNumber]);

  // useEffect(() => {
  //   console.log(outlinePoints);
  // }, [outlinePoints]);

  const [mp, setMp] = useState<IPoint | null>(null); // Mouse position
  // const [nearestPoint, setNearestPoint] = useState<IPoint | null>(null); // Nearest point on line vertical or horizontal

  const [activeRoofPlane, setActiveRoofPlane] = useState<IRoofPlane | null>(null); // Który Roof Plane jest aktywny w danym momencie (włączane gdy najechana myszka na wybrany Plane)

  // Dane do rysowania outline poprzez przyciski
  // const [angle, setAngle] = useState<number>(40);
  const [distance, setDistance] = useState<number>(1);

  const scaleState = useAppSelector((state) => state.drawingScale); // Wcześniej było 70 na sztywno
  const point0Ref = useRef<Konva.Circle>(null); // Referencja do niewidocznego punktu
  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  const roofPointsState = useAppSelector((state) => state.roofPoints);
  const roofEdgesState = useAppSelector((state) => state.roofEdges);
  const roofPlanesState = useAppSelector((state) => state.roofPlanes);
  const roofHolesState = useAppSelector((state) => state.roofHoles);

  const savedOutlines = useAppSelector((state) => state.savedOutlines);

  const domaGrid = useAppSelector((state) => state.domaGrid);

  // useEffect(() => {
  //   console.log("domaGrid", domaGrid);
  // }, [domaGrid]);

  const inputDataState = useAppSelector((state) => state.inputData);
  const calcDataState = useAppSelector((state) => state.calcData);
  const generalRoofDataState = useAppSelector((state) => state.generalRoofData); // Ogólne i różne dane do dachu, zmienny stan po modyfikacji
  const [lastAngle, setLastAngle] = useState(90); // Kontener zapamietujący ostatnio wpisany kąt podczas edycji

  const [waitingApi, setWaitingApi] = useState<boolean>(false);

  const [shouldFinishDrawUnit, setShouldFinishDrawUnit] = useState<boolean>(false);

  // const drawRoofProcess = useAppSelector((state) => state.drawRoofProcess);
  // const mousePosition = useAppSelector((state) => state.mousePosition); // nie można uzyć bo renderuje cały obraz tak często jak klatki na s

  const [activeDrawChimney, setActiveDrawChimney] = useState<boolean>(false);

  const [api, contextHolder] = notification.useNotification();

  const [confirmAddNextRoof, setConfirmAddNextRoof] = useState<boolean>(false);

  const [dHtoMove, setdHToMove] = useState<number>(0.5);

  const [showAngle, setShowAngle] = useState<boolean>(false);

  // Widocznośc modalu z podglądem 3D
  const [view3DModal, setView3DModal] = useState(false);

  // useEffect(() => {
  //   if (drawRoofProcess.inDrawUnit === true && drawRoofProcess.acceptedUnit) {
  //     dispatch(endDrawUnitDrawRoofProcess());
  //   }
  // }, [drawRoofProcess]);

  // Stan procesu rysowania nowego dachu (działa dopóki jest w Geometrii -> przełączenie na inny ekran to reset)
  const [newRoofProcess, setNewRoofProcess] = useState<{
    active: boolean;
    visibleModal: boolean;
    templateIsReady: boolean;
    equalHeight: boolean;
  }>({
    active: false,
    visibleModal: false,
    templateIsReady: false,
    equalHeight: true,
  });

  const [generalRoofData, setGeneralRoofData] = useState<IGeneralRoofData>(generalRoofDataState);
  useEffect(() => {
    setGeneralRoofData(generalRoofDataState);
  }, [generalRoofDataState]);

  const [calcData, setCalcData] = useState<any>(calcDataState);
  useEffect(() => {
    setCalcData(calcDataState);
  }, [calcDataState]);

  const [inputData, setInputData] = useState<ICalculationRequest>(inputDataState);
  useEffect(() => {
    setInputData(inputDataState);
  }, [inputDataState]);

  const [scale, setScale] = useState<number>(scaleState);
  useEffect(() => {
    setScale(scaleState);
  }, [scaleState]);

  const [roofPlanes, setRoofPlanes] = useState<IRoofPlane[]>(roofPlanesState);
  useEffect(() => {
    setRoofPlanes(roofPlanesState);
  }, [roofPlanesState]);

  const [roofPoints, setRoofPoints] = useState<IRoofPoint[]>(roofPointsState);
  useEffect(() => {
    setRoofPoints(roofPointsState);

    if (roofPointsState) {
      setWaitingApi(false);
    }
  }, [roofPointsState]);

  const [roofEdges, setRoofEdges] = useState<IRoofEdge[]>(roofEdgesState);
  useEffect(() => {
    setRoofEdges(roofEdgesState);
  }, [roofEdgesState]);

  const [roofHoles, setRoofHoles] = useState<IRoofHole[]>(roofHolesState);
  useEffect(() => {
    setRoofHoles(roofHolesState);
  }, [roofHolesState]);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, []);

  // Pobieranie wymiaru z rysunku
  const [pointsForLength, setPointsForLength] = useState<IPoint[]>([]); // []
  const [inGetLineFromDraw, setInGetLineFromDraw] = useState("");
  const [distFromDraw, setDistFromDraw] = useState<number>(0);

  useEffect(() => {
    if (distFromDraw > 0) {
      setDataForBay({
        ...dataForBay,
        distFromDraw: { type: inGetLineFromDraw, value: roundNumber(distFromDraw) },
      });

      setInGetLineFromDraw("");
      setRoofBayModalOpen(true);
      // setPointsForLength([]);

      setDistFromDraw(0);
      setShouldFinishDrawUnit(false);
    }
  }, [distFromDraw]);

  //Edges **********************************************************************************************
  // const [api, contextHolder] = notification.useNotification();

  const [selectedEdgeType, setSelectedEdgeType] = useState<number>(99);
  const [showEdgesColored, setShowEdgesColored] = useState<boolean>(false);

  const [edgeToUpdate, setEdgeToUpdate] = useState<IRoofEdge | null>(null);
  const [editEdgeModal, setEditEdgeModal] = useState<boolean>(false);

  const getModalForUpdateEdge = () => {
    const start = roofPoints.find((x) => x.id === edgeToUpdate?.startPointId);
    const end = roofPoints.find((x) => x.id === edgeToUpdate?.endPointId);

    return (
      <Modal
        title="Dane wybranej krawędzi"
        centered
        open={editEdgeModal}
        onOk={() => updateEdgeClick()}
        onCancel={() => cancelUpdateEdge()}
        footer={[
          <Button key="back" onClick={cancelUpdateEdge}>
            Anuluj
          </Button>,
          <Button type="primary" key="ok" onClick={updateEdgeClick}>
            Zapisz
          </Button>,
        ]}
      >
        <Form style={{ marginTop: "16px" }}>
          <Form.Item label="Długość krawędzi:">
            {edgeToUpdate && <b>{getEdgeLength(edgeToUpdate as IRoofEdge, roofPoints, scale)} m</b>}
          </Form.Item>
          <Form.Item label="Wybierz typ krawędzi">
            <Select
              value={edgeToUpdate?.type}
              onChange={(value) => setEdgeToUpdate({ ...(edgeToUpdate as IRoofEdge), type: value })}
              placeholder="Wybierz typ krawędzi"
              options={getEdgeTypesSelect()}
            />
          </Form.Item>
        </Form>

        <div> {`start: ${JSON.stringify(start)}`}</div>
        <div> {`end: ${JSON.stringify(end)}`}</div>

        <Alert message="Możesz zmienić typ wybranej krawędzi, wybierając nowy typ." showIcon />
      </Modal>
    );
  };

  const cancelUpdateEdge = () => {
    setEditEdgeModal(false);
    setEdgeToUpdate(null);
  };

  const updateEdgeClick = () => {
    dispatch(updateRoofEdgeType(edgeToUpdate as IRoofEdge));

    setEditEdgeModal(false);
    setEdgeToUpdate(null);
  };

  // Modal do podglądu danych wybranej połaci ************************************
  // const [roofPlaneToView, setRoofPlaneToView] = useState<IRoofPlane | null>(null);
  // const [viewRoofPlaneModal, setViewRoofPlaneModal] = useState<boolean>(false);

  // const getModalForViewRoofPlane = () => {
  //   return (
  //     <ViewRoofPlaneModal
  //       open={viewRoofPlaneModal}
  //       roofPlane={roofPlaneToView}
  //       roofHoles={roofHoles}
  //       roofPoints={roofPoints}
  //       cancelViewRoofPlane={cancelViewRoofPlane}
  //       scale={scale}
  //     />
  //   );
  // };

  // const cancelViewRoofPlane = () => {
  //   setViewRoofPlaneModal(false);
  //   setRoofPlaneToView(null);
  // };

  // Scale *********************************************************************************************
  const [drawScaleLine, setDrawScaleLine] = useState(false);
  const [scaleModalOpen, setScaleModalOpen] = useState(false);
  const scaleDataState = useAppSelector((state) => state.drawingScale);
  const [scaleData, setScaleData] = useState<number>(scaleDataState);
  useEffect(() => {
    setScaleData(scaleDataState);
  }, [scaleDataState]);

  const [scaleTempData, setScaleTempData] = useState({
    realValue: 1,
    screen: scaleData,
    startPoint: null, // pomocnicze do określenia nowej skali
    endPoint: null, // pomocnicze do określenia nowej skali
  });

  useEffect(() => {
    setScaleTempData({
      realValue: 1,
      screen: scaleData,
      startPoint: null,
      endPoint: null,
    });
  }, [scaleData]);

  useEffect(() => {
    if (scaleTempData.startPoint && scaleTempData.endPoint) {
      const dist = getDistTwoPoints(scaleTempData.startPoint, scaleTempData.endPoint);
      setScaleTempData({ ...scaleTempData, screen: dist, startPoint: null, endPoint: null });
      setScaleModalOpen(true);
      setDrawScaleLine(false);
    }
  }, [scaleTempData]);

  const okModalScale = () => {
    dispatch(updateDrawingScale(roundNumber(scaleTempData.screen / scaleTempData.realValue)));

    setScaleTempData({
      realValue: 1,
      screen: scaleData,
      startPoint: null,
      endPoint: null,
    });
    setScaleModalOpen(false);
  };

  const cancelModalScale = () => {
    setScaleTempData({
      realValue: 1,
      screen: scaleData,
      startPoint: null,
      endPoint: null,
    });
    setScaleModalOpen(false);
  };

  const contractors = useAppSelector((state) => state.parentContractors);

  const getSumPrincing = (model: IPrincingResponseModel) => {
    return model.elements.reduce((sum, obj) => sum + obj.price, 0);
  };

  const onSelectPrincingSystemToCalculate = (value: any) => {
    const model = getDataForPrincing(value, store.getState());

    dispatch(
      getPrincingResult(model, (data: any) => {
        api.info({
          message: `Cena robocizny`,
          description: (
            <div>
              Cena prac wykonawczych dla przygotowanego projektu to{" "}
              <span style={{ fontWeight: "bold" }}>{getSumPrincing(data)} zł</span>. Obliczenia zostały wykonane w
              systemie <span style={{ fontWeight: "bold" }}>XYZ.</span>
            </div>
          ),
          placement: "topRight",
        });
      })
    );
  };

  const contractor = useAppSelector((state) => state.contractor);
  const profile = useAppSelector((state) => state.profile);
  const princingSystems = useAppSelector((state) => state.princingSystems);
  const isContractor = () => {
    return profile.role === "contractor";
  };

  const getDataForSelectContractorSystem = (contractors: IContractor[]) => {
    const result = [];

    if (isContractor()) {
      for (const ps of princingSystems) {
        result.push({
          id: ps.id,
          value: ps.id,
          label: `[${contractor.name}] ${ps.name}`,
        });
      }
    } else {
      if (contractors) {
        for (const c of contractors) {
          for (const ps of c.princingSystems) {
            result.push({
              id: ps.id,
              value: ps.id,
              label: `[${c.name}] ${ps.name}`,
            });
          }
        }
      }
    }

    return result;
  };

  const getUnitScaleModal = () => {
    return (
      <Modal
        title="Określ jednostkę"
        centered
        open={scaleModalOpen}
        onOk={() => okModalScale()}
        onCancel={() => cancelModalScale()}
        destroyOnClose={false}
      >
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
          <p style={{ textAlign: "center" }}>
            Podaj rzeczywistą długość oraz odpowiadającą na rysunku (okreslenie lini bazowej automatycznie dobierze
            długość na rysunku)
          </p>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "16px" }}>
            <DomaInputNumber
              value={scaleTempData.realValue}
              onChange={(value) => setScaleTempData({ ...scaleTempData, realValue: value as number })}
            />
            <span style={{ marginLeft: "8px" }}>[m]</span>
            <span style={{ marginLeft: "8px" }}>➜</span>
            <DomaInputNumber
              style={{ marginLeft: "8px" }}
              value={scaleTempData.screen.toFixed(2)}
              onChange={(value) => setScaleTempData({ ...scaleTempData, screen: Number(value) })}
            />
            <span style={{ marginLeft: "8px" }}>[j.d]</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "16px" }}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => {
                setScaleTempData({
                  realValue: 1,
                  screen: scaleData,
                  startPoint: null,
                  endPoint: null,
                });
                setDrawScaleLine(true);
                setScaleModalOpen(false);
              }}
            >
              Rysuj linię bazową
            </Button>
          </div>
          <p style={{ textAlign: "center", marginTop: "16px" }}>Aktualnie: </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "24px",
              lineHeight: "24px",
            }}
          >
            <span style={{ marginLeft: "8px" }}>1 [m]</span>
            <span style={{ marginLeft: "8px" }}>➜</span>
            <span style={{ marginLeft: "8px" }}>
              <b>{scaleData.toFixed(2)}</b> [j.d]
            </span>
          </div>
        </div>
      </Modal>
    );
  };

  // End Scale *********************************************************************************************

  const callFunction = (codeFunction: string): void => {
    if (activeFunction && activeFunction.code === codeFunction) {
      setActiveFunction(null);

      if (codeFunction === "newRoof") {
        setNewRoofProcess({ ...newRoofProcess, active: false, visibleModal: false });
      }
    } else {
      switch (codeFunction) {
        case "newRoof":
          setActiveFunction(NEW_ROOF);
          setNewRoofProcess({ ...newRoofProcess, active: true, visibleModal: true });
          break;
        case "drawOutline":
          setActiveFunction(DRAW_OUTLINE);
          break;
        case "editAngles":
          setActiveFunction(EDIT_ANGLES);
          break;
        default:
          setActiveFunction(null);
          removeTemplateImage();
          setNewRoofProcess({
            ...newRoofProcess,
            active: false,
            visibleModal: false,
            templateIsReady: false,
            equalHeight: true,
          });
          break;
      }
    }
  };

  const onMouseMoveStage = (e: KonvaEventObject<MouseEvent>) => {
    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    const scaledMp = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };

    dispatch(updateMousePosition(scaledMp));
  };

  const onMouseDownStage = (e: KonvaEventObject<MouseEvent>) => {
    e.target.preventDefault();
    setEventClickStage(e);

    if (readyForWindowPoint) {
      const mp = store.getState().mousePosition;
      setPointForWindow(mp);
      setReadyForWindowPoint(false);
    }

    if (e.evt.button === 2) {
      // Wyłączenie dodawania elementów orynnowania po kliknieciu prawego przycisku myszki
      if (activeAddGutteringElement !== null) {
        setActiveAddGutteringElement(null);
      }

      if (activeAddRoofAccessory !== null) {
        setActiveAddRoofAccessory(null);
      }
    }
  };

  const [activeWindowHolePanel, setActiveWindowHolePanel] = useState<boolean>(false);
  const [readyForWindowPoint, setReadyForWindowPoint] = useState<boolean>(false);
  const [pointForWindow, setPointForWindow] = useState<IBasicPoint | null>(null);
  const [selectedWindowHole, setSelectedWindowHole] = useState<IWindowHole | null>(null); // Wymiary otwóru jakie trzeba dodać

  useEffect(() => {
    if (pointForWindow) {
      if (selectedWindowHole) {
        const roofDataCalc = calcData;
        setWaitingApi(true);

        let data: IAddWindowRequestModel = {
          calcData: roofDataCalc,
          roofPlaneCalcId: activeRoofPlane?.calcId as number,
          window: {
            x: pointForWindow?.x as number,
            y: pointForWindow?.y as number,
            width: Math.round((selectedWindowHole.width / 100) * scale),
            height: Math.round((selectedWindowHole.height / 100) * scale),
          },
        };

        dispatch(calculateRoofWithWindow(data));

        dispatch(
          addWindowHole({
            width: selectedWindowHole.width / 100,
            height: selectedWindowHole.height / 100,
            index: 0, // Nie ma tu znaczenia bo w reduxie jest ustalane
            additionProducts: [],
            windowProducts: [],
          })
        );
      }

      dispatch(invalidateCalculation());

      // Czyszczenie
      setPointForWindow(null);
      setSelectedWindowHole(null);
    }
  }, [pointForWindow]);

  const getTypeFunctionButton = (code: string) => {
    return activeFunction?.code === code ? "primary" : "default";
  };

  // Kończenie rysowania automatycznego dachu
  const endDrawOutline = () => {
    if (outlinePoints.length >= 3) {
      setWaitingApi(true); // ustawienie że czakamy na odpowiedz z API

      const points: ICalculationPoint[] = [];
      const planes: ICalculationPlane[] = [];

      const stateOutline = store.getState().outline;

      // const existPlanes = stateOutline.find((o) => o.roofNumber === roofContourNumber.index)?.planes;

      const roofContourNumber = store.getState().roofContourNumber; // Numer bierzącego indeksu rysowania

      // angle - domyślnie w pierwszym rzucie jest dobrze bo powinno brac domyśłny kąt z ustawień ale tylko dla bierzącego rysunku

      for (const outline of stateOutline) {
        for (const p of outline.points) {
          points.push({ x: p.x, y: p.y, z: p.z ? p.z : 0 });
          planes.push({
            index: outline.roofNumber,
            edgeCount: 1,
            x: 0,
            y: 0,
            angle: roofProcessStatus.slope as number, // TODO
            dH: 0,
          }); // TODO angle
        }
      }

      const calculationRequestModel: ICalculationRequest = {
        points: points,
        planes: planes,
      };

      dispatch(calculateRoof(calculationRequestModel));
    }

    dispatch(invalidateCalculation());

    dispatch(endDrawOutlineRoof()); // Przygotowanie do wyłączenia rysowania
    dispatch(generateEdgesForOutline());
  };

  const acceptGeneratedRoof = () => {
    dispatch(resetDrawRoofProcess());

    if (activeConnectPlanes) {
      setActiveConnectPlanes(false);
    }

    // TODO testo
    dispatch(clearOutline());

    // Tu powinno być czyszczenie ale chyba nie wsyztskiego tylko to co związane z dachem
  };

  const cancelDrawOutline = () => {
    callFunction("");
    dispatch(clearOutline());
    dispatch(cancelDrawNewRoofOutline());

    if (roofContourNumber.index > 1) {
      // Tylko gdy dodawany koljeny dach, nie dotyczy pierwszego
      dispatch(downIndexRoofContourNumberInReducer()); // trzeba usunąć zwiekszenie indeksu dla nowych dachów
    }
  };

  const undoLastPointInDrawOutline = () => {
    // console.log("UNDO");
    if (outlinePoints?.length > 0) {
      const newArr: IOutlinePoint[] = [...outlinePoints];
      newArr.splice(newArr.length - 1, 1);

      dispatch(setOutlinePoints({ points: newArr, roofNumber: roofContourNumber.index }));
    }
  };

  // TODo zdefiniować interfejs dla punktów, które ida do kalkulatora
  const addNextPoint = (direction: string) => {
    let p = outlinePoints[outlinePoints.length - 1];

    if (direction === "left") {
      const newX = p.x - distance * scale;
      p = { ...p, x: newX };
    } else if (direction === "right") {
      const newX = p.x + distance * scale;
      p = { ...p, x: newX };
    } else if (direction === "up") {
      const newY = p.y - distance * scale;
      p = { ...p, y: newY };
    } else if (direction === "down") {
      const newY = p.y + distance * scale;
      p = { ...p, y: newY };
    }

    dispatch(addOutlinePoint({ point: p, roofNumber: roofContourNumber.index }));
  };

  const resetStageScale = () => {
    if (stage.current !== null) {
      const s = stage.current;
      s.scale({ x: 1, y: 1 });
      setZoomScale(1);
    }
  };

  function zoomStage(event: any) {
    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScale = s.scaleX();
      const pointerX = s.getPointerPosition()?.x as number;
      const pointerY = s.getPointerPosition()?.y as number;
      const mousePointTo = {
        x: (pointerX - s.x()) / oldScale,
        y: (pointerY - s.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? Number((oldScale / SCALE_BY).toFixed(1)) : Number((oldScale * SCALE_BY).toFixed(1));
      s.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      s.position(newPos);
      s.batchDraw();

      setZoomScale(newScale);
    }
  }

  // Zdjęcie jako podkładka
  const templateImageStoreState = useAppSelector((state) => state.templateImage);
  const [templateImageStore, setTemplateImageStore] = useState<ITemplateImage>(templateImageStoreState);
  useEffect(() => {
    if (templateImageStoreState.string64 && templateImageStoreState.x === 0 && templateImageStoreState.y === 0) {
      const img = new window.Image();
      img.src = templateImageStoreState.string64;

      img.onload = () => {
        const node = shapeRef.current;
        if (node) {
          node.x(0);
          node.y(0);

          const sW = stage.current?.width() as number;
          const sH = stage.current?.height() as number;

          const w = node.width();
          const h = node.height();

          // Okreslenie skali
          const rectRatio = sW / sH;
          const imageRatio = w / h;
          let ratio = rectRatio > imageRatio ? sH / h : sW / w;

          ratio = ratio / 1.1;

          // Przypisanie skali
          node.scale({ x: ratio, y: ratio });

          const newW = w * ratio;
          const newH = h * ratio;

          const x = sW / 2 - newW / 2;
          const y = sH / 2 - newH / 2;

          dispatch(updateTemplateXYScaleImage({ x: x, y: y, scaleX: ratio, scaleY: ratio }));

          node.x(x);
          node.y(y);
        }
      };
    }
    setTemplateImageStore(templateImageStoreState);
  }, [templateImageStoreState]);

  const shapeRef = useRef<Konva.Image>(null); // referencja do image
  const trRef = useRef<Konva.Transformer>(null); // referencja do transformer
  const [isSelectedTemplateImage, setIsSelectedTemplateImage] = useState(false); // Czy podkład jest kliknięty i aktywny do modyfikacji

  // Przypisnanie transformer po kliknięciu na zdjęcie
  useEffect(() => {
    if (isSelectedTemplateImage) {
      Konva.dragButtons = [LEFT_MOUSE];
      trRef?.current?.nodes([shapeRef?.current as Konva.Image]);
    } else {
      Konva.dragButtons = [SCROLL_MOUSE];
    }
  }, [isSelectedTemplateImage]);

  // Funkcja pobiera i zapisuje zdjęcie podkładu
  // const onChangeImage = (info: any) => {
  //   if (info.file.type === "application/pdf") {
  //     const file = info.file;

  //     const reader = new FileReader();
  //     reader.onload = async (event) => {
  //       const pdfData = new Uint8Array(event.target?.result as ArrayBuffer);
  //       const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
  //       const page = await pdf.getPage(1);
  //       const viewport = page.getViewport({ scale: 1 });
  //       const canvas = document.createElement("canvas");
  //       const context = canvas.getContext("2d") as CanvasRenderingContext2D;
  //       canvas.width = viewport.width;
  //       canvas.height = viewport.height;
  //       await page.render({ canvasContext: context, viewport }).promise;
  //       const imageDataUrl = canvas.toDataURL("image/jpeg");

  //       const obj = { string64: imageDataUrl, visible: true };
  //       dispatch(addTemplateImage(obj));
  //     };
  //     reader.readAsArrayBuffer(file);
  //   } else {
  //     let fileList = [...info.fileList];
  //     fileList = fileList.slice(-1);
  //     fileList.forEach(function (file, index) {
  //       let reader = new FileReader();
  //       reader.onload = (e) => {
  //         // file.base64 = e?.target?.result;
  //         const obj = { string64: e?.target?.result, visible: true };
  //         dispatch(addTemplateImage(obj));
  //       };
  //       reader.readAsDataURL(file.originFileObj);
  //     });
  //   }
  // };

  // Usuń podkład
  const removeTemplateImage = () => {
    dispatch(deleteTemplateImage());
  };

  // Włącz/wyłąc zmodyfikację podkładu
  const toogleModifyTemplateImage = () => {
    setIsSelectedTemplateImage((prev) => !prev);
  };

  // Dodawanie wykuszu ***********************************************************************************
  const [roofBayModalOpen, setRoofBayModalOpen] = useState(false); // Modal dla wykuszu
  const [pointForBay, setPointForBay] = useState<IPoint | null>(null); // Punkt do wstawienia wykuszu. Gdy sie pojawi to przechodzi do obliczenia i dodania wykuszu/lukarny
  const [activeGetPointForBay, setActiveGetPointForBay] = useState(false); // Czy aktywne pobieranie punktu dla wstawienia wykuszu/lukarny

  useEffect(() => {
    // Koniec rysownaia obwodu dachu poprzez Prawy Przyciszk Myszki
    if (activeFunction?.code === "drawOutline") {
      if (eventClickStage?.evt?.button === 2) {
        endDrawOutline();
      }
    }

    if (activeGetPointForBay) {
      const mps = store.getState().mousePosition;

      setPointForBay(mps);
      setActiveGetPointForBay(false);
    }

    // if (inGetLineFromDraw) {
    //   const mp = store.getState().mousePosition; // Pobieramy nowa pozycję myszki
    //   if (pointsForLength.length === 0) {
    //     setPointsForLength([mp as IPoint]);
    //   } else if (pointsForLength.length === 1) {
    //     const points = [...pointsForLength, mp];

    //     const distance = Math.hypot(
    //       (points[1]?.x as number) - (points[0]?.x as number),
    //       (points[1]?.y as number) - (points[0]?.y as number)
    //     );
    //     const distScaled = distance / scale;
    //     setDataForBay({
    //       ...dataForBay,
    //       distFromDraw: { type: inGetLineFromDraw, value: roundNumber(distScaled) },
    //     });

    //     setInGetLineFromDraw("");
    //     setRoofBayModalOpen(true);
    //     setPointsForLength([]);
    //   }
    // }
  }, [eventClickStage]);

  // Dodanie wykuszu/lukarny gdy pojawi się punkt do wstawienia
  useEffect(() => {
    if (pointForBay) {
      const roofDataCalc = calcData;

      setWaitingApi(true);

      let data: ICalculationWithBayRequest = {
        calcData: roofDataCalc,
        roofPlaneCalcId: activeRoofPlane?.calcId as number,
        bayModel:
          dataForBay.bay === true
            ? getBayModel(dataForBay, pointForBay, scale)
            : getDormerModel(dataForBay, pointForBay, scale),
      };
      dispatch(calculateRoofWithBay(data));
      dispatch(invalidateCalculation());
    }
  }, [pointForBay]);

  const addChimneyClick = () => {
    let points = [...store.getState().drawChimneyProcess.points];

    if (points && points.length >= 4) {
      const roofDataCalc = calcData;

      setWaitingApi(true);

      points.splice(-1);

      let data: IAddChimneyRequestModel = {
        calcData: roofDataCalc,
        roofPlaneCalcId: store.getState().drawChimneyProcess.roofPlaneId,
        points: points,
      };

      dispatch(calculateRoofWithChimney(data));
      dispatch(resetDrawChimneyProcess());
      setActiveDrawChimney(false);

      dispatch(invalidateCalculation());
    }
  };

  const getDormerModel = (dataForBay: IDataForBay, setPoint: IPoint, scale: number) => {
    let type = 2; // typ z daszkiem, 1 to prostokątny ale do sprawdzenia

    if (dataForBay.rectangle) {
      type = 1;
    }

    let bayModel: IBayModel = {
      type: type,
      a: dataForBay.a * scale,
      w: -1 * dataForBay.d * scale,
      x: setPoint?.x,
      y: setPoint?.y,
      alfa: -1,
      dd: -1,
      ee: -1,
      ss: -1,
      overhangSide: dataForBay.overhangSide * scale,
      overhangFront: dataForBay.overhangFront * scale,
    };

    if (type === 1) {
      bayModel = {
        ...bayModel,
        alfa: dataForBay.alfa,
        dd: dataForBay.alfa,
        ee: 0,
        ss: 0,
      };
    } else if (type === 2) {
      if (dataForBay.overhang === true && dataForBay.with3Slope == false) {
        if (dataForBay.useAngle) {
          bayModel = { ...bayModel, alfa: dataForBay.alfa, ss: 0, dd: 0, ee: 0 };
        } else {
          bayModel = { ...bayModel, alfa: 0, ss: dataForBay.hy * scale + dataForBay.d * scale, dd: 0, ee: 0 };
        }
      } else if (dataForBay.overhang === false && dataForBay.with3Slope == false) {
        bayModel = { ...bayModel, alfa: 0, ss: dataForBay.d * scale, dd: 0, ee: 0 }; // Kąt nie ma znaczenia
      }
    }

    return bayModel;
  };

  const getBayModel = (dataForBay: IDataForBay, setPoint: IPoint, scale: number) => {
    let type = 2; // typ z daszkiem, 1 to prostokątny ale do sprawdzenia

    if (dataForBay.rectangle) {
      type = 1;
    }

    let bayModel: IBayModel = {
      type: type,
      a: dataForBay.a * scale,
      x: setPoint?.x,
      y: setPoint?.y,
      w: 0,
      alfa: 0,
      dd: 0,
      ee: 0,
      ss: 0,
      overhangSide: dataForBay.overhangSide * scale,
      overhangFront: dataForBay.overhangFront * scale,
    };

    if (type === 1) {
      bayModel = {
        ...bayModel,
        w: dataForBay.w * scale,
        alfa: dataForBay.alfa,
        dd: dataForBay.alfa,
      };
    } else if (type === 2) {
      if (dataForBay.overhang === true && dataForBay.indentation === true && dataForBay.with3Slope == false) {
        if (dataForBay.useAngle) {
          bayModel = { ...bayModel, w: dataForBay.w * scale, alfa: dataForBay.alfa, ss: 0, dd: 0, ee: 0 };
        } else {
          bayModel = { ...bayModel, w: dataForBay.w * scale, alfa: 0, ss: dataForBay.hy * scale, dd: 0, ee: 0 };
        }
      } else if (
        dataForBay.overhang === true &&
        dataForBay.indentation === false &&
        dataForBay.with3Slope == false
      ) {
        bayModel = { ...bayModel, w: dataForBay.w * scale, alfa: 0, ss: 0, dd: 0, ee: 0 }; // Kąt nie ma znaczenia
      } else if (
        dataForBay.overhang === false &&
        dataForBay.indentation === true &&
        dataForBay.with3Slope == false
      ) {
        if (dataForBay.useAngle) {
          bayModel = { ...bayModel, w: 0, alfa: dataForBay.alfa, ss: 0, dd: 0, ee: 0 };
        } else {
          bayModel = { ...bayModel, w: 0, alfa: 0, ss: dataForBay.hy * scale, dd: 0, ee: 0 };
        }
      } else if (
        dataForBay.overhang === true &&
        dataForBay.indentation === false &&
        dataForBay.with3Slope == false
      ) {
        bayModel = { ...bayModel, w: 0, alfa: 0, ss: 0, dd: 0, ee: 0 }; // Kąt nie ma znaczenia
      }
    }

    return bayModel;
  };

  const initialDataForBay: IDataForBay = {
    bay: true,
    rectangle: false,
    overhang: true,
    indentation: true,
    with3Slope: false,
    useAngle: true,
    d: 1,
    a: 2,
    l: 0,
    w: 0.5,
    hy: 0,
    wy: 0,
    alfa: 40,
    overhangSide: 0,
    overhangFront: 0,
  };

  const [dataForBay, setDataForBay] = useState<IDataForBay>(initialDataForBay); // Obiekt zawierający wszystkie dane do dodania wykuszu (równiez model danych dla modal z wykuszem)

  const addBay = () => {
    setActiveGetPointForBay(true);
    setRoofBayModalOpen(false);
  };

  const cancelAddBay = () => {
    setRoofBayModalOpen(false);
  };

  const getImageTemplateItems = (): MenuProps => {
    const items: MenuProps["items"] = [];

    items.push({
      key: "1",
      label: (
        <div onClick={() => dispatch(updateVisibleTemplateImage(!templateImageStore.visible))}>
          {templateImageStore.visible ? "Ukryj podkład" : "Pokaż podkład"}
        </div>
      ),
    });

    {
      templateImageStore.visible &&
        items.push({
          key: "2",
          label: (
            <div style={{ color: "#764cff" }} onClick={() => toogleModifyTemplateImage()}>
              Modyfikuj podkład
            </div>
          ),
        });
    }

    items.push({
      key: "3",
      label: (
        <div style={{ color: "#ff0000" }} onClick={() => removeTemplateImage()}>
          Usuń podkład
        </div>
      ),
    });

    return { items };
  };

  const getBayAndDormerItems = (): MenuProps => {
    const items: MenuProps["items"] = [];

    items.push({
      key: "1",
      label: (
        <div
          onClick={() => {
            setDataForBay({ ...dataForBay, bay: true });
            setRoofBayModalOpen(true);
          }}
        >
          Wykusz
        </div>
      ),
    });

    items.push({
      key: "2",
      label: (
        <div
          onClick={() => {
            setDataForBay({ ...dataForBay, bay: false });
            setRoofBayModalOpen(true);
          }}
        >
          Lukarna
        </div>
      ),
    });

    return { items };
  };

  const [activeAddBay, setActiveAddBay] = useState<boolean>(false); // Czy aktywne dodawanie wykuszu lub kukarny - włączony panel
  const [activeAddBayOnDraw, setActiveAddBayOnDraw] = useState<boolean>(false); // Czy aktywne dodawanie wykuszu lub lukarny na rysunku - czy w trakcie rysowania

  const [activeAddGutteringElement, setActiveAddGutteringElement] = useState<TypeGutteringElement>(null); // Czy aktywne dodawanie elementu orynnowania i jeśli tak to co dokładnie
  const [activeGuttering, setActiveGuttering] = useState<boolean>(false); // Czy aktywne system orynnowania
  const gutteringElements = useAppSelector((state) => state.gutteringElements); // odwołanie do redux po elementy orynnowania w geometrii

  const [activeAddRoofAccessory, setActiveAddRoofAccessory] = useState<TypeRoofAccessory>(null);
  const [activeRoofAccessories, setActiveRoofAccessories] = useState<boolean>(false);
  const roofAccessories = useAppSelector((state) => state.roofAccessories);

  useEffect(() => {
    // console.log("activeAddBayOnDraw", activeAddBayOnDraw);
    if (activeAddBayOnDraw) {
      Konva.dragButtons = [LEFT_MOUSE];
    } else {
      Konva.dragButtons = [SCROLL_MOUSE];
    }
  }, [activeAddBayOnDraw]);

  const [activeConnectPlanes, setActiveConnectPlanes] = useState<boolean>(false); // Aktywowanie PanleFlow łączenia polaci

  // const getGutteringItems = (): MenuProps => {
  //   const items: MenuProps["items"] = [];

  //   items.push({
  //     key: "1",
  //     label: (
  //       <div
  //         style={{ display: "flex", gap: "4px", alignItems: "center" }}
  //         onClick={() => {
  //           setActiveAddGutteringElement("gutterDrainPipe");
  //         }}
  //       >
  //         <Tag>{gutteringElements.gutterDrainPipes.length}</Tag> <div>Dodaj rurę spustową</div>
  //         <Stage width={30} height={30}>
  //           <Layer>
  //             <GutteringElement typeElement="gutterDrainPipe" element={null} zoomScale={zoomScale} />
  //           </Layer>
  //         </Stage>
  //       </div>
  //     ),
  //   });

  //   items.push({
  //     key: "2",
  //     label: (
  //       <div
  //         style={{ display: "flex", gap: "4px", alignItems: "center" }}
  //         onClick={() => {
  //           setActiveAddGutteringElement("gutterInternalCorner");
  //         }}
  //       >
  //         <Tag>{gutteringElements.gutterInternalCorners.length}</Tag> <div>Dodaj narożnik wewnętrzny</div>
  //         <Stage width={30} height={30}>
  //           <Layer>
  //             <GutteringElement typeElement="gutterInternalCorner" element={null} zoomScale={zoomScale} />
  //           </Layer>
  //         </Stage>
  //       </div>
  //     ),
  //   });

  //   items.push({
  //     key: "3",
  //     label: (
  //       <div
  //         style={{ display: "flex", gap: "4px", alignItems: "center" }}
  //         onClick={() => {
  //           setActiveAddGutteringElement("gutterExternalCorner");
  //         }}
  //       >
  //         <Tag>{gutteringElements.gutterExternalCorners.length}</Tag> <span>Dodaj narożnik zewnętrzny</span>
  //         <Stage width={30} height={30}>
  //           <Layer>
  //             <GutteringElement typeElement="gutterExternalCorner" element={null} zoomScale={zoomScale} />
  //           </Layer>
  //         </Stage>
  //       </div>
  //     ),
  //   });

  //   items.push({
  //     key: "4",
  //     label: (
  //       <div
  //         style={{ display: "flex", gap: "4px", alignItems: "center" }}
  //         onClick={() => {
  //           setActiveAddGutteringElement("gutterEndLeft");
  //         }}
  //       >
  //         <Tag>{gutteringElements.gutterEndLefts.length}</Tag> <span>Dodaj zaślepkę lewą</span>
  //         <Stage width={30} height={30}>
  //           <Layer>
  //             <GutteringElement typeElement="gutterEndLeft" element={null} zoomScale={zoomScale} />
  //           </Layer>
  //         </Stage>
  //       </div>
  //     ),
  //   });

  //   items.push({
  //     key: "5",
  //     label: (
  //       <div
  //         style={{ display: "flex", gap: "4px", alignItems: "center" }}
  //         onClick={() => {
  //           setActiveAddGutteringElement("gutterEndRight");
  //         }}
  //       >
  //         <Tag>{gutteringElements.gutterEndRights.length}</Tag> <span>Dodaj zaślepkę prawą</span>
  //         <Stage width={30} height={30}>
  //           <Layer>
  //             <GutteringElement typeElement="gutterEndRight" element={null} zoomScale={zoomScale} />
  //           </Layer>
  //         </Stage>
  //       </div>
  //     ),
  //   });

  //   return { items };
  // };

  // const [view3DModal, setView3DModal] = useState(false);

  const [confirmNewRoofModal, setConfirmNewRoofModal] = useState(false); // State dla modala z potwierdzeniem rysowania nowego dachu

  const drawChimneyProcess = useAppSelector((state) => state.drawChimneyProcess);

  const handleClosePanelFlowRoofAccessories = useCallback(() => {
    setActiveRoofAccessories(false);
    setActiveAddRoofAccessory(null);
  }, [setActiveRoofAccessories, setActiveAddRoofAccessory]);

  return (
    <div>
      <Container
        bodyStyle={{
          padding: "0px",
          pointerEvents: waitingApi ? "none" : "auto",
          opacity: waitingApi ? "0.5" : "1",
        }}
      >
        {contextHolder}

        {waitingApi && (
          <DomaSpinContainer>
            <DomaSpin />
          </DomaSpinContainer>
        )}

        {/* {drawRoofProcess.isActive && drawRoofProcess.isVisibleModal && (
          <NewRoofModal
            drawRoofProcess={drawRoofProcess}
            setShouldFinishDrawUnit={(v: boolean) => setShouldFinishDrawUnit(v)}
          />
        )} */}

        {/* Określanie jednostki */}
        {drawScaleLine === true && (
          <DefineUnit
            eventClickStage={eventClickStage}
            mp={mp}
            setStartScalePoint={(obj: any) => setScaleTempData({ ...scaleTempData, startPoint: obj })}
            setEndScalePoint={(obj: any) => {
              setScaleTempData({ ...scaleTempData, endPoint: obj });
              setDrawScaleLine(false);
            }}
          />
        )}
        {/* {contextHolder} */}
        {getUnitScaleModal()}
        {getModalForUpdateEdge()}
        {/* {viewRoofPlaneModal && getModalForViewRoofPlane()} */}

        {/* {view3DModal && (
          <View3DModal
            open={view3DModal}
            onOk={() => setView3DModal(false)}
            onCancel={() => setView3DModal(false)}
            roofPoints={roofPoints}
            roofPlanes={roofPlanes}
            roofEdges={roofEdges}
          />
        )} */}

        {roofBayModalOpen && (
          <RoofBayModal
            roofPlane={activeRoofPlane}
            visible={roofBayModalOpen}
            dataForBay={dataForBay}
            setDataForBay={(v: any) => setDataForBay(v)}
            onOk={() => addBay()}
            onCancel={() => cancelAddBay()}
            setInGetLineFromDraw={(value: any) => {
              setRoofBayModalOpen(false);
              setInGetLineFromDraw(value);
            }}
          />
        )}

        <MenuBar>
          {isGeometry() && (
            <MenuBarContent>
              {/* Left side */}
              <MenuButtonContainer>
                {/* Tylko gdy funkcja rysowania dachu jest wyłączona */}
                {/* {roofProcessFunctionsStatus.isActive === false &&
                  !inGetLineFromDraw &&
                  !activeDrawChimney &&
                  !activeAddGutteringElement && (
                    <Button
                      onClick={() => {
                        dispatch(startRoofProcessWithType("outline"));
                      }}
                    >
                      Nowy dach automatyczny
                    </Button>
                  )} */}

                {roofPoints && roofPoints.length === 0 && !roofProcessFunctionsStatus.inDrawOutline && (
                  <Button
                    onClick={() => {
                      dispatch(startDrawNewRoofOutline());
                    }}
                  >
                    Rysuj dach
                  </Button>
                )}

                {!activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  roofPoints &&
                  roofPoints.length > 0 &&
                  !roofProcessFunctionsStatus.inDrawOutline &&
                  !roofProcessFunctionsStatus.isCloseOutline &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && (
                    <Button
                      onClick={() => {
                        setConfirmAddNextRoof(true);
                      }}
                    >
                      Dodaj dach
                    </Button>
                  )}

                {/* Modal z potwierdzeniem rysowania kolejnego dachu. */}
                {confirmAddNextRoof && (
                  <Modal
                    open={true}
                    centered
                    title="Dodanie kolejnego dachu"
                    onCancel={() => setConfirmAddNextRoof(false)}
                    footer={
                      <div>
                        <Button
                          key="back"
                          onClick={() => {
                            setConfirmAddNextRoof(false);
                          }}
                        >
                          Anuluj
                        </Button>
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => {
                            dispatch(upIndexRoofContourNumberInReducer());
                            dispatch(startDrawNextRoofOutline());

                            setConfirmAddNextRoof(false);
                          }}
                        >
                          Tak
                        </Button>
                      </div>
                    }
                  >
                    Czy na pewno chcesz dodać kolejny dach? Jeśli masz dodane wykusze/lukarny/otwory/okna/kominy to
                    zostaną one usunięte.
                  </Modal>
                )}

                {/* {!activeAddGutteringElement && !inGetLineFromDraw && (
                  <Button
                    disabled
                    onClick={() => {
                      if (outlinePoints?.length > 0) {
                        const newArr = [...outlinePoints].map((e) => ({ ...e, z: e.z - 100 }));

                        dispatch(setOutlinePoints({ points: newArr, roofNumber: roofContourNumber.index }));
                      }

                      endDrawOutline();
                    }}
                  >
                    100cm w ↓
                  </Button>
                )} */}

                {/* {!activeAddGutteringElement && !inGetLineFromDraw && (
                  <Button
                    disabled
                    onClick={() => {
                      if (outlinePoints?.length > 0) {
                        const newArr = [...outlinePoints].map((e) => ({ ...e, z: e.z + 100 }));

                        dispatch(setOutlinePoints({ points: newArr, roofNumber: roofContourNumber.index }));
                      }

                      endDrawOutline();
                    }}
                  >
                    100cm w ↑
                  </Button>
                )} */}

                {view3DModal && (
                  <View3DModal
                    open={view3DModal}
                    onOk={() => setView3DModal(false)}
                    onCancel={() => setView3DModal(false)}
                    roofPoints={roofPoints}
                    roofPlanes={roofPlanes}
                    roofEdges={roofEdges}
                    rsoPoints={[]}
                    rsoPlanes={[]}
                    rsoEdges={[]}
                  />
                )}

                {/* Gdy zakończono rysować podstawowy obwód dachu */}
                {roofProcessFunctionsStatus.isCloseOutline &&
                  !roofProcessFunctionsStatus.inSelectManyEdges &&
                  !activeAddGutteringElement && (
                    <Tooltip title={<TooltipText>Zakończ rysowanie dachu.</TooltipText>} color={PRIMARY_COLOR}>
                      <Button
                        type={roofProcessFunctionsStatus.inMoveEdge ? "default" : "primary"}
                        onClick={() => acceptGeneratedRoof()}
                      >
                        Zakończ rysowanie obrysu dachu
                      </Button>
                    </Tooltip>
                  )}

                {templateImageStore &&
                  (templateImageStore.string64 === null || templateImageStore.string64 === "") &&
                  templateImageStore.templateImageFileName !== null &&
                  templateImageStore.templateImageFileName.length > 0 &&
                  roofProcessFunctionsStatus.isActive === false &&
                  !inGetLineFromDraw && (
                    <Button
                      onClick={() => {
                        console.log("Wczytaj podkład", templateImageStore);
                        dispatch(getTemplateImage(templateImageStore.templateImageFileName || ""));
                      }}
                    >
                      Załaduj podkład
                    </Button>
                  )}

                {templateImageStore.string64 &&
                  roofProcessFunctionsStatus.isActive === false &&
                  !inGetLineFromDraw && (
                    <Button onClick={() => dispatch(updateVisibleTemplateImage(!templateImageStore.visible))}>
                      {templateImageStore.visible ? "Ukryj podkład" : "Pokaż podkład"}
                    </Button>
                  )}

                {roofProcessFunctionsStatus.inDrawOutline && !activeAddGutteringElement && (
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      cancelDrawOutline();
                    }}
                  >
                    Anuluj
                  </Button>
                )}

                {roofProcessFunctionsStatus.inDrawOutline && outlinePoints.length > 0 && (
                  <>
                    <div style={{ display: "flex", marginLeft: "64px", alignItems: "center" }}>
                      Długość:
                      <DomaInputNumber
                        value={distance}
                        onChange={(value) => setDistance(value as number)}
                        style={{ marginLeft: "8px", marginRight: "8px" }}
                      />
                      [m]
                    </div>

                    <div style={{ display: "flex", gap: "8px", marginLeft: "8px", alignItems: "center" }}>
                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>LEWĄ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("left")}>
                          ←
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>PRAWĄ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("right")}>
                          →
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>GÓRĘ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("up")}>
                          ↑
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>DÓŁ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("down")}>
                          ↓
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                )}

                {/* Włączenie wybierania kilku krawędzi jako jedna połać*/}
                {/* {roofProcessFunctionsStatus.isCloseOutline &&
                  !activeAddGutteringElement &&
                  !roofProcessFunctionsStatus.inMoveEdge && (
                    <Tooltip
                      title={<TooltipText>Wybierz krawędzie dla jednej połaci!</TooltipText>}
                      color={PRIMARY_COLOR}
                    >
                      <Button
                        onClick={() => {
                          if (roofProcessFunctionsStatus.inSelectManyEdges) {
                            dispatch(clearSelectedEdgeToDrawRoofProcess());
                          } else {
                            dispatch(startSelectManyEdgesDrawRoofProcess());
                          }
                        }}
                      >
                        {roofProcessFunctionsStatus.inSelectManyEdges ? "Anuluj" : "Określ krawędź wspólną"}
                      </Button>
                    </Tooltip>
                  )} */}

                {roofProcessFunctionsStatus.isCloseOutline &&
                  !roofProcessFunctionsStatus.inSelectManyEdges &&
                  !activeAddGutteringElement && (
                    <Tooltip
                      title={<TooltipText>Zmiana wysokości połaci bez zmiany kąta nachylenia połaci</TooltipText>}
                      color={PRIMARY_COLOR}
                    >
                      <Button
                        type={roofProcessFunctionsStatus.inMoveEdge ? "primary" : "default"}
                        onClick={() => {
                          if (roofProcessFunctionsStatus.inMoveEdge) {
                            dispatch(endMoveEdgeDrawRoofProcess());
                          } else {
                            dispatch(startMoveEdgeDrawRoofProcess());
                          }
                        }}
                      >
                        {roofProcessFunctionsStatus.inMoveEdge ? "Zakończ przesuwanie" : "Przesuń kalenicę"}
                      </Button>
                    </Tooltip>
                  )}

                {roofProcessFunctionsStatus.isCloseOutline &&
                  !roofProcessFunctionsStatus.inSelectManyEdges &&
                  !activeAddGutteringElement && (
                    <Tooltip title={<TooltipText>Połącz kilka połaci w jedną</TooltipText>} color={PRIMARY_COLOR}>
                      <Button
                        type={"dashed"}
                        onClick={() => {
                          setActiveConnectPlanes(true);
                        }}
                      >
                        Połącz połacie
                      </Button>
                    </Tooltip>
                  )}

                {/* Akceptacja kilku krawędzi jako jedna połać*/}
                {roofProcessFunctionsStatus.isCloseOutline &&
                  roofProcessFunctionsStatus.selectedEdges.length > 1 &&
                  !activeAddGutteringElement && (
                    <Tooltip title={<TooltipText>Zaakceptuj!</TooltipText>} color={PRIMARY_COLOR}>
                      <Button
                        onClick={() => {
                          // Logika przebudowania edges na podstawie zaznaczonych krawędzi -> w reduxie
                          dispatch(regenerateEdgesForOutline());
                        }}
                      >
                        Akceptacja wybranych krawędzi
                      </Button>
                    </Tooltip>
                  )}

                {roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  !activeDrawChimney &&
                  !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && (
                    <Button
                      type={activeAddBay ? "primary" : "default"}
                      // style={!activeAddBay ? { background: "#ffffff" } : {}}
                      onClick={() => setActiveAddBay(true)}
                    >
                      Wykusz
                    </Button>
                  )}

                {/* {roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  !activeDrawChimney &&
                  !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && (
                    <Dropdown menu={getBayAndDormerItems()} placement="bottomLeft" arrow>
                      <Button>Wykusz/lukarna</Button>
                    </Dropdown>
                  )} */}

                {/* {roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  !activeDrawChimney &&
                  !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && (
                    <Button onClick={() => setActiveAddBay(true)}>Wykusz/lukarna (BETA)</Button>
                  )} */}

                {!activeDrawChimney &&
                  roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && <Button onClick={() => setActiveDrawChimney(true)}>Komin</Button>}

                {activeDrawChimney && !activeAddGutteringElement && (
                  <>
                    <Alert
                      type="info"
                      showIcon
                      message="Wskaż punkty obwodu komina i zakończ wybierając punkt początkowy!"
                    />
                    {/* <Button onClick={() => addChimneyClick()} type="primary">
                      Generuj komin
                    </Button> */}
                    <Button
                      onClick={() => {
                        dispatch(resetDrawChimneyProcess());
                        setActiveDrawChimney(false);
                      }}
                      type="primary"
                      danger
                    >
                      Przerwij rysownie komina
                    </Button>
                  </>
                )}

                {!activeDrawChimney &&
                  roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && (
                    <Button onClick={() => setActiveWindowHolePanel(true)}>Otwór okienny</Button>
                  )}

                {!activeDrawChimney &&
                  roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  // !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && <Button onClick={() => setActiveGuttering(true)}>Orynnowanie</Button>}

                {!activeDrawChimney &&
                  roofProcessFunctionsStatus.isActive === false &&
                  roofPoints.length > 0 &&
                  // !activeAddGutteringElement &&
                  !inGetLineFromDraw &&
                  !activeGuttering &&
                  !activeRoofAccessories &&
                  !activeWindowHolePanel && (
                    <Button onClick={() => setActiveRoofAccessories(true)}>Akcesoria dachowe</Button>
                  )}

                {inGetLineFromDraw && (
                  <Button
                    danger
                    type="primary"
                    onClick={() => {
                      setRoofBayModalOpen(true);
                      setInGetLineFromDraw("");
                      // cancelAddBay();
                    }}
                  >
                    Anuluj pobieranie wymiaru
                  </Button>
                )}

                {activeFunction?.code === "drawOutline" && (
                  <>
                    <div style={{ display: "flex", marginLeft: "16px", alignItems: "center" }}>
                      Długość:
                      <DomaInputNumber
                        value={distance}
                        onChange={(value) => setDistance(value as number)}
                        style={{ marginLeft: "8px", marginRight: "8px" }}
                      />
                      [m]
                    </div>
                    <div style={{ display: "flex", gap: "8px", marginLeft: "16px", alignItems: "center" }}>
                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>LEWĄ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("left")}>
                          ←
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>PRAWĄ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("right")}>
                          →
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>GÓRĘ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("up")}>
                          ↑
                        </Button>
                      </Tooltip>

                      <Tooltip
                        title={
                          <TooltipText>
                            Narysuj linię w <b>DÓŁ</b> stronę od ostatniego punktu
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button type="primary" onClick={() => addNextPoint("down")}>
                          ↓
                        </Button>
                      </Tooltip>
                    </div>
                  </>
                )}
              </MenuButtonContainer>
              {/* Right side */}
              <MenuButtonContainer>
                <Button
                  onClick={() => {
                    setView3DModal(true);
                  }}
                >
                  3D
                </Button>

                <Tooltip title={<TooltipText>Pokaż kąty nachylenia połaci</TooltipText>} color={PRIMARY_COLOR}>
                  <Button
                    type={showAngle ? "primary" : "default"}
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    onClick={() => {
                      setShowAngle((prev) => !prev);
                    }}
                  >
                    <span
                      style={{
                        lineHeight: "24px",
                        fontSize: "24px",
                        marginTop: "-8px",
                        color: showAngle ? "#ffffff" : TEXT_COLOR,
                      }}
                    >
                      ∢
                    </span>
                  </Button>
                </Tooltip>

                {domaGrid.gridVisible && (
                  <Tooltip title={<TooltipText>Śledzenie siatki</TooltipText>} color={PRIMARY_COLOR}>
                    <Button
                      type={domaGrid.gridSnapping ? "primary" : "default"}
                      onClick={() =>
                        dispatch(setDomaGridInReducer({ ...domaGrid, gridSnapping: !domaGrid.gridSnapping }))
                      }
                    >
                      <MenuUnfoldOutlined />
                    </Button>
                  </Tooltip>
                )}

                <Tooltip title={<TooltipText>Siatka</TooltipText>} color={PRIMARY_COLOR}>
                  <Button
                    type={domaGrid.gridVisible ? "primary" : "default"}
                    onClick={() =>
                      dispatch(setDomaGridInReducer({ ...domaGrid, gridVisible: !domaGrid.gridVisible }))
                    }
                  >
                    <AlignCenterOutlined />
                  </Button>
                </Tooltip>

                {!activeDrawChimney && (
                  <Tooltip title={<TooltipText>Cofnij</TooltipText>} color={PRIMARY_COLOR}>
                    {roofProcessFunctionsStatus.inDrawOutline === true ? (
                      <Button type="default" onClick={() => undoLastPointInDrawOutline()}>
                        <UndoOutlined />
                      </Button>
                    ) : (
                      <Button
                        disabled={!isPossibleUndo(store.getState())}
                        onClick={() => dispatch(undoDrawing(store.getState()))}
                      >
                        <UndoOutlined />
                      </Button>
                    )}
                  </Tooltip>
                )}

                {!activeDrawChimney && (
                  <Tooltip title={<TooltipText>Przywróć</TooltipText>} color={PRIMARY_COLOR}>
                    <Button
                      disabled={!isPossibleRedo(store.getState())}
                      onClick={() => dispatch(redoDrawing(store.getState()))}
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>
                )}
              </MenuButtonContainer>
            </MenuBarContent>
          )}

          {isHoles() && (
            <>
              <MenuBarContent>
                {/* Left side */}
                <MenuButtonContainer>
                  {/* {!activeDrawChimney && <Button onClick={() => setActiveDrawChimney(true)}>Dodaj komin</Button>} */}

                  {/* {activeDrawChimney && (
                    <>
                      <Button onClick={() => addChimneyClick()} type="primary">
                        Generuj komin
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(resetDrawChimneyProcess());
                          setActiveDrawChimney(false);
                        }}
                        type="primary"
                        danger
                      >
                        Anuluj
                      </Button>
                    </>
                  )} */}
                </MenuButtonContainer>
                {/* Right side */}
                <MenuButtonContainer>
                  <Tooltip title={<TooltipText>Cofnij</TooltipText>} color={PRIMARY_COLOR}>
                    {roofProcessFunctionsStatus.inDrawOutline === true ? (
                      <Button type="default" onClick={() => undoLastPointInDrawOutline()}>
                        <UndoOutlined />
                      </Button>
                    ) : (
                      <Button
                        disabled={!isPossibleUndo(store.getState())}
                        onClick={() => dispatch(undoDrawing(store.getState()))}
                      >
                        <UndoOutlined />
                      </Button>
                    )}
                  </Tooltip>
                  <Tooltip title={<TooltipText>Przywróć</TooltipText>} color={PRIMARY_COLOR}>
                    <Button
                      disabled={!isPossibleRedo(store.getState())}
                      onClick={() => dispatch(redoDrawing(store.getState()))}
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>
                </MenuButtonContainer>
              </MenuBarContent>
            </>
          )}
        </MenuBar>

        {roofProcessFunctionsStatus.inDrawOutline && (
          <DrawOutline
            roofProcessFunctionsStatus={roofProcessFunctionsStatus}
            eventClickStage={eventClickStage}
            endDraw={() => endDrawOutline()}
            roofContourNumber={roofContourNumber.index}
            gridPoints={getGridPoints(
              dimStageParent.width,
              dimStageParent.height,
              scale,
              domaGrid.gridSize,
              GRID_OFFSET
            )}
          />
        )}

        {/* key jest do odświeżania obrazu tylko */}
        <StageParent ref={stageParent}>
          {activeConnectPlanes && (
            <PanelFlowConnectPlanes
              close={() => {
                setActiveConnectPlanes(false);
              }}
              scale={scale}
            />
          )}

          {/* Panel do wykuszy i lukarn */}
          {activeAddBay && (
            <PanelFlowBays
              plane={activeRoofPlane}
              close={() => {
                setActiveAddBay(false);
                setActiveAddBayOnDraw(false);
              }}
              activeAddBayOnDraw={activeAddBayOnDraw}
              setActiveAddBayOnDraw={(value) => {
                setActiveAddBayOnDraw(value);
              }}
              scale={scale}
            />
          )}

          {/* Panel do orynnowania */}
          {activeGuttering && (
            <PanelFlowGutters
              extended={true}
              close={() => {
                setActiveGuttering(false);
                setActiveAddGutteringElement(null);
              }}
              activeAddGutteringElement={activeAddGutteringElement}
              setActiveAddGutteringElement={(value) => {
                setActiveAddGutteringElement(value);
              }}
            />
          )}

          {/* Panel do akcesorii dachowych */}
          {activeRoofAccessories && (
            <PanelFlowRoofAccessories
              extended={true}
              close={handleClosePanelFlowRoofAccessories}
              activeAddRoofAccessory={activeAddRoofAccessory}
              setActiveAddRoofAccessory={setActiveAddRoofAccessory}
            />
          )}

          {activeWindowHolePanel && (
            <PanelFlowWindowHoles
              isAutomatic={true}
              close={() => {
                if (selectedWindowHole) setSelectedWindowHole(null);
                if (readyForWindowPoint) setReadyForWindowPoint(false);

                setActiveWindowHolePanel(false);
              }}
              readyForWindowPoint={readyForWindowPoint}
              setReadyForWindowPoint={(v: boolean) => setReadyForWindowPoint(v)}
              setSelectedWindowHole={(v: IWindowHole) => setSelectedWindowHole(v)}
              onCancelClick={() => {
                if (selectedWindowHole) setSelectedWindowHole(null);
                if (readyForWindowPoint) setReadyForWindowPoint(false);
              }}
            />
          )}

          <Stage
            ref={stage}
            height={900}
            width={dimStageParent.width}
            style={{
              margin: "0",
              background: "#E8E8E8",
              // background: "#4b4a4a",
              borderRadius: "0 0 8px 8px",
              overflow: "hidden",
            }}
            onMouseMove={(e) => {
              (roofProcessFunctionsStatus.loadingDrawingScale ||
                roofProcessFunctionsStatus.inDrawOutline ||
                roofProcessFunctionsStatus.inDrawUnit ||
                activeGetPointForBay ||
                activeAddBayOnDraw ||
                readyForWindowPoint ||
                inGetLineFromDraw ||
                roofProcessFunctionsStatus.inMoveEdge ||
                activeAddGutteringElement ||
                activeAddRoofAccessory ||
                activeDrawChimney) &&
                onMouseMoveStage(e);
            }}
            onMouseDown={(e) => {
              if (e.evt.button === 1) {
                e.evt.preventDefault();
              }

              onMouseDownStage(e);
            }}
            onContextMenu={(e) => {
              e.evt.preventDefault();
            }}
            onWheel={zoomStage}
            draggable={activeAddBayOnDraw ? false : true}
          >
            {templateImageStore?.visible && (
              <Layer>
                {templateImageStore.string64 && (
                  <Image
                    ref={shapeRef}
                    image={getImageFromString64(templateImageStore?.string64)}
                    x={templateImageStore?.x}
                    y={templateImageStore?.y}
                    scaleX={templateImageStore?.scaleX}
                    scaleY={templateImageStore?.scaleY}
                    rotation={templateImageStore?.rotation}
                  />
                )}

                {roofProcessFunctionsStatus.loadingDrawingScale && (
                  <DomaUnit stageRef={stage} point0Ref={point0Ref} zoomScale={zoomScale} />
                )}
              </Layer>
            )}

            {domaGrid.gridVisible && (
              <Layer>
                <DomaGrid
                  stageWidth={dimStageParent.width}
                  stageHeight={dimStageParent.height}
                  scale={scale}
                  zoomScale={zoomScale}
                  gridSettings={domaGrid}
                />
              </Layer>
            )}

            <Layer>
              {activeDrawChimney && (
                <>
                  {/* Funkcja rysownaia komina */}
                  <DrawChimney
                    active={activeDrawChimney}
                    endDraw={() => setActiveDrawChimney(false)}
                    generateChimney={() => {
                      addChimneyClick();
                    }}
                    eventClickStage={eventClickStage}
                    scaleZoom={zoomScale}
                    gridPoints={getGridPoints(
                      dimStageParent.width,
                      dimStageParent.height,
                      scale,
                      domaGrid.gridSize,
                      GRID_OFFSET
                    )}
                  />
                  {/* Tymczasowa linia rysownaego komina */}
                  {drawChimneyProcess.points.length > 0 && <ChimneyLine scale={zoomScale} />}
                </>
              )}

              {/* {isHoles() && (
                <>
                  <DrawChimney
                    active={activeDrawChimney}
                    endDraw={() => setActiveDrawChimney(false)}
                    eventClickStage={eventClickStage}
                    scaleZoom={zoomScale}
                  />
                  {drawChimneyProcess.points.length > 0 && <ChimneyLine scale={zoomScale} />}
                </>
              )} */}

              {readyForWindowPoint && activeRoofPlane && selectedWindowHole && (
                <WindowInDraw
                  zoomScale={zoomScale}
                  scale={scale}
                  plane={activeRoofPlane}
                  selectedWindowHole={selectedWindowHole}
                />
              )}

              {activeGetPointForBay && activeRoofPlane && (
                <BayInDraw zoomScale={zoomScale} scale={scale} plane={activeRoofPlane} />
              )}

              {/* Otwory pod lukarnami */}
              {roofHoles.map((o, i) => {
                return <RoofHole key={o.id} roofHole={o} roofPoints={roofPoints} scale={zoomScale} />;
              })}

              {!roofProcessFunctionsStatus.inSelectManyEdges &&
                activeAddBayOnDraw === false &&
                roofEdges
                  .filter((x) => x.type === EdgeType.Eaves)
                  .map((o, i) => {
                    return (
                      <GutterSymbolAsEdge
                        key={o.id}
                        roofEdge={o}
                        roofPoints={roofPoints}
                        scale={zoomScale}
                        stageRef={stage}
                        point0Ref={point0Ref}
                      />
                    );
                  })}

              {/* Wszystkie połacie dachu */}
              {roofPlanes.map((o, i) => {
                return (
                  <RoofPlane
                    key={o.id}
                    roofInProgress={roofProcessFunctionsStatus.isActive || inGetLineFromDraw !== ""}
                    // roofInProgress={false} // Tymczasowo wyłączam proces generowania dachu i zawsze jest jakby ostateczne
                    roofProcessFunctionsStatus={roofProcessFunctionsStatus}
                    roofPlane={o}
                    roofPoints={roofPoints}
                    roofEdges={roofEdges}
                    inputData={inputData}
                    inEditAngle={activeFunction === EDIT_ANGLES}
                    dispatch={dispatch}
                    lastAngle={lastAngle}
                    setLastAngle={(v: any) => setLastAngle(v)}
                    setActiveRoofPlane={(v: any) => setActiveRoofPlane(v)}
                    blockSetActiveRoofPlane={
                      activeDrawChimney ||
                      activeAddGutteringElement !== null ||
                      activeAddRoofAccessory !== null ||
                      activeAddBay
                    }
                    scaleZoom={zoomScale}
                    scale={scale}
                    showArea={false}
                    onClickRoofPlane={() => {}}
                    dHtoMove={dHtoMove}
                    setdHtoMove={(v: any) => setdHToMove(v)}
                    showAngle={showAngle}
                    activeConnectPlanes={activeConnectPlanes}
                  />
                );
              })}

              {/* Actual drawing roof outline */}
              {/* {activeFunction?.code === "drawOutline" && <Outline />} */}
              {!roofProcessFunctionsStatus.inMoveEdge &&
                (roofProcessFunctionsStatus.inDrawOutline ||
                  (roofProcessFunctionsStatus.isCloseOutline && roofProcessFunctionsStatus.isActive)) && (
                  <Outline
                    roofProcessStatus={roofProcessStatus}
                    roofProcessFunctionsStatus={roofProcessFunctionsStatus}
                    scaleZoom={zoomScale}
                    activeRoofPlane={activeRoofPlane as IRoofPlane}
                    gridPoints={getGridPoints(
                      dimStageParent.width,
                      dimStageParent.height,
                      scale,
                      domaGrid.gridSize,
                      GRID_OFFSET
                    )}
                  />
                )}

              {/* Wymiary nad krawędzia dachu */}
              {showAngle &&
                roofEdges
                  // .filter((x) => x.type === EdgeType.Eaves)
                  .map((o, i) => {
                    return (
                      <DimensionEdgeLine
                        key={o.id}
                        roofEdge={o}
                        roofPoints={roofPoints}
                        scale={scale}
                        zoomScale={zoomScale}
                      />
                    );
                  })}

              {activeConnectPlanes && <VectorSlopeComponent scale={scale} />}

              {/* {activeFunction?.code === "drawOutline" && <MouseCross scale={zoomScale} />} */}
              {roofProcessFunctionsStatus.inDrawOutline === true && (
                <MouseCross
                  scale={zoomScale}
                  gridPoints={getGridPoints(
                    dimStageParent.width,
                    dimStageParent.height,
                    scale,
                    domaGrid.gridSize,
                    GRID_OFFSET
                  )}
                />
              )}

              {isSelectedTemplateImage && (
                <Transformer
                  ref={trRef}
                  keepRatio={true}
                  enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
                  rotationSnaps={[0, 90, 180, 270]}
                />
              )}

              {inGetLineFromDraw && (
                <DomaDistanceFromDraw
                  zoomScale={zoomScale}
                  shouldFinish={shouldFinishDrawUnit}
                  resetShouldFinish={() => setShouldFinishDrawUnit(false)}
                  roofProcessFunctionsStatus={roofProcessFunctionsStatus}
                  setDistFromDraw={(v: number) => setDistFromDraw(v)}
                  acceptDistance={() => setShouldFinishDrawUnit(true)}
                />
              )}

              {/* Właśnie w tym momencie dodawany element orynnowania */}
              {activeGuttering && activeAddGutteringElement !== null && (
                <AddGutteringElementFunction
                  typeElement={activeAddGutteringElement}
                  eventClickStage={eventClickStage}
                  zoomScale={zoomScale}
                  closeFunction={() => null}
                />
              )}

              {/* Wyswietlanie elementów dodanych do rysunku */}
              {gutteringElements.gutterDrainPipes.map((o, i) => {
                return (
                  <GutteringElement key={i} typeElement="gutterDrainPipe" element={o} zoomScale={zoomScale} />
                );
              })}
              {gutteringElements.gutterInternalCorners.map((o, i) => {
                return (
                  <GutteringElement key={i} typeElement="gutterInternalCorner" element={o} zoomScale={zoomScale} />
                );
              })}
              {gutteringElements.gutterExternalCorners.map((o, i) => {
                return (
                  <GutteringElement key={i} typeElement="gutterExternalCorner" element={o} zoomScale={zoomScale} />
                );
              })}
              {gutteringElements.gutterEndLefts.map((o, i) => {
                return <GutteringElement key={i} typeElement="gutterEndLeft" element={o} zoomScale={zoomScale} />;
              })}
              {gutteringElements.gutterEndRights.map((o, i) => {
                return <GutteringElement key={i} typeElement="gutterEndRight" element={o} zoomScale={zoomScale} />;
              })}

              {activeRoofAccessories && activeAddRoofAccessory !== null && (
                <AddRoofAccessoryFunction
                  typeElement={activeAddRoofAccessory}
                  scale={scale}
                  eventClickStage={eventClickStage}
                  zoomScale={zoomScale}
                  closeFunction={() => null}
                  activeRoofPlane={activeRoofPlane}
                />
              )}

              {/* Wyswietlanie akcesoriów dachowych dodanych do rysunku */}
              {roofAccessories.roofStepList.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofStep"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench40List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench40"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench80List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench80"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench200List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench200"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.chimneySweepBench300List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="chimneySweepBench300"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.snowFence200List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="snowFence200"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.snowFence300List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="snowFence300"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.roofVent100List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofVent100"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.roofVent125List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofVent125"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}
              {roofAccessories.roofVent150List.map((o, i) => {
                return (
                  <RoofAccessoryElement
                    key={o.id}
                    typeElement="roofVent150"
                    element={o}
                    scale={scale}
                    zoomScale={zoomScale}
                    activeDelete={activeAddRoofAccessory === null}
                  />
                );
              })}

              {/* Funkcja dodawania wykuszy i lukarn - NOWA */}
              {activeAddBayOnDraw && (
                <AddBayFunction
                  zoomScale={zoomScale}
                  scale={scale}
                  plane={activeRoofPlane}
                  point0Ref={point0Ref}
                />
              )}

              {/* Niewidoczny punkt w pozycji 0,0 do skalowania */}
              <Circle ref={point0Ref} x={0} y={0} radius={0} fill="transparent" />

              {/* <Circle x={0} y={0} radius={10} fill="black" /> */}
            </Layer>
          </Stage>
        </StageParent>
      </Container>
    </div>
  );
};

export default GeometryStep;

const getFlatPoints = (points: IBasicPoint[]): number[] => points.map((p) => [p.x, p.y]).flat();

const ChimneyLine: FC<{ scale: number }> = ({ scale }) => {
  const mp = useAppSelector((state) => state.mousePosition);
  const state: IDrawChimneyProcess = useAppSelector((state) => state.drawChimneyProcess);

  const getPoints = () => {
    if (state.isReadyToCalc) {
      return getFlatPoints([...state.points]);
    } else {
      return getFlatPoints([...state.points, mp]);
    }
  };

  return (
    <Group>
      <Line points={getPoints()} closed={true} stroke="#000000" strokeWidth={2 / scale} />
      {state.points.length > 0 && (
        <Circle
          x={state.points[0].x}
          y={state.points[0].y}
          radius={4 / scale}
          strokeWidth={1 / scale}
          stroke={"#000000"}
          fill="#ffffff"
        />
      )}
    </Group>
  );
};

const Container = styled(Card)``;

const StageParent = styled.div``;

const MenuBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 8px 8px 0 0;
  background: #bdbdbd;
`;

const MenuBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipText = styled.div`
  text-align: center;
`;

const DomaSpinContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 50px);
  z-index: 99999;
`;

const DomaSpin = styled(DSpin)`
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
