// Function3Handler.tsx
import { message } from "antd";
import Konva from "konva";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Circle, Group, Line, Text } from "react-konva";
import { IRoofPoint } from "../../../../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSOEdge, IRSOPoint, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";

export function calculatePerpendicularPoint(
  points: IRoofPoint[] | IRSOPoint[],
  edge: IRSOEdge,
  mp: RSOPoint2D
): RSOPoint2D {
  const startPoint = points.find((p) => p.id === edge.startPointId);
  const endPoint = points.find((p) => p.id === edge.endPointId);

  if (startPoint && endPoint) {
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;

    const k = ((mp.x - startPoint.x) * dx + (mp.y - startPoint.y) * dy) / (dx * dx + dy * dy);

    const x = startPoint.x + k * dx;
    const y = startPoint.y + k * dy;

    return { x, y };
  }

  return { x: 0, y: 0 };
}

interface Function3HandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  startPointDeclineVector?: RSOPoint2D | null;
  endPointDeclineVector?: RSOPoint2D | null;
  setStartPointDeclineVector: (point: RSOPoint2D) => void;
  setEndPointDeclineVector: (point: RSOPoint2D) => void;
  activateEditOutlineModal: () => void;
  close: () => void;
}

// Funckja do rysowania wektora spadku
const Function3Handler: FC<Function3HandlerProps> = ({
  stageRef,
  point0Ref,
  startPointDeclineVector,
  endPointDeclineVector,
  setStartPointDeclineVector,
  setEndPointDeclineVector,
  activateEditOutlineModal,
  close,
}) => {
  const dispatch = useAppDispatch();

  const startPointDeclineVectorRef = useRef<RSOPoint2D | null | undefined>(null);
  const endPointDeclineVectorRef = useRef<RSOPoint2D | null | undefined>(null);

  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const rsoEdges = useAppSelector((state) => state.rsoEdges) || [];
  const rsoPoints = useAppSelector((state) => state.rsoPoints) || [];

  const [perpendicularPoint, setPerpendicularPoint] = React.useState<RSOPoint2D | null>(null); // Punkt na krawedzi stanowiacy prostopadłość do wektora spadku
  const perpendicularPointRef = useRef(perpendicularPoint);

  // Aktualizuj referencje gdy wartość się zmienia
  useEffect(() => {
    if (startPointDeclineVector !== undefined) {
      startPointDeclineVectorRef.current = startPointDeclineVector;
    }
    if (endPointDeclineVector !== undefined) {
      endPointDeclineVectorRef.current = endPointDeclineVector;
    }
  }, [startPointDeclineVector, endPointDeclineVector]);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
    perpendicularPointRef.current = perpendicularPoint;
  }, [mpLocal, perpendicularPoint]);

  const handleClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (!startPointDeclineVectorRef.current && !endPointDeclineVectorRef.current) {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      if (startPointPosition) {
        setStartPointDeclineVector(startPointPosition);
      }
    }

    if (startPointDeclineVectorRef.current && !endPointDeclineVectorRef.current) {
      const endPointPosition = point0Ref?.current?.getRelativePointerPosition();

      // Jeśłi jest punkt prostopadły to dodaj
      if (perpendicularPointRef.current) {
        setEndPointDeclineVector(perpendicularPointRef.current);
        setShowTooltip(false);

        activateEditOutlineModal();
      } else if (endPointPosition) {
        // Jelsi dowolne miejsce
        setEndPointDeclineVector(endPointPosition);
        setShowTooltip(false);

        activateEditOutlineModal();
      }
    }
  };

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        e.evt.preventDefault();
        handleClick(e);
      } else if (e.evt.button === 2) {
        console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const startPointPosition = point0Ref?.current?.getRelativePointerPosition();
      if (startPointPosition) {
        const scaledMp: RSOPoint2D = { x: startPointPosition.x, y: startPointPosition.y };
        setMpLocal(scaledMp);

        // WYkrywanie krawędzi tylko dla 2 punktu
        if (startPointDeclineVectorRef.current && !endPointDeclineVectorRef.current) {
          // 1. Wykrywamy czy myszka jest nad jakąąś krawędzia i nad jaką
          const stage = e.target.getStage();
          const pointerPos = stage?.getPointerPosition();

          // Utwórz okrąg wokół kursora z tolerancją
          const circle = new Konva.Circle({
            x: pointerPos?.x,
            y: pointerPos?.y,
            radius: 5,
          });

          // Sprawdź, czy istnieje element na scenie, który przecina się z okręgiem
          const intersectedObject = stage?.getIntersection(circle.getClientRect());

          if (intersectedObject && intersectedObject.hasName("rsoEdge")) {
            const edgeId = intersectedObject.id();
            const edge = rsoEdges.find((e) => e.id === edgeId);

            if (edge) {
              const tempPoint = calculatePerpendicularPoint(rsoPoints, edge, startPointDeclineVectorRef.current); // funkcja obliczająca pozycję tymczasowego punktu
              setPerpendicularPoint(tempPoint); // funkcja, która ustawia stan tymczasowego punktu
            }
          } else {
            setPerpendicularPoint(null); // resetuj stan tymczasowego punktu, gdy mysz opuszcza krawędź
          }
        }
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  return (
    <Group>
      {showTooltip && (
        <Group>
          <Text
            x={mpLocal.x}
            y={mpLocal.y - 20}
            text={
              !startPointDeclineVector
                ? "Wybierz pierwszy punkt określający wektor spadku"
                : "Wybierz drugi punkt określający wektor spadku"
            }
            fill="black"
          />
          <Circle
            x={perpendicularPointRef.current ? perpendicularPointRef.current.x : mpLocal.x}
            y={perpendicularPointRef.current ? perpendicularPointRef.current.y : mpLocal.y}
            radius={4}
            fill="red"
            strokeWidth={1}
            stroke="black"
          />

          {startPointDeclineVector && (
            <Line
              points={
                perpendicularPointRef.current
                  ? [
                      startPointDeclineVector.x,
                      startPointDeclineVector.y,
                      perpendicularPointRef.current.x,
                      perpendicularPointRef.current.y,
                    ]
                  : [
                      startPointDeclineVector.x,
                      startPointDeclineVector.y,
                      mpLocalRef.current.x,
                      mpLocalRef.current.y,
                    ]
              }
              stroke="black"
              strokeWidth={2}
            />
          )}
        </Group>
      )}
    </Group>
  );
};

export default Function3Handler;
