import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct, IProductUseType } from "../../models/Models";

const initialState: Array<IProductUseType> = [];

export const productUseTypesSlice = createSlice({
  name: "productUseTypes",
  initialState,
  reducers: {
    addProductUseTypesInReducer: (state, action: PayloadAction<Array<IProductUseType>>) => {
      let productUseTypes = action.payload;
      return productUseTypes;
    },
  },
});

export const { addProductUseTypesInReducer } = productUseTypesSlice.actions;
export default productUseTypesSlice.reducer;
