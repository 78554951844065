import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { AppDispatch, AppThunk } from "../store";

export interface ITemplateImage {
  string64: string | null;
  templateImageFileName: string | null;
  x: number;
  y: number;
  rotation: number;
  scaleX: number;
  scaleY: number;
  visible: boolean;
}

const initialState: ITemplateImage = {
  string64: null,
  templateImageFileName: null,
  x: 0,
  y: 0,
  rotation: 0,
  scaleX: 1,
  scaleY: 1,
  visible: true,
};

export const templateImageSlice = createSlice({
  name: "templateImage",
  initialState,
  reducers: {
    addTemplateImage: (state, action) => {
      let image = action.payload;
      return image;
    },
    updateTemplatePositionImage: (state, action) => {
      let position = action.payload;
      return { ...state, x: position.x, y: position.y };
    },
    updateTemplateImageFileName: (state, action) => {
      let fileName = action.payload;
      return { ...state, templateImageFileName: fileName };
    },
    updateTemplateImageString64: (state, action) => {
      let string64 = action.payload;
      return { ...state, string64: string64 };
    },
    updateTemplateXYScaleImage: (state, action) => {
      let position = action.payload;
      return { ...state, x: position.x, y: position.y, scaleX: position.scaleX, scaleY: position.scaleY };
    },
    updateTemplateScaleImage: (state, action) => {
      let scale = action.payload;
      return { ...state, scaleX: scale.x, scaleY: scale.y, rotation: scale.rotation };
    },
    updateVisibleTemplateImage: (state, action) => {
      let visible = action.payload;
      return { ...state, visible: visible };
    },
    deleteTemplateImage: (state) => {
      return initialState;
    },
    setSavedTemplateImage: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearTemplateImage: (state) => {
      return initialState;
    },
  },
});

export const {
  addTemplateImage,
  updateTemplatePositionImage,
  deleteTemplateImage,
  updateTemplateScaleImage,
  updateTemplateImageFileName,
  updateTemplateImageString64,
  updateTemplateXYScaleImage,
  updateVisibleTemplateImage,
  clearTemplateImage,
  setSavedTemplateImage,
} = templateImageSlice.actions;
export default templateImageSlice.reducer;

// API
// Upload zdjęcia szablonu
export const uploadTemplateImage =
  (string64: string): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("Upload image", string64);
    const model = { ImageBase64: string64 };
    client
      .post(`/ImagesStore/Upload`, model)
      .then((response) => {
        if (response.status == 200) {
          console.log("Template Image Uploaded", response.data);
          dispatch(updateTemplateImageFileName(response.data.fileName));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Pobranie zdjęcia szablonu
export const getTemplateImage = (fileName: string) => async (dispatch: AppDispatch) => {
  if (fileName !== null) {
    try {
      console.log("Get image", fileName);
      const response = await client.get("/ImagesStore/Download/" + fileName);
      if (response.status === 200) {
        console.log("Template Image Downloaded", response.data);
        dispatch(updateTemplateImageString64(response.data.string64));
      }
    } catch (error) {
      console.error(error);
    }
  }
};
