import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRSOPoint } from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

const initialState: IRSOPoint[] = [];

export const rsoPointsSlice = createSlice({
  name: "rsoPoints",
  initialState,
  reducers: {
    addRSOPoint: (state, action: PayloadAction<IRSOPoint>) => {
      // Dodaj tylko jeśli punkt nie istnieje
      let p = action.payload;
      if (state.find((o) => o.id === p.id)) {
        return state;
      } else {
        return [...state, p];
      }
    },
    addRSOPoints: (state, action: PayloadAction<IRSOPoint[]>) => {
      let ps = action.payload;
      // Dodaj tylko jeśli punkty nie istnieją
      let newPoints = ps.filter((p) => !state.find((o) => o.id === p.id)); // Filtrujemy tylko te punkty które nie istnieją
      return [...state, ...newPoints];
    },
    setRSOPoints: (state, action: PayloadAction<IRSOPoint[]>) => {
      let array = action.payload;
      return array;
    },
    updateRSOPoints: (state, action: PayloadAction<IRSOPoint[]>) => {
      let ps = action.payload;
      for (let p of ps) {
        let index = state.findIndex((o) => o.id === p.id);
        if (index !== -1) {
          state[index] = p;
        }
      }
    },
    removeRSOPoint: (state, action: PayloadAction<IRSOPoint>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRSOPoints: (state) => {
      return initialState;
    },
    setSavedRSOPoints: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRSOPoint,
  addRSOPoints,
  updateRSOPoints,
  setRSOPoints,
  removeRSOPoint,
  clearRSOPoints,
  setSavedRSOPoints,
} = rsoPointsSlice.actions;
export default rsoPointsSlice.reducer;
