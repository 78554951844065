import React, { useEffect, useState, useRef, Suspense } from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import styled from "styled-components";
import { TOKEN_KEY_NAME } from "./api/ApiClient";
import "./App.css";
import Start from "./Start";
import SignPage from "./modules/sign/SignPage";
import { logOut } from "./redux/actions";
import { getUserData } from "./redux/auth/actions/authActions";
import DSpin from "./helpers/DSpin";

const getTokenFromSession = () => {
  const tokenString = localStorage.getItem(TOKEN_KEY_NAME);
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const LoadingStatus = {
  Loading: 0,
  GoToSign: 1,
  GoToStart: 2,
};

const isGoToSign = (loadingStatus) => loadingStatus === LoadingStatus.GoToSign;
const isGoToStart = (loadingStatus) => loadingStatus === LoadingStatus.GoToStart;

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Loading); // Na start jest Loading

  const firstRunAuth = useRef(true);

  useEffect(() => {
    const tokenInStorage = getTokenFromSession();

    if (tokenInStorage) {
      dispatch(getUserData(tokenInStorage));
    } else {
      setLoadingStatus(LoadingStatus.GoToSign);
    }

    const preventZoom = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };
    document.addEventListener("wheel", preventZoom, { passive: false });
    return () => document.removeEventListener("wheel", preventZoom);
  }, []);

  // Jeśli token istniej w redux to znaczy, że również są pobrane dane i można włączyć aplikację
  useEffect(() => {
    if (!firstRunAuth.current) {
      if (auth.token && auth.token.length > 1) {
        setLoadingStatus(LoadingStatus.GoToStart);
      } else if (auth.token === "") {
        setLoadingStatus(LoadingStatus.GoToSign);
      }
    } else {
      firstRunAuth.current = false;
    }
  }, [auth]);

  // RETURN
  return (
    <>
      {/* <BetaHeader>
        <BetaHeaderColumn style={{ width: "150px" }}></BetaHeaderColumn>
        <BetaHeaderColumn style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          Testowa wersja aplikacji.
        </BetaHeaderColumn>
        <BetaHeaderColumn
          style={{ width: "100px", display: "flex", justifyContent: "flex-end", marginRight: "16px" }}
        >
        </BetaHeaderColumn>
      </BetaHeader> */}
      {isGoToSign(loadingStatus) && <SignPage />}
      {isGoToStart(loadingStatus) && <Start />}
      {!isGoToSign(loadingStatus) && !isGoToStart(loadingStatus) && (
        <SpinContainer>
          <DomaSpin
            size="large"
            onClick={() => {
              dispatch(logOut());
            }}
          />
        </SpinContainer>
      )}
    </>
  );
}

export default App;

const SpinContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DomaSpin = styled(DSpin)`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const BetaHeader = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: white;
  background: red;
`;

const BetaHeaderColumn = styled.div``;
