import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGeometryData } from "../../models/Models";
import { TypeRoofAccessory } from "./roofAccessoriesSlice";

export type TypeGeometryData =
  | null
  | "startHorizontalRidge"
  | "startAngledRidge"
  | "endHorizontalRidge"
  | "endAngledRidge"
  | "initialRidgeThree"
  | "endRidgeThree"
  | "ridgeFour";

const initialState: IGeometryData = {
  startHorizontalRidgeCount: 0, // Ilość punktów jako początek kalenicy poziomej
  startAngledRidgeCount: 0, // Ilość punktów jako początek kalenicy skośnej

  endHorizontalRidgeCount: 0, // Ilość punktów jako koniec kalenicy poziomej
  endAngledRidgeCount: 0, // Ilość punktów jako koniec kalenicy skośnej

  initialRidgeThreeCount: 0, // Ilość punktów jako początek kalenicy z trójnikiem
  endRidgeThreeCount: 0, // Ilość punktów jako koniec kalenicy z trójnikiem

  ridgeFourCount: 0, // Ilość punktów z czwórnikiem (gąsiorem)
};

// Dane geometryczne np. poczatek kalenicy. Tylko dla obrysu połaci
export const geometryDataSlice = createSlice({
  name: "geometryData",
  initialState,
  reducers: {
    updateGeometryData: (state, action: PayloadAction<IGeometryData>) => {
      const geometryData = action.payload;
      return { ...geometryData };
    },
    // Znajdź i zaktualizuj odpowiedni element jako tylko wartość
    updateGeometryDataValue: (
      state,
      action: PayloadAction<{ typeGeometryData: TypeGeometryData; countValue: number }>
    ) => {
      let e = action.payload;

      return {
        ...state,
        [e.typeGeometryData + "Count"]: e.countValue,
      };
    },
    clearGeometryData: (state) => {
      return initialState;
    },
    setSavedGeometryData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { updateGeometryData, clearGeometryData, updateGeometryDataValue, setSavedGeometryData } =
  geometryDataSlice.actions;
export default geometryDataSlice.reducer;
