import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Card, Table, Tag } from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import UserModal from "../../components/UserModal";
import { IRootState, IUser } from "../../models/Models";
import { useAppSelector } from "../../redux/hooks";

const usersColumns = [
  {
    title: "Imię i nazwisko",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Login",
    dataIndex: "login",
    key: "login",
  },
  {
    title: "Ostatnia aktywność",
    dataIndex: "lastActivity",
    key: "lastActivity",
    align: "center" as "center",
    width: "180px",
  },
  {
    title: "Rola",
    dataIndex: "role",
    key: "role",
    align: "center" as "center",
    width: "120px",
  },
  {
    title: "Aktywne",
    dataIndex: "active",
    key: "active",
    align: "center" as "center",
    width: "100px",
  },
  {
    title: "Edytuj",
    dataIndex: "edit",
    key: "edit",
    align: "center" as "center",
    width: "100px",
  },
];

const getRoleTag = (role: string): JSX.Element => {
  if (role === "admin") {
    return <Tag color="magenta">ADMIN</Tag>;
  } else if (role === "moderator") {
    return <Tag color="orange">MODERATOR</Tag>;
  } else if (role === "contractor") {
    return <Tag color="blue">WYKONAWCA</Tag>;
  } else {
    return <Tag color="volcano">SPRZEDAWCA</Tag>;
  }
};

const UsersPage: FC = () => {
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [userInEdit, setUserInEdit] = useState<IUser | null>(null);
  const profile = useAppSelector((state: IRootState) => state.profile);
  const users = useAppSelector((state: IRootState) => state.users);

  const isMyUser = (user: IUser | null) => {
    return user?.email === profile.email ? true : false;
  };

  const editUserOnClick = (user: IUser) => {
    setUserInEdit(user);
    setAddUserModalOpen(true);
  };

  const addUserOnClick = () => {
    if (userInEdit !== null) {
      setUserInEdit(null);
    }
    setAddUserModalOpen(true);
  };

  const getUsersData = (users: IUser[]) => {
    const data: {
      key: string;
      fullName: string;
      login: string;
      lastActivity: string;
      role: JSX.Element;
      active: JSX.Element;
      edit: JSX.Element;
    }[] = [];

    if (users) {
      // Najpierw sortuj użytkowników według lastActivity
      const sortedUsers = [...users].sort(
        (a, b) => new Date(b.lastActivity).getTime() - new Date(a.lastActivity).getTime()
      );

      // Znajdź użytkownika z loginem szymon.kaminski@batman.pl i przenieś go na koniec
      const szymonUser = sortedUsers.find((x) => x.email === "szymon.kaminski@batman.pl");
      if (szymonUser) {
        sortedUsers.splice(sortedUsers.indexOf(szymonUser), 1);
        sortedUsers.push(szymonUser);
      }

      for (const user of sortedUsers) {
        data.push({
          key: user.id,
          fullName: user.fullName,
          login: user.email,
          lastActivity: new Date(user.lastActivity).toLocaleString("pl-PL"),
          role: getRoleTag(user.role),
          active: user.active ? (
            <CheckCircleFilled style={{ color: "#5ec01c", fontSize: "20px" }} />
          ) : (
            <CloseCircleFilled style={{ color: "#ee0b0b", fontSize: "20px" }} />
          ),
          edit: !isMyUser(user) ? <Button onClick={() => editUserOnClick(user)}>Edytuj</Button> : <></>,
        });
      }
    }

    return data;
  };

  return (
    <Container
      title={"Lista użytkowników"}
      extra={
        <Button type="primary" onClick={() => addUserOnClick()}>
          Dodaj nowego użytkownika
        </Button>
      }
    >
      {addUserModalOpen && (
        <UserModal
          addUserModalOpen={addUserModalOpen}
          setAddUserModalOpen={setAddUserModalOpen}
          user={userInEdit}
          isMyUser={isMyUser(userInEdit)}
        />
      )}
      <Table dataSource={getUsersData(users)} columns={usersColumns} pagination={{ defaultPageSize: 10 }} />
    </Container>
  );
};

export default UsersPage;

const Container = styled(Card)``;
