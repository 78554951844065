import { ArrowsAltOutlined, CloseOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, message, Radio, Row, Tooltip } from "antd";
import Konva from "konva";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../../../theme";
import { calculateRoofWithBay } from "../../../../../../api/ApiRoof";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import {
  IBayModel,
  ICalculationWithBayRequest,
  IDataForBay,
  IPoint,
  IRoofPlane,
} from "../../../../../../models/Models";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import {
  clearBayState,
  updateAllBayState,
  updateAngleInBay,
  updateBayObject,
  updateBayTypeInBay,
  updateH1InBay,
  updateOverhangFrontInBay,
  updateOverhangSideInBay,
  updateW10InBay,
  updateW1InBay,
  updateW2InBay,
} from "../../../../../../redux/drawing/baysSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";

type Props = {
  plane: IRoofPlane | null;
  close: () => void;
  activeAddBayOnDraw: boolean;
  setActiveAddBayOnDraw: (value: boolean) => void;
  scale: number;
};

const PanelFlowBays: FC<Props> = ({ plane, close, activeAddBayOnDraw, setActiveAddBayOnDraw, scale }) => {
  const dispatch = useAppDispatch();

  const baysState = useAppSelector((state) => state.baysState);
  const calcData = useAppSelector((state) => state.calcData);
  const roofPlanes = useAppSelector((state) => state.roofPlanes);

  useEffect(() => {
    dispatch(clearBayState());
    setActiveAddBayOnDraw(true); // Na start or razu aktywowanie
  }, []);

  const acceptBay = () => {
    const plane = roofPlanes.find((p) => p.id === baysState.roofPlaneId) as IRoofPlane;

    let data: ICalculationWithBayRequest = {
      calcData: calcData,
      roofPlaneCalcId: plane.calcId as number,
      bayModel: getBayModel(scale),
    };

    dispatch(updateBayObject(null)); // Czyścimy rysunek

    dispatch(calculateRoofWithBay(data));
    dispatch(invalidateCalculation());
  };

  const getBayModel = (scale: number) => {
    let type = 2;

    if (baysState.bayType === "bay-rectangle") {
      type = 1;
    }

    const p0 = baysState.bayObject?.p1 as IPoint;
    const a = baysState.h1 * scale;
    const w = baysState.w2 * scale;
    const hy = baysState.w10 * scale;
    const overhangSide = baysState.overhangSide * scale;
    const overhangFront = baysState.overhangFront * scale;

    let bayModel: IBayModel = {
      type: type,
      a: a,
      x: p0.x,
      y: p0.y,
      w: w,
      alfa: 0,
      dd: baysState.bayType === "bay-rectangle" ? baysState.angle : 0, // Kąt nachylenia połaci czołowej
      ee: 0,
      ss: hy,
      overhangSide: overhangSide,
      overhangFront: overhangFront,
    };

    return bayModel;
  };

  return (
    <Container title="Wykusze i lukarny" size="small" bodyStyle={{ padding: "0px" }}>
      <SimpleContent size="small" headStyle={{ backgroundColor: "#f5f5f5" }}>
        <StyledRadioGroup
          style={{ width: "100%" }}
          onChange={(e) => dispatch(updateBayTypeInBay(e.target.value))}
          value={baysState.bayType}
          buttonStyle="solid"
          disabled={baysState.bayObject !== null}
        >
          <div style={{ display: "flex", gap: "8px", width: "100%" }}>
            <Radio.Button value="bay-rectangle" style={{ width: "100%" }}>
              Prostokątny
            </Radio.Button>
            <Radio.Button value="bay" style={{ width: "100%" }}>
              Trapezowy
            </Radio.Button>
          </div>
        </StyledRadioGroup>
        <Divider style={{ margin: "16px 0px 16px 0px" }} />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", marginTop: "8px" }}>
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              W1 [m]
            </Col>
            <Col span={8}>
              <DomaInputNumber
                step={0.01}
                value={baysState.w1 ? baysState.w1.toFixed(2) : 0}
                onChange={(value) => dispatch(updateW1InBay(value as number))}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              <Button disabled>
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              W2 [m]
            </Col>
            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
              <DomaInputNumber
                allowNegative
                step={0.01}
                value={baysState.w2 ? baysState.w2.toFixed(2) : 0}
                onChange={(value) => dispatch(updateW2InBay(value as number))}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              {baysState.w2 === 0 && (
                <Button onClick={(value) => dispatch(updateW2InBay(0.5))}>
                  <ArrowsAltOutlined />
                </Button>
              )}
              {baysState.w2 != 0 && (
                <Button onClick={(value) => dispatch(updateW2InBay(0))}>
                  <ShrinkOutlined />
                </Button>
              )}
            </Col>
          </Row>
          <Row style={baysState.bayType === "bay" ? {} : { opacity: "0.5", pointerEvents: "none" }}>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              Wcięcie [m]
            </Col>
            <Col span={8}>
              <DomaInputNumber
                disabled={baysState.bayType === "bay-rectangle"}
                step={0.01}
                allowNegative={false}
                value={
                  baysState.w2 && baysState.w2 < 0
                    ? (baysState.w10 + baysState.w2).toFixed(2)
                    : baysState.w10
                    ? baysState.w10.toFixed(2)
                    : 0
                }
                onChange={(value) => dispatch(updateW10InBay(value as number))}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              {baysState.w2 < 0 ? (
                // Gdy lukarna
                <div>
                  {Math.abs(baysState.w10) === Math.abs(baysState.w2) ? (
                    <Button
                      onClick={(value) => {
                        dispatch(updateW10InBay(-baysState.w2 + 0.25));
                      }}
                    >
                      <ArrowsAltOutlined />
                    </Button>
                  ) : (
                    <Button
                      onClick={(value) => {
                        dispatch(updateW10InBay(-baysState.w2));
                      }}
                    >
                      <ShrinkOutlined />
                    </Button>
                  )}
                </div>
              ) : (
                // Gdy wykusz
                <div>
                  {baysState.w10 > 0 ? (
                    <Button
                      onClick={(value) => {
                        dispatch(updateW10InBay(0));
                      }}
                    >
                      <ArrowsAltOutlined />
                    </Button>
                  ) : (
                    <Button
                      onClick={(value) => {
                        dispatch(updateW10InBay(0.5));
                      }}
                    >
                      <ShrinkOutlined />
                    </Button>
                  )}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              Szerokość [m]
            </Col>
            <Col span={8}>
              <DomaInputNumber
                step={0.01}
                value={baysState.h1 ? baysState.h1.toFixed(2) : 0}
                onChange={(value) => {
                  dispatch(updateH1InBay(value as number));
                }}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              <Button disabled>
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
          {/* Tylko dla prostokątnego */}
          <Row style={baysState.bayType === "bay-rectangle" ? {} : { opacity: "0.5", pointerEvents: "none" }}>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              Kąt nachylenia [°]
            </Col>
            <Col span={12}>
              <DomaInputNumber
                disabled={baysState.bayType === "bay"}
                value={baysState.angle ? baysState.angle : 0}
                onChange={(value) => dispatch(updateAngleInBay(value as number))}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              Okap boczny [m]
            </Col>
            <Col span={8}>
              <DomaInputNumber
                value={baysState.overhangSide ? baysState.overhangSide : 0}
                onChange={(value) => dispatch(updateOverhangSideInBay(value as number))}
                step={0.01}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              <Button onClick={(value) => dispatch(updateOverhangSideInBay(0))}>
                <ShrinkOutlined />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              Okap czołowy [m]
            </Col>
            <Col span={8}>
              <DomaInputNumber
                value={baysState.overhangFront ? baysState.overhangFront : 0}
                onChange={(value) => dispatch(updateOverhangFrontInBay(value as number))}
                step={0.01}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
              <Button onClick={(value) => dispatch(updateOverhangFrontInBay(0))}>
                <ShrinkOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      </SimpleContent>
      <Row style={{ display: "flex", justifyContent: "space-between", gap: "8px", padding: "8px" }}>
        {baysState.bayObject === null && activeAddBayOnDraw === false ? (
          <Button
            type="primary"
            onClick={() => {
              setActiveAddBayOnDraw(true);
            }}
          >
            Dodaj na rysunku
          </Button>
        ) : baysState.bayObject ? (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              type="primary"
              danger
              onClick={() => {
                setActiveAddBayOnDraw(false);
                dispatch(updateBayObject(null)); // Czyścimy rysunek
              }}
            >
              Przerwij
            </Button>
            <Button
              type="primary"
              onClick={() => {
                // Sprawdzamy czy dane są poprawne - czy > 0
                if (
                  baysState.w1 <= 0 ||
                  baysState.h1 <= 0 ||
                  baysState.overhangFront < 0 ||
                  baysState.overhangSide < 0
                ) {
                  // Wyświetl komunikat
                  message.error("Wprowadź poprawne dane wykuszu.");

                  return;
                }

                // Jeśli ok, to zapisujemy dane
                acceptBay();
                setActiveAddBayOnDraw(false);
              }}
            >
              Zatwierdź wykusz
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            danger
            onClick={() => {
              setActiveAddBayOnDraw(false);
              dispatch(updateBayObject(null)); // Czyścimy rysunek
            }}
          >
            Przerwij
          </Button>
        )}

        <Button
          type="default"
          onClick={() => {
            close();
            setActiveAddBayOnDraw(false);
          }}
        >
          Zakończ
        </Button>
      </Row>
    </Container>
  );
};
export default PanelFlowBays;

const Container = styled(Card)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
  margin-top: 66px;
  padding: 0px;
  width: 400px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 999;
`;

const SimpleContent = styled(Card)`
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
