import { DollarOutlined, InfoCircleOutlined, ProjectOutlined } from "@ant-design/icons";
import { Alert, Card, Col, Row, Statistic, Tag, Timeline, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { IRootState } from "../../models/Models";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import DomaLogoComponentBW from "../../components/SVGComponents/DomaLogoComponentBW";
import NotificationPosition from "./components/NotificationPosition";
import { getNotificationsFromApi } from "../../redux/informations/notificationsSlice";
import { getSystemActivitiesFromApi } from "../../redux/informations/systemActivitiesSlice";
import { getInformationsFromApi } from "../../redux/informations/informationsSlice";
import CustomProgress from "./components/CustomProgress";
import { useNavigate, useNavigation } from "react-router-dom";
import { PRIMARY_COLOR } from "../../theme";

const { Text } = Typography;

type Props = {};

// Dashboard comment
const HomePage: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const parentStatic = useAppSelector((state: IRootState) => state.parentStatic);

  const profile = useAppSelector((state) => state.profile);

  const notifications = useAppSelector((state) => state.notifications);
  const notificationTypes = useAppSelector((state) => state.notificationTypes);

  const systemActivities = useAppSelector((state) => state.systemActivities);
  const systemActivityTypes = useAppSelector((state) => state.systemActivityTypes);

  const informations = useAppSelector((state) => state.informations);

  useEffect(() => {
    dispatch(getInformationsFromApi());
    dispatch(getNotificationsFromApi());
    dispatch(getSystemActivitiesFromApi());
  }, []);

  useEffect(() => {}, [parentStatic]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  const isNoAdmin = () => {
    return profile.role === "seller" || profile.role === "moderator" || profile.role === "contractor";
  };

  const getNotifications = () => {
    const arr = [];

    // Sortowanie aktywności od najnowszej do najstarszej
    const sortedNotifications = [...notifications.notifications].sort(
      (a, b) => Date.parse(b.created) - Date.parse(a.created)
    );

    // Pobranie pierwszych 5 elementów
    const recentNotifications = [...sortedNotifications].slice(0, 5);

    for (const notification of recentNotifications) {
      const notificationType = notificationTypes.find((o) => o.id === notification.notificationTypeId);

      let comment = "";

      switch (notificationType?.code) {
        case "updateStatusReport":
          comment = `Zmieniono status raportu z ${notification.details}`;
          break;
        case "updateTypeReport":
          comment = `Zmieniono typ raportu z ${notification.details}`;
          break;
        default:
          comment = `${notification.details}`;
          break;
      }

      const title = notificationType?.name;
      const who = notification.userName;
      const investmentCode = notification.investmentCode;
      const reportCode = notification.reportCode;
      const when = new Date(notification.created).toLocaleString("pl-PL");

      const message = (
        <NotificationRow>
          <NotificationColumn style={{ width: "150px" }}>
            <Tag color="green" style={{ width: "100%", textAlign: "center" }}>
              {title}
            </Tag>
          </NotificationColumn>

          <NotificationColumn style={{ width: "150px" }}>{who}</NotificationColumn>

          <NotificationColumn style={{ width: "400px", fontWeight: "bold" }}>{comment}</NotificationColumn>

          <NotificationColumn style={{ width: "150px" }}>{when}</NotificationColumn>

          <NotificationColumn style={{ width: "150px", gap: "8px" }}>
            <div>Inwestycja:</div>
            <Tag
              style={{ cursor: "pointer" }}
              color={PRIMARY_COLOR}
              onClick={() => openInNewTab(`${startLink}/${investmentCode}`)}
            >
              {investmentCode ? `${investmentCode}` : "Brak danych"}
            </Tag>
          </NotificationColumn>

          <NotificationColumn style={{ width: "150px", gap: "8px" }}>
            <div>Raport:</div>
            <Tag
              style={{ cursor: "pointer" }}
              color={PRIMARY_COLOR}
              onClick={() => openInNewTab(`${startLink}/${investmentCode}/${reportCode}`)}
            >
              {reportCode && `${reportCode}`}
            </Tag>
          </NotificationColumn>
        </NotificationRow>
      );

      arr.push(<NotificationPosition key={notification.id} type={"info"} message={message} />);
    }

    return arr;
  };

  const getSystemActivities = () => {
    const arr = [];

    // Sortowanie aktywności od najnowszej do najstarszej
    const sortedActivities = [...systemActivities.systemActivities].sort(
      (a, b) => Date.parse(b.created) - Date.parse(a.created)
    );

    // Pobranie pierwszych 5 elementów
    const recentActivities = [...sortedActivities].slice(0, 5);

    for (const activity of recentActivities) {
      const systemActivityType = systemActivityTypes.find((o) => o.id === activity.systemActivityTypeId);

      const when = new Date(activity.created).toLocaleString("pl-PL");

      arr.push(
        <Timeline.Item key={activity.id} color="green">
          <NotificationRow style={{ justifyContent: "flex-start", gap: "8px" }}>
            <NotificationColumn>{activity.userName}</NotificationColumn>
            <NotificationColumn>{when}</NotificationColumn>
            <NotificationColumn style={{ fontWeight: "bold" }}>{systemActivityType?.name}</NotificationColumn>
          </NotificationRow>
        </Timeline.Item>
      );
    }

    return arr;
  };

  const startLink = "https://raport.doma.pl";
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getSumOffers = () => {
    return (
      informations.offersCreated +
      informations.offersSent +
      informations.offersRejected +
      informations.offersAccepted
    );
  };

  return (
    <Container>
      {isAdmin() && (
        <div>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card
                loading={informations.loading}
                onClick={() => navigate("oferty")}
                style={{ cursor: "pointer" }}
              >
                <Statistic
                  title="Przygotowane projekty (oferty)"
                  value={`${informations.investmentsCount} (${informations.reportsCount})`}
                  prefix={<ProjectOutlined />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card loading={informations.loading}>
                <Statistic
                  title="Średnia cena oferty (netto)"
                  value={informations.averagePriceOffers.toFixed(2)}
                  prefix={<DollarOutlined />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Card
                loading={informations.loading}
                onClick={() => navigate("materialy")}
                style={{ cursor: "pointer" }}
              >
                <Statistic
                  title="Produkty w sklepie"
                  value={informations.productsCount}
                  prefix={<InfoCircleOutlined />}
                />
              </Card>
            </Col>
          </Row>

          <StyledRow gutter={16} style={{ marginTop: "16px" }}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card title="Powiadomienia" bordered={false} loading={notifications.loading}>
                {getNotifications()}
              </Card>
            </Col>
          </StyledRow>

          <StyledRow gutter={16} style={{ marginTop: "16px", display: "flex" }}>
            <Col xs={24} sm={24} md={12} lg={12} style={{ height: "100%" }}>
              <Card title="Status ofert" bordered={false} loading={informations.loading}>
                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Utworzone
                </Text>
                <CustomProgress count={informations.offersCreated} maxElements={getSumOffers()} status="active" />

                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Wysłane
                </Text>
                <CustomProgress count={informations.offersSent} maxElements={getSumOffers()} status="normal" />

                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Odrzucone
                </Text>
                <CustomProgress
                  count={informations.offersRejected}
                  maxElements={getSumOffers()}
                  status="exception"
                />

                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Zaakceptowane
                </Text>
                <CustomProgress
                  count={informations.offersAccepted}
                  maxElements={getSumOffers()}
                  status="success"
                />
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} style={{ height: "100%" }}>
              <Card title="Ostatnie aktywności w systemie" bordered={false} loading={systemActivities.loading}>
                <Timeline>{getSystemActivities()}</Timeline>
              </Card>
            </Col>
          </StyledRow>

          {/* <StyledRow gutter={16} style={{ marginTop: "16px" }}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card title="Lista użytkowników" bordered={false}>
                <Table dataSource={userData} columns={userColumns} pagination={false} />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Card title="Lista popularnych produktów" bordered={false}>
                <Table dataSource={productData} columns={productColumns} pagination={false} />
              </Card>
            </Col>
          </StyledRow> */}
        </div>
      )}
      {isNoAdmin() && (
        <div style={{ position: "relative" }}>
          <LogoContainer>
            <StyledLogo />
          </LogoContainer>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Card
                loading={informations.loading}
                onClick={() => navigate("oferty")}
                style={{ cursor: "pointer" }}
              >
                <Statistic
                  title="Przygotowane projekty (oferty)"
                  value={`${informations.investmentsCount} (${informations.reportsCount})`}
                  prefix={<ProjectOutlined />}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Card loading={informations.loading}>
                <Statistic
                  title="Średnia cena oferty (netto)"
                  value={informations.averagePriceOffers.toFixed(2)}
                  prefix={<DollarOutlined />}
                  suffix={<ReportSuffix>zł</ReportSuffix>}
                />
              </Card>
            </Col>
          </Row>

          {notifications && notifications.notifications.length > 0 && (
            <StyledRow gutter={16} style={{ marginTop: "16px" }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Card title="Powiadomienia" bordered={false} loading={notifications.loading}>
                  {getNotifications()}
                </Card>
              </Col>
            </StyledRow>
          )}

          <StyledRow gutter={16} style={{ marginTop: "16px", display: "flex" }}>
            <Col xs={24} sm={24} md={24} lg={24} style={{ height: "100%" }}>
              <Card title="Status ofert" bordered={false} loading={informations.loading}>
                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Utworzone
                </Text>
                <CustomProgress count={informations.offersCreated} maxElements={getSumOffers()} status="active" />

                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Wysłane
                </Text>
                <CustomProgress count={informations.offersSent} maxElements={getSumOffers()} status="normal" />

                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Odrzucone
                </Text>
                <CustomProgress
                  count={informations.offersRejected}
                  maxElements={getSumOffers()}
                  status="exception"
                />

                <Text strong style={{ display: "block", marginBottom: 0 }}>
                  Zaakceptowane
                </Text>
                <CustomProgress
                  count={informations.offersAccepted}
                  maxElements={getSumOffers()}
                  status="success"
                />
              </Card>
            </Col>
          </StyledRow>
        </div>
      )}
    </Container>
  );
};
export default HomePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled(DomaLogoComponentBW)`
  width: 20%;
  height: 20%;
`;

const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;

const Section = styled(Card)`
  width: 100%;
`;

const ReportSuffix = styled.span`
  font-size: 14px;
  color: #000000;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const StyledRow = styled(Row)``;

const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
`;

const NotificationRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`;

const NotificationColumn = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
