import React from "react";
import { FC, useEffect, useState } from "react";
import { Group, Line } from "react-konva";
import { useAppSelector } from "../../../../../../redux/hooks";
import { IRSOEdge, IRSOLinkedEdges } from "./Models/RoofSlopeOutlineModels";

type Props = {
  rsole: IRSOLinkedEdges;
};

const RoofSlopeOutlineLinkedLine: FC<Props> = ({ rsole }) => {
  const [edges, setEdges] = useState<IRSOEdge[]>([]);

  const rsoPoints = useAppSelector((state) => state.rsoPoints) || [];
  const rsoEdges = useAppSelector((state) => state.rsoEdges) || [];

  useEffect(() => {
    if (rsole) {
      const fEId = rsole.firstEdgeId;
      const sEId = rsole.secondEdgeId;

      const edge1 = rsoEdges.find((e) => e.id === fEId);
      const edge2 = rsoEdges.find((e) => e.id === sEId);

      if (edge1 && edge2) {
        setEdges([edge1, edge2]);
      }
    }
  }, [rsole]);

  const startPointFirstEdge = rsoPoints.find((p) => p.id === edges[0]?.startPointId);
  const endPointFirstEdge = rsoPoints.find((p) => p.id === edges[0]?.endPointId);
  const startPointSecondEdge = rsoPoints.find((p) => p.id === edges[1]?.startPointId);
  const endPointSecondEdge = rsoPoints.find((p) => p.id === edges[1]?.endPointId);

  let middlePointX1 = 0;
  let middlePointY1 = 0;
  let middlePointX2 = 0;
  let middlePointY2 = 0;

  if (startPointFirstEdge && endPointFirstEdge && startPointSecondEdge && endPointSecondEdge) {
    middlePointX1 = (startPointFirstEdge?.x + endPointFirstEdge?.x) / 2;
    middlePointY1 = (startPointFirstEdge?.y + endPointFirstEdge?.y) / 2;

    middlePointX2 = (startPointSecondEdge?.x + endPointSecondEdge?.x) / 2;
    middlePointY2 = (startPointSecondEdge?.y + endPointSecondEdge?.y) / 2;
  }

  const controlPointX1 = (middlePointX1 + middlePointX2) / 2 + 50; // dodajemy/przekształcamy wartość, aby stworzyć efekt falowania
  const controlPointY1 = (middlePointY1 + middlePointY2) / 2;

  const controlPointX2 = (middlePointX1 + middlePointX2) / 2 - 50; // odejmujemy/przekształcamy wartość, aby stworzyć efekt falowania
  const controlPointY2 = (middlePointY1 + middlePointY2) / 2;

  return (
    <Group>
      {edges && edges.length === 2 && (
        <Line
          points={[
            middlePointX1,
            middlePointY1,
            controlPointX1,
            controlPointY1,
            controlPointX2,
            controlPointY2,
            middlePointX2,
            middlePointY2,
          ]}
          bezier={true}
          stroke="#9da199cc"
          strokeWidth={2}
          dash={[10, 5]}
        />
      )}
    </Group>
  );
};

export default RoofSlopeOutlineLinkedLine;
