import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalcMaterialsData } from "../../models/Models";

const initialState: ICalcMaterialsData = {
  systemType: 0, // 0 ceramiczna, -1 cementowa, 1 stalowa, 2 stalowa, 3 stalowa,
  roofSystemId: null,
  gutterSystemId: null,
  fasteningSystemId: null,
  preCoveringSystemId: null,
  eaveOverhang: 0.8,
  wallHeight: 2.5,
  drainPipeCount: 0,
  internalCornerCount: 0,
  outerCornerCount: 0,
  leftCapCount: 0,
  rightCapCount: 0,
};

// Kontener na dane materiałowe potrzebne do oblczeń
export const calcMaterialsDataSlice = createSlice({
  name: "calcMaterialsData",
  initialState,
  reducers: {
    updateCalcMaterialsData: (state, action: PayloadAction<ICalcMaterialsData>) => {
      const generalRoofData = action.payload;
      return { ...generalRoofData };
    },
    clearCalcMaterialsData: (state) => {
      return initialState;
    },
    clearGutteringElementsCalcMaterialsData: (state) => {
      return {
        ...state,
        drainPipeCount: 0,
        internalCornerCount: 0,
        outerCornerCount: 0,
        leftCapCount: 0,
        rightCapCount: 0,
        eaveOverhang: 0.8,
        wallHeight: 2.5,
      };
    },
    setSavedRoofCalcMaterialsData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  updateCalcMaterialsData,
  clearCalcMaterialsData,
  clearGutteringElementsCalcMaterialsData,
  setSavedRoofCalcMaterialsData,
} = calcMaterialsDataSlice.actions;
export default calcMaterialsDataSlice.reducer;
