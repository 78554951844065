import { Alert } from "antd";
import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  message: JSX.Element | string;
  type: "info" | "success" | "warning" | "error" | undefined;
};

const NotificationPosition: FC<Props> = ({ type, message }) => {
  return <StyledAlert message={message} type={type} showIcon={false} closable />;
};

export default NotificationPosition;

const StyledAlert = styled(Alert)`
  margin-bottom: 10px;
`;
