import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICalculationPlane, ICalculationPoint } from "../../models/Models";

// To są wszystkie dane wejściowe jakie były przy danym obrysie dachu wysyłane do API jako ostatnie (nie uzwglednia wykuszy itp.)
export interface ISavedOutline {
  index: number;
  points: ICalculationPoint[];
  planes: ICalculationPlane[];
}

const initialState: ISavedOutline[] = [];

export const savedOutlinesSlice = createSlice({
  name: "savedOutlines",
  initialState,
  reducers: {
    addSavedOutlines: (state, action: PayloadAction<ISavedOutline[]>) => {
      return action.payload;
    },
    addSavedOutline: (state, action: PayloadAction<ISavedOutline>) => {
      return [...state, action.payload];
    },
    // Aktualizuj dh w plane należącym do savedoutline
    updateSavedOutlinePlane: (
      state,
      action: PayloadAction<{ savedOutlineIndex: number; plane: ICalculationPlane }>
    ) => {
      const data = action.payload;
      const index = state.findIndex((o) => o.index === data.savedOutlineIndex);
      // aktualizacja po klejności na liści ebo pole index nie jest unikalne
      if (index >= 0) {
        const planeIndex = state[index].planes.findIndex((o) => o.index === data.plane.index);
        if (planeIndex >= 0) {
          state[index].planes[planeIndex] = data.plane;
        }
      }
    },
    updateSavedOutline: (state, action: PayloadAction<ISavedOutline>) => {
      const newData = action.payload;
      const index = state.findIndex((outline) => outline.index === newData.index);

      if (index !== -1) {
        // Aktualizacja istniejącego SavedOutline
        // state[index] = { ...state[index], ...newData };

        // Głęboka aktualizacja dla SavedOutline
        const existingOutline = state[index];
        existingOutline.points = newData.points;
        existingOutline.planes = newData.planes;

        // Aktualizacja pozostałych właściwości, jeśli istnieją
        Object.assign(existingOutline, newData);
      } else {
        console.warn(`SavedOutline with index ${newData.index} not found.`);
      }
    },
    updateSavedOutlines: (state, action: PayloadAction<ISavedOutline[]>) => {
      return action.payload;
    },
    removeSavedOutline: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      return state.filter((o) => o.index !== index);
    },
    resetSavedOutlines: (state) => {
      return initialState;
    },
    setSavedOutlines: (state, action: PayloadAction<ISavedOutline[]>) => {
      return action.payload;
    },
  },
});

export const {
  addSavedOutlines,
  addSavedOutline,
  updateSavedOutline,
  updateSavedOutlinePlane,
  updateSavedOutlines,
  removeSavedOutline,
  resetSavedOutlines,
  setSavedOutlines,
} = savedOutlinesSlice.actions;
export default savedOutlinesSlice.reducer;
