import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IGutterSystemAdditionType } from "../../models/Models";

const initialState: IGutterSystemAdditionType[] = [];

export const gutterSystemAdditionTypesSlice = createSlice({
  name: "gutterSystemAdditionTypes",
  initialState,
  reducers: {
    setGutterSystemAdditionTypessInReducer: (state, action: PayloadAction<IGutterSystemAdditionType[]>) => {
      let gutterSystems = action.payload;
      return gutterSystems;
    },
  },
});

export const { setGutterSystemAdditionTypessInReducer } = gutterSystemAdditionTypesSlice.actions;
export default gutterSystemAdditionTypesSlice.reducer;
