import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import {
  IAddPrincingSystem,
  IContractor,
  IPrincingSystem,
  IUpdateActivePriceSystemRequestModel,
} from "../../models/Models";
import { AppDispatch } from "../store";

const initialState: IContractor[] = [];

export const parentContractorsSlice = createSlice({
  name: "parentContractors",
  initialState,
  reducers: {
    setParentContractorsInReducer: (state, action: PayloadAction<IContractor[]>) => {
      let list = action.payload;
      return list;
    },
    addParentContractorInReducer: (state, action: PayloadAction<IContractor>) => {
      let pc = action.payload;
      return [...state, pc];
    },
    deleteContractorInReducer: (state, action: PayloadAction<string>) => {
      const contractorId = action.payload;
      return state.filter((o) => o.id !== contractorId);
    },
  },
});

export const { setParentContractorsInReducer, addParentContractorInReducer, deleteContractorInReducer } =
  parentContractorsSlice.actions;
export default parentContractorsSlice.reducer;
