import React, { FC, useEffect, useRef, useState } from "react";
import { InputNumber, InputNumberProps, message } from "antd";

interface DomaInputNumberProps extends InputNumberProps {
  allowNegative?: boolean;
}

const DomaInputNumber: FC<DomaInputNumberProps> = ({ onChange, allowNegative = false, ...props }) => {
  const inputNumberRef = useRef<any>(null);

  useEffect(() => {
    const handleInput = (e: any) => {
      const inputValue = parseFloat(e.target.value.replace(",", "."));
      if (!allowNegative && inputValue < 0) {
        message.error("Nie wolno wprowadzać ujemnych wartości!");
        e.target.value = "0";
        if (onChange) {
          onChange(0);
        }
      }
    };

    const inputElem = inputNumberRef.current;
    if (inputElem) {
      inputElem.addEventListener("input", handleInput);
      return () => {
        inputElem.removeEventListener("input", handleInput);
      };
    }
  }, [onChange, allowNegative]);

  return <InputNumber ref={inputNumberRef} decimalSeparator="," onChange={onChange} {...props} />;
};

export default DomaInputNumber;
