import React, { FC, useEffect, useState } from "react";
import { Circle, Group, Line } from "react-konva";
import { rotate } from "../../../../../helpers/Calculator";
import { EdgeType } from "../../../../../models/Enums";
import { IBasicPoint, IProduct, IRoofEdge, IRoofPlane, IRoofPoint } from "../../../../../models/Models";
import { useAppSelector } from "../../../../../redux/hooks";
import { IWindowHole } from "./PanelFlowWindowHoles";

type Props = {
  scale: number; // skala wymiaru
  zoomScale: number;
  plane: IRoofPlane | null;
  selectedWindowHole: IWindowHole;
};

const WindowInDraw: FC<Props> = ({ scale, zoomScale, plane, selectedWindowHole }) => {
  const mousePosition = useAppSelector((state) => state.mousePosition);
  const edges = useAppSelector((state) => state.roofEdges);
  const points = useAppSelector((state) => state.roofPoints);
  const holes = useAppSelector((state) => state.roofHoles);

  const [mp, setMp] = useState<IBasicPoint>({ x: 0, y: 0 });

  const [eaveEdge, setEaveEdge] = useState<IRoofEdge | null>(null);

  const [holePointsOnPlane, setHolePointsOnPlane] = useState<IRoofPoint[]>([]);

  const [tempLinesForWindows, setTempLinesForWindows] = useState<{ p1: IBasicPoint; p2: IBasicPoint }[]>([]);

  useEffect(() => {
    if (plane) {
      const planeId = plane.id;
      const planeHoles = holes.filter((x) => x.roofPlaneId === planeId);

      if (planeHoles) {
        const lines: { p1: IBasicPoint; p2: IBasicPoint }[] = [];

        for (const h of planeHoles) {
          if (h.pointIds) {
            const p0Id = h.pointIds[0];
            const p1Id = h.pointIds[1];
            const p2Id = h.pointIds[2];
            const p3Id = h.pointIds[3];

            const p0 = points.find((x) => x.id === p0Id) as IBasicPoint;
            const p1 = points.find((x) => x.id === p1Id) as IBasicPoint;
            const p2 = points.find((x) => x.id === p2Id) as IBasicPoint;
            const p3 = points.find((x) => x.id === p3Id) as IBasicPoint;

            lines.push({ p1: p0, p2: p1 });
            lines.push({ p1: p1, p2: p2 });
            lines.push({ p1: p2, p2: p3 });
            lines.push({ p1: p3, p2: p0 });
          }
        }

        setTempLinesForWindows(lines);
      }
    }
    setMp(mousePosition);
  }, [mousePosition]);

  useEffect(() => {
    if (plane) {
      const planeId = plane.id;
      const planeHoles = holes.filter((x) => x.roofPlaneId === planeId);

      if (planeHoles && planeHoles.length > 0) {
        const res: IRoofPoint[] = [];

        for (const ph of planeHoles) {
          for (const p of ph.pointIds as string[]) {
            const pp = points.find((x) => x.id === p);

            if (pp) {
              res.push(pp);
            }
          }
        }
        setHolePointsOnPlane(res);
      } else {
        setHolePointsOnPlane([]);
      }
    } else {
      setHolePointsOnPlane([]);
    }
  }, [holes]);

  useEffect(() => {
    // Szukamy krawędzi okapu
    if (plane && edges) {
      for (const id of plane.edgeIds as []) {
        const e = edges.find((x) => x.id === id);
        if (e && e.type === EdgeType.Eaves) {
          setEaveEdge(e);
          break;
        }
      }
    }
  }, [plane, edges]);

  const ExtendedLine: FC<{ pointA: any; pointB: any }> = ({ pointA, pointB }) => {
    const screenWidth = 9999;
    const screenHeight = 9999;

    // Oblicz wektor między punktami A i B
    const vector = {
      x: pointB?.x - pointA?.x,
      y: pointB?.y - pointA?.y,
    };

    // Oblicz długość wektora
    const length = Math.sqrt(vector.x * vector.x + vector.y * vector.y);

    // Oblicz jednostkowy wektor
    const unitVector = {
      x: vector.x / length,
      y: vector.y / length,
    };

    // Określ o ile należy przedłużyć linię poza punkty A i B
    const extension = Math.max(screenWidth, screenHeight);

    // Oblicz nowe końce linii
    const startPoint = {
      x: pointA?.x - unitVector.x * extension,
      y: pointA?.y - unitVector.y * extension,
    };

    const endPoint = {
      x: pointB?.x + unitVector.x * extension,
      y: pointB?.y + unitVector.y * extension,
    };

    return (
      <Line points={[startPoint.x, startPoint.y, endPoint.x, endPoint.y]} stroke="#7c7c7c" strokeWidth={0.5} />
    );
  };

  const calculateAngleLineToAxisX = (A: any, B: any) => {
    const angle = (Math.atan2(B.y - A.y, B.x - A.x) * 180) / Math.PI;
    return angle;
  };

  const getRotatedPoints = () => {
    const wInM = (selectedWindowHole.width as number) / 100;
    const hInM = (selectedWindowHole.height as number) / 100;

    let w = wInM * scale;
    let h = hInM * scale;

    // Zmniejszamy wysokośc okna o kąt spadku
    const slopeAngle = plane?.angle as number;
    h = h * Math.cos(slopeAngle * (Math.PI / 180));

    const basicPoints = [
      { x: mp.x, y: mp.y },
      { x: mp.x, y: mp.y + h },
      { x: mp.x - w, y: mp.y + h },
      { x: mp.x - w, y: mp.y },
    ];

    const start = points.find((x) => x.id === eaveEdge?.startPointId);
    const end = points.find((x) => x.id === eaveEdge?.endPointId);

    const angle = -1 * calculateAngleLineToAxisX(start, end);

    const result = [];

    for (const p of basicPoints) {
      result.push(rotate(mp.x, mp.y, p.x, p.y, angle));
    }

    return result.flat();
  };

  const getWindowOutline = () => {
    return (
      <Line
        points={getRotatedPoints()}
        closed={true}
        stroke="#000000"
        strokeWidth={1 / zoomScale}
        fill="#ffffff80"
      />
    );
  };

  return (
    <Group>
      <Circle
        x={mp.x}
        y={mp.y}
        radius={4 / zoomScale}
        strokeWidth={1 / zoomScale}
        stroke={"#000000"}
        fill="#ffffff"
      />

      {window && points && eaveEdge && getWindowOutline()}

      {tempLinesForWindows.map((x, i) => (
        <ExtendedLine key={i} pointA={x.p1} pointB={x.p2} />
      ))}
    </Group>
  );
};
export default WindowInDraw;
