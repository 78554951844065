import Konva from "konva";
import React, { FC, useRef, useState } from "react";
import { Group, Layer, Line, Rect, Text } from "react-konva";
import styled from "styled-components";
import { IRoofEdge, IRoofPoint } from "../../../../../models/Models";

const getPointsForLineComponent = (pointIds: any, points: any) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x: any) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

type Props = {
  roofEdge: IRoofEdge;
  roofPoints: IRoofPoint[];
  scale: number;
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
};

const GutterSymbolAsEdge: FC<Props> = ({ roofEdge, roofPoints, scale, point0Ref, stageRef }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipText, setTooltipText] = useState(""); // Tutaj możesz ustawić domyślny tekst
  const groupRef = useRef<Konva.Group>(null); // Ref do przechowywania odniesienia do komponentu Text

  const handleMouseEnter = (e: any) => {
    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    const scaledMp = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };

    setShowTooltip(true);
    setTooltipPosition({ x: scaledMp.x, y: scaledMp.y });
    setTooltipText("Orynnowanie powinno przebiegać wzdłuż tej krawędzi."); // Możesz tu ustawić dynamiczny tekst

    // Przesuń tooltip na wierzch po jego pojawieniu się
    if (groupRef.current) {
      groupRef.current.moveToTop(); // Przesuwa tooltip na wierzch w ramach jego warstwy
    }
  };

  const handleMouseMove = (e: any) => {
    const startPointPosition = point0Ref?.current?.getRelativePointerPosition(); // Pozycja niewidocznego punktu w pozycji 0,0
    const scaledMp = { x: startPointPosition?.x as number, y: startPointPosition?.y as number };

    setTooltipPosition({ x: scaledMp.x, y: scaledMp.y });
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);

    if (groupRef.current) {
      groupRef.current.moveToBottom(); // Przesuwa tooltip na wierzch w ramach jego warstwy
    }
  };

  const getStrokeWidth = () => {
    let baseStrokeWidth = 10 / scale;
    if (scale > 0.5) {
      baseStrokeWidth += 10;
    }

    return baseStrokeWidth;
  };

  return (
    <Container ref={groupRef}>
      <Line
        points={getPointsForLineComponent([roofEdge.startPointId, roofEdge.endPointId], roofPoints)}
        closed={false}
        stroke={"#9280b866"}
        strokeWidth={getStrokeWidth()}
        onMouseEnter={handleMouseEnter}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
      {showTooltip && (
        <Text
          text={tooltipText}
          width={300}
          x={tooltipPosition.x + 20}
          y={tooltipPosition.y - 20}
          fontSize={14 / scale}
          fontStyle="400"
          fill="black"
        />
      )}
    </Container>
  );
};
export default GutterSymbolAsEdge;

const Container = styled(Group)``;
