import React from "react";
import styled from "styled-components";
import { Alert, Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../redux/general/actions/profileActions";

export default function ProfileInfoCard({ profile }) {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const data = { fullName: values.fullName, contactEmail: values.contactEmail, phone: values.phone };
    dispatch(updateProfile(data));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <Container title="Dane ogólne">
      <Form initialValues={profile} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Form.Item
          label="Imię i nazwisko"
          name="fullName"
          rules={[{ required: true, message: "Podaj imię i nazwisko!" }]}
          hasFeedback
        >
          <Input value="fullName" />
        </Form.Item>

        <Form.Item
          label="Email kontaktowy"
          name="contactEmail"
          rules={[
            {
              type: "email",
              message: "Podana treść nie jest typem email!",
            },
            {
              required: true,
              message: "Podaj email do kontaktu!",
            },
          ]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Telefon"
          name="phone"
          rules={[{ required: true, message: "Podaj numer telefonu!" }]}
          hasFeedback
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ display: "flex", justifyContent: "right" }}>
          <Button type="primary" htmlType="submit">
            Zapisz zmiany
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
