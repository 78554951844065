import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IGutteringElement {
  id: string;
  x: number;
  y: number;
}

export interface IGutteringElements {
  gutterDrainPipes: IGutteringElement[];
  gutterInternalCorners: IGutteringElement[];
  gutterExternalCorners: IGutteringElement[];
  gutterEndLefts: IGutteringElement[];
  gutterEndRights: IGutteringElement[];
}

const initialState: IGutteringElements = {
  gutterDrainPipes: [],
  gutterInternalCorners: [],
  gutterExternalCorners: [],
  gutterEndLefts: [],
  gutterEndRights: [],
};

export const gutteringElementsSlice = createSlice({
  name: "gutteringElements",
  initialState,
  reducers: {
    addGutterDrainPipe: (state, action: PayloadAction<IGutteringElement>) => {
      let e = action.payload;
      return { ...state, gutterDrainPipes: [...state.gutterDrainPipes, e] };
    },
    addGutterInternalCorner: (state, action: PayloadAction<IGutteringElement>) => {
      let e = action.payload;
      return { ...state, gutterInternalCorners: [...state.gutterInternalCorners, e] };
    },
    addGutterExternalCorner: (state, action: PayloadAction<IGutteringElement>) => {
      let e = action.payload;
      return { ...state, gutterExternalCorners: [...state.gutterExternalCorners, e] };
    },
    addGutterEndLeft: (state, action: PayloadAction<IGutteringElement>) => {
      let e = action.payload;
      return { ...state, gutterEndLefts: [...state.gutterEndLefts, e] };
    },
    addGutterEndRight: (state, action: PayloadAction<IGutteringElement>) => {
      let e = action.payload;
      return { ...state, gutterEndRights: [...state.gutterEndRights, e] };
    },
    setGutteringElements: (state, action: PayloadAction<IGutteringElements>) => {
      let e = action.payload;
      return e;
    },
    deleteGutteringElement: (state, action: PayloadAction<{ id: string; type: string }>) => {
      let element = action.payload;
      if (element.type === "gutterDrainPipe") {
        return { ...state, gutterDrainPipes: state.gutterDrainPipes.filter((o) => o.id !== element.id) };
      } else if (element.type === "gutterInternalCorner") {
        return { ...state, gutterInternalCorners: state.gutterInternalCorners.filter((o) => o.id !== element.id) };
      } else if (element.type === "gutterExternalCorner") {
        return { ...state, gutterExternalCorners: state.gutterExternalCorners.filter((o) => o.id !== element.id) };
      } else if (element.type === "gutterEndLeft") {
        return { ...state, gutterEndLefts: state.gutterEndLefts.filter((o) => o.id !== element.id) };
      } else if (element.type === "gutterEndRight") {
        return { ...state, gutterEndRights: state.gutterEndRights.filter((o) => o.id !== element.id) };
      }
    },
    clearGutteringElements: (state) => {
      return initialState;
    },
    setSavedGutteringElements: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addGutterDrainPipe,
  addGutterInternalCorner,
  addGutterExternalCorner,
  addGutterEndLeft,
  addGutterEndRight,
  setGutteringElements,
  clearGutteringElements,
  setSavedGutteringElements,
  deleteGutteringElement,
} = gutteringElementsSlice.actions;
export default gutteringElementsSlice.reducer;
