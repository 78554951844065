import { Card } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import OfferInformations from "../../../../../components/OfferInformations";

type Props = {};

const OfferStep: FC<Props> = () => {
  return (
    <Container>
      <OfferInformations />
    </Container>
  );
};
export default OfferStep;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  width: 100%;
  height: 100%;
`;
