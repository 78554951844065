import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { IAddPreCoveringSystem, IAddSteelSystem, IPreCoveringSystem, ISteelSystem } from "../../models/Models";
import { addStatusApi } from "../other/statusApiSlice";
import { AppThunk } from "../store";

const initialState: IPreCoveringSystem[] = [];

export const preCoveringSystemsSlice = createSlice({
  name: "preCoveringSystems",
  initialState,
  reducers: {
    addPreCoveringSystemsInReducer: (state, action: PayloadAction<IPreCoveringSystem[]>) => {
      let systems = action.payload;
      return systems;
    },
    addPreCoveringSystemInReducer: (state, action: PayloadAction<IPreCoveringSystem>) => {
      let system = action.payload;
      return [...state, system];
    },
    updatePreCoveringSystemInReducer: (state, action: PayloadAction<IPreCoveringSystem>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);
      state[index] = { ...state[index], ...update };
    },
    deletePreCoveringSystemInReducer: (state, action: PayloadAction<string>) => {
      const systemId = action.payload;
      return state.filter((o) => o.id !== systemId);
    },
    setSavedPreCoveringSystem: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addPreCoveringSystemsInReducer,
  addPreCoveringSystemInReducer,
  updatePreCoveringSystemInReducer,
  deletePreCoveringSystemInReducer,
  setSavedPreCoveringSystem,
} = preCoveringSystemsSlice.actions;
export default preCoveringSystemsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add pre covering system
export const addPreCoveringSystem =
  (data: IAddPreCoveringSystem, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("ADD MODEL", data);
    client
      .put("/PreCoveringSystems", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addPreCoveringSystemInReducer(response.data));
          callback();
          // dispatch(addStatusApi({ key: "addSteelSystem", type: "success", message: "" }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addSteelSystem", type: "error", message: "Błąd dodania systemu!" }));
        // console.error(error);
      });
  };

// Update pre covering system
export const updatePreCoveringSystem =
  (data: IPreCoveringSystem): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE MODEL", data);
    client
      .post(`/PreCoveringSystems`, data)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATE MODEL RESPONSE", response.data);
          dispatch(updatePreCoveringSystemInReducer(response.data));

          message.open({
            type: "success",
            content: "System został zaktualizowany!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete pre covering system
export const deletePreCoveringSystem =
  (data: IPreCoveringSystem): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/PreCoveringSystems/${data.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deletePreCoveringSystemInReducer(data.id));
          dispatch(addStatusApi({ key: "deletePreCoveringSystem", type: "success", message: "" }));

          message.open({
            type: "success",
            content: "System został usunięty!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
