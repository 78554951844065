import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import DomaLogoComponentBW from "../../components/SVGComponents/DomaLogoComponentBW";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import RoofSteps from "./components/roofSteps/RoofSteps";
import AdditionalElementsStep from "./components/roofSteps/steps/AdditionalElementsStep";
import GeometryStep from "./components/roofSteps/steps/GeometryStep";
import MaterialsStep from "./components/roofSteps/steps/MaterialsStep";
import OfferStep from "./components/roofSteps/steps/OfferStep";
import PreviewStep from "./components/roofSteps/steps/PreviewStep";
import ResultStep from "./components/roofSteps/steps/ResultStep";
import RoofProcessManager from "./components/roofSteps/steps/RoofProcessManager";
import RoofSlopeOutlineMain from "./components/roofSteps/steps/RoofSlopeOutline/RoofSlopeOutlineMain";

type Props = {
  calcLabour?: boolean;
};

const RoofsPage: FC<Props> = ({ calcLabour }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = useState<number>(1);

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);
  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);

  useEffect(() => {
    if (location.pathname === "/dachy" && location.state && location.state.goToResults === true) {
      setActiveStep(6);
    } else if (location.pathname === "/dachy" && location.state && location.state.goToMaterials === true) {
      setActiveStep(4);
    }
  }, [location]);

  // Gdy proces rysowania dachu jest na nowo zainicjalizowany to ustawiamy pierwszy krok
  useEffect(() => {
    if (roofProcessStatus.type === "undefined") {
      setActiveStep(1);
    }
  }, [roofProcessStatus]);

  const isGeometry = () => {
    if ([1, 2].includes(activeStep)) {
      return true;
    } else {
      return false;
    }
  };

  const isControl = () => {
    return activeStep === 3 ? true : false;
  };

  const isMaterials = () => {
    return activeStep === 4 ? true : false;
  };

  const isAdditionalElements = () => {
    return activeStep === 5 ? true : false;
  };

  const isResult = () => {
    return activeStep === 6 ? true : false;
  };

  const isOffer = () => {
    return activeStep === 7 ? true : false;
  };

  return (
    <Container>
      {/* Cały mechanizm, który odpowiada za proces rysowania dachy, typu itp */}
      <RoofProcessManager step={activeStep} />

      <div>
        {/* Jeśłi już jest wybrany jakis typ rysowania to pojawia się pasek pomiędzy krokami oblicznia oferty */}
        {roofProcessStatus.type !== "undefined" && (
          <RoofSteps activeStep={activeStep} setActiveStep={setActiveStep} calcLabour={calcLabour} />
        )}

        {/* Zawartość rysowania czyli kroki - zawartość */}
        {roofProcessStatus.type !== "undefined" && (
          <Content>
            {isGeometry() && roofProcessStatus.type === "outline" && <GeometryStep step={activeStep} />}

            {isGeometry() && roofProcessStatus.type === "general" && <RoofSlopeOutlineMain />}

            {isControl() && <PreviewStep step={activeStep} />}

            {isMaterials() && <MaterialsStep step={activeStep} />}

            {isAdditionalElements() && <AdditionalElementsStep />}

            {isResult() && <ResultStep />}

            {isOffer() && <OfferStep />}
          </Content>
        )}

        {roofProcessStatus.type === "undefined" && (
          <EmptyContent>
            <StyledDomaLogoComponentBW />
          </EmptyContent>
        )}
      </div>
    </Container>
  );
};

export default RoofsPage;

const Container = styled.div``;

const Content = styled.div`
  margin-top: 16px;
  height: 100%;
`;

const EmptyContent = styled.div`
  width: 100%;
  height: 100%;

  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  width: 50%;
  height: 50%;
  min-height: 200px;
  font-size: 24px;
`;

const StyledDomaLogoComponentBW = styled(DomaLogoComponentBW)`
  width: 20%;
  height: 20%;
`;
