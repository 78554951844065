import { message } from "antd";
import Konva from "konva";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Circle, Group, Line, Text } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSO, IRSOEdge, IRSOHole, IRSOPoint, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { IBasicPoint } from "../../../../../../../models/Models";
import { addRSOPoints } from "../../../../../../../redux/roofSlopeOutline/rsoPointsSlice";
import { addRSOEdges } from "../../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { addRSOHole } from "../../../../../../../redux/roofSlopeOutline/rsoHolesSlice";
import { EdgeType } from "../../../../../../../models/Enums";
import { IWindowHole } from "../../../others/PanelFlowWindowHoles";

const calculateAngle = (vector: { x: number; y: number }) => {
  return Math.atan2(vector.x, vector.y); // Zmieniono kolejność argumentów
};

const rotatePoint = (point: IBasicPoint, angle: number, center: IBasicPoint): IBasicPoint => {
  const cosTheta = Math.cos(angle);
  const sinTheta = Math.sin(angle);

  return {
    x: cosTheta * (point.x - center.x) - sinTheta * (point.y - center.y) + center.x,
    y: sinTheta * (point.x - center.x) + cosTheta * (point.y - center.y) + center.y,
  };
};

interface FunctionAddHoleHandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  windowHole: IWindowHole;
  scale: number;
  close: () => void;
}

// Funckja do dodawania otwworu okiennego
const FunctionAddHoleHandler: FC<FunctionAddHoleHandlerProps> = ({
  stageRef,
  point0Ref,
  windowHole,
  scale,
  close,
}) => {
  const dispatch = useAppDispatch();

  const rsoEdges = useAppSelector((state) => state.rsoEdges);
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes);

  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  const [mouseOverPlane, setMouseOverPlane] = useState<boolean>(false);
  const [holePointsOnPlane, setHolePointsOnPlane] = useState<IBasicPoint[]>([]);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
  }, [mpLocal]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        e.evt.preventDefault();
        const mousePointPosition = point0Ref?.current?.getRelativePointerPosition();
        finishHoleDrawing(mousePointPosition as IBasicPoint, e);
      } else if (e.evt.button === 2) {
        // console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const mousePointPosition = point0Ref?.current?.getRelativePointerPosition();

      if (e.target.hasName("rsoPlane")) {
        const planeId = e.target.id();

        if (planeId) {
          setMouseOverPlane(true);
          const selectedPlane = rsoPlanes.find((plane) => plane.id === planeId);
          if (selectedPlane) {
            // console.log("Wybrano płaszczyznę", selectedPlane);

            const width = (windowHole.width / 100) * scale;
            let height = (windowHole.height / 100) * scale;

            let p0 = mousePointPosition as IBasicPoint;
            let p1 = { x: p0.x - width, y: p0.y } as IBasicPoint;
            let p2 = { x: p0.x - width, y: p0.y - height } as IBasicPoint;
            let p3 = { x: p0.x, y: p0.y - height } as IBasicPoint;

            // TODO jeśli jest okreslony wektor spadku to musi sie obracac okno w podglądzie
            if (selectedPlane.declineVectoor.x !== 0 || selectedPlane.declineVectoor.y !== 0) {
              // console.log("Okno ma wektor spadku");

              // W Twoim istniejącym kodzie
              const angle = -calculateAngle(selectedPlane.declineVectoor);

              height = height * Math.cos(selectedPlane.angle * (Math.PI / 180)); // zmiana wysokośc w zależności od kąta nachylenia

              p0 = mousePointPosition as IBasicPoint;
              p1 = rotatePoint({ x: p0.x - width, y: p0.y }, angle, p0);
              p2 = rotatePoint({ x: p0.x - width, y: p0.y - height }, angle, p0);
              p3 = rotatePoint({ x: p0.x, y: p0.y - height }, angle, p0);
            }

            setHolePointsOnPlane([p0, p1, p2, p3]);
          }
        }
      } else {
        setMouseOverPlane(false);
      }
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  const finishHoleDrawing = (mousePointPosition: IBasicPoint, e: Konva.KonvaEventObject<MouseEvent>) => {
    e.evt.stopPropagation();

    // Przygotować punkty tak jak w połaci tylko z innym statusem
    const points: IRSOPoint[] = [];

    // console.log("mouseClickPoint", mousePointPosition);

    if (e.target.hasName("rsoPlane")) {
      const planeId = e.target.id();

      if (planeId) {
        const selectedPlane = rsoPlanes.find((plane) => plane.id === planeId);
        if (selectedPlane) {
          // console.log("Wybrano płaszczyznę", selectedPlane);

          const width = (windowHole.width / 100) * scale;
          let height = (windowHole.height / 100) * scale; // Pełna wysokość

          let p0 = mousePointPosition as IBasicPoint;
          let p1 = { x: p0.x - width, y: p0.y } as IBasicPoint;
          let p2 = { x: p0.x - width, y: p0.y + height } as IBasicPoint;
          let p3 = { x: p0.x, y: p0.y + height } as IBasicPoint;

          // TODO jeśli jest okreslony wektor spadku to musi sie obracac okno w podglądzie
          if (selectedPlane.declineVectoor.x !== 0 || selectedPlane.declineVectoor.y !== 0) {
            // console.log("Okno ma wektor spadku");
            // Dostosować punkty do wektora spadku

            // W Twoim istniejącym kodzie
            const angle = -calculateAngle(selectedPlane.declineVectoor);

            height = height * Math.cos(selectedPlane.angle * (Math.PI / 180)); // zmiana wysokośc w zależności od kąta nachylenia

            p0 = mousePointPosition as IBasicPoint;
            p1 = rotatePoint({ x: p0.x, y: p0.y - height }, angle, p0); // Punkt po lewej górze
            p2 = rotatePoint({ x: p0.x - width, y: p0.y - height }, angle, p0); // Punkt po prawej górze
            p3 = rotatePoint({ x: p0.x - width, y: p0.y }, angle, p0); // Punkt po prawej dole
          }

          const holePointsOnPlane: IBasicPoint[] = [p0, p1, p2, p3];

          for (const p of holePointsOnPlane) {
            const point: IRSOPoint = {
              id: uuidv4(),
              calcId: 0,
              type: 1,
              status: 2,
              x: p.x,
              y: p.y,
              z: 0,
            };
            points.push(point);
          }

          // 2. Przygotować krawędzie o typie empty
          const edges: IRSOEdge[] = [];

          for (let i = 0; i < points.length; i++) {
            const startPoint = points[i];
            const endPoint = i === points.length - 1 ? points[0] : points[i + 1];

            const startPointId = points.find((p) => p.x === startPoint.x && p.y === startPoint.y)?.id;
            const endPointId = points.find((p) => p.x === endPoint.x && p.y === endPoint.y)?.id;

            if (startPointId && endPointId) {
              const existedEdge = rsoEdges.find(
                (e) =>
                  (e.startPointId === startPointId && e.endPointId === endPointId) ||
                  (e.startPointId === endPointId && e.endPointId === startPointId)
              );

              if (!existedEdge) {
                const edge: IRSOEdge = {
                  id: uuidv4(),
                  calcId: 0,
                  startPoint: { x: 0, y: 0 },
                  endPoint: { x: 0, y: 0 },
                  type: EdgeType.Window,
                  status: 2,
                  startPointId: startPointId,
                  endPointId: endPointId,
                  startPointCalcId: 0,
                  endPointCalcId: 0,
                  active: true,
                  hover: false,
                };
                edges.push(edge);
              } else {
                edges.push(existedEdge);
              }
            } else {
              // console.log("Błąd w punktach");
            }
          }

          const hole: IRSOHole = {
            id: uuidv4(),
            calcId: 0,
            pointIds: points.map((p) => p.id),
            pointCalcIds: points.map((p) => p.calcId),
            rsoPlaneId: selectedPlane.id as string,
            type: 2, // Typ otworu 1 - otwór pod lukarna, 2 - otwór pod okno, 3 - otwór pod komin
            active: true,
            hover: false,
            area: 0,
            points: [],
            edges: [],
            edgeIds: edges.map((e) => e.id),
          };

          // Dodanie wszystkich punktów, krawędzi i otwory do redux
          dispatch(addRSOPoints(points));
          dispatch(addRSOEdges(edges));
          dispatch(addRSOHole(hole));
        }
      }
    }
    close();
  };

  return (
    <Group>
      {mouseOverPlane && (
        <Line
          listening={false}
          points={holePointsOnPlane.flatMap((p) => [p.x, p.y])}
          closed={true}
          stroke="black"
          fill="#d0fabd"
          strokeWidth={0.5}
        />
      )}
    </Group>
  );
};

export default FunctionAddHoleHandler;
