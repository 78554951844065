import { IProductToReport, IResultItemResponse, IRootState } from "../../../models/Models";
import store from "../../../redux/store";

// Funkcja pobierająca cenę za 1 produkt
export const getItemNettPrice = (id: string) => {
  const calcResult = store.getState().calcResult;
  const item = calcResult.data?.find((x) => x.id === id);

  return item?.price as number;
};

// Funkcja pobierająca cenę za 1 produkt ze wszystkimi zniżkami
export const getItemNettPriceWithAllDiscount = (id: string) => {
  const calcResult = store.getState().calcResult;
  const item = calcResult.data?.find((x) => x.id === id);

  return getNettValueWithAllDiscount(id) / (item?.amount as number);
};

// Funkcja pobierająca aktualny VAT
export const getVAT = () => {
  const calcResult = store.getState().calcResult;

  return calcResult.vat;
};

// Funkcja pobierająca wartość netto wybranego produktu bez rabatów
export const getNettValue = (id: string) => {
  const calcResult = store.getState().calcResult;
  const item = calcResult.data?.find((x) => x.id === id);

  return (item?.amount as number) * (item?.price as number);
};

// Funkcja pobierająca wartość netto wybranego produktu po rabacie na produkt
export const getNettValueWithPoductDiscount = (id: string) => {
  const calcResult = store.getState().calcResult;
  const item = calcResult.data?.find((x) => x.id === id);

  if (item) {
    let value = item.amount * item.price; // Zapisanie podstawowej wartości netto dla pozycji

    if (item.discount > 0) {
      if (item.discountType === "%") {
        // Jeśli rabat jest % (nie ma znaczenia czy dla netto czy dla brutto)
        const eff_0 = item.discount / 100;
        const eff = 1 - eff_0;
        value = value * eff;
      } else {
        // Jeśli rabat jest kwotowy
        if (item.discountPart === "nett") {
          // Jesli kwotowy na netto
          const discountInValue = item.discount; // wartość zniżki w kwocie netto
          value = value - discountInValue; // zmniejszona wartość pozycji o zniżkę kwotową
        } else {
          // Jeśli kwotowy na brutto
          const vat = getVAT();
          const vat_eff_0 = vat / 100;
          const vat_eff = 1 + vat_eff_0;
          const discountInValue = item.discount; // wartość zniżki w kwocie brutto
          const grossValue = value * vat_eff; // Wartość brutto pozycji (tu jest bez zniżki)
          const grossValueWithDiscount = grossValue - discountInValue; // Wartość brutto po uwzględnieniu rabatu

          // Teraz należy zamienić kwotę brutto na netto i mamy cenę netto po rabacie brutto
          value = grossValueWithDiscount / vat_eff;
        }
      }
    }

    return value;
  }

  return undefined;
};

// Funkcja pobierająca wartość netto wybranego produktu po rabacie na produkt
export const getGrossValueWithPoductDiscount = (id: string) => {
  const vat = getVAT();
  const vat_eff_0 = vat / 100;
  const vat_eff = 1 + vat_eff_0;

  return (getNettValueWithPoductDiscount(id) as number) * vat_eff;
};

// Funkcja pobierająca wartość netto wybranego produktu po wszystkich rabatach (na produkty i na ofertę)
// productId - id produktu do obliczeń
export const getNettValueWithAllDiscount = (id: string) => {
  const calcResult = store.getState().calcResult;
  const item = calcResult.data?.find((x) => x.id === id);

  if (item) {
    let value = item.amount * item.price; // Zapisanie podstawowej wartości netto dla pozycji
    if (item.discount > 0) {
      value = getNettValueWithPoductDiscount(item.id) as number;
    }

    if (calcResult.offerDiscount > 0) {
      // dodanie rabatu dodatkowego. Na tą potrzebę został przygotowany zsumowana wartośc netto wszystkich produktów po rabacie produktowym
      // tu podział na % i kwotę oraz na netto i brutto rabat

      if (calcResult.offerDiscountType === "%") {
        // Gdy rabat %. Nie ma znaczenia netto czy brutto
        const eff_0 = calcResult.offerDiscount / 100;
        const eff = 1 - eff_0;

        value = value * eff;
      } else {
        // Gdy rabat kwotowy

        // Łączna wartość netto wszytskich produktów po rabatach na produkt
        let sumNettValueWithProductDiscount = 0; // netto całość z rabatami produktowymi
        if (calcResult.data) {
          for (const item of calcResult.data.filter((x) => x.active)) {
            sumNettValueWithProductDiscount += getNettValueWithPoductDiscount(item.id) as number;
          }
        }

        const allPercent =
          ((getNettValueWithPoductDiscount(item.id) as number) * 100) / sumNettValueWithProductDiscount; // Ile % stanowi produkt całości netto
        const eff = allPercent / 100;

        if (calcResult.offerDiscountPart === "nett") {
          // Gdy rabat kwotowy w netto
          const discountForItem = calcResult.offerDiscount * eff;
          value = value - discountForItem;
        } else {
          // Gdy rabat kwotowy w brutto
          const vat = getVAT();
          const eff_0 = vat / 100;
          const vat_eff = 1 + eff_0;

          const offerDiscount = calcResult.offerDiscount / vat_eff;
          const discountForItem = offerDiscount * eff;
          value = value - discountForItem;
        }
      }
    }

    return value;
  }

  return 0;
};

// Funkcja pobierająca wartość brutto wybranego produktu bez rabatów
export const getGrossValue = (id: string) => {
  const vat = getVAT();
  const vat_eff_0 = vat / 100;
  const vat_eff = 1 + vat_eff_0;

  return getNettValue(id) * vat_eff;
};

// Funkcja pobierająca wartość brutto wybranego produktu z wszystkimi rabatami
export const getGrossValueWithAllDiscount = (id: string) => {
  const vat = getVAT();
  const vat_eff_0 = vat / 100;
  const vat_eff = 1 + vat_eff_0;

  return getNettValueWithAllDiscount(id) * vat_eff;
};

// Funkcja pobierająca wartość vat już pow szytskich rabatach
export const getVatValueFinal = (id: string) => {
  return getGrossValueWithAllDiscount(id) - getNettValueWithAllDiscount(id);
};

// Funkcja pobierająca wartość netto bez zniżek dla wszystkich produktów
export const getNettValueSum = () => {
  const calcResult = store.getState().calcResult;

  // Łączna wartość netto wszytskich produktów po rabatach na produkt
  let sumNettValueWithProductDiscount = 0;
  if (calcResult.data) {
    for (const item of calcResult.data.filter((x) => x.active)) {
      sumNettValueWithProductDiscount += getNettValue(item.id) as number;
    }
  }

  return sumNettValueWithProductDiscount;
};

// Funkcja pobierająca wartość netto bez zniżek dla wszystkich produktów
export const getNettValueSumWithAllDiscount = () => {
  const calcResult = store.getState().calcResult;

  // Łączna wartość netto wszytskich produktów po rabatach na produkt
  let sumNettValueWithProductDiscount = 0;
  if (calcResult.data) {
    for (const item of calcResult.data.filter((x) => x.active)) {
      sumNettValueWithProductDiscount += getNettValueWithAllDiscount(item.id) as number;
    }
  }

  return sumNettValueWithProductDiscount;
};

// Funkcja pobierająca wartość brutto bez zniżek dla wszystkich produktów
export const getGrossValueSum = () => {
  const vat = getVAT();
  const vat_eff_0 = vat / 100;
  const vat_eff = 1 + vat_eff_0;

  const nett = getNettValueSum();

  return nett * vat_eff;
};

// Funkcja pobierająca wartość brutto bez zniżek dla wszystkich produktów
export const getGrossValueSumWithAllDiscount = () => {
  const vat = getVAT();
  const vat_eff_0 = vat / 100;
  const vat_eff = 1 + vat_eff_0;

  const nett = getNettValueSumWithAllDiscount();

  return nett * vat_eff;
};

// Funkcja pobierająca listę wszystkich produktów z wszystkimi rabatami (z cenami ostatecznymi)
export const getProductsWithDiscount = (elements: IResultItemResponse[]) => {
  const finalProducts: IProductToReport[] = [];

  if (store.getState() != null) {
    if (elements !== null) {
      for (const item of elements.filter((x) => x.active)) {
        const reportProduct: IProductToReport = {
          productId: item.productId,
          name: item.name,
          amount: item.amount,
          unit: item.unit,
          price: Number(getNettValueWithAllDiscount(item.id).toFixed(2)),
          unitPrice: Number(getItemNettPriceWithAllDiscount(item.id).toFixed(2)),
        };

        finalProducts.push(reportProduct);
      }
    }
  }

  return finalProducts;
};
