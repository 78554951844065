import { Form, Input, InputNumber, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from "react";
import styled from "styled-components";
import { IPrincingSystemElement } from "../../../models/Models";
import { useAppDispatch } from "../../../redux/hooks";

export interface RefObject {
  getFormData: () => any;
}

type Props = {
  princingSystemElement?: IPrincingSystemElement | null;
};

const PrincingPosition: ForwardRefRenderFunction<RefObject, Props> = (props, ref) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const { princingSystemElement } = props;

  useImperativeHandle(ref, () => ({
    getFormData: () => form.getFieldsValue(),
  }));

  const getInitialValues = () => {
    if (princingSystemElement) {
      return {
        name: princingSystemElement.name,
        relation: princingSystemElement.relation,
        price: princingSystemElement.price,
      };
    }
    return { name: "", relation: "PLANE", price: 0 };
  };

  return (
    <Container>
      <Form
        id="addPrincingSystemElementForm"
        style={{ width: "100%" }}
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={getInitialValues()}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Nazwa pozycji" name="name">
          <Input />
        </Form.Item>

        <Form.Item label="Relacja" name="relation">
          <Select
            options={[
              { value: "EXTRA", label: "Procent sumarycznej ceny projektu [%]" },
              { value: "ADDITIONALS", label: "Procent ceny elementów dodatkowych [%]" },
              { value: "PLANE", label: "Powierzchnia połaci [m2]" },
              { value: "H-RIDGE-EDGE", label: "Krawędź kalenicy poziomej [mb]" },
              { value: "D-RIDGE-EDGE", label: "Krawędź kalenicy skośnej [mb]" },
              { value: "VALLEY-EDGE", label: "Krawędź koszowa [mb]" },
              { value: "R-GABLE-EDGE", label: "Krawędź szczytowa prawa [mb]" },
              { value: "L-GABLE-EDGE", label: "Krawędź szczytowa lewa [mb]" },
              { value: "ROOF-WALL-EDGE", label: "Krawędź dach-ściana [mb]" },
              { value: "WINDOW-EDGE", label: "Krawędź okna dachowego [mb]" },
              { value: "CHIMNEY-EDGE", label: "Krawędź komina [mb]" },
              { value: "EAVE-EDGE", label: "Krawędź okapu [mb]" },
              // { value: "2-RIDGE", label: "Punkt łączący 2 kalenice [szt.]" },
              // { value: "3-RIDGE", label: "Punkt łączący 3 kalenice [szt.]" },
              // { value: "4-RIDGE", label: "Punkt łączący 4 kalenice [szt.]" },
              // { value: "POINT-GABLES", label: "Punkt łączący 2 krawędzie szczytowe [szt.]" },
              { value: "WINDOW", label: "Okno dachowe [szt.]" },
              { value: "GUTTER-DRAIN-POINT", label: "Punkt z rurą spustową [szt.]" },
            ]}
          />
        </Form.Item>

        <Form.Item label="Cena lub % dla sumarycznej ceny projektu lub elementów dodatkowych" name="price">
          <InputNumber />
        </Form.Item>
      </Form>
    </Container>
  );
};
export default React.forwardRef(PrincingPosition);

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
`;
