import { Button, Card, Checkbox, Divider, Form, Image, InputNumber, notification, Select, Tag } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { getPrincingResult } from "../api/ApiContractors";
import { getDataForPrincing } from "../helpers/DomaFunctions";
import {
  IAddProduct,
  IContractor,
  IPrincingResponseModel,
  IResultItemResponse,
  IRootState,
} from "../models/Models";
import {
  getGrossValueSum,
  getGrossValueSumWithAllDiscount,
  getNettValueSum,
  getNettValueSumWithAllDiscount,
  getVAT,
} from "../modules/roofs/managers/DiscountManager";
import {
  addCalcResultItem,
  addProductToOrder,
  deleteLaboursCalcResultItem,
  updateVATResultItem,
} from "../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import AdditionalDiscountModal from "./AdditionalDiscountModal";
import { v4 as uuidv4 } from "uuid";
import store from "../redux/store";
import { updateCalcMaterialsData } from "../redux/calculationProcess/calcMaterialsDataSlice";
import { createFilterOption } from "../helpers/Helpers";
import { PRIMARY_COLOR, TEXT_COLOR } from "../theme";

type Props = {};

const OfferPrice: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const calcResult = useAppSelector((state: IRootState) => state.calcResult);

  const [additionalDiscountModal, setAdditionalDiscountModal] = useState<boolean>(false);

  const [discount, setDiscount] = useState<number>(0);

  const [api, contextHolder] = notification.useNotification();

  const contractor = useAppSelector((state) => state.contractor);
  const contractors = useAppSelector((state) => state.parentContractors);
  const profile = useAppSelector((state) => state.profile);
  const princingSystems = useAppSelector((state) => state.princingSystems);
  const externalPrincingSystems = useAppSelector((state) => state.externalPrincingSystems);
  const isContractor = () => {
    return profile.role === "contractor";
  };

  // console.log("COMMENT");
  useEffect(() => {
    // Policzyć jaki to jest rabat w % z wszystkich cen
    const basic = getNettValueSum();
    const withDiscount = getNettValueSumWithAllDiscount();
    const percents = (withDiscount * 100) / basic;
    const discountInPercents = Number((100 - percents).toFixed(2));

    setDiscount(discountInPercents);
  }, [calcResult]);

  const getBasicNettValue = () => {
    return getNettValueSum().toFixed(2);
  };

  const getBasicGrossValue = () => {
    return getGrossValueSum().toFixed(2);
  };

  const getBasicVATValue = () => {
    return Number((getGrossValueSum() - getNettValueSum()).toFixed(2));
  };

  const getFinalNettValue = () => {
    return getNettValueSumWithAllDiscount().toFixed(2);
  };

  const getFinalGrossValue = () => {
    return getGrossValueSumWithAllDiscount().toFixed(2);
  };

  const getFinalVATValue = () => {
    return Number((getGrossValueSumWithAllDiscount() - getNettValueSumWithAllDiscount()).toFixed(2));
  };

  const getDataForSelectContractorSystem = (contractors: IContractor[]) => {
    const result = [];

    if (princingSystems) {
      for (const ps of princingSystems) {
        result.push({
          id: ps.id,
          value: ps.id,
          // label: `[${contractor.name}] ${ps.name}`,
          label: `[Mój system] ${ps.name}`,
        });
      }
    }

    if (externalPrincingSystems) {
      for (const ps of externalPrincingSystems) {
        result.push({
          id: ps.id,
          value: ps.id,
          label: `[${ps.owner}] ${ps.name}`,
        });
      }
    }

    return result;
  };

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const priceUnits = useAppSelector((state: IRootState) => state.priceUnits);

  const calcMaterialsData = useAppSelector((state: IRootState) => state.calcMaterialsData);

  const getSumPrincing = (model: IPrincingResponseModel) => {
    return model.elements.reduce((sum, obj) => sum + obj.price, 0);
  };

  const onSelectPrincingSystemToCalculate = (value: any) => {
    const model = getDataForPrincing(value, store.getState());
    // console.log("DATA FOR PRINCING", model);

    dispatch(deleteLaboursCalcResultItem());

    if (model.princingSystemId) {
      dispatch(
        getPrincingResult(model, (data: any) => {
          for (const element of data.elements) {
            let model: IAddProduct = {
              name: element.name,
              ean: "",
              active: true,
              coating: "",
              effectiveWidth: 0,
              lowerZipperLength: 0,
              upperZipperLength: 0,
              material: "",
              maxLength: 0,
              minLength: 0,
              moduleCount: 0,
              moduleLength: 0,
              producerId: "",
              shelfLife: "",
              warranty: "",
              storageTemperature: 0,
              companyId: undefined,
              externalId: "",
              productCategoryId: productCategories.find((x) => x.code === "labour")?.id as string,
              productColorId: productColors.find((x) => x.name === "Brak")?.id as string,
              productDiscount: 0,
              stockQuantity: 0,
              width: 0,
              length: 0,
              weight: 0,
              capacity: 0,
              diameter: 0,
              height: 0,
              productModelId: "",
              salesForms: [],
              use: 0,
              calculationUnit: "szt.",
            };

            // WAŻNE ->
            // Tu trzeba dodac listę produktów
            // Czy mamy funkcj, która doda kilka produktów na raz? w API
            // Teraz dodajemy wszystko w pętli
            dispatch(addProductToOrder(model, 1));

            dispatch(updateCalcMaterialsData({ ...calcMaterialsData, contractorId: value }));
          }
        })
      );
    }
  };

  return (
    <Container bodyStyle={{ width: "100%" }}>
      {contextHolder}

      {additionalDiscountModal && (
        <AdditionalDiscountModal
          open={additionalDiscountModal}
          onCancel={() => {
            setAdditionalDiscountModal(false);
          }}
          onOk={() => setAdditionalDiscountModal(false)}
          nettValue={1000}
          grossValue={1230}
          calcResult={calcResult}
        />
      )}

      <Top>
        <Side></Side>
        <Title>Cena oferty dachu</Title>
        <Side>
          <Button onClick={() => setAdditionalDiscountModal(true)}>Dodatkowy rabat</Button>
        </Side>
      </Top>

      <DVATContainer style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={calcResult.vat === 8}
          onChange={(e) => {
            dispatch(updateVATResultItem(e.target.checked ? 8 : 23));

            // TODO gdy false i istnieją już w tabeli dane robicizny to należy je usunąć
            const laboursInState = calcResult.data?.filter((o) => o.categoryCode === "labour");
            if (!e.target.checked && calcResult && laboursInState) {
              dispatch(deleteLaboursCalcResultItem()); // Czyszczenie z tabeli wszystkiego co związane z robocizną
            } else if (e.target.checked && calcMaterialsData.contractorId) {
              onSelectPrincingSystemToCalculate(calcMaterialsData.contractorId); // Wywołaj tą samą funkcję jakby się wybierało z listy
            }
          }}
        >
          Kompleksowa usługa z montażem dachu (VAT 8%)
        </Checkbox>

        {getVAT() === 8 && (
          <div style={{ display: "flex", gap: "16px", alignItems: "center", fontWeight: "bold" }}>
            <div>→ Wybierz system do wyceny robocizny</div>

            <Select
              disabled
              style={{ width: "500px" }}
              value={calcMaterialsData.contractorId}
              placeholder="Wybierz system wyceny"
              onChange={(value) => onSelectPrincingSystemToCalculate(value)}
              options={getDataForSelectContractorSystem(contractors)}
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </div>
        )}
      </DVATContainer>

      <PriceContainer>
        <DTable>
          <DRow>
            <DColumn style={{ width: "250px", justifyContent: "left" }}></DColumn>

            <div style={{ display: "flex", width: "100%" }}>
              <DColumn style={{ width: "100%" }}>Netto</DColumn>
              <DColumn style={{ width: "100%" }}>
                <Tag>
                  <b>VAT {getVAT()}%</b>
                </Tag>
              </DColumn>
              <DColumn style={{ width: "100%" }}>Brutto</DColumn>
            </div>
          </DRow>

          <Divider style={{ margin: "4px 0px" }} />

          <DRow>
            <DColumn style={{ width: "250px", justifyContent: "left" }}>Cena bazowa</DColumn>
            <div style={{ display: "flex", width: "100%" }}>
              <DColumn style={{ width: "100%" }}>{getBasicNettValue()} zł</DColumn>
              <DColumn style={{ width: "100%" }}>{getBasicVATValue()} zł</DColumn>
              <DColumn style={{ width: "100%" }}>{getBasicGrossValue()} zł</DColumn>
            </div>
          </DRow>
          <DRow style={{ fontWeight: "bold", color: PRIMARY_COLOR, fontSize: "18px", padding: "8px 0px" }}>
            <DColumn
              style={{
                width: "250px",
                display: "block",
              }}
            >
              <span>Cena końcowa</span>
              {discount > 0 && (
                <Tag>
                  <b>Rabat {discount}%</b>
                </Tag>
              )}
            </DColumn>
            <div style={{ display: "flex", width: "100%" }}>
              <DColumn style={{ width: "100%" }}>{getFinalNettValue()} zł</DColumn>
              <DColumn style={{ width: "100%" }}>{getFinalVATValue()} zł</DColumn>
              <DColumn style={{ width: "100%" }}>{getFinalGrossValue()} zł</DColumn>
            </div>
          </DRow>

          <Divider style={{ margin: "8px 0px" }} />

          <DRow style={{ color: "#e81717", fontSize: "16px" }}>
            <DColumn style={{ width: "250px", justifyContent: "left" }}>Oszczędzasz</DColumn>
            <div style={{ display: "flex", width: "100%" }}>
              <DColumn style={{ width: "100%" }}>
                {(getNettValueSum() - getNettValueSumWithAllDiscount()).toFixed(2)} zł
              </DColumn>
              <DColumn style={{ width: "100%" }}>
                {(
                  getGrossValueSum() -
                  getNettValueSum() -
                  (getGrossValueSumWithAllDiscount() - getNettValueSumWithAllDiscount())
                ).toFixed(2)}
                zł
              </DColumn>
              <DColumn style={{ width: "100%" }}>
                {(getGrossValueSum() - getGrossValueSumWithAllDiscount()).toFixed(2)} zł
              </DColumn>
            </div>
          </DRow>
        </DTable>
      </PriceContainer>
    </Container>
  );
};
export default OfferPrice;

const Container = styled(Card)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
`;
const Side = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00000072;
  font-size: 14px;
`;

const DVATContainer = styled.div`
  margin-top: 16px;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  width: 100%;
  margin-top: 16px;
`;

const DTable = styled.div`
  width: 100%;
  /* border: 1px solid #000000; */
`;

const DRow = styled.div`
  display: flex;
`;

const DColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #000000; */
  padding: 4px;
`;
