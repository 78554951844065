import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Card, Form, Input, Table, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { IRootState } from "../../../models/Models";
import { IProductsGroup } from "../../../redux/productsGroups/productsGroupsSlice";
import { debounce } from "lodash";

const columns = [
  {
    title: "Nazwa systemu",
    dataIndex: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "Akcja",
    dataIndex: "action",
    align: "center" as const,
    width: 100,
  },
];

const ProductsGroupsPage: FC = () => {
  const navigate = useNavigate();

  const productsGroups = useAppSelector((state: IRootState) => state.productsGroups);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IProductsGroup[]>([]);

  // WYSZUKIWARKA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // Tworzymy debounced wersję funkcji search
  const debouncedSearch = useCallback(
    debounce((text) => {
      search(text);
    }, 500),
    []
  );

  // Efekt, który obserwuje zmiany searchTerm i wywołuje debouncedSearch
  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch, productsGroups]);

  const search = (text: string) => {
    let result: IProductsGroup[] = [];

    if (text.length > 0) {
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      result = productsGroups.filter((system) => {
        const systemName = system.name.toLowerCase();

        return inputWords.every((word) => systemName.includes(word));
      });

      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const getSystemsData = (groups: IProductsGroup[]) => {
    const data: {
      key: string;
      name: string;
      description: string;
      action: JSX.Element;
    }[] = [];

    if (groups) {
      const sortedSystems = [...groups].sort((a, b) => a.name.localeCompare(b.name));
      for (const s of sortedSystems) {
        data.push({
          key: s.id,
          name: s.name,
          description: s.description,
          action: <Button onClick={() => navigate(`/grupy-produktowe/${s.id}/edytuj`)}>Edytuj</Button>,
        });
      }
    }

    return data;
  };

  const getCount = (elements: IProductsGroup[]) => {
    return elements ? elements.length : 0;
  };

  return (
    <Container
      title={
        <div>
          Grupy produktowe{" "}
          <Tag style={{ padding: "4px 8px 4px 8px" }}>
            {getCount(searchResults.length > 0 ? searchResults : productsGroups)} szt.
          </Tag>
        </div>
      }
      extra={
        <Button type="primary" onClick={() => navigate(`/grupy-produktowe/dodaj`)}>
          Dodaj grupę produktową
        </Button>
      }
    >
      <div style={{ marginBottom: "16px" }}>
        <Form.Item label="Znajdź grupę produktową" labelCol={{ span: 24 }} style={{ marginBottom: "32px" }}>
          <SearchInput
            value={searchTerm}
            placeholder="Wpisz nazwę grupy produktowej..."
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
          />
        </Form.Item>

        {searchTerm.length > 0 && searchResults.length === 0 && (
          <Alert
            showIcon
            type="error"
            message="Brak grup spełniających kryteria wyszukiwania!"
            style={{ marginTop: "16px" }}
          />
        )}
      </div>

      <Table
        columns={columns}
        dataSource={getSystemsData(searchResults.length > 0 ? searchResults : productsGroups)}
        pagination={{ defaultPageSize: 10 }}
      />
    </Container>
  );
};

export default ProductsGroupsPage;

const Container = styled(Card)``;

const SearchInput = styled(Input)``;
