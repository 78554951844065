import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IResultItemResponse } from "../../models/Models";

// Interfejs dla Dodatkowego Zapisanego Produktu - te same pola co IResultItemResponse - jest to pozycja w Podsumowaniu produktu
export interface ISavedAdditionalProduct extends IResultItemResponse {}

// Początkowy stan - pusta lista zapisanych dodatkowych produktów
const initialState: ISavedAdditionalProduct[] = [];

// Konfiguracja stanu aplikacji
export const savedAdditionalProductsSlice = createSlice({
  name: "savedAdditionalProducts",
  initialState,
  reducers: {
    // Ustawia listę zapisanych dodatkowych produktów
    setSavedAdditionalProductsReducer: (state, action: PayloadAction<ISavedAdditionalProduct[]>) => {
      return action.payload; // Zwraca nową listę produktów
    },
    // Dodaje zapisany dodatkowy produkt
    addSavedAdditionalProductReducer: (state, action: PayloadAction<ISavedAdditionalProduct>) => {
      state.push(action.payload);
    },
    // Aktualizuje id zapisanego dodatkowego produktu - zastępuje wpis
    updateSavedAdditionalProductReducer: (state, action: PayloadAction<ISavedAdditionalProduct>) => {
      const product = action.payload;
      const index = state.findIndex((p) => p.id === product.id);

      if (index !== -1) {
        state[index] = product; // Aktualizuje produkt, jeśli znaleziony
      } else {
        console.warn("Nie znaleziono produktu o id: ", product.id);
      }
    },
    // Usuwa zapisany dodatkowy produkt na podstawie ID
    deleteSavedAdditionalProductReducer: (state, action: PayloadAction<string>) => {
      const productId = action.payload;
      const index = state.findIndex((p) => p.id === productId);

      if (index !== -1) {
        state.splice(index, 1); // Usuwa produkt, jeśli znaleziony
      } else {
        console.warn("Nie znaleziono produktu o id: ", productId);
      }
    },
    // Czyści listę zapisanych dodatkowych produktów, przywracając stan początkowy
    clearSavedAdditionalProductsReducer: () => {
      return initialState; // Zwraca stan początkowy (pusta lista)
    },
  },
});

// Eksportuje akcje reduktora
export const {
  setSavedAdditionalProductsReducer,
  addSavedAdditionalProductReducer,
  updateSavedAdditionalProductReducer,
  deleteSavedAdditionalProductReducer,
  clearSavedAdditionalProductsReducer,
} = savedAdditionalProductsSlice.actions;

// Eksportuje reduktor jako domyślny eksport
export default savedAdditionalProductsSlice.reducer;
