import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = 75;

export const drawingScaleSlice = createSlice({
  name: "drawingScale",
  initialState,
  reducers: {
    updateDrawingScale: (state, action) => {
      return action.payload;
    },
    clearDrawingScale: (state) => {
      return initialState;
    },
    setSavedDrawingScale: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { updateDrawingScale, clearDrawingScale, setSavedDrawingScale } = drawingScaleSlice.actions;
export default drawingScaleSlice.reducer;
