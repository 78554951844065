import React, { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { Button, Card, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../layout/components/PageHeader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import ProductDetails from "./components/ProductDetails";
import { IProduct, IRootState, ITileSystemAddition } from "../../models/Models";
import { deleteProduct } from "../../redux/general/productsSlice";
import { useDispatch } from "react-redux";
import { deleteStatusApi } from "../../redux/other/statusApiSlice";

type Props = {};

const EditProduct: FC<Props> = () => {
  const { productId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const products = useAppSelector((state: IRootState) => state.products);
  const profile = useAppSelector((state) => state.profile);
  const tileSystems = useAppSelector((state) => state.tileSystems);

  const [product, setProduct] = useState<IProduct | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteProduct")
  );

  useEffect(() => {
    const p = products.find((x) => x.id === productId);
    if (p) {
      setProduct(p);
    }
  }, [productId]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteProduct"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  // Sprawdzanie czy można usunąć produkt
  // Warunek to nie może być użyte w żadnym zestawie dachowym
  const possibleToDelete = () => {
    const additionalElements: ITileSystemAddition[] = [];

    for (const ts of tileSystems) {
      additionalElements.push(...ts.tileSystemAdditions);
    }

    return ![...tileSystems, ...additionalElements].some((o) => Object.values(o).some((v) => v === productId));
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${product?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && product && (
              <Button
                danger
                onClick={() => dispatch(deleteProduct(product, () => navigate(-1)))}
                disabled={!possibleToDelete()}
              >
                Usuń
              </Button>
            )}

            {/* Gdy pobieranie nowych danych lub cen */}
            <Button onClick={() => null} disabled>
              Aktualizuj stan produktu
            </Button>

            <Button type="primary" form="productDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>

            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy produkt
            </Button>
          </div>
        }
      />

      <Content>{product && <ProductDetails product={product} manuallySubmitForm={manuallySubmitForm} />}</Content>
    </Container>
  );
};

export default EditProduct;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
