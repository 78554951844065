import { Alert, Button, Card, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../api/ApiClient";
import PageHeader from "../../../layout/components/PageHeader";
import { useAppSelector } from "../../../redux/hooks";

const columns = [
  {
    title: "Nazwa sklepu",
    dataIndex: "name",
    key: "name",
  },
];

export default function ConfigurationContractor() {
  const [generatedInvitation, setGeneratedInvitation] = useState<any>(null);

  const contractor = useAppSelector((state) => state.contractor);

  const [relatedParents, setRelatedParents] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get("/Contractor/getRelatedParents");
        if (response.status === 200) {
          // console.log(response.data);
          setRelatedParents(response.data);
        }
      } catch (err) {}
    };

    fetchData();
  }, []);

  const handleGenerateCodeClick = async () => {
    try {
      const model = {
        contractorId: contractor.id,
      };

      const response = await client.post("/Contractor/generateInvitation", model);
      if (response.status === 200) {
        setGeneratedInvitation(response.data);
      }
    } catch (err) {}
  };

  return (
    <Container>
      <PageHeader title={"Konfiguracja panelu wykonawcy"} />

      <Section title={"Wygeneruj kod zaproszeniowy dla zewnętrznego sklepu/hurtowni"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Alert
            message="Potrzebne gdy chcesz aby ten użytkownik był wykonawcą i został powiązany z zewnętrznym sklepem/hurtownią!"
            type="warning"
            showIcon
          />
          <Alert message="Aby dodać wykonawcę należy uzyskać kod zaproszeniowy!" type="info" showIcon />
          <Button style={{ width: "100%" }} type="primary" onClick={() => handleGenerateCodeClick()}>
            Wygeneruj kod
          </Button>
          {generatedInvitation && (
            <div>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "32px" }}>
                Twój wygenerowany kod zaproszeniowy
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                {generatedInvitation.code}
              </div>
            </div>
          )}
        </div>
      </Section>

      <Section title={"Lista przypisanych zewnętrznych sklepów/hurtowni"}>
        <Table dataSource={relatedParents} columns={columns} rowKey="id" pagination={{ defaultPageSize: 10 }} />
      </Section>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
