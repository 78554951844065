import React, { FC, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../redux/actions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { clearData } from "../api/ApiRoof";
import { startNewRoofProcess } from "../redux/drawing/roofProcessFunctionsStatusSlice";
import { FundProjectionScreenOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { DraggableData, DraggableEvent } from "react-draggable";

type Props = {
  isAdmin: () => boolean;
  isModerator: () => boolean;
};

const Header: FC<Props> = ({ isAdmin, isModerator }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarStatus = useAppSelector((state) => state.sidebarStatus);

  const [confirmNewRoofModal, setConfirmNewRoofModal] = useState<boolean>(false); // State dla modala z potwierdzeniem nowego projektu

  return (
    <Container>
      {/* <LogoContent>
        <StyledLogoSVG />
      </LogoContent> */}

      {/* Modal z potwierdzeniem rysowania nowego dachu. */}
      {confirmNewRoofModal && (
        <Modal
          open={true}
          centered
          title="Nowy projekt"
          onCancel={() => setConfirmNewRoofModal(false)}
          footer={
            <div style={{ display: "flex", gap: "16px", justifyContent: "flex-end" }}>
              <Button
                key="back"
                onClick={() => {
                  setConfirmNewRoofModal(false);
                }}
              >
                Anuluj
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  // 1 czyszcenie danych
                  dispatch(clearData());

                  // 2 Nowy proces
                  dispatch(startNewRoofProcess());

                  // 3. Wyłączenie pomocniczego modala
                  setConfirmNewRoofModal(false);
                }}
              >
                Tak
              </Button>
            </div>
          }
        >
          Czy na pewno chcesz utworzyć nowy projekt? Wszystkie niezapisane dane zostaną utracone.
        </Modal>
      )}

      <Content>
        <ButtonsContainer>
          <Button
            type="primary"
            onClick={() => {
              if (location.pathname === "/dachy") {
                setConfirmNewRoofModal(true);
              } else {
                navigate("/dachy");
                setConfirmNewRoofModal(true);
              }
            }}
            style={{ marginLeft: sidebarStatus ? "150px" : "0px" }}
            icon={<FundProjectionScreenOutlined />}
          >
            Nowy projekt
          </Button>
        </ButtonsContainer>
        <ButtonsContainer>
          <Button
            type="default"
            onClick={() =>
              window.open("https://forms.office.com/e/wWQffPf6N5?embed=true", "_blank", "noopener,noreferrer")
            }
          >
            Uwagi do systemów dachowych
          </Button>
          <Button
            type="default"
            onClick={() =>
              window.open(
                "https://forms.office.com/Pages/ResponsePage.aspx?id=E01d4hYZCUierv_9dNOwTkDWBOMQWlVAq4rI5DksrVhUQktLQkxTUDdDUTJHM1NQN1c2VTBKME84Si4u&embed=true",
                "_blank",
                "noopener,noreferrer"
              )
            }
            style={{ marginRight: "16px" }}
          >
            Uwagi ogólne
          </Button>

          {/* {(isAdmin() || isModerator()) && <Button onClick={() => navigate("oferty")}>Oferty</Button>} */}
          <Button onClick={() => navigate("profil")}>
            <UserOutlined />
          </Button>
          <Button
            onClick={() => {
              dispatch(logOut());
            }}
          >
            <LogoutOutlined />
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
export default Header;

const Container = styled.div`
  height: 50px;
  display: flex;
  background: #ffffff;
`;

const Content = styled.div`
  flex: 1;
  height: 100%;
  /* margin-left: 200px; */
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
