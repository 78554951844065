import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Circle, Group } from "react-konva";
import { IPoint } from "../../../../../models/Models";
import { useAppSelector } from "../../../../../redux/hooks";
import { useKeyPress } from "react-use";
import { getNearestPoint } from "../../../../../helpers/Helpers";

// CONSTANTS
const DIST = 10;

type Props = {
  scale: number;
  gridPoints: IPoint[];
};

const MouseCross: FC<Props> = ({ scale, gridPoints }) => {
  const roofContourNumber = useAppSelector((state) => state.roofContourNumber);
  const outlinePoints =
    useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber.index)?.points) || [];
  const mousePosition = useAppSelector((state) => state.mousePosition);
  const [mp, setMp] = useState<IPoint>();
  const [isOrtogonal, setIsOrtogonal] = useState<boolean>(false);

  const ctrlPress = useKeyPress("Control");

  const gridSettings = useAppSelector((state) => state.domaGrid);
  const isGridSnapping = gridSettings && gridSettings.gridVisible && gridSettings.gridSnapping ? true : false;

  useEffect(() => {
    const dist = ctrlPress[0] ? 0 : DIST;
    const value = getNearestPoint(mousePosition, outlinePoints, dist, gridPoints, isGridSnapping);
    setMp(value.point);
    setIsOrtogonal(value.ortogonal as boolean);
  }, [mousePosition]);

  return (
    <Container>
      {mp && (
        <Group>
          <Line
            points={[-99999, mp?.y, 99999, mp?.y]}
            stroke={isOrtogonal ? "#4447ff" : "gray"}
            strokeWidth={0.5 / scale}
          />
          <Line
            points={[mp?.x, -99999, mp?.x, 99999]}
            stroke={isOrtogonal ? "#4447ff" : "gray"}
            strokeWidth={0.5 / scale}
          />
          <Circle x={mp?.x} y={mp?.y} radius={4 / scale} fill="gray" />
        </Group>
      )}
    </Container>
  );
};

export default MouseCross;

const Container = styled(Group)``;
