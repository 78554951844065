import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { IAdditionalReportPage, IReportNote, IReportQuestion } from "../../modules/admin/CompanyPage";
import { AppThunk } from "../store";

export interface IParentSettings {
  id: string;
  name: string;
  reportType: string;
  showProductCountInBasicType: boolean;
  addCurrencyValueInNotes: boolean;
  logoUrl: string;
  prefix: string;
  reportFooterText: string;

  reportNotes: IReportNote[];
  reportQuestions: IReportQuestion[];
  additionalReportPages: IAdditionalReportPage[];
}

const initialState: IParentSettings = {
  id: "",
  name: "",
  reportType: "basic",
  showProductCountInBasicType: false,
  addCurrencyValueInNotes: false,
  logoUrl: "",
  prefix: "",
  reportFooterText: "",
  reportNotes: [],
  reportQuestions: [],
  additionalReportPages: [],
};

export const parentSettingsSlice = createSlice({
  name: "parentSettings",
  initialState,
  reducers: {
    addParentSettingsInReducer: (state, action: PayloadAction<IParentSettings>) => {
      let parentSettings = action.payload;
      return parentSettings;
    },
    updateParentSettingsInReducer: (state, action: PayloadAction<IParentSettings>) => {
      let update = action.payload;
      return { ...state, ...update };
    },
    clearParentSettingsInReducer: (state, action: PayloadAction<string>) => {
      return initialState;
    },
  },
});

export const { addParentSettingsInReducer, updateParentSettingsInReducer, clearParentSettingsInReducer } =
  parentSettingsSlice.actions;
export default parentSettingsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Update parent settings
export const updateParentSettings =
  (data: IParentSettings): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE PARENT SETTINGS", data);
    client
      .post(`/ParentSettings`, data)
      .then((response) => {
        if (response.status == 200) {
          //   console.log("UPDATE RESPONSE", response.data);
          dispatch(updateParentSettingsInReducer(response.data));

          message.open({
            type: "success",
            content: "Dane firmy zostały zaktualizowane!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
