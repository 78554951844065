import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { IGutterSystemAdditionType, ITileSystemAdditionType } from "../../models/Models";

const initialState: ITileSystemAdditionType[] = [];

export const tileSystemAdditionTypesSlice = createSlice({
  name: "tileSystemAdditionTypes",
  initialState,
  reducers: {
    setTileSystemAdditionTypessInReducer: (state, action: PayloadAction<ITileSystemAdditionType[]>) => {
      let gutterSystems = action.payload;
      return gutterSystems;
    },
  },
});

export const { setTileSystemAdditionTypessInReducer } = tileSystemAdditionTypesSlice.actions;
export default tileSystemAdditionTypesSlice.reducer;
