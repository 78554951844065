import Konva from "konva";
import React, { FC, useEffect, useRef, useState } from "react";
import { Group } from "react-konva";
import { useAppDispatch, useAppSelector } from "../../../../../../../redux/hooks";
import { IRSO, RSOPoint2D } from "../Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { IBasicPoint } from "../../../../../../../models/Models";
import {
  getChimneySweepBenchIcon,
  getRoofStepIcon,
  getRoofVentIcon,
  getSnowFenceIcon,
} from "../../../../geometryRoofAccessories/RoofAccessoryElement";
import { saveDrawing } from "../../../../../../../redux/roofs/roofDrawingStoreSlice";
import {
  addChimneySweepBench200,
  addChimneySweepBench300,
  addChimneySweepBench40,
  addChimneySweepBench80,
  addRoofStep,
  addRoofVent100,
  addRoofVent125,
  addRoofVent150,
  addSnowFence200,
  addSnowFence300,
} from "../../../../../../../redux/roofs/roofAccessoriesSlice";
import store from "../../../../../../../redux/store";
import {
  addGutterDrainPipe,
  addGutterEndLeft,
  addGutterEndRight,
  addGutterExternalCorner,
  addGutterInternalCorner,
} from "../../../../../../../redux/roofs/gutteringElementsSlice";
import {
  getGutterDrainPipeIcon,
  getGutterEndLeftIcon,
  getGutterEndRightIcon,
  getGutterExternalCornerIcon,
  getGutterInternalCornerIcon,
} from "../../../../geometryGuttering/GutteringElement";
import { invalidateCalculation } from "../../../../../../../redux/calculationProcess/calcResultSlice";

interface FunctionAddGutterElementHandlerProps {
  stageRef: React.RefObject<Konva.Stage>;
  point0Ref: React.RefObject<Konva.Circle>;
  typeElementState:
    | null
    | "gutterDrainPipe"
    | "gutterInternalCorner"
    | "gutterExternalCorner"
    | "gutterEndLeft"
    | "gutterEndRight";
  eventClickStage: any;
  scale: number;
  zoomScale: number;
  closeFunction: any;
  activeRoofPlane?: IRSO | null;
}

// Funckja do dodawania otwworu okiennego
const FunctionAddGutterElementHandler: FC<FunctionAddGutterElementHandlerProps> = ({
  stageRef,
  point0Ref,
  typeElementState,
  activeRoofPlane,
  scale,
  zoomScale,
}) => {
  const dispatch = useAppDispatch();

  const [mpLocal, setMpLocal] = React.useState<RSOPoint2D>({ x: 0, y: 0 }); // Położenie myszki w Stage analizowanie w tym komponencie (uwzglednia skalowanie do punktu 0,0)
  const mpLocalRef = useRef(mpLocal);

  const typeElementRef = useRef(typeElementState);
  const activeRoofPlaneRef = useRef(activeRoofPlane);

  const [accessoryPoint, setAccessoryPoint] = useState<IBasicPoint | null>(null);

  useEffect(() => {
    activeRoofPlaneRef.current = activeRoofPlane;
  }, [activeRoofPlane]);

  useEffect(() => {
    typeElementRef.current = typeElementState;
  }, [typeElementState]);

  // Odświeżanie referencji do mpLocal bo po wywołaniu setMpLacal zmienia się referencja do obiektu wewnętrznego
  useEffect(() => {
    mpLocalRef.current = mpLocal;
  }, [mpLocal]);

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const handleMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (e.evt.button === 0) {
        e.evt.preventDefault();
        const mousePosition = point0Ref?.current?.getRelativePointerPosition() || { x: 0, y: 0 };

        // Wstawienie elementu orynnowania w wybranym miejscu czyli dodanie gdzieś do listy w reduxie która póxniej jest wyświetlana
        if (typeElementRef.current === "gutterDrainPipe") {
          dispatch(addGutterDrainPipe({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
        } else if (typeElementRef.current === "gutterInternalCorner") {
          dispatch(addGutterInternalCorner({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
        } else if (typeElementRef.current === "gutterExternalCorner") {
          dispatch(addGutterExternalCorner({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
        } else if (typeElementRef.current === "gutterEndLeft") {
          dispatch(addGutterEndLeft({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
        } else if (typeElementRef.current === "gutterEndRight") {
          dispatch(addGutterEndRight({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
        }

        dispatch(invalidateCalculation());

        dispatch(saveDrawing(store.getState()));
      } else if (e.evt.button === 2) {
        // console.log("Kliknięto prawym przyciskiem myszy");
        e.evt.preventDefault();
      }
    };

    const handleMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
      const mousePointPosition = point0Ref?.current?.getRelativePointerPosition();
      let p = mousePointPosition as IBasicPoint;

      setAccessoryPoint(p);
    };

    stage.on("mousedown", handleMouseDown);
    stage.on("mousemove", handleMouseMove);

    return () => {
      stage.off("mousedown", handleMouseDown);
      stage.off("mousemove", handleMouseMove);
    };
  }, [stageRef]);

  return (
    <Group
      x={accessoryPoint ? accessoryPoint?.x : 0}
      y={accessoryPoint ? accessoryPoint?.y : 0}
      // scale={{ x: 1 / zoomScale, y: 1 / zoomScale }}
    >
      {typeElementRef.current === "gutterDrainPipe" && getGutterDrainPipeIcon(0, 0, zoomScale, false)}
      {typeElementRef.current === "gutterInternalCorner" && getGutterInternalCornerIcon(0, 0, zoomScale)}
      {typeElementRef.current === "gutterExternalCorner" && getGutterExternalCornerIcon(0, 0, zoomScale)}
      {typeElementRef.current === "gutterEndLeft" && getGutterEndLeftIcon(0, 0, zoomScale)}
      {typeElementRef.current === "gutterEndRight" && getGutterEndRightIcon(0, 0, zoomScale)}
    </Group>

    // <Group x={accessoryPoint ? accessoryPoint?.x : 0} y={accessoryPoint ? accessoryPoint?.y : 0}>
    //   {typeElementRef.current === "roofStep" && getRoofStepIcon(scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "chimneySweepBench40" &&
    //     getChimneySweepBenchIcon(0.4, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "chimneySweepBench80" &&
    //     getChimneySweepBenchIcon(0.8, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "chimneySweepBench200" &&
    //     getChimneySweepBenchIcon(2, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "chimneySweepBench300" &&
    //     getChimneySweepBenchIcon(3, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "snowFence200" && getSnowFenceIcon(2, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "snowFence300" && getSnowFenceIcon(3, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "roofVent100" && getRoofVentIcon(0.1, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "roofVent125" && getRoofVentIcon(0.125, scale, zoomScale, getRotationAngle())}
    //   {typeElementRef.current === "roofVent150" && getRoofVentIcon(0.15, scale, zoomScale, getRotationAngle())}
    // </Group>
  );
};

export default FunctionAddGutterElementHandler;
