import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { createFilterOption } from "../../../../../../helpers/Helpers";
import { useFirstRender } from "../../../../../../hooks/useFirstRender";
import { IProduct, IProductCategory, IRootState } from "../../../../../../models/Models";
import { IAdditionalProduct } from "../../../../../../redux/calculationProcess/additionalProductsSlice";

interface AdditionalProductPositionProps {
  addition?: IAdditionalProduct;
  products: IProduct[];
  productCategories: IProductCategory[];
  readyOptions?: any[];
  updateAddition: (addition: IAdditionalProduct) => void;
  deleteAddition: (addition: IAdditionalProduct) => void;
}

interface AdditionState {
  productId: string | null;
  amount: number;
}

const AdditionalProductPosition: React.FC<AdditionalProductPositionProps> = ({
  addition,
  products,
  productCategories,
  readyOptions,
  updateAddition,
  deleteAddition,
}) => {
  const firstRender = useFirstRender();

  const [additionState, setAdditionState] = useState<AdditionState>({
    productId: null,
    amount: 0,
  });

  useEffect(() => {
    setAdditionState({
      productId: addition?.productId || null,
      amount: addition?.amount || 0,
    });
  }, [addition]);

  useEffect(() => {
    if (
      addition &&
      !firstRender &&
      (additionState.amount !== addition.amount || additionState.productId !== addition.productId)
    ) {
      updateAddition({
        ...addition,
        ...additionState,
      });
    }
  }, [additionState]);

  return (
    <Row gutter={16} key={addition?.id}>
      <Col span={20}>
        <Form.Item label={"Nazwa produktu"} labelCol={{ span: 24 }}>
          <Select
            placeholder="Wybierz produkt!"
            value={additionState.productId}
            options={readyOptions}
            showSearch
            filterOption={createFilterOption()}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={(value) => {
              setAdditionState((prevState) => ({
                ...prevState,
                productId: value,
              }));
            }}
          />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label="Ilość" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={additionState.amount}
            min={0}
            style={{ width: "100%" }}
            onChange={(value) => {
              setAdditionState((prevState) => ({ ...prevState, amount: value as number }));
            }}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              if (addition) {
                deleteAddition(addition);
              }
            }}
          >
            <DeleteOutlined />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AdditionalProductPosition;
