import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Collapse, Flex, Form, Image, Radio, Row, Tooltip } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import gutterImage from "../../../../../../assets/roofs/other/gutter-new.svg";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { acceptGutteringInRoof } from "../../../../../../helpers/Helpers";
import { ICalcMaterialsData } from "../../../../../../models/Models";
import {
  clearGutteringElementsCalcMaterialsData,
  updateCalcMaterialsData,
} from "../../../../../../redux/calculationProcess/calcMaterialsDataSlice";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  clearGutteringElements,
  IGutteringElement,
  IGutteringElements,
} from "../../../../../../redux/roofs/gutteringElementsSlice";
import { PRIMARY_COLOR } from "../../../../../../theme";
import { TypeGutteringElement } from "../GeometryStep";

type Props = {
  close: () => void;
  activeAddGutteringElement: TypeGutteringElement | null;
  setActiveAddGutteringElement: (value: TypeGutteringElement | null) => void;
  extended?: boolean;
};

const PanelFlowGutters: FC<Props> = ({
  extended,
  close,
  activeAddGutteringElement,
  setActiveAddGutteringElement,
}) => {
  const dispatch = useAppDispatch();

  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);
  const gutteringElements = useAppSelector((state) => state.gutteringElements);
  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);
  const responsesApi = useAppSelector((state) => state.responsesApi);

  const isAutomatic = roofProcessStatus.type === "outline";

  const GutterRowComponent: FC<any> = ({ marginTop, name, countValueAutomatic, countValue }) => {
    return (
      <GutterRow style={{ marginTop: marginTop ? marginTop : "default" }}>
        <GutterCol span={1}>
          <Checkbox
            // disabled={!extended}
            checked={activeAddGutteringElement?.toString() === countValueAutomatic.slice(0, -1)}
            onChange={() => {
              if (activeAddGutteringElement?.toString() === countValueAutomatic.slice(0, -1)) {
                setActiveAddGutteringElement(null);
              } else {
                setActiveAddGutteringElement(countValueAutomatic.slice(0, -1));
              }
            }}
          />
        </GutterCol>
        <GutterCol span={11} style={{ display: "flex", justifyContent: "left", paddingLeft: "16px" }}>
          {name}
        </GutterCol>
        <GutterCol span={10} style={{ display: "flex", justifyContent: "right" }}>
          <DomaInputNumber
            disabled={true}
            value={gutteringElements?.[countValueAutomatic as keyof IGutteringElements].length}
            onChange={(value) => {
              dispatch(
                updateCalcMaterialsData({ ...calcMaterialsData, [countValue]: value ? (value as number) : 0 })
              );
            }}
          />
        </GutterCol>
        <GutterCol span={2}>szt.</GutterCol>
      </GutterRow>
    );
  };

  const acceptAutoGutteringElements = () => {
    if (responsesApi.gutteringData === null) return;

    const gutteringData = responsesApi.gutteringData;

    dispatch(acceptGutteringInRoof(gutteringData));
  };

  return (
    <Container title="Orynnowanie" size="small" bodyStyle={{ padding: "0px" }}>
      <SimpleContent
        title="Wymiary do wyznaczenia orynnowania"
        size="small"
        headStyle={{ backgroundColor: "#f5f5f5" }}
      >
        <Row gutter={16}>
          <Col span={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ImageContainer style={{ padding: "16px 0px 16px 16px" }}>
              <Image style={{ width: "100%" }} src={gutterImage} preview={false} />
            </ImageContainer>
          </Col>
          <Col span={12} style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
            <Form style={{ marginTop: "32px" }}>
              <Form.Item label="W [m]" labelAlign="left" labelCol={{ span: 0 }}>
                <DomaInputNumber
                  value={calcMaterialsData?.eaveOverhang}
                  onChange={(value) => {
                    dispatch(
                      updateCalcMaterialsData({
                        ...calcMaterialsData,
                        eaveOverhang: value ? (value as number) : 0,
                      })
                    );
                    dispatch(invalidateCalculation());
                  }}
                />
              </Form.Item>
              <Form.Item label="H [m]" labelAlign="left" labelCol={{ span: 0 }}>
                <DomaInputNumber
                  value={calcMaterialsData?.wallHeight}
                  onChange={(value) => {
                    dispatch(
                      updateCalcMaterialsData({ ...calcMaterialsData, wallHeight: value ? (value as number) : 0 })
                    );
                    dispatch(invalidateCalculation());
                  }}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </SimpleContent>
      <SimpleContent
        title={
          <Flex justify="space-between">
            <div>Elementy orynnowania</div>
            <Tooltip
              title={
                <div style={{ textAlign: "center" }}>
                  Automatyczne określenie i dodanie do rysunku elementów orynnowania. <br />
                  Należy przeprowadzić kontrolę i ewentualne poprawki.
                </div>
              }
              color={PRIMARY_COLOR}
              zIndex={9999999}
              placement="topRight"
            >
              <Button size="small" type="primary" onClick={() => acceptAutoGutteringElements()}>
                Dodaj automatycznie
              </Button>
            </Tooltip>
          </Flex>
        }
        size="small"
        headStyle={{ backgroundColor: "#f5f5f5" }}
      >
        <GutterRowComponent
          name="Punkty spustowe"
          countValueAutomatic="gutterDrainPipes"
          countValue="drainPipeCount"
        />
        <GutterRowComponent
          name="Narożniki wewnętrzne"
          countValueAutomatic="gutterInternalCorners"
          countValue="internalCornerCount"
          marginTop={8}
        />
        <GutterRowComponent
          name="Narożniki zewnętrzne"
          countValueAutomatic="gutterExternalCorners"
          countValue="outerCornerCount"
          marginTop={8}
        />
        <GutterRowComponent
          name="Zakończenia lewe"
          countValueAutomatic="gutterEndLefts"
          countValue="leftCapCount"
          marginTop={8}
        />
        <GutterRowComponent
          name="Zakończenia prawe"
          countValueAutomatic="gutterEndRights"
          countValue="rightCapCount"
          marginTop={8}
        />
      </SimpleContent>
      <Row style={{ display: "flex", justifyContent: "space-between", gap: "8px", padding: "8px" }}>
        <Button
          type="primary"
          danger
          onClick={() => {
            dispatch(clearGutteringElements());
            dispatch(clearGutteringElementsCalcMaterialsData());
            dispatch(invalidateCalculation());
          }}
        >
          Wyczyść dane!
        </Button>
        <Button type="primary" onClick={() => close()}>
          OK
        </Button>
      </Row>
    </Container>
  );
};
export default PanelFlowGutters;

const GutterRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GutterCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(Card)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
  margin-top: 66px;
  padding: 0px;
  width: 400px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 999;
`;

const SimpleContent = styled(Card)`
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
