import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, Input, message, Radio, Row, Select } from "antd";
import {
  IAddPreCoveringSystem,
  IPreCoveringSystem,
  IPreCoveringSystemAddition,
  IProduct,
  IProductCategory,
  IRootState,
  ISelectOption,
} from "../../../models/Models";
import { addPreCoveringSystem, updatePreCoveringSystem } from "../../../redux/roofSystems/preCoveringSystemsSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { CATEGORIES_ALL_ROOF, EXTRA_TILE_SYSTEM_RELATIONS, TILE_SYSTEM_RELATIONS } from "../RoofConstants";
import { createFilterOption } from "../../../helpers/Helpers";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import ExtraProductPreCoveringSystemPosition from "./ExtraProductPreCoveringSystemPosition";
import SystemImageComponent from "../roofSystems/components/SystemImageComponent";
import SystemConfigurationDetailsSection from "../components/SystemConfigurationDetailsSection";

type Props = {
  system: IPreCoveringSystem | null;
  manuallySubmitForm?: boolean;
  setManuallySubmitForm?: any;
};

const PreCoveringSystemDetails: FC<Props> = ({ system, manuallySubmitForm, setManuallySubmitForm }) => {
  const [form] = useForm();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const products = useAppSelector((state: IRootState) => state.products);
  const statusApi = useAppSelector((state: IRootState) =>
    state.statusApi.find((x: any) => x.key === "addPreCoveringSystem")
  );

  const [preCoveringSystemAdditions, setPreCoveringSystemAdditions] = useState<IPreCoveringSystemAddition[]>([]);

  const [imageFileName, setImageFileName] = useState<string>("");

  const preCoveringSystems = useAppSelector((state: IRootState) => state.preCoveringSystems);

  const [productsSelectForBasic, setProductsSelectForBasic] = useState<ISelectOption[]>([]);
  const [productsSelectForAdditions, setProductsSelectForAdditions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    if (products && productCategories && companies && productColors && productModels) {
      if (productsSelectForBasic.length === 0) {
        setProductsSelectForBasic(
          getProductsByCategoryForSelect(products, productCategories, [
            "supplementary-products",
            "thermal-insulation",
            "preliminary-covering",
          ])
        );
      }

      if (productsSelectForAdditions.length === 0) {
        setProductsSelectForAdditions(
          getProductsByCategoryForSelect(products, productCategories, [
            "basic-products-tiles-concrete",
            "basic-products-tiles-ceramic",
            "basic-products-sheet-metal",
            "basic-products-cut-sheet-metal",
            "basic-products-sheet-metal-trapezoidal",
            "basic-products-sheet-metal-standing-seam",
            "wood",
            "supplementary-products",
            "lightning-protection-systems",
            "thermal-insulation",
            "roof-walkways-and-snow-guards",
            "roofing-accessories",
            "fasteners",
            "preliminary-covering",
          ])
        );
      }
    }
  }, [products, productCategories, companies, productColors, productModels]);

  useEffect(() => {
    if (statusApi?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("addPreCoveringSystem"));
    } else if (statusApi?.type === "error") {
      dispatch(deleteStatusApi("addPreCoveringSystem"));
    }
  }, [statusApi]);

  useEffect(() => {
    if (preCoveringSystems) {
      const s = preCoveringSystems.find((x) => x.id === system?.id);
      if (s) {
        setPreCoveringSystemAdditions(s.preCoveringSystemAdditions);
      }
    }
  }, [preCoveringSystems]);

  useEffect(() => {
    if (system && system.preCoveringSystemAdditions && system.preCoveringSystemAdditions.length > 0) {
      setPreCoveringSystemAdditions(system.preCoveringSystemAdditions);
    }

    if (system?.imageUrl) {
      setImageFileName(system.imageUrl);
    }
  }, [system]);

  useEffect(() => {
    if (manuallySubmitForm === true) {
      manuallySubmit();
    }
  }, [manuallySubmitForm]);

  const isNew = () => {
    return system === null ? true : false;
  };

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  const getInitialValues = () => {
    return isNew()
      ? {
          name: "",
          description: "",
          imageUrl: "",
          basicUse: 0,
          basicRelation: "area",
          basicCorrection: 0,
        }
      : {
          name: system?.name,
          description: system?.description,
          imageUrl: system?.imageUrl,
          basicProductId: system?.basicProductId ? system?.basicProductId : undefined,
          basicUse: system?.basicUse ? system?.basicUse : 0,
          basicCorrection: system?.basicCorrection ? system?.basicCorrection : 0,
          basicRelation: system?.basicRelation ? system?.basicRelation : "area",

          companyId: system?.companyId ? system?.companyId : undefined,
          modelId: system?.productModelId ? system?.productModelId : undefined,
          colorId: system?.productColorId ? system?.productColorId : undefined,
        };
  };

  const manuallySubmit = async () => {
    setManuallySubmitForm(false);
    try {
      const values = await form.validateFields();
      const newVersion = true;
      onFinish(values, newVersion);
    } catch (error) {
      // console.error("Błąd walidacji:", error);
    }
  };

  const successCallback = () => {
    messageApi.open({
      type: "success",
      content: "System został utworzony!",
    });
  };

  const onFinish = (values: any, newVersion: boolean) => {
    if (isNew() || newVersion) {
      let s: IAddPreCoveringSystem = {
        name: values.name,
        description: values.description,
        // imageUrl: values.imageUrl,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        preCoveringSystemAdditions: preCoveringSystemAdditions,
        basicUse: values?.basicUse,
        basicRelation: values?.basicRelation,
        basicCorrection: values?.basicCorrection,
        basicProductId: values?.basicProductId,
      };
      dispatch(addPreCoveringSystem(s, successCallback));
    } else {
      let s: IPreCoveringSystem = {
        id: system?.id as string,
        name: values.name,
        description: values.description,
        // imageUrl: values.imageUrl,
        imageUrl: imageFileName,
        companyId: values?.companyId,
        productColorId: values?.colorId,
        productModelId: values?.modelId,
        preCoveringSystemAdditions: preCoveringSystemAdditions,
        basicUse: values?.basicUse,
        basicRelation: values?.basicRelation,
        basicCorrection: values?.basicCorrection,
        basicProductId: values?.basicProductId,
      };
      dispatch(updatePreCoveringSystem(s));
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  interface MainProductPositionProps {
    productLabel: string;
    productName: string;
    useName: string;
    useDisabled: boolean;
    relationName: string;
    relationDisabled: boolean;
    correctionName: string;
    correctionDisabled: boolean;
    products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
    productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
    relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
    selectedCategories: string[];
    readyOptions?: any[];
  }

  const MainProductPosition: React.FC<MainProductPositionProps> = ({
    productLabel,
    productName,
    useName,
    useDisabled,
    relationName,
    relationDisabled,
    correctionName,
    correctionDisabled,
    products,
    productCategories,
    relations,
    selectedCategories,
    readyOptions,
  }) => {
    const onProductChange = (productId: any) => {
      const selectedProduct = products.find((p) => p.id === productId);
      if (selectedProduct) {
        form.setFieldsValue({ [useName]: selectedProduct.use });
      }
    };

    return (
      <Row gutter={16}>
        <Col span={14}>
          <Form.Item label={productLabel} name={productName} labelCol={{ span: 24 }}>
            <Select
              placeholder="Wybierz produkt!"
              options={
                readyOptions
                  ? readyOptions
                  : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
              }
              showSearch
              filterOption={createFilterOption()}
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
              onChange={onProductChange}
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Użycie" name={useName} labelCol={{ span: 24 }}>
            <DomaInputNumber disabled={useDisabled} min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Zależność" name={relationName} labelCol={{ span: 24 }}>
            <Select
              disabled={relationDisabled}
              placeholder="Wybierz zależność!"
              options={relations.sort((a, b) => a.index - b.index)}
              dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
              filterOption={createFilterOption()}
              showSearch
            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label="Korekta w [%]" name={correctionName} labelCol={{ span: 24 }}>
            <DomaInputNumber
              disabled={correctionDisabled}
              allowNegative
              min={-100}
              max={200}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Container>
      {contextHolder}
      <Form
        id="preCoveringSystemDetailsForm"
        style={{ width: "100%" }}
        form={form}
        initialValues={getInitialValues()}
        onFinish={(values) => onFinish(values, false)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="horizontal"
      >
        <Section title={"Ogólne dane systemu"}>
          <Form.Item
            label="Nazwa systemu"
            name="name"
            rules={[{ required: true, message: "Podaj nazwę systemu!" }]}
            labelCol={{ span: 24 }}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Opis systemu" name="description" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>

          {/* <Form.Item label="Link do zdjęcia obrazującego system" name="imageUrl" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item> */}

          <SystemImageComponent
            systemId={system?.id || ""}
            systemName="preCovering"
            imageFileName={imageFileName}
            setImageFileName={(name) => setImageFileName(name)}
          />
        </Section>

        {/* Sekcja do konfiguracji producenta, modelu oraz koloru */}
        <SystemConfigurationDetailsSection form={form} />

        <Section title={"Podstawowy produkt systemu"} style={{ marginTop: "16px" }}>
          {/* Produkt podstawowy czyli folia */}
          <MainProductPosition
            selectedCategories={["supplementary-products", "thermal-insulation", "preliminary-covering"]}
            readyOptions={productsSelectForBasic}
            products={products}
            productCategories={productCategories}
            productLabel="Produkt krycia wstępnego"
            productName="basicProductId"
            useName="basicUse"
            relationName="basicRelation"
            useDisabled={false}
            relationDisabled={true}
            correctionDisabled={false}
            correctionName="basicCorrection"
            relations={TILE_SYSTEM_RELATIONS}
          />
        </Section>

        <Section
          title={"Pozostałe produkty systemu (uzależnione od typu krawędzi, powierzchni dachu, itp.)"}
          style={{ marginTop: "16px" }}
          extra={
            <Button
              type="primary"
              onClick={() => {
                const newPreCoveringSystemAddition: IPreCoveringSystemAddition = {
                  id: uuidv4(),
                  productId: "",
                  use: 0,
                  relation: "none",
                  correction: 0,
                  preCoveringSystemId: system?.id as string,
                };
                setPreCoveringSystemAdditions([newPreCoveringSystemAddition, ...preCoveringSystemAdditions]);
              }}
            >
              Dodaj
            </Button>
          }
        >
          {preCoveringSystemAdditions.map((addition) => {
            // console.log("additionMap", addition);
            return (
              <ExtraProductPreCoveringSystemPosition
                key={addition.id}
                addition={addition}
                selectedCategories={[
                  "basic-products-tiles-concrete",
                  "basic-products-tiles-ceramic",
                  "basic-products-sheet-metal",
                  "basic-products-cut-sheet-metal",
                  "basic-products-sheet-metal-trapezoidal",
                  "basic-products-sheet-metal-standing-seam",
                  "wood",
                  "supplementary-products",
                  "lightning-protection-systems",
                  "thermal-insulation",
                  "roof-walkways-and-snow-guards",
                  "roofing-accessories",
                  "fasteners",
                  "preliminary-covering",
                ]}
                readyOptions={productsSelectForAdditions}
                products={products}
                productCategories={productCategories}
                useDisabled={false}
                relationDisabled={false}
                correctionDisabled={false}
                relations={[...TILE_SYSTEM_RELATIONS]}
                updateAddition={(addition: any) => {
                  const newAdditions = [...preCoveringSystemAdditions];
                  const index = newAdditions.findIndex((x) => x.id === addition.id);

                  if (index >= 0) {
                    newAdditions[index] = addition;
                    setPreCoveringSystemAdditions(newAdditions);
                  }
                }}
                deleteAddition={(addition: any) => {
                  const newAdditions = [...preCoveringSystemAdditions].filter((x) => x.id !== addition.id);
                  setPreCoveringSystemAdditions(newAdditions);
                }}
              />
            );
          })}
        </Section>
      </Form>
    </Container>
  );
};

export default PreCoveringSystemDetails;

const Container = styled.div``;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
