import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IExternalGutterSystem {
  id: string;
  name: string;
  owner: string;
  description?: string;
  imageUrl?: string;
}

const initialState: IExternalGutterSystem[] = [];

export const externalGutterSystemsSlice = createSlice({
  name: "externalGutterSystems",
  initialState,
  reducers: {
    setExternalGutterSystemsInReducer: (state, action: PayloadAction<IExternalGutterSystem[]>) => {
      let list = action.payload;
      return list;
    },
  },
});

export const { setExternalGutterSystemsInReducer } = externalGutterSystemsSlice.actions;
export default externalGutterSystemsSlice.reducer;
