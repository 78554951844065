import { Action, Dispatch, Store } from "@reduxjs/toolkit";
import { TOKEN_KEY_NAME } from "../api/ApiClient";
import { resetRedux } from "./resetSlice";

// SignUp and go to SignIn page if return 201
export const logOut = () => (dispatch: Dispatch) => {
  // dispatch(clearAuthInReducer());
  dispatch(resetRedux());
  localStorage.removeItem(TOKEN_KEY_NAME);
};
