import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISteelSystemAdditionType } from "../../models/Models";

const initialState: ISteelSystemAdditionType[] = [];

export const steelSystemAdditionTypesSlice = createSlice({
  name: "steelSystemAdditionTypes",
  initialState,
  reducers: {
    setSteelSystemAdditionTypessInReducer: (state, action: PayloadAction<ISteelSystemAdditionType[]>) => {
      let types = action.payload;
      return types;
    },
  },
});

export const { setSteelSystemAdditionTypessInReducer } = steelSystemAdditionTypesSlice.actions;
export default steelSystemAdditionTypesSlice.reducer;
