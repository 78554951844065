import { InboxOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Form, message, Popconfirm, Row, Select, Space, Statistic, Table } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../api/ApiClient";
import PageHeader from "../../../layout/components/PageHeader";
import { UploadRequestOption } from "../../contractors/components/ContractorDataTransfer";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "../../../redux/hooks";
import { ICompany, IProduct, ISalesForm } from "../../../models/Models";
import { set } from "js-cookie";

export interface IImportProductsRequestModel {
  file: any;
  companyId: string;
}

type Props = {};

const ImportProductsFromExcel: FC<Props> = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [existedProductsInCompany, setExistedProductsInCompany] = useState<any[]>([]);
  const [existedProductsByNames, setExistedProductsByNames] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newCompanies, setNewCompanies] = useState<any[]>([]);
  const [newProductModels, setNewProductModels] = useState<any[]>([]);
  const [newProductColors, setNewProductColors] = useState<any[]>([]);
  const [allProductsInExcelCount, setAllProductsInExcelCount] = useState<number>(0);
  const [existingProductsCount, setExistingProductsCount] = useState<number>(0);

  const fileUploadProps = {
    name: "file",
    action: "/DataTransfer/verifyNewProducts",
    customRequest(options: UploadRequestOption) {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();

      formData.append("file", file); // Dołącz plik

      setLoading(true);

      setProducts([]);
      setExistedProductsInCompany([]);
      setExistedProductsByNames([]);
      setNewCompanies([]);
      setNewProductModels([]);
      setNewProductColors([]);
      setAllProductsInExcelCount(0);
      setExistingProductsCount(0);

      client
        .post(fileUploadProps.action, formData, {
          headers: {
            ...client.defaults.headers.common, // Zachowaj domyślne nagłówki
            "Content-Type": "multipart/form-data", // Nadpisz tylko Content-Type
          },
        })
        .then((response) => {
          if (onSuccess) {
            console.log(response.data);

            if (response.data.allProductsInExcelCount === 0 && response.data.existingProductsCount === 0) {
              message.error("Nie znaleziono żadnych produktów w pliku.", 10);
            } else if (response.data.allProductsInExcelCount > 0 && response.data.existingProductsCount > 0) {
              if (response.data.allProductsInExcelCount === response.data.existingProductsCount) {
                message.info("Wszystkie produkty z pliku są już w bazie danych - brak produktów do dodania", 10);
              } else {
                message.success("Plik został przetworzony pomyślnie.");
              }
            }

            if (response.data.products && response.data.products.length > 0) {
              setProducts(response.data.products);
            }

            if (response.data.existedProductsByNames && response.data.existedProductsByNames.length > 0) {
              setExistedProductsByNames(response.data.existedProductsByNames);
            }

            if (response.data.existedProductsInCompany && response.data.existedProductsInCompany.length > 0) {
              setExistedProductsInCompany(response.data.existedProductsInCompany);
            }

            setAllProductsInExcelCount(response.data.allProductsInExcelCount);
            setExistingProductsCount(response.data.existingProductsCount);
            setNewCompanies(response.data.newCompanies);
            setNewProductModels(response.data.newProductModels);
            setNewProductColors(response.data.newProductColors);
          }

          setLoading(false);
        })
        .catch((error) => {
          if (onError) {
            console.log(error);
            message.error(
              "Wystąpił błąd podczas przetwarzania pliku. Sprawdź czy plik jest poprawny! " +
                error?.response?.data,
              15
            );
            // onError(error, error.response);
          }

          setLoading(false);
        });
    },
  };

  const columns = [
    {
      title: "Nazwa produktu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Id producenta",
      dataIndex: "producerId",
      key: "producerId",
      align: "center" as "center",
    },
    {
      title: "Forma sprzedaży 1",
      dataIndex: "salesForms",
      key: "salesForms1",
      align: "center" as "center",
      render: (salesForms: ISalesForm[]) =>
        // Pierwsza forma sprzedaży - jeśli sitenije
        salesForms.length > 0 ? `${salesForms[0].name} ${salesForms[0].price} ${salesForms[0].currency}` : "Brak",
    },
    {
      title: "Forma sprzedaży 2",
      dataIndex: "salesForms",
      key: "salesForms2",
      align: "center" as "center",
      render: (salesForms: ISalesForm[]) =>
        // Druga forma sprzedaży - jeśli istnieje
        salesForms.length > 1 ? `${salesForms[1].name} ${salesForms[1].price} ${salesForms[1].currency}` : "Brak",
    },
    {
      title: "Forma sprzedaży 3",
      dataIndex: "salesForms",
      key: "salesForms3",
      align: "center" as "center",
      render: (salesForms: ISalesForm[]) =>
        // Trzecia forma sprzedaży - jeśli istnieje
        salesForms.length > 2 ? `${salesForms[2].name} ${salesForms[2].price} ${salesForms[2].currency}` : "Brak",
    },
  ];

  const columnsExistedProducts = [
    {
      title: "Nazwa produktu",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Id producenta",
      dataIndex: "producerId",
      key: "producerId",
      align: "center" as "center",
    },
  ];

  const confirmAddProducts = async () => {
    try {
      const model = {
        products: products,
        newCompanies: newCompanies,
        newProductModels: newProductModels,
        newProductColors: newProductColors,
      };

      const response = await client.post("/DataTransfer/uploadProducts", model);
      if (response.status === 200) {
        const data = response.data;
        message.success(`Pomyślnie dodano ${data} nowych produktów do bazy danych.`, 5);
      } else {
        message.error("Wystąpił błąd podczas dodawania produktów do bazy danych!", 8);
      }
    } catch (err) {
      console.error(err);
      message.error("Wystąpił błąd podczas dodawania produktów do bazy danych!", 8);
    }
  };

  return (
    <Container>
      <PageHeader
        title={"Importowanie produktów z pliku"}
        extra={
          products.length > 0 && (
            <div style={{ display: "flex", gap: "16px" }}>
              <Button
                type="primary"
                danger
                onClick={() => {
                  setProducts([]);
                  setExistedProductsInCompany([]);
                  setExistedProductsByNames([]);
                  setNewCompanies([]);
                  setNewProductModels([]);
                  setNewProductColors([]);
                  setAllProductsInExcelCount(0);
                  setExistingProductsCount(0);
                }}
              >
                Anuluj
              </Button>
              <Popconfirm
                title="Dodanie nowych produktów"
                description="Dodanie produktów z pliku Excel spowoduje zmianę w bazie danych. Czy na pewno chcesz kontynuować?"
                onConfirm={() => {
                  confirmAddProducts();
                }}
                okText="Tak"
                cancelText="Nie"
                placement="topRight"
              >
                <Button type="primary">Dodaj produkty</Button>
              </Popconfirm>
            </div>
          )
        }
      />

      {products.length > 0 && (
        <>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px" }}>
            <Card style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Statistic
                title="Ilość pozycji w Excelu"
                value={allProductsInExcelCount}
                valueStyle={{ textAlign: "center" }}
              />
            </Card>
            <Card
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Statistic
                title="Ilość istniejących produktów w aplikacji (bazie danych)"
                value={existingProductsCount}
                valueStyle={{ textAlign: "center" }}
              />
            </Card>
            <Card
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                background: "#539626",
              }}
            >
              <Statistic
                title={<div style={{ color: "#ffffff" }}>Ilość produktów, które zostaną dodane</div>}
                value={allProductsInExcelCount - existingProductsCount}
                valueStyle={{ textAlign: "center", color: "#ffffff", fontWeight: "bold" }}
                style={{ color: "#ffffff" }}
              />
            </Card>
          </div>
          <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "16px" }}>
            <Card style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Statistic
                title="Ilość nowych producentów produktów (będą dodane)"
                value={newCompanies.length}
                valueStyle={{ textAlign: "center" }}
              />
            </Card>
            <Card
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Statistic
                title="Ilość nowych modeli produktów (będą dodane)"
                value={newProductModels.length}
                valueStyle={{ textAlign: "center" }}
              />
            </Card>
            <Card
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Statistic
                title="Ilość nowych kolorów produktów (będą dodane)"
                value={newProductColors.length}
                valueStyle={{ textAlign: "center" }}
              />
            </Card>
          </div>
        </>
      )}

      {products.length > 0 && (
        <Alert
          message="Uwaga!"
          description={
            <div>
              Upewnij się, że ilość produktów, które zostaną dodane jest zgodna z oczekiwaniami. W przeciwnym razie
              sprawdź plik Excel czy jest poprawny!
              <br />
              Jeśli ilość pozycji w Excelu nie zgadza się z ilością istniejących produktów w aplikacji, oznacza to,
              że część z tych produktów jest już w bazie danych.
            </div>
          }
          type="warning"
          showIcon
        />
      )}

      {products.length === 0 && (
        <Section loading={loading}>
          <Alert
            message="Uwaga!"
            description={
              <div>
                Aby produkty mogły być wczytane poprawnie, plik Excel musi zawierać odpowiednie dane.
                <br />
                Struktura pliku musi być zgodna z wymaganiami.
                <br />
                Minimalne wymagania to określona{" "}
                <b>nazwa produktu, kategoria, producent, nazwa i cena pierwszej formy sprzedażowej</b> (jeśli tego
                nie ma to produkt jest zbędny - funkcja nie będzie działać)
              </div>
            }
            type="warning"
            showIcon
            style={{ marginBottom: "16px" }}
          />

          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Dragger {...fileUploadProps} showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Kliknij lub przenieś plik EXCEL z produtami</p>
                <p className="ant-upload-hint">
                  Wybierz tylko jeden plik EXCEL. Plik musi mieć strukturę zgodną z wymaganiami.
                </p>
              </Dragger>
            </Col>
          </Row>
        </Section>
      )}

      {products.length > 0 && (
        <Section title={<div>Lista produktów, które zostaną dodane - {products.length} szt.</div>}>
          <Table dataSource={products} columns={columns} loading={loading} rowKey="id" />
        </Section>
      )}

      {existedProductsInCompany && existedProductsInCompany.length > 0 && (
        <Section
          title={
            <div style={{ color: "#ff0000" }}>
              !!! Produkty, których <b>Id Producenta</b> jest już zajęte dla wskazanego producenta (w systemie lub
              tym excelu) - {existedProductsInCompany.length} szt.
            </div>
          }
        >
          <Alert
            showIcon
            description={
              <div>
                Sprawdź w excelu czy nie ma duplikatów <b>Id Producenta</b>
              </div>
            }
            type="warning"
          />
          <Table
            dataSource={existedProductsInCompany}
            columns={columnsExistedProducts}
            loading={loading}
            rowKey="id"
          />
        </Section>
      )}

      {existedProductsByNames && existedProductsByNames.length > 0 && (
        <Section
          title={
            <div style={{ color: "#ff0000" }}>
              !!! Produkty, których zestaw nazw jest już w bazie danych (w systemie lub tym excelu) -
              {existedProductsByNames.length} szt.
            </div>
          }
        >
          <Alert showIcon description={<div>Sprawdź w excelu czy nie ma duplikatów</div>} type="warning" />
          <Table
            dataSource={existedProductsByNames}
            columns={columnsExistedProducts}
            loading={loading}
            rowKey="id"
          />
        </Section>
      )}
    </Container>
  );
};
export default ImportProductsFromExcel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)``;
