import React, { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { Button, Card, Table } from "antd";
import { useParams } from "react-router-dom";
import PageHeader from "../../layout/components/PageHeader";
import { useAppSelector } from "../../redux/hooks";
import ProductDetails from "./components/ProductDetails";

type Props = {};

const AddProduct: FC<Props> = () => {
  const addProduct = () => {
    <Button form="productUseForm" key="submit" htmlType="submit" type="primary">
      Zaktualizuj
    </Button>;
  };

  return (
    <Container>
      <PageHeader
        title={"Dodaj nowy produkt"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button onClick={() => null} disabled>
              Pobierz dane na podstawie kodu
            </Button>
            <Button type="primary" form="productDetailsForm" key="submit" htmlType="submit">
              Dodaj produkt
            </Button>
          </div>
        }
      />

      <Content>
        <ProductDetails product={null} />
      </Content>
    </Container>
  );
};

export default AddProduct;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
