import { WarningFilled } from "@ant-design/icons";
import { Points } from "@react-three/drei";
import { Tooltip } from "antd";
import React from "react";
import { FC, useEffect, useState } from "react";
import { Arrow, Group, Line } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { updateRSOPlane } from "../../../../../../redux/roofSlopeOutline/rsoPlanesSlice";
import { IRSO, IRSOPoint, RSOPoint2D } from "./Models/RoofSlopeOutlineModels";

const HOVER_COLOR = "#9daf95cc";
const READY_COLOR = "#d0fabd80";
const NOT_READY_COLOR = "#FF0000cc";

type Props = {
  rso: IRSO;
  openEditOutlineModal: (rso: IRSO) => void;
  zoomScale: number;
  activePlaneToGetDeclineVector?: boolean;
  setActiveOutline?: (rso: IRSO | null) => void;
};

const RoofSlopeOutlinePosition: FC<Props> = ({
  rso,
  openEditOutlineModal,
  zoomScale,
  activePlaneToGetDeclineVector,
  setActiveOutline,
}) => {
  const dispatch = useAppDispatch();
  const rsoPoints = useAppSelector((state) => state.rsoPoints) || []; // wszystkie punkty na cąłgym rysunku

  const [points, setPoints] = useState<RSOPoint2D[]>(rso.points) || []; // tylko punkty dla połaci

  useEffect(() => {
    if (rso && rso.points) {
      // Lista id punktów na połaci
      const pointIds = rso.pointIds || []; // TODO na razie sa tylko punkt po obrysie
      // Pobieramy pełne punkty z rsoPoint po id
      //   const planePoints = rsoPoints.filter((p) => pointIds.includes(p.id));
      // Nowy sposbó zby zachowac kolejność punktów po pobraniu z rsoPoints
      const planePoints: IRSOPoint[] = pointIds
        .map((id) => rsoPoints.find((p) => p.id === id))
        .filter((p): p is IRSOPoint => p != null) as IRSOPoint[];

      setPoints(planePoints);
    }
  }, [rso, rsoPoints]);

  const setPointer = (e: any) => {
    e.target.getStage().container().style.cursor = "pointer";
  };

  const deletepointer = (e: any) => {
    e.target.getStage().container().style.cursor = "default";
  };

  const onClickRoofSlopeOutline = () => {
    openEditOutlineModal(rso);
  };

  const isReady = () => {
    return rso.declineVectoor.x !== 0 || rso.declineVectoor.y !== 0 ? true : false;
  };

  // Znajdź środek geometryczny Line który jest polygonem
  const getPolygonCenter = (points: RSOPoint2D[]) => {
    let sumX = 0;
    let sumY = 0;

    points.forEach((point) => {
      sumX += point.x;
      sumY += point.y;
    });

    return {
      x: sumX / points.length,
      y: sumY / points.length,
    };
  };

  const WIDTH_BOX_WARNING = 25;
  const HEIGHT_BOX_WARNING = 25;

  const ARROW_LENGTH = 50 / zoomScale; // Określ długość strzałki dla wektora spadku

  const getArrowPoints = () => {
    if (rso.declineVectoor.x !== 0 || rso.declineVectoor.y !== 0) {
      const startPoint = getPolygonCenter(points);
      const endPoint = {
        x: startPoint.x - rso.declineVectoor.x * ARROW_LENGTH,
        y: startPoint.y - rso.declineVectoor.y * ARROW_LENGTH,
      };
      return [startPoint.x, startPoint.y, endPoint.x, endPoint.y];
    }
    return [];
  };

  const getFill = () => {
    if (activePlaneToGetDeclineVector) {
      return "#aaaaaab3";
    } else if (rso.hover) {
      return HOVER_COLOR;
    } else {
      return isReady() === true ? READY_COLOR : NOT_READY_COLOR;
    }
  };

  return (
    points && (
      <Group>
        {points.length >= 3 && !isReady() && (
          <Group x={getPolygonCenter(points).x} y={getPolygonCenter(points).y}>
            <Html>
              <Tooltip title={<TooltipText>Niezdefiniowany wektor spadku!</TooltipText>} color="#ff0000">
                <WarningFilled
                  style={{
                    display: "flex",
                    fontSize: `${(WIDTH_BOX_WARNING - 5) / zoomScale}px`,
                    width: `${WIDTH_BOX_WARNING / zoomScale}px`,
                    height: `${HEIGHT_BOX_WARNING / zoomScale}px`,
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#e8e8e8",
                    color: "#ff0000",
                  }}
                  onClick={() => {
                    onClickRoofSlopeOutline();
                  }}
                />
              </Tooltip>
            </Html>
          </Group>
        )}

        <Line
          name="rsoPlane"
          id={rso.id}
          key={rso.id}
          points={points.flatMap((p) => [p.x, p.y])}
          stroke={rso.hover ? HOVER_COLOR : READY_COLOR}
          fill={getFill()}
          strokeWidth={2}
          closed
          onMouseEnter={(e) => {
            setPointer(e);
            if (setActiveOutline) {
              setActiveOutline(rso);
            }

            dispatch(updateRSOPlane({ ...rso, hover: true }));
          }}
          onMouseLeave={(e) => {
            deletepointer(e);
            if (setActiveOutline) {
              setActiveOutline(null);
            }
            dispatch(updateRSOPlane({ ...rso, hover: false }));
          }}
          onClick={() => {
            onClickRoofSlopeOutline();
          }}
        />

        {points.length >= 3 && isReady() && (
          <Arrow
            points={getArrowPoints()}
            pointerLength={10 / zoomScale} // Długość "grotu" strzałki
            pointerWidth={10 / zoomScale} // Szerokość "grotu" strzałki
            fill="black" // Kolor wypełnienia
            stroke="black" // Kolor obrysu
            strokeWidth={2 / zoomScale} // Szerokość obrysu
            zIndex={99999}
          />
        )}
      </Group>
    )
  );
};

export default RoofSlopeOutlinePosition;

const TooltipText = styled.div`
  text-align: center;
`;
