import React, { FC, useState } from "react";
import { Group, Line } from "react-konva";
import styled from "styled-components";
import { IRoofEdge, IRoofPoint } from "../../../../../models/Models";
import { addPointer, removePointer } from "../../../../../helpers/Helpers";

const getPointsForLineComponent = (pointIds: any, points: any) => {
  let arr = [];
  for (const id of pointIds) {
    const p = points.find((x: any) => x.id === id);

    if (p) {
      arr.push(p.x);
      arr.push(p.y);
    }
  }
  return arr;
};

export const getEdgeColor = (edgeType: number) => {
  switch (edgeType) {
    case 1:
      return "#8000ff";
    case 2:
      return "#675c72";
    case 3:
      return "#ff0000";
    case 4:
      return "#add422";
    case 5:
      return "#ff0000";
    case 6:
      return "#9dff00";
    case 7:
      return "#643e3e";
    case 9:
      return "#ffffff";
    case 10:
      return "#605151";
    default:
      return "#000";
  }
};

type Props = {
  roofEdge: IRoofEdge;
  roofPoints: IRoofPoint[];
  scale: number;
  showColored?: boolean;
  clickEdge: any;
};

const RoofEdge: FC<Props> = ({ roofEdge, roofPoints, scale, showColored, clickEdge }) => {
  const [hover, setHover] = useState<boolean>(false); // Czy myszka nad krawędzią

  // Gdy myszką najedzie się na obiekt
  const onMouseEnter = () => {
    setHover(true);
  };

  // Gdy myszką zjedzie się z obiektu
  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <Container>
      <Line
        points={getPointsForLineComponent([roofEdge.startPointId, roofEdge.endPointId], roofPoints)}
        closed={false}
        stroke={showColored ? getEdgeColor(roofEdge.type || 99) : "#2b2b2b"}
        strokeWidth={hover ? 7 / scale : 4 / scale}
        onMouseOver={(e) => {
          onMouseEnter();
          addPointer(e);
        }}
        onMouseLeave={(e) => {
          onMouseLeave();
          removePointer(e);
        }}
        onClick={() => clickEdge(roofEdge)}
      />
    </Container>
  );
};

export default RoofEdge;

const Container = styled(Group)``;
