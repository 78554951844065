import Konva from "konva";
import React, { FC, useLayoutEffect, useRef, useState } from "react";
import { Circle, Layer, Stage } from "react-konva";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import RoofEdge from "../../others/RoofEdge";
import RoofPlane from "../../others/RoofPlane";
import RoofSlopeOutlineEdge from "../RoofSlopeOutline/RoofSlopeOutlineEdge";
import RoofSlopeOutlinePosition from "../RoofSlopeOutline/RoofSlopeOutlinePosition";

type Props = {
  activeRoofSlopeId: string | null;
  setActiveRoofSlopeId: any;
};

const VerificationMaterialsRoofMiniView: FC<Props> = ({ activeRoofSlopeId, setActiveRoofSlopeId }) => {
  const dispatch = useAppDispatch();

  const roofPoints = useAppSelector((state) => state.roofPoints);
  const roofPlanes = useAppSelector((state) => state.roofPlanes);
  const roofEdges = useAppSelector((state) => state.roofEdges);
  const roofHoles = useAppSelector((state) => state.roofHoles);

  const rsoPoints = useAppSelector((state) => state.rsoPoints);
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes);
  const rsoEdges = useAppSelector((state) => state.rsoEdges);
  const rsoHoles = useAppSelector((state) => state.rsoHoles);

  const roofProcessFunctionsStatus = useAppSelector((state) => state.roofProcessFunctionsStatus);
  const inputData = useAppSelector((state) => state.inputData);
  const roofSlopes = useAppSelector((state) => state.roofSlopes) || [];

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, [stageParent.current]);

  const getDrawingCenter = () => {
    // Określamy minimalne x minimalne y maksymalne x maksymalne y dla roofPoints lub rsoPoints
    let minX = 0;
    let minY = 0;
    let maxX = 0;
    let maxY = 0;

    if (roofPoints && roofPoints.length > 0) {
      minX = Math.min(...roofPoints.map((p) => p.x));
      maxX = Math.max(...roofPoints.map((p) => p.x));
      minY = Math.min(...roofPoints.map((p) => p.y));
      maxY = Math.max(...roofPoints.map((p) => p.y));
    } else if (rsoPoints && rsoPoints.length > 0) {
      minX = Math.min(...rsoPoints.map((p) => p.x));
      maxX = Math.max(...rsoPoints.map((p) => p.x));
      minY = Math.min(...rsoPoints.map((p) => p.y));
      maxY = Math.max(...rsoPoints.map((p) => p.y));
    }

    // Obliczamy środek oryginalnego rysunku
    const originalCenterX = (minX + maxX) / 2;
    const originalCenterY = (minY + maxY) / 2;

    return { x: originalCenterX, y: originalCenterY };
  };

  const getScaleForDraw = () => {
    // Określamy minimalne x minimalne y maksymalne x maksymalne y dla roofPoints lub rsoPoints
    let minX = 0;
    let minY = 0;
    let maxX = 0;
    let maxY = 0;

    if (roofPoints && roofPoints.length > 0) {
      minX = Math.min(...roofPoints.map((p) => p.x));
      maxX = Math.max(...roofPoints.map((p) => p.x));
      minY = Math.min(...roofPoints.map((p) => p.y));
      maxY = Math.max(...roofPoints.map((p) => p.y));
    } else if (rsoPoints && rsoPoints.length > 0) {
      minX = Math.min(...rsoPoints.map((p) => p.x));
      maxX = Math.max(...rsoPoints.map((p) => p.x));
      minY = Math.min(...rsoPoints.map((p) => p.y));
      maxY = Math.max(...rsoPoints.map((p) => p.y));
    }

    // Odległość od minX do maxX
    const distanceX = maxX - minX;
    const distanceY = maxY - minY;

    const margin = 30; // 50

    const scaleX = (dimStageParent.width - margin) / distanceX;
    const scaleY = (dimStageParent.height - margin) / distanceY;

    const scale = scaleX < scaleY ? scaleX : scaleY;

    return { x: scale, y: scale };
  };

  const isAutomatic = () => {
    return rsoPlanes && rsoPlanes.length > 0 ? false : true;
  };

  const isSelectedPlane = (calcId: number) => {
    const roofSlopeByPlane = roofSlopes.find((rs) => rs.number === calcId);

    if (roofSlopeByPlane) {
      if (roofSlopeByPlane.id === activeRoofSlopeId) {
        return true;
      }
    }

    return false;
  };

  const getRoofPlanesOutlineSlope = () => {
    if (!isAutomatic()) {
      return rsoPlanes.map((rso) => {
        if (rso.active) {
          return (
            <RoofSlopeOutlinePosition
              key={rso.id}
              rso={rso}
              zoomScale={1}
              activePlaneToGetDeclineVector={isSelectedPlane(rso.calcId)}
              openEditOutlineModal={() => {
                const roofSlopeByPlane = roofSlopes.find((rs) => rs.number === rso.calcId);

                if (roofSlopeByPlane) {
                  setActiveRoofSlopeId(roofSlopeByPlane.id);
                }
              }}
            />
          );
        }
      });
    } else {
      // Gdy z automatu dach
      return roofPlanes.map((o, i) => {
        return (
          <RoofPlane
            key={o.id}
            roofInProgress={isSelectedPlane(o.calcId)}
            roofProcessFunctionsStatus={roofProcessFunctionsStatus}
            roofPlane={o}
            roofPoints={roofPoints}
            roofEdges={roofEdges}
            inputData={inputData}
            inEditAngle={false}
            dispatch={dispatch}
            lastAngle={0}
            setLastAngle={(v: any) => null}
            setActiveRoofPlane={(v: any) => null}
            blockSetActiveRoofPlane={false}
            scaleZoom={1}
            scale={1}
            showArea={false}
            onClickRoofPlane={() => {
              const roofSlopeByPlane = roofSlopes.find((rs) => rs.number === o.calcId);

              if (roofSlopeByPlane) {
                setActiveRoofSlopeId(roofSlopeByPlane.id);
              }
            }}
          />
        );
      });
    }
  };

  const getRoofEdgesOutlineSlope = () => {
    if (!isAutomatic()) {
      return rsoEdges.map((e) => {
        return (
          <RoofSlopeOutlineEdge key={e.id} rsoe={e} openEdgeModal={() => null} colored={false} zoomScale={1} />
        );
      });
    } else {
      // Gdy z automatu dach
      return roofEdges.map((o, i) => {
        return (
          <RoofEdge
            key={o.id}
            roofEdge={o}
            roofPoints={roofPoints}
            scale={1}
            showColored={false}
            clickEdge={() => null}
          />
        );
      });
    }
  };

  const drawingCenter = getDrawingCenter();
  const scale = getScaleForDraw();

  return (
    <Container>
      <StageParent ref={stageParent}>
        <Stage
          ref={stage}
          height={300}
          width={dimStageParent.width}
          style={{ margin: "0" }}
          onContextMenu={(e) => {
            e.evt.preventDefault();
          }}
          // draggable
          x={dimStageParent.width / 2 - drawingCenter.x * scale.x} // Uwzględnij skalę w obliczeniach przesunięcia
          y={dimStageParent.height / 2 - drawingCenter.y * scale.y} // Uwzględnij skalę w obliczeniach przesunięcia
          scale={scale} // Załóżmy, że scale to obiekt { x: scaleX, y: scaleY }
        >
          <Layer>
            {getRoofPlanesOutlineSlope()}
            {getRoofEdgesOutlineSlope()}
            {/* <Circle x={100} y={100} radius={50} strokeWidth={1} stroke={"#000000"} fill="#ffffff" /> */}
          </Layer>
        </Stage>
      </StageParent>
    </Container>
  );
};
export default VerificationMaterialsRoofMiniView;

const Container = styled.div`
  width: 300px;
  height: 300px;
  background-color: #d4d4d4;
`;

const StageParent = styled.div``;
