import { Button, Card, Input, Switch, Tooltip, Divider, Alert } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import {
  IFasteningSystem,
  IGutterSystem,
  IPreCoveringSystem,
  IRootState,
  ITileSystem,
} from "../../../../../models/Models";
import { updateCalcMaterialsData } from "../../../../../redux/calculationProcess/calcMaterialsDataSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import GutterSystem from "./MaterialStepComponents/GutterSystem";
import OtherMaterialData from "./MaterialStepComponents/OtherMaterialData";
import RoofSystem from "./MaterialStepComponents/RoofSystem";
import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";
import store from "../../../../../redux/store";
import {
  invalidateCalculation,
  updateStatusCalcResult,
} from "../../../../../redux/calculationProcess/calcResultSlice";
import ContractorSystem from "./MaterialStepComponents/ContractorSystem";
import VerificationMaterials from "./MaterialStepComponents/VerificationMaterials";
import { setRSOPoints } from "../../../../../redux/roofSlopeOutline/rsoPointsSlice";
import { setRSOEdges } from "../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import { setRSOPlanes } from "../../../../../redux/roofSlopeOutline/rsoPlanesSlice";
import {
  getDefaultWindowCollarForWindow,
  getDefaultWindowForHole,
  isClockwise,
} from "../../../../../helpers/Helpers";
import { setRSOHoles } from "../../../../../redux/roofSlopeOutline/rsoHolesSlice";
import { clearRoofSlopes } from "../../../../../redux/roofMetal/roofSlopesSlice";
import { clearMetalSheets } from "../../../../../redux/roofMetal/metalSheetsSlice";
import { calculateRoofMetal, ICalcRoofMetalRequestModel } from "../../../../../api/ApiRoof";
import PreCoveringSystem from "./MaterialStepComponents/PreCoveringSystem";
import FasteningSystem from "./MaterialStepComponents/FasteningSystem";
import {
  addAdditionProductsToWindowHole,
  addWindowProductsToWindowHole,
  clearWindowHoles,
  resetWindowHoles,
} from "../../../../../redux/calculationProcess/windowHolesSlice";
import CurrencyValue from "./MaterialStepComponents/CurrencyValue";
import AdditionalProductsSection from "./MaterialStepComponents/AdditionalProductsSection";
import { PRIMARY_COLOR } from "../../../../../theme";

type Props = {
  step: number;
};

const getNameRoofSystem = (systemId: string, systems: any[]) => {
  if (systemId) {
    return systems.find((x) => x.id === systemId)?.name;
  }
  return "Brak wybranego systemu pokrycia dachu";
};

const getNameGutterSystem = (systemId: string, gutterSystems: IGutterSystem[]) => {
  if (systemId) {
    return gutterSystems.find((x) => x.id === systemId)?.name;
  }
  return "Brak wybranego systemu rynnowego";
};

const getNamePreCoveringSystem = (systemId: string, preCoveringSystems: IPreCoveringSystem[]) => {
  if (systemId) {
    return preCoveringSystems.find((x) => x.id === systemId)?.name;
  }
  return "Brak wybranego systemu krycia wstęnego";
};

const getNameFasteningSystem = (systemId: string, fasteningSystems: IFasteningSystem[]) => {
  if (systemId) {
    return fasteningSystems.find((x) => x.id === systemId)?.name;
  }
  return "Brak wybranego systemu zamocowań";
};

const MaterialsStep: FC<Props> = ({ step }) => {
  const dispatch = useAppDispatch();
  const calcMaterialsData = useAppSelector((state: IRootState) => state.calcMaterialsData);
  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems) || [];
  const steelSystems = useAppSelector((state: IRootState) => state.steelSystems) || [];
  const gutterSystems = useAppSelector((state: IRootState) => state.gutterSystems) || [];
  const preCoveringSystems = useAppSelector((state: IRootState) => state.preCoveringSystems) || [];
  const fasteningSystems = useAppSelector((state: IRootState) => state.fasteningSystems) || [];
  const profile = useAppSelector((state) => state.profile);

  const rsoPoints = useAppSelector((state) => state.rsoPoints);
  const rsoEdges = useAppSelector((state) => state.rsoEdges);
  const rsoPlanes = useAppSelector((state) => state.rsoPlanes);
  const rsoHoles = useAppSelector((state) => state.rsoHoles);

  const metalSheets = useAppSelector((state) => state.metalSheets);

  const windowHoles = useAppSelector((state) => state.windowHoles);
  const windowIntegration = useAppSelector((state: IRootState) => state.windowIntegration);

  const isContractor = () => {
    return profile.role === "contractor";
  };

  // Co jest zaznaczone wybór materiałów lub weryfikacja
  const [selectedTab, setSelectedTab] = useState<string>("materials");

  useEffect(() => {
    // Przebudowa wszystki calcId
    let newPoints = rsoPoints.map((x, i) => {
      return { ...x, calcId: i + 1 };
    });

    const newEdges = rsoEdges.map((x, i) => {
      return { ...x, calcId: i + 1 };
    });

    const newPlanes = rsoPlanes.map((x, i) => {
      return { ...x, calcId: i + 1 };
    });

    const newHoles = rsoHoles.map((x, i) => {
      return { ...x, calcId: i + 1 };
    });

    // Zaktualziować pointCalcIds w planes
    for (let plane of newPlanes) {
      const pointIds = plane.pointIds; // Id do punktów jako GUID

      if (pointIds) {
        // Utworzyć kolekcję pełnych punktów
        let fullPoints =
          pointIds.map((pointId) => {
            const point = newPoints.find((x) => x.id === pointId);
            if (point) {
              return point;
            }

            return { id: "", type: 0, x: 0, y: 0, z: 0, calcId: 0 };
          }) || [];

        const isRight = isClockwise(fullPoints);
        fullPoints = isRight ? fullPoints.reverse() : fullPoints;

        const pointCalcIds = fullPoints.map((x) => x.calcId);

        plane.pointCalcIds = pointCalcIds;
      }
    }

    // Ustawienie calck dla start i end point for edge
    for (const edge of newEdges) {
      const startPointCalcId = newPoints.find((x) => x.id === edge.startPointId)?.calcId || 0;
      const endPointCalcId = newPoints.find((x) => x.id === edge.endPointId)?.calcId || 0;
      edge.startPointCalcId = startPointCalcId;
      edge.endPointCalcId = endPointCalcId;
    }

    // Aktualizacja nowych danych
    dispatch(setRSOPoints(newPoints));
    dispatch(setRSOEdges(newEdges));
    dispatch(setRSOPlanes(newPlanes));
    dispatch(setRSOHoles(newHoles));
  }, []);

  const clearDataForSheets = () => {
    // Czyścimy dane z poprzednich obliczeń jeśli były
    dispatch(clearRoofSlopes());
    dispatch(clearMetalSheets());
  };

  return (
    <Container>
      <div style={{ display: "flex", width: "100%", gap: "16px" }}>
        <Button
          type={selectedTab === "materials" ? "primary" : "default"}
          style={{ width: "100%", maxHeight: "40px", fontWeight: "bold" }}
          onClick={() => {
            setSelectedTab("materials");
          }}
        >
          Wybór materiałów
        </Button>
        <Button
          type={selectedTab === "verify" ? "primary" : "default"}
          style={{ width: "100%", maxHeight: "40px", fontWeight: "bold" }}
          onClick={() => {
            // UWAGA - podobny kod jest też dodany w VerificationMaterials.tsx

            if (!metalSheets || metalSheets.length === 0) {
              dispatch(clearRoofSlopes());
              dispatch(clearMetalSheets());

              // System pokrycia dachowego dla blachy
              const roofSystemId = calcMaterialsData.roofSystemId;
              const activeSteelSystem = steelSystems.find((x) => x.id === roofSystemId);

              if (activeSteelSystem) {
                // Przygotowanie danych do obliczeń z domysłnymi wartościami
                const model: ICalcRoofMetalRequestModel = {
                  layingDirection: 3,
                  layingMethod: 1,
                  overlapModuleCount: 1,
                  holeAddition: activeSteelSystem.holeAddition,
                  tolerance: activeSteelSystem.dimensionsTolerance,
                  userHeight: activeSteelSystem.length,
                  systemId: calcMaterialsData.roofSystemId || "",
                  systemType: calcMaterialsData.systemType,
                };

                dispatch(calculateRoofMetal(model, true));
              }
            }

            setSelectedTab("verify");
          }}
          disabled={
            calcMaterialsData.systemType === 0 ||
            calcMaterialsData.systemType === -1 ||
            calcMaterialsData.roofSystemId === null ||
            calcMaterialsData.roofSystemId === ""
          }
        >
          {metalSheets && metalSheets.length > 0
            ? "Podgląd rozkładu materiałów (tylko dla blach)"
            : "Oblicz i weryfikuj rozkład materiałów (tylko dla blach)"}
        </Button>
      </div>

      {selectedTab !== "materials" && <VerificationMaterials />}

      {selectedTab === "materials" && (
        <>
          {isContractor() && (
            <Section title="Wybierz system wyceny robocizny">
              <ContractorSystem />
            </Section>
          )}

          <Section
            title={
              <div style={{ display: "flex", gap: "16px", flexDirection: "column", paddingTop: "16px" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  Wybierz system dachowy
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "16px" }}>
                      <b>
                        <span style={{ color: "#ff0000" }}>
                          {getNameRoofSystem(calcMaterialsData.roofSystemId as string, [
                            ...tileSystems,
                            ...steelSystems,
                          ])}
                        </span>
                      </b>
                    </span>
                    {calcMaterialsData.roofSystemId ? (
                      <CheckCircleOutlined style={{ fontSize: "24px", color: "#3b8800" }} />
                    ) : (
                      <Tooltip title="Wybierz system dachowy!" color="#ed0000">
                        <CloseCircleOutlined style={{ fontSize: "24px", color: "#eb0000" }} />
                      </Tooltip>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", gap: "8px", marginRight: "16px" }}>
                  <Button
                    type={calcMaterialsData.systemType === 0 ? "primary" : "default"}
                    onClick={() => {
                      clearDataForSheets();
                      dispatch(
                        updateCalcMaterialsData({
                          ...calcMaterialsData,
                          roofSystemId: "",
                          systemType: 0,
                        })
                      );
                    }}
                  >
                    Dachówka ceramiczna
                  </Button>
                  <Button
                    type={calcMaterialsData.systemType === -1 ? "primary" : "default"}
                    onClick={() => {
                      clearDataForSheets();
                      dispatch(
                        updateCalcMaterialsData({
                          ...calcMaterialsData,
                          roofSystemId: "",
                          systemType: -1,
                        })
                      );
                    }}
                  >
                    Dachówka cementowa
                  </Button>
                  <Button
                    type={calcMaterialsData.systemType === 1 ? "primary" : "default"}
                    onClick={() => {
                      clearDataForSheets();
                      dispatch(
                        updateCalcMaterialsData({
                          ...calcMaterialsData,
                          roofSystemId: "",
                          systemType: 1,
                        })
                      );
                    }}
                  >
                    Blachodachówka modułowa
                  </Button>
                  <Button
                    type={calcMaterialsData.systemType === 2 ? "primary" : "default"}
                    onClick={() => {
                      clearDataForSheets();
                      dispatch(
                        updateCalcMaterialsData({
                          ...calcMaterialsData,
                          roofSystemId: "",
                          systemType: 2,
                        })
                      );
                    }}
                  >
                    Blachodachówka cięta
                  </Button>
                  <Button
                    type={calcMaterialsData.systemType === 3 ? "primary" : "default"}
                    onClick={() => {
                      clearDataForSheets();
                      dispatch(
                        updateCalcMaterialsData({
                          ...calcMaterialsData,
                          roofSystemId: "",
                          systemType: 3,
                        })
                      );
                    }}
                  >
                    Blacha trapezowa
                  </Button>
                  <Button
                    type={calcMaterialsData.systemType === 4 ? "primary" : "default"}
                    onClick={() => {
                      clearDataForSheets();
                      dispatch(
                        updateCalcMaterialsData({
                          ...calcMaterialsData,
                          roofSystemId: "",
                          systemType: 4,
                        })
                      );
                    }}
                  >
                    Blacha na rąbek
                  </Button>
                </div>
              </div>
            }
          >
            <RoofSystem
              selectRoofSystemId={(id: string) => {
                const currentRoofSystemId = store.getState().calcMaterialsData.roofSystemId;
                if (currentRoofSystemId !== id) {
                  dispatch(updateStatusCalcResult({ ...store.getState().calcResult, isCalculated: false }));

                  dispatch(
                    updateCalcMaterialsData({
                      ...calcMaterialsData,
                      roofSystemId: id,
                    })
                  );
                } else {
                  dispatch(
                    updateCalcMaterialsData({
                      ...calcMaterialsData,
                      roofSystemId: "",
                    })
                  );
                }

                // Czyszczenie danych arkuszy gdy zmiana systemu dachowego
                dispatch(clearRoofSlopes());
                dispatch(clearMetalSheets());
              }}
              selectedRoofSystemId={calcMaterialsData.roofSystemId}
            />
          </Section>

          <Section
            title="Wybierz system krycia wstępnego (opcjonalne)"
            extra={
              <div style={{ display: "flex" }}>
                <span style={{ marginRight: "16px" }}>
                  <b>
                    <span style={{ color: "#ff0000" }}>
                      {getNamePreCoveringSystem(
                        calcMaterialsData.preCoveringSystemId as string,
                        preCoveringSystems
                      )}
                    </span>
                  </b>
                </span>
              </div>
            }
          >
            <PreCoveringSystem
              selectPreCoveringSystemId={(id: string) => {
                const currentPreCoveringSystemId = store.getState().calcMaterialsData.preCoveringSystemId;
                if (currentPreCoveringSystemId !== id) {
                  dispatch(updateStatusCalcResult({ ...store.getState().calcResult, isCalculated: false }));
                  dispatch(updateCalcMaterialsData({ ...calcMaterialsData, preCoveringSystemId: id }));
                } else {
                  dispatch(updateCalcMaterialsData({ ...calcMaterialsData, preCoveringSystemId: "" }));
                }
              }}
              selectedPreCoveringSystemId={calcMaterialsData.preCoveringSystemId}
            />
          </Section>

          <Section
            title="Wybierz system zamocowań (opcjonalne)"
            extra={
              <div style={{ display: "flex" }}>
                <span style={{ marginRight: "16px" }}>
                  <b>
                    <span style={{ color: "#ff0000" }}>
                      {getNameFasteningSystem(calcMaterialsData.fasteningSystemId as string, fasteningSystems)}
                    </span>
                  </b>
                </span>
              </div>
            }
          >
            <FasteningSystem
              selectFasteningSystemId={(id: string) => {
                const currentFasteningSystemId = store.getState().calcMaterialsData.fasteningSystemId;
                if (currentFasteningSystemId !== id) {
                  dispatch(updateStatusCalcResult({ ...store.getState().calcResult, isCalculated: false }));
                  dispatch(updateCalcMaterialsData({ ...calcMaterialsData, fasteningSystemId: id }));
                } else {
                  dispatch(updateCalcMaterialsData({ ...calcMaterialsData, fasteningSystemId: "" }));
                }
              }}
              selectedFasteningSystemId={calcMaterialsData.fasteningSystemId}
            />
          </Section>

          <Section
            title="Informacje o oknach dachowych oraz kołnierzach"
            extra={
              <div style={{ display: "flex", gap: "16px" }}>
                <Button
                  danger
                  onClick={() => {
                    // Czyszczenie danych okien i kołnierzy
                    dispatch(resetWindowHoles());
                    dispatch(invalidateCalculation());
                  }}
                >
                  Wyczyść dobrane produkty
                </Button>

                <Tooltip
                  title={
                    <div style={{ textAlign: "center" }}>
                      Wybierz system pokrycia dachowego aby móc automatycznie dobrać okna i kołnierze!
                    </div>
                  }
                  color={PRIMARY_COLOR}
                >
                  <Button
                    type="primary"
                    disabled={calcMaterialsData.roofSystemId ? false : true}
                    onClick={() => {
                      // Pętla po wszystkich otworach
                      if (windowHoles && windowHoles.length > 0) {
                        // Czyścimy dane z poprzednich obliczeń jeśli były
                        dispatch(resetWindowHoles());

                        for (const windowHole of windowHoles) {
                          const defaultWindow = getDefaultWindowForHole(windowHole, windowIntegration);
                          if (defaultWindow && calcMaterialsData.roofSystemId) {
                            dispatch(
                              addWindowProductsToWindowHole({
                                index: windowHole.index,
                                productIds: [defaultWindow],
                              })
                            );

                            // Sprawdzamy czy jest kołnierz przygotowany do tego okna oraz systemu dachowego
                            const defaultWindowCollar = getDefaultWindowCollarForWindow(
                              defaultWindow,
                              windowIntegration,
                              calcMaterialsData.roofSystemId
                            );

                            if (defaultWindowCollar) {
                              dispatch(
                                addAdditionProductsToWindowHole({
                                  index: windowHole.index,
                                  productIds: [defaultWindowCollar],
                                })
                              );
                            }
                          }
                        }
                      }

                      dispatch(invalidateCalculation());
                    }}
                  >
                    Dobierz automatycznie okna i kołnierze
                  </Button>
                </Tooltip>
              </div>
            }
          >
            <OtherMaterialData />
          </Section>

          <Section
            title={"Wybierz system rynnowy (opcjonalne)"}
            extra={
              <div style={{ display: "flex" }}>
                <span style={{ marginRight: "16px" }}>
                  <b>
                    <span style={{ color: "#ff0000" }}>
                      {getNameGutterSystem(calcMaterialsData.gutterSystemId as string, gutterSystems)}
                    </span>
                  </b>
                </span>
              </div>
            }
          >
            <GutterSystem
              selectGutterSystemId={(id: string) => {
                const currentGutterSystemId = store.getState().calcMaterialsData.gutterSystemId;
                if (currentGutterSystemId !== id) {
                  // console.log("ZMIANA SYSTEMU ORYNNOWANIA");
                  dispatch(updateStatusCalcResult({ ...store.getState().calcResult, isCalculated: false }));

                  dispatch(updateCalcMaterialsData({ ...calcMaterialsData, gutterSystemId: id }));
                } else {
                  dispatch(updateCalcMaterialsData({ ...calcMaterialsData, gutterSystemId: "" }));
                }

                // dispatch(updateCalcMaterialsData({ ...calcMaterialsData, gutterSystemId: id }));
              }}
              selectedGutterSystemId={calcMaterialsData.gutterSystemId}
            />
          </Section>

          <AdditionalProductsSection />

          <Section title="Wartość waluty">
            <CurrencyValue />
          </Section>

          {!isContractor() && (
            <Section
              title="Wybierz system wyceny robocizny (opcjonalne)"
              style={{ pointerEvents: "none", opacity: "0.5" }}
            >
              <ContractorSystem />
            </Section>
          )}
        </>
      )}
    </Container>
  );
};

export default MaterialsStep;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)``;
