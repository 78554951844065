import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPriceUnit, IProduct, IProductUseType } from "../../models/Models";

const initialState: Array<IPriceUnit> = [];

export const priceUnitsSlice = createSlice({
  name: "priceUnits",
  initialState,
  reducers: {
    addPriceUnitsInReducer: (state, action: PayloadAction<Array<IPriceUnit>>) => {
      let e = action.payload;
      return e;
    },
  },
});

export const { addPriceUnitsInReducer } = priceUnitsSlice.actions;
export default priceUnitsSlice.reducer;
