import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBasicPoint } from "../../models/Models";

export interface IVectorSlope {
  startPoint: IBasicPoint;
  endPoint: IBasicPoint;
}

export interface IConnectPlanesState {
  vectorSlope: IVectorSlope | null; // Punkty na rysunku dla wektora spadku
  angle: number; // Kąt nachylenia dla kierunku wektora spadku (główna dana do ustalenia kierunku spadku) - na tej podstawie sa obliczane punkty dla vector
  selectedPlaneIds: string[]; // Lista wybranych połaci - lista tylko id
}

export const initialState: IConnectPlanesState = {
  vectorSlope: null,
  angle: 0,
  selectedPlaneIds: [],
};

export const connectPlanesSlice = createSlice({
  name: "connectPlanes",
  initialState,
  reducers: {
    updateConnectPlanesState: (state, action: PayloadAction<IConnectPlanesState>) => {
      const connectPlanesState = action.payload;
      return {
        ...state,
        vectorSlope: connectPlanesState.vectorSlope,
        angle: connectPlanesState.angle,
        selectedPlaneIds: [...connectPlanesState.selectedPlaneIds],
      };
    },
    clearConnectPlanesState: (state) => {
      return initialState;
    },
  },
});

export const { updateConnectPlanesState, clearConnectPlanesState } = connectPlanesSlice.actions;
export default connectPlanesSlice.reducer;
