import { Button, Card, Checkbox, Col, Collapse, Form, Image, InputNumber, Radio, Row } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { ChangeEvent, FC, memo, useEffect } from "react";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  clearRoofAccessoriesElements,
  TypeRoofAccessory,
  updateRoofAccessoryValue,
} from "../../../../../../redux/roofs/roofAccessoriesSlice";

type Props = {
  close: () => void;
  activeAddRoofAccessory: TypeRoofAccessory | null;
  setActiveAddRoofAccessory: (value: TypeRoofAccessory | null) => void;
  extended?: boolean;
};

const PanelFlowRoofAccessories: FC<Props> = memo(
  ({ close, activeAddRoofAccessory, setActiveAddRoofAccessory, extended }) => {
    const dispatch = useAppDispatch();

    const roofAccessories = useAppSelector((state) => state.roofAccessories);

    type RowComponentProps = {
      marginTop?: number;
      value: number;
      onChange: (value: number) => void;
      name: string;
      checked?: boolean;
      checkOnChange?: (value: boolean) => void;
    };

    const RowComponent: FC<RowComponentProps> = memo(
      ({ marginTop, name, checked, checkOnChange, value, onChange }) => {
        const [localValue, setLocalValue] = React.useState<number | undefined>(value);

        const handleInputChange = (value: ValueType | null) => {
          setLocalValue(value === null ? undefined : Number(value));
        };

        const handleInputBlur = () => {
          onChange(localValue === undefined ? 0 : localValue);
        };

        const handleCheckboxChange = (e: CheckboxChangeEvent) => {
          if (checkOnChange) {
            checkOnChange(e.target.checked);
          }
        };

        return (
          <GutterRow style={{ marginTop: marginTop ? marginTop : "default" }}>
            <GutterCol span={1}>
              {checkOnChange && <Checkbox checked={!!checked} onChange={handleCheckboxChange} />}
            </GutterCol>
            <GutterCol span={13} style={{ display: "flex", justifyContent: "left", paddingLeft: "16px" }}>
              {name}
            </GutterCol>
            <GutterCol span={8} style={{ display: "flex", justifyContent: "right" }}>
              <DomaInputNumber
                disabled={true}
                value={localValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
              />
            </GutterCol>
            <GutterCol span={2}>szt.</GutterCol>
          </GutterRow>
        );
      }
    );

    return (
      <Container title="Akcesoria dachowe" size="small" bodyStyle={{ padding: "0px" }}>
        <SimpleContent size="small">
          <RowComponent
            name="Stopnie dachowe"
            checked={activeAddRoofAccessory === "roofStep"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("roofStep");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.roofStep}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "roofStep", countValue: value }));
            }}
          />

          <RowComponent
            name="Ławy kominiarskie 40cm"
            checked={activeAddRoofAccessory === "chimneySweepBench40"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("chimneySweepBench40");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.chimneySweepBench40}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "chimneySweepBench40", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Ławy kominiarskie 80cm"
            checked={activeAddRoofAccessory === "chimneySweepBench80"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("chimneySweepBench80");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.chimneySweepBench80}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "chimneySweepBench80", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Ławy kominiarskie 200cm"
            checked={activeAddRoofAccessory === "chimneySweepBench200"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("chimneySweepBench200");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.chimneySweepBench200}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "chimneySweepBench200", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Ławy kominiarskie 300cm"
            checked={activeAddRoofAccessory === "chimneySweepBench300"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("chimneySweepBench300");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.chimneySweepBench300}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "chimneySweepBench300", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Płotki śniegowe 200cm"
            checked={activeAddRoofAccessory === "snowFence200"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("snowFence200");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.snowFence200}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "snowFence200", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Płoiki śniegowe 300cm"
            checked={activeAddRoofAccessory === "snowFence300"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("snowFence300");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.snowFence300}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "snowFence300", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Kominki wentylacyjne 100"
            checked={activeAddRoofAccessory === "roofVent100"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("roofVent100");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.roofVent100}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "roofVent100", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Kominki wentylacyjne 125"
            checked={activeAddRoofAccessory === "roofVent125"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("roofVent125");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.roofVent125}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "roofVent125", countValue: value }));
            }}
            marginTop={8}
          />

          <RowComponent
            name="Kominki wentylacyjne 150"
            checked={activeAddRoofAccessory === "roofVent150"}
            checkOnChange={(value) => {
              if (value) {
                setActiveAddRoofAccessory("roofVent150");
              } else {
                setActiveAddRoofAccessory(null);
              }
            }}
            value={roofAccessories.roofVent150}
            onChange={(value) => {
              dispatch(updateRoofAccessoryValue({ typeRoofAccessory: "roofVent150", countValue: value }));
            }}
            marginTop={8}
          />
        </SimpleContent>

        <Row style={{ display: "flex", justifyContent: "space-between", gap: "8px", padding: "8px" }}>
          <Button
            type="primary"
            danger
            onClick={() => {
              dispatch(clearRoofAccessoriesElements());
              dispatch(invalidateCalculation());
            }}
          >
            Wyczyść dane!
          </Button>
          <Button type="primary" onClick={() => close()}>
            OK
          </Button>
        </Row>
      </Container>
    );
  }
);
export default PanelFlowRoofAccessories;

const GutterRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const GutterCol = styled(Col)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled(Card)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 16px;
  margin-top: 66px;
  padding: 0px;
  width: 400px;
  max-height: calc(100% - 82px); // take full available height
  background-color: #f5f5f5;
  overflow-y: auto; // to enable scrolling if content exceeds panel height
  z-index: 999;
`;

const MainCollapse = styled(Collapse)`
  background-color: #f5f5f5;
`;

const ContentPanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 8px !important;
  }
`;

const SimpleContent = styled(Card)`
  background-color: #ffffff;
  padding: 0px;
  border: 0px;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
