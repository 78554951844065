import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IReportOffer, IUpdateReport } from "../../models/Models";
import { AppThunk } from "../store";

const initialState: IReportOffer[] = [];

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setReportsInReducer: (state, action: PayloadAction<IReportOffer[]>) => {
      let reports = action.payload;
      return [...reports];
    },
    addReportInReducer: (state, action: PayloadAction<IReportOffer>) => {
      let report = action.payload;
      return [...state, report];
    },
    updateFullReportInReducer: (state, action: PayloadAction<IReportOffer>) => {
      let report = action.payload;

      let index = state.findIndex((o) => o.id === report.id);
      state[index] = { ...report };
    },
    updateTypeReportInReducer: (state, action: PayloadAction<{ id: string; type: string }>) => {
      let data = action.payload;

      let index = state.findIndex((o) => o.id === data.id);
      state[index] = { ...state[index], type: data.type };
    },
    updateStatusReportInReducer: (state, action: PayloadAction<{ id: string; status: string }>) => {
      let data = action.payload;

      let index = state.findIndex((o) => o.id === data.id);
      state[index] = { ...state[index], status: data.status };
    },
    updateReportInReducer: (state, action: PayloadAction<{ id: string; active: boolean }>) => {
      let data = action.payload;

      let index = state.findIndex((o) => o.id === data.id);
      state[index] = { ...state[index], active: data.active };
    },
    updateReportDataInReducer: (state, action: PayloadAction<{ reportId: string; data: string }>) => {
      const { reportId, data } = action.payload;

      let index = state.findIndex((o) => o.id === reportId);
      state[index] = { ...state[index], data: data };
    },
    updateDataReportInReducer: (state, action: PayloadAction<{ id: string; data: string }>) => {
      let model = action.payload;
      let index = state.findIndex((o) => o.id === model.id);
      state[index] = { ...state[index], data: model.data };
    },
    deleteReportInReducer: (state, action: PayloadAction<string>) => {
      let reportId = action.payload;
      return state.filter((o) => o.id !== reportId);
    },
    clearReportsInReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setReportsInReducer,
  addReportInReducer,
  updateReportInReducer,
  updateFullReportInReducer,
  updateDataReportInReducer,
  updateReportDataInReducer,
  updateTypeReportInReducer,
  updateStatusReportInReducer,
  deleteReportInReducer,
  clearReportsInReducer,
} = reportsSlice.actions;
export default reportsSlice.reducer;

// API *****************************************

// export const getAllUserReports = () => (dispatch) => {
//     client
//       .get("/roofs/report/all")
//       .then((response) => {
//         if (response.status == 200) {
//           dispatch(addUserReportsCollectionInReducer(response.data));
//         }
//       })
//       .catch((error) => {});
//   };

// // Aktualizacja raportu
// export const updateReport =
//   (reportId: string): AppThunk =>
//   (dispatch: Dispatch<any>, getState) => {
//     const roofSystemId = getState().calcMaterialsData.roofSystemId;
//     const gutterSystemId = getState().calcMaterialsData.gutterSystemId;
//     const calcResultData = getState().calcResult.data; // Wszystkie produkty do raportu
//     const vat = getState().calcResult.vat;
//     // const activeInvestmentId = getState().general.investmentId;

//     const requestData = getState().report.requestData;

//     const appState = getStateToSave(getState()); // stan reduxa do zapisania

//     let requestModel: IUpdateReportRequestData = {
//       id: reportId,
//       projectName: requestData.projectName,
//       investor: requestData.investor,
//       phone: requestData.phone,
//       email: requestData.email,
//       zipCode: requestData.zipCode,
//       street: requestData.street,
//       houseNumber: requestData.houseNumber,
//       city: requestData.city,
//       validDays: requestData.validDays,
//       roofSystemId: roofSystemId,
//       gutterSystemId: gutterSystemId,
//       vat: vat,
//       products: calcResultData ? getProductsWithDiscount(calcResultData) : [],
//       data: JSON.stringify(appState),
//     };

//     client
//       .put("/AppReports/update", requestModel)
//       .then((response) => {
//         if (response.status == 200) {
//           console.log("UPDATED REPORT RESPONSE", response.data);

//           dispatch(updateFullReportInReducer(response.data));
//         }
//       })
//       .catch((error) => {
//         // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
//         // console.error(error);
//       });
//   };

export const updateUserReport =
  (data: IUpdateReport): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .put("/AppReports/updateActivity", data)
      .then((response) => {
        if (response.status == 204) {
          console.log("UPDATED REPORT RESPONSE", response.data);
          dispatch(updateReportInReducer({ id: data.id, active: data.active }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addProduct", type: "error", message: "Błąd dodania produktu" }));
        // console.error(error);
      });
  };

export const deleteReport =
  (reportId: string): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/AppReports/${reportId}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteReportInReducer(reportId));
          //   dispatch(addStatusApi({ key: "deleteProduct", type: "success", message: "" }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
