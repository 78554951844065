import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Line, Group, Text, Arc } from "react-konva";
import {
  ICalculationPlane,
  ICalculationPoint,
  ICalculationRequest,
  IPoint,
  IRoofPlane,
} from "../../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { getDistTwoPoints, getNearestPoint, middlePoint } from "../../../../../helpers/Helpers";
import { calculateRoof } from "../../../../../api/ApiRoof";
import OutlineEdge from "./OutlineEdge";
import { useFirstRender } from "../../../../../hooks/useFirstRender";
import { useKeyPress } from "react-use";
import store from "../../../../../redux/store";
import { IRoofProcessFunctionsStatus } from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { IRoofProcessStatus } from "../../../../../redux/drawing/roofProcessStatusSlice";

// CONSTANTS
const DIST = 10;

type Props = {
  roofProcessStatus: IRoofProcessStatus;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  scaleZoom: number;
  activeRoofPlane: IRoofPlane;
  gridPoints: IPoint[];
};

const getFlatPoints = (points: IPoint[]): number[] => points.map((p) => [p.x, p.y]).flat();

const getAcceptedFlatPoints = (points: IPoint[]): number[] => {
  return points.map((p) => [p.x, p.y]).flat();
};

const Outline: FC<Props> = ({
  roofProcessStatus,
  roofProcessFunctionsStatus,
  scaleZoom,
  activeRoofPlane,
  gridPoints,
}) => {
  const dispatch = useAppDispatch();
  const firstRender = useFirstRender();
  const ctrlPress = useKeyPress("Control");

  const roofContourNumber = useAppSelector((state) => state.roofContourNumber);

  const connectPlanes = useAppSelector((state) => state.connectPlanes);

  const mousePosition = useAppSelector((state) => state.mousePosition);
  const scale = useAppSelector((state) => state.drawingScale);
  const [shouldRecalculate, setShouldRecalculate] = useState<boolean>(false);

  const [mp, setMp] = useState<IPoint | null>(); // Wydzielone do pomocy w okresleniu najbliższego punktu w useEfffect
  const outlinePoints =
    useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber.index))?.points || [];
  const outlineEdges =
    useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber.index))?.edges || [];

  const gridSettings = useAppSelector((state) => state.domaGrid);
  const isGridSnapping = gridSettings && gridSettings.gridVisible && gridSettings.gridSnapping ? true : false;

  useEffect(() => {
    if (!firstRender) {
      // 10.10.2023 wyłączyłem shouldRecalculate bo wywoływało podówjne zapytanie do APi podczas obliczania dachu
      setShouldRecalculate(true);
    }

    console.log("Zmiana w outline edges", outlineEdges);
  }, [outlineEdges]);

  useEffect(() => {
    if (shouldRecalculate) {
      recalculateRoof();
      setShouldRecalculate(false);
    }
  }, [shouldRecalculate]);

  useEffect(() => {
    const dist = ctrlPress[0] ? 0 : DIST;
    const value = getNearestPoint(mousePosition, outlinePoints, dist, gridPoints, isGridSnapping);
    setMp(value.point);
  }, [mousePosition]);

  const getScaleFont = () => {
    return scaleZoom >= 0 ? scaleZoom : 1;
  };

  const getPointForText = () => {
    if (outlinePoints && outlinePoints.length > 0 && mp) {
      return middlePoint(outlinePoints?.at(-1) as IPoint, mp as IPoint);
    }
  };

  const getLengthForText = () => {
    if (outlinePoints && outlinePoints.length > 0 && mp) {
      const px = getDistTwoPoints(outlinePoints?.at(-1) as IPoint, mp as IPoint);
      const val = px / scale;

      return `${val.toFixed(2)}`;
    }
  };

  const recalculateRoof = () => {
    const points: ICalculationPoint[] = [];
    const planes: ICalculationPlane[] = [];

    const stateOutline = store.getState().outline;

    let i = 1;
    for (const outline of stateOutline) {
      if (outline.points.length > 0) {
        for (const p of outline.points) {
          // Obniżenie punktów dla każdego kolejnego konturu
          var z = 0;
          if (i > 1) {
            z = -1 * 0.5 * scale;
          }

          points.push({ x: p.x, y: p.y, z: p.z ? p.z : z });
        }
      }
      i++;
    }

    for (const outline of stateOutline) {
      for (const e of outline.edges) {
        const eavevectorPoints = e.eaveVectorPoints;

        let vx = 0;
        let vy = 0;

        // Sprawdzamy czy eavevectorPoints[0] oraz eavevectorPoints[1] są number
        const isEaveVectorPointsNumber = eavevectorPoints.every((o) => !isNaN(Number(o)));

        if (isEaveVectorPointsNumber) {
          const cevtX = Number(eavevectorPoints[0]) || 0;
          const cevtY = Number(eavevectorPoints[1]) || 0;

          if (cevtX && cevtY) {
            vx = cevtX;
            vy = cevtY;
          }
        } else if (eavevectorPoints) {
          const firstPoint = outline.points.find((o) => o.id === eavevectorPoints[0]);
          const secondPoint = outline.points.find((o) => o.id === eavevectorPoints[1]);

          let vector = {
            x: (secondPoint?.x as number) - (firstPoint?.x as number),
            y: (secondPoint?.y as number) - (firstPoint?.y as number),
          };

          vx = vector.x;
          vy = vector.y;
        }

        planes.push({
          index: outline.roofNumber,
          edgeCount: e.points.length - 1,
          x: vx,
          y: vy,
          angle: e.slope as number,
          dH: 0,
        }); // TODO angle
      }
    }

    const calculationRequestModel: ICalculationRequest = {
      points: points,
      planes: planes,
    };

    dispatch(calculateRoof(calculationRequestModel));
  };

  const getLastPoint = () => {
    return outlinePoints?.at(-1);
  };

  const calculateAngle = (p1: any, p2: any, p3: any) => {
    const v1 = { x: p2.x - p1.x, y: p2.y - p1.y };
    const v2 = { x: p3.x - p2.x, y: p3.y - p2.y };
    const angleRad = Math.atan2(v2.y, v2.x) - Math.atan2(v1.y, v1.x);
    let angleDeg = angleRad * (180 / Math.PI);
    if (angleDeg < 0) {
      angleDeg += 360;
    }
    return angleDeg; // Kąt w stopniach
  };

  return (
    <Container>
      {mp && outlinePoints && (
        <Group onMouseOver={(e) => e.evt.stopPropagation()}>
          <Line
            points={
              roofProcessFunctionsStatus.inDrawOutline
                ? getFlatPoints([...outlinePoints, mp as IPoint])
                : getFlatPoints([...outlinePoints])
            }
            closed={true}
            stroke="black"
            strokeWidth={0.5}
            listening={false}
          />

          {/* To pojawia się po zamknięciu obrysu dachu */}
          {outlineEdges &&
            connectPlanes.selectedPlaneIds.length === 0 &&
            outlineEdges.map((o, i) => (
              <OutlineEdge
                key={o.id}
                edge={o}
                roofProcessStatus={roofProcessStatus}
                roofProcessFunctionsStatus={roofProcessFunctionsStatus}
                scaleZoom={scaleZoom}
                scale={scale}
                setShouldRecalculate={setShouldRecalculate}
                isEaveVector={true}
              />
            ))}

          {/* W trakcie rysowania pojawia się ta linia */}
          {outlineEdges.length === 0 && (
            <Line
              points={getAcceptedFlatPoints([...outlinePoints, mp as IPoint])}
              closed={roofProcessFunctionsStatus.inDrawOutline ? false : true}
              stroke="#ff0000cc"
              strokeWidth={4}
            />
          )}

          {/* Wymiar przy aktualnie rysowanej krawędzi */}
          {roofProcessFunctionsStatus.inDrawOutline && (
            <Text
              fontStyle="bold"
              fontSize={14 / getScaleFont()}
              x={getPointForText()?.x}
              y={getPointForText()?.y}
              text={getLengthForText()}
            />
          )}

          {/* <Arc
            x={getLastPoint()?.x}
            y={getLastPoint()?.y}
            innerRadius={0}
            outerRadius={50}
            angle={calculateAngle(outlinePoints?.at(-2), outlinePoints?.at(-1), mp)}
            fill={"yellow"}
            stroke={"black"}
            strokeWidth={1}
            rotation={
              Math.atan2(
                (outlinePoints?.at(-1)?.y || 0) - (outlinePoints?.at(-2)?.y || 0),
                (outlinePoints?.at(-1)?.x || 0) - (outlinePoints?.at(-2)?.x || 0)
              ) *
              (180 / Math.PI)
            }
          /> */}
        </Group>
      )}
    </Container>
  );
};

export default Outline;

const Container = styled(Group)``;
