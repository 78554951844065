import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatusApi } from "../../models/Models";

export interface ILoadingState {
  loading: boolean;
}

const initialState: ILoadingState = { loading: false };

export const loadingStateSlice = createSlice({
  name: "loadingState",
  initialState,
  reducers: {
    startLoading: (state) => {
      return { ...state, loading: true };
    },
    endLoading: (state) => {
      return { ...state, loading: false };
    },
  },
});

export const { startLoading, endLoading } = loadingStateSlice.actions;
export default loadingStateSlice.reducer;
