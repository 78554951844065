import { Button, Card, Checkbox, Form, InputNumber, Radio, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import { set } from "immer/dist/internal";
import Konva from "konva";
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Circle, Group, Layer, Line, Stage } from "react-konva";
import { Html } from "react-konva-utils";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../../../theme";
import { calculateRoofMetal, ICalcRoofMetalRequestModel } from "../../../../../../api/ApiRoof";
import DSpin from "../../../../../../helpers/DSpin";
import { IMetalSheet, IRoofHole, IRoofSlopeHole, IRoofSlopePoint } from "../../../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { clearMetalSheets } from "../../../../../../redux/roofMetal/metalSheetsSlice";
import { clearRoofSlopes } from "../../../../../../redux/roofMetal/roofSlopesSlice";
import EditMetalSheetModal from "./EditMetalSheetModal";
import MetalSheetComponent from "./MetalSheetComponent";
import PreviewMetalSheets from "./PreviewMetalSheets";
import VerificationMaterialsRoofMiniView from "./VerificationMaterialsRoofMiniView";

// CONSTANTS
const DIST = 5;
const SCALE_BY = 1.1;
const LEFT_MOUSE = 0;
const SCROLL_MOUSE = 1;
const RIGHT_MOUSE = 2;

type Props = {};

const VerificationMaterials: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  const [form] = useForm();

  const roofSlopes = useAppSelector((state) => state.roofSlopes) || [];
  const metalSheets = useAppSelector((state) => state.metalSheets) || [];

  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);
  const steelSystems = useAppSelector((state) => state.steelSystems) || [];

  const [activeRoofSlopeId, setActiveRoofSlopeId] = useState<string>("");

  const [activeCalcAgain, setActiveCalcAgain] = useState<boolean>(false);
  const [activeUserhHeight, setActiveUserhHeight] = useState<boolean>(false);

  const [activePreviewSheetsModal, setActivePreviewSheetsModal] = useState<boolean>(false); // Modal z podglądem arkuszy blach

  const [activeEditMetalSheetModal, setActiveEditMetalSheetModal] = useState<boolean>(false);
  const [selectedMetalSheet, setSelectedMetalSheet] = useState<IMetalSheet | null>(null); // Wybrany metalSheet do edycji

  const [waitingApi, setWaitingApi] = useState<boolean>(true);

  useEffect(() => {
    if (waitingApi) {
      if (metalSheets && metalSheets.length > 0) {
        setWaitingApi(false);
      }
    }
  }, [metalSheets]);

  // Funkcja sprawdza czy zaznaczone jest system z pokrycia blachodachówką i czy ma zaznaczone połówki
  const isSelectedSystemIsSteelWithHalfAvailable = () => {
    const steelSystem = steelSystems.find((x) => x.id === calcMaterialsData.roofSystemId);
    if (steelSystem && steelSystem.typeSteelSystem === 1) {
      return steelSystem.halfAvailable;
    }

    return false;
  };

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  useEffect(() => {
    if (roofSlopes && roofSlopes.length > 0) {
      const firstRoofSlope = roofSlopes[0];
      if (firstRoofSlope && firstRoofSlope.id) {
        setActiveRoofSlopeId(firstRoofSlope.id);
      }
    }
  }, [roofSlopes]);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, [stageParent.current]);

  const getButtonsForRoofSlopes = () => {
    const tags = [];

    for (const rs of roofSlopes) {
      tags.push(
        <RoofSlopeTag key={rs.id} onClick={() => setActiveRoofSlopeId(rs.id)}>
          Połać {rs.number}
        </RoofSlopeTag>
      );
    }
    return tags;
  };

  const getMetalSheetsForActiveRoofSlope = () => {
    if (!metalSheets) return [];

    return metalSheets.filter((x) => x.roofSlopeId === activeRoofSlopeId);
  };

  const getPointsForLine = (points: any[]) => {
    let arr = [];
    for (const p of points) {
      if (p) {
        arr.push(p.x);
        arr.push(p.y);
      }
    }

    return arr;
  };

  const getPointsForLineComponentHole = (hole: IRoofSlopeHole) => {
    let arr: any[] = [];
    if (hole) {
      const pointsArr = getPointsForLine(movePoints(hole.points));

      return pointsArr;
    }

    return arr;
  };

  const movePoints = (points: any[]) => {
    const center = getCenterForRoofSlope();

    const diffX = dimStageParent.width / 2 + center.x;
    const diffY = dimStageParent.height / 2 - center.y;

    const newPoints = [];

    for (const p of points) {
      newPoints.push({ ...p, x: p.x - diffX, y: p.y + diffY });
    }

    return newPoints;
  };

  const getLinesForRoofSlope = () => {
    const roofSlope = roofSlopes.find((x) => x.id === activeRoofSlopeId);
    if (!roofSlope?.holes) return [];

    const points = movePoints(roofSlope.roofSlopePoints);

    return (
      <Line points={getPointsForLine(points)} fill="#000000cc" stroke="#000000cc" closed={true} strokeWidth={0} />
    );
  };

  const getLinesForRoofSlopeHole = (points: any[], id: string) => {
    return <Line key={id} points={points} fill="#FFFFFF" stroke="#000000" closed={true} strokeWidth={2} />;
  };

  const getHolesForRoofSlope = () => {
    let arr = [];

    if (!activeRoofSlopeId) return [];
    const roofSlope = roofSlopes.find((x) => x.id === activeRoofSlopeId);
    if (!roofSlope?.holes) return [];

    if (roofSlope) {
      if (roofSlope.holes && roofSlope.holes.length > 0) {
        for (const h of roofSlope?.holes) {
          arr.push(h);
        }
      }
    }

    return arr;
  };

  // Oblicz środek geometryczny połaci
  const calculateGeometricCenter = (points: { x: number; y: number }[]) => {
    const totalPoints = points.length;

    if (totalPoints === 0) {
      throw new Error("List of points cannot be empty");
    }

    const sum = points.reduce(
      (acc, point) => {
        acc.x += point.x;
        acc.y += point.y;
        return acc;
      },
      { x: 0, y: 0 }
    );

    return {
      x: sum.x / totalPoints,
      y: sum.y / totalPoints,
    };
  };

  const getCenterForRoofSlope = () => {
    const roofSlope = roofSlopes.find((x) => x.id === activeRoofSlopeId);

    if (roofSlope) {
      const selectedMtalSheets = metalSheets.filter((x) => x.roofSlopeId === activeRoofSlopeId);

      const pointsMetalSheets = selectedMtalSheets.map((x) => {
        return { x: x.x, y: x.y };
      });

      return calculateGeometricCenter([...roofSlope.roofSlopePoints, ...pointsMetalSheets]);
    }

    return { x: 0, y: 0 };
  };

  function zoomStage(event: any) {
    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScaleX = s.scaleX();
      const oldScaleY = s.scaleY();
      const scaleFactor = event.evt.deltaY > 0 ? 1 / SCALE_BY : SCALE_BY;

      const newScaleX = oldScaleX * scaleFactor;
      const newScaleY = oldScaleY * scaleFactor;

      // Obliczenia uwzględniające odwrócenie skali
      // Załóżmy, że scaleX i scaleY mają wartości -1 lub 1
      const pointer = s.getPointerPosition();
      if (pointer) {
        const mousePointTo = {
          x: (pointer.x - s.x()) / oldScaleX,
          y: (pointer.y - s.y()) / oldScaleY,
        };

        s.scale({ x: newScaleX, y: newScaleY });

        const newPos = {
          x: pointer.x - mousePointTo.x * newScaleX,
          y: pointer.y - mousePointTo.y * newScaleY,
        };
        s.position(newPos);
      }

      s.batchDraw();
      setZoomScale(newScaleX); // Uwaga: Możesz potrzebować oddzielnych stanów dla scaleX i scaleY
    }
  }

  // function zoomStage(event: any) {
  //   if (event.evt.ctrlKey && stage.current !== null) {
  //     event.evt.preventDefault();

  //     const s = stage.current;
  //     const oldScale = s.scaleX();
  //     const pointerX = s.getPointerPosition()?.x as number;
  //     const pointerY = s.getPointerPosition()?.y as number;
  //     const mousePointTo = {
  //       x: (pointerX - s.x()) / oldScale,
  //       y: (pointerY - s.y()) / oldScale,
  //     };
  //     const newScale =
  //       event.evt.deltaY > 0 ? Number((oldScale / SCALE_BY).toFixed(1)) : Number((oldScale * SCALE_BY).toFixed(1));
  //     s.scale({ x: newScale, y: newScale });
  //     const newPos = {
  //       x: pointerX - mousePointTo.x * newScale,
  //       y: pointerY - mousePointTo.y * newScale,
  //     };
  //     s.position(newPos);
  //     s.batchDraw();

  //     setZoomScale(newScale);
  //   }
  // }

  // TODO
  const getInitialValues = () => {
    return {
      layingDirection: 3,
      layingMethod: 1,
      overlapModuleCount: 1,
      holeAddition: 0.5,
      tolerance: 0.15,
      userHeight: 3,
    };
  };

  // Gdy zmieni się cokolwiek w FORM to należy ponownie obliczyć blachę
  const onChangeForm = (changedValues: any, allValues: any) => {
    setActiveCalcAgain(true);

    if (allValues.layingMethod === 3 && calcMaterialsData.systemType === 2) {
      setActiveUserhHeight(true);
    } else {
      setActiveUserhHeight(false);
    }
  };

  const getMetalSheetsCalculation = (allSlopes: boolean) => {
    setWaitingApi(true);

    // // Czyścimy dane z poprzednich obliczeń jeśli były
    // dispatch(clearRoofSlopes());
    // dispatch(clearMetalSheets());

    const { layingDirection, layingMethod, overlapModuleCount, holeAddition, tolerance, userHeight } =
      form.getFieldsValue();

    // Pobieramy połać aktywną (dla odwrócenia kierunku układnaia)
    const roofSlope = roofSlopes.find((x) => x.id === activeRoofSlopeId);

    const model: ICalcRoofMetalRequestModel = {
      layingDirection: layingDirection,
      layingMethod: layingMethod,
      overlapModuleCount: overlapModuleCount,
      holeAddition: holeAddition,
      tolerance: tolerance,
      userHeight: userHeight,
      systemId: calcMaterialsData.roofSystemId || "",
      systemType: calcMaterialsData.systemType,
      selectedRoofSlopeId: allSlopes ? undefined : roofSlope ? roofSlope.number : -1,
    };

    dispatch(calculateRoofMetal(model, allSlopes));

    if (activeCalcAgain) {
      setActiveCalcAgain(false);
    }
  };

  return (
    <Container>
      {activePreviewSheetsModal && (
        <PreviewMetalSheets
          onOk={() => setActivePreviewSheetsModal(false)}
          onCancel={() => setActivePreviewSheetsModal(false)}
          onEdidMetalSheet={(o: IMetalSheet | null) => {
            setSelectedMetalSheet(o);
            setActiveEditMetalSheetModal(true);
          }}
        />
      )}

      {activeEditMetalSheetModal && (
        <EditMetalSheetModal
          onOk={() => setActiveEditMetalSheetModal(false)}
          onCancel={() => setActiveEditMetalSheetModal(false)}
          metalSheet={selectedMetalSheet}
        />
      )}

      <Section bodyStyle={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Form
            form={form}
            style={{ display: "flex", width: "100%", gap: "16px", justifyContent: "left" }}
            initialValues={getInitialValues()}
            onValuesChange={onChangeForm}
          >
            {/* Dla wszystkich typów blach */}
            <Form.Item label="Kierunek układania" name="layingDirection" labelCol={{ span: 24 }}>
              <StyledRadioGroup>
                <Radio.Button value={3}>Od lewej</Radio.Button>
                <Radio.Button value={1}>Od prawej</Radio.Button>
              </StyledRadioGroup>
            </Form.Item>

            {/* Tylke dla typu 1 */}
            {calcMaterialsData.systemType === 1 && (
              <Form.Item label="Sposób układania" name="layingMethod" labelCol={{ span: 24 }}>
                <StyledRadioGroup>
                  <Radio.Button value={1}>Równo</Radio.Button>
                  <Radio.Button value={2}>Cegiełka</Radio.Button>

                  {/* To będzie aktywne tylko wtedy gdy system posiada połówkową  */}
                  <Radio.Button value={3} disabled={!isSelectedSystemIsSteelWithHalfAvailable()}>
                    Połówkowa
                  </Radio.Button>
                </StyledRadioGroup>
              </Form.Item>
            )}

            {/* Tylke dla typu 2 */}
            {calcMaterialsData.systemType === 2 && (
              <Form.Item
                label="Ilość modułów na zakład blach"
                name="overlapModuleCount"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    type: "integer",
                    message: "Tylko liczby całkowite!",
                  },
                ]}
              >
                <InputNumber min={0} max={100} step={1} />
              </Form.Item>
            )}
            {/* Tylke dla typu 2 */}
            {calcMaterialsData.systemType === 2 && (
              <Form.Item label="Sposób układania" name="layingMethod" labelCol={{ span: 24 }}>
                <StyledRadioGroup>
                  <Radio.Button value={1}>Równe elementy</Radio.Button>
                  <Radio.Button value={2}>Wysokość max + reszta</Radio.Button>
                  <Radio.Button value={3} disabled>
                    Wysokość użytkownika
                  </Radio.Button>
                </StyledRadioGroup>
              </Form.Item>
            )}
            {/* Tylke dla typu 2 i tylko gdy zaznaczona 3 opcja*/}
            {calcMaterialsData.systemType === 2 && activeUserhHeight && (
              <Form.Item label="Wysokość blachy" name="userHeight" labelCol={{ span: 24 }}>
                <InputNumber />
              </Form.Item>
            )}

            {/* Tylke dla typu 3 */}
            {calcMaterialsData.systemType === 3 && (
              <Form.Item label="Nadmiar materiału na otwór" name="holeAddition" labelCol={{ span: 24 }}>
                <InputNumber />
              </Form.Item>
            )}

            {/* Dla wszystkich typów */}
            <Form.Item label="Tolerancja wymiarów" name="tolerance" labelCol={{ span: 24 }}>
              <InputNumber />
            </Form.Item>
          </Form>
        </div>

        <div>
          <Button
            disabled={!activeCalcAgain}
            type="primary"
            style={{ width: "100%" }}
            onClick={() => getMetalSheetsCalculation(true)}
          >
            Wykonaj obliczenia dla <b> wszystkich połaci</b>
          </Button>
        </div>
      </Section>
      <Section>
        <StageParent ref={stageParent}>
          <LeftTopButtonContainer>
            <Button
              type="default"
              style={{ width: "100%", marginTop: "8px" }}
              onClick={() => getMetalSheetsCalculation(false)}
            >
              Odwróć kierunek układania arkuszy
            </Button>
          </LeftTopButtonContainer>
          {!waitingApi && (
            <MiniViewContainer>
              <VerificationMaterialsRoofMiniView
                activeRoofSlopeId={activeRoofSlopeId}
                setActiveRoofSlopeId={(id: any) => {
                  setActiveRoofSlopeId(id);
                }}
              />
              <StyledButtonToOpenSheets type="primary" onClick={() => setActivePreviewSheetsModal(true)}>
                Zestawienie arkuszy blach
              </StyledButtonToOpenSheets>
            </MiniViewContainer>
          )}

          <Stage
            ref={stage}
            height={900}
            scaleY={-1}
            y={900}
            width={dimStageParent.width}
            style={{ margin: "0", background: "#E8E8E8", borderRadius: "8px", overflow: "hidden" }}
            onContextMenu={(e) => {
              e.evt.preventDefault();
            }}
            draggable
            onWheel={zoomStage}
          >
            <Layer scaleX={-1}>
              {getLinesForRoofSlope()}
              {getHolesForRoofSlope().map((o) => {
                return getLinesForRoofSlopeHole(getPointsForLineComponentHole(o), o.id);
              })}
              {getMetalSheetsForActiveRoofSlope().map((o) => {
                return (
                  <MetalSheetComponent
                    key={o.id}
                    zoomScale={zoomScale}
                    metalSheet={o}
                    startPoint={movePoints([{ x: o.x, y: o.y }])}
                    onClickEdit={() => {
                      setSelectedMetalSheet(o);
                      setActiveEditMetalSheetModal(true);
                    }}
                  />
                );
              })}
            </Layer>
          </Stage>

          {waitingApi && (
            <MaskOverlay
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <DomaSpin />
            </MaskOverlay>
          )}
        </StageParent>
      </Section>
    </Container>
  );
};
export default VerificationMaterials;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  width: 100%;
`;

const RoofSlopeTag = styled(Button)`
  font-weight: bold;
`;

const StageParent = styled.div``;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;

const MiniViewContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 100;
`;

const LeftTopButtonContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 32px;
  z-index: 100;
`;

const StyledButtonToOpenSheets = styled(Button)`
  width: 100%;
  margin-top: 8px;
`;

const MaskOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer !important;
  pointer-events: none;
`;

const DomaSpin = styled(DSpin)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
