import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Collapse, List } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import { getEdgeNameByType } from "../../../../../../helpers/Helpers";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { saveDrawing } from "../../../../../../redux/roofs/roofDrawingStoreSlice";
import { removeRSOEdge, updateRSOEdge } from "../../../../../../redux/roofSlopeOutline/rsoEdgesSlice";
import store from "../../../../../../redux/store";
import { IRSOEdge } from "./Models/RoofSlopeOutlineModels";
import { v4 as uuidv4 } from "uuid";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";

type Props = { openEditEdgeModal: (item: IRSOEdge) => void };

const RSOEdgesTable: FC<Props> = ({ openEditEdgeModal }) => {
  const dispatch = useAppDispatch();
  const rsoEdges = useAppSelector((state) => state.rsoEdges) || [];
  const rsoLinkedEdges = useAppSelector((state) => state.rsoLinkedEdges) || [];

  return (
    <List
      size="small"
      dataSource={rsoEdges}
      renderItem={(item) => (
        <StyledListItem
          hover={item.hover}
          done={item.type !== 99}
          onMouseEnter={(e) => {
            dispatch(updateRSOEdge({ ...item, hover: true }));
          }}
          onMouseLeave={(e) => {
            dispatch(updateRSOEdge({ ...item, hover: false }));
          }}
        >
          <ItemContainer>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Checkbox
                checked={item.active}
                onChange={(e) => {
                  dispatch(updateRSOEdge({ ...item, active: !item.active }));
                  dispatch(invalidateCalculation());
                }}
              />
              <RowsContainer>
                <TitleRow>Krawędź {item.calcId > 0 ? item.calcId : "X"}</TitleRow>
                <SubTitleRow>{getEdgeNameByType(item.type)}</SubTitleRow>
              </RowsContainer>
            </div>

            <div style={{ display: "flex", gap: "8px" }}>
              <Button
                size="small"
                onClick={() => {
                  dispatch(removeRSOEdge(item));
                  dispatch(saveDrawing(store.getState())); // Zapis nowego stanu !!!

                  dispatch(invalidateCalculation());
                }}
                danger
              >
                <DeleteOutlined />
              </Button>
              <Button size="small" onClick={() => openEditEdgeModal(item)}>
                <EditOutlined />
              </Button>
            </div>
          </ItemContainer>
        </StyledListItem>
      )}
    />
  );
};
export default RSOEdgesTable;

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StyledListItem = styled(List.Item)<{ hover: boolean; done: boolean }>`
  background-color: ${(props) => (props.hover ? "#c4c4c4" : props.done ? "#ffffff" : "#FF0000cc")};
  cursor: pointer;
`;

const RowsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
`;

const TitleRow = styled.div`
  width: 100%;
  font-size: 14px;
`;

const SubTitleRow = styled.div`
  width: 100%;
  font-size: 12px;
  color: #7a7a7a;
`;
