import { Dispatch } from "@reduxjs/toolkit";
import client, { TOKEN_KEY_NAME } from "../../../api/ApiClient";
import { IAuth, ISignIn } from "../../../models/Models";
import { logOut } from "../../actions";
import { setExternalGutterSystemsInReducer } from "../../external/externalGutterSystemsSlice";
import { setExternalPrincingSystemsInReducer } from "../../external/externalPrincingSystemsSlice";
import { setExternalTileSystemsInReducer } from "../../external/externalTileSystemsSlice";
import { setInvestmentsInReducer } from "../../general/investmentsSlice";
import { addProductCategoriesInReducer } from "../../general/productCategoriesSlice";
import { addProductColorsInReducer } from "../../general/productColorsSlice";
import { setProductModelsReducer } from "../../general/productModelsSlice";
import { addProductsInReducer } from "../../general/productsSlice";
import { setReportsInReducer } from "../../general/reportsSlice";
import { endLoading, startLoading } from "../../loading/loadingStateSlice";
import { AppDispatch } from "../../store";
import { addMessageInReducer, addTokenInReducer, dispatchAllInitialData } from "../authSlice";

// SignIn and get data
export const signIn = (signIn: ISignIn) => (dispatch: Dispatch<any>) => {
  dispatch(startLoading());
  client
    .post("/Users/sign-in", signIn)
    .then((response) => {
      // console.log(response);
      if (response.status == 200) {
        let data = response.data;

        localStorage.setItem(TOKEN_KEY_NAME, JSON.stringify(data.profile.accessToken));
        dispatchAllInitialData(data, dispatch);

        const auth: IAuth = { token: data.profile.accessToken, messageType: "success", message: "" };
        dispatch(addTokenInReducer(auth));

        dispatch(endLoading());

        dispatch(getAllProducts());
        dispatch(getAllReports());
        dispatch(getAllProductCategories());
        dispatch(getAllProductColors());
        dispatch(getAllProductModels());
        dispatch(getAllInvestments());
        dispatch(getExternalSystems());
      }
    })
    .catch((error) => {
      const auth: IAuth = { token: "", messageType: "error", message: "Błędna nazwa konta lub hasło." };
      dispatch(addMessageInReducer(auth));
      dispatch(endLoading());
    });
};

// Only get data
export const getUserData = (token: string) => (dispatch: Dispatch<any>) => {
  client
    .get("/Users")
    .then((response) => {
      // console.log(response);
      if (response.status == 200) {
        let data = response.data;

        dispatchAllInitialData(data, dispatch);

        const obj: IAuth = { token: token, messageType: "success", message: "" };
        dispatch(addTokenInReducer(obj));

        dispatch(getAllProducts());
        dispatch(getAllReports());
        dispatch(getAllProductCategories());
        dispatch(getAllProductColors());
        dispatch(getAllProductModels());
        dispatch(getAllInvestments());
        dispatch(getExternalSystems());
      }
    })
    .catch((error) => {
      dispatch(logOut());
    });
};

export const getExternalSystems = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/getExternalSystems");
    if (response.status === 200) {
      dispatch(setExternalTileSystemsInReducer(response.data.tileSystems));
      dispatch(setExternalGutterSystemsInReducer(response.data.gutterSystems));
      dispatch(setExternalPrincingSystemsInReducer(response.data.princingSystems));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllInvestments = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/allInvestments");
    if (response.status === 200) {
      dispatch(setInvestmentsInReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllProductModels = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/allProductModels");
    if (response.status === 200) {
      dispatch(setProductModelsReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllProductColors = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/allProductColors");
    if (response.status === 200) {
      dispatch(addProductColorsInReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllProductCategories = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/allProductCategories");
    if (response.status === 200) {
      dispatch(addProductCategoriesInReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllProducts = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/allProducts");
    if (response.status === 200) {
      console.log(response.data);
      dispatch(addProductsInReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllReports = () => async (dispatch: AppDispatch) => {
  try {
    const response = await client.get("/Users/allReports");
    if (response.status === 200) {
      dispatch(setReportsInReducer(response.data));
    }
  } catch (error) {
    console.error(error);
  }
};

// SignUp and go to SignIn page if return 201
export const signUp = (user: ISignIn) => (dispatch: Dispatch) => {
  client
    .post("/Users/sign-up", user)
    .then((response) => {
      if (response.status == 201) {
        const obj: IAuth = {
          token: "",
          messageType: "success",
          message: "Dziękujemy za rejestrację. Teraz możesz sie zalogować.",
        };

        dispatch(addTokenInReducer(obj));
      }
    })
    .catch((error) => {
      const auth: IAuth = {
        token: "",
        messageType: "error",
        message: "Błędna nazwa konta lub hasło. Spróbuj ponownie wprowadzić prawidłowe dane.",
      };
      dispatch(addMessageInReducer(auth));
    });
};
