import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Col, Form, Input, List, Radio, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { DOMA_LOGO_COLOR_A, PRIMARY_COLOR, TEXT_COLOR } from "../../../../../../theme";
import DomaLogoComponent from "../../../../../../components/SVGComponents/DomaLogoComponent";
import { getFilteredSystems, getSystemImg } from "../../../../../../helpers/Helpers";
import { IRootState, ISteelSystem, ITileSystem } from "../../../../../../models/Models";
import { IExternalTileSystem } from "../../../../../../redux/external/externalTileSystemsSlice";
import { useAppSelector } from "../../../../../../redux/hooks";
import SystemCard from "./SystemCard";
import SystemFilteringComponent from "./SystemFilteringComponent";

type Props = {
  selectRoofSystemId: any;
  selectedRoofSystemId: string | null;
};

const RoofSystem: FC<Props> = ({ selectRoofSystemId, selectedRoofSystemId }) => {
  const tileSystemsState = useAppSelector((state: IRootState) => state.tileSystems) || [];
  const steelSystemsState = useAppSelector((state: IRootState) => state.steelSystems) || [];
  const externalTileSystemsState = useAppSelector((state) => state.externalTileSystems) || [];
  // const externalSteelSystems = useAppSelector((state) => state.externalSteelSystems) || [];

  const [tileSystems, setTileSystems] = useState<ITileSystem[]>(tileSystemsState);
  const [externalTileSystems, setExternalTileSystems] = useState<IExternalTileSystem[]>(externalTileSystemsState);
  const [steelSystems, setSteelSystems] = useState<ISteelSystem[]>(steelSystemsState);

  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);

  const [searchResults, setSearchResults] = useState<ITileSystem[] | ISteelSystem[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const filtersForSystems = useAppSelector((state) => state.filtersForSystems);

  const [view, setView] = useState<"list" | "cards">("cards");

  useEffect(() => {
    if (selectedRoofSystemId) {
      const systems = [...tileSystemsState].sort((a, b) => {
        if (a.id === selectedRoofSystemId) return -1;
        if (b.id === selectedRoofSystemId) return 1;
        return 0;
      });
      setTileSystems(systems);
    } else {
      setTileSystems(tileSystemsState);
    }
  }, [tileSystemsState]);

  useEffect(() => {
    if (selectedRoofSystemId) {
      const systems = [...externalTileSystemsState].sort((a, b) => {
        if (a.id === selectedRoofSystemId) return -1;
        if (b.id === selectedRoofSystemId) return 1;
        return 0;
      });
      setExternalTileSystems(systems);
    } else {
      setExternalTileSystems(externalTileSystemsState);
    }
  }, [externalTileSystemsState]);

  useEffect(() => {
    if (selectedRoofSystemId) {
      const systems = [...steelSystemsState].sort((a, b) => {
        if (a.id === selectedRoofSystemId) return -1;
        if (b.id === selectedRoofSystemId) return 1;
        return 0;
      });
      setSteelSystems(systems);
    } else {
      setSteelSystems(steelSystemsState);
    }
  }, [steelSystemsState]);

  const getAllSystemsByNumber = (systemNumber: number) => {
    let allTileSystems = [...tileSystems, ...externalTileSystems];

    if (systemNumber === 0) {
      return [...allTileSystems.filter((s) => s.typeTileSystem === 1)];
    } else if (systemNumber === -1) {
      return [...allTileSystems.filter((s) => s.typeTileSystem === 2)];
    } else if (systemNumber === 1) {
      return [...steelSystems.filter((s) => s.typeSteelSystem === 1)];
    } else if (systemNumber === 2) {
      return [...steelSystems.filter((s) => s.typeSteelSystem === 2)];
    } else if (systemNumber === 3) {
      return [...steelSystems.filter((s) => s.typeSteelSystem === 3)];
    } else if (systemNumber === 4) {
      return [...steelSystems.filter((s) => s.typeSteelSystem === 4)];
    }

    return [];
  };

  const search = (text: string, systems: any[]) => {
    let result: any[] = [];

    if (text.length > 0) {
      // Dzielimy wprowadzony tekst na słowa, ignorując wielkość liter
      // Używamy wyrażenia regularnego, aby podzielić tekst według spacji, przecinka, średnika lub dwukrotnego wystąpienia spacji
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      for (const p of getFilteredSystems(systems, filtersForSystems.filterForRoofSystem)) {
        // Sprawdzamy, czy każde słowo występuje w ciągu znaków 'name'
        const matched = inputWords.every((word) => p.name.toLowerCase().includes(word));

        if (matched) {
          result = [...result, p];
        }
      }
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <Container>
      {/* Komponent do filtrowania systemów */}
      <SystemFilteringComponent typeSystem="roof" />

      <Form.Item label="Znajdź system dachowy" labelCol={{ span: 24 }} style={{ marginBottom: "32px" }}>
        <SearchInput
          placeholder="Wpisz nazwę systemu dachowego..."
          onChange={(e) => {
            search(e.target.value, getAllSystemsByNumber(calcMaterialsData.systemType));
            setSearchText(e.target.value);
          }}
          allowClear
        />
      </Form.Item>

      <Row style={{ display: "flex", width: "100%", justifyContent: "right" }}>
        <StyledRadioGroup style={{ marginTop: "16px" }} value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button value="list">
            <BarsOutlined />
          </Radio.Button>
          <Radio.Button value="cards">
            <AppstoreOutlined />
          </Radio.Button>
        </StyledRadioGroup>
      </Row>

      <Systems>
        {view === "cards" ? (
          <SystemList
            grid={{
              xxl: 3,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
            dataSource={
              searchText.length > 0
                ? searchResults
                : getFilteredSystems(
                    getAllSystemsByNumber(calcMaterialsData.systemType),
                    filtersForSystems.filterForRoofSystem
                  )
            }
            renderItem={(item: any, index) => (
              <List.Item style={{ margin: "8px", padding: "0px" }}>
                <SystemCard
                  key={item.id}
                  system={item}
                  selectSystem={(id: string) => selectRoofSystemId(id)}
                  selected={selectedRoofSystemId === item.id ? true : false}
                />
              </List.Item>
            )}
            pagination={{
              pageSize: 9,
            }}
          />
        ) : (
          <List
            dataSource={
              searchText.length > 0
                ? searchResults
                : getFilteredSystems(
                    getAllSystemsByNumber(calcMaterialsData.systemType),
                    filtersForSystems.filterForRoofSystem
                  )
            }
            pagination={{
              pageSize: 20,
            }}
            renderItem={(item: any, index) => (
              <ContainerListItem
                selected={selectedRoofSystemId === item.id ? true : false}
                onClick={() => selectRoofSystemId(item.id)}
                style={{ cursor: "pointer" }}
              >
                <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                  {item.imageUrl && item.imageUrl !== "" ? (
                    <CardImage alt={item.name} src={getSystemImg(item)} />
                  ) : (
                    <DomaLogoComponent colorA={DOMA_LOGO_COLOR_A} />
                  )}
                </Col>
                <Col span={23} style={{ width: "100%", padding: "16px", marginLeft: "16px" }}>
                  <b>{item.name}</b>
                </Col>
              </ContainerListItem>
            )}
            style={{ marginTop: "16px" }}
          />
        )}
      </Systems>
    </Container>
  );
};
export default RoofSystem;

const Container = styled.div``;

const SearchInput = styled(Input)``;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;

const Systems = styled.div``;

const SystemList = styled(List)`
  margin-top: 16px;
`;

const CardImage = styled.img`
  max-width: 75px;
`;

const ContainerListItem = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  background: ${(props) => (props.selected ? PRIMARY_COLOR : "")};
  color: ${(props) => (props.selected ? "#ffffff !important" : "")};
`;
