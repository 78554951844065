import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { IContractorPriceItem } from "../../models/Models";
import { AppDispatch } from "../store";

const initialState: IContractorPriceItem[] = [];

export const contractorPriceListSlice = createSlice({
  name: "contractorPriceLists",
  initialState,
  reducers: {
    setContractorPriceListInReducer: (state, action: PayloadAction<IContractorPriceItem[]>) => {
      let list = action.payload;
      return list;
    },
    updateContractorPriceListInReducer: (state, action: PayloadAction<IContractorPriceItem[]>) => {
      let list = action.payload;
      return list;
    },
  },
});

export const { setContractorPriceListInReducer, updateContractorPriceListInReducer } =
  contractorPriceListSlice.actions;
export default contractorPriceListSlice.reducer;

// API

// Update price list
export const updateContractorPriceList =
  (model: IContractorPriceItem[], callback: () => void) => async (dispatch: AppDispatch) => {
    // console.log(model);
    try {
      const response = await client.put("/Contractor/contractorPriceList", { items: model });
      if (response.status === 204) {
        dispatch(updateContractorPriceListInReducer(model));
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
