import React, { FC, useState } from "react";
import styled from "styled-components";
import { Button, Card, Table } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { ICompany, IRootState } from "../../models/Models";
import CompanyModal from "./CompanyModal";

const companyColumns = [
  {
    title: "Nazwa",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Opis",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Kaucja za paletę [zł]",
    dataIndex: "palletFee",
    key: "palletFee",
    align: "center" as "center",
    width: "200px",
  },
  {
    title: "Edytuj",
    dataIndex: "edit",
    key: "edit",
    align: "center" as "center",
    width: "100px",
  },
];

const CompaniesPage: FC = () => {
  const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
  const [companyInEdit, setCompanyInEdit] = useState<ICompany | null>(null);

  const companies = useAppSelector((state: IRootState) => state.companies);

  const editCompanyOnClick = (company: ICompany) => {
    setCompanyInEdit(company);
    setAddCompanyModalOpen(true);
  };

  const getCompaniesData = (companies: ICompany[]) => {
    const data: {
      key: string;
      name: JSX.Element;
      description: string;
      palletFee: number;
      edit: JSX.Element;
    }[] = [];

    for (const company of companies) {
      data.push({
        key: company.id,
        name: <b>{company.name}</b>,
        description: company.description,
        palletFee: company.palletFee,
        edit: <Button onClick={() => editCompanyOnClick(company)}>Edytuj</Button>,
      });
    }
    return data;
  };

  const addNewCompany = () => {
    setCompanyInEdit(null);
    setAddCompanyModalOpen(true);
  };

  return (
    <Container
      title={"Lista producentów"}
      extra={
        <Button type="primary" onClick={() => addNewCompany()}>
          Dodaj nowego producenta
        </Button>
      }
    >
      {addCompanyModalOpen && (
        <CompanyModal
          addCompanyModalOpen={addCompanyModalOpen}
          setAddCompanyModalOpen={setAddCompanyModalOpen}
          company={companyInEdit}
        />
      )}
      <Table
        dataSource={getCompaniesData(companies)}
        columns={companyColumns}
        pagination={{ defaultPageSize: 10 }}
      />
    </Container>
  );
};

export default CompaniesPage;

const Container = styled(Card)``;
