import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import {
  ICompany,
  IContractor,
  IUpdateActivePriceSystemRequestModel,
  IUpdateActivePrincingPriceSystemRequestModel,
  IUpdateContractorData,
} from "../../models/Models";
import { AppDispatch, AppThunk } from "../store";

const initialState: IContractor = {
  id: "",
  name: "",
  nip: "",
  city: "",
  description: "",
  email: "",
  phone: "",
  postCode: "",
  regon: "",
  street: "",
  parentIds: [],
  activePriceSystemId: null,
  princingSystems: [],
};

export const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    addContractorInReducer: (state, action: PayloadAction<IContractor>) => {
      let contractor = action.payload;
      return contractor;
    },
    // addCompaniesInReducer: (state, action: PayloadAction<Array<ICompany>>) => {
    //   let companies = action.payload;
    //   return companies;
    // },
    updateContractorInReducer: (state, action: PayloadAction<IUpdateContractorData>) => {
      let contractor = action.payload;

      return { ...state, ...contractor };
    },
    updateActivePrincingPriceSystemInReducer: (state, action: PayloadAction<string>) => {
      let id = action.payload;

      return { ...state, activePriceSystemId: id };
    },
    // deleteCompanyInReducer: (state, action: PayloadAction<string>) => {
    //   const companyId = action.payload;
    //   return state.filter((o) => o.id !== companyId);
    // },
  },
});

export const { addContractorInReducer, updateContractorInReducer, updateActivePrincingPriceSystemInReducer } =
  contractorSlice.actions;
export default contractorSlice.reducer;

// API

// Update product
export const updateContractorData =
  (model: IUpdateContractorData, callback: () => void) => async (dispatch: AppDispatch) => {
    console.log(model);
    try {
      const response = await client.put("/Contractor/contractorData", model);
      if (response.status === 204) {
        dispatch(updateContractorInReducer(model));
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };

// Update active princing
export const updateActivePrincingPriceSystemInContractor =
  (model: IUpdateActivePrincingPriceSystemRequestModel, callback: () => void) => async (dispatch: AppDispatch) => {
    console.log(model);
    try {
      const response = await client.put("/Contractor/updateActivePrincingPriceSystem", model);
      if (response.status === 204) {
        dispatch(updateActivePrincingPriceSystemInReducer(model.priceSystemId));
        callback();
      }
    } catch (error) {
      console.error(error);
    }
  };
