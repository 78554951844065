import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DomaLogoComponent from "../../components/SVGComponents/DomaLogoComponent";
import SignIn from "./SignIn";

export default function SignPage() {
  const [signUp, setSignUp] = useState(false);

  return (
    <Container>
      <Logo>
        <StyledDomaLogoComponent />
      </Logo>
      <Content>
        <SignIn />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    min-height: 0;
    flex-direction: column;
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0 64px 0 64px;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 16px 64px 16px 64px;
  }
`;

const StyledDomaLogoComponent = styled(DomaLogoComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70px;
`;
