import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { INotification, ISystemActivity } from "../../models/Models";
import { AppDispatch } from "../store";

export interface ISystemActivitiesState {
  loading: boolean;
  systemActivities: ISystemActivity[];
}

const initialState: ISystemActivitiesState = {
  loading: false,
  systemActivities: [],
};

export const systemActivitiesSlice = createSlice({
  name: "systemActivities",
  initialState,
  reducers: {
    setLoadingSystemActivitiesInReducer: (state, action: PayloadAction<boolean>) => {
      return { ...state, loading: action.payload };
    },
    setSystemActivitiesInReducer: (state, action: PayloadAction<ISystemActivity[]>) => {
      return { ...state, systemActivities: action.payload, loading: false };
    },
    clearSystemActivitiesInReducer: (state, action: PayloadAction<string>) => {
      return initialState;
    },
  },
});

export const {
  setSystemActivitiesInReducer,
  setLoadingSystemActivitiesInReducer,
  clearSystemActivitiesInReducer,
} = systemActivitiesSlice.actions;
export default systemActivitiesSlice.reducer;

// API
export const getSystemActivitiesFromApi = () => async (dispatch: AppDispatch) => {
  dispatch(setLoadingSystemActivitiesInReducer(true));
  try {
    const response = await client.get("/Informations/getSystemActivities");
    if (response.status === 200) {
      const systemActivities = response.data;
      dispatch(setSystemActivitiesInReducer(systemActivities));
    }
  } catch (error) {
    dispatch(setLoadingSystemActivitiesInReducer(false));
    console.error(error);
  }
};
