import { Alert, Button, Form, Input, InputNumber, Modal, Radio, Select } from "antd";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../../../theme";
import DomaInputNumber from "../../../../../../helpers/DomaInputNumber";
import { calculatePolygonArea3D, getRoofPlaneArea } from "../../../../../../helpers/Helpers";
import { IRoofPoint } from "../../../../../../models/Models";
import { invalidateCalculation } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { updateRSOPlane } from "../../../../../../redux/roofSlopeOutline/rsoPlanesSlice";
import { updateRSOPoints } from "../../../../../../redux/roofSlopeOutline/rsoPointsSlice";
import { convertPercentageToAngle } from "../../../newRoof/RoofProcessMainModal";
import {
  IRSO,
  IRSODeclineVector,
  IRSOEdge,
  IRSOHole,
  IRSOPoint,
  RSOPoint2D,
} from "./Models/RoofSlopeOutlineModels";

type Props = {
  model: IRSO;
  startPointDeclineVector?: RSOPoint2D | null;
  endPointDeclineVector?: RSOPoint2D | null;
  selectedEdge?: IRSOEdge | null;
  closeModal: () => void;
  save: () => void;
  activateDrawDeclineVector: () => void;
  activateGetEdgeDeclineVector: () => void;
};

const RoofSlopeOutlineEditModal: FC<Props> = ({
  model,
  startPointDeclineVector,
  endPointDeclineVector,
  selectedEdge,
  closeModal,
  save,
  activateDrawDeclineVector,
  activateGetEdgeDeclineVector,
}) => {
  const dispatch = useAppDispatch();

  const scale = useAppSelector((state) => state.drawingScale);

  const [declineVector, setDeclineVector] = useState<IRSODeclineVector>(model.declineVectoor);
  const [angle, setAngle] = useState<number>(0) || 0;

  const [dimensionsType, setDimensionsType] = useState<number>(1); // 1 - rzutowane, 2 - rzeczywiste

  const rsoPlanes = useAppSelector((state) => state.rsoPlanes) || [];
  const rsoPoints = useAppSelector((state) => state.rsoPoints) || [];
  const rsoHoles = useAppSelector((state) => state.rsoHoles) || [];

  const roofProcessStatus = useAppSelector((state) => state.roofProcessStatus);

  const INFINITY = 0.000000000000001;

  useEffect(() => {
    if (model.angle === INFINITY) {
      setDimensionsType(2);
    } else {
      // setDimensionsType(1);
    }
  }, []);

  useEffect(() => {
    if (roofProcessStatus && !isVectorNonZero(declineVector)) {
      if (roofProcessStatus.typeSlope === "degrees") {
        setAngle(roofProcessStatus.slope);
      } else {
        setAngle(convertPercentageToAngle(roofProcessStatus.slope));
      }
    } else {
      setAngle(model.angle);
    }
  }, [roofProcessStatus]);

  // Przypisanie wektora spadku do pól
  useEffect(() => {
    if (startPointDeclineVector && endPointDeclineVector) {
      const dx = endPointDeclineVector.x - startPointDeclineVector.x;
      const dy = endPointDeclineVector.y - startPointDeclineVector.y;

      const vectorLength = Math.sqrt(dx * dx + dy * dy);

      let newDeclineVector = { x: 0, y: 0 };

      // -1 do odwrócenia wektora
      if (vectorLength !== 0) newDeclineVector = { x: (-1 * dx) / vectorLength, y: (-1 * dy) / vectorLength };

      setDeclineVector(newDeclineVector);
    } else if (selectedEdge) {
      const dx = selectedEdge.endPoint.x - selectedEdge.startPoint.x;
      const dy = selectedEdge.endPoint.y - selectedEdge.startPoint.y;

      const vectorLength = Math.sqrt(dx * dx + dy * dy);

      let newDeclineVector = { x: 0, y: 0 };

      if (vectorLength !== 0) newDeclineVector = { x: dx / vectorLength, y: dy / vectorLength };

      setDeclineVector(newDeclineVector);
    } else {
      setDeclineVector(model.declineVectoor);
    }
  }, [model, startPointDeclineVector, endPointDeclineVector, selectedEdge]);

  useEffect(() => {
    if (dimensionsType == 1) {
      if (model.angle === INFINITY) {
        setAngle(1);
      } else {
        setAngle(model.angle);
      }
    } else {
      setAngle(INFINITY);
    }
  }, [dimensionsType]);

  // Funkcja która oblicza na nowo poląć gdy zostało coś zmienione czyli dodano otwór, kąt, wektor spadku
  const reloadDataWithNewVectorOrAngle = (rsoPoints: IRSOPoint[], rsoHoles: IRSOHole[]) => {
    let area = 0;

    if (model) {
      // Filtruj punkty, które należą do tej połaci
      const planePoints = model.pointIds?.map((pointId) => rsoPoints.find((point) => point.id === pointId)) ?? [];

      const holesInPlane = rsoHoles.filter((hole) => hole.rsoPlaneId === model.id);
      const pointForHoles: IRSOPoint[] = [];

      if (holesInPlane.length > 0) {
        const holesPoints = holesInPlane.map(
          (hole) => hole.pointIds?.map((pointId) => rsoPoints.find((point) => point.id === pointId)) ?? []
        );

        for (const holePoints of holesPoints) {
          pointForHoles.push(...holePoints.filter((point): point is IRSOPoint => point !== undefined));
        }
      }

      planePoints.push(...pointForHoles);

      // Usuń ewentualne elementy undefined
      const filteredPlanePoints = planePoints.filter((point): point is IRSOPoint => point !== undefined);

      // Oblicz wartości z tylko dla punktów należących do tej połaci
      const points3D = calculateZForPoints(filteredPlanePoints, model, declineVector, angle) || [];

      // Musimy tu policzyć pole netto połaci czyli uwzgledniając otwory

      // 1. obliczmy pole ogólne dla połaci nie uwzgledniają cotworów
      const pointsForArea3D = points3D.filter((point) => !pointForHoles.find((p) => p.id === point.id));
      area = calculatePolygonArea3D(pointsForArea3D, 1, angle);

      // 2. Obliczmy pola otworów wszystich w połaci
      let holesArea = 0.0;
      for (const hole of holesInPlane) {
        // Wszystkie punkty otworu
        const holePoints: IRSOPoint[] = [];
        if (hole.pointIds) {
          for (const id of hole.pointIds) {
            const point = rsoPoints.find((point) => point.id === id);
            if (point) {
              holePoints.push(point);
            }
          }
        }

        const holeArea = calculatePolygonArea3D(holePoints, 1, angle);
        holesArea += holeArea;
      }

      // Obliczamy pole netto połaci
      area = area - holesArea;

      // Aktualizacja punktów w Redux
      dispatch(updateRSOPoints(points3D));
    }

    dispatch(updateRSOPlane({ ...model, declineVectoor: declineVector, angle: angle, area: area }));

    dispatch(invalidateCalculation());
  };

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="back" onClick={() => closeModal()}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button
        key="ok"
        type="primary"
        onClick={() => {
          reloadDataWithNewVectorOrAngle(rsoPoints, rsoHoles);
          save();
        }}
      >
        Zaktualizuj dane
      </Button>
    );

    return buttons;
  };

  const calculateZForPoints = (
    points: IRSOPoint[],
    plane: IRSO,
    declineVectorProps?: IRSODeclineVector,
    angleProps?: number
  ): IRSOPoint[] => {
    const result: IRSOPoint[] = [];

    if (points && plane) {
      const firstPoint = points[0];

      const angle = angleProps ? angleProps : plane.angle;
      const tanAngle = Math.tan((Math.PI / 180) * angle);
      const declineVector = declineVectorProps ? declineVectorProps : plane.declineVectoor;

      let minZ = Infinity;

      for (const point of points || []) {
        if (point) {
          // Sprawdzamy czy punkt istnieje i czy wartość z nie jest już ustawiona
          const dx = point.x - firstPoint.x;
          const dy = point.y - firstPoint.y;
          const projectionLength = dx * declineVector.x + dy * declineVector.y;

          const deltaZ = tanAngle * projectionLength;

          const newPoint = { ...point, z: deltaZ };

          result.push({ ...newPoint });

          if (deltaZ < minZ) {
            minZ = deltaZ;
          }
        }
      }

      // Przesunięcie wszystkich punktów, tak aby najniższy miał wartość z równą 0
      return result.map((point) => ({ ...point, z: point.z - minZ }));
    }

    return result;
  };

  const isVectorNonZero = (vector: IRSODeclineVector): boolean => {
    return vector.x !== 0 || vector.y !== 0;
  };

  return (
    <Modal
      title="Edytuj obrys połaci"
      open={true}
      bodyStyle={{
        paddingTop: "16px",
      }}
      centered
      onCancel={() => closeModal()}
      footer={getButtonsInFooter()}
    >
      {/* Jeśli vektor jest ZERO to pokaż alert */}
      {!isVectorNonZero(declineVector) && (
        <Alert
          type="error"
          showIcon
          message="Brakuje definicji wektora spadku. Możesz to zrobić na dwa poniższe sposoby"
        />
      )}
      <StyledVectorContainer>
        <StyledVectorContainerTitle>Wektor spadku</StyledVectorContainerTitle>
        <StyledVectorRow>
          <VectorColumn>
            <DomaInputNumber
              allowNegative={true}
              style={{ width: "100%" }}
              value={declineVector.x}
              prefix={"x:"}
              onChange={(e) => setDeclineVector({ ...declineVector, x: e ? (e as number) : 0 })}
            />
          </VectorColumn>
          <VectorColumn>
            <DomaInputNumber
              allowNegative={true}
              style={{ width: "100%" }}
              value={declineVector.y}
              prefix={"y:"}
              onChange={(e) => setDeclineVector({ ...declineVector, y: e ? (e as number) : 0 })}
            />
          </VectorColumn>
        </StyledVectorRow>
      </StyledVectorContainer>

      <StyledButtonsContainer>
        <Button type="primary" onClick={() => activateDrawDeclineVector()}>
          Narysuj wektor spadku
        </Button>
        <Button type="primary" onClick={() => activateGetEdgeDeclineVector()}>
          Wybierz poziomą krawędź okapu
        </Button>
      </StyledButtonsContainer>

      <StyledAngleContainer>
        <StyledVectorContainerTitle>Wymiary</StyledVectorContainerTitle>
        <StyledRadioGroup
          value={dimensionsType}
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
          onChange={(e) => setDimensionsType(e.target.value)}
        >
          <Radio.Button value={1}>Rzutowane</Radio.Button>
          <Radio.Button value={2}>Rzeczywiste</Radio.Button>
        </StyledRadioGroup>
      </StyledAngleContainer>

      {dimensionsType === 1 && (
        <StyledAngleContainer>
          <StyledVectorContainerTitle>Nachylenie połaci</StyledVectorContainerTitle>
          <DomaInputNumber
            style={{ width: "100%" }}
            prefix="stopni [°]"
            value={angle}
            min={1}
            onChange={(v) => {
              if (typeof v === "number") {
                setAngle(v);
              }
            }}
          />
        </StyledAngleContainer>
      )}
      <div style={{ marginBottom: "16px" }}></div>
    </Modal>
  );
};
export default RoofSlopeOutlineEditModal;

const StyledVectorContainer = styled.div``;

const StyledVectorContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0px;
`;

const StyledVectorRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const VectorColumn = styled.div`
  display: flex;
  width: 100%;
`;

const StyledButtonsContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledAngleContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
