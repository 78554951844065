import { InboxOutlined } from "@ant-design/icons";
import { Card, message, Image, Row, Col, Button } from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import client from "../../../../api/ApiClient";
import DomaLogoComponent from "../../../../components/SVGComponents/DomaLogoComponent";
import DSpin from "../../../../helpers/DSpin";
import { FALLBACK_IMAGE, getBasicUrlSystemImage } from "../../../../helpers/Helpers";
import { DOMA_LOGO_COLOR_A, TEXT_COLOR } from "../../../../theme";

export interface UploadRequestOption<T = any> {
  onProgress?: (event: any) => void;
  onError?: (event: any | ProgressEvent, body?: T) => void;
  onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
  data?: Record<string, unknown>;
  filename?: string;
  file: Exclude<any, File | boolean> | RcFile;
  withCredentials?: boolean;
  action: string;
  headers?: any;
  method: any;
}

type Props = {
  systemId: string;
  systemName: string;
  imageFileName: string;
  setImageFileName: (fileName: string) => void;
};

const SystemImageComponent: FC<Props> = ({ systemId, systemName, imageFileName, setImageFileName }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const fileUploadProps = {
    name: "file",
    action: "/ImagesStore/UploadSystemImage",
    customRequest(options: UploadRequestOption) {
      const { file, onSuccess, onError } = options;
      const formData = new FormData();

      formData.append("file", file); // Dodaj plik
      formData.append("systemName", systemName); // Dodaj systemName z informacją o typie systemu

      setLoading(true);
      client
        .post(fileUploadProps.action, formData, {
          headers: {
            ...client.defaults.headers.common, // Zachowaj domyślne nagłówki
            "Content-Type": "multipart/form-data", // Nadpisz tylko Content-Type
          },
        })
        .then((response) => {
          message.success(`${file.name} został dodany.`);
          if (onSuccess) {
            // console.log(response.data);
            onSuccess(response.data, file);

            var fileName = response.data;
            setImageFileName(fileName);
            setLoading(false);
          }
        })
        .catch((error) => {
          message.error(`${file.name} błąd dodawania.`);
          if (onError) {
            console.log(error);
            onError(error, error.response);
          }
        });
    },
    beforeUpload(file: any) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Rozmiar pliku przekracza limit 2 MB.");
      }
      return isLt2M; // Zwraca false, jeśli plik jest większy niż 2MB, co zatrzyma przesyłanie
    },
  };

  // W S3 nie usuwamy zdjecia
  const removeSystemImage = () => {
    setImageFileName("");
  };

  return (
    <Container
      title="Grafika do systemu"
      extra={
        <Button
          disabled={imageFileName !== undefined && imageFileName.length === 0}
          type="primary"
          danger
          onClick={() => removeSystemImage()}
        >
          Usuń zdjęcie systemu
        </Button>
      }
      bodyStyle={{ padding: "16px" }}
    >
      <Row>
        <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
          <DraggerContainer>
            {loading && <DomaSpin />}
            {!loading && imageFileName.length > 0 ? (
              <Image
                key={imageFileName}
                style={{
                  objectFit: "contain",
                  maxWidth: imageFileName.length === 0 ? "200px" : "600px",
                  maxHeight: "600px",
                }} // lub "contain" zamiast "cover"
                preview={false}
                // src={`${getBasicUrlSystemImage()}/${imageFileName}`}
                src={`${getBasicUrlSystemImage()}${imageFileName}`} // Dodanie znacznika czasu jako parametru zapytania
                fallback={FALLBACK_IMAGE}
              />
            ) : (
              <DomaLogoComponent style={{ width: "200px" }} />
            )}
          </DraggerContainer>
        </Col>
        <Col span={12}>
          <Dragger {...fileUploadProps} showUploadList={false}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Kliknij lub przenieś plik zdjęcia</p>
            <p className="ant-upload-hint">Wybierz tylko jeden plik. Plik może mieć maksymalnie 2MB.</p>
          </Dragger>
        </Col>
      </Row>
    </Container>
  );
};
export default SystemImageComponent;

const Container = styled(Card)`
  margin-bottom: 16px;
`;

const DraggerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Ustaw wysokość na wartość, która odpowiada Twoim potrzebom
`;

const DomaSpin = styled(DSpin)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999;
`;
