import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile, IUpdateProfile, IUserSettings } from "../../models/Models";

const initialState: IProfile = {
  accessToken: "",
  email: "",
  fullName: "",
  phone: "",
  role: "seller",
  contactEmail: "",
  active: true,
  contractor: undefined,
  userSettings: undefined,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    addProfileInReducer: (state, action: PayloadAction<IProfile>) => {
      let profile = action.payload;
      return profile;
    },
    updateProfileInReducer: (state, action: PayloadAction<IUpdateProfile>) => {
      let params = action.payload;
      return { ...state, ...params };
    },
    updateUserSettingsInReducer: (state, action: PayloadAction<IUserSettings>) => {
      let userSettings = action.payload;
      return { ...state, userSettings: userSettings };
    },
    addErrorProfileInReducer: (state, action: PayloadAction<string | null>) => {
      let error = action.payload;
      return { ...state, error: error };
    },
  },
});

export const {
  addProfileInReducer,
  updateProfileInReducer,
  updateUserSettingsInReducer,
  addErrorProfileInReducer,
} = profileSlice.actions;
export default profileSlice.reducer;
