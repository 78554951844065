import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IRSO,
  IRSOHole,
} from "../../modules/roofs/components/roofSteps/steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";

const initialState: IRSOHole[] = [];

export const rsoHolesSlice = createSlice({
  name: "rsoHoles",
  initialState,
  reducers: {
    addRSOHole: (state, action: PayloadAction<IRSOHole>) => {
      let p = action.payload;
      return [...state, p];
    },
    addRSOHoles: (state, action: PayloadAction<IRSOHole[]>) => {
      let ps = action.payload;
      return [...state, ...ps];
    },
    setRSOHoles: (state, action: PayloadAction<IRSOHole[]>) => {
      let array = action.payload;
      return array;
    },
    updateRSOHole: (state, action: PayloadAction<IRSOHole>) => {
      let p = action.payload;
      return state.map((rso) => {
        if (rso.id === p.id) {
          return {
            ...rso,
            hover: p.hover,
            active: p.active,
            area: p.area,
            type: p.type,
            rsoPlaneId: p.rsoPlaneId,
          };
        } else {
          return rso;
        }
      });
    },
    removeRSOHole: (state, action: PayloadAction<IRSOHole>) => {
      let p = action.payload;
      return state.filter((o) => o.id !== p.id);
    },
    clearRSOHoles: (state) => {
      return initialState;
    },
    setSavedRSOHoles: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addRSOHole,
  addRSOHoles,
  updateRSOHole,
  setRSOHoles,
  removeRSOHole,
  clearRSOHoles,
  setSavedRSOHoles,
} = rsoHolesSlice.actions;
export default rsoHolesSlice.reducer;
