import { Alert, Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { useFirstRender } from "../../../hooks/useFirstRender";
import { IRootState } from "../../../models/Models";
import { addColor } from "../../../redux/general/productColorsSlice";
import { useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";

type Props = {
  open: boolean;
  onOk: any;
  onCancel: any;
  dispatch: any;
};

const AddProductColorModal: FC<Props> = ({ open, onOk, onCancel, dispatch }) => {
  const [form] = useForm();
  const firstRender = useFirstRender();

  const statusApi = useAppSelector((state: IRootState) => state.statusApi.filter((x) => x.key === "productColor"));

  useEffect(() => {
    if (!firstRender) {
      if (statusApi.length === 0) {
        onCancel();
      }
    }
  }, [statusApi]);

  const onFinish = (values: any) => {
    dispatch(addColor({ name: values.name, hex: values.hex ? values.hex : "" }));
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Container
      title="Dodaj nowy kolor"
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      centered
      footer={[
        <Button
          key="back"
          onClick={() => {
            if (statusApi.length > 0) {
              dispatch(deleteStatusApi("productColor"));
            }
            onCancel();
          }}
        >
          Anuluj
        </Button>,
        ,
        <Button form="productColorForm" key="ok" type="primary" htmlType="submit">
          Dodaj
        </Button>,
      ]}
    >
      <Form
        id="productColorForm"
        style={{ width: "100%" }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Nazwa koloru" name="name" rules={[{ required: true, message: "Podaj nazwę koloru!" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Kod HEX koloru (np. #FFFFFF)" name="hex">
          <Input />
        </Form.Item>
      </Form>

      {statusApi.length > 0 && (
        <Alert message="Błąd. Prawdopodobnie podany kolor już istnieje." type="error" showIcon />
      )}
    </Container>
  );
};

export default AddProductColorModal;

const Container = styled(Modal)``;
