import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { ICompany } from "../../models/Models";
import { AppThunk } from "../store";

const initialState: Array<ICompany> = [];

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    addCompanyInReducer: (state, action: PayloadAction<ICompany>) => {
      let company = action.payload;
      return [...state, company];
    },
    addCompaniesInReducer: (state, action: PayloadAction<Array<ICompany>>) => {
      let companies = action.payload;
      return companies;
    },
    updateCompanyInReducer: (state, action: PayloadAction<ICompany>) => {
      let company = action.payload;

      let index = state.findIndex((o) => o.id === company.id);
      state[index] = { ...state[index], ...company };
    },
    deleteCompanyInReducer: (state, action: PayloadAction<string>) => {
      const companyId = action.payload;
      return state.filter((o) => o.id !== companyId);
    },
  },
});

export const { addCompanyInReducer, addCompaniesInReducer, updateCompanyInReducer, deleteCompanyInReducer } =
  companiesSlice.actions;
export default companiesSlice.reducer;

// *******************************************************
// API
// *******************************************************

// Add company
export const addCompany =
  (data: ICompany): AppThunk =>
  (dispatch: Dispatch) => {
    console.log(data);
    client
      .put("/Companies", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addCompanyInReducer(response.data));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Update company
export const updateCompany =
  (data: ICompany): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .post("/Companies", data)
      .then((response) => {
        if (response.status == 204) {
          dispatch(updateCompanyInReducer(data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete company
export const deleteCompany =
  (data: ICompany): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/Companies/${data.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteCompanyInReducer(data.id));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
