import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IGutterSystem, IRootState } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { deleteGutterSystem } from "../../../redux/roofSystems/gutterSystemsSlice";
import GutterSystemDetails from "./components/GutterSystemDetails";

type Props = {};

const EditGutterSystemPage: FC<Props> = () => {
  const { gutterSystemId } = useParams();
  const [gutterSystem, setGutterSystem] = useState<IGutterSystem | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const gutterSystems = useAppSelector((state: IRootState) => state.gutterSystems);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteGutterSystem")
  );

  useEffect(() => {
    const s = gutterSystems.find((x) => x.id === gutterSystemId);
    if (s) {
      setGutterSystem(s);
    }
  }, [gutterSystem]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteGutterSystem"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${gutterSystem?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && gutterSystem && (
              <Button danger onClick={() => dispatch(deleteGutterSystem(gutterSystem))}>
                Usuń
              </Button>
            )}
            <Button type="primary" form="gutterSystemDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy system
            </Button>
          </div>
        }
      />

      <Content>
        {gutterSystem && (
          <GutterSystemDetails
            system={gutterSystem}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};
export default EditGutterSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
