import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPriceUnit, IProduct, IProductUseType, IProductUseUnit } from "../../models/Models";

const initialState: Array<IProductUseUnit> = [];

export const productUseUnitsSlice = createSlice({
  name: "productUseUnits",
  initialState,
  reducers: {
    addProductUseUnitsInReducer: (state, action: PayloadAction<Array<IProductUseUnit>>) => {
      let e = action.payload;
      return e;
    },
  },
});

export const { addProductUseUnitsInReducer } = productUseUnitsSlice.actions;
export default productUseUnitsSlice.reducer;
