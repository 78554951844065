import { Dispatch } from "@reduxjs/toolkit";
import client from "../../../api/ApiClient";
import { IChangePassword, IUpdateProfile } from "../../../models/Models";
import { logOut } from "../../actions";
import { AppThunk } from "../../store";
import { addErrorProfileInReducer, updateProfileInReducer } from "../profileSlice";

// Update profile
export const updateProfile =
  (data: IUpdateProfile): AppThunk =>
  (dispatch: Dispatch<any>) => {
    client
      .post("/Users/update", data)
      .then((response) => {
        if (response.status == 204) {
          dispatch(updateProfileInReducer(data));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Change password
export const changePassword =
  (data: IChangePassword): AppThunk =>
  (dispatch: Dispatch<any>) => {
    client
      .post("/Users/changePassword", data)
      .then((response) => {
        if (response.status == 204) {
          dispatch(addErrorProfileInReducer(null));
          dispatch(logOut());
        } else {
          dispatch(addErrorProfileInReducer("Podano nieprawidłowe dane!"));
        }
      })
      .catch((error) => {
        dispatch(addErrorProfileInReducer("Podano nieprawidłowe dane!"));
      });
  };
