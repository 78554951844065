import { Button, Card, Checkbox, Form, InputNumber, Select, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { getDataForSelect } from "../../../../../helpers/DomaFunctions";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import {
  IAdditionalElement,
  ICompany,
  IProduct,
  IProductCategory,
  IProductColor,
  IResultItemResponse,
  IRootState,
} from "../../../../../models/Models";
import {
  addAdditionalElement,
  clearAdditionalElements,
  deleteAdditionalElement,
  updateAdditionalElement,
} from "../../../../../redux/calculationProcess/additionalElementsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import store from "../../../../../redux/store";
import { v4 as uuidv4 } from "uuid";
import { getProductPrice } from "../../../../../helpers/Helpers";

const columns = [
  {
    title: "Produkt",
    dataIndex: "name",
  },
  {
    title: "Producent",
    dataIndex: "company",
    width: 200,
    align: "center" as const,
  },
  {
    title: "Kolor",
    dataIndex: "color",
    align: "center" as const,
  },
  {
    title: "Ilość [szt.]",
    dataIndex: "amount",
    width: 150,
    align: "center" as const,
  },
  {
    title: "Akcja",
    dataIndex: "action",
    width: 100,
    align: "center" as const,
  },
];

const getCategoryIdByCode = (code: string, categories: IProductCategory[]) => {
  return categories.find((x) => x.code === code)?.id as string;
};

const isColorDefault = (colorId: string, productColors: IProductColor[]) => {
  const color = productColors.find((x) => x.id === colorId);
  return color?.name === "Brak";
};

type Props = {};

const AdditionalElementsStep: FC<Props> = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const [useProducentFilter, setUseProducentFilter] = useState<boolean>(true);
  const [useColorFilter, setUseColorFilter] = useState<boolean>(true);

  const companies = useAppSelector((state: IRootState) => state.companies);
  const productCategories = useAppSelector((state: IRootState) => state.productCategories);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const calcMaterialsData = useAppSelector((state: IRootState) => state.calcMaterialsData);
  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems);
  const products = useAppSelector((state: IRootState) => state.products);
  const additionalElements = useAppSelector((state: IRootState) => state.additionalElements);

  const getDefaultCompanyId = () => {
    const tileSystem = tileSystems.find((x) => x.id === calcMaterialsData.roofSystemId);
    const deafultTile = products.find((x) => x.id === tileSystem?.basicTileProductId);
    return deafultTile?.companyId as string;
  };

  const getDefaultColorId = () => {
    const tileSystem = tileSystems.find((x) => x.id === calcMaterialsData.roofSystemId);
    const deafultTile = products.find((x) => x.id === tileSystem?.basicTileProductId);
    return deafultTile?.productColorId as string;
  };

  const [companyFilter, setCompanyFilter] = useState<string>(getDefaultCompanyId());
  const [colorFilter, setColorFilter] = useState<string>(getDefaultColorId());

  const getAdditionalProductsForSelect = (
    products: IProduct[],
    categoryId: string,
    useColorFilter: boolean,
    useProducentFilter: boolean
  ) => {
    const result = [];
    for (const p of products.filter((x) => x.productCategoryId === categoryId)) {
      let isCompany = p.companyId === companyFilter || undefined;
      let isColor = p.productColorId === colorFilter || undefined;

      if (isColorDefault(colorFilter, productColors) || colorFilter === undefined) isColor = true;

      const allColors = !useColorFilter || colorFilter === "all" ? true : false;
      const allCompanies = !useProducentFilter || companyFilter === "all" ? true : false;

      const company = companies?.find((x) => x.id === p.companyId);
      const color = productColors?.find((x) => x.id === p.productColorId);

      const name = `${p.name} ${company?.name} (${color?.name})`;

      if ((isCompany || allCompanies) && (isColor || allColors)) {
        result.push({
          value: p.id,
          label: name,
        });
      }
    }
    return result;
  };

  const getInitialValues = () => {
    return { company: companyFilter, color: colorFilter };
  };

  const selectedProduct = (value: string) => {
    const productId = value;

    // Kontrola czy dany produkt już istnieje la liście dodatkowych elementów
    const isExist = additionalElements.some((x) => x.productId === productId);

    if (!isExist) {
      const product = store.getState().products.find((x) => x.id === productId);
      const category = store.getState().productCategories.find((x: any) => x.id === product?.productCategoryId);

      const element: IResultItemResponse = {
        id: uuidv4(),
        active: true,
        productId: productId,
        name: product?.name as string,
        categoryCode: category?.code as string,
        price: product ? getProductPrice(product) : 0,
        salesFormCurrency: "PLN",
        salesFormName: "Sztuka",
        discount: 0,
        discountPart: "nett",
        discountType: "%",
        amount: 1,
        unit: "Sztuka",
      };

      dispatch(addAdditionalElement(element));
    }

    form.resetFields(["name"]);
  };

  const getAdditionalElementsData = (
    additionalElements: IResultItemResponse[],
    products: IProduct[],
    companies: ICompany[],
    colors: IProductColor[]
  ) => {
    const data: {
      key: string;
      name: string;
      company: string;
      color: string;
      amount: JSX.Element;
      action: JSX.Element;
    }[] = [];

    if (additionalElements) {
      for (const e of additionalElements) {
        const product = products?.find((x) => x.id === e.productId);
        const company = companies?.find((x) => x.id === product?.companyId);
        const color = colors?.find((x) => x.id === product?.productColorId);

        data.push({
          key: e.productId,
          name: product?.name as string,
          company: company?.name as string,
          color: color?.name as string,
          amount: (
            <DomaInputNumber
              min={0}
              value={e.amount}
              onChange={(value) => dispatch(updateAdditionalElement({ ...e, amount: value as number }))}
            />
          ),
          action: (
            <Button onClick={() => dispatch(deleteAdditionalElement(e.productId))} danger>
              Usuń
            </Button>
          ),
        });
      }
    }

    return data;
  };

  return (
    <Container>
      <Section title="Filtruj i dodawaj produkty">
        <Form form={form} layout="vertical" initialValues={getInitialValues()}>
          <Form.Item
            label={
              <Checkbox checked={useProducentFilter} onChange={(e) => setUseProducentFilter(e.target.checked)}>
                Nazwa producenta
              </Checkbox>
            }
            name="company"
          >
            <Select
              disabled={!useProducentFilter}
              placeholder="Wybierz producenta!"
              options={getDataForSelect(companies)}
              onChange={(value) => setCompanyFilter(value)}
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            label={
              <Checkbox checked={useColorFilter} onChange={(e) => setUseColorFilter(e.target.checked)}>
                Kolor produktu
              </Checkbox>
            }
            name="color"
          >
            <Select
              disabled={!useColorFilter}
              placeholder="Wybierz kolor produktu!"
              options={getDataForSelect(productColors)}
              onChange={(value) => setColorFilter(value)}
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item label={<b>Wyszukaj i wybierz wybrane produkty</b>} name="name" style={{ marginTop: "32px" }}>
            <Select
              placeholder="Wyszukaj produkt!"
              options={getAdditionalProductsForSelect(
                products,
                getCategoryIdByCode("additional-elements", productCategories),
                useColorFilter,
                useProducentFilter
              )}
              onChange={(value) => selectedProduct(value)}
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA: any, optionB: any) =>
                (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Section>
      <Section
        title="Dodatkowe produkty na dach (płotki śniegowe, drabinki śniegowe, ławy kominiarskie itp.)"
        extra={<Button onClick={() => dispatch(clearAdditionalElements())}>Wyczyść listę</Button>}
      >
        <Table
          columns={columns}
          dataSource={getAdditionalElementsData(additionalElements, products, companies, productColors)}
          pagination={false}
          rowKey="key"
        />
      </Section>
    </Container>
  );
};
export default AdditionalElementsStep;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)``;
