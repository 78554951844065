import { Card, Input, Select } from "antd";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { createFilterOption } from "../../../../../../helpers/Helpers";
import { IContractor, IRootState, ITileSystem } from "../../../../../../models/Models";
import { updateCalcMaterialsData } from "../../../../../../redux/calculationProcess/calcMaterialsDataSlice";
import { updateVATResultItem } from "../../../../../../redux/calculationProcess/calcResultSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import SystemCard from "./SystemCard";

type Props = {
  selectRoofSystemId?: any;
  selectedRoofSystemId?: string | null;
};

const ContractorSystem: FC<Props> = ({ selectRoofSystemId, selectedRoofSystemId }) => {
  const dispatch = useAppDispatch();
  const calcMaterialsData = useAppSelector((state: IRootState) => state.calcMaterialsData);

  const tileSystems = useAppSelector((state: IRootState) => state.tileSystems);
  const contractors = useAppSelector((state) => state.parentContractors);

  const profile = useAppSelector((state) => state.profile);
  const contractor = useAppSelector((state) => state.contractor);
  const princingSystems = useAppSelector((state) => state.princingSystems);
  const externalPrincingSystems = useAppSelector((state) => state.externalPrincingSystems);

  const isContractor = () => {
    return profile.role === "contractor";
  };

  const [searchResults, setSearchResults] = useState<ITileSystem[]>([]);

  const getMostPopularTileSystems = (tileSystems: ITileSystem[]): ITileSystem[] => {
    return tileSystems.slice(0, 6);
  };

  const getDataForSelectContractorSystem = (contractors: IContractor[]) => {
    const result = [];

    if (princingSystems) {
      for (const ps of princingSystems) {
        result.push({
          id: ps.id,
          value: ps.id,
          // label: `[${contractor.name}] ${ps.name}`,
          label: `[Mój system] ${ps.name}`,
        });
      }
    }

    if (externalPrincingSystems) {
      for (const ps of externalPrincingSystems) {
        result.push({
          id: ps.id,
          value: ps.id,
          label: `[${ps.owner}] ${ps.name}`,
        });
      }
    }

    return result;
  };

  const search = (text: string) => {
    let result: ITileSystem[] = [];

    if (text.length > 0) {
      // Dzielimy wprowadzony tekst na słowa, ignorując wielkość liter
      // Używamy wyrażenia regularnego, aby podzielić tekst według spacji, przecinka, średnika lub dwukrotnego wystąpienia spacji
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      for (const p of tileSystems) {
        // Sprawdzamy, czy każde słowo występuje w ciągu znaków 'name'
        const matched = inputWords.every((word) => p.name.toLowerCase().includes(word));

        if (matched) {
          result = [...result, p];
        }
      }
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const selectContractorSystem = (id: string) => {
    dispatch(updateVATResultItem(8));
    dispatch(updateCalcMaterialsData({ ...calcMaterialsData, contractorId: id }));
  };

  return (
    <Container>
      <Select
        style={{ width: "100%" }}
        placeholder="Wybierz system wyceny"
        value={calcMaterialsData.contractorId}
        onChange={(value) => selectContractorSystem(value)}
        options={getDataForSelectContractorSystem(contractors)}
        showSearch
        filterOption={createFilterOption()}
        filterSort={(optionA: any, optionB: any) =>
          (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
        }
        allowClear
      />
    </Container>
  );
};
export default ContractorSystem;

const Container = styled.div``;

const SearchInput = styled(Input)``;

const Systems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 32px;
`;
