import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {};

export const calcDataSlice = createSlice({
  name: "calcData",
  initialState,
  reducers: {
    addCalcDataInReducer: (state, action) => {
      let data = action.payload;
      return data;
    },
    deleteCalcDataInReducer: (state) => {
      return initialState;
    },
    setSavedRoofCalcData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { addCalcDataInReducer, deleteCalcDataInReducer, setSavedRoofCalcData } = calcDataSlice.actions;
export default calcDataSlice.reducer;
