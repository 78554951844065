import React, { FC, useEffect, useMemo, useState } from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { IPoint } from "../../../../../models/Models";
import { useFirstRender } from "../../../../../hooks/useFirstRender";
import { roundNumber } from "../../../../../helpers/Calculator";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { addOutlinePoint } from "../../../../../redux/drawing/outlineSlice";
import { useKeyPress } from "react-use";
import { IRoofProcessFunctionsStatus } from "../../../../../redux/drawing/roofProcessFunctionsStatusSlice";
import { getNearestPoint } from "../../../../../helpers/Helpers";

// CONSTANTS
const DIST = 10;

type Props = {
  eventClickStage: KonvaEventObject<MouseEvent> | null;
  roofProcessFunctionsStatus: IRoofProcessFunctionsStatus;
  endDraw: any;
  roofContourNumber: number;
  gridPoints: IPoint[];
};

// Popracować z ref dla mp i event -> bo częśte renderowanie to PROBLEM
const DrawOutline: FC<Props> = ({
  roofProcessFunctionsStatus,
  eventClickStage,
  endDraw,
  roofContourNumber,
  gridPoints,
}) => {
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();

  const outlinePoints =
    useAppSelector((state) => state.outline.find((o) => o.roofNumber === roofContourNumber)?.points) || [];

  const mp = useAppSelector((state) => state.mousePosition); // to powoduje ponowne rendery ale tylko tego komponentu
  const [nearestPoint, setNearestPoint] = useState<IPoint | null>();
  const ctrlPress = useKeyPress("Control");

  const gridSettings = useAppSelector((state) => state.domaGrid);
  const isGridSnapping = gridSettings && gridSettings.gridVisible && gridSettings.gridSnapping ? true : false;

  useEffect(() => {
    const dist = ctrlPress[0] ? 0 : DIST;
    const value = getNearestPoint(mp, outlinePoints, dist, gridPoints, isGridSnapping);
    setNearestPoint(value.point);
  }, [mp, gridPoints]);

  const isFirstPoint = () => {
    const first = outlinePoints[0];
    if (
      roundNumber(nearestPoint?.x) === roundNumber(first.x) &&
      roundNumber(nearestPoint?.y) === roundNumber(first.y)
    ) {
      return true;
    }

    return false;
  };

  const isTheSamePointLast = () => {
    const last = outlinePoints[outlinePoints.length - 1];

    const pointToAnalisis = nearestPoint ? nearestPoint : mp;

    if (
      roundNumber(pointToAnalisis?.x) === roundNumber(last.x) &&
      roundNumber(pointToAnalisis?.y) === roundNumber(last.y)
    ) {
      return true;
    }
    return false;
  };

  // CLICK IN STAGE
  useEffect(() => {
    // Tylko lewy przycisk myszki
    if (eventClickStage?.evt.button === 0) {
      if (!firstRender) {
        // Jeśłi jest ten sam punkt kliknięty to przerywa funkcję
        if (outlinePoints.length > 0 && isTheSamePointLast()) return;

        // Gdy klikniety 1 punkt to zamyka działanie rysowania obwodu
        if (outlinePoints.length > 0 && isFirstPoint()) {
          endDraw();
          return;
        }

        if (nearestPoint) {
          // console.log("DODAJ PUNKT Z NEAREST");
          dispatch(
            addOutlinePoint({
              point: { x: nearestPoint.x, y: nearestPoint.y, z: nearestPoint.z as number },
              roofNumber: roofContourNumber,
            })
          );
        } else {
          dispatch(addOutlinePoint({ point: { x: mp.x, y: mp.y, z: 0 }, roofNumber: roofContourNumber }));
        }
      }
    }
  }, [eventClickStage]);

  return null;
};

export default React.memo(DrawOutline);
