import { Dispatch } from "redux";
import store, { AppThunk, RootState } from "./store";

export interface IStateToSave {
  general: any;
  calcResult: any;
  outline: any;
  report: any;
  calcMaterialsData: any;
  additionalElements: any;
  templateImage: any;
  drawingScale: any;
  calcData: any;
  inputData: any;
  roofDrawingStore: any;
  generalRoofData: any;
  roofPoints: any;
  roofPlanes: any;
  roofEdges: any;
  roofHoles: any;
  drawChimneyProcess: any;
  gutteringElements: any;
  roofContourNumber: any;
  roofSlopes: any;
  metalSheets: any;
  rsoLinkedEdges: any;
  rsoPoints: any;
  rsoPlanes: any;
  rsoEdges: any;
  rsoHoles: any;
  roofProcessStatus: any;
  roofProcessFunctionsStatus: any;
  savedOutlines: any;
  domaGrid: any;

  roofAccessories: any;
  windowHoles: any;

  geometryData: any;

  groupDiscounts: any;
  currencyValue: any;

  filtersForSystems: any;
  additionalProducts: any;

  responsesApi: any;

  savedAdditionalProducts: any;

  // otherAppStatuses: any; // to dana nie jest związana z konkretnym projektem
}

export const getStateToSave = (state: RootState) => {
  // console.log("getStateToSave", state);

  // Przed zapisem stanu usuwamy string64 z obrazka - nie jest potrzebny
  let templateImageState = state.templateImage;
  templateImageState = { ...templateImageState, string64: "" };

  const stateToSave: IStateToSave = {
    general: state.general,
    calcResult: state.calcResult,
    outline: state.outline,
    report: state.report,
    calcMaterialsData: state.calcMaterialsData,
    additionalElements: state.additionalElements,
    templateImage: templateImageState,
    drawingScale: state.drawingScale,
    calcData: state.calcData,
    inputData: state.inputData,
    roofDrawingStore: state.roofDrawingStore,
    generalRoofData: state.generalRoofData,
    roofPoints: state.roofPoints,
    roofPlanes: state.roofPlanes,
    roofEdges: state.roofEdges,
    roofHoles: state.roofHoles,
    drawChimneyProcess: state.drawChimneyProcess,
    gutteringElements: state.gutteringElements,
    roofContourNumber: state.roofContourNumber,
    roofSlopes: state.roofSlopes,
    metalSheets: state.metalSheets,
    rsoLinkedEdges: state.rsoLinkedEdges,
    rsoPoints: state.rsoPoints,
    rsoPlanes: state.rsoPlanes,
    rsoEdges: state.rsoEdges,
    rsoHoles: state.rsoHoles,
    roofProcessStatus: state.roofProcessStatus,
    roofProcessFunctionsStatus: state.roofProcessFunctionsStatus,
    savedOutlines: state.savedOutlines,
    domaGrid: state.domaGrid,
    windowHoles: state.windowHoles,
    roofAccessories: state.roofAccessories,
    geometryData: state.geometryData,
    groupDiscounts: state.groupDiscounts,
    currencyValue: state.currencyValue,
    filtersForSystems: state.filtersForSystems,
    additionalProducts: state.additionalProducts,
    responsesApi: state.responsesApi,
    savedAdditionalProducts: state.savedAdditionalProducts,
    // otherAppStatuses: state.otherAppStatuses,
  };

  return stateToSave;
};
