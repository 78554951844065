import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
import DomaInputNumber from "../../../../helpers/DomaInputNumber";
import { createFilterOption } from "../../../../helpers/Helpers";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { IProduct, IProductCategory, IRootState, ISheetMetalProcess } from "../../../../models/Models";
import { useAppSelector } from "../../../../redux/hooks";

interface SheetMetalProcessPositionProps {
  sheetMetalProcess?: ISheetMetalProcess;
  products: IProduct[]; // Załóżmy, że to jest typ dla twoich produktów
  productCategories: IProductCategory[]; // Załóżmy, że to jest typ dla twoich kategorii produktów
  relations: any[]; // Załóżmy, że to jest typ dla twoich relacji
  selectedCategories: string[];
  readyOptions?: any[];
  updateSheetMetalProcess: (obj: ISheetMetalProcess) => void;
  deleteSheetMetalProcess: (obj: ISheetMetalProcess) => void;
}

interface SheetMetaProcessState {
  productId: string | null;
  sheetWidth: number;
  sheetLength: number;
  stripWidth: number;
  overlap: number;
  relation: string;
  correction: number;
}

const SheetMetalProcessPosition: React.FC<SheetMetalProcessPositionProps> = ({
  sheetMetalProcess,
  products,
  productCategories,
  relations,
  selectedCategories,
  readyOptions,
  updateSheetMetalProcess,
  deleteSheetMetalProcess,
}) => {
  const firstRender = useFirstRender();

  const companies = useAppSelector((state: IRootState) => state.companies);
  const productColors = useAppSelector((state: IRootState) => state.productColors);
  const productModels = useAppSelector((state: IRootState) => state.productModels);

  const [sheetMetalProcessState, setSheetMetalProcessState] = React.useState<SheetMetaProcessState>({
    productId: null,
    sheetWidth: 0,
    sheetLength: 0,
    stripWidth: 0,
    overlap: 0,
    relation: "none",
    correction: 0,
  });

  useEffect(() => {
    setSheetMetalProcessState({
      productId: sheetMetalProcess?.productId || null,
      sheetWidth: sheetMetalProcess?.sheetWidth || 0,
      sheetLength: sheetMetalProcess?.sheetLength || 0,
      stripWidth: sheetMetalProcess?.stripWidth || 0,
      overlap: sheetMetalProcess?.overlap || 0,
      relation: sheetMetalProcess?.relation || "none",
      correction: sheetMetalProcess?.correction || 0,
    });
  }, [sheetMetalProcess]);

  useEffect(() => {
    if (
      sheetMetalProcess &&
      !firstRender &&
      (sheetMetalProcessState.sheetWidth !== sheetMetalProcess.sheetWidth ||
        sheetMetalProcessState.sheetLength !== sheetMetalProcess.sheetLength ||
        sheetMetalProcessState.stripWidth !== sheetMetalProcess.stripWidth ||
        sheetMetalProcessState.overlap !== sheetMetalProcess.overlap ||
        sheetMetalProcessState.correction !== sheetMetalProcess.correction ||
        sheetMetalProcessState.relation !== sheetMetalProcess.relation ||
        sheetMetalProcessState.productId !== sheetMetalProcess.productId)
    ) {
      updateSheetMetalProcess({
        ...sheetMetalProcess,
        ...sheetMetalProcessState,
      });
    }
  }, [sheetMetalProcessState]);

  const getProductsByCategoryForSelect = (
    products: IProduct[],
    categories: IProductCategory[],
    categoryCodes: string[]
  ) => {
    const result: { value: any; label: string }[] = [
      {
        value: null,
        label: "Brak",
      },
    ];
    for (const p of products) {
      const category = categories.find((x) => x.id === p.productCategoryId);
      if (categoryCodes.includes(category?.code as string)) {
        const company = companies?.find((x) => x.id === p.companyId);
        const color = productColors?.find((x) => x.id === p.productColorId);
        const model = productModels?.find((x) => x.id === p.productModelId);
        let colorName = color?.name === "Brak" ? "Brak koloru" : color?.name;

        let productName = `${p.name} ${company?.name} ${model?.name} (${colorName}`;
        if (p.coating.length > 0) {
          productName += `, ${p.coating}`;
        }
        productName += ")";

        result.push({
          value: p.id,
          label: `${productName}`,
        });
      }
    }
    return result;
  };

  return (
    <Row gutter={16} key={sheetMetalProcess?.id}>
      <Col span={8}>
        <Form.Item label={"Arkusz blachy"} labelCol={{ span: 24 }}>
          <Select
            placeholder="Wybierz produkt!"
            value={sheetMetalProcessState.productId}
            onChange={(value) => {
              setSheetMetalProcessState((prevState) => ({
                ...prevState,
                productId: value,
                sheetWidth: value ? products.find((x) => x.id === value)?.width || 0 : 0,
                sheetLength: value ? products.find((x) => x.id === value)?.length || 0 : 0,
              }));
            }}
            options={
              readyOptions
                ? readyOptions
                : getProductsByCategoryForSelect(products, productCategories, selectedCategories)
            }
            showSearch
            filterOption={createFilterOption()}
            filterSort={(optionA: any, optionB: any) =>
              (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
            }
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Szer. arkusza" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={sheetMetalProcessState.sheetWidth}
            onChange={(value: any) => {
              setSheetMetalProcessState((prevState) => ({ ...prevState, sheetWidth: value }));
            }}
            min={0}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Wys. arkusza" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={sheetMetalProcessState.sheetLength}
            onChange={(value: any) => {
              setSheetMetalProcessState((prevState) => ({ ...prevState, sheetLength: value }));
            }}
            min={0}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Szer. pasa" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={sheetMetalProcessState.stripWidth}
            onChange={(value: any) => {
              setSheetMetalProcessState((prevState) => ({ ...prevState, stripWidth: value }));
            }}
            min={0}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Zakład blachy" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={sheetMetalProcess?.overlap}
            onChange={(value: any) => {
              setSheetMetalProcessState((prevState) => ({ ...prevState, overlap: value }));
            }}
            min={0}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={5}>
        <Form.Item label="Zależność" labelCol={{ span: 24 }}>
          <Select
            placeholder="Wybierz zależność!"
            value={sheetMetalProcessState.relation}
            onChange={(value) => {
              setSheetMetalProcessState((prevState) => ({ ...prevState, relation: value }));
            }}
            options={relations.sort((a, b) => a.index - b.index)}
            dropdownRender={(menu) => <div style={{ width: "400px" }}>{menu}</div>}
            filterOption={createFilterOption()}
            showSearch
          />
        </Form.Item>
      </Col>
      <Col span={2}>
        <Form.Item label="Korekta w [%]" labelCol={{ span: 24 }}>
          <DomaInputNumber
            value={sheetMetalProcessState.correction}
            onChange={(value: any) => {
              setSheetMetalProcessState((prevState) => ({ ...prevState, correction: value }));
            }}
            allowNegative
            min={-100}
            max={200}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item label="&nbsp;" labelCol={{ span: 24 }}>
          <Button
            danger
            onClick={() => {
              if (sheetMetalProcess) {
                deleteSheetMetalProcess(sheetMetalProcess);
              }
            }}
          >
            <DeleteOutlined />
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default SheetMetalProcessPosition;
