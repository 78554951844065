import axios from "axios";

export const TOKEN_KEY_NAME = "doma-user-token";

// Modyfikacja klienta Axios
const createClient = (baseUrl) => {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      "Content-type": "application/json",
    },
  });

  client.interceptors.request.use((config) => {
    const tokenString = localStorage.getItem(TOKEN_KEY_NAME);
    const token = JSON.parse(tokenString);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return client;
};

// Używanie klienta z różnymi URL
const DOMA_API_CALC_URL = process.env.REACT_APP_DOMA_API_CALC_URL;
const DOMA_API_GENERAL_URL = process.env.REACT_APP_DOMA_API_GENERAL_URL;

const calcClient = createClient(DOMA_API_CALC_URL); // Dla zapytań kalkulatora
const client = createClient(DOMA_API_GENERAL_URL); // Dla zapytań ogólnych

// Eksportowanie domyślnego klienta oraz dodatkowego klienta
export default client; // Domyślny klient (ogólne zapytania)
export { calcClient }; // Dodatkowy klient (kalkulator)

// import axios from "axios";

// export const TOKEN_KEY_NAME = "doma-user-token";
// export const DOMA_API_URL = process.env.REACT_APP_DOMA_API_URL;

// const client = axios.create({
//   baseURL: DOMA_API_URL,
//   headers: {
//     "Content-type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Credentials": true,
//   },
// });

// client.interceptors.request.use((config) => {
//   const tokenString = localStorage.getItem(TOKEN_KEY_NAME);
//   const token = JSON.parse(tokenString);

//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }

//   return config;
// });

// export default client;
