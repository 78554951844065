import React, { useState, useEffect } from "react";
import { useFirstRender } from "../../../../../hooks/useFirstRender";

export default function DefineUnit(props) {
  const firstRender = useFirstRender();
  const { eventClickStage, mp, setStartScalePoint, setEndScalePoint } = props;

  const [startPointDefined, setStartPointDefined] = useState(false);

  // useEffect(() => {
  //   console.log("START UNIT SCALE");

  //   return () => {
  //     console.log("END UNIT SCALE");
  //   };
  // }, []);

  useEffect(() => {
    if (!firstRender) {
      if (startPointDefined === false) {
        setStartScalePoint(mp);
        setStartPointDefined(true);
      } else {
        // Zakończenie
        setEndScalePoint(mp);
      }
    }
  }, [eventClickStage]);

  return null;
}
