import { Button } from "antd";
import React, { FC } from "react";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import TileSystemDetails from "./components/TileSystemDetails";

type Props = {};

const AddTileSystemPage: FC<Props> = () => {
  return (
    <Container>
      <PageHeader
        title={"Dodaj nowy system dachu z dachówką"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="primary" form="tileSystemDetailsForm" key="submit" htmlType="submit">
              Dodaj system
            </Button>
          </div>
        }
      />

      <Content>
        <TileSystemDetails system={null} />
      </Content>
    </Container>
  );
};

export default AddTileSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
