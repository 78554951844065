import React, { FC, useEffect } from "react";
import { Group } from "react-konva";
import { Html } from "react-konva-utils";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  addGutterDrainPipe,
  addGutterEndLeft,
  addGutterEndRight,
  addGutterExternalCorner,
  addGutterInternalCorner,
} from "../../../../redux/roofs/gutteringElementsSlice";
import { v4 as uuidv4 } from "uuid";
import {
  getGutterDrainPipeIcon,
  getGutterEndLeftIcon,
  getGutterEndRightIcon,
  getGutterExternalCornerIcon,
  getGutterInternalCornerIcon,
} from "./GutteringElement";
import { updateCalcMaterialsData } from "../../../../redux/calculationProcess/calcMaterialsDataSlice";
import { saveDrawing } from "../../../../redux/roofs/roofDrawingStoreSlice";
import store from "../../../../redux/store";
import { invalidateCalculation } from "../../../../redux/calculationProcess/calcResultSlice";

type Props = {
  typeElement:
    | null
    | "gutterDrainPipe"
    | "gutterInternalCorner"
    | "gutterExternalCorner"
    | "gutterEndLeft"
    | "gutterEndRight";
  eventClickStage: any;
  zoomScale: number;
  closeFunction: any;
};

const AddGutteringElementFunction: FC<Props> = ({ typeElement, eventClickStage, closeFunction, zoomScale }) => {
  const firstRender = useFirstRender();
  const dispatch = useAppDispatch();
  const mousePosition = useAppSelector((state) => state.mousePosition); // położenie myszki w Stage

  useEffect(() => {
    if (!firstRender) {
      //   console.log(
      //     `DODAJĘ ELEMENT ${typeElement} w pozycji (${mousePosition.x}, ${mousePosition.y}) i kończę funkcję`
      //   );

      // Wstawienie elementu orynnowania w wybranym miejscu czyli dodanie gdzieś do listy w reduxie która póxniej jest wyświetlana
      if (typeElement === "gutterDrainPipe") {
        dispatch(addGutterDrainPipe({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
      } else if (typeElement === "gutterInternalCorner") {
        dispatch(addGutterInternalCorner({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
      } else if (typeElement === "gutterExternalCorner") {
        dispatch(addGutterExternalCorner({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
      } else if (typeElement === "gutterEndLeft") {
        dispatch(addGutterEndLeft({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
      } else if (typeElement === "gutterEndRight") {
        dispatch(addGutterEndRight({ id: uuidv4(), x: mousePosition.x, y: mousePosition.y }));
      }

      dispatch(invalidateCalculation());

      dispatch(saveDrawing(store.getState()));

      // Zamykanie funcji
      closeFunction();
    }
  }, [eventClickStage]);

  // Podczas przemieszczania myszka juz widać tą ikonkę na ekrnaie aby było wiadmo co się dodaje

  return (
    <Group
      x={mousePosition.x}
      y={mousePosition.y}
      // scale={{ x: 1 / zoomScale, y: 1 / zoomScale }}
    >
      {typeElement === "gutterDrainPipe" && getGutterDrainPipeIcon(0, 0, zoomScale, false)}
      {typeElement === "gutterInternalCorner" && getGutterInternalCornerIcon(0, 0, zoomScale)}
      {typeElement === "gutterExternalCorner" && getGutterExternalCornerIcon(0, 0, zoomScale)}
      {typeElement === "gutterEndLeft" && getGutterEndLeftIcon(0, 0, zoomScale)}
      {typeElement === "gutterEndRight" && getGutterEndRightIcon(0, 0, zoomScale)}
    </Group>
  );
};

export default AddGutteringElementFunction;
