import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { resetRoofProcessFunctionsStatus } from "./roofProcessFunctionsStatusSlice";

export type RoofProcessStatusType = "preset" | "outline" | "general" | "undefined";
export type SlopeTypes = "degrees" | "percentages";

export interface IRoofProcessStatus {
  type: RoofProcessStatusType; // Preset - dach z presetów, outline - dach z rysowania obrysu dachu + automat, general - ręczne rysownaie połaci
  typeSlope: SlopeTypes; // Spadek dachu podany w stopniach lub procentach
  slope: number; // domyślny spadek dachu dla całego rysunku
  unit: number; // jednostka czyli ile px to 1 m
}

const initialState: IRoofProcessStatus = {
  type: "undefined",
  typeSlope: "degrees",
  slope: 40,
  unit: 70,
};

export const roofProcessStatusSlice = createSlice({
  name: "roofProcessStatus",
  initialState,
  reducers: {
    setRoofProcessStatus: (state, action: PayloadAction<IRoofProcessStatus>) => {
      return action.payload;
    },
    updateRoofProcessStatus: (state, action: PayloadAction<IRoofProcessStatus>) => {
      return action.payload;
    },
    resetRoofProcessStatus: (state) => {
      return initialState;
    },
    setSavedRoofProcessStatus: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { setRoofProcessStatus, updateRoofProcessStatus, resetRoofProcessStatus, setSavedRoofProcessStatus } =
  roofProcessStatusSlice.actions;
export default roofProcessStatusSlice.reducer;

export const selectRoofProcessStatusType =
  (newType: RoofProcessStatusType): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const { type, typeSlope, slope, unit } = getState().roofProcessStatus;
    dispatch(updateRoofProcessStatus({ type: newType, typeSlope, slope, unit }));
  };

export const selectSlopeType =
  (newSlopeType: SlopeTypes): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const { type, typeSlope, slope, unit } = getState().roofProcessStatus;
    dispatch(updateRoofProcessStatus({ type, typeSlope: newSlopeType, slope, unit }));
  };

export const updateSlope =
  (newSlope: number): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const { type, typeSlope, slope, unit } = getState().roofProcessStatus;
    dispatch(updateRoofProcessStatus({ type, typeSlope, slope: newSlope, unit }));
  };

// Aktualizacja slope oraz slopeType w jednym
export const updateSlopeAndSlopeType =
  (newSlope: number, newSlopeType: SlopeTypes): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const { type, typeSlope, slope, unit } = getState().roofProcessStatus;
    dispatch(updateRoofProcessStatus({ type, typeSlope: newSlopeType, slope: newSlope, unit }));
  };

export const updateUnit =
  (newUnit: number): AppThunk =>
  (dispatch: Dispatch<any>, getState) => {
    const { type, typeSlope, slope, unit } = getState().roofProcessStatus;
    dispatch(updateRoofProcessStatus({ type, typeSlope, slope, unit: newUnit }));
  };

export const resetDrawRoofProcess = (): AppThunk => (dispatch: Dispatch<any>, getState) => {
  const rps = getState().roofProcessStatus;
  const currentType = rps.type;

  dispatch(resetRoofProcessFunctionsStatus());

  //  // To świadczy, że roofProcess nie jest zamieniany aby nie stracić danych o kącie ogólnym czy jednostce
  //  dispatch(
  //   updateRoofProcessStatus({
  //     ...initialState,
  //     type: currentType,
  //     slope: rps.slope,
  //     typeSlope: rps.typeSlope,
  //     unit: rps.unit,
  //   })
  // );
};
