import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IExternalTileSystem {
  id: string;
  name: string;
  owner: string;
  description?: string;
  imageUrl?: string;
  typeTileSystem: number;
}

const initialState: IExternalTileSystem[] = [];

export const externalTileSystemsSlice = createSlice({
  name: "externalTileSystems",
  initialState,
  reducers: {
    setExternalTileSystemsInReducer: (state, action: PayloadAction<IExternalTileSystem[]>) => {
      let list = action.payload;
      return list;
    },
  },
});

export const { setExternalTileSystemsInReducer } = externalTileSystemsSlice.actions;
export default externalTileSystemsSlice.reducer;
