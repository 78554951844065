import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IPreCoveringSystem, IRootState } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { deletePreCoveringSystem } from "../../../redux/roofSystems/preCoveringSystemsSlice";
import PreCoveringSystemDetails from "./PreCoveringSystemDetails";

type Props = {};

const EditPreCoveringSystemPage: FC<Props> = () => {
  const { preCoveringSystemId } = useParams();
  const [preCoveringSystem, setPreCoveringSystem] = useState<IPreCoveringSystem | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const preCoveringSystems = useAppSelector((state: IRootState) => state.preCoveringSystems);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deletePreCoveringSystem")
  );

  useEffect(() => {
    const s = preCoveringSystems.find((x) => x.id === preCoveringSystemId);
    if (s) {
      setPreCoveringSystem(s);
    }
  }, [preCoveringSystem]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deletePreCoveringSystem"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${preCoveringSystem?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && preCoveringSystem && (
              <Button danger onClick={() => dispatch(deletePreCoveringSystem(preCoveringSystem))}>
                Usuń
              </Button>
            )}
            <Button type="primary" form="preCoveringSystemDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nowy system
            </Button>
          </div>
        }
      />

      <Content>
        {preCoveringSystem && (
          <PreCoveringSystemDetails
            system={preCoveringSystem}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};
export default EditPreCoveringSystemPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
