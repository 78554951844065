import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGeneralRoofData } from "../../models/Models";

const initialState: IGeneralRoofData = { withBayOrDormer: false };

// Kontener na różne dane dotyczące rysunku (kopiowane razem z dokonywaniem zapisu danego stanu)
export const generalRoofDataSlice = createSlice({
  name: "generalRoofData",
  initialState,
  reducers: {
    updateGeneralRoofData: (state, action: PayloadAction<IGeneralRoofData>) => {
      const generalRoofData = action.payload;
      return { ...generalRoofData };
    },
    updateWithBayOrDormer: (state, action: PayloadAction<boolean>) => {
      const withBayOrDormer = action.payload;
      return { ...state, withBayOrDormer: withBayOrDormer };
    },
    clearGeneralRoofData: (state) => {
      return initialState;
    },
    setSavedGeneralRoofData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { updateGeneralRoofData, updateWithBayOrDormer, clearGeneralRoofData, setSavedGeneralRoofData } =
  generalRoofDataSlice.actions;
export default generalRoofDataSlice.reducer;
