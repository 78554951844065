import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBasicPoint } from "../../models/Models";

const initialState: IBasicPoint = {
  x: 0,
  y: 0,
};

export const mousePositionSlice = createSlice({
  name: "mousePosition",
  initialState,
  reducers: {
    updateMousePosition: (state, action: PayloadAction<IBasicPoint>) => {
      const mousePosition = action.payload;
      return mousePosition;
    },
    clearMousePosition: (state) => {
      return initialState;
    },
  },
});

export const { updateMousePosition, clearMousePosition } = mousePositionSlice.actions;
export default mousePositionSlice.reducer;
