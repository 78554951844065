import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Button, Card, Col, Modal, Row, Statistic, Table } from "antd";
import Konva from "konva";
import React, { useLayoutEffect, useRef, useState } from "react";
import { FC } from "react";
import { Circle, Layer, Rect, Stage, Text } from "react-konva";
import styled from "styled-components";
import { IMetalSheet } from "../../../../../../models/Models";
import { useAppSelector } from "../../../../../../redux/hooks";
import * as XLSX from "xlsx";
import * as ExcelJS from "exceljs";
import { head } from "lodash";

const SCALE_BY = 1.1;

type Props = {
  onCancel: any;
  onOk: any;
  onEdidMetalSheet: any;
};

const PreviewMetalSheets: FC<Props> = ({ onOk, onCancel, onEdidMetalSheet }) => {
  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);

  const reportOffer = useAppSelector((state) => state.reports);
  const report = useAppSelector((state) => state.report);
  const investments = useAppSelector((state) => state.investments);

  const metalSheets = useAppSelector((state) => state.metalSheets) || [];
  const scale = useAppSelector((state) => state.drawingScale);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, [stageParent.current]);

  // Create a map to track unique sheets and their counts
  const uniqueSheetsMap = new Map<number, { sheet: IMetalSheet; count: number }>();

  metalSheets.forEach((sheet) => {
    const key = sheet.type; // Use the 'type' field as the key
    if (uniqueSheetsMap.has(key)) {
      uniqueSheetsMap.get(key)!.count += 1;
    } else {
      uniqueSheetsMap.set(key, { sheet, count: 1 });
    }
  });

  // Convert map to array and sort by height
  const uniqueSheets = Array.from(uniqueSheetsMap.values()).sort((a, b) => b.sheet.height - a.sheet.height);

  const maxHeight = Math.max(...uniqueSheets.map(({ sheet }) => sheet.height));

  // funkcja która liczy ile sztuk arkuszy zmieści się w widocznym ekranie uwzględniając szerokośc arkusza oraz odstępy między nimi czyli 50 jednostek
  const calculateSheetsInScreen = () => {
    // suma szerokości wszystkich arkuszy
    const totalWidth = uniqueSheets.reduce((acc, { sheet }) => acc + sheet.width, 0);
    // ilość wszystkich arkuszy
    const sheetsCount = uniqueSheets.length;

    // średnia szerokość arkuszy
    const sheetWidth = totalWidth / sheetsCount;
    // szerokość ekranu
    const screenWidth = dimStageParent.width || 0;
    // odstęp pomiędzy arkuszami
    const spacing = 50;

    // oblicz ile arkuszy zmieści się w ekranie
    const sheetsInScreen = Math.floor(screenWidth / (sheetWidth + spacing));

    return sheetsInScreen;
  };

  const getAreaMetalSheet = (metalSheet: IMetalSheet) => {
    const w = Number((metalSheet.width / scale).toFixed(2));
    const h = Number((metalSheet.height / scale).toFixed(2));
    return w * h;
  };

  const getSumaAreaMetalSheets = (metalSheets: IMetalSheet[]) => {
    return metalSheets.reduce(
      (acc, { width, height }) => acc + Number((width / scale).toFixed(2)) * Number((height / scale).toFixed(2)),
      0
    );
  };

  const columns = [
    {
      title: "Lp",
      dataIndex: "index",
      width: "60px",
    },
    {
      title: "Długość",
      dataIndex: "length",
      align: "center" as const,
    },
    {
      title: "Szerokość",
      dataIndex: "width",
      align: "center" as const,
    },
    {
      title: "Powierzchnia [m2]",
      dataIndex: "area",
      align: "center" as const,
    },
    {
      title: "Ilość",
      dataIndex: "amount",
      align: "center" as const,
    },
    {
      title: "Akcja",
      dataIndex: "action",
      align: "center" as const,
      width: 100,
    },
  ];

  const getMetalSheetsFormsData = (
    metalSheets: {
      sheet: IMetalSheet;
      count: number;
    }[]
  ) => {
    const data: {
      key: string;
      index: number;
      length: string;
      width: string;
      area: string;
      amount: number;
      action: JSX.Element;
    }[] = [];

    // pętla po uniqueSheets

    if (metalSheets) {
      for (const u of metalSheets) {
        data.push({
          key: u.sheet.id.toString(),
          index: u.sheet.type,
          length: (u.sheet.height / scale).toFixed(2),
          width: (u.sheet.width / scale).toFixed(2),
          area: getAreaMetalSheet(u.sheet).toFixed(2),
          amount: u.count, // Policz ile jest arkuszy z tym samym type w metalsheets
          action: (
            <Button
              onClick={() => {
                onEdidMetalSheet(u.sheet);
              }}
              disabled={calcMaterialsData.systemType === 1}
            >
              Edytuj
            </Button>
          ),
        });
      }
    }

    return data.sort((a, b) => a.index - b.index);
  };

  type DataObject = {
    lp: number;
    length: number;
    width: number;
    amount: number;
    m2: number;
  };

  const getFileWithSheets = async () => {
    const currentReportId = report.responseData?.id;
    let projectNumber = "";
    let investmentId = "";
    if (currentReportId) {
      const report = reportOffer.find((report) => report.id === currentReportId);
      if (report) {
        projectNumber = report.projectNumber;
        investmentId = report.investmentId || "";
      }
    }

    if (projectNumber === "") {
      projectNumber = "projekt nie został wygenerowany";
    }

    const investment = investments.find((investment) => investment.id === investmentId);

    let headerRowIndex = 2;

    const data: DataObject[] = [];

    for (const sheet of uniqueSheets) {
      const { sheet: metalSheet, count } = sheet;

      data.push({
        lp: metalSheet.type,
        length: Number((metalSheet.height / scale).toFixed(2)),
        width: Number((metalSheet.width / scale).toFixed(2)),
        amount: count,
        m2: Number(getAreaMetalSheet(metalSheet).toFixed(2)),
      });
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Zestawienie");

    // Scalenie komórek od pierwszej do piątej w pierwszym wierszu
    worksheet.mergeCells("A1:E1");

    // Ustawienie tekstu i formatowania dla scalonej komórki
    const mergedCell = worksheet.getCell("A1");
    mergedCell.value = "Numer projektu: " + projectNumber;
    mergedCell.alignment = { horizontal: "center", vertical: "middle" };
    mergedCell.font = {
      bold: true,
    };
    mergedCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "ff7ccfff" },
    };

    // Ustaw obramowanie dla każdej komórki w zakresie A1:E1
    for (let col = 1; col <= 5; col++) {
      const cell = worksheet.getCell(1, col);
      cell.border = {
        top: { style: "thin", color: { argb: "FF000000" } },
        left: { style: "thin", color: { argb: "FF000000" } },
        bottom: { style: "thin", color: { argb: "FF000000" } },
        right: { style: "thin", color: { argb: "FF000000" } },
      };
    }

    if (investment && investment.code && report.responseData?.urlCode) {
      headerRowIndex = 3; // Jeśli projekt istnieje to przesuwamy nagłówek o jeden wiersz w dół

      // W drugim wierszu dodajemy link do strony z raportem
      worksheet.mergeCells("A2:E2");
      const linkCell = worksheet.getCell("A2");
      const reportLink = `https://raport.doma.pl/${investment.code}/${report.responseData?.urlCode}`;
      linkCell.value = {
        text: "Podgląd raportu (kliknij)",
        hyperlink: reportLink,
      };
      linkCell.alignment = { horizontal: "center", vertical: "middle" };
      linkCell.font = {
        bold: true,
        color: { argb: "ff398fff" },
        size: 8,
      };
      linkCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffffffff" },
      };

      // Ustaw obramowanie dla każdej komórki w zakresie A2:E2
      for (let col = 1; col <= 5; col++) {
        const cell = worksheet.getCell(2, col);
        cell.border = {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        };
      }
    }

    worksheet.columns = [
      { key: "lp", width: 10 },
      { key: "length", width: 20 },
      { key: "width", width: 22 },
      { key: "amount", width: 17 },
      { key: "m2", width: 25 },
    ];

    // Dodaj wiersz z nagłówkami jako zwykły tekst
    worksheet.addRow({
      lp: "Lp",
      length: "Długość arkusza [m]",
      width: "Szerokość arkusza [m]",
      amount: "Ilość arkuszy [szt.]",
      m2: "Powierzchnia arkusza [m2]",
    });

    // Zastosowanie stylów do wiersza nagłówkowego
    const headerRow = worksheet.getRow(headerRowIndex);
    headerRow.eachCell((cell) => {
      cell.style = {
        font: { bold: true, size: 10 },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD9D9D9" },
        },
        border: {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        },
        alignment: {
          horizontal: "center",
        },
      };
    });

    // Dodawanie wierszy z danymi
    data.forEach((item) => {
      const row = worksheet.addRow(item);
      // Zastosowanie stylów do wiersza
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.style = {
          border: {
            top: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          },
          alignment: {
            horizontal: "left",
          },
        };
      });
    });

    // Dodajemy wiersz z sumą powierzchni
    const sumRow = worksheet.addRow([
      "SUMA",
      Number(data.reduce((acc, { length, amount }) => acc + length * amount, 0).toFixed(2)),
      "",
      Number(data.reduce((acc, { amount }) => acc + amount, 0)),
      Number(data.reduce((acc, { width, length, amount }) => acc + width * length * amount, 0).toFixed(2)),
    ]);

    // Zastosowanie stylów do wiersza z sumą
    sumRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      cell.style = {
        font: { bold: true },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD9D9D9" },
        },
        border: {
          top: { style: "thin", color: { argb: "FF000000" } },
          left: { style: "thin", color: { argb: "FF000000" } },
          bottom: { style: "thin", color: { argb: "FF000000" } },
          right: { style: "thin", color: { argb: "FF000000" } },
        },
        alignment: {
          horizontal: "left",
        },
      };
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = window.URL.createObjectURL(blob);

    // Tworzenie linku do pobrania
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = "Zestawienie arkuszy blach.xlsx";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="getFile" type="primary" onClick={(e) => getFileWithSheets()}>
        Pobierz zestawienie arkuszy
      </Button>
    );

    buttons.push(
      <Button key="back" onClick={(e) => onCancel()}>
        Zamknij
      </Button>
    );

    return buttons;
  };

  function zoomStage(event: any) {
    // event.evt.preventDefault();
    // event.stopPropagation();

    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScale = s.scaleX();
      const pointerX = s.getPointerPosition()?.x as number;
      const pointerY = s.getPointerPosition()?.y as number;
      const mousePointTo = {
        x: (pointerX - s.x()) / oldScale,
        y: (pointerY - s.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? Number((oldScale / SCALE_BY).toFixed(1)) : Number((oldScale * SCALE_BY).toFixed(1));
      s.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      s.position(newPos);
      s.batchDraw();

      setZoomScale(newScale);
    }
  }

  return (
    <StyledModal
      centered
      width={"80%"}
      open={true}
      title={"Podgląd wszystkich arkuszy blach"}
      onCancel={onCancel}
      onOk={onOk}
      footer={getButtonsInFooter()}
    >
      <Section>
        <StageParent ref={stageParent}>
          <Stage
            ref={stage}
            height={700}
            width={dimStageParent.width}
            style={{ margin: "0", background: "#E8E8E8", borderRadius: "8px" }}
            onContextMenu={(e) => {
              e.evt.preventDefault();
            }}
            draggable
            onWheel={zoomStage}
          >
            <Layer offsetX={-30} offsetY={-30}>
              {uniqueSheets.map((value, index) => {
                const { sheet, count } = value;
                // Calculate the X position for each sheet
                let xPosition =
                  index === 0
                    ? 0
                    : uniqueSheets.slice(0, index).reduce((acc, prevValue) => acc + prevValue.sheet.width + 50, 0);

                let additionalY = 0;

                // TODO nie działa dobrze gdy jest więcej arkuszy niż zmieści się w ekranie i niektóre sa bardzo wąskie
                // jeśli index jest większy niż ilość arkuszy na jeden wiersz to nie rysuj (nie mieści się w ekranie)
                // if (index >= sheetsInScreen) {
                //   xPosition = xPosition - (sheet.width + 50) * sheetsInScreen;

                //   // Dodatkowa wysokość to najwyższy arkusz + 50
                //   additionalY = maxHeight + 50;
                // }

                return (
                  <React.Fragment key={sheet.id}>
                    <Rect
                      x={xPosition}
                      y={maxHeight - sheet.height + additionalY} // Align the bottom edges
                      width={sheet.width}
                      height={sheet.height}
                      fill={sheet.active ? "#d1d8f8" : "red"}
                      stroke="black"
                      strokeWidth={1}
                    />
                    <Text
                      x={xPosition - 10}
                      y={maxHeight - sheet.height / 2 + additionalY} // Centered in the rect
                      text={sheet.type.toString()}
                      fontSize={16 / zoomScale}
                      fontFamily="Calibri"
                      fontStyle="bold"
                      fill="black"
                      align="center"
                      verticalAlign="middle"
                      width={sheet.width + 20} // Ensure the text is centered
                    />
                    <Text
                      x={xPosition - 25}
                      y={maxHeight + 10 / zoomScale + additionalY} // Positioned below the sheet
                      text={`${count} szt.`}
                      fontSize={16 / zoomScale}
                      fontFamily="Calibri"
                      fill="black"
                      align="center"
                      verticalAlign="middle"
                      width={sheet.width + 50} // Ensure the text is centered
                    />

                    <Text // Width dimension
                      x={xPosition - 5}
                      y={maxHeight - sheet.height - 20 / zoomScale + additionalY}
                      text={`${(sheet.width / scale).toFixed(2)} m`}
                      fontSize={16 / zoomScale}
                      fontFamily="Calibri"
                      fill="black"
                      align="center"
                      verticalAlign="middle"
                      width={sheet.width + 10} // Ensure the text is centered
                    />

                    <Text // Height dimension
                      x={xPosition - 20 / zoomScale}
                      y={maxHeight - sheet.height / 2 + 20 / zoomScale + additionalY}
                      text={`${(sheet.height / scale).toFixed(2)} m`}
                      fontSize={16 / zoomScale}
                      fontFamily="Calibri"
                      fill="black"
                      rotation={-90}
                      align="center"
                      verticalAlign="middle"
                    />
                  </React.Fragment>
                );
              })}
              {/* <Circle x={0} y={0} radius={30} fill="blue" /> */}
            </Layer>
          </Stage>
        </StageParent>
      </Section>
      <Section style={{ marginTop: "16px" }}>
        <Table
          columns={columns}
          dataSource={getMetalSheetsFormsData(uniqueSheets)}
          pagination={{ defaultPageSize: 10 }}
        ></Table>

        <Row gutter={16} style={{ marginTop: "16px" }}>
          <Col span={12}>
            <Card>
              <Statistic
                title="Ilość unikalnych arkuszy (Ilość wszystkich arkuszy))"
                value={`${uniqueSheets.length} (${metalSheets.length}) `}
                valueStyle={{ color: "#3f8600" }}
                suffix="szt."
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="Łączna powierzchnia"
                value={getSumaAreaMetalSheets(metalSheets)}
                precision={2}
                valueStyle={{ color: "#cf1322" }}
                suffix="m2"
              />
            </Card>
          </Col>
        </Row>
      </Section>
    </StyledModal>
  );
};
export default PreviewMetalSheets;

const StyledModal = styled(Modal)``;

const Section = styled.div`
  width: 100%;
`;

const StageParent = styled.div``;
