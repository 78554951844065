import React, { FC, useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Canvas } from "@react-three/fiber";
import {
  Vector3,
  BufferGeometry,
  Float32BufferAttribute,
  MeshStandardMaterial,
  Vector2,
  DoubleSide,
  Shape,
  ExtrudeGeometry,
} from "three";
import { IPoint, IRoofEdge, IRoofPlane, IRoofPoint } from "../../../../../models/Models";
import {
  AccumulativeShadows,
  GizmoHelper,
  GizmoViewcube,
  GizmoViewport,
  OrbitControls,
  PerspectiveCamera,
  RandomizedLight,
} from "@react-three/drei";
import { Mesh } from "three";
import { getFullPointsById, getPointsForCenetrRoofPart, isClockwise } from "../../../../../helpers/Helpers";
import earcut from "earcut";
import { MeshLineGeometry, MeshLineMaterial } from "meshline";
import { useSize } from "react-use";
import { Button, Checkbox, Modal, Spin, Switch } from "antd";
import store from "../../../../../redux/store";
import { useAppSelector } from "../../../../../redux/hooks";
import { IRSO, IRSOEdge, IRSOPoint } from "../steps/RoofSlopeOutline/Models/RoofSlopeOutlineModels";
import DSpin from "../../../../../helpers/DSpin";
import { TEXT_COLOR } from "../../../../../theme";

type Props = {
  open: boolean;
  onOk: any;
  onCancel: any;
  roofPoints: IRoofPoint[];
  roofPlanes: IRoofPlane[];
  roofEdges: IRoofEdge[];
  rsoPoints: IRSOPoint[];
  rsoEdges: IRSOEdge[];
  rsoPlanes: IRSO[];
};

const Plane = () => {
  return (
    <mesh position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry attach="geometry" args={[2000, 2000]} />
      <meshStandardMaterial attach="material" color="#636363" />
    </mesh>
  );
};

const moveToOrigin = (points: IRoofPoint[], anchorPoint: { x: number; y: number; z: number }): IRoofPoint[] => {
  const offset = { x: -anchorPoint.x, y: -anchorPoint.y, z: -anchorPoint.z };
  return points.map((point) => ({
    ...point,
    x: point.x + offset.x,
    y: point.y + offset.y,
    z: point.z + offset.z,
  }));
};

interface IRoofPlaneView {
  points: IRoofPoint[];
  holes: IRoofPoint[][]; // Dodane pole holes
  roofPlane?: IRoofPlane;
}

function reverseFlatTriangles(triangles: any[]) {
  for (let i = 0; i < triangles.length; i += 3) {
    const temp = triangles[i];
    triangles[i] = triangles[i + 2];
    triangles[i + 2] = temp;
  }
  return triangles;
}

const RoofEdgeView: FC<IRoofPlaneView> = ({ points }) => {
  const geometry = new MeshLineGeometry();
  const offset = 0.1;
  geometry.setPoints([points[0], points[1]].map((point) => new Vector3(point.x, point.z + offset, point.y)));
  const material = new MeshLineMaterial({ resolution: new Vector2(512, 512), lineWidth: 1, color: "#8d8d8d" });
  const f = new Mesh(geometry, material);

  return <primitive object={f} />;
};

// Funkcja do obliczania pola wielokąta
function calculatePolygonArea(points: IRoofPoint[]) {
  let area = 0;
  for (let i = 0, j = points.length - 1; i < points.length; j = i++) {
    const pointI = points[i];
    const pointJ = points[j];
    area += (pointJ.x + pointI.x) * (pointJ.y - pointI.y);
  }
  return area / 2;
}

// Funkcja do odwracania punktów wielokąta
function reversePoints(points: IRoofPoint[]) {
  return points.slice().reverse();
}

function getRandomHexColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const RoofPlane: FC<IRoofPlaneView> = ({ points, holes }) => {
  // Sprawdź, czy punkty głównego wielokąta są zdefiniowane przeciwnie do ruchu wskazówek zegara
  if (calculatePolygonArea(points) > 0) {
    points = reversePoints(points);
  }

  // Sprawdź, czy punkty dla każdego otworu są zdefiniowane zgodnie z ruchem wskazówek zegara
  for (let i = 0; i < holes.length; i++) {
    if (calculatePolygonArea(holes[i]) < 0) {
      holes[i] = reversePoints(holes[i]);
    }
  }

  const allPoints = [...points];
  const arrayPoints = [];
  const holeIndices = [];

  for (const p of points) {
    arrayPoints.push(p.x);
    arrayPoints.push(p.y);
  }

  for (const hole of holes) {
    const holeIndex = arrayPoints.length / 2;
    holeIndices.push(holeIndex);

    for (const p of hole) {
      allPoints.push(p);
      arrayPoints.push(p.x);
      arrayPoints.push(p.y);
    }
  }

  const position = allPoints.map((p) => [p.x, p.z, p.y]).flat();

  const triangles = earcut(arrayPoints, holeIndices);

  let geometry = new BufferGeometry();
  geometry.setAttribute("position", new Float32BufferAttribute(position, 3));
  geometry.setIndex(reverseFlatTriangles(triangles));
  geometry.computeVertexNormals();

  const material = new MeshStandardMaterial({
    color: "#3c3c3c",
    // color: "#5c130f",
    // color: getRandomHexColor(),
    side: DoubleSide,
  });

  const plane = new Mesh(geometry, material);
  plane.castShadow = true;
  plane.receiveShadow = true;

  return <primitive object={plane} />;
};

const Wall: FC<{ points: IPoint[]; height: number }> = ({ points, height }) => {
  const extrudeSettings = {
    steps: 1,
    depth: height,
    bevelEnabled: false,
  };

  // Utwórz obiekt Shape z punktów obwodu
  const shape = new Shape();
  shape.moveTo(points[0].x, points[0].y);

  points.slice(1).forEach((point) => {
    shape.lineTo(point.x, point.y);
  });

  shape.lineTo(points[0].x, points[0].y);

  return (
    <mesh position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]}>
      <extrudeGeometry attach="geometry" args={[shape, extrudeSettings]} />
      <meshStandardMaterial attach="material" color="#c7c7c7" />
    </mesh>
  );
};

const View3DModal: FC<Props> = ({
  open,
  onOk,
  onCancel,
  roofPoints,
  roofPlanes,
  roofEdges,
  rsoPoints,
  rsoEdges,
  rsoPlanes,
}) => {
  const [loadContent, setLoadContent] = useState<boolean>(false);
  const [showWalls, setShowWalls] = useState<boolean>(false);
  const [movedPoints, setMovedPoints] = useState<IRoofPoint[]>([]);
  const [movedRSOPoints, setMovedRSOPoints] = useState<IRoofPoint[]>([]);
  const modalContent = useRef<any>(null);

  useEffect(() => {
    // 1. Określamy środek polygonu jako obwód dachu
    const dist = 0.01;

    // To tak nie może być bo to blokuje punkty które są podniesione przez RoofStatZ
    // const outlinePoints = roofPoints.filter((o) => o.z <= dist && o.z >= -1 * dist);
    // const center = getPointsForCenetrRoofPart([...outlinePoints]);

    // TODO przesunięcie nie jest prawidłowe w tym układzie po ściany są w innym miejscu
    // Sprawdzić czy ściany też powinny być inaczej przesunięte
    const center = getPointsForCenetrRoofPart([...roofPoints]);
    setMovedPoints(moveToOrigin(roofPoints, { x: center?.x as number, y: center?.y as number, z: 0 }));

    const center2 = getPointsForCenetrRoofPart([...rsoPoints]);
    setMovedRSOPoints(moveToOrigin(rsoPoints, { x: center2?.x as number, y: center2?.y as number, z: 0 }));
  }, [roofPoints, rsoPoints]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setLoadContent(true);
      }, 500);
    }
  }, [open]);

  // Zwraca listę id punktów, które sa n aobwodzie dachu (odpadają wsyztskie otwory, okna, kominy)
  const getPointIdsForOutlineRoof = () => {
    const arr = [];

    for (const e of roofEdges) {
      if (e.type !== 9 && e.type !== 11 && e.type !== 12 && e.status === 2) {
        arr.push(e.startPointId);
        arr.push(e.endPointId);
      }
    }

    return arr;
  };

  const isZero = (value: number) => {
    const threshold = Number.EPSILON;

    if (Math.abs(value) <= threshold) {
      return true;
    } else {
      return false;
    }
  };

  const getWallPoints = () => {
    const center = getPointsForCenetrRoofPart([...roofPoints]);

    const pointsForWallIds = getPointIdsForOutlineRoof();

    const forWalls = [];

    for (const p of roofPoints) {
      if (pointsForWallIds.some((o) => p.id && isZero(p.z))) {
        forWalls.push(p);
      }
    }

    return moveToOrigin(
      forWalls.filter((o) => o.status === 1),
      { x: center?.x as number, y: center?.y as number, z: 0 }
    );
  };

  return (
    <Container
      title={"Widok 3D dachu (uproszczony)"}
      width={"90vw"}
      open={open}
      onCancel={onCancel}
      centered
      bodyStyle={{ height: "80vh" }}
      footer={[
        <Button type="primary" key="ok" onClick={onCancel}>
          Zamknij
        </Button>,
      ]}
    >
      {!loadContent && (
        <SpinContainer>
          <DomaSpin />
        </SpinContainer>
      )}
      {loadContent && (
        <Content ref={modalContent}>
          {/* <div style={{ display: "flex", gap: "8px", width: "100%", justifyContent: "right" }}>
            <Switch checked={showWalls} onChange={(e) => setShowWalls(e)} /> <div>Pokaż ściany po obwodzie</div>
          </div> */}
          <Canvas>
            <color attach="background" args={["#ffffff"]} />

            <OrbitControls
              minDistance={10}
              maxDistance={2000}
              minZoom={0.5}
              maxZoom={5}
              enableDamping
              dampingFactor={0.05}
            />
            <PerspectiveCamera makeDefault position={[-700, 700, 700]} fov={45} near={1} far={10000} />

            <ambientLight intensity={0.8} />
            <directionalLight
              position={[5, 100, 100]}
              intensity={2.2}
              castShadow
              shadow-camera-left={-500}
              shadow-camera-right={500}
              shadow-camera-top={500}
              shadow-camera-bottom={-500}
            />

            {roofPlanes &&
              roofPlanes.length > 0 &&
              roofPlanes.map((o, i) => {
                if (o.type === 1) {
                  let points = getFullPointsById(o.pointIds, movedPoints);

                  const holesArr: any[] = [];

                  for (const h of store.getState().roofHoles) {
                    if (h.roofPlaneId === o.id) {
                      holesArr.push(getFullPointsById(h.pointIds, movedPoints));
                    }
                  }

                  return <RoofPlane roofPlane={o} key={i} points={points} holes={holesArr} />;
                }
              })}

            {/* Dodatkowe wyświetlanie połaci rysowanych ręcznie */}
            {rsoPlanes &&
              rsoPlanes.length > 0 &&
              rsoPlanes.map((plane, i) => {
                if (plane) {
                  // Filtruj punkty, które należą do tej połaci
                  const planePoints =
                    plane.pointIds?.map((pointId) => rsoPoints.find((point) => point.id === pointId)) ?? [];

                  // Usuń ewentualne elementy undefined
                  const filteredPlanePoints = planePoints.filter(
                    (point): point is IRSOPoint => point !== undefined
                  );

                  const holesArr: any[] = [];

                  for (const h of store.getState().rsoHoles) {
                    if (h.rsoPlaneId === plane.id) {
                      holesArr.push(getFullPointsById(h.pointIds, rsoPoints));
                    }
                  }

                  return (
                    <RoofPlane
                      key={i}
                      points={filteredPlanePoints.reverse()}
                      holes={holesArr}
                      roofPlane={undefined}
                    />
                  );
                }
              })}

            {/* {roofEdges.map((o, i) => {
              const newList = movedPoints;

              let s = getFullPointsById([o.startPointId], newList)[0];
              let e = getFullPointsById([o.endPointId], newList)[0];
              return <RoofEdgeView key={i} points={[s, e]} holes={[]} />;
            })} */}

            {showWalls && <Wall points={getWallPoints()} height={200} />}

            <GizmoHelper alignment="bottom-left" margin={[50, 50]}>
              <GizmoViewcube color="#3c3c3c" textColor="#ffffff" />
            </GizmoHelper>
          </Canvas>
        </Content>
      )}
    </Container>
  );
};

export default View3DModal;

const modalStyles = css`
  .ant-modal-content {
    background-color: #ffffff;
  }

  .ant-modal-header {
    background-color: #ffffff;
    border-bottom: none;
  }

  .ant-modal-title {
    color: ${TEXT_COLOR};
  }

  .ant-modal-body {
    background-color: #ffffff;
  }

  .ant-modal-footer {
    background-color: #ffffff;
    border-top: none;
  }

  z-index: 99999999999999;
`;

const Container = styled(Modal)`
  ${modalStyles}
`;

const Content = styled.div`
  width: 100% !important;
  height: 100% !important;
`;

const SpinContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DomaSpin = styled(DSpin)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
