import { Button, Card, Col, Form, InputNumber, Modal, Row, Statistic, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import Konva from "konva";
import React, { useLayoutEffect, useRef, useState } from "react";
import { FC } from "react";
import { Circle, Layer, Line, Rect, Stage, Text } from "react-konva";
import styled from "styled-components";
import { IMetalSheet } from "../../../../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import {
  addMetalSheet,
  removeMetalSheet,
  updateMetalSheet,
} from "../../../../../../redux/roofMetal/metalSheetsSlice";
import { v4 as uuidv4 } from "uuid";

const SCALE_BY = 1.1;

type Props = {
  metalSheet: IMetalSheet | null;
  onCancel: any;
  onOk: any;
};

const EditMetalSheetModal: FC<Props> = ({ metalSheet, onOk, onCancel }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const [zoomScale, setZoomScale] = useState(1); // Skala zoom, przybliżenia rysunku

  // Konfiguracja Stage
  const stage = useRef<Konva.Stage>(null); // Referencja do obszaru
  const stageParent = useRef<HTMLDivElement>(null); // Referencja do rodzica stage
  const [dimStageParent, setDimStageParent] = useState({ width: 0, height: 0 }); // wymiary parent stage

  const metalSheets = useAppSelector((state) => state.metalSheets);
  const scale = useAppSelector((state) => state.drawingScale);

  const calcMaterialsData = useAppSelector((state) => state.calcMaterialsData);

  // Przypisanie wymiarów parent stage
  useLayoutEffect(() => {
    if (stageParent.current) {
      setDimStageParent({
        width: stageParent.current.offsetWidth,
        height: stageParent.current.offsetHeight,
      });
    }
  }, [stageParent.current]);

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="back" onClick={(e) => onCancel()}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button
        key="ok"
        type="primary"
        onClick={(e) => {
          onOkClick(); // wewnętzrna funkcja
          onOk(); // zewnętrzna funkcja
        }}
      >
        Ok
      </Button>
    );

    return buttons;
  };

  const getInitialValues = () => {
    if (metalSheet) {
      return {
        number: metalSheet?.type,
        width: metalSheet?.width / scale,
        length: Number((metalSheet?.height / scale).toFixed(4)),
      };
    }
    return {};
  };

  const onOkClick = () => {
    let { width, length } = form.getFieldsValue();

    width = width * scale;
    length = length * scale;

    // Tu cała logika do zarządzania arkuszem blachy jeśłi zmieniają się rozmiary

    if (metalSheet) {
      // 1 Sprawdzenie czy zmienił się wymiar
      const isChanged = width !== metalSheet?.width || length !== metalSheet?.height;

      // Jeśłi nie zmienił się to koniec funkcji
      if (!isChanged) {
        return;
      }

      // Jeśli zmienił się to dalej

      // 2. Sprawdzenie czy ten arkusz był jedynym egzemplarzem
      const isOnlyOne = metalSheets.filter((ms) => ms.type === metalSheet?.type).length === 1;

      // Jeśłi był jedynym egzemplarzem to aktualizujemy jego wymiary i nie musimy nic więcej zmieniać
      if (isOnlyOne) {
        dispatch(
          updateMetalSheet({
            ...metalSheet,
            width: width,
            height: length,
          })
        );
        return;
      }

      // Jeśłi nie był jedynym egzemplarzem to dalej

      // 3 Sprawdzenie czy istnieją już arkusze o takim wymiarze (z dokładnością do 4 miejsc po przecinku)
      // Powinien być tylko jeden ale sprawdzamy więcej
      const existedTheSameSheets = metalSheets.filter(
        (ms) => ms.width.toFixed(4) === width.toFixed(4) && ms.height.toFixed(4) === length.toFixed(4)
      );

      if (existedTheSameSheets.length > 0) {
        // Jeśli istnieje to zmieniamy type na type tego istniejącego arkusza
        dispatch(
          updateMetalSheet({
            ...metalSheet,
            type: existedTheSameSheets[0].type,
          })
        );

        return;
      }

      // Określamy typ arkusza który nie istnieje
      const existedTypes = metalSheets.map((ms) => ms.type);
      // wybierz największy type jaki istnieje
      const maxType = Math.max(...existedTypes);

      const newType = maxType + 1;

      // Jeśli nie istnieje to tworzymy nowy arkusz o takim wymiarze
      const newMetalSheet: IMetalSheet = {
        id: uuidv4(),
        type: newType,
        width: width,
        height: length,
        active: metalSheet.active,
        x: metalSheet.x,
        y: metalSheet.y,
        roofSlopeId: metalSheet.roofSlopeId,
        roofSlopeNumber: metalSheet.roofSlopeNumber,
      };

      dispatch(addMetalSheet(newMetalSheet));
      dispatch(removeMetalSheet(metalSheet));
    }
  };

  const isModularSheets = calcMaterialsData.systemType === 2;

  const drawMetalSheet = () => {
    if (metalSheet) {
      // Oblicz pozycję, aby wyśrodkować arkusz blachy na scenie
      const metalSheetX = dimStageParent.width / 2 - metalSheet.width / 2;
      const metalSheetY = dimStageParent.height / 2 - metalSheet.height / 2;

      return (
        <Rect
          x={metalSheetX}
          y={metalSheetY}
          width={metalSheet.width}
          height={metalSheet.height}
          fill={"#ddd"}
          stroke={"#333"}
          strokeWidth={1}
        />
      );
    }
    return null;
  };

  function zoomStage(event: any) {
    // event.evt.preventDefault();
    // event.stopPropagation();

    if (event.evt.ctrlKey && stage.current !== null) {
      event.evt.preventDefault();

      const s = stage.current;
      const oldScale = s.scaleX();
      const pointerX = s.getPointerPosition()?.x as number;
      const pointerY = s.getPointerPosition()?.y as number;
      const mousePointTo = {
        x: (pointerX - s.x()) / oldScale,
        y: (pointerY - s.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? Number((oldScale / SCALE_BY).toFixed(1)) : Number((oldScale * SCALE_BY).toFixed(1));
      s.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      s.position(newPos);
      s.batchDraw();

      setZoomScale(newScale);
    }
  }

  return (
    <StyledModal
      centered
      width={"40%"}
      open={true}
      title={"Edycja arkusza blachy"}
      onCancel={onCancel}
      onOk={onOk}
      footer={getButtonsInFooter()}
    >
      <Section>
        <StageParent ref={stageParent}>
          <Stage
            ref={stage}
            height={500}
            width={dimStageParent.width}
            style={{ margin: "0", background: "#E8E8E8", borderRadius: "8px" }}
            onContextMenu={(e) => {
              e.evt.preventDefault();
            }}
            draggable
            onWheel={zoomStage}
          >
            <Layer>{drawMetalSheet()}</Layer>
          </Stage>
        </StageParent>
      </Section>
      <Section style={{ marginTop: "32px", display: "flex" }}>
        <Form
          id="editMetalSheetDataForm"
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
          form={form}
          autoComplete="off"
          initialValues={getInitialValues()}
        >
          <Form.Item label="Numer arkusza" name="number" style={{ display: "flex" }}>
            <InputNumber disabled style={{ minWidth: "200px" }} />
          </Form.Item>

          <Form.Item label="Szerokość arkusza [m]" name="width" style={{ display: "flex" }}>
            <InputNumber style={{ minWidth: "200px" }} disabled />
          </Form.Item>

          <Form.Item label="Długość arkusza [m]" name="length" style={{ display: "flex", alignItems: "center" }}>
            <InputNumber style={{ minWidth: "200px" }} />
          </Form.Item>
        </Form>
      </Section>
    </StyledModal>
  );
};
export default EditMetalSheetModal;

const StyledModal = styled(Modal)``;

const Section = styled.div`
  width: 100%;
`;

const StageParent = styled.div``;
