import { Button, Card } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../layout/components/PageHeader";
import {
  IAddPrincingSystem,
  IAddPrincingSystemElement,
  IPrincingSystemElement,
  IUpdatePrincingSystem,
  IUpdatePrincingSystemElement,
} from "../../models/Models";
import { addPrincingSystem } from "../../redux/contractors/princingSystemsSlice";
import { useAppDispatch } from "../../redux/hooks";
import ContractorPrincingSystem from "./components/ContractorPrincingSystem";
import { v4 as uuidv4 } from "uuid";

export default function AddContractorPrincingSystemPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [princingSystem, setPrincingSystem] = useState<IAddPrincingSystem>({
    name: "",
    description: "",
    princingSystemElements: [],
  });

  const updatePrincingSystemGeneralData = (newName: string, newDescription: string) => {
    setPrincingSystem((prevState) => ({
      ...prevState,
      name: newName,
      description: newDescription,
    }));
  };

  const addPrincingSystemElement = (element: IAddPrincingSystemElement) => {
    if (!princingSystem) return; // jeśli princingSystem jest null, przerwij funkcję

    // Tworzenie nowego elementu na podstawie przekazanych danych.
    // Zakładam, że możesz chcieć generować ID automatycznie, jeśli nie jest ono dostarczone.
    const newElement: IPrincingSystemElement = {
      id: uuidv4(),
      name: element.name,
      relation: element.relation,
      price: element.price,
      currency: "", // niepotrzebne do zapytania
      princingSystemId: "", // niepotrzebne do zapytania
    };

    // Aktualizujemy stan princingSystem z nową listą elementów
    setPrincingSystem({
      ...princingSystem,
      princingSystemElements: [...princingSystem.princingSystemElements, newElement],
    });
  };

  // const addPrincingSystemElement = (element: IAddPrincingSystemElement) => {
  //   // Sprawdzanie, czy princingSystem istnieje (choć w tym przypadku zawsze powinien być zainicjowany)
  //   if (princingSystem) {
  //     setPrincingSystem((prevState) => ({
  //       ...prevState, // zachowuje istniejące właściwości (name, description)
  //       princingSystemElements: [...prevState.princingSystemElements, element], // dodaje nowy element do tablicy elements
  //     }));
  //   }
  // };

  const updatePrincingSystemElement = (updatedElement: IUpdatePrincingSystemElement) => {
    if (!princingSystem) return; // jeśli princingSystem jest null, przerwij funkcję
    // Mapujemy przez każdy element w princingSystemElements
    const updatedPrincingSystemElements = princingSystem.princingSystemElements.map((element) => {
      // Jeśli ID aktualnie przetwarzanego elementu pasuje do ID updatedElement
      // to zwracamy zaktualizowany obiekt, w przeciwnym razie zwracamy oryginalny element
      if (element.id === updatedElement.id) {
        return {
          ...element,
          name: updatedElement.name,
          relation: updatedElement.relation,
          price: updatedElement.price,
        };
      }
      return element;
    });

    // Aktualizujemy stan princingSystem z zaktualizowaną listą elementów
    setPrincingSystem({
      ...princingSystem,
      princingSystemElements: [...updatedPrincingSystemElements],
    });
  };

  const deletePrincingSystemElement = (elementId: string) => {
    if (!princingSystem) return; // jeśli princingSystem jest null, przerwij funkcję

    // Filtruj listę elementów, aby usunąć ten o podanym ID
    const updatedElements = princingSystem.princingSystemElements.filter((element) => element.id !== elementId);

    // Aktualizuj stan princingSystem z nową listą elementów
    setPrincingSystem({
      ...princingSystem,
      princingSystemElements: updatedElements,
    });
  };

  // Funkcja kończąca dodawanie systemu -> wywołanie do API
  const addSystem = () => {
    // console.log("ADD SYSTEM");
    // console.log(princingSystem);

    dispatch(addPrincingSystem(princingSystem, () => navigate(-1)));
  };

  const addExamplePrincingSystem = () => {
    const array = [];

    array.push({ name: "Montaż dachówki na powierzchni", relation: "PLANE", price: 20 });
    array.push({ name: "Deskowanie dachu", relation: "PLANE", price: 30 });
    array.push({ name: "Montaż membrany dachowej", relation: "PLANE", price: 12 });
    array.push({ name: "Montaż gąsiorów na kalenicy poziomej", relation: "H-RIDGE-EDGE", price: 30 });
    array.push({ name: "Montaż gąsiorów na kalenicy skośnej", relation: "D-RIDGE-EDGE", price: 30 });
    array.push({ name: "Montaż taśmy kalenicowej", relation: "H-RIDGE-EDGE", price: 8 });
    array.push({ name: "Montaż taśmy kalenicowej", relation: "D-RIDGE-EDGE", price: 8 });
    array.push({ name: "Montaż łaty kalenicowej", relation: "H-RIDGE-EDGE", price: 12 });
    array.push({ name: "Montaż łaty kalenicowej", relation: "D-RIDGE-EDGE", price: 12 });
    array.push({ name: "Montaż blachy koszowej", relation: "VALLEY-EDGE", price: 40 });
    array.push({ name: "Obróbka szczytów", relation: "R-GABLE-EDGE", price: 80 });
    array.push({ name: "Obróbka szczytów", relation: "L-GABLE-EDGE", price: 80 });
    array.push({ name: "Obróbka krawędzi ściana-dach", relation: "ROOF-WALL-EDGE", price: 60 });
    array.push({ name: "Obróka krawędzi okna", relation: "WINDOW-EDGE", price: 30 });
    array.push({ name: "Obróbka kominów", relation: "CHIMNEY-EDGE", price: 70 });
    array.push({ name: "montaż orynnowania", relation: "EAVE-EDGE", price: 30 });
    array.push({ name: "Montaż pasa nadrynnowego", relation: "EAVE-EDGE", price: 15 });

    const examplePrincingSystem: IAddPrincingSystem = {
      name: "Przykładowy system wyceny A1",
      description: "Przygotowany do testów system (ceny są abstrakcyjne)",
      princingSystemElements: [],
    };

    for (const e of array) {
      examplePrincingSystem.princingSystemElements.push(e);
    }

    dispatch(addPrincingSystem(examplePrincingSystem, () => navigate(-1)));
  };

  return (
    <Container>
      <PageHeader
        title={"Dane systemu wyceny"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            <Button type="default" onClick={addExamplePrincingSystem}>
              Dodaj przykładowy system
            </Button>
            <Button type="primary" onClick={addSystem}>
              Dodaj system
            </Button>
          </div>
        }
      />

      <ContractorPrincingSystem
        princingSystem={princingSystem}
        addPrincingSystemElement={addPrincingSystemElement}
        updatePrincingSystemGeneralData={(v: any) => updatePrincingSystemGeneralData(v.name, v.description)}
        updatePrincingSystemElement={updatePrincingSystemElement}
        deletePrincingSystemElement={deletePrincingSystemElement}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
