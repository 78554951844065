import React from "react";
import styled from "styled-components";
import { Alert, Button, Card, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../redux/general/actions/profileActions";

export default function ChangePasswordCard({ error }) {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const data = { currentPassword: values.currentPassowrd, newPassword: values.newPassword };
    dispatch(changePassword(data));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <Container title="Zmiana hasła">
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Form.Item
          label="Obecne hasło"
          name="currentPassowrd"
          rules={[{ required: true, message: "Podaj poprawne obecne hasło!" }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Nowe hasło"
          name="newPassword"
          rules={[{ required: true, message: "Podaj nowe hasło!" }]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Powtórz nowe hasło"
          name="repeatNewPassword"
          rules={[
            {
              required: true,
              message: "Podane hasło jest inne niż podane wyżej!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Podane hasło jest inne niż te podane wyżej!"));
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item style={{ display: "flex", justifyContent: "right" }}>
          <Button type="primary" htmlType="submit">
            Zapisz zmiany
          </Button>
        </Form.Item>
      </Form>

      <Alerts>
        {error && <Alert message={error} type="error" showIcon />}

        <Alert
          message="Po poprawnej zmianie hasła nastąpi wylogowanie użytkownika i należy ponownie zalogować się używając nowego hasła!"
          type="info"
          showIcon
        />
      </Alerts>
    </Container>
  );
}

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Alerts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
