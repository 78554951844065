import { Alert, Button, Form, InputNumber, Modal, Radio, Segmented, Select, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import DomaInputNumber from "../helpers/DomaInputNumber";
import { ICalcResult, updateAdditionalDiscountResultItem } from "../redux/calculationProcess/calcResultSlice";
import { useAppDispatch } from "../redux/hooks";
import { PRIMARY_COLOR } from "../theme";

type Props = {
  open: boolean;
  onCancel: any;
  onOk: any;
  calcResult: ICalcResult;
  nettValue: number; // Cena netto już po rabatach na produktach
  grossValue: number; // Cena brutto już po rabatach na produktach
};

const AdditionalDiscountModal: FC<Props> = ({ open, onCancel, onOk, calcResult, nettValue, grossValue }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const getInitialValues = () => {
    return {
      offerDiscount: calcResult.offerDiscount,
      offerDiscountType: calcResult.offerDiscountType,
      offerDiscountPart: calcResult.offerDiscountPart,
    };
  };

  const onFinish = (values: any) => {
    const model: {
      offerDiscount: number;
      offerDiscountType: "%" | "price";
      offerDiscountPart: "nett" | "gross";
    } = {
      offerDiscount: values.offerDiscount,
      offerDiscountType: values.offerDiscountType,
      offerDiscountPart: values.offerDiscountPart,
    };

    dispatch(updateAdditionalDiscountResultItem(model));
    onOk();
  };

  const onFinishFailed = (errorInfo: any) => {};

  const getButtonsInFooter = () => {
    const buttons = [];

    buttons.push(
      <Button key="back" onClick={(e) => onCancel()}>
        Anuluj
      </Button>
    );

    buttons.push(
      <Button form="additionalDiscountForm" key="submit" htmlType="submit" type="primary">
        Zapisz
      </Button>
    );

    return buttons;
  };

  return (
    <Container
      title={"Dodatkowy rabat globalny"}
      open={open}
      width={"50%"}
      onCancel={(e) => onCancel()}
      centered
      footer={getButtonsInFooter()}
      bodyStyle={{ width: "100%" }}
    >
      {/* <Alert
        message="WAŻNE! Dodatkowy rabat jest naliczany do ceny uzyskanej już po rabatach na produkt."
        showIcon
        type="warning"
        style={{ marginTop: "16px" }}
      /> */}

      <div style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: "16px" }}>
        <Form
          id="additionalDiscountForm"
          style={{ width: "100%" }}
          form={form}
          autoComplete="off"
          layout="vertical"
          initialValues={getInitialValues()}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Typ rabatu" name="offerDiscountType">
            <StyledRadioGroup>
              <Radio.Button value="%">Procentowy [%]</Radio.Button>
              <Radio.Button value="price">Kwotowy [zł]</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item label="Przeznaczenie rabatu" name="offerDiscountPart">
            <StyledRadioGroup>
              <Radio.Button value="nett">Dla kwoty netto</Radio.Button>
              <Radio.Button value="gross">Dla kwoty brutto</Radio.Button>
            </StyledRadioGroup>
          </Form.Item>

          <Form.Item label="Wartość rabatu [%/zł]" name="offerDiscount">
            <DomaInputNumber />
          </Form.Item>
        </Form>
      </div>
    </Container>
  );
};
export default AdditionalDiscountModal;

const Container = styled(Modal)`
  display: flex;
  width: 100%;
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    margin-right: 8px;
    &:hover {
      color: ${PRIMARY_COLOR}; // kolor dla hover
    }
    &.ant-radio-button-wrapper-checked {
      background-color: ${PRIMARY_COLOR}; // kolor dla zaznaczonego
      color: white;
    }
  }
`;
