import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBasicPoint } from "../../models/Models";

export interface IBayObject {
  p0: IBasicPoint;
  p1: IBasicPoint;
  p2: IBasicPoint;
  p10L: IBasicPoint;
  p10R: IBasicPoint;
  p0L: IBasicPoint;
  p0R: IBasicPoint;
  p2L: IBasicPoint;
  p2R: IBasicPoint;

  pw: IBasicPoint;
  pwL: IBasicPoint;
  pwR: IBasicPoint;
  pwLg: IBasicPoint;
  pwRg: IBasicPoint;
}

export interface IBaysState {
  roofPlaneId: string | null;
  roofEdgeId: string | null;
  startPoint: IBasicPoint | null; // Punkt startowy okapu
  endPoint: IBasicPoint | null; // Punkt końcowy okapu
  w1: number;
  w10: number;
  w2: number;
  h1: number;
  overhangSide: number;
  overhangFront: number;
  bayObject: IBayObject | null;
  angle: number;
  bayType?: "bay" | "bay-rectangle";
}

export const initialState: IBaysState = {
  roofPlaneId: null,
  roofEdgeId: null,
  startPoint: null,
  endPoint: null,
  w1: 1.5,
  w10: 1,
  w2: 1,
  h1: 2,
  overhangSide: 0.1,
  overhangFront: 0.1,
  bayObject: null,
  angle: 40,
  bayType: "bay",
};

export const baysSlice = createSlice({
  name: "baysState",
  initialState,
  reducers: {
    updateAllBayState: (state, action: PayloadAction<IBaysState>) => {
      const bayState = action.payload;
      return { ...state, ...bayState };
    },
    updateAngleInBay: (state, action: PayloadAction<number>) => {
      const angle = action.payload;
      return { ...state, angle: angle };
    },
    updateBayTypeInBay: (state, action: PayloadAction<"bay" | "bay-rectangle">) => {
      const bayType = action.payload;
      return { ...state, bayType: bayType };
    },
    updateBayObject: (state, action: PayloadAction<IBayObject | null>) => {
      const bayObject = action.payload;
      return { ...state, bayObject: bayObject ? { ...bayObject } : null };
    },
    updatePointsInBay: (state, action: PayloadAction<{ startPoint: IBasicPoint; endPoint: IBasicPoint }>) => {
      const { startPoint, endPoint } = action.payload;
      return { ...state, startPoint: startPoint, endPoint: endPoint };
    },
    updateRoofPlaneIdInBay: (state, action: PayloadAction<string>) => {
      const roofPlaneId = action.payload;
      return { ...state, roofPlaneId: roofPlaneId };
    },
    updateRoofEdgeIdInBay: (state, action: PayloadAction<string>) => {
      const roofEdgeId = action.payload;
      return { ...state, roofEdgeId: roofEdgeId };
    },
    updateOverhangSideInBay: (state, action: PayloadAction<number>) => {
      const overhangSide = action.payload;
      return { ...state, overhangSide: overhangSide };
    },
    updateOverhangFrontInBay: (state, action: PayloadAction<number>) => {
      const overhangFront = action.payload;
      return { ...state, overhangFront: overhangFront };
    },
    updateW1InBay: (state, action: PayloadAction<number>) => {
      const w1 = action.payload;
      return { ...state, w1: w1 };
    },
    updateW10InBay: (state, action: PayloadAction<number>) => {
      const w10 = action.payload;
      return { ...state, w10: w10 };
    },
    updateW2InBay: (state, action: PayloadAction<number>) => {
      const w2 = action.payload;
      return { ...state, w2: w2 };
    },
    updateH1InBay: (state, action: PayloadAction<number>) => {
      const h1 = action.payload;
      return { ...state, h1: h1 };
    },
    clearBayState: (state) => {
      return initialState;
    },
  },
});

export const {
  updateAllBayState,
  updateBayObject,
  updateBayTypeInBay,
  updateAngleInBay,
  updatePointsInBay,
  updateOverhangFrontInBay,
  updateOverhangSideInBay,
  updateH1InBay,
  updateRoofPlaneIdInBay,
  updateRoofEdgeIdInBay,
  updateW10InBay,
  updateW1InBay,
  updateW2InBay,
  clearBayState,
} = baysSlice.actions;
export default baysSlice.reducer;
