import {
  BlockOutlined,
  CalculatorOutlined,
  EuroOutlined,
  FileOutlined,
  FileWordOutlined,
  FormOutlined,
  HomeOutlined,
  MenuOutlined,
  OrderedListOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  WindowsOutlined,
  UnderlineOutlined,
  PushpinOutlined,
  BorderOutlined,
  GroupOutlined,
  CodepenOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DomaLogoComponent from "../components/SVGComponents/DomaLogoComponent";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { changeSidebarStatus } from "../redux/other/sidebarStatusSlice";
import { Collapse } from "antd";
import { PRIMARY_COLOR } from "../theme";

export default function Sidebar({ activePage, onClickMenuButton, isAdmin, isModerator, isContractor }) {
  const dispatch = useAppDispatch();
  const isSelected = (key) => activePage === key;

  const sidebarStatus = useAppSelector((state) => state.sidebarStatus);

  const handleMouseEnter = () => {
    if (!sidebarStatus) {
      dispatch(changeSidebarStatus());
    }
  };
  const handleMouseLeave = () => {
    if (sidebarStatus) {
      dispatch(changeSidebarStatus());
    }
  };

  return (
    <Container
      name="sidebar-doma"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      expanded={sidebarStatus}
    >
      <LogoContent
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <StyledDomaLogoComponent />
      </LogoContent>

      <SectionCollapseContainer bordered={false} style={{ marginTop: "16px" }} activeKey={"main"}>
        <StyledCollapsePanel
          header={<SectionTitle>{sidebarStatus ? "Main" : <span>M</span>}</SectionTitle>}
          key="main"
          showArrow={false}
        >
          <MenuButtonsContainer>
            <MenuButton expanded={sidebarStatus} active={isSelected("")} onClick={() => onClickMenuButton("")}>
              <MenuItemContainer>
                <HomeOutlined />
                {sidebarStatus && "Panel główny"}
              </MenuItemContainer>
            </MenuButton>
            <MenuButton
              expanded={sidebarStatus}
              active={isSelected("oferty")}
              onClick={() => onClickMenuButton("oferty")}
            >
              <MenuItemContainer>
                <OrderedListOutlined />
                {sidebarStatus && "Moje oferty"}
              </MenuItemContainer>
            </MenuButton>
            <MenuButton
              expanded={sidebarStatus}
              active={isSelected("dachy")}
              onClick={() => onClickMenuButton("dachy")}
            >
              <MenuItemContainer>
                <CalculatorOutlined />
                {sidebarStatus && "Oblicz dach"}
              </MenuItemContainer>
            </MenuButton>
          </MenuButtonsContainer>
        </StyledCollapsePanel>
      </SectionCollapseContainer>

      {(isAdmin() || isModerator()) && (
        <SectionCollapseContainer bordered={false} style={{ marginTop: "16px" }} activeKey={"modarator"}>
          <StyledCollapsePanel
            header={<SectionTitle>{sidebarStatus ? "Moderator" : <span>M</span>}</SectionTitle>}
            key="modarator"
            showArrow={false}
          >
            <MenuButtonsContainer>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("producenci")}
                onClick={() => onClickMenuButton("producenci")}
              >
                <MenuItemContainer>
                  <BlockOutlined />
                  {sidebarStatus && "Producenci"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("materialy")}
                onClick={() => onClickMenuButton("materialy")}
              >
                <MenuItemContainer>
                  <ShoppingCartOutlined />
                  {sidebarStatus && "Materiały"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("systemy-dachowe")}
                onClick={() => onClickMenuButton("systemy-dachowe")}
              >
                <MenuItemContainer>
                  <FormOutlined />
                  {sidebarStatus && "Sys. pokryć dach."}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("systemy-krycia-wstepnego")}
                onClick={() => onClickMenuButton("systemy-krycia-wstepnego")}
              >
                <MenuItemContainer>
                  <BorderOutlined />
                  {sidebarStatus && "Sys. krycia wstęp."}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("systemy-zamocowan")}
                onClick={() => onClickMenuButton("systemy-zamocowan")}
              >
                <MenuItemContainer>
                  <PushpinOutlined />
                  {sidebarStatus && "Sys. zamocowań"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("systemy-rynnowe")}
                onClick={() => onClickMenuButton("systemy-rynnowe")}
              >
                <MenuItemContainer>
                  <UnderlineOutlined />
                  {sidebarStatus && "Sys. rynnowe"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("grupy-produktowe")}
                onClick={() => onClickMenuButton("grupy-produktowe")}
              >
                <MenuItemContainer>
                  <GroupOutlined />
                  {sidebarStatus && "Grupy produktowe"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("grupy-wyposazenie-techniczne")}
                onClick={() => onClickMenuButton("grupy-wyposazenie-techniczne")}
              >
                <MenuItemContainer>
                  <CodepenOutlined />
                  {sidebarStatus && "Grupy wyp. tech."}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("integracja-okien")}
                onClick={() => onClickMenuButton("integracja-okien")}
              >
                <MenuItemContainer>
                  <WindowsOutlined />
                  {sidebarStatus && "Integracja okien"}
                </MenuItemContainer>
              </MenuButton>
            </MenuButtonsContainer>
          </StyledCollapsePanel>
        </SectionCollapseContainer>
      )}

      {isAdmin() && (
        <SectionCollapseContainer bordered={false} style={{ marginTop: "16px" }} expandIconPosition="end">
          <StyledCollapsePanel
            header={<SectionTitle>{sidebarStatus ? "Admin" : <span>A</span>}</SectionTitle>}
            key="admin"
            showArrow={sidebarStatus}
          >
            <MenuButtonsContainer>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("uzytkownicy")}
                onClick={() => onClickMenuButton("uzytkownicy")}
              >
                <MenuItemContainer>
                  <UsergroupAddOutlined />
                  {sidebarStatus && "Użytkownicy"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("firma")}
                onClick={() => onClickMenuButton("firma")}
              >
                <MenuItemContainer>
                  <FileOutlined />
                  {sidebarStatus && "Dane firmy"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("wykonawcy")}
                onClick={() => onClickMenuButton("wykonawcy")}
              >
                <MenuItemContainer>
                  <MenuOutlined />
                  {sidebarStatus && "Wykonawcy"}
                </MenuItemContainer>
              </MenuButton>
            </MenuButtonsContainer>
          </StyledCollapsePanel>
        </SectionCollapseContainer>
      )}

      {(isContractor() || isAdmin()) && (
        <SectionCollapseContainer bordered={false} style={{ marginTop: "16px" }} expandIconPosition="end">
          <StyledCollapsePanel
            header={<SectionTitle>{sidebarStatus ? "Wykonawca" : <span>W</span>}</SectionTitle>}
            key="wykonawca"
            showArrow={sidebarStatus}
          >
            <MenuButtonsContainer>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("contractorData")}
                onClick={() => onClickMenuButton("contractorData")}
              >
                <MenuItemContainer>
                  <FileWordOutlined />
                  {sidebarStatus && "Dane wykonawcy"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("konfiguracja-cennika-wykonawcy")}
                onClick={() => onClickMenuButton("konfiguracja-cennika-wykonawcy")}
              >
                <MenuItemContainer>
                  <EuroOutlined />
                  {sidebarStatus && "Systemy wyceny"}
                </MenuItemContainer>
              </MenuButton>
              <MenuButtonDisabled
                expanded={sidebarStatus}
                active={isSelected("transfer-danych-wykonawcy")}
                // onClick={() => onClickMenuButton("transfer-danych-wykonawcy")}
              >
                <MenuItemContainer>
                  <UploadOutlined />
                  {sidebarStatus && "Transfer danych"}
                </MenuItemContainer>
              </MenuButtonDisabled>
              <MenuButton
                expanded={sidebarStatus}
                active={isSelected("konfiguracja-wykonawcy")}
                onClick={() => onClickMenuButton("konfiguracja-wykonawcy")}
              >
                <MenuItemContainer>
                  <SettingOutlined />
                  {sidebarStatus && "Konfiguracja"}
                </MenuItemContainer>
              </MenuButton>
            </MenuButtonsContainer>
          </StyledCollapsePanel>
        </SectionCollapseContainer>
      )}

      {sidebarStatus && (
        <Footer onClick={() => window.open("https://www.doma.pl/", "_blank", "noopener,noreferrer")}>
          by DOMA
        </Footer>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: ${(props) => (props.expanded ? "200px" : "50px")};
  min-width: ${(props) => (props.expanded ? "200px" : "50px")};
  position: fixed;
  top: 0;
  height: 100vh;
  background: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;

  z-index: 1000;

  @media (max-width: 991px) {
    display: none;
  }

  transition: width 0.2s ease; // Dodanie animacji
`;

const SectionCollapseContainer = styled(Collapse)`
  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    padding: 0px !important;
    margin: 0px 8px 0px 8px;
    background: #ffffff;
  }
`;

const StyledCollapsePanel = styled(Collapse.Panel)`
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  background: #ffffff;
`;

const SectionTitle = styled.div`
  color: #9d9d9d;
  font-size: 12px;
  text-align: center;
`;

const MenuButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
`;

const MenuButton = styled.div`
  display: flex;
  justify-content: ${(props) => (props.expanded ? "flex-start" : "center")};
  padding: ${(props) => (props.expanded ? "4px 8px 4px 8px" : "8px 0px 8px 0px")};
  margin: ${(props) => (props.expanded ? "0px 8px 0px 8px" : "0px 8px 0px 8px")};
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;

  background: ${(props) => (props.active ? PRIMARY_COLOR : "")};
  color: ${(props) => (props.active ? "#fff" : "")};

  &:hover {
    background: ${PRIMARY_COLOR}
    color: #fff;
  }
`;

const MenuButtonDisabled = styled(MenuButton)`
  color: #a9a9a9; /* szary kolor tekstu */

  &:hover {
    background: #ffffff; /* brak zmiany przy najechaniu */
    color: #a9a9a9; /* brak zmiany przy najechaniu */
  }

  pointer-events: none; /* brak reakcji na kliknięcie */
  cursor: default; /* brak zmiany kursora przy najechaniu */
`;

const Footer = styled.div`
  position: fixed;
  bottom: 8px;
  display: flex;
  justify-content: center;
  width: 200px;
  font-size: 12px;
  cursor: pointer;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const StyledDomaLogoComponent = styled(DomaLogoComponent)`
  width: 60%;
  height: 60%;
`;

const LogoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 4px; */
  height: 50px;
  border-bottom: 1px solid #e0e0e0;

  background: #ffffff;
  font-weight: bold;
  font-size: 20px;
  z-index: 1000;

  cursor: pointer;

  @media (max-width: 991px) {
    display: none;
  }
`;
