import { Button, Card, Checkbox, Form, Input, List, message, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import PageHeader from "../../../layout/components/PageHeader";
import {
  IContractorPriceItem,
  IPrincingSystem,
  IUpdateActivePriceSystemRequestModel,
  IUpdateActivePrincingPriceSystemRequestModel,
} from "../../../models/Models";
import { updateContractorPriceList } from "../../../redux/contractors/contractorPriceListSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { updateActivePrincingPriceSystemInContractor } from "../../../redux/contractors/contractorSlice";
import { updateActivePriceSystem } from "../../../redux/contractors/princingSystemsSlice";

const columns = [
  {
    title: "Nazwa systemu wyceny",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Opis",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Widoczność",
    dataIndex: "active",
    key: "active",
    align: "center" as "center",
    width: "150px",
  },
  // {
  //   title: "Aktywna wycena",
  //   dataIndex: "defaultActive",
  //   key: "defaultActive",
  //   align: "center" as "center",
  //   width: "200px",
  // },
  {
    title: "Edytuj",
    dataIndex: "edit",
    key: "edit",
    align: "center" as "center",
    width: "100px",
  },
];

const externalSystemsColumns = [
  {
    title: "Nazwa systemu",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Właściciel",
    dataIndex: "owner",
    key: "owner",
  },
];

export default function ConfigurationContractorPriceList() {
  const [shouldSaved, setShouldSaved] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const contractor = useAppSelector((state) => state.contractor);
  const princingSystems = useAppSelector((state) => state.princingSystems);

  const services = useAppSelector((state) => state.contractorServices);
  const priceList = useAppSelector((state) => state.contractorPriceLists);

  const externalPrincingSystems = useAppSelector((state) => state.externalPrincingSystems);

  useEffect(() => {
    if (services && priceList) {
      const arr = [];

      for (const s of services) {
        const priceItem = priceList.find((o) => o.contractorServiceId === s.id);

        if (priceItem) {
          arr.push({ ...priceItem });
        } else {
          arr.push({ id: uuidv4(), contractorServiceId: s.id, price: 0 });
        }
      }

      setPriceListData([...arr]);
    }
  }, [services, priceList]);

  const [priceListData, setPriceListData] = useState<IContractorPriceItem[]>([]);

  const getPrincingSystemItems = () => {
    var arr = [];

    let i = 0;
    for (const item of princingSystems) {
      arr.push({
        key: item.id,
        name: item.name,
        description: item.description,
        active: (
          <Checkbox
            checked={item.active}
            onChange={(e) => {
              // Aktualizacja Price System
              const model: IUpdateActivePriceSystemRequestModel = {
                active: e.target.checked,
                priceSystemId: item.id,
              };
              dispatch(updateActivePriceSystem(model, () => null));
            }}
          />
        ),
        // defaultActive: (
        //   <Checkbox
        //     checked={contractor.activePriceSystemId === item.id}
        //     onChange={(v) => {
        //       // Aktualizacja Contractor
        //       const model: IUpdateActivePrincingPriceSystemRequestModel = {
        //         contractorId: contractor.id,
        //         priceSystemId: item.id,
        //       };
        //       dispatch(updateActivePrincingPriceSystemInContractor(model, () => null));
        //       console.log(v);
        //     }}
        //   />
        // ),
        edit: (
          <Button onClick={() => navigate(`/konfiguracja-cennika-wykonawcy/${item.id}/edytuj`)}>Edytuj</Button>
        ),
      });
      i++;
    }
    return arr;
  };

  const getExternalSystemsItems = () => {
    var arr = [];

    if (externalPrincingSystems) {
      for (const item of externalPrincingSystems) {
        arr.push({
          key: item.id,
          name: item.name,
          owner: item.owner,
        });
      }
    }

    return arr;
  };

  return (
    <Container>
      <PageHeader
        title={"Konfiguracja systemów wyceny"}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {/* <Button type="primary" disabled={!shouldSaved} onClick={() => saveChanges()}>
              Zapisz zmiany
            </Button> */}
            <Button type="default" onClick={() => navigate(`/konfiguracja-cennika-wykonawcy/dodaj`)}>
              Dodaj system wyceny
            </Button>
          </div>
        }
      />

      <Section title="Moje systemy wyceny">
        <Table
          dataSource={getPrincingSystemItems()}
          rowKey="key"
          columns={columns}
          pagination={{ defaultPageSize: 10 }}
        />
      </Section>

      <Section title="Zewnętrzne systemy wyceny (tylko podgląd oraz możliwość wykorzystania w wycenie robocizny)">
        <Table
          dataSource={getExternalSystemsItems()}
          rowKey="key"
          columns={externalSystemsColumns}
          pagination={{ defaultPageSize: 10 }}
        />
      </Section>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;
