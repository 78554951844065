import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import client from "../../api/ApiClient";
import { IAddSteelSystem, ISteelSystem } from "../../models/Models";
import { addStatusApi } from "../other/statusApiSlice";
import { AppThunk } from "../store";

const initialState: ISteelSystem[] = [];

export const steelSystemsSlice = createSlice({
  name: "steelSystems",
  initialState,
  reducers: {
    addSteelSystemsInReducer: (state, action: PayloadAction<ISteelSystem[]>) => {
      let steelSystems = action.payload;
      return steelSystems;
    },
    addSteelSystemInReducer: (state, action: PayloadAction<ISteelSystem>) => {
      let steelSystem = action.payload;
      return [...state, steelSystem];
    },
    updateSteelSystemInReducer: (state, action: PayloadAction<ISteelSystem>) => {
      let update = action.payload;
      let index = state.findIndex((x) => x.id === update.id);
      state[index] = { ...state[index], ...update };
    },
    deleteSteelSystemInReducer: (state, action: PayloadAction<string>) => {
      const steelSystemId = action.payload;
      return state.filter((o) => o.id !== steelSystemId);
    },
  },
});

export const {
  addSteelSystemsInReducer,
  addSteelSystemInReducer,
  updateSteelSystemInReducer,
  deleteSteelSystemInReducer,
} = steelSystemsSlice.actions;
export default steelSystemsSlice.reducer;

// *********************************************************
// API
// *********************************************************

// Add steel system
export const addSteelSystem =
  (data: IAddSteelSystem, callback: () => void): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("ADD MODEL", data);
    client
      .put("/SteelSystems", data)
      .then((response) => {
        if (response.status == 201) {
          dispatch(addSteelSystemInReducer(response.data));
          callback();
          // dispatch(addStatusApi({ key: "addSteelSystem", type: "success", message: "" }));
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        // dispatch(addStatusApi({ key: "addSteelSystem", type: "error", message: "Błąd dodania systemu!" }));
        // console.error(error);
      });
  };

// Update steel system
export const updateSteelSystem =
  (data: ISteelSystem): AppThunk =>
  (dispatch: Dispatch) => {
    console.log("UPDATE MODEL", data);
    client
      .post(`/SteelSystems`, data)
      .then((response) => {
        if (response.status == 200) {
          console.log("UPDATE MODEL RESPONSE", response.data);
          dispatch(updateSteelSystemInReducer(response.data));

          message.open({
            type: "success",
            content: "System został zaktualizowany!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

// Delete steel system -> ONLY ADMIN
export const deleteSteelSystem =
  (data: ISteelSystem): AppThunk =>
  (dispatch: Dispatch) => {
    client
      .delete(`/SteelSystems/${data.id}`)
      .then((response) => {
        if (response.status == 204) {
          dispatch(deleteSteelSystemInReducer(data.id));
          dispatch(addStatusApi({ key: "deleteSteelSystem", type: "success", message: "" }));

          message.open({
            type: "success",
            content: "System został usunięty!",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
