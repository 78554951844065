import { Button, Card, Form, Input, List, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DomaInputNumber from "../../../helpers/DomaInputNumber";
import {
  IAddPrincingSystem,
  IAddPrincingSystemElement,
  IPrincingSystem,
  IPrincingSystemElement,
} from "../../../models/Models";
import { addPrincingSystem } from "../../../redux/contractors/princingSystemsSlice";
import { useAppDispatch } from "../../../redux/hooks";
import AddPrincingPosition from "./AddPrincingPosition";
import EditPrincingPosition from "./EditPrincingPosition";

type Props = {
  princingSystem?: IAddPrincingSystem | IPrincingSystem | null;
  updatePrincingSystemGeneralData?: any;
  addPrincingSystemElement?: any;
  updatePrincingSystemElement?: any;
  deletePrincingSystemElement?: any;
};

const ContractorPrincingSystem: FC<Props> = ({
  princingSystem,
  updatePrincingSystemGeneralData,
  addPrincingSystemElement,
  updatePrincingSystemElement,
  deletePrincingSystemElement,
}) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [addPrincingPositionActive, setAddPrincingPositionActive] = useState<boolean>(false);

  const [princingSystemElementInEdit, setPrincingSystemElementInEdit] = useState<IPrincingSystemElement | null>(
    null
  );

  const getInitialValues = () => {
    return { name: princingSystem?.name, description: princingSystem?.description };
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    updatePrincingSystemGeneralData(allValues);
  };

  return (
    <Container>
      {addPrincingPositionActive && (
        <AddPrincingPosition
          closeModal={() => setAddPrincingPositionActive(false)}
          addPrincingSystemElement={addPrincingSystemElement}
        />
      )}

      {princingSystemElementInEdit && (
        <EditPrincingPosition
          princingSystemElement={princingSystemElementInEdit}
          closeModal={() => setPrincingSystemElementInEdit(null)}
          updatePrincingSystemElement={updatePrincingSystemElement}
          deletePrincingSystemElement={deletePrincingSystemElement}
        />
      )}

      {/* <EditPrincingPosition /> */}
      <Form
        id="contractorPriceSystem"
        style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={getInitialValues()}
        onValuesChange={handleValuesChange}
      >
        <Section>
          <Form.Item label="Nazwa systemu" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Opis systemu" name="description">
            <Input />
          </Form.Item>
        </Section>

        {/* IMPORTANT !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! **********************!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
        {/* Możliwość edycji i usunięcie pozycji z listy */}
        <Section
          title={"Pozycje wyceny"}
          extra={
            <div style={{ display: "flex", gap: "16px" }}>
              <Button onClick={() => setAddPrincingPositionActive(true)}>Dodaj własną pozycję</Button>
            </div>
          }
        >
          <List
            itemLayout="horizontal"
            dataSource={princingSystem?.princingSystemElements}
            renderItem={(item: any, index) => (
              <List.Item
                actions={[
                  <PriceField key="price">
                    Cena: <b>{item.price} zł</b>
                  </PriceField>,
                  <Button key="edit" onClick={() => setPrincingSystemElementInEdit(item)}>
                    Edytuj
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={item.name}
                  description={
                    <span>
                      Odwołanie do <u>{item.relation}</u>
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        </Section>
      </Form>
    </Container>
  );
};
export default ContractorPrincingSystem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Section = styled(Card)`
  display: flex;
  flex-direction: column;
`;

const PriceField = styled.div``;
