import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import client from "../../api/ApiClient";
import { ICompany, IContractor, IContractorService, IUpdateContractorData } from "../../models/Models";
import { AppDispatch, AppThunk } from "../store";

const initialState: IContractorService[] = [];

export const contractorServicesSlice = createSlice({
  name: "contractorServices",
  initialState,
  reducers: {
    setContractorServicesInReducer: (state, action: PayloadAction<IContractorService[]>) => {
      let list = action.payload;
      return list;
    },
  },
});

export const { setContractorServicesInReducer } = contractorServicesSlice.actions;
export default contractorServicesSlice.reducer;
