import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PageHeader from "../../../layout/components/PageHeader";
import { IRootState } from "../../../models/Models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deleteStatusApi } from "../../../redux/other/statusApiSlice";
import { deleteProductsGroup, IProductsGroup } from "../../../redux/productsGroups/productsGroupsSlice";
import ProductsGroupsDetails from "./ProductsGroupsDetails";

type Props = {};

const EditProductsGroupsPage: FC<Props> = () => {
  const { groupId } = useParams();
  const [productsGroup, setproductsGroup] = useState<IProductsGroup | null>(null);

  const [manuallySubmitForm, setManuallySubmitForm] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useAppSelector((state) => state.profile);
  const productsGroups = useAppSelector((state: IRootState) => state.productsGroups);

  const deleteStatus = useAppSelector((state: IRootState) =>
    state.statusApi.find((x) => x.key === "deleteProductsGroup")
  );

  useEffect(() => {
    const s = productsGroups.find((x) => x.id === groupId);
    if (s) {
      setproductsGroup(s);
    }
  }, [productsGroup]);

  useEffect(() => {
    if (deleteStatus?.type === "success") {
      navigate(-1);
      dispatch(deleteStatusApi("deleteProductsGroup"));
    }
  }, [deleteStatus]);

  const isAdmin = () => {
    return profile.role === "admin";
  };

  return (
    <Container>
      <PageHeader
        title={`Edytuj (${productsGroup?.name})`}
        extra={
          <div style={{ display: "flex", gap: "8px" }}>
            {isAdmin() && productsGroup && (
              <Button danger onClick={() => dispatch(deleteProductsGroup(productsGroup, () => navigate(-1)))}>
                Usuń
              </Button>
            )}
            <Button type="primary" form="productsGroupDetailsForm" key="submit" htmlType="submit">
              Zapisz zmiany
            </Button>
            <Button type="primary" onClick={() => setManuallySubmitForm(true)} style={{ background: "green" }}>
              Utwórz nową grupę
            </Button>
          </div>
        }
      />

      <Content>
        {productsGroup && (
          <ProductsGroupsDetails
            system={productsGroup}
            manuallySubmitForm={manuallySubmitForm}
            setManuallySubmitForm={setManuallySubmitForm}
          />
        )}
      </Content>
    </Container>
  );
};
export default EditProductsGroupsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div``;
