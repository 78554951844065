import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAdditionalElement, IResultItemResponse } from "../../models/Models";

const initialState: IResultItemResponse[] = [];

export const additionalElementsSlice = createSlice({
  name: "additionalElements",
  initialState,
  reducers: {
    addAdditionalElement: (state, action: PayloadAction<IResultItemResponse>) => {
      let e = action.payload;
      return [...state, e];
    },
    addAdditionalElements: (state, action: PayloadAction<IResultItemResponse[]>) => {
      let es = action.payload;
      return es;
    },
    updateAdditionalElement: (state, action: PayloadAction<IResultItemResponse>) => {
      let e = action.payload;

      let index = state.findIndex((o) => o.productId === e.productId);
      state[index] = { ...state[index], ...e };
    },
    deleteAdditionalElement: (state, action: PayloadAction<string>) => {
      const eId = action.payload;
      return state.filter((o) => o.productId !== eId);
    },
    clearAdditionalElements: (state) => {
      return initialState;
    },
    setSavedRoofAdditionalElements: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const {
  addAdditionalElement,
  addAdditionalElements,
  updateAdditionalElement,
  deleteAdditionalElement,
  clearAdditionalElements,
  setSavedRoofAdditionalElements,
} = additionalElementsSlice.actions;
export default additionalElementsSlice.reducer;
