import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Alert, Button, Card, Form, Input, Table, Tag } from "antd";
import { generateRoof } from "./Test";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { IGutterSystem, IRootState } from "../../../models/Models";
import { getCompanyNameById, getProductColorNameById, getProductModelNameById } from "../../../helpers/Helpers";
import { debounce } from "lodash";
import { PRIMARY_COLOR } from "../../../theme";

const columns = [
  {
    title: "Nazwa systemu",
    dataIndex: "name",
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: "Producent",
    dataIndex: "companyName",
    width: 100,
    align: "center" as const,
    sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
    render: (companyName: string) =>
      companyName.length > 0 && (
        <Tag color={PRIMARY_COLOR} style={{ textAlign: "center", width: "100%" }}>
          {companyName}
        </Tag>
      ),
  },
  {
    title: "Model",
    dataIndex: "productModelName",
    align: "center" as const,
    width: 100,
    sorter: (a: any, b: any) => a.productModelName.localeCompare(b.productModelName),
    render: (productModelName: string) =>
      productModelName && (
        <Tag color="#87d068" style={{ textAlign: "center", width: "100%" }}>
          {productModelName}
        </Tag>
      ),
  },
  {
    title: "Kolor",
    dataIndex: "productColorName",
    align: "center" as const,
    width: 100,
    sorter: (a: any, b: any) => a.productColorName.localeCompare(b.productColorName),
    render: (productColorName: string) =>
      productColorName && (
        <Tag color={PRIMARY_COLOR} style={{ textAlign: "center", width: "100%" }}>
          {productColorName}
        </Tag>
      ),
  },
  {
    title: "Akcja",
    dataIndex: "action",
    align: "center" as const,
    width: 100,
  },
];

const GutterSystemsPage: FC = () => {
  const navigate = useNavigate();

  const companies = useAppSelector((state: IRootState) => state.companies);
  const productModels = useAppSelector((state: IRootState) => state.productModels);
  const productColors = useAppSelector((state: IRootState) => state.productColors);

  const gutterSystems = useAppSelector((state: IRootState) => state.gutterSystems);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<IGutterSystem[]>([]);

  // WYSZUKIWARKA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  // Tworzymy debounced wersję funkcji search
  const debouncedSearch = useCallback(
    debounce((text) => {
      search(text);
    }, 500),
    []
  );

  // Efekt, który obserwuje zmiany searchTerm i wywołuje debouncedSearch
  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch, gutterSystems]);

  const search = (text: string) => {
    let result: IGutterSystem[] = [];

    if (text.length > 0) {
      const inputWords = text
        .toLowerCase()
        .split(/[\s,;]+/)
        .filter((word: string) => word.trim() !== "");

      result = gutterSystems.filter((system) => {
        const systemName = system.name.toLowerCase();
        const companyName = getCompanyNameById(system.companyId as string, companies).toLowerCase();
        const productModelName = getProductModelNameById(
          system.productModelId as string,
          productModels
        ).toLowerCase();
        const productColorName = getProductColorNameById(
          system.productColorId as string,
          productColors
        ).toLowerCase();

        return inputWords.every(
          (word) =>
            systemName.includes(word) ||
            companyName.includes(word) ||
            productModelName.includes(word) ||
            productColorName.includes(word)
        );
      });

      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  };

  const getGutterSystemsData = (gutterSystems: IGutterSystem[]) => {
    const data: {
      key: string;
      name: string;
      companyName: string;
      productModelName: string;
      productColorName: string;
      action: JSX.Element;
    }[] = [];

    if (gutterSystems) {
      const sortedSystems = [...gutterSystems].sort((a, b) => a.name.localeCompare(b.name));
      for (const s of sortedSystems) {
        data.push({
          key: s.id,
          name: s.name,
          companyName: getCompanyNameById(s.companyId as string, companies),
          productModelName: getProductModelNameById(s.productModelId as string, productModels),
          productColorName: getProductColorNameById(s.productColorId as string, productColors),
          action: <Button onClick={() => navigate(`/systemy-rynnowe/${s.id}/edytuj`)}>Edytuj</Button>,
        });
      }
    }

    return data;
  };

  const getGutterSystemsCount = (gutterSystems: IGutterSystem[]) => {
    return gutterSystems ? gutterSystems.length : 0;
  };

  return (
    <Container
      title={
        <div>
          Systemy rynnowe{" "}
          <Tag style={{ padding: "4px 8px 4px 8px" }}>
            {getGutterSystemsCount(searchResults.length > 0 ? searchResults : gutterSystems)} szt.
          </Tag>
        </div>
      }
      extra={
        <Button type="primary" onClick={() => navigate(`/systemy-rynnowe/dodaj`)}>
          Dodaj system rynnowy
        </Button>
      }
    >
      <div style={{ marginBottom: "16px" }}>
        <Form.Item label="Znajdź system rynnowy" labelCol={{ span: 24 }} style={{ marginBottom: "32px" }}>
          <SearchInput
            value={searchTerm}
            placeholder="Wpisz nazwę, producenta, model lub kolor systemu rynnowego..."
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
          />
        </Form.Item>

        {searchTerm.length > 0 && searchResults.length === 0 && (
          <Alert
            showIcon
            type="error"
            message="Brak systemów spełniających kryteria wyszukiwania!"
            style={{ marginTop: "16px" }}
          />
        )}
      </div>

      <Table
        columns={columns}
        dataSource={getGutterSystemsData(searchResults.length > 0 ? searchResults : gutterSystems)}
        pagination={{ defaultPageSize: 10 }}
      />
    </Container>
  );
};

export default GutterSystemsPage;

const Container = styled(Card)``;

const SearchInput = styled(Input)``;
