import React, { useState, useEffect, FC } from "react";
import styled from "styled-components";
import { Card, Image } from "antd";
import { IProductCategory } from "../models/Models";
import { useNavigate } from "react-router-dom";
import { TEXT_COLOR } from "../theme";

type Props = {
  productCategory: IProductCategory;
};

const MaterialCategoryCard: FC<Props> = ({ productCategory }) => {
  const navigate = useNavigate();

  return (
    <Container bodyStyle={{ width: "100%" }} onClick={() => navigate(`/materialy/${productCategory.urlCode}`)}>
      <Content>
        <CategoryImage width={"150px"} preview={false} src={productCategory.imageUrl} />
        <TitleGroup>
          <Title>{productCategory.name}</Title>
          <SubTitle>{productCategory.description}</SubTitle>
        </TitleGroup>
      </Content>
    </Container>
  );
};

export default MaterialCategoryCard;

const Container = styled(Card)`
  display: flex;
  align-items: center;
  min-width: 300px;
  min-height: 200px;
  font-weight: bold;
  background: #fff;
  color: ${TEXT_COLOR}

  cursor: pointer;

  &:hover {
    background-color: #e9e9e9;
  }

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (min-width: 1301px) {
    width: calc((100% - 16px) / 2);
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const CategoryImage = styled(Image)``;

const TitleGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
`;
