import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Checkbox, InputNumber, Modal, Tooltip } from "antd";
import { IDataForBay, IRoofPlane } from "../../../../../models/Models";
import { FormOutlined } from "@ant-design/icons";

import bayRectangle from "../../../../../assets/roofs/bayTypes/bay_rectangle.svg";
import dormerRectangle from "../../../../../assets/roofs/bayTypes/dormer_rectangle.svg";
import bay1 from "../../../../../assets/roofs/bayTypes/bay_1.svg";
import bay1Angle from "../../../../../assets/roofs/bayTypes/bay_1_angle.svg";
import bay2 from "../../../../../assets/roofs/bayTypes/bay_2.svg";
import bay3 from "../../../../../assets/roofs/bayTypes/bay_3.svg";
import bay3Angle from "../../../../../assets/roofs/bayTypes/bay_3_angle.svg";
import bay4 from "../../../../../assets/roofs/bayTypes/bay_4.svg";
import dormer1 from "../../../../../assets/roofs/bayTypes/dormer_1.svg";
import dormer1Angle from "../../../../../assets/roofs/bayTypes/dormer_1_angle.svg";
import dormer2 from "../../../../../assets/roofs/bayTypes/dormer_2.svg";
import { useFirstRender } from "../../../../../hooks/useFirstRender";
import DomaInputNumber from "../../../../../helpers/DomaInputNumber";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { PRIMARY_COLOR } from "../../../../../theme";

const getBayImage = (dataForBay: IDataForBay) => {
  if (dataForBay.rectangle) {
    return bayRectangle;
  } else if (dataForBay.overhang === true && dataForBay.indentation === true && dataForBay.with3Slope == false) {
    if (dataForBay.useAngle === true) {
      return bay1Angle;
    } else {
      return bay1;
    }
  } else if (dataForBay.overhang === true && dataForBay.indentation === false && dataForBay.with3Slope == false) {
    return bay2;
  } else if (dataForBay.overhang === false && dataForBay.indentation === true && dataForBay.with3Slope == false) {
    if (dataForBay.useAngle === true) {
      return bay3Angle;
    } else {
      return bay3;
    }
  } else if (dataForBay.overhang === false && dataForBay.indentation === false && dataForBay.with3Slope == false) {
    return bay4;
  }
};

const getDormerImage = (dataForBay: IDataForBay) => {
  if (dataForBay.rectangle) {
    return dormerRectangle;
  } else if (dataForBay.overhang === true && dataForBay.with3Slope == false) {
    if (dataForBay.useAngle === true) {
      return dormer1Angle;
    } else {
      return dormer1;
    }
  } else if (dataForBay.overhang === false && dataForBay.with3Slope == false) {
    return dormer2;
  }
};

const getTitleModal = (dataForBay: IDataForBay) => {
  return dataForBay.bay ? "Dodaj wykusz" : "Dodaj lukarnę";
};

type Props = {
  roofPlane: IRoofPlane | null;
  dataForBay: IDataForBay;
  setDataForBay: any;
  visible: boolean;
  onOk: any;
  onCancel: any;
  setInGetLineFromDraw: any;
};

const RoofBayModal: FC<Props> = ({
  visible,
  roofPlane,
  dataForBay,
  setDataForBay,
  onOk,
  onCancel,
  setInGetLineFromDraw,
}) => {
  const firstRender = useFirstRender();

  const isBay = () => {
    return dataForBay.bay;
  };

  useEffect(() => {
    setDataForBay(dataForBay);
    if (firstRender) {
      if (dataForBay?.distFromDraw?.type === "A") {
        setDataForBay({ ...dataForBay, a: dataForBay.distFromDraw.value });
      } else if (dataForBay?.distFromDraw?.type === "W") {
        setDataForBay({ ...dataForBay, w: dataForBay.distFromDraw.value });
      } else if (dataForBay?.distFromDraw?.type === "D") {
        setDataForBay({ ...dataForBay, d: dataForBay.distFromDraw.value });
      } else if (dataForBay?.distFromDraw?.type === "Hy") {
        setDataForBay({ ...dataForBay, hy: dataForBay.distFromDraw.value });
      } else if (dataForBay?.distFromDraw?.type === "Os") {
        setDataForBay({ ...dataForBay, overhangSide: dataForBay.distFromDraw.value });
      } else if (dataForBay?.distFromDraw?.type === "Of") {
        setDataForBay({ ...dataForBay, overhangFront: dataForBay.distFromDraw.value });
      }
    }
  }, [dataForBay]);

  // Drag and drop modal
  const draggleRef = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x - 1000,
      right: clientWidth - (targetRect.right - uiData.x) + 1000,
      top: -targetRect.top + uiData.y - 1000,
      bottom: clientHeight - (targetRect.bottom - uiData.y) + 1000,
    });
  };

  return (
    <div
      style={{
        width: "100%",
        cursor: "move",
      }}
      onMouseEnter={() => {
        if (disabled) {
          setDisabled(false);
        }
      }}
      onMouseLeave={() => {
        setDisabled(true);
      }}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      <CustomModal
        title={getTitleModal(dataForBay)}
        open={visible}
        onCancel={() => onCancel(false)}
        centered
        width={1000}
        //   bodyStyle={{ height: "500px" }}
        footer={
          <>
            <Button key="back" onClick={onCancel}>
              Anuluj
            </Button>
            <Button key="ok" onClick={onOk} type="primary">
              {getTitleModal(dataForBay)}
            </Button>
          </>
        }
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
            <div ref={draggleRef} style={{ cursor: "move", width: "100%" }}>
              {modal}
            </div>
          </Draggable>
        )}
      >
        <Container>
          <BayOptions>
            <Checkbox
              checked={dataForBay.rectangle}
              onChange={(e) => setDataForBay({ ...dataForBay, rectangle: e.target.checked })}
            >
              {isBay() ? "Wykusz prostokątny" : "Lukarna prostokątna"}
            </Checkbox>

            <Checkbox
              disabled={dataForBay.rectangle}
              checked={dataForBay.overhang}
              onChange={(e) => setDataForBay({ ...dataForBay, overhang: e.target.checked })}
            >
              {isBay() ? "Wykusz z wysięgiem" : "Lukarna z bocznym okapem"}
            </Checkbox>

            {isBay() && (
              <Checkbox
                disabled={dataForBay.rectangle}
                checked={dataForBay.indentation}
                onChange={(e) => setDataForBay({ ...dataForBay, indentation: e.target.checked })}
              >
                Wykusz z wcięciem w dachu
              </Checkbox>
            )}

            <Checkbox
              disabled
              checked={dataForBay.with3Slope}
              onChange={(e) => setDataForBay({ ...dataForBay, with3Slope: e.target.checked })}
            >
              {isBay() ? "Wykusz z połacią czołową" : "Lukarna z połacią czołową"}
            </Checkbox>

            <Checkbox
              disabled={(!dataForBay.overhang && !dataForBay.indentation) || dataForBay.rectangle ? true : false}
              checked={dataForBay.useAngle}
              onChange={(e) => setDataForBay({ ...dataForBay, useAngle: e.target.checked })}
            >
              Znam kąt nachylenia połaci
            </Checkbox>
          </BayOptions>
          <Content>
            <ContentLeft>
              <BayImage src={isBay() ? getBayImage(dataForBay) : getDormerImage(dataForBay)} />
            </ContentLeft>
            <ContentRight>
              <Title>Dane wykuszu</Title>

              {!isBay() && (
                <InputContainer style={{ marginTop: "32px" }}>
                  <InputLabel>Zagłębienie lukarny (D)</InputLabel>
                  <InputContent>
                    <DomaInputNumber
                      value={dataForBay.d}
                      onChange={(value) => setDataForBay({ ...dataForBay, d: value })}
                    />
                    <UnitLabel>[m]</UnitLabel>
                    <InputButtonContainer>
                      <Tooltip
                        title={
                          <TooltipText>
                            Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button onClick={() => setInGetLineFromDraw("D")}>
                          <FormOutlined />
                        </Button>
                      </Tooltip>
                    </InputButtonContainer>
                  </InputContent>
                </InputContainer>
              )}

              <InputContainer style={{ marginTop: "32px" }}>
                <InputLabel>{isBay() ? "Szerokość wykuszu (A)" : "Szerokość lukarny (A)"}</InputLabel>
                <InputContent>
                  <DomaInputNumber
                    value={dataForBay.a}
                    onChange={(value) => setDataForBay({ ...dataForBay, a: value })}
                  />
                  <UnitLabel>[m]</UnitLabel>
                  <InputButtonContainer>
                    <Tooltip
                      title={
                        <TooltipText>
                          Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                        </TooltipText>
                      }
                      color={PRIMARY_COLOR}
                    >
                      <Button onClick={() => setInGetLineFromDraw("A")}>
                        <FormOutlined />
                      </Button>
                    </Tooltip>
                  </InputButtonContainer>
                </InputContent>
              </InputContainer>

              {isBay() && dataForBay.overhang && (
                <InputContainer>
                  <InputLabel>Długość wysięgu wykuszu (W)</InputLabel>
                  <InputContent>
                    <DomaInputNumber
                      value={dataForBay.w}
                      onChange={(value) => setDataForBay({ ...dataForBay, w: value })}
                    />
                    <UnitLabel>[m]</UnitLabel>
                    <InputButtonContainer>
                      <Tooltip
                        title={
                          <TooltipText>
                            Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button onClick={() => setInGetLineFromDraw("W")}>
                          <FormOutlined />
                        </Button>
                      </Tooltip>
                    </InputButtonContainer>
                  </InputContent>
                </InputContainer>
              )}

              {((isBay() && dataForBay.indentation && !dataForBay.useAngle) ||
                (!isBay() && dataForBay.overhang && !dataForBay.useAngle)) && (
                <InputContainer>
                  <InputLabel>
                    {isBay() ? "Długość wcięcia wykuszu (Hy)" : "Długość okapu lukarny (Hy)"}
                  </InputLabel>
                  <InputContent>
                    <DomaInputNumber
                      value={dataForBay.hy}
                      onChange={(value) => setDataForBay({ ...dataForBay, hy: value })}
                    />
                    <UnitLabel>[m]</UnitLabel>
                    <InputButtonContainer>
                      <Tooltip
                        title={
                          <TooltipText>
                            Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button onClick={() => setInGetLineFromDraw("Hy")}>
                          <FormOutlined />
                        </Button>
                      </Tooltip>
                    </InputButtonContainer>
                  </InputContent>
                </InputContainer>
              )}

              {isBay() && dataForBay.with3Slope && !dataForBay.useAngle && (
                <InputContainer>
                  <InputLabel>Długość połaci czołowej (Wy)</InputLabel>
                  <InputContent>
                    <DomaInputNumber
                      value={dataForBay.wy}
                      onChange={(value) => setDataForBay({ ...dataForBay, wy: value })}
                    />
                    <UnitLabel>[m]</UnitLabel>
                    <InputButtonContainer>
                      <Tooltip
                        title={
                          <TooltipText>
                            Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button>
                          <FormOutlined />
                        </Button>
                      </Tooltip>
                    </InputButtonContainer>
                  </InputContent>
                </InputContainer>
              )}

              {dataForBay.useAngle && (
                <InputContainer>
                  <InputLabel>
                    {isBay() ? "Kąt nachylenia połaci wykuszu (α)" : "Kąt nachylenia połaci lukarny (α)"}
                  </InputLabel>
                  <InputContent>
                    <DomaInputNumber
                      value={dataForBay.alfa}
                      onChange={(value) => setDataForBay({ ...dataForBay, alfa: value })}
                    />
                    <UnitLabel>[st.]</UnitLabel>
                    <InputButtonContainer style={{ visibility: "hidden" }}>
                      <Tooltip
                        title={
                          <TooltipText>
                            Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                          </TooltipText>
                        }
                        color={PRIMARY_COLOR}
                      >
                        <Button>
                          <FormOutlined />
                        </Button>
                      </Tooltip>
                    </InputButtonContainer>
                  </InputContent>
                </InputContainer>
              )}

              <InputContainer style={{ marginTop: "32px" }}>
                <InputLabel>Okap boczny (Os)</InputLabel>
                <InputContent>
                  <DomaInputNumber
                    value={dataForBay.overhangSide}
                    onChange={(value) => setDataForBay({ ...dataForBay, overhangSide: value })}
                  />
                  <UnitLabel>[m]</UnitLabel>
                  <InputButtonContainer>
                    <Tooltip
                      title={
                        <TooltipText>
                          Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                        </TooltipText>
                      }
                      color={PRIMARY_COLOR}
                    >
                      <Button onClick={() => setInGetLineFromDraw("Os")}>
                        <FormOutlined />
                      </Button>
                    </Tooltip>
                  </InputButtonContainer>
                </InputContent>
              </InputContainer>

              <InputContainer>
                <InputLabel>Okap czołowy (Of)</InputLabel>
                <InputContent>
                  <DomaInputNumber
                    value={dataForBay.overhangFront}
                    onChange={(value) => setDataForBay({ ...dataForBay, overhangFront: value })}
                  />
                  <UnitLabel>[m]</UnitLabel>
                  <InputButtonContainer>
                    <Tooltip
                      title={
                        <TooltipText>
                          Pobierz wartość z rysunku. Wskaż 2 punkty, które definują odcinek.
                        </TooltipText>
                      }
                      color={PRIMARY_COLOR}
                    >
                      <Button onClick={() => setInGetLineFromDraw("Of")}>
                        <FormOutlined />
                      </Button>
                    </Tooltip>
                  </InputButtonContainer>
                </InputContent>
              </InputContainer>
            </ContentRight>
          </Content>
        </Container>
      </CustomModal>
    </div>
  );
};

export default RoofBayModal;

const CustomModal = styled(Modal)`
  display: flex;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 32px;
`;

const BayOptions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 32px;
  gap: 16px;
`;

const ContentLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background: red; */
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background: green; */
`;

const Title = styled.div`
  font-weight: bold;
  text-align: center;
`;

const BayImage = styled.img`
  width: 80%;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const InputContent = styled.div`
  display: flex;
`;

const InputLabel = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const UnitLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const InputButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const TooltipText = styled.div`
  text-align: center;
`;
